import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
interface ButtonItem {
  id: string;
  label: string;
  icon: React.ReactNode;
}

const ButtonGroupContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const Button = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex: 1; /* Equal-sized buttons */
  padding: 8px 16px;
  border: 2px solid ${({ active }: any) => (active ? "#6150E1" : "#ddd")};
  border-radius: 8px;
  background-color: ${({ active }: any) => (active ? "#6150E1" : "#fff")};
  color: ${({ active }: any) => (active ? "#fff" : "#000")};
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  span {
    color: ${({ active }: any) => (active ? "#fff" : "#000")};
    transition: color 0.3s ease;
  }
`;

const Label = styled.span`
  font-size: 16px;
`;
interface ButtonGroupProps {
  incoming: boolean;
  outgoing: boolean;
}
const ButtonGroup: React.FC<ButtonGroupProps>  = ({ incoming, outgoing }) => {
  const { values, setFieldValue } = useFormikContext<{
    type: number;
  }>();
  const [activeId, setActiveId] = useState<any | null>();
  useEffect(() => {
    if(getIn(values, "type")){
      setActiveId(getIn(values, "type"));
    }
  },[getIn(values, "type")]) 

  const buttons: ButtonItem[] = [
    { id: "incoming", label: "Incoming calls agent", icon: <PhoneCallbackIcon />  },
    { id: "outgoing", label: "Make outgoing agent", icon: <PhoneMissedIcon /> },
  ];

  const handleClick = (id: any) => {
    setActiveId(id);
  };
  console.log("getIn(values",getIn(values, "type"));
  
  return (
    <ButtonGroupContainer>
      {buttons.map((button) => (
        <Button
          key={button.id}
          type="button"
          active={button.id === activeId}
          onClick={() => {
            handleClick(button.id);
            setFieldValue("type", button.id);
          }}
          style={{
            cursor:
            (button.id === "incoming"? incoming : button.id === "outgoing" ? outgoing : false)
              ? "not-allowed"
              : "auto",
          opacity:
            (button.id === "incoming"? incoming : button.id === "outgoing" ? outgoing : false)
              ? 0.5
              : 1, 
          }}
          disabled={button.id === "incoming"? incoming : button.id === "outgoing" ? outgoing : false }
        >
          <span>{button.icon}</span>
          <Label>{button.label}</Label>
        </Button>
      ))}
    </ButtonGroupContainer>
  );
};

export default ButtonGroup;
