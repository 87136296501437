import styled from "styled-components";
import { theme } from "../../../Customization/Theme";

// Container styles
export const styles = {
    SeemoreButton: {
        width: '279px',
        maxWidth: '279px',
        minWidth: '279px',
        margin: '0 auto',
        marginRight: '29px !important',
        position: 'sticky',
        bottom: 0,    
        },
  container: {
    display: 'flex'
  },
  cardName: {
    fontWeight: '500',
  },
  dragOverlay: {
    padding: '0px 16px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.default.lightGrayishBlue}`,
    borderRadius: '6px',
    opacity: '0.8',
    height: '114px',
    cursor: 'grabbing',
  },
};

export const wrapperStyle = {
  display: "flex",
  flexDirection: "row",
};

export const TableWrapper = styled("div")`
  & {
    height: calc(100vh - 168px);
    min-height: 400px;
    overflow: auto;
    scroll-behavior: auto;
  }
`;

export const AddNoteWrapper = styled("div")`
  .footer {
    display: flex;
    justfy-content: flex-end;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
    gap: 8px;
  }

  .text {
    padding: 16px;
    padding-bottom: 8px;
  }
  .header {
    height: auto;
    padding: 0px 16px;
    display: flex;
    border-bottom: none !important;
  }
`;

export const Textarea = styled.textarea`
  && {
    width: 100% !important;
    border-radius: 4px;
    background-color: ${theme.palette.default.lighterFilterGrey};
    outline: none !important;
    padding: 6px 1rem 6px 14px;
    border: 1px solid ${theme.palette.default.border};
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    resize: none;
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
  }
`;

// Section styled components
export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0;
  width: 100%;
`;

export const SectionTitle = styled.div`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  min-height: 40px;
  height: 40px;
  color: ${theme.palette.default.black};
  background: ${theme.palette.default.paleBlueViolet};
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
`;

// Column styled components
export const StyledChip = styled.div`
  height: 32px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background-color: ${theme.palette.default.dodgerBlue};
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
`;

export const ColumnTitle = styled.div`
  margin: 0;
  color: ${theme.palette.default.deepsteelBlue};
  font-weight: 500;
  font-size: 13px;
`;

export const RecordCount = styled.span`
  font-weight: 400;
  color: ${theme.palette.default.white};
  background-color: ${theme.palette.default.dustyBlue};
  padding: 2px 8px;
  margin-left: 4px;
  font-size: 12px;
  border-radius: 20px;
`;

export const DroppableArea = styled.div<{ isLoading?: boolean }>`
  flex: 1;
  overflow: ${(props: any) => props.isLoading ? 'hidden' : 'auto'};
  padding: 20px;
  padding-bottom: 8px;
  min-height: 200px;
  position: relative;
  overflow-x: hidden;
`;

export const ScrollArea = styled.div`
  height: calc(100vh - 325px);
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: ${theme.palette.default.lightsteelGray} transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.default.lightsteelGray};
    border-radius: 8px;
  }
`;

export const ColumnWrapper = styled.div`
  width: 330px; 
  max-width: 330px;
  position: relative;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  border-right: 1px solid ${theme.palette.default.lightGrayishBlue};
  
  &:last-child {
    border-right: none;
  }
`;

export const ColumnHeader = styled.div`
  background-color: ${theme.palette.default.white};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-bottom: 1px solid ${theme.palette.default.lightGrayishBlue};
`;

export const NoRecordsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
`;

export const NoRecordsText = styled.p`
  color: ${theme.palette.default.darkGrey};
  font-size: 13px;
`;

export const SkeletonCard = styled.div`
  background-color: ${theme.palette.default.white};
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 10px;
  box-shadow: 0 1px 2px ${theme.palette.default.shadow};
  width: 100%;
  height: 93px;
`;

export const SkeletonContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 10;
  padding: 8px 0;
`;

export const FooterButtonWrapper = styled.div`
  button {
    width: 279px !important;
    max-width: 279px !important;
    margin: 0 auto !important;
  }
`;

// Card component styled components
export const StyledCardContainer = styled.div<{ $isDragging: boolean }>`
  height: 114px;
  max-height: 114px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 8px;
  padding: 12px;
  // box-shadow: 0 1px 3px ${theme.palette.default.transparentBlack};
  border: 1px solid ${theme.palette.default.paleBlueGray};
  cursor: grab;
  user-select: none;
  transition: all 0.2s ease;
  
  ${(props: any) => props.$isDragging && `
    opacity: 0.4;  // Make original card semi-transparent
    transform: scale(0.95);  // Slightly shrink original card
    pointer-events: none;  // Disable interactions while dragging
  `}
  
  &:hover {
    box-shadow: 0 2px 5px ${theme.palette.default.transparentBlack};
  }
`;

export const StyledCardHeader = styled.div`
  max-width: 86%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  cursor: grab;
`;

export const StyledAvatar = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${theme.palette.default.paleskyBlue};
  color: ${theme.palette.default.boldskyBlue};
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
`;

export const StyledTitle = styled.h3`
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  color: ${theme.palette.default.deepskyBlue};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 32px);
`;

export const StyledCardContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
  cursor: grab;
  p {
    // margin-top: 2px;
    display: flex;
    color: ${theme.palette.default.darkLiver};
    align-items: center;
    font-size: 13px;
  }
  svg {
    font-size: 13px;
    color: ${theme.palette.default.darkLiver};
  }
`;

export const StyledIconButton = styled.button`
  position: absolute;
  right: 8px;
  bottom: 12px;
  padding: 4px;
  border-radius: 4px;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 13px;
    color: ${theme.palette.default.darkLiver};
  }
  
  &:hover {
    background-color: ${theme.palette.default.lightGrey};
  }
`;

export const StyledIconButtonAddNotes = styled.button`
  padding: 4px;
  position: absolute;
  left: 8px;
  top: 52px;
  border-radius: 4px;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 6px;
    font-size: 12px;
    color: ${theme.palette.default.deepskyBlue};
  }
  svg {
    font-size: 13px;
    fill: ${theme.palette.default.deepskyBlue};
  }
  
  &:hover {
    background-color: ${theme.palette.default.shadow};
  }
`;

export const StyledMenuItem = styled.div`
  padding: 8px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  color: ${theme.palette.default.darkGrey};
  
  &:hover {
    background-color: ${theme.palette.default.lightGrey};
  }
`; 

export const CardWrapper = styled.div`
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
`;

export const AvatarNameWrapper = styled.div`
display: flex;
align-items: center;
gap: 8px;
cursor: grab;
width: 100%;
`

export const SkeletonTopWrapepr = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`
