import React from "react";
import {
  Box,
  IconButton,
  Popover,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { ReactComponent as SearchIcon } from "../../../../assets/images/Searchicon.svg";

interface SelectPopoverProps {
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  optionsStack: any[][];
  searchTerm: string;
  onClose: () => void;
  onBackClick: () => void;
  onSearchChange: (term: string) => void;
  onOptionClick: (option: any) => void;
}

const SelectPopover: React.FC<SelectPopoverProps> = ({
  anchorEl,
  isOpen,
  optionsStack,
  searchTerm,
  onClose,
  onBackClick,
  onSearchChange,
  onOptionClick,
}) => {
  const currentOptions = optionsStack || [];
  const filteredOptions =
    currentOptions?.filter((option: any) =>
      option?.value?.toLowerCase().includes(searchTerm?.toLowerCase())
    ) || currentOptions;
  const isNestedLevel = optionsStack.length > 3;

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box sx={{ p: 2, width: 250 }}>
        {isNestedLevel && (
          <Box display="flex" alignItems="center" mb={2}>
            <IconButton size="small" onClick={onBackClick}>
              <ArrowBackIosNewIcon fontSize="small" />
            </IconButton>
            <TextField
              placeholder="Search..."
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={(e) => onSearchChange(e.target.value)}
              InputProps={{
                startAdornment: (
                  <IconButton size="small">
                    <SearchIcon fontSize="small" />
                  </IconButton>
                ),
              }}
              size="small"
              sx={{
                ml: 1,
                border: "1px solid #ccc",
                borderRadius: "4px",
                "& .MuiInputBase-root": { padding: "0px" },
              }}
            />
          </Box>
        )}

        <List>
          {filteredOptions.map((option: any) => (
            <ListItem key={option.value} disablePadding>
              <ListItemButton onClick={() => onOptionClick(option)}>
                {option?.value?.length > 20 ? (
                  <Tooltip title={option?.label} arrow>
                    <ListItemText
                      primary={`${option?.label?.slice(0, 20)}...`}
                    />
                  </Tooltip>
                ) : (
                  <ListItemText primary={option?.label} />
                )}
              </ListItemButton>
            </ListItem>
          ))}
          {filteredOptions.length === 0 && (
            <Typography variant="body2" sx={{ mt: 2, textAlign: "center" }}>
              No options found
            </Typography>
          )}
        </List>
      </Box>
    </Popover>
  );
};

export default SelectPopover;
