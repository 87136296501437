import { memo, useCallback, useMemo } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Box, Skeleton } from '@mui/material';
import AppButton from '../../../AppComponents/AppButton';
import Card from './Card';
import { 
  StyledChip, 
  ColumnTitle, 
  RecordCount, 
  DroppableArea, 
  ScrollArea, 
  ColumnWrapper, 
  ColumnHeader, 
  NoRecordsWrapper, 
  NoRecordsText, 
  SkeletonCard, 
  SkeletonContainer, 
  FooterButtonWrapper, 
  SkeletonTopWrapepr,
  styles
} from './SwimLaneView.styles';

// Card Skeleton component for loading state based on reference design
const CardSkeleton = memo(() => {
  return (
    <SkeletonCard>
      <SkeletonTopWrapepr>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Skeleton variant="circular" width={28} height={28} />
          <Skeleton variant="text" width={80} />
        </Box>
        <Skeleton variant="rectangular" width={8} height={16} />
      </SkeletonTopWrapepr>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, gap: 1 }}>
        <Skeleton variant="text" width={50} />
      </Box>
    </SkeletonCard>
  );
});

CardSkeleton.displayName = 'CardSkeleton';

// Extracted CardList component with performance optimizations
const CardList = memo(({ 
  contacts, 
  setDeleteData, 
  toggleContactModal, 
  status,
  statusId,
  stageId 
}: any) => {
  // Use useMemo to prevent unnecessary re-renders of the contact list
  const cardItems = useMemo(() => 
    contacts.map((contact: any) => (
      <Card
        key={contact.id}
        contact={contact}
        setDeleteData={setDeleteData}
        toggleContactModal={toggleContactModal}
        status={status}
        statusId={statusId}
        index={contacts.length - 1}
        stageId={stageId}
      />
    )), [contacts, setDeleteData, toggleContactModal, status, statusId, stageId]);

  return <>{cardItems}</>;
});

CardList.displayName = 'CardList';

// Main Column component with performance optimizations
const Column = memo(({
  status,
  sectionId,
  setPagination,
  items,
  Loader,
  pagination,
  setDeleteData,
  toggleContactModal
}: any) => {
  const { setNodeRef } = useDroppable({
    id: `${sectionId}-${status.id}`,
  });

  // Memoize load more callback
  const handleLoadMore = useCallback(() => {
    setPagination((prev: any) => ({
      index: status.contacts?.length,
      status: status?.label,
      statusId: status.id,
      stageId: sectionId,
      limit: 10,
    }));
  }, [status.contacts?.length, sectionId, setPagination, status.id, status.label]);

  const isLoading = Loader && status.label === pagination?.status;
  const showLoadMore = status.total_records && 
    status.total_records > "10" && 
    parseInt(status.total_records) !== items?.length;

  return (
    <ColumnWrapper>
      <div>
        <ColumnHeader>
          <StyledChip>
            <ColumnTitle>{status.label}</ColumnTitle>
            <RecordCount>{status.total_records} Leads</RecordCount>
          </StyledChip>
        </ColumnHeader>


        {isLoading ? (
          <Box p={2}>
              <SkeletonContainer>
                {[...Array(3)].map((_, index) => (
                  <CardSkeleton key={`skeleton-${index}`} />
                ))}
              </SkeletonContainer>
                </Box>
            ): 

        <ScrollArea>
          <DroppableArea isLoading={isLoading} ref={setNodeRef}>
            
          {status.contacts.length === 0 ? (
              <NoRecordsWrapper>
                <NoRecordsText>No contacts found.</NoRecordsText>
              </NoRecordsWrapper>
            ) : (
              <CardList
                contacts={status.contacts}
                setDeleteData={setDeleteData}
                toggleContactModal={toggleContactModal}
                status={status.label}
                statusId={status.id}
                stageId={sectionId}
              />
            )}

            
          </DroppableArea>
        </ScrollArea>
        }
      </div>
      <FooterButtonWrapper>
      {showLoadMore ? (
        <AppButton
        isDisabled={Loader || status.contacts?.length === status.total_records}
        style={styles.SeemoreButton}
        variant={Loader || status.contacts?.length === status.total_records ? 'grey' : 'outline'}
        startIcon="add"
        onClick={handleLoadMore}
        >
          See more
        </AppButton>
      ): null}
      </FooterButtonWrapper>
      
    </ColumnWrapper>
  );
});

Column.displayName = 'Column';

export default Column;
