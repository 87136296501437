import { memo, useMemo } from 'react';
import Column from './Column';
import { SectionContainer, SectionTitle, ColumnsContainer } from './SwimLaneView.styles';
import { ColumnListProps, SectionProps } from './utils/swimlinetype';


// Optimized Column renderer component
const ColumnList = memo(({ 
  statuses, 
  sectionId, 
  pagination, 
  Loader, 
  items, 
  setPagination, 
  toggleContactModal, 
  setDeleteData 
}: ColumnListProps) => {
  // Memoize the columns to prevent unnecessary re-renders
  const columns = useMemo(() => 
    statuses.map((status, index) => (
      <Column
        key={status.id}
        index={index}
        pagination={pagination}
        Loader={Loader}
        status={status}
        sectionId={sectionId}
        items={items}
        setPagination={setPagination}
        toggleContactModal={toggleContactModal}
        setDeleteData={setDeleteData}
      />
    )), 
    [
      statuses, 
      sectionId, 
      pagination, 
      Loader, 
      items, 
      setPagination, 
      toggleContactModal, 
      setDeleteData
    ]
  );

  return <>{columns}</>;
});

ColumnList.displayName = 'ColumnList';

// Main Section component
const Section = memo(({
  section,
  setPagination,
  items,
  Loader,
  pagination,
  setDeleteData,
  toggleContactModal
}: SectionProps) => {
  // Memoize section data to prevent unnecessary re-renders
  const sectionData = useMemo(() => ({
    statuses: section.statuses,
    sectionId: section.id,
    label: section.label
  }), [section.statuses, section.id, section.label]);

  return (
    <SectionContainer>
      <SectionTitle>{sectionData.label}</SectionTitle>
      <ColumnsContainer>
        <ColumnList
          statuses={sectionData.statuses}
          sectionId={sectionData.sectionId}
          pagination={pagination}
          Loader={Loader}
          items={items}
          setPagination={setPagination}
          toggleContactModal={toggleContactModal}
          setDeleteData={setDeleteData}
        />
      </ColumnsContainer>
    </SectionContainer>
  );
});

Section.displayName = 'Section';

export default Section;