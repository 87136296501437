import React, { useEffect, useRef, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, IconButton, Typography, debounce } from "@mui/material";
import { useDrag } from "react-dnd";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import {
  AppForm,
  AppFormColorPicker,
  AppFormField,
  AppFormObserver,
  AppFormSelectField,
  AppSubmitButton,
} from "../../../../AppComponents/Forms";
import AppButtonGroup from "../../../../AppComponents/Forms/AppButtonGroup";
import AppFormCheckboxField from "../../../../AppComponents/Forms/AppFormCheckboxField";
import AppReactSelectField from "../../../../AppComponents/Forms/AppFormReactSelect";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import { theme } from "../../../../Customization/Theme";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { FormAction } from "../../../../Store/Slices/FormBuilder/formbuilder.actions";
import {
  contactsDropDownList,
  fieldObj,
  formBuilderStore,
  formNameStoreData,
  getContactFields,
  getCustomPropertiesPreview,
  isEdit,
} from "../../../../Store/Slices/FormBuilder/formbuilder.selectors";
import {
  addDefaultFieldsId,
  addDefaultValue,
  addFieldToRows,
  addOptionsToShow,
  addRow,
  addSettings,
  deleteDroppedFieldsIds,
  editField,
  removeRow,
  saveContactFields,
  saveFormName,
  saveUpdatedFieldsPositions,
  setCustomPropertiesPreview,
} from "../../../../Store/Slices/FormBuilder/formbuilder.slice";
import { ContactPropertiesActions } from "../../../../Store/Slices/Settings/ContactProperties/contactProperties.action";
import CloseIcon from "../../../../assets/images/CloseIcon.svg";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import AppAccordion from "../../Components/AppAccordion";
import DragColumnInfo from "../../Components/DragColumnInfo";
import FieldLabel from "../../Components/FieldLabel";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import Fields from "../../Components/Fields";
import {
  countryCodeMobileOptions,
  customPropertiesArray,
  gridPreview,
  optVarificationOptions,
} from "../../Components/constants";
import { DroppableRow } from "./Droppable";

const Forms = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const formRef: any = useRef(null);
  const navigate = useNavigate();
  const contactsDropDownListOptions = useAppSelector(contactsDropDownList);
  const selectDates = [
    { id: 0, label: "None", value: "" },
    { id: 1, label: "Past", value: "past" },
    { id: 2, label: "Future", value: "future" },
  ];
  const formFieldsData: any = useAppSelector(formBuilderStore);
  const isFieldEdit: any = useAppSelector(isEdit);
  const formDetails: any = useAppSelector(formNameStoreData);
  const contactFields: any = useAppSelector(getContactFields);
  const isCustomPropertiesPreview = useAppSelector(getCustomPropertiesPreview);
  const { isShowing, toggle } = useModal();
  const [isGridViewOpen, setIsGridViewOpen] = useState(false);
  const [fieldsArray, setFieldsArray] = useState<any>([]);
  const [formikProps, setFormikProps] = useState<any>(null);
  const { showAlert } = useNotification();
  const [searchData, setSearchData] = useState({
    search: "",
  });

  const field: any = useAppSelector(fieldObj);
  const EditFieldValidationSchema = Yup.object().shape({
    label: Yup.string().label("Field Label").required("Required"),
  });

  const formNameValidationSchema = Yup.object().shape({
    formName: Yup.string().label("Form Name").required("Form Name is Required"),
  });

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setSearchData({
      ...searchData,
      [field]: e,
    });
  }, 700);

  //function to clear Searchfield with Values
  const clearCallback = (e?: any, field?: any) => {
    setSearchData({
      ...searchData,
      [field]: "",
    });
  };

  useEffect(() => {
    const fields = contactFields?.filter((item: any) =>
      item?.label?.toLowerCase().includes(searchData.search?.toLowerCase())
    );
    setFieldsArray(fields);
  }, [searchData]); // eslint-disable-line

  useEffect(() => {
    dispatch(ContactPropertiesActions.getListProperties({})).then(
      (contactProperties) => {
        dispatch(ContactPropertiesActions.GetHiddenField({})).then(
          (hiddenFields: any) => {
            const visibleFields: any =
              contactProperties?.payload?.data?.data?.map((parent: any) =>
                parent?.fields?.map((childs: any) => childs)
              );
            const hiddenFieldsArray: any = hiddenFields?.payload?.data?.data;
            const fields: any = [...visibleFields, hiddenFieldsArray]?.flat();
            dispatch(saveContactFields(fields));
            setFieldsArray(fields);
          }
        );
      }
    );
  }, []); // eslint-disable-line

  // useEffect to open Form Name Modal
  useEffect(() => {
    if (!formDetails && !id) {
      toggle(true);
    }
  }, [formDetails]); // eslint-disable-line

  //Dragging Hook
  const [, drag] = useDrag({
    type: "FORM_FIELD",
    collect: (monitor: any) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  //method to create a new row
  const createRow = (row: number) => {
    const rows: any = gridPreview.filter((item: any) => item.id === row);
    dispatch(addRow(rows?.[0]?.widthsArray));
  };

  // // useEffect to get options of contacts dropdown
  useEffect(() => {
    if (
      field?.is_dependant &&
      field?.type === "dropdown" &&
      field?.dependant_field_option_id
    ) {
      const payload = {
        parentId: field.dependant_field_id,
        selectedId: field?.dependant_field_option_id,
      };
      dispatch(FormAction.getAddContactsDropDown(payload));
    } else {
      const payload = { parentId: field.id };
      dispatch(FormAction.getAddContactsDropDown(payload));
    }
  }, [field]); // eslint-disable-line
  // useEffect to get options of contacts dropdown

  //useEffect to manage visibility of the field
  useEffect(() => {
    if (formRef?.current?.values?.default_value)
      formikProps?.setFieldValue("hide", true);
    else formikProps?.setFieldValue("hide", false);
  }, [formRef?.current?.values?.default_value, formRef?.current?.values?.type]); // eslint-disable-line

  // method to generate default values object
  const generateDefaultValues = (values: any) => {
    const default_fields_id: any = {
      [formRef?.current?.values?.name]: formRef?.current?.values?.id,
    };
    const default_value: any = {
      [formRef?.current?.values?.name]:
        typeof formRef?.current?.values?.default_value === "object"
          ? formRef?.current?.values?.default_value?.id
          : formRef?.current?.values?.default_value,
    };
    dispatch(addDefaultFieldsId(default_fields_id));
    dispatch(addDefaultValue(default_value));
    formikProps?.setFieldValue("hide", true);
  };

  const reorderElementsInRow = (
    rowIndex: number,
    sourceIndex: number,
    destinationIndex: number
  ) => {
    // Create a deep copy to avoid mutating state
    const items = formFieldsData.map((row: any) =>
      row.map((col: any) => ({ ...col }))
    );
    // Get the specific row where the column reordering is happening
    const rowItems = [...items[rowIndex]];
    // Remove and store the dragged column
    const [movedColumn] = rowItems.splice(sourceIndex, 1);
    // Insert the column at the new position
    rowItems.splice(destinationIndex, 0, movedColumn);
    // Update column index to maintain correct order
    rowItems.forEach((item, index) => {
      item.column = index; // Ensure this is not a read-only property
    });
    // Update the row in the copied array
    items[rowIndex] = rowItems;
    // Dispatch the updated data
    dispatch(saveUpdatedFieldsPositions(items));
  };

  const reorderRows = (source: number, destination: number) => {
    // Create a deep copy of formFieldsData
    const items = formFieldsData.map((row: any) =>
      JSON.parse(JSON.stringify(row))
    );
    // Extract the source row
    const [movedRow] = items.splice(source, 1);
    // Insert the row at the new position
    items.splice(destination, 0, movedRow);
    // Update row indices
    items.forEach((row: any, index: any) => {
      if (Array.isArray(row)) {
        row.forEach((field) => (field.row = index));
      } else if (typeof row === "object") {
        Object.values(row).forEach((field: any) => {
          if (typeof field === "object" && field !== null) {
            field.row = index;
          }
        });
        row.row = index;
      }
    });
    // Dispatch the updated form fields
    dispatch(saveUpdatedFieldsPositions(items));
  };

  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId.startsWith("droppable-elements-")) {
        const rowIndex = parseInt(source.droppableId.split("-")[2], 10);
        reorderElementsInRow(rowIndex, source.index, destination.index);
      } else {
        reorderRows(source.index, destination.index);
      }
    }
  };

  return (
    <Container>
      <DraggableSection>
        <div className="buttonGroup">
          <AppButtonGroup
            lables={[
              {
                id: 1,
                lable: "Default Properties",
                handleButtonClick: () => {
                  dispatch(setCustomPropertiesPreview(false));
                  dispatch(editField(false));
                },
              },
              {
                id: 2,
                lable: "Custom Properties",
                handleButtonClick: () => {
                  dispatch(setCustomPropertiesPreview(true));
                  dispatch(editField(false));
                },
              },
            ]}
          />
        </div>
        {isFieldEdit ? (
          <DraggableEditContainer>
            <AppForm
              initialValues={{
                ...field,
                otp_required: field?.otp_required ? "yes" : "no",
                hide: !field?.visible,
              }}
              validationSchema={EditFieldValidationSchema}
              innerRef={formRef}
              onSubmit={async (values: any) => {
                if (values?.type === "dropdown" && values?.[values?.name]) {
                  dispatch(
                    addOptionsToShow({
                      values,
                      options: values?.[values?.name],
                    })
                  );
                }
                if (values?.name === "mobile_phone_number") {
                  values["otp_required"] =
                    values?.otp_required === "yes" ? true : false || false;
                  values["otp_validated"] =
                    values?.otp_required === "yes" ? true : false || false;
                  let county_code: any = { county_code: values?.county_code };
                  dispatch(addSettings(county_code));
                }
                dispatch(
                  addFieldToRows({
                    row: field.row,
                    column: field.column,
                    newFormField: values,
                  })
                );
                if (values?.default_value) {
                  generateDefaultValues(values);
                }
                showAlert("Field config Saved!", "success");
                dispatch(editField(false));
              }}
            >
              <div className="header">
                <div className="header-content">
                  <IconButton onClick={() => dispatch(editField(false))}>
                    <AppMaterialIcons
                      iconName={"ArrowBackIosRounded"}
                      style={{ fontSize: "1rem", color: "black" }}
                    />
                  </IconButton>
                  <FieldLabel label={`Edit ${field?.label}`} />
                </div>
                <div className="underline"></div>
              </div>
              <EditFieldsContainer>
                <AppFormField
                  name="label"
                  errorMessage=""
                  label="Field Label"
                  requiredSign
                  defaultBorder={true}
                  labelStyle={{ color: theme.palette.default.newFontColor }}
                />

                <AppFormField
                  name="type"
                  errorMessage=""
                  label="Field Type"
                  labelStyle={{ color: theme.palette.default.newFontColor }}
                  disabled
                />

                {!isCustomPropertiesPreview && (
                  <React.Fragment>
                    <div className="checkBoxContainer">
                      <AppFormCheckboxField
                        name="required"
                        label={"Required Field"}
                        disabled={formRef?.current?.values?.hide}
                      />
                      <AppFormCheckboxField
                        name="hide"
                        label={"Hidden Field"}
                        disabled={formRef?.current?.values?.required}
                      />
                      <AppToolTip
                        tooltipProps={{
                          title: "If Checked, then field will not be Visible.",
                          placement: "top",
                        }}
                        iconButtonProps={{ disableRipple: true }}
                        IconComponent={<InfoOutlinedIcon fontSize="small" />}
                      />
                    </div>

                    {field?.name === "mobile_phone_number" ? (
                      <React.Fragment>
                        <AppFormSelectField
                          name="county_code"
                          Options={countryCodeMobileOptions}
                          valueKey="value"
                          labelKey="label"
                          popOverMaxWidth={"100%"}
                          label="Choose Default Country Code For Mobile"
                          labelStyle={{
                            color: theme.palette.default.newFontColor,
                          }}
                          enableSearch
                          divStyle={{
                            maxWidth: "100%",
                          }}
                        />

                        <AppFormSelectField
                          name="otp_required"
                          Options={optVarificationOptions}
                          valueKey="value"
                          labelKey="label"
                          popOverMaxWidth={"100%"}
                          label="OTP Varification Allowed"
                          labelStyle={{
                            color: theme.palette.default.newFontColor,
                          }}
                          enableSearch
                          divStyle={{
                            maxWidth: "100%",
                          }}
                        />
                      </React.Fragment>
                    ) : null}

                    {field?.type === "dropdown" ? (
                      <AppReactSelectField
                        name={"default_value"}
                        options={contactsDropDownListOptions?.options || []}
                        label={field?.label}
                        displayKey="label"
                        valueKey="id"
                        placeholder="Please Select Default Value"
                        isSearchable={true}
                        isClearable={true}
                        hideSelectedOptions={true}
                        divStyle={{ background: "none", padding: "0" }}
                        optionsPlaceholder="No Options Found"
                        onChangeCallbackFn={() => {
                          formikProps?.setFieldValue(field.name, "");
                        }}
                      />
                    ) : (
                      <AppFormField
                        name="default_value"
                        label="Default Value"
                        errorMessage=""
                        labelStyle={{
                          color: theme.palette.default.newFontColor,
                        }}
                        placeholder="Please Enter Default Value"
                      />
                    )}

                    {field?.type === "dropdown" && (
                      <>
                        {!formRef?.current?.values?.default_value ? (
                          <AppReactSelectField
                            name={field?.name}
                            options={contactsDropDownListOptions?.options || []}
                            label={"Select Options to Show"}
                            displayKey="label"
                            valueKey="id"
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            hideSelectedOptions={true}
                            divStyle={{ background: "none", padding: "0" }}
                            optionsPlaceholder="No Options Found"
                          />
                        ) : null}
                      </>
                    )}

                    {field?.type === "datetime" && (
                      <>
                        <AppReactSelectField
                          name="disable_dates"
                          options={selectDates}
                          label={"Select Disable Dates"}
                          displayKey="label"
                          valueKey="id"
                          isSearchable={true}
                          isClearable={true}
                          hideSelectedOptions={true}
                          divStyle={{ background: "none", padding: "0" }}
                          optionsPlaceholder="No Options Found"
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
                {field?.type === "textarea" && (
                  <>
                    <AppFormField
                      name="rows"
                      label="Rows"
                      errorMessage=""
                      labelStyle={{ color: theme.palette.default.newFontColor }}
                      placeholder="Please Enter Rows"
                    />

                    <AppFormField
                      name="cols"
                      label="Cols"
                      errorMessage=""
                      labelStyle={{ color: theme.palette.default.newFontColor }}
                      placeholder="Please Enter Cols"
                    />
                  </>
                )}
                {field?.type === "divider" && (
                  <AppFormColorPicker
                    label="Divider Color"
                    name="divider_color"
                    labelStyle={{ color: theme.palette.default.newFontColor }}
                  />
                )}

                {field?.type === "link" && (
                  <>
                    <AppFormColorPicker
                      label="Link Color"
                      name="link_color"
                      labelStyle={{ color: theme.palette.default.newFontColor }}
                    />
                    <AppFormCheckboxField
                      name="underline"
                      label={"Underline ?"}
                    />

                    <AppFormField
                      name="link_label"
                      label="Link Label"
                      errorMessage=""
                      labelStyle={{ color: theme.palette.default.newFontColor }}
                      placeholder="Please Link Title"
                    />

                    <AppFormField
                      name="link_url"
                      label="Link URL"
                      errorMessage=""
                      labelStyle={{ color: theme.palette.default.newFontColor }}
                      placeholder="Please Link URL"
                    />
                  </>
                )}
                {field?.type !== "link" && (
                  <AppFormField
                    name="placeholder"
                    errorMessage=""
                    label="Placeholder"
                    labelStyle={{ color: theme.palette.default.newFontColor }}
                    placeholder="Please Enter Placeholder"
                  />
                )}
                <AppFormField
                  name="className"
                  errorMessage=""
                  label="Class Name"
                  labelStyle={{ color: theme.palette.default.newFontColor }}
                  placeholder="Please Enter Class Name"
                />

                <AppFormField
                  name="idName"
                  errorMessage=""
                  label="ID Name"
                  labelStyle={{ color: theme.palette.default.newFontColor }}
                  placeholder="Please Enter ID"
                />
              </EditFieldsContainer>
              <ButtonsContainer>
                <div className="buttons">
                  <AppButton
                    variant="grey"
                    onClick={() => dispatch(editField(false))}
                  >
                    Cancel
                  </AppButton>
                  <AppButton
                    type="submit"
                    variant="outline"
                    startIcon="ri-save-line"
                  >
                    Save
                  </AppButton>
                </div>
              </ButtonsContainer>
              <AppFormObserver setFormikProps={setFormikProps} />
            </AppForm>
          </DraggableEditContainer>
        ) : isCustomPropertiesPreview ? (
          <CustomFieldsContainer>
            <AppAccordion label={"Custom Fields"} isDefaultOpen={true}>
              {customPropertiesArray?.map((section: any, index: number) => (
                <Fields item={section} key={index} isCustomProperty={true} />
              ))}
            </AppAccordion>
          </CustomFieldsContainer>
        ) : (
          <DraggableSectionContainer>
            <AppAccordion label="Grid Preview" isDefaultOpen={true}>
              <GridContainer>
                {gridPreview.map((grid, index: number) => (
                  <Button active={0}>
                    <button
                      key={index}
                      onClick={() => {
                        createRow(grid?.id);
                        showAlert("Row Added!", "success");
                        setIsGridViewOpen(!isGridViewOpen);
                      }}
                      ref={drag}
                    >
                      {grid.icon}
                    </button>
                  </Button>
                ))}
              </GridContainer>
            </AppAccordion>
            <AppAccordion label={"Contact Fields"} isDefaultOpen={true}>
              <React.Fragment>
                <div style={{ width: "100%", padding: "0px 8px" }}>
                  <AppForm
                    initialValues={searchData}
                    validationSchema={undefined}
                    onSubmit={() => {}}
                  >
                    <AppFormSearchField
                      name="search"
                      placeholder="Search"
                      onChange={(e: any) => {
                        handleSelectSearch(e, "search");
                      }}
                      divStyle={{
                        margin: "8px 0",
                      }}
                      clearCallback={(e: any) => clearCallback(e, "search")}
                      defaultBorder={true}
                    />
                  </AppForm>
                </div>
                {fieldsArray?.map((section: any, index: number) => (
                  <Fields item={section} key={index} />
                ))}
              </React.Fragment>
            </AppAccordion>
          </DraggableSectionContainer>
        )}
      </DraggableSection>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable-section">
          {(provided, snapshot) => (
            <DroppableSectionContainer
              isDragging={snapshot.isDraggingOver}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {formFieldsData?.length === 0 ? (
                <DragColumnInfo />
              ) : (
                <React.Fragment>
                  {formFieldsData?.map((rowFields: any, rowIndex: any) => (
                    <Draggable
                      key={rowIndex}
                      draggableId={`draggable-${rowIndex}`}
                      index={rowIndex}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={`container ${
                            snapshot.isDragging
                              ? "dragging dragging-container"
                              : ""
                          }`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="row-container">
                            <StyledImage
                              src={CloseIcon}
                              onClick={() => {
                                dispatch(removeRow(rowIndex));
                                rowFields.map((item: any) => {
                                  dispatch(deleteDroppedFieldsIds(item?.id));
                                });
                              }}
                              alt=""
                            />
                            <AppMaterialIcons
                              iconName={"DragIndicator"}
                              style={{ cursor: "grab" }}
                            />
                            <Droppable
                              droppableId={`droppable-elements-${rowIndex}`}
                              type="ELEMENT"
                            >
                              {(providedChild, snapshotChild) => (
                                <div
                                  ref={providedChild.innerRef}
                                  {...providedChild.droppableProps}
                                  style={{ display: "flex", flex: "1" }}
                                >
                                  {rowFields.map((field: any, column: any) => (
                                    <Draggable
                                      key={column}
                                      draggableId={`draggable-element-${rowIndex}-${column}`}
                                      index={column}
                                    >
                                      {(providedItem, snapshotItem) => (
                                        <div
                                          ref={providedItem.innerRef}
                                          {...providedItem.draggableProps}
                                          {...providedItem.dragHandleProps}
                                          style={{
                                            ...providedItem.draggableProps
                                              .style,
                                            display: "flex",
                                            flex: 1,
                                          }}
                                        >
                                          <DroppableRow
                                            item={field}
                                            row={rowIndex}
                                            column={column}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {providedChild.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </React.Fragment>
              )}
              {provided.placeholder}
            </DroppableSectionContainer>
          )}
        </Droppable>
      </DragDropContext>

      {/* Modal to open FormName Modal */}
      <AppModel
        isShowing={isShowing}
        onClose={() => toggle(true)}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            width: "450px",
          },
        }}
      >
        <AppForm
          initialValues={{ formName: "" }}
          onSubmit={(values: any) => {
            dispatch(saveFormName({ form_name: values?.formName }));
            toggle(false);
          }}
          validationSchema={formNameValidationSchema}
        >
          <StyledModelHeader>
            <Box>
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                Enter Form Name
              </Typography>
            </Box>
          </StyledModelHeader>
          <AppFormModalContainer direction="column">
            <GridContainer>
              <AppFormField
                name="formName"
                errorMessage=""
                label="Form Name"
                placeholder="Please Enter Form Name"
                defaultBorder={true}
                labelStyle={{ color: theme.palette.default.newFontColor }}
                requiredSign
              />
            </GridContainer>
            <div className="saveButton">
              <AppButton
                variant="grey"
                onClick={() => navigate("/forms/lists")}
              >
                Cancel
              </AppButton>
              <AppSubmitButton title="Save" variant="default" />
            </div>
          </AppFormModalContainer>
        </AppForm>
      </AppModel>
    </Container>
  );
};

const Container = styled.div`
  && {
    width: 100%;
    height: calc(100vh - 250px);
    margin: 0.8rem 1rem;
    display: flex;
    gap: 20px;
  }
`;
const DraggableSection = styled.div`
  && {
    width: 40%;
    height: calc(100vh - 250px);
    overflow-y: auto;
    background-color: ${theme.palette.default.white};
    .buttonGroup {
      margin: 1rem !important;
    }
  }
`;

const DraggableSectionContainer = styled.div`
  && {
    margin: 1rem;
    overflow-y: auto;
  }
`;

const CustomFieldsContainer = styled.div`
  && {
    margin: 1rem;
  }
`;

const DraggableEditContainer = styled.div`
  && {
    position: relative;
    .checkBoxContainer {
      display: flex;
    }
    .header {
      border-bottom: 0.5px solid ${theme.palette.default.grey};
      margin-bottom: 10px;
    }
    .header-content {
      margin: 1rem;
      display: flex;
      align-items: center;
    }
  }
`;

const StyledImage = styled.img`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const Button = styled.span`
  && {
    button {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      svg path {
        fill: ${(props: { active: boolean }) =>
          props.active
            ? `${theme.palette.primary.dark}`
            : `${theme.palette.default.darkGrey}`};
      }
    }
  }
`;
const ButtonsContainer = styled.div`
  && {
    border-top: 1px solid ${theme.palette.default.grey};
    position: absolute;
    right: 0;
    bottom: -16%;
    width: 100%;
    .buttons {
      gap: 20px;
      display: flex;
      justify-content: end;
      align-items: center;
      margin: 1rem;
    }
  }
`;

const EditFieldsContainer = styled.div`
  && {
    padding: 10px 0;
    margin: 1rem;
  }
`;
const DroppableSectionContainer = styled.div`
&& {
  width: 100%;
  height: 100%;
  background-color: ${theme.palette.default.white};
  overflow-y:auto;
  padding: 1rem;
  .dragging-container{
    background-color: ${theme.palette.default.lightError};
  };
  .container{
    position:relative;
    width:100%;
    display:flex;
    flex:1;
    img{
      position:absolute;
      top: 0;
      right:0;
    }
  }
  .row-container{
    width:100%;
    display:flex;
    align-items:center;
    margin-bottom: 40px;
    z-index:1;    
    &:hover img {
      display: block;
   }

  }
  .logo-section{
    position:relative;
    border : 1px solid ${theme.palette.default.darkGrey};
    padding:10px 0;  
    display:flex;
    justify-content:center;
    border-radius:4px;
  }
  .content{
      display:flex;
      justify-content:center;
      align-items:center;
      margin:auto;  
      gap:20px;
    }
  }
  span{
      color:${theme.palette.default.midGrey};
  }
}`;

const StyledModelHeader = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    height: 56px;
    min-height: 56px;
    align-items: center;
    padding: 0rem 1rem 0rem 1rem;
    position: sticky;
    background-color: ${theme.palette.default.background};
    top: 0;
    z-index: 9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

    h6 {
      font-size: 1.05rem;
      color: ${theme.palette.default.text};
    }

    svg {
      color: ${theme.palette.default.darkGrey};
    }

    .footer {
    }
  }
`;

const AppFormModalContainer = styled.div`
  && {
    display: flex;
    flex-direction: ${(props: { direction: string }) => props.direction};
    justify-content: start;
    padding: 30px;
    gap: 25px;
    .saveButton {
      width: 100%;
      display: flex;
      justify-content: end;
      gap: 20px;
    }
  }
`;

const GridContainer = styled.div`
  && {
    display: flex;
    gap: 10px;
    padding: 10px 0 10px 0;
  }
`;
export default Forms;
