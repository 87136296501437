import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import AppAudioComponent from "../../../../../../../AppComponents/Forms/AppAudioComponent";
import { getCallLogsRowData } from "../../../../../../../Store/Slices/Voicebot/voicebot.selectors";
import { useAppSelector } from "../../../../../../../Store";

const Container = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin: 16px 0;
`;

const Title = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  color: #101010;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #ddd;
  margin-bottom: 16px;
`;

const Recording = (): JSX.Element => {
  const callLogsRowData: any = useAppSelector(getCallLogsRowData);
  return (
    <Container>
      <Title>
        Call Recording
        <Tooltip title={"Recording"} placement="top">
          <InfoOutlined sx={{ fontSize: "16px" }} />
        </Tooltip>
      </Title>
      <Divider />
      <AppAudioComponent src={callLogsRowData?.recordingURL} />
    </Container>
  );
};

export default Recording;
