import { IconButton, MenuItem } from "@mui/material";
import React from "react";
import { AppMaterialIcons } from "../../../../../../AppComponents/AppMaterialIcons";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../../../../HOC/HocBackdropLoader";
import { useNotification } from "../../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../../../Store";
import { useAuthStore } from "../../../../../../Store/Slices/AuthSlice";
import { useCurrentUser } from "../../../../../../Store/Slices/CurrentUser/CurrentUserSlice";
import {
  allMessagesStore,
  removeAllMessages,
} from "../../../../../../Store/Slices/socket/AllMessagesSlice";
import { ChatNetworkService } from "../../../../../../Store/Slices/socket/liveChat/ChatNetworkService";
import { LiveChatData } from "../../../../../../Store/Slices/socket/liveChat/chat.selectors";
import {
  removeSessionData,
  updateSessionData,
} from "../../../../../../Store/Slices/socket/liveChat/chat.slice";
import { SOCKET_CONNECTION_TYPES } from "../../../../../utils/Enum";
import { ChatMenuWrapper } from "./ChatMenuStyle";
import NestedDropdown, {
  DropdownNestedMenuItem,
} from "./NestedMenu/NestedDropdown";
import { ProtectedAppUiElement } from "../../../../../../AppComponents/ProtectedComponents";
import useRolesPermissionsAccess from "../../../../../../Hooks/useRolesPermissionsAccess";
import { useSocket } from "../../../../../hooks/socket-context";

interface Props extends HocBackdropLoaderProps {
  currentItem: any;
}

const ChatCardMenu: React.FC<Props> = ({ setLoading, currentItem }) => {
  const { showAlert } = useNotification();

  const dispatch = useAppDispatch();
  const { allAgentsData, allLabelsData, chatSessionActiveFilter } =
    useAppSelector(LiveChatData);
  const { isUserAvailable } = useAppSelector(useCurrentUser);
  const { data: authData } = useAppSelector(useAuthStore);
  const { socket } = useSocket();
  const { sessionId } = useAppSelector(allMessagesStore);
  const { isValidAccess } = useRolesPermissionsAccess(
    "can_assign_conversations"
  );

  const handleLabelClick = (curElem: any) => {
    if (!curElem?.id) return;

    let data = {
      id: curElem?.id,
      session_id: currentItem?.session_id,
    };

    setLoading(true);
    ChatNetworkService.changeChatLabel(data)
      .then((res) => {
        let data = {
          ...currentItem,
          label: Array.isArray(res?.data?.data)
            ? res?.data?.data
            : currentItem?.label || [],
        };
        dispatch(updateSessionData(data));
        showAlert(`Label Changed to ${curElem?.name} `, "success");
      })
      .catch((err) => {
        showAlert("Something went wrong. Try again", "error");
        console.warn(err);
      })
      .finally(() => {
        setLoading(false);
        // handleClose();
      });
  };

  const handleAssignAgent = (curElem: any) => {
    if (!curElem?.id && !currentItem) return;
    let data = {
      fromAgent: currentItem?.agent,
      toAgent: curElem?.id,
      session_id: currentItem?.session_id,
    };

    socket?.emit(
      SOCKET_CONNECTION_TYPES.AGENT_REASSIGN,
      data,
      (error: any, response: any) => {
        if (error) {
          showAlert("Something went wrong. Try again", "error");
          console.warn(error);
        } else if (!error && response?.status) {
          showAlert(`Chat assigned to ${curElem?.name}`, "success");
        } else {
        }
      }
    );
    if (chatSessionActiveFilter?.conversation !== "all") {
      dispatch(removeSessionData(currentItem));
    }

    if (currentItem?.session_id === sessionId) {
      dispatch(removeAllMessages({}));
    }
  };

  const checkIfLabelAlreadyExits = (labelId: string) => {
    let isExits = false;

    if (!currentItem?.label) return isExits;

    currentItem &&
      currentItem?.label &&
      currentItem?.label?.length > 0 &&
      currentItem?.label?.forEach((curElem: any) => {
        if (curElem?.id === labelId) {
          isExits = true;
        }
      });

    return isExits;
  };

  return (
    <ChatMenuWrapper>
      <NestedDropdown
        trigger={
          <IconButton
            style={{ width: "20px", height: "20px" }}
            className="moreBtn"
          >
            <AppMaterialIcons
              iconName="MoreVert"
              style={{ fontSize: "16px" }}
            />
          </IconButton>
        }
        menu={[
          <DropdownNestedMenuItem
            label="Assign Label"
            rightIcon={
              <AppMaterialIcons
                iconName="ArrowRight"
                style={{ fontSize: "16px" }}
              />
            }
            menu={
              allLabelsData && allLabelsData?.length > 0 ? (
                allLabelsData?.map((curElem: any, index: number) => {
                  return (
                    <MenuItem
                      onClick={() =>
                        checkIfLabelAlreadyExits(curElem?.id)
                          ? undefined
                          : handleLabelClick(curElem)
                      }
                      key={index}
                      style={
                        checkIfLabelAlreadyExits(curElem?.id)
                          ? {
                              color: curElem?.color || "black",
                              opacity: 0.5,
                              cursor: "not-allowed",
                            }
                          : { color: curElem?.color || "black" }
                      }
                      selected={checkIfLabelAlreadyExits(curElem?.id)}
                    >
                      {curElem?.name?.length > 20 ? `${curElem.name.substring(0, 20)}...` : curElem.name}
                    </MenuItem>
                  );
                })
              ) : (
                <p>No labels available.</p>
              )
            }
          />,

          currentItem?.conversation_type === "livechat" &&
          currentItem?.conversation_status !== "Closed" &&
          allAgentsData?.length > 0 &&
          isValidAccess ? (
            <DropdownNestedMenuItem
              label="Assign Agent"
              rightIcon={
                <AppMaterialIcons
                  iconName="ArrowRight"
                  style={{ fontSize: "16px" }}
                />
              }
              menu={allAgentsData?.map((curElem: any, index: number) => {
                return (
                  <MenuItem
                    onClick={() =>
                      curElem?.id === currentItem?.agent ||
                      !isUserAvailable ||
                      curElem?.status === "offline"
                        ? undefined
                        : handleAssignAgent(curElem)
                    }
                    key={index}
                    selected={curElem?.id === currentItem?.agent}
                    style={
                      curElem?.id === currentItem?.agent ||
                      !isUserAvailable ||
                      curElem?.status === "offline"
                        ? {
                            color: curElem?.color,
                            opacity: 0.5,
                            cursor: "not-allowed",
                          }
                        : { color: curElem?.color }
                    }
                  >
                    {curElem?.name}
                  </MenuItem>
                );
              })}
            />
          ) : (
            // </ProtectedAppUiElement>
            <p />
          ), // cannot define null here otherwise it will show error

          authData?.data?.user?.id !== currentItem?.agent &&
          currentItem?.conversation_type === "livechat" &&
          currentItem?.conversation_status !== "Closed" ? (
            <ProtectedAppUiElement moduleId="can_assign_conversations">
              <MenuItem
                onClick={() =>
                  authData?.data?.user?.id === currentItem?.agent
                    ? undefined
                    : handleAssignAgent({ id: authData?.data?.user?.id })
                }
                disabled={
                  authData?.data?.user?.id === currentItem?.agent ||
                  currentItem?.conversation_status === "Closed" ||
                  currentItem?.conversation_type === "bot" ||
                  !isUserAvailable
                }
                style={
                  authData?.data?.user?.id === currentItem?.agent ||
                  currentItem?.conversation_status === "Closed" ||
                  !isUserAvailable
                    ? { opacity: 0.5, cursor: "not-allowed" }
                    : {}
                }
              >
                Assign to me
              </MenuItem>
            </ProtectedAppUiElement>
          ) : (
            <p />
          ),
        ]}
      />
    </ChatMenuWrapper>
  );
};

export default HocBackdropLoader(ChatCardMenu);
