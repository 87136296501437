import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { FieldArray, getIn, useFormikContext } from "formik";
import React, { memo, useEffect, useState } from "react";
import { AppFormField } from "../../../AppComponents/Forms";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import deleteIcon from "../../../assets/images/delete-icon.svg";
import AppButton from "../../../AppComponents/AppButton";
import {
  hasLostStatus,
  hasWonStatus,
} from "../DataManagement/LifecycleStage/utils";
import { useSelector } from "react-redux";
import { LifecycleStage } from "../../../Store/Slices/Settings/LifecycleStage/lifecycleStage.selectors";
import { SortableList } from "../../UiComponents/DndKitDraggable/SortableList/SortableList";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
// import { SortableList } from "../../../UiComponents/DndKitDraggable/SortableList/SortableList";
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
export enum CreateEditModes {
  CREATE = "create",
  EDIT = "edit",
}

interface Props {
  name: string;
  onDrop: (items: any[], prevIndex: number, nextIndex: number) => void;
  dependantCheck?: boolean
}
const OptionMapping: React.FC<Props> = ({ name, onDrop, dependantCheck }) => {
  const { showAlert } = useNotification()
  const { values, setFieldValue, touched } = useFormikContext<any>();
  const currentLifecycleStage = useSelector(LifecycleStage);
  const [disabledButtons, setDisabledButtons] = useState({}); // Track disabled state per button

  const onDragStart = (event: any, index: any) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const onDragOver = (event: any) => {
    event.preventDefault();
  };
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);
  const handleCopy = (index: number, text: string) => {
    if (text) {
      navigator.clipboard.writeText(text);
      setCopiedIndex(index);
      setTimeout(() => {
        setCopiedIndex(null);
      }, 9000);
    }
  };
  const removeOption = (index: any, data: any, removeFunction: any) => {
    if (dependantCheck && data?.is_dependant) {
      showAlert("This choice can't be deleted as it has a dependency on another field. Update the dependency and try again", "error")
    } else {
      removeFunction(index)
      let removeIds = values?.removeIds ? [...values?.removeIds] : []
      let ids = removeIds.concat([data.id])
      setFieldValue("removeIds", ids)
    }
  }
  return (
    <>
      <FieldArray name={name}>
        {({ insert, remove, push, swap, unshift, pop, replace }) => (
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                className="add-stage"
                variant="body2"
                sx={{ color: `${theme.palette.default.darkGrey}` }}
              >
                Choices
              </Typography>
              {/* <AppButton
                variant="outline"
                onClick={() =>
                  push({
                    id: values?.options.length + 1,
                    position: values?.options.length + 1,
                    label: "",
                    new: true
                  })
                }
              >
                <AppMaterialIcons iconName="AddOutlined" /> Add Choices
              </AppButton> */}
              <AppButton
                variant="outline"
                onClick={() =>
                  push({
                    id: "",
                    position: values?.options.length + 1,
                    label: "",
                    new: true,
                  })
                }
              >
                <AppMaterialIcons iconName="AddOutlined" /> Add Choices
              </AppButton>
            </Box>

            <SortableList
              items={getIn(values, name)}
              onChange={onDrop}
              orientation="vertical"
              renderItem={(item: any, index: number) => {
                const fieldName: string = `${name}.${index}.label`;
                return (
                  <SortableList.Item id={item.id}>
                    <div className="drag-list" style={{ width: "100%", display: "flex", gap: "8px" }}>
                      <AppFormField
                        name={fieldName}
                        placeholder="Choice Label"
                        className="field"
                        divStyle={{
                          flex: 2,
                          marginBottom: "0px",
                        }}
                      />
                      <AppFormField
                        name={`${name}.${index}.id`}
                        placeholder="ID"
                        className="field"
                        divStyle={{
                          flex: 1,
                          marginBottom: "0px",
                        }}
                        value={item?.id ? item.id : ""}
                        disabled
                      />

                      <Tooltip
                        title={copiedIndex === index ? "Copied!" : "Copy to clipboard"}
                        arrow
                      >
                        <IconButton
                          onClick={() => handleCopy(index, item?.id || "")}
                          size="small"
                          edge="end"
                          sx={{
                            color: "black",
                            padding: "5px",
                            cursor: "pointer",
                            fontSize: "18px",
                            marginLeft: "-40px",
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: "black",
                              transform: "none",
                            },
                            "&:disabled": { color: "#888" },
                          }}
                        >
                          {copiedIndex === index ? (
                            <CheckTwoToneIcon sx={{ fontSize: 18, color: "green" }} />
                          ) : (
                            <ContentCopyIcon sx={{ fontSize: 18 }} />
                          )}
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        onClick={() => removeOption(index, item, remove)}
                        component="span"
                        disabled={values?.options?.length === 1}
                      >
                        <img src={deleteIcon} />
                      </IconButton>
                      <SortableList.DragHandle />
                    </div>
                  </SortableList.Item>
                );
              }}
            />
          </Box>
        )}
      </FieldArray>
    </>
  );
};

export default memo(OptionMapping);
