import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SouthOutlinedIcon from '@mui/icons-material/SouthOutlined';
import NorthOutlinedIcon from '@mui/icons-material/NorthOutlined';
import { useLocation } from 'react-router-dom';
import { theme } from '../../../Customization/Theme';

interface SortingMenuProps {
  header: {
    column: {
      toggleSorting: (descending: boolean) => void;
      getCanSort: () => boolean;
      getIsSorted: () => string | false;
      id: string;
    };
  };
  onSortChange?: (sortData: { id: string; desc: boolean }[]) => void;
}

const ICON_BUTTON_STYLES = {
  width: "28px",
  height: "28px",
  borderRadius: "4px",
  padding: "7px",
  cursor: "pointer",
  "&:hover": {
    background: theme.palette.default.merinish,
    borderRadius: "4px",
  }
} as const;

const MENU_PAPER_PROPS = {
  sx: {
    width: "230px",
    maxWidth: "230px",
    minWidth: "230px",
    backgroundColor: theme.palette.default.white,
    borderRadius: "4px",
    boxShadow: `0 4px 16px ${theme.palette.default.borderBlack}`,
    padding: "6px 0",
  }
} as const;

const SortingMenu = React.memo(({ header, onSortChange }: SortingMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const location = useLocation();

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSort = useCallback((descending: boolean) => {
    header.column.toggleSorting(descending);
    if (onSortChange) {
      onSortChange([{ id: header.column.id, desc: descending }]);
    }
    handleClose();
  }, [header.column, onSortChange, handleClose]);

  // Get current sort state
  const sortState = header.column.getIsSorted();
  
  // Show sort indicator if column is sorted
  const getSortIcon = () => {
    return <ArrowDropDownIcon />;
  };

  return (
    <>
      {
        !location.pathname.includes("marketing") ? <IconButton
        onClick={handleClick}
        sx={ICON_BUTTON_STYLES}
      >
        {getSortIcon()}
      </IconButton> :""
      }

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: MENU_PAPER_PROPS
        }}
      >
        <MenuItem
          sx={{
            mb: 1,
            fontSize: '13px',
            backgroundColor: sortState === 'asc' ? theme.palette.default.wildSand  : 'inherit'
          }}
          onClick={() => handleSort(false)}
          disabled={!header.column.getCanSort()}
        >
          <NorthOutlinedIcon sx={{mr: 1}} style={{fontSize:"14px"}} />
          Sort ascending A → Z
        </MenuItem>
        <MenuItem
          sx={{
            fontSize: '13px',
            backgroundColor: sortState === 'desc' ? theme.palette.default.wildSand : 'inherit'
          }}
          onClick={() => handleSort(true)}
          disabled={!header.column.getCanSort()}
        >
          <SouthOutlinedIcon sx={{mr: 1}} style={{fontSize:"14px"}} />
          Sort descending Z → A
        </MenuItem>
      </Menu>
    </>
  );
});

SortingMenu.displayName = 'SortingMenu';

export default SortingMenu;