import { createAsyncThunk } from "@reduxjs/toolkit";
import { FacebookSettingsService } from "./facebookSettingsService";

export class FacebookSettingsActions {
  // Validate whatsappConfigData Settings
  static facebookConfigData = createAsyncThunk(
    "facebookSettingsSlice/facebookConfigData",
    (data: any, { dispatch, rejectWithValue }) => {
      return FacebookSettingsService.facebookConfigData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  static catalogConfigData = createAsyncThunk(
    "facebookSettingsSlice/catalogConfigData",
    (data: any, { dispatch, rejectWithValue }) => {
      return FacebookSettingsService.CatalogConfigData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  // Validate whatsappConfigData Settings
  static facebookConfigList = createAsyncThunk(
    "facebookSettingsSlice/facebookConfigList",
    ({}: any, { dispatch, rejectWithValue }) => {
      return FacebookSettingsService.getFacebookConfigData()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  // Validate whatsappConfigData Settings
  static logoutFacebookConfiguration = createAsyncThunk(
    "facebookSettingsSlice/logoutFacebookConfiguration",
    ({}: any, { dispatch, rejectWithValue }) => {
      return FacebookSettingsService.logoutFacebookConfiguration({})
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  static disconnectSingleFacebookPage = createAsyncThunk(
    "facebookSettingsSlice/disconnectSingleFacebookPage",
    (data: any, { dispatch, rejectWithValue }) => {
      return FacebookSettingsService.disconnectSingleFbPage(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );
}
