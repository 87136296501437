export enum ImageState {
  INITIAL = "initial",
  SEARCH = "search",
  GALLERY = "gallery",
  UPLOADED = "uploaded",
};

export const headerOptions = [
  {
    value: "none",
    label: "None",
  },
  {
    value: "text",
    label: "Text",
  },
  {
    value: "image",
    label: "Image",
  },
  {
    value: "video",
    label: "Video",
  },
  {
    value: "document",
    label: "Document",
  },
];

export const IMAGE_TYPES = ["image/jpeg", "image/png"];
export const VIDEO_TYPES = ["video/mp4"];
export const AUDIO_TYPES = ["audio/aac", "audio/mp4", "audio/mpeg", "audio/amr"];
export const STICKER_TYPES = ["image/webp"];

export const DOCUMENT_TYPES = [
  "text/plain",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const getCorrectMediaFormats = (mediaType: string) => {
  if (mediaType === "video") {
    return VIDEO_TYPES;
  } else if (mediaType === "image") {
    return IMAGE_TYPES;
  } else if (mediaType === "document") {
    return DOCUMENT_TYPES;
  } else if (mediaType === "sticker") {
    return STICKER_TYPES;
  } else if (mediaType === "audio") {
    return AUDIO_TYPES;
  } else {
    return [];
  }
};

export const getCorrectMediaSize = (mediaType: string) => {
  if (mediaType === "video") {
    return 16000000;
  } else if (mediaType === "image") {
    return 5000000;
  } else if (mediaType === "document") {
    return 100000000;
  } else if (mediaType === "sticker") {
    return 1000000;
  } else if (mediaType === "audio") {
    return 16000000;
  } else {
    return 500000;
  }
};

export const getInfoMessage = (mediaType: string) => {
  if (mediaType === "image") {
    return "The supported formats are - jpg, jpeg, and png. The image size must be smaller than 5MB.";
  } else if (mediaType === "video") {
    return "The supported format is mp4. The video size must be smaller than 16MB.";
  } else if (mediaType === "document") {
    return "The supported formats are - pdf. The document size must be smaller than 100MB.";
  }
};


export const getMediaInfo = (response: any, type: string) => {
  let data: any = {};
  data.type = type;
  if(type === 'text'){
    data.text = response;
  }
  if(type === 'document') {
    data.id = response.id;
    data.link = response.source;
    data.filename = response.name;
  }

  if(type === 'video') {
    data.id = response.id;
    data.link = response.source;
  }

  if(type === 'image') {
    data.id = response.id;
    data.link = response.source;
  }

  return data;
};