import { useEffect, useState } from "react";
import styled from "styled-components";
// custom imports
import {
  AppFormField,
  AppFormSwitchField
} from "../../../../../../../AppComponents/Forms";
import AppReactSelectField from "../../../../../../../AppComponents/Forms/AppFormReactSelect";
import AppFormSliderField from "../../../../../../../AppComponents/Forms/AppFormSliderField";
import { Label } from "../../../../../../../Customization/CommonElements";
import { theme } from "../../../../../../../Customization/Theme";
import { useAppSelector } from "../../../../../../../Store";
import { getVoiceBotDetails } from "../../../../../../../Store/Slices/Voicebot/voicebot.selectors";
import { getResponseBehavior } from "../../../utils";

const VoiceSpeechSettings = (): JSX.Element => {
  const [responseRate, setResponseRate] = useState<any>({});
  const botDeatils = useAppSelector(getVoiceBotDetails);
  useEffect(() => {
    if (
      botDeatils?.voicebot_configuration?.speech_setting
        ?.assistant_response_behaviour
    ) {
      setResponseRate({
        ...botDeatils?.voicebot_configuration?.speech_setting
          ?.assistant_response_behaviour,
      });
    }
  }, [botDeatils?.voicebot_configuration?.speech_setting]);

  return (
    <Container>
      <FormContent>
        <AppFormSliderField
          minValue={1}
          maxValue={400}
          name="buffer_rate"
          showValue={true}
        />
        <AppReactSelectField
          name="assistant_response_behaviour"
          options={getResponseBehavior()}
          label={"Response Rate"}
          displayKey="label"
          valueKey="id"
          isSearchable={true}
          isClearable={true}
          hideSelectedOptions={true}
          maxWidth="100%"
          divStyle={{ background: "none", padding: "0" }}
          optionsPlaceholder="No Options Found"
          tooltip={"Response Rate"}
          onChangeCallbackFn={(res: any) => {
            setResponseRate(res);
          }}
        />

        {responseRate?.id === "custom" ? (
          <>
            {" "}
            <AppFormSliderField
              minValue={0}
              maxValue={5000}
              name="endpointing"
              showValue={true}
            />
            <AppFormSliderField
              minValue={0}
              maxValue={2500}
              name="lineardelay"
              showValue={true}
            />
          </>
        ) : null}

        <div className="switch-field-container">
          <AppFormSwitchField
            label=""
            divStyle={{ width: "auto" }}
            labelStyle={{ color: "#09090B" }}
            name="check_if_user_is_online"
          />
          <div>
            <p className="header-text">Check if user is online</p>
            <p className="sub-header">
              Agent will check if the user is online if there's no reply from
              the user
            </p>
          </div>
        </div>
        <AppFormField
          name="user_online_message"
          label="User is online message"
          placeholder="Please enter call hangup logic"
        />
        <Label>Invoke message after (seconds)</Label>
        <AppFormSliderField
          name="invoke_message_rate"
          // additonalValueText="Seconds"
          showValue={true}
          minValue={5}
          maxValue={20}
        />
        {/* <div className="switch-field-container">
            <AppFormSwitchField
              label=""
              divStyle={{ width: "auto" }}
              labelStyle={{ color: "#09090B" }}
              name="allow_assistant_interruption"
            />
            <div>
              <p className="header-text">Allow Assistant Interuption</p>
              <p className="sub-header">
                If turned on, we are allowing callers to interrupt the assistant
                while the assistant is speaking. Once interrupted the assistant
                will stop speaking and listen to what the caller is saying.
              </p>
            </div>
          </div>
          <Label>No Response Time Limit</Label>
          <AppFormSliderField
            name="no_response_time_limit"
            additonalValueText="Seconds"
            showValue={true}
          /> */}
      </FormContent>
    </Container>
  );
};

export default VoiceSpeechSettings;

const Container = styled.div`
  && {
    width: 100%;
    height: 100%;
  }
`;

const FormContent = styled.div`
  && {
    padding: 1rem;
    background: ${theme.palette.default.white};
    height: 100%;
    .switch-field-container {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 20px;
    }
    .header-text{
      font-weight: 600;
    }
    .sub-header{
      font-size: 14px;
      color: ${theme.palette.default.darkGrey};
  }
`;
