// vendors
import { useState } from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { getActiveNode, getLanguage, updateModifiedData } from "../../../Slices/main.slice";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { cleanMediaDetails, getInitialEditiorText, getMediaDetails, getResponse, getText } from "../../../Utils";
import { useAppDispatch } from "../../../../../Store";
import { getChannelWiseText, getHTMLContent, getMediaDetailsForMediaNode, getPlainTextData } from "../../../Utils/editor";
import MessagePreview from "../../../Components/MessagePreview";
import Editor from "../../../Components/Editor";
import UploadSection from "../../../Components/Editor/upload";

const MediaNode = (props: NodeProps) => {
  const { channel } = useSelector(useCurrentBot);
  const { node } = props;
  const language = useSelector(getLanguage);
  const activeNode = useSelector(getActiveNode);
  const response = getResponse(node, language);  
  const text = response?.[0]?.value?.text;
  const media = getMediaDetailsForMediaNode(response);
  const [editorState, setEditorState] = useState(
    getInitialEditiorText(text || ""),
  );
  const isActiveNode = activeNode === node.nodeId;
  const dispatch = useAppDispatch();

  const onEditorStateChange = (editorState: any, mediaInfo: any) => {
    setEditorState(editorState);

    const cleanedMedia = cleanMediaDetails(mediaInfo);

    if (cleanMediaDetails(mediaInfo)) {
      dispatch(
        updateModifiedData([
          {
            id: uuid(),
            value: getChannelWiseText(getHTMLContent(editorState), channel),
            type: "text",
            media: cleanMediaDetails(mediaInfo) || {},
          },
        ]),
      );
    } else {
      dispatch(
        updateModifiedData([
          {
            id: uuid(),
            value: getChannelWiseText(getHTMLContent(editorState), channel),
            type: "text",
            media : {}
          },
        ]),
      );
    }
  };
  
  return (
    <Box p={"8px 16px"}>
      {isActiveNode ? (
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          error={getPlainTextData(editorState) === ""}
          mediaInfo={media}
          isMediaNode={true}
        />
      ) : (
        <MessagePreview text={text} mediaDetails={media} />
      )}
    </Box>
  );
};
export default MediaNode;
