import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { ReactComponent as DeleteIcon } from "../../../../../../../assets/images/deletegrey.svg";
import { ReactComponent as EditIcon } from "../../../../../../../assets/images/editIcon.svg";

import moment from "moment";
import NoDataFound from "./NoDataFound";
import AppBadge from "../../../../../../../AppComponents/AppBadge";
import { AppMaterialIcons } from "../../../../../../../AppComponents/AppMaterialIcons";
import { theme } from "../../../../../../../Customization/Theme";
import { DocumentAndTextTableWrapper, Scroll } from "../styles/styles";

interface Props {
  SourceListing: any;
  sourceType: string;
  id: any;
  open: boolean;
  setSelectedDocumentIds?: any;
  selectedDocumentIds?: any;
  anchorEl: HTMLButtonElement | null;
  handleClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    documentId: string
  ) => void;
  handleClose: () => void;
  getDocumentByID?: any;
  toggle?: any;
  setMode?: any;
  toggleConfirmModal?: any;
}

const DocumentAndTextTable: React.FC<Props> = ({
  SourceListing,
  id,
  open,
  anchorEl,
  handleClick,
  handleClose,
  getDocumentByID,
  toggle,
  setMode,
  setSelectedDocumentIds,
  selectedDocumentIds,
  sourceType,
  toggleConfirmModal,
}) => {
  // Handle Selected Values
  useEffect(() => {
    setSelectedDocumentIds([]);
  }, []);
  // Handle Single Check
  const handleSelectChanges = (
    event: React.ChangeEvent<HTMLInputElement>,
    documentId: string
  ) => {
    const isChecked = event.target.checked;
    setSelectedDocumentIds((prevSelectedIds: any) => {
      let updatedSelectedIds = isChecked
        ? [...prevSelectedIds, documentId]
        : prevSelectedIds.filter((id: any) => id !== documentId);

      return updatedSelectedIds;
    });
  };
  return (
    <>
      {SourceListing?.length ? (
        <Scroll>
          <DocumentAndTextTableWrapper>
            <Box className="table-header">
              <Box className="table-row">
                <Box className="col-head"></Box>
                <Box className="col-head">Name</Box>
                <Box className="col-head">Data Characters</Box>
                <Box className="col-head">Status</Box>
                <Box className="col-head">Created</Box>
                <Box className="col-head"></Box>
              </Box>
            </Box>
            <Box className="table-body">
              {SourceListing?.map((item: any, index: any) => {
                return (
                  <Box className="table-row" key={`${index}` + 1}>
                    <Box className="col-body">
                      <Checkbox
                        checked={selectedDocumentIds.includes(item.documentId)}
                        onChange={(event: any) =>
                          handleSelectChanges(event, item.documentId)
                        }
                        id={item?.documentId}
                        disabled={item?.status === "deleting"}
                      />
                    </Box>
                    <Box className="col-body">{item?.title}</Box>
                    <Box className="col-body">{item?.characters}</Box>
                    <Box className="col-body">
                      {item?.status ? (
                        <AppBadge
                          status={item?.status}
                          isTooltipAvaliabe={true}
                        />
                      ) : (
                        "--"
                      )}
                    </Box>
                    <Box className="col-body">
                      {moment(item?.createdOn).format("DD-MM-YYYY h:mm A")}
                    </Box>
                    <Box className="col-body">
                      <IconButton
                        aria-describedby={id}
                        onClick={(e: any) => handleClick(e, item?.documentId)}
                        disabled={item?.status === "deleting"}
                      >
                        <AppMaterialIcons iconName="MoreVert" />
                      </IconButton>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{
                          "& .MuiPaper-root": {
                            borderRadius: 1,
                            boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                          },
                        }}
                      >
                        <Box
                          sx={{ width: "100%", maxWidth: 130, minWidth: 130 }}
                        >
                          <nav aria-label="secondary mailbox folders">
                            <List
                              disablePadding
                              sx={{
                                color: `${theme.palette.default.darkGrey}`,
                              }}
                            >
                              {sourceType === "text" && (
                                <ListItem disablePadding>
                                  <ListItemButton
                                    sx={{ p: "0 2" }}
                                    onClick={() => {
                                      getDocumentByID();
                                      toggle();
                                      setMode("edit");
                                      handleClose();
                                    }}
                                  >
                                    <EditIcon />
                                    <Typography sx={{ ml: 1 }} variant="body2">
                                      Edit
                                    </Typography>
                                  </ListItemButton>
                                </ListItem>
                              )}

                              <Divider />
                              <Divider />

                              <ListItem
                                disablePadding
                                onClick={() => {
                                  toggleConfirmModal();
                                  handleClose();
                                }}
                              >
                                <ListItemButton>
                                  <DeleteIcon />
                                  <Typography sx={{ ml: 1 }} variant="body2">
                                    Delete
                                  </Typography>
                                </ListItemButton>
                              </ListItem>
                            </List>
                          </nav>
                        </Box>
                      </Popover>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </DocumentAndTextTableWrapper>
        </Scroll>
      ) : (
        <NoDataFound data={0} />
      )}
    </>
  );
};

export default DocumentAndTextTable;
