import React, { useState } from "react";
import { FormikContextType } from "formik";
import styled from "styled-components";
import AppButton from "../../../../../AppComponents/AppButton";
import AppSmallWindowPane from "../../../../../AppComponents/AppSmallWindowPane";
import {
  AppErrorMessage,
  AppForm,
  AppFormColorPicker,
  AppFormField,
  AppFormImagePicker,
  AppFormMobileField,
  AppFormObserver,
  AppFormRadioField,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import { Label, LabelDark } from "../../../../../Customization/CommonElements";
import { theme } from "../../../../../Customization/Theme";
import {
  MESSENGER_CTA_BUTTON_TPYES,
  STATIC_BUTTON_GRADIENTS,
  TELEGRAM_CTA_BUTTON_TPYES,
  WB_CTA_BUTTON_TPYES,
  CHANNELS_WIDGET_POSITION,
  BOT_CUSTOMISE_SCHEMA,
} from "../../../utils/BotConstants";
import { AxiosResponse } from "axios";
import uniqid from "uniqid";
import AppFormCheckboxField from "../../../../../AppComponents/Forms/AppFormCheckboxField";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { DefaultIndiaMobileFormat } from "../../../../../Models/defaultSelectOptions";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { ChangeSelectedBot, useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import GradientPickerField from "../../../utils/GradientPickerField";
import StaticPreview from '../../StaticPreview/Preview/StaticPreview'
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../../AppComponents/Loader";
import { capitalizeFirstLetter } from "../../../../../Utils";
import { setWhatsAppBotWelcomeText } from "../../../../../Store/Slices/botConfig/BotConfigSlice";
import { config } from "node:process";
import { setChannelName } from "../../../../../Whatsapp/Store/FlowBuilder.slice";

interface Props extends LoaderComponentProps {
  // children?: React.ReactNode;
}
const ChannelsLookFeel: React.FC<Props> = ({ setLoading }) => {
  let { showAlert } = useNotification();
  const currentBot: any = useAppSelector(useCurrentBot);
  const [formikBag, setFormikBag] = useState<FormikContextType<any>>();
  const dispatch = useAppDispatch()
  const initialValues = {
    name: "Jhon",
    company_logo: null,
    button_type: "icon",
    button_text: "Chat with us",
    prefilled_message_text:
      "Hi, I would like to know more about your products and services.",
    welcome_message_text:
      `We are here to help you! Chat with us on ${capitalizeFirstLetter(currentBot?.channel)} for any queries.`,
    subheader_text: "Typically replies within 5 mins",
    cta_button_text: "Start Conversation",
    mobile_display_position: "Right",
    desktop_display_position: "Right",
    mobile_side_margin: 20,
    mobile_bottom_margin: 40,
    desktop_side_margin: 130,
    desktop_bottom_margin: 150,
    whatsapp_number: "+919876543210",
    button_background_color: "#282828",
    primary_color: currentBot?.channel === "messenger" ? "#0084ff" : currentBot?.channel === "telegram" ? "#0088cc" : "#25D366",
    button_gradient_color: STATIC_BUTTON_GRADIENTS?.[0],
    button_gradient_enabled: true,
    mobileCountryFormat: DefaultIndiaMobileFormat,
  };

  const [userInput, setUserInput] = useState<any>(initialValues);

  const getPreviousData = () => {
    ChatbotConsoleService.getChatbotLookFeel(currentBot.id as string)
      .then((res: AxiosResponse<any>) => {
        if (res.data) {
          const bot: any = res.data.chatbot;
          const ctheme: any = res.data.chatbot_theme;
          const cconfig: any = res.data.chatbot_configuration;
          let temp = {
            name: bot?.name ?? bot,
            whatsapp_number: bot?.whatsapp_number ?? "",
            primary_color: ctheme?.primary?.main ? ctheme?.primary?.main : bot?.channel === "messenger" ? "#0084ff" : bot?.channel === "telegram" ? "#0088cc" : "#25D366",
            button_type: ctheme?.button?.button_type ?? "icon",
            button_text: ctheme?.button?.button_text ?? "Chat with us",
            button_background_color: ctheme?.button?.background ?? "#282828",
            button_gradient_enabled: ctheme?.button?.button_gradient_enabled,
            button_gradient_color:
              ctheme?.button?.button_gradient_color ??
              STATIC_BUTTON_GRADIENTS?.[0],
            company_logo: cconfig?.company_logo ?? null,
            prefilled_message_text:
              cconfig?.prefilled_message_text ??
              "Hi, I would like to know more about your products and services.",
            welcome_message_text:
              cconfig?.welcome_message_text ??
              `We are here to help you! Chat with us on ${capitalizeFirstLetter(currentBot?.channel)} for any queries.`,
            subheader_text:
              cconfig?.subheader_text ??
              "Typically replies within 5 mins",
            cta_button_text:
              cconfig?.cta_button_text ?? "Start Conversation",
            mobile_display_position:
              cconfig?.mobile_display_position ?? "Right",
            desktop_display_position:
              cconfig?.desktop_display_position ?? "Right",
            mobile_side_margin: cconfig?.mobile_side_margin ?? 8,
            mobile_bottom_margin: cconfig?.mobile_bottom_margin ?? 8,
            desktop_side_margin: cconfig?.desktop_side_margin ?? 8,
            desktop_bottom_margin: cconfig?.desktop_bottom_margin ?? 8,
            channel: bot.channel,
          };
          dispatch(setWhatsAppBotWelcomeText(temp?.prefilled_message_text))
          setUserInput(temp);
          dispatch(setChannelName(bot?.channel));
        }
      }).finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (currentBot?.id) {
      getPreviousData();
    } else {
      setLoading(false);
    }
  }, [currentBot]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitBtn = (values: any, submitProps: any) => {
    let data: any = {
      chatbot: {
        name: values.name,
      },
      chatbot_theme: {
        primary: {
          main: values.primary_color,
        },
        button: {
          button_type: values.button_type,
          button_text: values.button_text,
          background: values?.button_background_color,
          button_gradient_enabled: values?.button_gradient_enabled,
          button_gradient_color: values?.button_gradient_color,
        },
      },
      chatbot_configuration: {
        prefilled_message_text: values.prefilled_message_text,
        welcome_message_text: values.welcome_message_text,
        subheader_text: values.subheader_text,
        cta_button_text: values.cta_button_text,
        mobile_display_position: values.mobile_display_position.value,
        desktop_display_position: values.desktop_display_position.value,
        mobile_side_margin: values.mobile_side_margin,
        mobile_bottom_margin: values.mobile_bottom_margin,
        desktop_side_margin: values.desktop_side_margin,
        desktop_bottom_margin: values.desktop_bottom_margin,
      },
    };
    if (currentBot?.channel === "whatsapp") {
      dispatch(setWhatsAppBotWelcomeText(values?.prefilled_message_text))
      data.chatbot = {
        ...data?.chatbot,
        whatsapp_number: values?.whatsapp_number
      }
      data.chatbot_configuration = {
        ...data?.chatbot_configuration,
        company_logo: values?.company_logo?.id
      }
    }
    ChatbotConsoleService.changeLookAndFeel(data, [currentBot?.id as string])
      .then((res: any) => {
        showAlert("Configuration Saved!", "success");
      }).catch((error: any) => {
        showAlert("something went wrong", "error");
      }).finally(() => {
        submitProps?.setSubmitting(false);
      });
  };

  const handleCancelClick = () => {
    formikBag?.resetForm();
  };
  React.useEffect(() => {
    if (formikBag?.values?.button_gradient_enabled) {
      formikBag?.setFieldValue(
        "primary_color",
        formikBag?.values?.button_gradient_color?.endColor ??
        STATIC_BUTTON_GRADIENTS?.[0]?.endColor
      );
    }
  }, [  // eslint-disable-line react-hooks/exhaustive-deps
    formikBag?.values?.button_gradient_enabled,
    formikBag?.values?.button_gradient_color,
  ]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <StyledPageWrapper>
      <AppSmallWindowPane
        style={{
          minWidth: "65%",
          borderRadius: "8px",
          padding: "1.1rem 1.5rem 0rem 1.5rem",
          position: "relative",
        }}
      >
        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmitBtn(values, submitProps);
          }}
          validationSchema={BOT_CUSTOMISE_SCHEMA}
          intialTouched
        >
          {currentBot?.channel === "whatsapp" &&
            <AppFormImagePicker
              label="Upload a Company Logo"
              name="company_logo"
              tooltip={
                <ul>
                  <li>Recommended size 64*64 px</li>
                  <li>Your logo must be smaller than 1 MB</li>
                  <li>A PNG, JPEG or JPG works best</li>
                </ul>
              }
              sizeLimit={1000000}
              sizeErrorMsg={"Image size must be smaller than 1MB."}
              formatErrorMsg={"image should be PNG, JPEG or JPG."}
            />
          }
          <AppFormField
            label="Bot Name"
            name="name"
            placeholder="Travel Booking Bot"
            disabled={currentBot?.channel === "messenger" || currentBot?.channel === "telegram"}
          />
          <AppFormRadioField
            label={"Button"}
            name={"button_type"}
            Options={currentBot?.channel === "messenger" ? MESSENGER_CTA_BUTTON_TPYES : currentBot?.channel === "telegram" ? TELEGRAM_CTA_BUTTON_TPYES : WB_CTA_BUTTON_TPYES}
            row={true}
            labelStyle={{ marginBottom: "12px" }}
          />

          {formikBag?.values?.button_type?.value === "button" ? (
            <AppFormField
              label="Button Text"
              name="button_text"
              placeholder="Chat with us"
            />
          ) : null}

          <AppFormColorPicker
            label="Primary Color"
            name="primary_color"
            disabled={formikBag?.values?.button_gradient_enabled}
          />
          <AppFormCheckboxField
            label={"Use Predefined Gradients"}
            name="button_gradient_enabled"
            divStyle={{ marginTop: "-0.5rem" }}
          />

          {formikBag?.values?.button_gradient_enabled ? (
            <GradientPickerField
              name="button_gradient_color"
              options={STATIC_BUTTON_GRADIENTS}
              divStyle={{ marginTop: "-1rem", marginBottom: "1rem" }}
            />
          ) : null}

          <AppFormTextArea
            label="Welcome Message"
            name="welcome_message_text"
            rows={2}
            placeholder={`We are here to help you! Chat with us on ${capitalizeFirstLetter(currentBot?.channel)} for any queries.`}
          />

          <AppFormField
            label="Sub-Header Text"
            name="subheader_text"
            placeholder="Typically replies within 5mins"
          />
          {currentBot?.channel === "whatsapp" &&
            <AppFormField
              label="Pre-Filled Message"
              name="prefilled_message_text"
              placeholder="Hi, I would like to know more about your products and services"
            />
          }
          <AppFormField
            label="Call To Action Button Text"
            name="cta_button_text"
            placeholder="Start Conversation"
          />
          {currentBot?.channel === "whatsapp" &&
            <AppFormMobileField
              label="Whatsapp Number"
              name="whatsapp_number"
              placeholder="+91"
              mobileCountryFormat="mobileCountryFormat"
              disabled={true}
            />
          }

          <LabelDark style={{ margin: "1rem 0px" }}>
            Set Your Widget Position
          </LabelDark>

          <AppFormRadioField
            label={"Desktop Widget Position"}
            name={"desktop_display_position"}
            Options={CHANNELS_WIDGET_POSITION}
            row={true}
          />
          <div className="sameRowFields">
            <div className="newFieldWrapper">
              <Label>
                Desktop side spacing
                <AppFormField
                  divStyle={{
                    width: "70px",
                    marginBottom: 0,
                    marginLeft: "6px",
                  }}
                  name="desktop_side_margin"
                  placeholder="10"
                  type="number"
                  customClassNameInput="customClassNameInput"
                  showError={false}
                />
                px
              </Label>
              <AppErrorMessage
                visible={true}
                error={formikBag?.errors?.desktop_side_margin}
              />
            </div>
            <div className="newFieldWrapper">
              <Label>
                Desktop bottom spacing
                <AppFormField
                  divStyle={{
                    width: "70px",
                    marginBottom: 0,
                    marginLeft: "6px",
                  }}
                  name="desktop_bottom_margin"
                  placeholder="10"
                  type="number"
                  customClassNameInput="customClassNameInput"
                  showError={false}
                />
                px
              </Label>
              <AppErrorMessage
                visible={true}
                error={formikBag?.errors?.desktop_bottom_margin}
              />
            </div>
          </div>
          <AppFormRadioField
            label={"Mobile Widget Position"}
            name={"mobile_display_position"}
            Options={CHANNELS_WIDGET_POSITION}
            row={true}
          />
          <div className="sameRowFields">
            <div className="newFieldWrapper">
              <Label>
                Mobile side spacing
                <AppFormField
                  divStyle={{
                    width: "70px",
                    marginBottom: 0,
                    marginLeft: "6px",
                  }}
                  name="mobile_side_margin"
                  placeholder="10"
                  type="number"
                  customClassNameInput="customClassNameInput"
                  showError={false}
                />
                px
              </Label>
              <AppErrorMessage
                visible={true}
                error={formikBag?.errors?.mobile_side_margin}
              />
            </div>
            <div className="newFieldWrapper">
              <Label>
                Mobile bottom spacing
                <AppFormField
                  divStyle={{
                    width: "70px",
                    marginBottom: 0,
                    marginLeft: "6px",
                  }}
                  name="mobile_bottom_margin"
                  placeholder="10"
                  type="number"
                  customClassNameInput="customClassNameInput"
                  showError={false}
                />
                px
              </Label>
              <AppErrorMessage
                visible={true}
                error={formikBag?.errors?.mobile_bottom_margin}
              />
            </div>
          </div>

          <AppFormObserver setFormikProps={setFormikBag} />

          <div className="formActionBox">
            <AppButton
              variant="grey"
              style={{ width: "20%" }}
              onClick={() => handleCancelClick()}
            >
              Cancel
            </AppButton>
            <AppSubmitButton
              variant="submit"
              style={{ width: "25%", margin: "0.5rem 0" }}
              title={"Save"}
            />
          </div>
        </AppForm>
      </AppSmallWindowPane>

      <StaticPreview
        name={formikBag?.values?.name}
        company_logo={formikBag?.values?.company_logo?.source}
        subheader_text={formikBag?.values?.subheader_text}
        button_text={formikBag?.values?.button_text}
        button_gradient_color={formikBag?.values?.button_gradient_color}
        button_gradient_enabled={formikBag?.values?.button_gradient_enabled}
        button_type={formikBag?.values?.button_type?.value}
        primary_color={formikBag?.values?.primary_color}
        prefilled_message_text={
          formikBag?.values?.prefilled_message_text
        }
        welcome_message_text={
          formikBag?.values?.welcome_message_text
        }
        whatsapp_number={formikBag?.values?.whatsapp_number}
        startButtonText={formikBag?.values?.cta_button_text}
        key={uniqid()}
      />
    </StyledPageWrapper>
  );
};

export default withLoader(ChannelsLookFeel);

const StyledPageWrapper = styled.div`
  && {
    width: 100%;
    height: 100%;
    /* padding: 1rem; */
    background-color: ${theme.palette.secondary.main};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    .formActionBox {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
      padding: 0.7rem 0rem;
      position: sticky;
      bottom: 0;
      z-index: 1;
      border-top: 1px solid ${theme.palette.default.border};
      background-color: ${theme.palette.default.white};
    }

    .sameRowFields {
      width: 100%;
      margin-top: -12px;
      padding: 0px;
      display: flex;
      flex-direction: row;
      gap: 3rem;
      justify-content: flex-start;
    }
    .newFieldWrapper {
      width: 35%;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .customClassNameInput {
      padding-right: 4px;
      min-width: 70px;
    }

    .buttonColorBox {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      margin-bottom: 16px;
    }
  }
`;