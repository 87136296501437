// vendors
import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

// components
import UIButton from "../../Components/Button";
import Icon from "../../Components/Icon";

// slices
import { getActiveNode } from "../../Slices/main.slice";

// utils
import { getIcon } from "./helper";
import { theme } from "../../../../Customization/Theme";
import { AppToolTip } from "../../../../Components/UiComponents/AppToolTip/AppToolTip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";

type Props = {
  buttonList: any;
  onButtonClick: (buttonIndex: number) => void;
};
const BUTTONS_LIMIT = 5;

const ButtonPreview = (props: Props) => {
  const { buttonList, onButtonClick } = props;
  const activeNodeId = useSelector(getActiveNode);
  const [buttonsList, updatesButtonsList] = useState(buttonList.slice(0, BUTTONS_LIMIT));

  const isEmptyAction = (button: any) => {
    return button.type === "";
  };

  const renderButtonList = () => {
    return buttonsList.map((button: any, index: number) => {
      const type = button?.value?.flow_type
        ? button?.value?.flow_type
        : button.type;
      const stage = button.type === "flow" ? button.value.stage : "";
      const icon = getIcon(type);
      return (
        <Box
          sx={{ mt: index === 0 ? 0 : "8px" }}
          key={`preview-button-${activeNodeId}-${index + 1}`}
        >
          <UIButton
            key={`preview-button-${activeNodeId}-${index + 1}`}
            variant="outlined"
            onClick={() => onButtonClick(index)}
            fullWidth
            color={isEmptyAction(button) ? "warning" : "primary"}
            style={{ borderRadius: "1rem", backgroundColor: "#fff" }}
          >
            <Tooltip title={button.value.text}>
            <Box component="span">
              {button.value.text?.length > 18
                ? `${button.value.text.slice(0, 18)}...`
                : button.value.text}
            </Box>
            </Tooltip>
            {button.value.description?.length > 0 && (
              <AppToolTip
                tooltipProps={{
                  title: button.value.description,
                  placement: "top",
                }}
                className="chip"
                iconButtonProps={{ disableRipple: true }}
                IconComponent={<InfoOutlinedIcon style={{ width: "13px" }} />}
              />
            )}
            <Box
              component="span"
              sx={{
                display: "inline-flex",
                alignItems: "center",
                gap: "10px",
                marginLeft: "auto",
              }}
            >
              {stage && (
                <Typography
                  component={"span"}
                  sx={{
                    marginLeft: "10px",
                    fontStyle: "italic",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "20px",
                    backgroundColor: "rgb(117 124 232 / 38%)",
                    color: "#3654E3",
                    p: "1px 5px",
                    borderRadius: "4px",
                  }}
                >
                  {`Stage ${stage}`}
                </Typography>
              )}
              <Icon icon={icon} size={16} />
            </Box>
          </UIButton>
        </Box>
      );
    });
  };

  const handleShowAllClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    updatesButtonsList(buttonList);
    event.stopPropagation();
  };

  const handleShowLessClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    updatesButtonsList(buttonList.slice(0, BUTTONS_LIMIT));
    event.stopPropagation();
  };

  const renderShowButtonsWithLimit = () => (
    <Box sx={{ mt: "10px" }}>
      {buttonsList.length === BUTTONS_LIMIT ? (
        <UIButton
          fullWidth
          onClick={handleShowAllClick}
        >{`Show all ${buttonList.length} buttons`}</UIButton>
      ) : (
        <UIButton
          fullWidth
          onClick={handleShowLessClick}
        >{`Show less`}</UIButton>
      )}
    </Box>
  );

  return Array.isArray(buttonList) && buttonList.length > 0 ? (
    <Box
      sx={{
        p: "16px",
        m: "1rem",
        backgroundColor: theme.palette.default.lightGrey,
      }}
    >
      {renderButtonList()}
      {buttonList.length > BUTTONS_LIMIT && renderShowButtonsWithLimit()}
    </Box>
  ) : null;
};
export default ButtonPreview;
