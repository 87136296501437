import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";
import { AppToolTip } from "../UiComponents/AppToolTip/AppToolTip";
import { TextTitle } from "./AnalyticsSummaryStyles";
import { DonoughtWrapper, ParentDonoughtWrapper } from "./BotConvDounoutstyle";
import {
  SkeletonCircleIconDoughnut
} from "./SkeletonLoader.tsx/SkeletonCard";
interface Props {
  agent?: any;
  loading?: any;
}

const AgentStatusDoughnout: React.FC<Props> = ({ agent, loading }) => {
  const data = {
    labels: ["Closed", "Open", "Pending"],
    datasets: [
      {
        label: "# of Votes",
        data: agent ?? [],
        backgroundColor: ["#ff4545", "#feb019", "#008ffb"],
        borderWidth: 4,
      },
    ],
  };

  const chartData: any = {
    series: agent,
    options: {
      chart: {
        type: "donut",
        // width: 270,
        events: {
          dataPointSelection: function (event: any, chartContext: any, config: any) {
            if (!chartContext || !config || !config.w) return;
            const selectedLabel = config.w.globals.labels[config.dataPointIndex];
            const value = config.w.globals.series[config.dataPointIndex];
            const updatedLabel = `${selectedLabel}`;
            
            setTimeout(() => {
              chartContext.updateOptions({
                plotOptions: {
                  pie: {
                    expandOnClick: false,
                    donut: {
                      size: "70%",
                      labels: {
                        show: true,
                        total: {
                          show: true,
                          showAlways: true,
                          label: updatedLabel,
                          formatter: function (w: any) {
                            return `${value}`;
                          },
                        },
                      },
                    },
                  },
                },
              });
            }, 0);
          },
        },
      },
      labels: ["Closed", "Open", "Pending"],
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: { width: 300 },
            legend: { position: "bottom" }, // Same as chartData1
          },
        },
        {
          breakpoint: 3000,
          options: {
            chart: { width: 300 },
          },
        },
      ],
      dataLabels: { enabled: false },
      fill: { colors: ["#ff4545", "#feb019", "#008ffb"] },
      colors: ["#ff4545", "#feb019", "#008ffb"],
      legend: {
        position: "left",
        show: true,
      },
      states: {
        hover: { filter: { type: "lighten", value: 0.5 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 0 },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: "Total",
              },
            },
          },
        },
      },
    },
  };
  
  return (
    <ParentDonoughtWrapper  style={{height: 'calc(100% - 47px)'}}>
      <div className="tooltip_header">
        <p className="dounoht_header">
          <TextTitle>Agent Conversations Status</TextTitle>
          <AppToolTip
            tooltipProps={{
              title: "Agent Conversation Metrics",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
          />
        </p>
      </div>
      <DonoughtWrapper
      style={{
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center'
      }}
        isdata={
          data.datasets[0].data[0] === 0 && data.datasets[0].data[1] === 0
        }
      >
        {loading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <DoughnutLoading style={{ maxWidth: '114px', margin: 0}}>
                        <SkeletonCircleIconDoughnut />   
                    </DoughnutLoading>
                  </div>
        ) : data.datasets[0].data[0] === 0 &&
          data?.datasets?.[0]?.data?.[1] === 0 ? (
          <NoPreview>No Preview</NoPreview>
        ) : (
          <div className="donou_graph">
            {agent?.length > 0 ? (
              <ReactApexChart
                type="donut"
                series={chartData.series}
                options={chartData.options}
                className="graph"
              />
            ) : (
              "No Data"
            )}
          </div>
        )}
      </DonoughtWrapper>
    </ParentDonoughtWrapper>
  );
};

export default AgentStatusDoughnout;

const NoPreview = styled.div`
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DoughnutLoading = styled.div`
  min-width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  > span {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
