import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { debounce } from "@mui/material";
import { ReactComponent as DeleteIcon } from "../../../../../../../../assets/images/delete.svg";
import { ReactComponent as SearchIcon } from "../../../../../../../../assets/images/Searchicon.svg";
import { useAppDispatch, useAppSelector } from "../../../../../../../../Store";
import {
  SourceList,
  SourceListCount,
} from "../../../../../../../../Store/Slices/Faq/Source/source.selectors";
import useModal from "../../../../../../../../Hooks/useModel/useModel";
import { useNotification } from "../../../../../../../../Hooks/useNotification/useNotification";
import { FaqSourceAction } from "../../../../../../../../Store/Slices/Faq/Source/source.actions";
import { FaqTextWrap, StyledAppInput } from "../../styles/styles";
import AppButton from "../../../../../../../../AppComponents/AppButton";
import WebsiteAndSitemapTable from "../../commonComponents/WebsiteAndSitemapTable";
import { AppMaterialIcons } from "../../../../../../../../AppComponents/AppMaterialIcons";
import WebsiteAndSitemapAddModal from "../../commonComponents/WebsiteAndSitemapAddModal";
import DeleteConfirmationModal from "../../commonComponents/DeleteConfirmationModal";
import AppPaginationComponent from "../../../../../../../../AppComponents/Pagination/Pagination";
import { withLoader } from "../../../../../../../../AppComponents/Loader";
import styled from "styled-components";

const VoiceSitemap: React.FC = (props: any) => {
  const sourceType = "sitemap";
  const sourceListCount = useAppSelector(SourceListCount);
  const params = useParams();
  const { isShowing, toggle } = useModal();
  let { showAlert } = useNotification();
  let dispatch = useAppDispatch();
  let websiteUrlData = useAppSelector(SourceList);
  // selected ides
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<any>([]);

  const { bot_id } = params;

  // modals
  const ConfirmModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingConfirmModal: isShowing, toggleConfirmModal: toggle };
  };
  const { isShowingConfirmModal, toggleConfirmModal } = ConfirmModal();
  // Setting Values Locally
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: { label: "10 per page", value: 10 },
    currentPage: 0,
    query: "",
    checkbox: [],
    checkboxAll: false,
  });

  // api call to get website list
  const getWebsiteList = () => {
    props.setLoading(true);
    const HEADERS = {
      appid: JSON.stringify({
        botid: bot_id,
      }),
    };
    const data: any = {
      HEADERS,
      sourceType,
      offset: localValues.currentPage,
      limit: localValues.itemsPerPage.value,
      query: localValues.query,
    };
    dispatch(FaqSourceAction.GeteSourceListing(data))
      .then((res: any) => {
        props.setLoading(false);
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {
        props.setLoading(false);
      });
  };
  // Function To Delete website
  const deletSourceByDocumentId = () => {
    const HEADERS = {
      appid: JSON.stringify({
        botid: bot_id,
      }),
    };
    let documentIds;
    if (selectedDocumentIds) {
      documentIds = selectedDocumentIds;
    } else {
      documentIds = selectedDocumentIds;
    }
    const data = {
      HEADERS,
      documentIds,
    };

    dispatch(FaqSourceAction.DeleteSourceByIds(data))
      .then((res: any) => {
        if (res?.payload?.status === 200) {
          getWebsiteList();
        } else {
          showAlert("Error", "error");
        }
      })
      .catch((error: any) => {
        showAlert(error, "error");
      });
    setSelectedDocumentIds([]);
    toggleConfirmModal();
  };

  // Handle Pagination Functions
  const handlePageChange = (event: any, value: any) => {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  };
  const handleChangeRowsPerPage = (event: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: event,
      currentPage: 0,
    });
  };

  // render list data*************

  useEffect(() => {
    if (websiteUrlData?.lenght === 0 || websiteUrlData?.lenght === undefined) {
      getWebsiteList();
    } else {
      if (websiteUrlData?.lenght > 0) {
        getWebsiteList();
      }
    }
  }, [localValues]);

  // Hadnling Search Functionality
  const handleSelectSearch = debounce((e?: any) => {
    setLocalValues({
      ...localValues,
      query: e.target.value,
      currentPage: 0,
    });
  }, 700);

  return (
    <Container>
      <div className="right-section-header">
        <p>Sitemap</p>
        <AppButton>Save Changes</AppButton>
      </div>
      <FaqTextWrap width={"640px"}>
        <div className="top-header">
          <div className="header-left">
            <p>
              Leverage your website's sitemap for an intelligent bot! Share the
              sitemap and witness the magic unfold. Our bot will utilize it as a
              knowledge base to respond accurately to user queries. Try it out
              now!
            </p>
          </div>
          <div className="serach-class">
            {selectedDocumentIds.length ? (
              <span className="delete-button">
                <AppButton
                  onClick={() => toggleConfirmModal()}
                  variant="danger-filled"
                >
                  Delete <DeleteIcon />
                </AppButton>
              </span>
            ) : null}
            <div className="faq-search">
              <StyledAppInput>
                <input
                  type="text"
                  onChange={(e: any) => handleSelectSearch(e)}
                  name="query"
                  id="query"
                  placeholder="Search"
                />
                <SearchIcon />
              </StyledAppInput>
            </div>
            <div className="button">
              <AppButton onClick={toggle}>
                Crawl Sitemap
                <AppMaterialIcons iconName="LinkOutlined" />
              </AppButton>
            </div>
          </div>
        </div>

        {/* URL Listing Table */}
        <WebsiteAndSitemapTable
          type="sitemap"
          listData={websiteUrlData || []} //websit list data
          setSelectedDocumentIds={setSelectedDocumentIds}
          selectedDocumentIds={selectedDocumentIds}
          setLocalValues={setLocalValues}
        />

        {/* Modal To Add Website's Sitemap URL */}
        <WebsiteAndSitemapAddModal
          toggle={toggle}
          isShowing={isShowing}
          type="sitemap"
          getListingData={setLocalValues}
        />

        {/* Delete Confirmation Modal */}
        <DeleteConfirmationModal
          toggleConfirmModal={toggleConfirmModal}
          deletSourceByDocumentId={deletSourceByDocumentId}
          selectedDocumentIds={selectedDocumentIds}
          isShowingConfirmModal={isShowingConfirmModal}
          type={"Url"}
        />
      </FaqTextWrap>
      {/* pagination */}
      {true ? (
        <AppPaginationComponent
          totalCount={100}
          handlePageChange={handlePageChange}
          currentPage={1}
          totalPages={10}
          rowsPerPage={10}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
    </Container>
  );
};

export default withLoader(VoiceSitemap);

const Container = styled.div`
  .right-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
`;
