import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  AppFormField,
  AppFormSwitchField,
  AppFormTextArea
} from "../../../../../../../AppComponents/Forms";
import AppReactSelectField from "../../../../../../../AppComponents/Forms/AppFormReactSelect";
import AppFormSliderField from "../../../../../../../AppComponents/Forms/AppFormSliderField";
import { Label } from "../../../../../../../Customization/CommonElements";
import { theme } from "../../../../../../../Customization/Theme";
import { useAppSelector } from "../../../../../../../Store";
import { getVoiceBotDetails } from "../../../../../../../Store/Slices/Voicebot/voicebot.selectors";
import {
  getCallHangUpLogicOptions,
  getCallTerminationOptions,
} from "../../../utils";

const VoiceCallSettings = (): JSX.Element => {
  const [callHangup, setCallHangUp] = useState<any>({});
  const [callHangupTime, setCallHangupTime] = useState<number | null>(null);
  const [callTerminationTime, setCallTerminationTime] = useState<number | null>(
    null
  );
  const botDeatils = useAppSelector(getVoiceBotDetails);
  useEffect(() => {
    if (botDeatils?.voicebot_configuration?.call_setting) {
      setCallHangupTime(
        botDeatils?.voicebot_configuration?.call_setting?.call_hangup_time
      );
      setCallTerminationTime(
        botDeatils?.voicebot_configuration?.call_setting?.call_termination_time
      );
      setCallHangUp(
        botDeatils?.voicebot_configuration?.call_setting?.call_hangup_logic
      );
    }
  }, [botDeatils?.voicebot_configuration?.speech_setting]); //eslint-disable-line

  return (
    <Container>
      <FormContent>
        <div className="switch-field-container">
          <AppFormSwitchField
            label=""
            divStyle={{ width: "auto" }}
            labelStyle={{ color: "#09090B" }}
            name="voicemail"
          />
          <div>
            <p className="header-text">Voicemail</p>
            <p className="sub-header">
              Voicemail Detects answering machines or voicemails and disconnects
              the call without deducting minutes from your quota.
            </p>
          </div>
        </div>
        <Label>Max Call Duration</Label>
        <AppFormSliderField
          name="max_call_duration"
          additonalValueText="minutes"
          showValue={true}
          minValue={1}
          maxValue={20}
        />
        {/* <AppReactSelectField
            name="select_options"
            options={[]}
            label={"Assistant's Response Behavior"}
            displayKey="label"
            valueKey="id"
            isSearchable={true}
            isClearable={true}
            isMulti={true}
            hideSelectedOptions={true}
            divStyle={{ background: "none", padding: "0", width: "100%" }}
            optionsPlaceholder="No Options Found"
            tooltip={"Assistant's Response Behavior"}
          />

          <Label>No Response Time Limit</Label>
          <AppFormSliderField
            name="no_response_time_limit"
            additonalValueText="Seconds"
            showValue={true}
          /> */}

        {/* <div className="switch-field-container">
            <AppFormSwitchField
              label=""
              divStyle={{ width: "auto" }}
              labelStyle={{ color: "#09090B" }}
              name="allow_assistant_interruption"
            />
            <div>
              <p className="header-text">Check if user is online</p>
              <p className="sub-header">
                Agent will check if the user is online if there's no reply from
                the user
              </p>
            </div>
          </div>
          <AppFormField
            name="call_hangup_logic"
            label="User is online message"
            tooltip={"Call hangup logic"}
            placeholder="Please enter call hangup logic"
          />
          <Label>Invoke message after</Label>
          <AppFormSliderField
            name="invoke_message_after"
            additonalValueText="Seconds"
            showValue={true}
          /> */}

        <AppReactSelectField
          name="call_hangup_logic"
          options={getCallHangUpLogicOptions()}
          label={"Call hangup logic"}
          displayKey="label"
          valueKey="id"
          isSearchable={true}
          maxWidth="100%"
          isClearable={true}
          hideSelectedOptions={true}
          divStyle={{ background: "none", padding: "0", width: "100%" }}
          optionsPlaceholder="No Options Found"
          tooltip={"Assistant's Response Behavior"}
          onChangeCallbackFn={(value: any) => {
            setCallHangUp(value);
          }}
        />
        {callHangup?.id === "call_hangs_up_on_silence" ? (
          <a style={{ opacity: "38%" }}>
            {" "}
            Call hangs up on silence for {callHangupTime} seconds{" "}
          </a>
        ) : null}
        {callHangup?.id === "call_hangs_up_on_silence" ? (
          <div style={{ marginTop: "5px" }}>
            {" "}
            <Label>Time</Label>
            <AppFormSliderField
              name="call_hangup_time"
              additonalValueText="Seconds"
              showValue={true}
              minValue={1}
              maxValue={30}
              onChangeCallbackFn={(value: number) => setCallHangupTime(value)}
            />{" "}
          </div>
        ) : (
          <AppFormTextArea
            name="call_hangup_prompt"
            placeholder="Please enter script"
            rows={3}
            label="Call hangup prompt"
            tooltip={"Please enter call hangup prompt"}
            labelStyle={{ color: "#000" }}
          />
        )}

        <AppFormField
          name="call_hangup_message"
          label="Call hangup message"
          tooltip={"Call hangup message"}
          placeholder="Please enter call hangup message"
          requiredSign
        />

        {/* <AppReactSelectField
          name="call_termination"
          options={getCallTerminationOptions()}
          label={"Call Termination"}
          displayKey="label"
          valueKey="id"
          isSearchable={true}
          isClearable={true}
          // isMulti={true}
          hideSelectedOptions={true}
          maxWidth="100%"
          divStyle={{ background: "none", padding: "0" }}
          optionsPlaceholder="No Options Found"
          tooltip={"Call Terminations"}
        /> */}
        <a style={{ opacity: "38%" }}>
          The call ends after {callTerminationTime} seconds for call time{" "}
        </a>
        <Label>Time</Label>
        <AppFormSliderField
          name="call_termination_time"
          additonalValueText="Seconds"
          showValue={true}
          minValue={30}
          maxValue={1200}
          onChangeCallbackFn={(value: number) => setCallTerminationTime(value)}
        />
      </FormContent>
      {/* </AppForm> */}
    </Container>
  );
};

export default VoiceCallSettings;

const Container = styled.div`
  && {
    width: 100%;
    height: 100%;
  }
`;

const FormContent = styled.div`
  && {
    padding: 1rem;
    background: ${theme.palette.default.white};
    height: calc(100vh - 200px);  
    overflow-y: auto;
    .switch-field-container {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 20px;
    }
    .header-text{
      font-weight: 600;
    }
    .sub-header{
      font-size: 14px;
      color: ${theme.palette.default.darkGrey};
  }
`;
