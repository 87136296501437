let BASE_URL = process.env
  .REACT_APP_S3BUCKET_PUBLIC_ASSETS_BASE_URL
  ? process.env.REACT_APP_S3BUCKET_PUBLIC_ASSETS_BASE_URL
  : "https://assets.getalabs.com/";

BASE_URL = BASE_URL + "assets/";

function getPublicAssets(path: string) {
  return BASE_URL + path;
}

export default getPublicAssets;
