import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Typography,
  debounce,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppButton from "../../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import AppModel from "../../../../../Hooks/useModel/AppModel";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { FaqSourceAction } from "../../../../../Store/Slices/Faq/Source/source.actions";
import { ReactComponent as DoneIcon } from "../../../../../assets/images/done.svg";
import { ReactComponent as FailedIcon } from "../../../../../assets/images/failed_red.svg";
import nodata from "../../../../../assets/images/noDatafound.svg";
import { ReactComponent as ReatainIcon } from "../../../../../assets/images/retain.svg";
import {
  FaqWebsiteWrap,
  Scroll,
  ShowAllLinkTable,
  StyledAppInput,
} from "../../BotKnowledgeStyles";
import AppTablePagination from "../../../../../AppComponents/AppTablePagination";
import { theme } from "../../../../../Customization/Theme";
import useModal from "../../../../../Hooks/useModel/useModel";
import {
  CrawlLinkList,
  crawlLinkPagination,
} from "../../../../../Store/Slices/Faq/Source/source.selectors";
import { ReactComponent as SearchIcon } from "../../../../../assets/images/Searchicon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/deletegrey.svg";
import { ReactComponent as ProgressIcon } from "../../../../../assets/images/progress.svg";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import FaqLoader from "./FaqLoader";
import AppPaginationComponent from "../../../../../AppComponents/Pagination/Pagination";

interface Props {
  toggleModalShowAllLinks: () => void;
  isShowingAllLinks: boolean;
  type: string;
  selectedRow: any;
  setSelectedRow: any;
}

const ShowAllLinksCommonTable = (props: Props) => {
  const {
    toggleModalShowAllLinks,
    isShowingAllLinks,
    type,
    selectedRow,
    setSelectedRow,
  } = props;
  const params = useParams();
  const { bot_id } = params;
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);

  const urlList = useAppSelector(CrawlLinkList);
  const pagination = useAppSelector(crawlLinkPagination);
  // Modals
  const ConfirmModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingConfirmModal: isShowing, toggleConfirmModal: toggle };
  };
  const { isShowingConfirmModal, toggleConfirmModal } = ConfirmModal();

  // Search Websites Values
  const [rowData, setRowData] = useState({
    documentId: "",
  });
  const [count, setCount] = useState(0);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<any>([]);
  // Action PopOver for Table
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [localValues, setLocalValues] = useState<any>({
    offset: 0,
    limit: { label: "50 per page", value: 50 },
    crawlUrl: "",
    query: "",
    checkbox: [],
    checkboxAll: false,
  });

  // api auth headers
  const headers = {
    appid: JSON.stringify({
      botid: bot_id,
    }),
  };
  // payload to fetch data
  let payLoad_data = {
    sourceId: selectedRow?.sourceId,
    offset: localValues?.offset,
    query: localValues?.query,
    limit: localValues.limit.value,
    headers: headers,
  };
  // Close Actions for table
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // single select
  const handleSelectChanges = (
    event: React.ChangeEvent<HTMLInputElement>,
    documentId: string
  ) => {
    setRowData({
      documentId: "",
    });
    const isChecked = event.target.checked;

    setSelectedDocumentIds((prevSelectedIds: any) => {
      let updatedSelectedIds = isChecked
        ? [...prevSelectedIds, documentId]
        : prevSelectedIds.filter((id: any) => id !== documentId);
      return updatedSelectedIds;
    });
  };

  // Action Click for table
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setRowData(item);
    setSelectedDocumentIds([]);
  };

  useEffect(() => {
    setSelectedDocumentIds([]);
    if (selectedRow && selectedRow?.sourceId && isShowingAllLinks) {
      if (type === "website") {
        getCrawlUrls(payLoad_data);
      } else {
        getSiteMapCrawlUrls(payLoad_data);
      }
    }
  }, [selectedRow, localValues]);

  const LoaderStatus = () => {
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  // api call for sitemap ************
  const getSiteMapCrawlUrls = (payLoad_data: any) => {
    setLoader(true);
    dispatch(FaqSourceAction.GetSiteMapCrawlUrls(payLoad_data))
      .then((res: any) => {
        if (res?.payload?.data?.error) {
          showAlert(res?.payload?.data?.error, "error");
          LoaderStatus();
        } else {
          if (res?.payload?.data && !res?.payload?.data?.error) {
            setCount(res?.payload?.data?.count);
            LoaderStatus();
          }
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  };

  // crwal website api ****
  const getCrawlUrls = (payLoad_data: any) => {
    setLoader(true);
    dispatch(FaqSourceAction.GetWebsitesCrawlUrls(payLoad_data))
      .then((res: any) => {
        if (res?.payload?.data?.error) {
          showAlert(res?.payload?.data?.error, "error");
          setTimeout(() => {
            LoaderStatus();
          }, 2000);
        } else {
          if (res?.payload?.data && !res?.payload?.data?.error) {
            setCount(res?.payload?.data?.count);
            LoaderStatus();
          }
        }
      })
      .catch((error: any) => {})
      .finally(() => {});
  };

  // reTry url api call
  const reTrain = (status: any) => {
    const payload = {
      urls: { documentId: rowData.documentId },
      headers: headers,
    };

    dispatch(FaqSourceAction.PostSelectedSubUrl(payload))
      .then((res: any) => {
        if (res?.payload?.data?.error) {
          showAlert(res?.payload?.data?.error, "error");
        } else {
          if (res?.payload?.data && !res?.payload?.data?.error) {
            showAlert(
              `URL ${status === "failed" ? "Retry" : "Retrain"}  Successfully!`,
              "success"
            );
            handleClose();
            getCrawlUrls(payLoad_data);
          }
        }
      })
      .catch((error: any) => {
        showAlert("submit false", "error");
      })
      .finally(() => {});
  };

  // Function To Delete
  const deletSourceByDocumentId = () => {
    let ids = selectedDocumentIds.length
      ? selectedDocumentIds
      : [rowData?.documentId];
    const HEADERS = {
      appid: JSON.stringify({
        botid: bot_id,
      }),
    };
    const data = {
      HEADERS,
      documentIds: ids,
    };
    dispatch(FaqSourceAction.DeleteSourceByIds(data))
      .then((res: any) => {
        if (res?.payload?.status === 200) {
          showAlert("Source Url Is Deleted", "success");
          // toggleModalShowAllLinks();
          toggleConfirmModal();
          getCrawlUrls(payLoad_data);
          setLocalValues((prev: any) => ({
            ...prev,
          }));
          setSelectedDocumentIds([]);
          handleClose();
        } else {
          showAlert("Error", "error");
        }
      })
      .catch((error: any) => {
        showAlert(error, "error");
      });
  };

  // pagination
  const handleFetchMoreData = (p: any, value: number) => {
    setLocalValues({
      ...localValues,
      offset: value - 1,
    });
  };

  // Hadnling Search Functionality
  const handleSelectSearch = debounce((e?: any) => {
    setLocalValues({
      ...localValues,
      query: e.target.value,
      offset: 0,
    });
  }, 700);

  // on reset
  const onReset = () => {
    setLocalValues({
      ...localValues,
      offset: 0,
      query: "",
      checkbox: [],
      checkboxAll: false,
    });
    setRowData({
      documentId: "",
    });
    setSelectedRow({});
    setSelectedDocumentIds([]);
  };

  const renderStatus = (status: any) => {
    if (status === "ready") {
      return (
        <Box className="status">
          <DoneIcon />
          Ready
        </Box>
      );
    } else if (status === "failed") {
      return (
        <Box
          sx={{
            border: `1px solid ${theme.palette.default.error}`,
            padding: "1px 16px",
            background: `${theme.palette.default.errorLighter}`,
            color: `${theme.palette.default.error}`,
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <FailedIcon />
          Failed
        </Box>
      );
    } else if (status === "uploaded") {
      return <Box className="status-uploaded">Uploaded</Box>;
    } else if (status === "pending") {
      return (
        <Box className="in-progress">
          <ProgressIcon />
          In-Progress
        </Box>
      );
    } else {
      return (
        <Box className="in-progress">
          <ProgressIcon />
          {status}
        </Box>
      );
    }
  };
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      limit: value,
    });
    // setShouldApply(true);
  };
  return (
    <AppModel
      isShowing={isShowingAllLinks}
      onClose={(e: React.MouseEvent<HTMLButtonElement>) => {
        onReset();
      }}
      divStyle={{
        width: "100%",
        gap: "0.5rem",
        overflowY: "hidden",
        padding: "1rem 1rem",
      }}
    >
      <>
        <FaqWebsiteWrap>
          <div className="container">
            <div className="header" style={{borderBottom: 'none'}}>
              <h5>{selectedRow?.url || ""}</h5>
              <p>Total URL: {count}</p>
            </div>
            <div
              className="serach-class"
              style={{ display: "flex", gap: "10px", alignItems: "end" }}
            >
              <div className="button">
                {selectedDocumentIds?.length ? (
                  <AppButton
                    onClick={() => toggleConfirmModal()}
                    variant="danger-filled"
                  >
                    Delete <DeleteIcon />
                  </AppButton>
                ) : null}
              </div>
              <div className="faq-search">
                <StyledAppInput>
                  <input
                    type="text"
                    onChange={(e: any) => handleSelectSearch(e)}
                    name="query"
                    id="query"
                    placeholder="Search By URL"
                  />
                  <SearchIcon />
                </StyledAppInput>
              </div>
              <div className="header-right">
                <IconButton
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    onReset();
                    toggleModalShowAllLinks();
                  }}
                >
                  <AppMaterialIcons iconName="close" />
                </IconButton>
              </div>
            </div>
          </div>

          {/* --------------no data image------------- */}
          {/* Table Start */}

          {!urlList || (urlList?.length === 0 && !loader) ? (
            <ShowAllLinkTable>
              <Divider />
              <div
                className="front-image"
                style={{ height: "calc(100vh - 408px)" }}
              >
                <div className="no-data-img">
                  <img src={nodata} alt="Description about" />
                </div>
                <div className="front-text">No Data found</div>
              </div>
            </ShowAllLinkTable>
          ) : (
            <Scroll>
              <ShowAllLinkTable>
                <Box className="table-header">
                  <Box className="table-row">
                    <Box className="col-head">URL</Box>
                    <Box className="col-head">Data Characters</Box>
                    <Box className="col-head">Status</Box>
                    <Box className="col-head"></Box>
                  </Box>
                </Box>
                <Box className="table-body" style={{ overflowY: "scroll" }}>
                  {loader && <FaqLoader />}
                  {urlList &&
                    !loader &&
                    urlList?.map((item: any) => {
                      return (
                        <Box className="table-row">
                          <Box className="col-body">
                            <Checkbox
                              checked={selectedDocumentIds.includes(
                                item.documentId
                              )}
                              onChange={(event: any) =>
                                handleSelectChanges(event, item.documentId)
                              }
                              id={item?.documentId}
                            />
                          </Box>
                          <Box className="col-body url">{item?.url}</Box>

                          <Box className="col-body">
                            {item?.characters || ""}
                          </Box>
                          <Box className="col-body">
                            {renderStatus(item?.status)}
                          </Box>
                          <Box className="col-body">
                            <IconButton
                              onClick={(e) => handleClick(e, item)}
                              aria-describedby={id}
                            >
                              <AppMaterialIcons iconName="MoreVert" />
                            </IconButton>

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              elevation={0}
                              sx={{
                                "& .MuiPaper-root": {
                                  boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.1)",
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  maxWidth: 130,
                                  minWidth: 130,
                                }}
                              >
                                <nav aria-label="secondary mailbox folders">
                                  <List
                                    disablePadding
                                    sx={{
                                      color: `${theme.palette.default.darkGrey}`,
                                    }}
                                  >
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          reTrain(item?.status);
                                        }}
                                      >
                                        <ReatainIcon />
                                        <Typography
                                          sx={{ ml: 1 }}
                                          variant="body2"
                                        >
                                          {item?.status?.toLowerCase() ===
                                          "failed"
                                            ? "Retry"
                                            : "Retrain"}
                                        </Typography>
                                      </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          toggleConfirmModal();
                                        }}
                                      >
                                        <DeleteIcon />
                                        <Typography
                                          sx={{ ml: 1 }}
                                          variant="body2"
                                        >
                                          Delete
                                        </Typography>
                                      </ListItemButton>
                                    </ListItem>
                                  </List>
                                </nav>
                              </Box>
                            </Popover>
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
              </ShowAllLinkTable>
            </Scroll>
          )}

          {/* pagination */}
          {urlList?.length ? (
            <AppPaginationComponent
              totalCount={pagination.totalRecords}
              handlePageChange={handleFetchMoreData}
              currentPage={localValues?.offset}
              totalPages={pagination?.totalPages}
              rowsPerPage={localValues?.limit}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              hideCount={true}
            />
          ) : null}

          <DeleteConfirmationModal
            toggleConfirmModal={toggleConfirmModal}
            deletSourceByDocumentId={deletSourceByDocumentId}
            selectedDocumentIds={selectedDocumentIds}
            isShowingConfirmModal={isShowingConfirmModal}
            type={"Url"}
          />
        </FaqWebsiteWrap>
      </>
    </AppModel>
  );
};

export default ShowAllLinksCommonTable;
