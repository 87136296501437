import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Box, debounce, IconButton, MenuItem, Select } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  AppForm,
  AppFormField,
} from "../../../../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../../../../AppComponents/Forms/AppFormSearchField";
import { theme } from "../../../../../../../Customization/Theme";
import { useAppDispatch, useAppSelector } from "../../../../../../../Store";
import { VoiceBotActions } from "../../../../../../../Store/Slices/Voicebot/voicebot.actions";
import { getGenders, getLanguageOptions } from "../../../utils";
import {
  getVoice,
  getVoiceBotDetails,
  getVoiceList,
  getVoiceListPagination,
} from "../../../../../../../Store/Slices/Voicebot/voicebot.selectors";
import AppButton from "../../../../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../../../../AppComponents/AppMaterialIcons";
import AppModel from "../../../../../../../Hooks/useModel/AppModel";
import { useParams } from "react-router-dom";
import { useNotification } from "../../../../../../../Hooks/useNotification/useNotification";
import AppPaginationComponent from "../../../../../../../AppComponents/Pagination/Pagination";
import NoRecordsFound from "../../../../../../UiComponents/NoRecordsFound/NoRecordsFound";
import { useFormikContext } from "formik";
import { setVoice } from "../../../../../../../Store/Slices/Voicebot/voicebot.slice";

const selectStyles = {
  marginBottom: "0px",
  height: "28px",
  fontSize: "14px",
  fontWeight: 400,
  width: "100%",
  maxWidth: "auto",
  minWidth: "100%",
};

const VoicesSettings: React.FC = () => {
  const [localValues, setLocalValues] = useState<any>({
    search: "",
    limit: 10,
    offset: 0,
    specification: "",
    gender: "",
    itemsPerPage: { label: "10  per page", value: 10 },
    currentPage: 0,
  });
  const voiceList = useAppSelector(getVoiceList);
  const pagination = useAppSelector(getVoiceListPagination);
  const voiceData = useAppSelector(getVoice)
  const { botId }: any = useParams();
  const { showAlert } = useNotification();
  const botDetails = useAppSelector(getVoiceBotDetails);
  const [languageOpen, setLanguageOpen] = useState(false);
  const { errors, touched, setFieldValue, values, setFieldError } =
    useFormikContext<any>();
  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
      currentPage : 0
    });
  }, 700);

  const clearCallback = (e?: any, field?: any) => {
    console.log(e, "e");
    console.log(field, "field");
    setLocalValues({
      ...localValues,
      [field]: "",
    });
  };

  const handleSelectChange = (e: any, field: any) => {
    setLocalValues({
      ...localValues,
      [field]: e.target.value,
    });
  };

  const renderGenderSelectIcon = localValues?.gender ? (
    <GridCloseIcon fontSize="small" />
  ) : null;

  const renderLanguageSelectIcon = localValues?.specification ? (
    <GridCloseIcon fontSize="small" />
  ) : null;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      VoiceBotActions.getVoicesList({
        limit: localValues?.itemsPerPage?.value,
        offset: localValues?.currentPage,
        specification: localValues?.specification,
        gender: localValues?.gender,
        search: localValues?.search,
      })
    );
  }, [localValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUseVoice = (row: any) => {
    dispatch(setVoice(row))
    showAlert("Voice set Successfully!", "success");
    return true
    // const payload = {
    //   ...botDetails,
    //   voicebot: {
    //     ...botDetails?.voicebot,
    //     voice: row,
    //   },
    // };
    // dispatch(VoiceBotActions.updateBotDetails({ data: payload, botId }))
    //   .then((response: any) => {
    //     if (response.payload.data.voicebot) {
    //       dispatch(VoiceBotActions.getBotDeatils(botId));
    //       showAlert("Voice used Successfully!", "success");
    //     }
    //   })
    //   .catch((error: any) => {
    //     console.error(error);
    //   });
  };


  // All About Pagination
  function handlePageChange(event: any, value: any) {
    console.log(value, "value");
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };
  // Function to check if a voice is the currently active one
  const isActiveVoice = (voice: any) => {
    if (!botDetails?.voicebot?.voice) return false;

    // Check if the voice IDs match
    return voice.id === botDetails.voicebot.voice.id;
  };

  console.log(voiceList, "voiceList");

  return (
    <>
      <Box>
        <AppForm
          initialValues={{}}
          onSubmit={() => { }}
          divStyle={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FiltersWrapper>
            <div>
              <p style={{ fontWeight: 500 }}>Voices</p>
              <AppFormSearchField
                divStyle={{
                  marginBottom: "0",
                  border: "1px solid rgb(203, 205, 211)",
                  borderRadius: "4px",
                  width: "200px",
                }}
                name="message-search"
                placeholder="Search"
                onChange={(e: any) => handleSelectSearch(e, "search")}
                clearCallback={(e: any) => clearCallback(e, "search")}
              />
              <p style={{ fontWeight: 500 }}>Language </p>
              <NewNativeSelect style={{ width: "200px" }}>
                <Select
                  defaultValue={undefined}
                  name="status"
                  value={localValues?.specification || ""}
                  onChange={(e) => handleSelectChange(e, "specification")}
                  displayEmpty
                  className="new-select-main mb0"
                  style={selectStyles}
                >
                  <MenuItem value="" disabled>
                    Select Status
                  </MenuItem>
                  {getLanguageOptions()?.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                <div
                  className="clear-icon"
                  onClick={(e: any) => clearCallback(e, "specification")}
                >
                  {renderLanguageSelectIcon}
                </div>
              </NewNativeSelect>

              <p style={{ fontWeight: 500 }}>Gender </p>
              <NewNativeSelect style={{ width: "200px" }}>
                <Select
                  defaultValue={undefined}
                  name="status"
                  value={localValues?.gender || ""}
                  onChange={(e) => handleSelectChange(e, "gender")}
                  displayEmpty
                  className="new-select-main mb0"
                  style={selectStyles}
                >
                  <MenuItem value="" disabled>
                    Select Gender
                  </MenuItem>
                  {getGenders()?.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                <div
                  className="clear-icon"
                  onClick={(e: any) => clearCallback(e, "gender")}
                >
                  {renderGenderSelectIcon}
                </div>
              </NewNativeSelect>
            </div>
          </FiltersWrapper>
        </AppForm>
        <TableContainer>
          {
            !voiceList?.length ? <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%" >
              <NoRecordsFound /> </Box> : <Table>

              <thead>
                <tr>
                  <TableHeader>Audio Sample</TableHeader>
                  <TableHeader>Avatar</TableHeader>
                  <TableHeader>Name</TableHeader>
                  <TableHeader>Specification</TableHeader>
                  <TableHeader>Provider</TableHeader>
                  <TableHeader>Use Voice</TableHeader>
                </tr>
              </thead>
              <tbody>
                {voiceList?.map((voice: any, index: number) => (
                  <TableRow key={index} className={isActiveVoice(voice) ? "active-row" : ""}>
                    <TableCell style={{ paddingLeft: "50px" }}>
                      <IconButton onClick={() => setLanguageOpen(true)}>
                        <PlayCircleOutlineIcon sx={{ color: "#000" }} />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Avatar src={voice.avatar} />
                    </TableCell>
                    <TableCell>{voice.name}</TableCell>
                    <TableCell>
                      {voice?.Locale && <Tag key={"1"}>{voice?.Locale}</Tag>}
                      {voice?.language && <Tag key={"2"}>{voice?.language}</Tag>}
                    </TableCell>
                    <TableCell>
                      <ProviderButtonOutline type="button">
                        {voice.provider}
                      </ProviderButtonOutline>
                    </TableCell>
                    <TableCell>
                      {isActiveVoice(voice) || voice?.id === voiceData?.id ? (
                        <Box display="flex" alignItems="center" justifyContent="center">
                          <CheckCircleIcon
                            style={{ color: "#25926A", fontSize: "1.7rem" }}
                          />
                        </Box>
                      ) : (
                        <AppButton
                          onClick={() => {
                            handleUseVoice(voice);
                          }}
                          variant="primary"
                        >
                          Use Voice
                        </AppButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          }
          {/* Choose Voice Agent Model */}
          <AppModel
            isShowing={languageOpen}
            onClose={() => {
              setLanguageOpen(false);
            }}
            divStyle={{
              padding: 0,
            }}
            PaperProps={{
              style: {
                boxShadow: "none",
                width: "30%",
                height: "30%",
              },
            }}
          >
            <AppForm
              initialValues={{ audio: "" }}
              onSubmit={() => { }}
              validationSchema={undefined}
            >
              <ModalContainer>
                <div className="header">
                  <h4>Voice</h4>
                  <div className="header-right">
                    <span
                      onClick={() => {
                        setLanguageOpen(false);
                      }}
                    >
                      <AppMaterialIcons iconName="close" />
                    </span>
                  </div>
                </div>
                <div className="body">
                  <p>AdaMultilingual</p>
                  <p className="provider-info">English (United Kingdom) accent</p>
                  <p className="provider-info">by azuretts</p>
                  <div className="fieldsContainer">
                    <AppFormField
                      name="audio"
                      label=""
                      placeholder="Please enter your text here"
                    />
                    <IconButton onClick={() => console.log("clicked")}>
                      <PlayCircleOutlineIcon
                        sx={{
                          color: theme.palette.default.darkGrey,
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
              </ModalContainer>

            </AppForm>
          </AppModel>
        </TableContainer>


      </Box>
      {voiceList?.length ?
        <AppPaginationComponent
          totalCount={pagination?.totalRecords}
          handlePageChange={handlePageChange}
          currentPage={localValues?.currentPage}
          totalPages={pagination?.totalPages}
          rowsPerPage={localValues?.itemsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
        : null
      }
    </>
  );
};

export default VoicesSettings;

// Styled Components
const TableContainer = styled.div`
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  height: calc(100vh - 400px);
  overflow: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  background-color: #fff;
`;

const TableHeader = styled.td`
  background-color: #fff;
  padding: 12px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
`;

const TableRow = styled.tr`
  background-color: #fff;  
  &.active-row {
    background-color: rgba(37, 146, 106, 0.1);
  }
`;

const TableCell = styled.td`
  padding: 20px 12px;
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const Tag = styled.span`
  display: inline-block;
  margin-right: 8px;
  padding: 4px 8px;
  background-color: #f0f0f0;
  border-radius: 8px;
  font-size: 12px;
  color: #555;
`;

const ProviderButton = styled.button`
  background-color: #2c2c54;
  color: white;
  padding: 8px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #40407a;
  }
`;

const ProviderButtonOutline = styled.button`
  border: 1px solid #2c2c54;
  color: #2c2c54;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  background-color: #fff;
  &:hover {
    background-color: #40407a;
    color: #fff;
  }
`;

const FiltersWrapper = styled.div`
  && {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 1rem;
    gap: 1rem;
    div {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const NewNativeSelect = styled.div`
  position: relative;
  width: 100%;
  .mb0 {
    margin-bottom: 0px !important;
  }
  .new-select-main {
    max-width: 210px;
    min-width: 180px;
    width: 210px;
    height: 35px;
    background-color: #f5f6f8;
    border: 1px solid rgb(203, 205, 211);
    border-radius: 4px;

    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }
  }
  .clear-icon {
    background-color: ${theme.palette.default.lightGrey};
    position: absolute;
    top: 1px;
    right: 10px;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
    padding:0 0.8rem;
    .header-left {
      display: flex;
      gap: 0.875rem;
      }
      .text h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .text p {
        color: ${theme.palette.default.darkGrey};
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }
  .content{
    margin:10px 0;
    padding: 10px 15px;
}
  .body {
    padding: 0.8rem;
    height: calc(100% - 10px);
    p {
    margin-bottom: 20px;
    font-size: 16px;
    }
    .provider-info {
      font-size: 14px;
      color: ${theme.palette.default.darkGrey};
      margin: 2px;
    }
    .fieldsContainer{
    display:flex;
    align-items:center;
    gap:0.5rem
  }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
    border-top: 1px solid ${theme.palette.default.border};
    .change-template-name {
      display: flex;
      justify-content: space-between;
      align-items: start;

      p.change-name {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;
