import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class VoiceBotNetworkService {
  // get  Key
  static getBotDeatils(botid: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_VOICE_DEATILS}${botid}/`);
  }

  // Create Voice Bot
  static createVoiceBot(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.CREATE_VOICE_BOT}`, data);
  }

  static updateVoiceBot(data: any, botid: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_VOICE_BOT}${botid}/`,
      data
    );
  }

  static getVoiceBotLogs(data:any) {
    return http.post(`${consoleBaseUrl}${Endpoints.GET_VOICE_BOT_LOGS}`,data);
  }
  static getVoicesList = (params = {}) => {
    return http.get(`${consoleBaseUrl}/${Endpoints.VOICE_LIST}`, { params });
  };
}
class Endpoints {
  static CREATE_VOICE_BOT = "/chatbot/voice/setup/";
  static GET_VOICE_DEATILS = "/chatbot/voice/details/";
  static UPDATE_VOICE_BOT = "/chatbot/voice/update/";
  static GET_VOICE_BOT_LOGS = "/workspace/analytics/call-logs";
  static VOICE_LIST = "chatbot/voice/list/";
}
