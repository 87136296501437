import { Divider, Skeleton } from "@mui/material";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import AppButton from "../../../../../AppComponents/AppButton";
import { theme } from "../../../../../Customization/Theme";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { ContactsActions } from "../../../../../Store/Slices/Contacts/contacts.action";
import {
  contactSectionData,
  contactSectionFieldsData,
} from "../../../../../Store/Slices/Contacts/contacts.selector";
import EditContactsBasicInformation from "./EditContactBasicInformation";

export default function ContactsBasicInformation(props: any) {
  const { contactSectionsArray, contactId, isHavingEditPermission } = props;
  // hooks
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const sectionData: any = useAppSelector(contactSectionData);
  const sectionFieldsData: any = useAppSelector(contactSectionFieldsData);
  const [value, setValue] = React.useState<string>(
    contactSectionsArray?.length
      ? contactSectionsArray[0]?.id
      : "contact_information"
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [editMode, setEditMode] = React.useState<boolean>(false);
  // const [details, setDetails] = React.useState<any>([]);
  const getData = () => {
    let payload = {
      contact_id: contactId,
      section_id: value,
    };
    dispatch(ContactsActions.getSectionDetails(payload))
      .then(() => {})
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {
        setIsLoading(false);
      })
      .finally(() => {});
  };
  React.useEffect(() => {
    getData();
  }, [value,contactId]); // eslint-disable-line react-hooks/exhaustive-deps
  var postCount = 0;
  return (
    <>
      <SectionList>
        <SectionHeader>
          {contactSectionsArray.map((item: any, index: number) => {
            return (
              <AppButton
                onClick={() => {
                  setValue(item.id);
                  setEditMode(false);
                }}
                key={"contact-tab-" + index}
                className={`section-item ${value === item.id ? "active" : ""}`}
              >
                {item?.label}
              </AppButton>
            );
          })}
        </SectionHeader>
        {!editMode && (
          <SectionFooter>
            {isHavingEditPermission ? (
              <AppButton onClick={() => setEditMode(true)}>Edit</AppButton>
            ) : null}
          </SectionFooter>
        )}
      </SectionList>
      <SectionContent>
        {!isLoading && !editMode && sectionData?.length > 0 ? (
          sectionData.map((item: any, index: number) => {
            postCount = postCount + 1;
            if ((postCount + 1) % 5 === 0) {
              postCount = 0;
            }

            return (
              <>
                <SectionItem key={`${value}-id-${item.label}-${index}`}>
                  <label>{item.label}</label>
                  <div
                    className="section-value"
                    title={getValue(item?.value, item?.type)}
                  >
                    {getValue(item?.value, item?.type)}
                  </div>
                </SectionItem>
                {postCount % 4 === 0 && <Divider style={{ width: "100%" }} />}
              </>
            );
          })
        ) : isLoading ? (
          <>
            <SectionItem>
              <Skeleton variant="rectangular" width={"100%"} height={"24px"} />
            </SectionItem>
            <SectionItem>
              <Skeleton variant="rectangular" width={"100%"} height={"24px"} />
            </SectionItem>
            <SectionItem>
              <Skeleton variant="rectangular" width={"100%"} height={"24px"} />
            </SectionItem>
            <SectionItem>
              <Skeleton variant="rectangular" width={"100%"} height={"24px"} />
            </SectionItem>
            <SectionItem>
              <Skeleton variant="rectangular" width={"100%"} height={"24px"} />
            </SectionItem>
            <SectionItem>
              <Skeleton variant="rectangular" width={"100%"} height={"24px"} />
            </SectionItem>
            <SectionItem>
              <Skeleton variant="rectangular" width={"100%"} height={"24px"} />
            </SectionItem>
            <SectionItem>
              <Skeleton variant="rectangular" width={"100%"} height={"24px"} />
            </SectionItem>
          </>
        ) : (
          <EditContactsBasicInformation
            setEditMode={() => setEditMode(false)}
            selectedSectionID={value}
            updateData={getData}
          />
        )}
      </SectionContent>
    </>
  );
}
const getValue = (value: any, type: string) => {
  if (type === "landline" && value !== "") {
    return value;
  } else if (type === "multiselect") {
    if (value.length > 0) {
      return value.map(
        (res: any, index: number) =>
          res + (index + 1 === value.length ? " " : ", ")
      );
    } else {
      return "---";
    }
  } else if (type === "phone" && value !== "") {
    return value?.extension !== "None" && value?.extension
      ? value?.extension + value?.number
      : "+" + value;
  } else if (typeof value === "string" && value !== "") {
    // if (type === "datetime" || type === "date") {
    //   console.log("valuevaluevalue",value);

    //   return `${moment(value).format("DD MMM YYYY")} ${moment(value).format(
    //     "HH:MM:A"
    //   )}`;
    // }
    return value;
  } else if (value?.length) {
    return value;
  } else if (typeof value === "boolean") {
    return value.toString();
  } else {
    return "---";
  }
};
const SectionFooter = styled.div`
  display: flex;
  // border-top: 1px solid ${theme.palette.default.border};
  padding: 0;
  justify-content: flex-end;
  margin-left: auto;
`;
const SectionContent = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 24px;
  }
`;

const SectionHeader = styled.div`
  max-width: calc(100% - 100px);
  overflow-x: auto;
  display: flex;
`;

const SectionList = styled.div`
  && {
    display: flex;
    gap: 32px;
    padding: 15px 29px 0 29px;
    border-bottom: 1px solid #7e839233;
    .section-item {
      background: none;
      color: #7e8392;
      font-size: 13px;
      border: none;
      border-bottom: 2px solid transparent;
      padding: 10px 10px 20px !important;
      border-radius: 0;
      min-width: auto;
      height: 40px;
      &:hover,
      &.active {
        color: ${theme.palette.primary.main};
        border-color: ${theme.palette.primary.main};
      }
      &.active {
        font-weight: 500;
      }
    }
  }
`;
const SectionItem = styled.div`
  && {
    width: calc(25% - 20px);

    label {
      font-size: 13px;
      color: #101010;
      font-weight: 500;
    }
    .section-value {
      font-size: 13px;
      color: #7e8392;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
