import React from "react";
import {Typography, Box, IconButton } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "../../../assets/images/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/icons/delete.svg";
import { ReactComponent as InfoIcon } from "../../../assets/images/infoicon.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ActionIcons = styled.div`
  display: flex;
  gap: 8px;
`;

interface Api {
  id: string;
  name: string;
  created_time: string;
  modified_time: string;
}

interface ApiCardProps {
  api: Api;
  handleDeleteModal:any;
}

const ApiCard: React.FC<ApiCardProps> = ({ api,handleDeleteModal }) => {
  const navigate = useNavigate();
  const handleEditClick = (id: string) => {
    navigate(`/api/library/edit/${id}`);
  };
  return (
    <>
      <Box className="table-row">
        <Box
          className={`col-body `}
          style={{
            minWidth: `150px`,
          }}
        >
          <Typography className="table-text"> {api.name}</Typography>
        </Box>
        <Box
          className={`col-body `}
          style={{
            minWidth: `150px`,
          }}
        >
          <Typography className="table-text">
            {moment(api.created_time).format("DD-MM-YYYY h:mm A")}
          </Typography>
        </Box>
        <Box
          className={`col-body `}
          style={{
            minWidth: `150px`,
          }}
        >
          <Typography className="table-text">
            {moment(api.modified_time).format("DD-MM-YYYY h:mm A")}
          </Typography>
        </Box>
        <Box
          className={`col-body `}
          style={{
            minWidth: `150px`,
          }}
        >
          <ActionIcons>
            {/* <IconButton aria-label="info" color="primary">
              <InfoIcon />
            </IconButton> */}
            <IconButton
              aria-label="edit"
              onClick={() => handleEditClick(api?.id)}
              color="primary"
            >
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete"  onClick={() => handleDeleteModal(api?.id)} color="primary">
              <DeleteIcon />
            </IconButton>
          </ActionIcons>
        </Box>
      </Box>
    </>
  );
};

export default ApiCard;
