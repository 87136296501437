import { useMemo } from "react";

const SUPPORT_BOT_GETA_HOST:string=process.env.REACT_APP_SUPPORT_BOT_GETA_HOST!;
const SUPPORT_BOT_MOTOMO_ID:string = process.env.REACT_APP_SUPPORT_BOT_MOTOMO_ID!;
const SUPPORT_BOT_ID:string  = process.env.REACT_APP_SUPPORT_BOT_ID!;
const IFRAMEURL: string =
process.env.REACT_APP_IFRAME_SCRIPT_BASE_URL! || "http://localhost:8080";

const scriptId = `getaScript_${SUPPORT_BOT_ID}_support_chat`;
const iframeId = `geta_${SUPPORT_BOT_ID}`;
const modifedIframeId = `geta_${SUPPORT_BOT_ID}_support_chat`;
const iframeClass = `geta-iframe-wrapper-support-chat`;

const html = `
          (function(w, d, s) {
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s);
            j.async = true;
    
            j.onload = function() {
              var geta = window.GetA("${SUPPORT_BOT_GETA_HOST}", ${SUPPORT_BOT_MOTOMO_ID}, "${SUPPORT_BOT_ID}");
    
              setTimeout(() => {
                var iframe = document.getElementById("${iframeId}");
                if (iframe) {
                  iframe.classList.add("${iframeClass}");
                  // ✅ Assign a new ID dynamically
                  iframe.id = "${modifedIframeId}"
                }
              }, 500); // Ensures iframe is available before adding class
            };
    
            j.src = "${IFRAMEURL}/output.js";
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script');
        `;

const useSupportChatWidgetScript = () => {
  const addScript = () => {
    if (
      document.getElementById(scriptId) ||
      document.getElementById(modifedIframeId)
    ) {
      return; // Prevent duplicates
    }

    const script = document.createElement("script");
    script.id = scriptId;
    script.async = true;
    script.innerHTML = html;
    document.body?.appendChild(script);
  };

  const removeScript = () => {
    const script = document.getElementById(scriptId);
    script?.parentNode?.removeChild(script);

    const iframe = document.getElementById(modifedIframeId);
    iframe?.parentNode?.removeChild(iframe);
  };

  const isSupportBotAvailable = useMemo(() => { 
    return (SUPPORT_BOT_GETA_HOST && SUPPORT_BOT_MOTOMO_ID && SUPPORT_BOT_ID && IFRAMEURL) ?? false;
  }, []);

  return { removeScript, addScript, isSupportBotAvailable };
};

export default useSupportChatWidgetScript;
