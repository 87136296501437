import React, { useState } from "react";
import AppButton from "../../../../../AppComponents/AppButton";
import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";
import Notes from "./SubActivities/Notes";
import Tasks from "./SubActivities/Tasks";

const Activities: React.FC<any> = (props: any) => {
  // Use a local state variable to control the active section.
  const [activeSection, setActiveSection] = useState("tasks");

  // Update the active section when a button is clicked.
  const handleRedirect = (section: string) => {
    setActiveSection(section);
  };

  return (
    <ActivitiesWrapper>
      <div className="header">
        <div className="button-wrapper">
          <AppButton
            className={activeSection === "tasks" ? "active" : ""}
            onClick={() => handleRedirect("tasks")}
          >
            Tasks
          </AppButton>
          <AppButton
            className={activeSection === "notes" ? "active" : ""}
            onClick={() => handleRedirect("notes")}
          >
            Notes
          </AppButton>
        </div>
        <div className="actionWrapper"></div>
      </div>
      <div className="content">
        {activeSection === "tasks" && <Tasks setLoading = {props.setLoading} />}
        {activeSection === "notes" && <Notes setLoading = {props.setLoading}/>}
      </div>
    </ActivitiesWrapper>
  );
};

export default Activities;

const ActivitiesWrapper = styled.div`
  background: ${theme.palette.default.white};
  margin: 0 15px;
  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.palette.default.randomGray};
    justify-content: space-between;
    padding: 0 15px;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    button {
      background: none;
      color: ${theme.palette.default.waterloo};
      font-size: 13px;
      border: none;
      border-bottom: 2px solid transparent;
      padding: 0 20px !important;
      border-radius: 0;
      min-height: 60px;
      &:hover,
      &.active {
        color: ${theme.palette.primary.main};
        font-weight: 500;
        border-color: ${theme.palette.primary.main};
      }
    }
  }
  .actionWrapper {
    .action-btn {
      svg path {
        fill: ${theme.palette.primary.main};
      }
    }
  }
  .content {
    padding: 1rem 15px;
  }
`;
