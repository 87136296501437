import * as Yup from "yup";

export const singleWhatsappBroadcastSchemaFlowBuilder = Yup.object().shape({
    // sender_field: Yup.mixed().required().label("Sender field"),
    // sender_field_value: Yup.string().required().label("Sender value"),
    template: Yup.mixed()
      .required()
      .test(
        "templateRequired",
        "Template is required.",
        function (value: any, ctx: any) {
          // const parent  = ctx?.parent;
          if (!value?.id) {
            return !!value;
          }
          return true;
        },
      )
      .label("Template"),
    resolved_body_variables: Yup.array()
      .of(
        Yup.object({
          index: Yup.string()
            .min(2, "Field is a required")
            .max(60)
            .required()
            .label("Field"),
          value: Yup.string()
            .ensure()
            .min(1, "Field is a required")
            .max(60)
            .required()
            .label("Value"),
        }),
      )
      .label("Body variables"),
    resolved_header_variables: Yup.array()
      .of(
        Yup.object({
          index: Yup.string()
            .max(60)
            .label("Field")
            .when(["type"], (key1: any, schema: any) => {
              return key1 !== "TEXT"
                ? schema.optional().notRequired()
                : schema.required().min(2, "Field is a required");
            }),
          value: Yup.mixed()
            .required()
            .label("Value")
            .test(
              "mediavaluetest",
              "Field is required",
              (currentValue: any, ctx: any) => {
                if (currentValue && Object?.keys(currentValue)?.length < 1) {
                  return new Yup.ValidationError(
                    `Field is required`,
                    null,
                    "resolved_header_variables",
                  );
                }
  
                return true;
              },
            ),
        }),
      )
      .max(1)
      .label("Header variables"),
  });