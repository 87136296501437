import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";
import { AppToolTip } from "../UiComponents/AppToolTip/AppToolTip";
import { TextTitle } from "./AnalyticsSummaryStyles";
import { DonoughtWrapper, ParentDonoughtWrapper } from "./BotConvDounoutstyle";
import {
  SkeletonCircleIconDoughnut
} from "./SkeletonLoader.tsx/SkeletonCard";

interface Props {
  botdata?: any;
  loading?: any;
}

const BotConversationDoughnout: React.FC<Props> = ({ botdata, loading }) => {
  const data = {
    labels: ["Handled By BOT", "Handed to Agents"],
    datasets: [
      {
        label: "# of Votes",
        data: Object.values(botdata) ?? [],
        backgroundColor: ["#00a76e", "#7973BC"],
        borderWidth: 4,
      },
    ],
  };

  const chartData: any = {
    series: botdata,
    options: {
      chart: {
        type: "donut",
        events: {
          dataPointSelection: function (event: any, chartContext: any, config: any) {
            if (!chartContext || !config || !config.w) return;
            const selectedLabel = config.w.globals.labels[config.dataPointIndex];
            const shortLabel = selectedLabel.includes("BOT")
              ? "BOT"
              : selectedLabel.includes("Agents")
              ? "Agents"
              : selectedLabel;
            const value = config.w.globals.series[config.dataPointIndex];
            const updatedLabel = `${shortLabel}`;
            
            setTimeout(() => {
              chartContext.updateOptions({
                plotOptions: {
                  pie: {
                    expandOnClick: false,
                    donut: {
                      labels: {
                        show: true,
                        total: {
                          show: true,
                          showAlways: true,
                          label: updatedLabel,
                          formatter: function (w: any) {
                            return `${value}`;
                          },
                        },
                      },
                    },
                  },
                },
              });
            }, 0);
          },
        },
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: { width: 350 },
            legend: { position: "bottom" }, // Same as chartData1
          },
        },
        {
          breakpoint: 3000,
          options: {
            chart: { width: 350 },
          },
        },
      ],
      labels: ["Handled By BOT", "Handed to Agents"],
      dataLabels: { enabled: false },
      fill: { colors: ["#00a76e", "#7973BC"] },
      colors: ["#00a76e", "#7973BC"],
      legend: {
        position: "left", // Matched to chartData1
        show: true,
      },
      states: {
        hover: { filter: { type: "lighten", value: 0.5 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 0 },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "70%",
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: "Total", // Initial label inside the donut
              },
            },
          },
        },
      },
    },
  };
  
  
  return (
    <ParentDonoughtWrapper style={{height: 'calc(100% - 47px)'}}>
      <div className="tooltip_header">
        <p className="dounoht_header">
          <TextTitle>Total Conversations</TextTitle>
          <AppToolTip
            tooltipProps={{
              title: "Total Conversations Distribution Metrics",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
          />
        </p>
      </div>
      <DonoughtWrapper
      style={{
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center'
      }}
        isdata={
          data.datasets[0].data[0] === 0 && data.datasets[0].data[1] === 0
        }
      >
        {loading ? (<div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
          <DoughnutLoading style={{ maxWidth: '114px', margin: 0}}>
              <SkeletonCircleIconDoughnut />   
          </DoughnutLoading>
        </div>
        ) : data.datasets[0].data[0] === 0 && data.datasets[0].data[1] === 0 ? (
          <NoPreview>No Preview</NoPreview>
        ) : (
        <div className="">
            {Object.values(botdata)?.length > 0 ? (
              <ReactApexChart
                type="donut"
                series={chartData.series}
                options={chartData.options}
              />
            ) : (
              <NoPreview>No Preview</NoPreview>
            )}
          </div>
        )}
      </DonoughtWrapper>
    </ParentDonoughtWrapper>
  );
};
export default BotConversationDoughnout;

const NoPreview = styled.div`
  min-width: 120px;
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DoughnutLoading = styled.div`
  min-width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  > span {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
