import { useMemo, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import {
  AppForm,
  AppSubmitButton,
} from "../../../../../../AppComponents/Forms";
import { theme } from "../../../../../../Customization/Theme";
import { useAppDispatch, useAppSelector } from "../../../../../../Store";
import { getVoice, getVoiceBotDetails } from "../../../../../../Store/Slices/Voicebot/voicebot.selectors";
import { getLanguageOptions, getResponseBehavior, voiceSettingsOptions, whoSpeaksFirstOptions } from "../../utils";
import VoiceCallSettings from "./pages/CallSettings";
import VoiceFunctions from "./pages/Functions";
import VoiceBotGeneralSettings from "./pages/GeneralSettings";
import VoiceSpeechSettings from "./pages/SpeechSettings";
import VoicesSettings from "./pages/Voices";
import AgentSetup from "./pages/agent";
import { VoiceBotActions } from "../../../../../../Store/Slices/Voicebot/voicebot.actions";
import { useNotification } from "../../../../../../Hooks/useNotification/useNotification";
import { useParams } from "react-router-dom";

const Agent = (): JSX.Element => {
  const { botId }: any = useParams();
  const [selectedTab, setSelectedTab] = useState("setup");
  const [selectedTabLabel, setSelectedTabLabel] = useState("Agent");

  const selectedTabComponent: any = {
    setup: <AgentSetup />,
    generalSettings: <VoiceBotGeneralSettings />,
    speechSettings: <VoiceSpeechSettings />,
    callSettings: <VoiceCallSettings />,
    functions: <VoiceFunctions />,
    voices: <VoicesSettings />,
  };
  const botDetails = useAppSelector(getVoiceBotDetails);
  const voice = useAppSelector(getVoice)
  const renderComponent = selectedTabComponent[selectedTab];
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();

  const languageOptions = getLanguageOptions();
  const responseBehaviorOptions = getResponseBehavior();
  const speaksFirstOptions = whoSpeaksFirstOptions()

  const defaultLang = useMemo(() => {
    const configLanguage = botDetails?.voicebot_configuration?.general_setting?.language?.id;
    console.log(configLanguage, "configLanguage");
    const foundLanguage = languageOptions.find((option: any) => option.id === configLanguage);
    console.log(foundLanguage, "foundLanguage");
    if (foundLanguage) return foundLanguage;
    return languageOptions.find((option: any) => option.id === "en-US") || languageOptions[0];
  }, [languageOptions, botDetails?.voicebot_configuration?.general_setting]);


  const assistantResponseBehaviour = useMemo(() => {
    const responseBehaviour = botDetails?.voicebot_configuration?.speech_setting?.assistant_response_behaviour?.id;
    const foundResponseBehaviour = responseBehaviorOptions.find((option: any) => option.id === responseBehaviour);
    if (foundResponseBehaviour) return foundResponseBehaviour;
    return responseBehaviorOptions.find((option: any) => option.id === "normal") || responseBehaviorOptions[0];
  }, [languageOptions, botDetails]);

  const whoSpeakFirst = speaksFirstOptions[1]

  // Format mapping data for form initial values
  const formattedMapping = useMemo(() => {
    if (!botDetails?.voicebot?.mapping || !Array.isArray(botDetails?.voicebot?.mapping)) {
      return [];
    }

    return botDetails.voicebot.mapping.map((item: any) => {
      // If the name field is a string, convert it to an object format for the select field
      const nameField = typeof item.name === 'string'
        ? {
          name: item.name,
          label: item.name.replace(/_/g, ' ').split(' ').map((word: any) =>
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' ')
        }
        : item.name;

      console.log(nameField, "nameField");

      return {
        label: item.label || '',
        name: nameField,
        value: item.value || ''
      };
    });
  }, [botDetails?.voicebot?.mapping]);

  const validationSchemas: any = {
    setup: Yup.object().shape({
      description: Yup.string().max(250).required("Description is required"),
      who_speak_first: Yup.object()
        .shape({
          value: Yup.string().required(),
        })
        .nullable()
        .required("Who speaks first is required"),
      agent_prompt: Yup.string().max(150),
      welcome_message: Yup.string().max(150).required("Welcome Message is required"),

    }),
    generalSettings: Yup.object().shape({
      company_name: Yup.string().required("Company name is required"),
      language: Yup.object().required("Language is required"),
    }),
    // speechSettings: Yup.object().shape({
    //   buffer_rate: Yup.number().min(0, "Must be at least 0").required("Buffer rate is required"),
    //   assistant_response_behaviour: Yup.string().max(200),
    //   invoke_message_rate: Yup.number().min(0).max(100, "Max is 100"),
    //   endpointing: Yup.boolean(),
    //   lineardelay: Yup.number().min(0),
    // }),
    // callSettings: Yup.object().shape({
    //   voicemail: Yup.boolean(),
    //   max_call_duration: Yup.number().min(0).required("Max call duration is required"),
    //   call_hangup_logic: Yup.string().required("Call hangup logic is required"),
    //   call_hangup_prompt: Yup.string(),
    //   call_hangup_time: Yup.number().min(0),
    //   call_hangup_message: Yup.string(),
    //   call_termination: Yup.boolean(),
    //   call_termination_time: Yup.number().min(0),
    // }),
    // functions: Yup.object().shape({
    //   functions: Yup.array().of(
    //     Yup.object().shape({
    //       name: Yup.string().required("Function name is required"),
    //     })
    //   ),
    //   webhooks: Yup.array().of(
    //     Yup.object().shape({
    //       webhook: Yup.string().url("Invalid URL").required("Webhook URL is required"),
    //     })
    //   ),
    // }),
    // voices: Yup.object().shape({
    //   // Add voice-specific validations if needed
    // }),
  };

  const initialValues = {
    description: botDetails?.voicebot?.description || "",
    who_speak_first: whoSpeakFirst,
    welcome_message: botDetails?.voicebot?.welcome_message || "",
    agent_prompt: botDetails?.voicebot?.agent_prompt || "",
    company_name:
      botDetails?.voicebot_configuration?.general_setting
        ?.company_name || "",
    language: defaultLang,
    mapping: formattedMapping,
    functions: botDetails?.voicebot_configuration?.function
      ?.functions || [
        {
          functions: [{ name: "" }],
        },
      ],
    webhooks: botDetails?.voicebot_configuration?.function?.webhook || [
      { webhook: "" },
    ],
    buffer_rate:
      botDetails?.voicebot_configuration?.speech_setting?.buffer_rate ||
      0,
    assistant_response_behaviour: assistantResponseBehaviour,
    check_if_user_is_online:
      botDetails?.voicebot_configuration?.speech_setting
        ?.check_if_user_is_online || false,
    user_online_message:
      botDetails?.voicebot_configuration?.speech_setting
        ?.user_online_message || "",
    invoke_message_rate:
      botDetails?.voicebot_configuration?.speech_setting
        ?.invoke_message_rate || 0, // This is working
    endpointing:
      botDetails?.voicebot_configuration?.speech_setting?.endpointing ||
      false,
    lineardelay:
      botDetails?.voicebot_configuration?.speech_setting?.lineardelay ||
      0,
    voicemail:
      botDetails?.voicebot_configuration?.call_setting?.voicemail ||
      false,
    max_call_duration:
      botDetails?.voicebot_configuration?.call_setting
        ?.max_call_duration || 0,
    call_hangup_logic:
      botDetails?.voicebot_configuration?.call_setting
        ?.call_hangup_logic || "",
    call_hangup_prompt:
      botDetails?.voicebot_configuration?.call_setting
        ?.call_hangup_prompt || "",
    call_hangup_time:
      botDetails?.voicebot_configuration?.call_setting
        ?.call_hangup_time || 0,
    call_hangup_message:
      botDetails?.voicebot_configuration?.call_setting
        ?.call_hangup_message || "",
    call_termination:
      botDetails?.voicebot_configuration?.call_setting
        ?.call_termination || false,
    call_termination_time:
      botDetails?.voicebot_configuration?.call_setting
        ?.call_termination_time || 0,
    transfer_to_human:
      botDetails?.voicebot_configuration?.function
        ?.transfer_to_human || false,
  }

  const activeValidationSchema = useMemo(() => {
    return validationSchemas[selectedTab] || Yup.object();
  }, [selectedTab]);

  return (
    <Wrapper>
      {/* Left Sections */}
      <LeftSection>
        {voiceSettingsOptions?.map((option: any, index: number) => (
          <p
            key={index}
            onClick={() => {
              setSelectedTab(option?.value);
              setSelectedTabLabel(option?.name);
            }}
            className={option?.value === selectedTab ? "active" : ""}
          >
            {option?.name}
          </p>
        ))}
      </LeftSection>

      {/* Right Sections */}
      <RightSection>
        <AppForm
          initialValues={{
            description: botDetails?.voicebot?.description || "",
            name: botDetails?.voicebot?.name || "",
            who_speak_first: whoSpeakFirst,
            welcome_message: botDetails?.voicebot?.welcome_message || "",
            agent_prompt: botDetails?.voicebot?.agent_prompt || "",
            company_name:
              botDetails?.voicebot_configuration?.general_setting
                ?.company_name || "",
            language: defaultLang,
            mapping: formattedMapping,
            functions: botDetails?.voicebot_configuration?.function
              ?.functions || [
                {
                  functions: [{ name: "" }],
                },
              ],
            webhooks: botDetails?.voicebot_configuration?.function?.webhook || [
              { webhook: "" },
            ],
            buffer_rate:
              botDetails?.voicebot_configuration?.speech_setting?.buffer_rate ||
              0,
            assistant_response_behaviour: assistantResponseBehaviour,
            check_if_user_is_online:
              botDetails?.voicebot_configuration?.speech_setting
                ?.check_if_user_is_online || false,
            user_online_message:
              botDetails?.voicebot_configuration?.speech_setting
                ?.user_online_message || "",
            invoke_message_rate:
              botDetails?.voicebot_configuration?.speech_setting
                ?.invoke_message_rate || 0, // This is working
            endpointing:
              botDetails?.voicebot_configuration?.speech_setting?.endpointing ||
              false,
            lineardelay:
              botDetails?.voicebot_configuration?.speech_setting?.lineardelay ||
              0,
            voicemail:
              botDetails?.voicebot_configuration?.call_setting?.voicemail ||
              false,
            max_call_duration:
              botDetails?.voicebot_configuration?.call_setting
                ?.max_call_duration || 0,
            call_hangup_logic:
              botDetails?.voicebot_configuration?.call_setting
                ?.call_hangup_logic || "",
            call_hangup_prompt:
              botDetails?.voicebot_configuration?.call_setting
                ?.call_hangup_prompt || "",
            call_hangup_time:
              botDetails?.voicebot_configuration?.call_setting
                ?.call_hangup_time || 0,
            call_hangup_message:
              botDetails?.voicebot_configuration?.call_setting
                ?.call_hangup_message || "",
            call_termination:
              botDetails?.voicebot_configuration?.call_setting
                ?.call_termination || false,
            call_termination_time:
              botDetails?.voicebot_configuration?.call_setting
                ?.call_termination_time || 0,
            transfer_to_human:
              botDetails?.voicebot_configuration?.function
                ?.transfer_to_human || false,
          }}
          validationSchema={Yup.object().shape({
            description: Yup.string()
              .max(250),
            welcome_message: Yup.string().max(150).required("Welcome message is required"),
            // company_name: Yup.string().required("Company name is required"),
            // agent_prompt: Yup.string().max(150),
            // language: Yup.string()
            //   .oneOf(["en", "es", "fr"], "Invalid language")
            //   .required("Language is required"),
            // mapping: Yup.array().of(
            //   Yup.object().shape({
            //     name: Yup.string().required("Mapping name is required"),
            //   })
            // ),
            // functions: Yup.array().of(
            //   Yup.object().shape({
            //     functions: Yup.array().of(
            //       Yup.object().shape({
            //         name: Yup.string().required("Function name is required"),
            //       })
            //     ),
            //   })
            // ),
            // webhooks: Yup.array().of(
            //   Yup.object().shape({
            //     webhook: Yup.string()
            //       .url("Invalid URL")
            //       .required("Webhook URL is required"),
            //   })
            // ),
            // buffer_rate: Yup.number()
            //   .min(0, "Must be at least 0")
            //   .required("Buffer rate is required"),
            // assistant_response_behaviour: Yup.string().max(200),
            // check_if_user_is_online: Yup.boolean(),
            // user_online_message: Yup.string().max(200),
            // invoke_message_rate: Yup.number().min(0).max(100, "Max is 100"),
            // endpointing: Yup.boolean(),
            // lineardelay: Yup.number().min(0),
            // voicemail: Yup.boolean(),
            // max_call_duration: Yup.number()
            //   .min(0)
            //   .required("Max call duration is required"),
            // call_hangup_logic: Yup.string().required(
            //   "Call hangup logic is required"
            // ),
            // call_hangup_prompt: Yup.string(),
            // call_hangup_time: Yup.number().min(0),
            // call_hangup_message: Yup.string(),
            // call_termination: Yup.boolean(),
            // call_termination_time: Yup.number().min(0),
          })}
          onSubmit={(values: any, submitProps: any) => {
            const payload = {
              ...botDetails,
              voicebot: {
                ...botDetails?.voicebot,
                description: values?.description,
                name:values?.name,
                who_speak_first: values?.who_speak_first?.value,
                welcome_message: values?.welcome_message,
                agent_prompt: values?.agent_prompt,
                mapping: values?.mapping?.map((field: any) => ({
                  ...field,
                  name: field?.name?.name,
                })),
                voice: voice
              },
              voicebot_configuration: {
                ...botDetails?.voicebot_configuration,
                general_setting: {
                  company_name: values.company_name,
                  language: values.language,
                },
                speech_setting: {
                  buffer_rate: values.buffer_rate,
                  assistant_response_behaviour:
                    values.assistant_response_behaviour,
                  check_if_user_is_online: values.check_if_user_is_online,
                  user_online_message: values.user_online_message,
                  invoke_message_rate: values.invoke_message_rate,
                  endpointing: values.endpointing,
                  lineardelay: values.lineardelay,
                },
                call_setting: {
                  voicemail: values.voicemail,
                  max_call_duration: values.max_call_duration,
                  call_hangup_logic: values.call_hangup_logic,
                  call_hangup_prompt: values.call_hangup_prompt,
                  call_hangup_time: values.call_hangup_time,
                  call_hangup_message: values.call_hangup_message,
                  call_termination: values.call_termination,
                  call_termination_time: values.call_termination_time,
                },
                function: {
                  transfer_to_human: values?.transfer_to_human,
                  webhook: [],
                  book_a_meeting: [],
                },
              },
            };

            dispatch(VoiceBotActions.updateBotDetails({ data: payload, botId }))
              .then((response: any) => {
                submitProps.setSubmitting(false);
                if (response.payload.data.voicebot) {
                  dispatch(VoiceBotActions.getBotDeatils(botId));
                  showAlert("Updated Successfully!", "success");
                }
              })
              .catch((error: any) => {
                console.error(error);
                submitProps.setSubmitting(false);
              });
          }}
          divStyle={{ height: "100%" }}
        >
          <div className="header-section">
            <p style={{ fontWeight: 500 }}>{selectedTabLabel}</p>
            <AppSubmitButton title="Save Changes" />
          </div>
          {renderComponent}
        </AppForm>
      </RightSection>
    </Wrapper>
  );
};

export default Agent;

const Wrapper = styled.div`
  && {
    display: flex;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    .info {
      font-size: 14px;
      font-weight: 600;
    }
    .extract-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.5rem 0;
    }
  }
`;

const LeftSection = styled.div`
  width: 15%;
  height: calc(100vh - 150px);
  background-color: ${theme.palette.default.white};
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;

  p {
    padding: 0.8rem;
    font-size: 16px;
    font-weight: 500;
    color: ${theme.palette.default.darkGrey};
    cursor: pointer;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover {
      background: rgba(91, 115, 232, 0.1);
    }

    &.active {
      color: ${theme.palette.primary.light};
      background: rgba(91, 115, 232, 0.1);
    }
  }
`;

const RightSection = styled.div`
  && {
    width: 60%;
    height: calc(100vh - 150px);
    padding: 1rem;
    .header-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0.5rem;
    }
  }
`;
