import { Box, IconButton } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  AppForm,
  AppFormField,
  AppFormTextArea,
} from "../../../../../AppComponents/Forms";
import AppModel from "../../../../../Hooks/useModel/AppModel";
import { CreateVoiceBotValidations } from "../../../../../Models/ValidationSchema";
import ButtonGroup from "./ButtonGroup";
import ClickableDiv from "./ClickableDiv";
import DividerWithText from "./DividerWithText";
import { FormikHelpers, useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { VoiceBotActions } from "../../../../../Store/Slices/Voicebot/voicebot.actions";
import AppReactSelectField from "../../../../../AppComponents/Forms/AppFormReactSelect";
import { theme } from "../../../../../Customization/Theme";
import { useAuthStore } from "../../../../../Store/Slices/AuthSlice";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useMyBotsStore } from "../../../../../Services/Apis";

interface CreateVoiceBotProps {
  isShowing: boolean;
  toggleModalCreate: () => void;
}

const CreateVoiceBot: React.FC<CreateVoiceBotProps> = ({
  isShowing,
  toggleModalCreate,
}) => {
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState({
    type: "",
  });
  const userConfig = useAppSelector(useAuthStore)
  const navigate = useNavigate();
  const { showAlert } = useNotification();
  const { data: paginatedMyBots } = useAppSelector(useMyBotsStore);
  const [incoming,setIncoming] = useState(true)
  const [outgoing,setOutgoing] = useState(true)
  const [available_mobile_number,setAvailable_mobile_number] = useState<any>([])
  useEffect(() => {
    setAvailable_mobile_number(userConfig?.userConfig?.payload?.voice_config?.map((i: any) => { return { value: i?.phone_no, label: i?.phone_no } }))
  },[userConfig])
  const handleSubmit = (values: any, submitProps: any) => {
    values.phone_number = values?.phone_number?.value || ""
    dispatch(VoiceBotActions.createVoiceBot({ voicebot: values }))
      .then((response: any) => {
        if (response?.payload?.data?.error) {
          showAlert(response?.payload?.data?.error, "error");
        }

        if (response.payload.data.voicebot) {
          setFormValues(values);
          submitProps.setSubmitting(false);
          navigate(
            `/voice-bot/${response.payload.data.voicebot.bot_id}/setup/agent`
          );
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };
  const onSelectNumber = (number: any) => {
    if (paginatedMyBots?.voice_bot_limit?.phone) {
      paginatedMyBots.voice_bot_limit?.phone?.map((config: any) => {
        if(config?.phone_no === number?.value){
          setIncoming(config?.incoming)
          setOutgoing(config?.outgoing)
        }
      })
    }
  }
  return (
    <AppModel
      isShowing={isShowing}
      onClose={toggleModalCreate}
      divStyle={{
        width: "872px",
        gap: "0.5rem",
        overflowY: "hidden",
        padding: "0px",
      }}
    >
      <AppForm
        initialValues={formValues}
        validationSchema={CreateVoiceBotValidations}
        onSubmit={(values: any, submitProps: FormikHelpers<any>) => {
          handleSubmit(values, submitProps);
        }}
      >
        <HeaderContainer>
          <HeaderTitle>Create new Voice Agent</HeaderTitle>
          <IconButton onClick={toggleModalCreate} aria-label="close">
            <GridCloseIcon />
          </IconButton>
        </HeaderContainer>
        <Wrapper>
          <AppFormField
            name="name"
            label="Agent Name"
            placeholder="Write your agent name here"
            requiredSign
          />
          <AppFormTextArea
            name="description"
            label="Description"
            placeholder="Description"
          />
          <AppReactSelectField
            name={`phone_number`}
            options={available_mobile_number}
            displaykey="label"
            label="Phone Number"
            valueKey="value"
            placeholder="Select Phone Number"
            isSearchable={true}
            isClearable={true}
            hideSelectedOptions={true}
            isRequired={true}
            divStyle={{ background: "none", padding: "0" }}
            labelStyle={{ color: theme.palette.default.black }}
            maxWidth="100%"
            requiredSign
            onChangeCallbackFn={onSelectNumber}
          />
          <p className="guideline">
            Select your use case and we'll create an agent
          </p>
          <div className="radioButtonContainer">
            <ButtonGroup incoming={incoming} outgoing={outgoing} />
          </div>
          <div className="buttons">
            <ValidatedClickableDiv label="I want to create an agent from Transcript" />
            <DividerWithText text="OR" />
            <ValidatedClickableDiv
              label="I want to create an agent from Flow"
              disabled
            />
          </div>
        </Wrapper>
      </AppForm>
    </AppModel>
  );
};

export default CreateVoiceBot;

/**
 * A wrapper around ClickableDiv that validates the form before submitting
 */
const ValidatedClickableDiv: React.FC<{
  label: string;
  disabled?: boolean;
}> = ({ label, disabled = false }) => {
  const { validateForm, submitForm } = useFormikContext();

  const handleClick = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      submitForm();
    } else {
      console.warn("Form has validation errors:", errors);
    }
  };

  return (
    <ClickableDiv
      label={label}
      handleDivClick={handleClick}
      disabled={disabled}
    />
  );
};

/**
 * Styled Components
 */
const HeaderContainer = styled(Box)(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: `1px solid #CBCDD3`,
  padding: `0.5rem 1rem`,
}));

const HeaderTitle = styled("h2")(({ theme }: any) => ({
  margin: 0,
  fontSize: "1.2rem",
  fontWeight: 600,
  color: theme?.palette?.primary?.dark,
}));

const Wrapper = styled.div`
  && {
    padding: 1rem 1.5rem 3rem 1.5rem;
    .guideline {
      font-size: 18px;
      font-weight: 500;
    }
    .radioButtonContainer {
      padding: 2rem 0;
    }
    .buttons {
      padding: 1rem 0;
    }
  }
`;
