// import { useEffect } from "react";
// import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
// import FacebookIntegration from "../Integrations/Channels/Facebook/FacebookIntegration";
// import styled from "styled-components";
// import FacebookLogin from "react-facebook-login";
// import { useSelector } from "react-redux";
// import { StyleFacebookButton } from "../../Customization/CommonElements";
// import facebook from "../../../src/assets/images/facebook-icon.svg";
// import { FacebookSettingsActions } from "../../Store/Slices/Settings/facebookSettings/facebookSettings.actions";
// import { useAppDispatch } from "../../Store";
// import { useNotification } from "../../Hooks/useNotification/useNotification";
// interface Props extends LoaderComponentProps { }

// const CatalogSetup: React.FC<Props> = ({ setLoading }) => {
//     const dispatch = useAppDispatch();
//     const { showAlert } = useNotification();

//     useEffect(() => {
//         setLoading(false);
//     }, [])

//     const componentClicked = (response: any) => {
//         console.log("catalog setup response", response);
//         if (response.accessToken && !response?.error?.code) {
//             const data_for_config = {
//                 catalog_token: response.accessToken,
//             };
//             console.log("catalog data", data_for_config);
//             setLoading(true);
//             dispatch(FacebookSettingsActions.catalogConfigData(data_for_config),
//             ).then((save_result: any) => {
//                 console.log("set config saved", save_result);
//                 showAlert("Connected to Facebook successfully!", "success");
//             });
//         }
//     }
//     return (

//         <FacebookPageWrap>
//             <div className="ctaBox">
//                 <>
//                     <img className="companyLogo" src={facebook} alt="" />
//                     <p className="ctaTitle">Connect with Facebook Account</p>
//                 </>
//                 <FacebookLogin
//                     class="ctaTitle"
//                     appId="4099765553590696"
//                     autoLoad={false}
//                     fields="name,email,picture"
//                     xfbml={true}
//                     scope="manage_pages,pages_messaging,pages_messaging_subscriptions,read_page_mailboxes,ads_management,leads_retrieval,pages_manage_ads,pages_read_engagement,pages_show_list,pages_manage_metadata,business_management,pages_manage_engagement,manage_pages,pages_messaging,pages_messaging_subscriptions,read_page_mailboxes,ads_management,leads_retrieval,pages_manage_ads,pages_read_engagement,pages_show_list,pages_manage_metadata,business_management,pages_manage_engagement,catalog_management"
//                     // onClick={componentClicked}
//                     callback={componentClicked}
//                     textButton={
//                         <StyleFacebookButton>
//                             <i className="ri-links-line"></i>{" "}
//                             {"Connect With Facebook"}
//                         </StyleFacebookButton>
//                     }
//                 />
//             </div>
//         </FacebookPageWrap>
//     )


// }

// export default withLoader(CatalogSetup);
// const FacebookPageWrap = styled.div`
//   && {
//     height: 100%;
//     width: 100%;
//     padding: 1rem;
//     display: flex;
//     flex-direction: column;

//     .pageTopbar {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       height: 48px;
//       width: 100%;
//     }

//     .ctaBox {
//       display: flex;
//       height: 40%;
//       flex-direction: column;
//       gap: 1.6rem;
//       padding: 1rem;
//       justify-content: center;
//       align-items: center;
//     }

//     .companyLogo {
//       width: 96px;
//       height: 96px;
//       border-radius: 50%;
//       object-fit: contain;
//       background-color: #f5f6f8;
//       padding: 0.2rem;
//     }

//     .buttonBox {
//       width: 280px !important;
//       /* background-color: red; */
//       display: flex;
//       justify-content: center;
//     }

//     .ctaTitle {
//       font-size: 1.2rem;
//     }
//     .kep-login-facebook {
//       font-size: 14px;
//       border: none;
//       border-radius: 6px !important;
//       background-color: #3654e3 !important;
//       height: 40px;
//       padding: 6px 12px !important;
//       font-weight: 400;
//       text-transform: inherit;
//     }
//   }
// `;


import React, { useEffect } from "react";
import { FacebookSettingsActions } from "../../Store/Slices/Settings/facebookSettings/facebookSettings.actions"
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../Store";

const CatalogSetup: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();

  useEffect(() => {
    // Load the Facebook SDK asynchronously
    const loadFacebookSDK = () => {
      if (!(window as any).FB) {
        (window as any).fbAsyncInit = function () {
          (window as any).FB.init({
            appId: "544372894068159",
            cookie: true,
            xfbml: true,
            version: "v20.0",
          });
        };

        (function (d, s, id) {
          var js: any,
            fjs: any = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
      }
    };

    loadFacebookSDK();
  }, []);

  const launchWhatsAppSignup = () => {
    if (!(window as any).FB) {
      console.error("Facebook SDK not loaded.");
      return;
    }

    // Facebook Login with JavaScript SDK
    (window as any).FB.login(
      function (response: any) {
        console.log(response, "response");
        console.log(
          response?.authResponse?.accessToken,
          "response.authResponse.accessToken",
        );
        if (response?.authResponse) {
          const code: string = response?.authResponse?.code;
          const accessToken = response?.authResponse?.accessToken;
          let payload = { "catalog_token" : code };
            console.log("catalog data", payload);
            dispatch(FacebookSettingsActions.catalogConfigData(payload),
            ).then((save_result: any) => {
                console.log("set config saved", save_result);
                showAlert("Connected to Facebook successfully!", "success");
            });
          // window.location.href =
          //   `${process.env.NOTIFIER_APP_ENDPOINT}/api/v1/whatsapp/auth/callback?access_token=` +
          //   code;
          // The returned code must be transmitted to your backend
          // which will perform a server-to-server call from there to our servers for an access token
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "4099765553590696",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {
            solutionID: "951670443632073",
          },
        },
      },
    );
  };

  return (
    <button
      onClick={launchWhatsAppSignup}
      style={{
        backgroundColor: "#1877f2",
        border: "0",
        borderRadius: "4px",
        color: "#fff",
        cursor: "pointer",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontSize: "16px",
        fontWeight: "bold",
        height: "40px",
        padding: "0 24px",
      }}
    >
      Login with Facebook
    </button>
  );
};

export default CatalogSetup;
