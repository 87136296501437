import { useAppDispatch, useAppSelector } from "../../../Store";
import { getActiveNode, getModifiedNodeData, updateModifiedData } from "../Slices/main.slice";
import useActions from "../Core/useActions";

export const useSaveNode = () => {
  const activeNode = useAppSelector(getActiveNode);
  const modifiedNodeData = useAppSelector(getModifiedNodeData);
  const dispatch = useAppDispatch();
  const { updateNode } = useActions();
  
  const saveNode = (data?: any) => {
    // If data is provided, update the store first
    if (data) {
      dispatch(updateModifiedData(data));
      // Small delay to ensure store is updated
      setTimeout(() => {
        updateNode();
      }, 0);
      return true;
    } else if (activeNode && modifiedNodeData && modifiedNodeData.length > 0) {
      // Use existing data in the store
      updateNode();
      return true;
    }
    return false;
  };
  
  return { saveNode };
}; 