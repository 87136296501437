// vendors
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import { v4 as uuid } from "uuid";

// component
import MessagePreview from "../../Components/MessagePreview";
import Editor from "../../Components/Editor";
import ButtonsList from "./ButtonsList";

// slice
import {
  getLanguage,
  getActiveNode,
  updateModifiedData,
  setButtonsList,
  getButtonsList,
} from "../../Slices/main.slice";
import { useAppDispatch } from "../../../../Store";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";

// utils
import {
  getResponse,
  getText,
  getInitialEditiorText,
  getPlainTextData,
  getHTMLContent,
  cleanMediaDetails,
  getMediaDetails,
  getResponseWithoutText,
  getNodeConfig,
} from "../../Utils";
import { getChannelWiseText } from "../../Utils/editor";

const QuickReplies = (props: NodeProps) => {
  const { channel } = useSelector(useCurrentBot);
  const { node } = props;
  const language = useSelector(getLanguage);
  const activeNode = useSelector(getActiveNode);
  const response = getResponse(node, language);
  const text = getText(response);
  const media = getMediaDetails(response);
  const [editorState, setEditorState] = useState(
    getInitialEditiorText(text || ""),
  );
  const buttonsList = useSelector(getButtonsList);
  const isActiveNode = activeNode === node.nodeId;
  const currentBot = useSelector(useCurrentBot);
  const nodeConfig = getNodeConfig("button");
  const validation = currentBot?.channel
    ? nodeConfig.validation[currentBot?.channel]
    : {};
  const charLimit = validation?.charLimit;
  const dispatch = useAppDispatch();
  useEffect(() => {
    isActiveNode &&
      dispatch(setButtonsList(getResponseWithoutText(node, language) || []));
  }, [isActiveNode]);

  const onEditorStateChange = (editorState: any, mediaInfo: any) => {
    setEditorState(editorState);

    if (cleanMediaDetails(mediaInfo)) {
      dispatch(
        updateModifiedData([
          {
            id: uuid(),
            value: getChannelWiseText(getHTMLContent(editorState), channel),
            type: "text",
            media: cleanMediaDetails(mediaInfo),
          },
        ]),
      );
    } else {
      dispatch(
        updateModifiedData([
          {
            id: uuid(),
            value: getChannelWiseText(getHTMLContent(editorState), channel),
            type: "text",
          },
        ]),
      );
    }
  };

  const handleButtonLabelChange = (
    label: string,
    activeButtonIdx: number | null,
  ) => {
    if (activeButtonIdx !== null) {
      const newState = buttonsList.map((button: any, index: number) => {
        if (index === activeButtonIdx) {
          return {
            ...button,
            value: {
              ...button.value,
              ...{ text: label},
            },
          };
        }
        return button;
      });
      dispatch(setButtonsList(newState));
    }
  };

  const handleButtonSave = async (updatedAction: any) => {
    if (updatedAction.id) {
      const previousValue = getById(updatedAction.id);

      const isDifferent = isEqual(previousValue, updatedAction);

      if (previousValue) {
        if (!isDifferent) {
          const newState = buttonsList.map((button: any, index: number) => {
            if (button.id === updatedAction.id) {
              return {
                ...button,
                type: updatedAction.type,
                value: {
                  ...button.value,
                  ...{
                    text: updatedAction.value.text.substring(0, charLimit),
                    url: updatedAction.value.url,
                    ...updatedAction.value,
                  },
                },
                ...(updatedAction.type === "transfer_to_faqs" && {
                  text: updatedAction.text,
                }),
              };
            }
            return button;
          });
          await dispatch(setButtonsList(newState));
        }
        return;
      } else {
        await dispatch(setButtonsList([...buttonsList, ...[updatedAction]]));
      }
    }
  };

  const handleOrderChange = (buttonsList: any) => {
    dispatch(setButtonsList(buttonsList));
  };

  const handleButtonDelete = (activeButton: any, index: number) => {
    if (activeButton.id) {
      const newState = buttonsList.filter(
        (button: any) => button.id !== activeButton.id,
      );
      dispatch(setButtonsList(newState));
      return;
    }
    /* NOTE: in case button doesn't have ids. delete operation will perform on the basis of index */
    if (index !== -1) {
      const newState = cloneDeep(buttonsList);
      newState.splice(index, 1);
      dispatch(setButtonsList(newState));
      return;
    }
  };

  const handlePreviewButtonClick = () => {
    // setPreview(false);
  };

  const getById = (id: string) =>
    buttonsList.find((button: any) => button.id === id);

  return (
    <>
      <Box p={"8px 16px"}>
        {isActiveNode ? (
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            error={getPlainTextData(editorState) === ""}
            mediaInfo={media}
          />
        ) : (
          <MessagePreview text={text} mediaDetails={media} />
        )}
      </Box>
      <Divider />
      <ButtonsList
        isActiveNode={isActiveNode}
        buttonsList={
          isActiveNode ? buttonsList : getResponseWithoutText(node, language)
        }
        onButtonSave={handleButtonSave}
        onLabelChange={handleButtonLabelChange}
        onButtonDelete={handleButtonDelete}
        onPreviewButtonClick={handlePreviewButtonClick}
        onOrderChange={handleOrderChange}
        nodeType="quick_replies"
      />
    </>
  );
};
export default QuickReplies;
