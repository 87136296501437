export const checkSelectedAndActionValues = (data: any[]): boolean => {
  // Recursive function to handle nested conditions
  const checkConditions = (conditions: any[]): boolean => {
    for (const condition of conditions) {
      // If a combinator exists, skip the selected value check
      if (condition?.combinator) {
        // If there's a combinator, we don't need to check for selected values
        if (condition?.conditions && !checkConditions(condition.conditions)) {
          return false;
        }
      } else {
        // Check field and value selected only when there is no combinator
        const fieldSelected = condition?.field?.selected;
        const valueSelected = condition?.value?.selected;

        // If no selected values for either field or value, return false
        if (!fieldSelected || !valueSelected) {
          return false;
        }

        // Check for nested conditions if present
        if (condition?.conditions && !checkConditions(condition.conditions)) {
          return false;
        }
      }
    }
    return true;
  };

  // Loop through each group in the data
  for (const group of data) {
    // Check conditions' selected values, skipping combinators
    if (group?.conditions && !checkConditions(group.conditions)) {
      return false;
    }

    // Check action positive value
    const positiveValue = group?.action?.positive?.value;
    if (positiveValue == null || positiveValue === "") {
      return false;
    }
  }
  return true;
};
