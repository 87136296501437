import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

//user-defined
import { theme } from "../../../Customization/Theme";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import {
  ContactListingPagination,
  enabledViewsListing,
  getContactHistoryLoading,
  GridDataCount,
  isGridDataLoading,
  viewsListing,
} from "../../../Store/Slices/Contacts/contacts.selector";
import { setSelectedContactView } from "../../../Store/Slices/Contacts/contacts.slice";
import AddViewBtn from "./add-view-btn";
import { samePageLinkNavigation } from "./utils";
import { ProtectedAppUiElement } from "../../../AppComponents/ProtectedComponents";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { ContactsNetworkService } from "../../../Store/Slices/Contacts/contactsNetworkService";
import ViewMoreBtn from "./view-more-btn";

// A little function to help with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  // return result;
  return result.map((item, index) => ({
    ...item,
    position: index + 1,
  }));
};

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: React.CSSProperties
): React.CSSProperties => ({
  userSelect: "none",
  padding: "0px",
  margin: "0px",
  borderRadius: "8px 8px 0 0",
  // margin: `0 ${grid}px 0 0`,
  backgroundColor: isDragging
    ? theme.palette.default.lightGrey
    : theme.palette.default.white,
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties | any => ({
  backgroundColor: theme.palette.default.white,
  display: "flex",
  overflow: "auto",
  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari and Opera
  },
});

const ViewsTab = ({showSwimlaneview}: {showSwimlaneview: boolean}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allViewsData: any = useAppSelector(viewsListing);
  const loading: boolean = useAppSelector(getContactHistoryLoading);
  const enabledViewsData: any = useAppSelector(enabledViewsListing);
  const [searchParams] = useSearchParams();
  const currentViewId = searchParams.get("view");
  const paginationCount = useAppSelector(ContactListingPagination);
  const gridDataCount = useAppSelector(GridDataCount);
  const IsGridDataLoading = useAppSelector(isGridDataLoading);
  const { showAlert } = useNotification();

  const [value, setValue] = useState<string>(currentViewId ?? "");
  const [tabOptions, setTabOptions] = useState(enabledViewsData);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== "click" ||
      (event.type === "click" &&
        samePageLinkNavigation(
          event as React.MouseEvent<HTMLAnchorElement, MouseEvent>
        ))
    ) {
      setValue(newValue);
    }
  };

  const onTabActionClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newValue: string
  ) => {
    console.log(
      `🚀 ~ file: views-tab.tsx:55 ~ ViewsTab ~ onTabActionClick:`,
      newValue
    );
    event.preventDefault();
    setValue(newValue);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      tabOptions,
      result.source.index,
      result.destination.index
    );
    console.log("reorderedItems", reorderedItems);

    let payload = {
      data: { data: reorderedItems },
    };
    ContactsNetworkService.reOrderContactView(payload)
      .then((res: any) => {
        showAlert("Position updated", "success");
        setTabOptions(reorderedItems);
        dispatch(ContactsActions.getAllContactsViews({})).then((response) => {
          // if (res?.payload?.data) {
          // }
          // else {
          //   showAlert("Something went wrong", "error");
          // }
        });
      })
      .catch((err: any) => {
        console.log("err", err);
        showAlert("Something went wrong", "error");
      })
      .finally(() => {
        // handleClose();
      });
  };

  // const getViewsListings = () => {
  //   dispatch(ContactsActions.getAllContactsViews({}));
  // };

  // useEffect(() => {
  //   getViewsListings();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (enabledViewsData?.length === 0) return;
    setTabOptions(enabledViewsData);
  }, [enabledViewsData]);

  useEffect(() => {
    if (enabledViewsData?.length === 0) return;
    const tempView = allViewsData?.find(
      (view: any) => view?.viewId === currentViewId
    );
    if (currentViewId) {
      setValue(currentViewId?.toString());
      dispatch(setSelectedContactView(tempView));

      if (!tempView) {
        // showAlert("View not found", "error");
        navigate(`/contacts/list?view=${enabledViewsData?.[0]?.viewId}`);
      }
    } else {
      //navigate to first tab
      navigate(`/contacts/list?view=${enabledViewsData?.[0]?.viewId}`);
    }
  }, [currentViewId, enabledViewsData]); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  if (enabledViewsData?.length === 0) return null;

  return (
    <>
      <StyledTabsWrapper ref={scrollContainerRef}>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided: any, snapshot: any) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
                id="tabs-container"
              >
                <Tabs
                  value={currentViewId}
                  aria-label="wrapped label tabs example"
                  textColor="primary"
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons={"auto"}
                  role="navigation"
                >
                  {tabOptions?.map((view: any, index: number) => (
                    <Draggable
                      key={view?.viewId}
                      draggableId={view?.viewId}
                      index={index}
                    >
                      {(provided: any, snapshot: any) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style as React.CSSProperties
                          )}
                        >
                          <StyledLinkButtonLabel
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            component={Link as any}
                            href={`/contacts/list?view=${
                              view?.viewId as string
                            }`}
                            to={`/contacts/list?view=${view?.viewId as string}`}
                            className={
                              currentViewId === view?.viewId
                                ? "selected-tab"
                                : ""
                            }
                            title={view?.name}
                          >
                            <span className="labelBox">
                              {view?.name?.length > 20
                                ? view?.name?.substring(0, 20) + " ..."
                                : view?.name}
                              {(loading && paginationCount?.totalRecords > 0) ? (
                                <span className={"countBox"}>⚪</span>
                              ) : (
                                <span className={"countBox"}>
                                  {showSwimlaneview ? 
                                    (IsGridDataLoading ? '⚪' : gridDataCount) : 
                                    (paginationCount?.totalRecords ?? 0)}
                                </span>
                              )}
                              {view?.type === "custom" &&
                                (view?.can_delete ||
                                  view?.can_edit ||
                                  view?.can_update) && (
                                  <ViewMoreBtn
                                    id={view?.viewId}
                                    can_delete={view?.can_delete}
                                    can_edit={
                                      view?.can_edit || view?.can_update
                                    }
                                  />
                                )}
                            </span>
                          </StyledLinkButtonLabel>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </Tabs>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <ProtectedAppUiElement
          moduleId="contacts_views"
          specificPermissionKey="create"
        >
          <AddViewBtn />
        </ProtectedAppUiElement>
      </StyledTabsWrapper>
    </>
  );
};

export default memo(ViewsTab);

const StyledTabsWrapper = styled(Box)`
  && {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    .selected-tab {
      background-color: ${theme.palette.default.lightGrey} !important;
      border-radius: 8px 8px 0px 0px !important;
      color: ${theme.palette.default.text};
      .labelBox .countBox {
        display: inline; // Show when tab is selected
      }

      .MuiIconButton-root {
        display: inline !important; // Show when tab is selected
      }
    }
    }

    .scrollBtns{
      font-size: 14px;
      padding: 4px;
      svg{
          font-size: 14px;
      }
  }

  .hide{
    display: none;
  }

  .show{
    display: inline;
  }

    .MuiTabs-root {
      background-color: ${theme.palette.default.white};
    }
    .MuiTabs-indicator {
      display: none;
    }
    .MuiTabs-scrollButtons {
      &.Mui-disabled {
        display: none;
        opacity: 0;
      }
    }

    .createViewBtn {
      margin-left: 0.5rem;
      color: ${theme.palette.default.darkGrey};
      height: 32px;
      padding:2px 12px;
      min-width: 32px;
      font-size: 12px !important;
      text-transform: none;
      white-space: nowrap;
      svg {
        font-size: 14px;
        margin-right: 6px;
      }
    
  }
`;

const StyledLinkButtonLabel = styled(Button)`
  && {
    text-transform: none;
    color: ${theme.palette.default.darkGrey};
    // background-color: ${theme.palette.default.success};
    background-color: transparent !important;

    padding: 4px 16px;
    min-height: 40px;
    min-width: 120px;
    .labelBox {
      white-space: nowrap;
      text-align: center;
      max-height: 56px;
      line-height: 1;
      font-size: 13px;
      font-weight: 400;
      .countBox {
        display: none; // Hide by default
        text-transform: none;
        margin-left: 8px;
        text-align: center;
        font-size: 0.75rem;
        min-width: 20px;
        padding: 2px 6px;
        height: 24px;
        border-radius: 12px;
        background-color: ${theme.palette.default.darkGrey};
        color: white;
      }

      .MuiIconButton-root {
        display: none;
        padding: 4px;
        margin: 0;
        margin-left: 0.8rem;
        color: ${theme.palette.default.darkGrey};
        line-height: 1;
        .MuiSvgIcon-root {
          font-size: 1rem;
        }
      }
    }

    &.Mui-selected {
      background-color: ${theme.palette.default
        .lightGrey}; // Replace #yourColor with the color you want
      border-radius: 8px 8px 0 0;

      color: ${theme.palette.default.text};
      .labelBox .countBox {
        display: inline; // Show when tab is selected
      }

      .MuiIconButton-root {
        display: inline;
      }
    }
  }
`;
