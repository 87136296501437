import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import uniqid from "uniqid";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormObserver,
  AppSubmitButton
} from "../../../AppComponents/Forms";
import AppFormCustomRadioField from "../../../AppComponents/Forms/AppFormCustomRadioField";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  automationBuilderPayload,
  automationBuilderState,
  modelConfigData,
  modelConfigError,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import {
  closeConfigModal,
  setNodeConfig,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import { SMSActions } from "../../../Store/Slices/SMS/sms.actions";
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import AppReactSelectField from "../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { saveGraph } from "../config";
import { SmsActivityConfigSchema } from "../validationSchema";
import { withLoader } from "../../../AppComponents/Loader";

export const SmsViewOptions = [
  {
    id: uniqid(),
    value: "Any SMS",
    name: "any",
  },
  {
    id: uniqid(),
    value: "Particular SMS",
    name: "perticular",
  },
];
const sms_status_options = [
  { id: "sent", name: "SMS Sent" },
  { id: "delivered", name: "SMS Delivered" },
  { id: "failed", name: "SMS Failed" },
];
const SMSActivity = ({ setLoading }: any): JSX.Element => {
  const formRef: any = useRef();
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const emailModelConfig: any = useAppSelector(modelConfigData);
  const emailModelError: any = useAppSelector(modelConfigError);
  const automationReduxData: any = useAppSelector(automationBuilderState);
  const automationPayload: any = useAppSelector(automationBuilderPayload);
  const [templateOptions, setTemplateOptions]: any = useState([]);

  const [initialValues, setInitialValues]: any = useState({
    smsTemplateId: null,
    sms_view: "Any SMS",
    sms_activity: null,
  });

  const [formikProps, setFormikProps] = useState<any>(null);
  useEffect(() => {
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.any !== undefined
    ) {
      //function to filter object based on Value
      let preFilledData: any = {
        sms_activity: {
          id: sms_status_options.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.function
          )?.id,
          name: sms_status_options.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.function
          )?.name,
        },
      };
      if (
        automationPayload?.draftedUIObject?.graphData?.nodes?.[
          automationReduxData?.selectedNodeId
        ]?.nodeObjectData?.any
      ) {
        preFilledData.sms_view = "Any SMS";
      } else {
        preFilledData.sms_view = "Particular SMS";
      }
      if (
        automationPayload?.draftedUIObject?.graphData?.nodes?.[
          automationReduxData?.selectedNodeId
        ]?.nodeObjectData?.smsTemplateId
      ) {
        preFilledData.smsTemplateId = {
          id: templateOptions.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.smsTemplateId
          )?.id,
          value: templateOptions.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.smsTemplateId
          )?.name,
        };
      }
      setInitialValues(preFilledData);
    }
  }, [automationPayload, emailModelConfig]);   // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const payload: any = {
      limit: 999,
      offset: 0,
    };
    dispatch(SMSActions.getSMSTemplateListData(payload))
      .then((res: any) => {
        let tempArray: any = [];
        res?.payload.data?.results?.map((ele: any) => {
          if (ele.status === "APPROVED") {
            tempArray.push({
              id: ele.id,
              name: ele.name,
              sender_ids: ele.sender_ids,
            });
          }
          setTemplateOptions(tempArray);
        });
      }).then(() => {
        setLoading(false)
      }).catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps
  const closeModel = () => {
    dispatch(closeConfigModal({}));
  };
  useEffect(() => {
    // Manually set the isValid state to false
    formRef?.current?.setFormikState((prevState: any) => ({
      ...prevState,
      isValid: false,
    }));
    formRef?.current?.setFieldValue("smsTemplateId", "", { shouldDirty: true })
  }, []);
  const handleSubmit = async (values: any, submitProps: any) => {
    let payload: any = {
      function: values?.sms_activity.id,
      key: "function",
      nodeObject: {},
      nodeId: automationReduxData?.selectedNodeId,
      configText: `${values?.sms_activity.name}`,
    };
    if (values?.sms_view === "Any SMS") {
      payload.nodeObject.any = true;
    } else {
      payload.nodeObject.any = false;
      payload.nodeObject.smsTemplateId = values?.smsTemplateId.id;
    }
    submitProps.setSubmitting(false);
    dispatch(setNodeConfig(payload));
    await saveGraph();

    showAlert("Node Config Saved Successfully!", "success");
    closeModel();
  };
  return (
    <ModelFormWrapper isDataAvaliable={templateOptions?.length}>
      <Typography variant="h6">SMS Activity</Typography>
      {emailModelError?.length ? (
        <React.Fragment>
          <div className="image_container">
            <NoDataFound />
          </div>
          <ModelFooter>
            <AppButton onClick={closeModel}>Close</AppButton>
          </ModelFooter>
        </React.Fragment>
      ) : (
        <AppForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={SmsActivityConfigSchema}
          innerRef={formRef}
        >
          <AppFormCustomRadioField
            name="sms_view"
            layout="vertical"
            Options={SmsViewOptions}
          />
          {formRef?.current?.values?.sms_view === "Particular SMS" &&
            templateOptions.length ? (
            <>
              <AppReactSelectField
                name={"smsTemplateId"}
                options={templateOptions || []}
                label="Sms Template List"
                displayKey="name"
                valueKey="id"
                placeholder="Select SMS Template"
                isSearchable={true}
                isClearable={true}
                hideSelectedOptions={true}
                isRequired={true}
                divStyle={{ background: "none", padding: "0" }}
              />
            </>
          ) : null}
          <AppReactSelectField
            name={"sms_activity"}
            options={sms_status_options}
            label="SMS Activity"
            displayKey="name"
            valueKey="id"
            placeholder="Select Activity"
            isSearchable={true}
            isClearable={true}
            hideSelectedOptions={true}
            isRequired={true}
            divStyle={{ background: "none", padding: "0" }}
            optionsPlaceholder="No List Found"
          />
          <AppFormObserver setFormikProps={setFormikProps} />
          <ModelFooter>
            <AppButton variant="outline" onClick={closeModel}>Cancel</AppButton>
            <AppSubmitButton title={"Save Config"} disabled={!formRef?.current?.isValid} />
          </ModelFooter>
        </AppForm>
      )}
    </ModelFormWrapper>
  );
};
export default withLoader(SMSActivity);

const ModelFormWrapper = styled.div`
width: ${(props: any) => props.isDataAvaliable ? "400px" : "500px"};
  h6 {
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 15px;
    padding: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }
  .image_container{
    text-align:center;
  }
`;
const ModelFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${theme.palette.default.border};
  padding: 15px 0 0;
  button:last-child {
    margin-left: 15px;
  }
`;
