import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiLibraryNetworkActions } from "./apilibrary.actions";

interface ApiLibraryState {
  apiList: any;
  pagination: {
    totalRecords: number;
    totalPages: number;
  };
  contactOption: any[];
  apiOptions: any[];
  loading: boolean;
  error: string | null;
}

const initialState: ApiLibraryState = {
  apiList: [],
  pagination: {
    totalRecords: 0,
    totalPages: 0,
  },
  contactOption: [],
  apiOptions: [],
  loading: false,
  error: null,
};

const apiLibrarySlice = createSlice({
  name: "apiLibrary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //* all Marketing List
      .addCase(
        ApiLibraryNetworkActions.getApiList.pending,
        (state, action) => {}
      )
      .addCase(
        ApiLibraryNetworkActions.getApiList.fulfilled,
        (state, action) => {
          state.apiList = action?.payload?.data?.data;
          state.pagination = action?.payload?.data?.pagination;
        }
      )

      .addCase(
        ApiLibraryNetworkActions.getApiList.rejected,
        (state, action) => {}
      )
      // getContactProperties
      .addCase(
        ApiLibraryNetworkActions.getContactProperties.pending,
        (state, action) => {}
      )
      .addCase(
        ApiLibraryNetworkActions.getContactProperties.fulfilled,
        (state, action) => {
          state.contactOption = action?.payload?.data?.data;
        }
      )

      .addCase(
        ApiLibraryNetworkActions.getContactProperties.rejected,
        (state, action) => {}
      )
      // getApiListOption
      .addCase(
        ApiLibraryNetworkActions.getApiListOption.pending,
        (state, action) => {}
      )
      .addCase(
        ApiLibraryNetworkActions.getApiListOption.fulfilled,
        (state, action) => {
          state.apiOptions = action?.payload?.data?.response;
        }
      )

      .addCase(
        ApiLibraryNetworkActions.getApiListOption.rejected,
        (state, action) => {}
      );
  },
});

export default apiLibrarySlice.reducer;
