import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import Danger from "../../../../../../../assets/images/danger-live.svg";
import { ReactComponent as FailedIcon } from  "../../../../../../../assets/images/close.svg"
import LinksList from "./LinksList";
import SkeletonLoader from "./SkeletonLoader";
import { useAppDispatch, useAppSelector } from "../../../../../../../Store";
import { useNotification } from "../../../../../../../Hooks/useNotification/useNotification";
import { CrawlLinkList, siteMappedUrlsData } from "../../../../../../../Store/Slices/Faq/Source/source.selectors";
import useModal from "../../../../../../../Hooks/useModel/useModel";
import { FaqSourceAction } from "../../../../../../../Store/Slices/Faq/Source/source.actions";
import { setcrawlLinkList, setSiteMappedUrls } from "../../../../../../../Store/Slices/Faq/Source/source.slice";
import AppModel from "../../../../../../../Hooks/useModel/AppModel";
import { CrawlPopupWrap } from "../../../../../../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import { WebSiteAndSitemapUrlValidations } from "../../../../../../../Models/ValidationSchema";
import { AppForm, AppFormField, AppSubmitButton } from "../../../../../../../AppComponents/Forms";
import AppButton from "../../../../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../../../../AppComponents/AppMaterialIcons";
import { theme } from "../../../../../../../Customization/Theme";

interface Props {
  getListingData: any;
  toggle: () => void;
  isShowing: boolean;
  type: string;
}
const WebsiteAndSitemapAddModal = (props: Props) => {
  // hooks*******
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const params = useParams();

  // const values
  const { bot_id } = params;
  const { toggle, isShowing, type, getListingData } = props;
  const urlList = useAppSelector(CrawlLinkList);
  const siteMapData = useAppSelector(siteMappedUrlsData);
  const headers = {
    appid: JSON.stringify({
      botid: bot_id,
    }),
  };
  // states********
  const [shouldApply, setShouldApply] = useState(false);
  const [submitingButton, setSubmittingButton] = useState<boolean>(false);
  // TO show and hide crawl url's response urls
  const [showListing, setShowListing] = useState<any>(false);
  const [Loader, setLoader] = useState(true);
  const [docId, setDocId] = useState(null);
  const [sourceAndDocsId, setSourceAndDocsId] = useState({
    count: 0,
    offset: 0,
    limit: 10,
    sourceId: "",
    documentId: "",
    perPage: { label: "50 per page", value: 50 },
  });
  const [localValues, setLocalValues] = useState<any>({
    crawlUrl: "",
    checkbox: [],
    checkboxAll: true,
  });
  // select row
  // Cancel Modals
  const ConfirmModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingConfirmModal: isShowing, toggleConfirmModal: toggle };
  };
  const { isShowingConfirmModal, toggleConfirmModal } = ConfirmModal();

  useEffect(() => {
    if (shouldApply) {
      let payLoad_data = {
        sourceId: sourceAndDocsId.sourceId,
        offset: sourceAndDocsId.offset,
        query: "",
        limit: localValues?.perPage?.value || 10,
        headers: headers,
      };
      getCrawlUrls(payLoad_data);
      setShouldApply(false);
    }
  }, [shouldApply]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleChangeRowsPerPage = (value: any) => {
    setSourceAndDocsId({
      ...sourceAndDocsId,
      perPage: value,
    });
    setShouldApply(true);
    // setShouldApply(true);
  };
  //get sub crwaled urls list api ****
  const getCrawlUrls = (payLoad_data: any) => {
    if (type === "website" && payLoad_data?.sourceId) {
      dispatch(FaqSourceAction.GetWebsitesCrawlUrls(payLoad_data))
        .then((res: any) => {
          if (res?.payload?.data?.error) {
            showAlert(res?.payload?.data?.error, "error");
            setTimeout(() => {
              setShowListing(false);
              setSubmittingButton(false);
            }, 2000);
            if (payLoad_data && payLoad_data?.submitProps) {
              payLoad_data?.submitProps?.setSubmitting(false);
            }
          } else {
            if (res?.payload?.data && !res?.payload?.data?.error) {
              setLoader(false);
              setSubmittingButton(false);
              if (payLoad_data && payLoad_data?.submitProps) {
                payLoad_data?.submitProps?.setSubmitting(false);
              }
            }
          }
        })
        .catch((error: any) => {
          console.error(error);
          setSubmittingButton(false);
          if (payLoad_data && payLoad_data?.submitProps) {
            payLoad_data?.submitProps?.setSubmitting(false);
          }
        })
        .finally(() => {
          if (payLoad_data && payLoad_data?.submitProps) {
            payLoad_data?.submitProps?.setSubmitting(false);
          }
        });
    }
    if (type === "sitemap" && payLoad_data?.sourceId) {
      // api call for sitemap ************
      dispatch(FaqSourceAction.GetSiteMapCrawlUrls(payLoad_data))
        .then((res: any) => {
          if (res?.payload?.data?.error) {
            showAlert(res?.payload?.data?.error, "error");
            setTimeout(() => {
              setShowListing(false);
              setSubmittingButton(false);
            }, 2000);
            if (payLoad_data && payLoad_data?.submitProps) {
              payLoad_data?.submitProps?.setSubmitting(false);
            }
          } else {
            if (res?.payload?.data && !res?.payload?.data?.error) {
              setLoader(false);
              setSubmittingButton(false);
              if (payLoad_data && payLoad_data?.submitProps) {
                payLoad_data?.submitProps?.setSubmitting(false);
              }
            }
          }
        })
        .catch((error: any) => {
          console.error(error);
          setSubmittingButton(false);
          if (payLoad_data && payLoad_data?.submitProps) {
            payLoad_data?.submitProps?.setSubmitting(false);
          }
        })
        .finally(() => {
          if (payLoad_data && payLoad_data?.submitProps) {
            payLoad_data?.submitProps?.setSubmitting(false);
          }
        });
    }
  };
  //   Add urls API Call
  const handleSubmitBtn = (values: any, submitProps: any) => {
    setShowListing(true);
    setSubmittingButton(true);
    if (type === "website") {
      const payload = {
        url: { url: values.crawlUrl },
        headers: headers,
      };
      if (!showListing) {
        dispatch(FaqSourceAction.CreateWebsiteCrawlUrl(payload))
          .then((res: any) => {
            setDocId(res?.payload?.data?.documentId);
            // api call for crawl list
            let response = res.payload.data;
            if (res?.payload?.data?.error) {
              showAlert(res?.payload?.data?.error, "error");
              setTimeout(() => {
                setShowListing(false);
                setSubmittingButton(false);
              }, 2000);
            } else {
              if (response?.sourceId && response?.sourceId?.length) {
                setSourceAndDocsId({
                  ...sourceAndDocsId,
                  count: response.count,
                  sourceId: response.sourceId,
                  documentId: response.documentId,
                });
                let payLoad_data = {
                  sourceId: response.sourceId,
                  offset: 0,
                  limit: 10,
                  headers: headers,
                  submitProps: submitProps,
                };
                setLoader(true);
                getCrawlUrls(payLoad_data);
              }
            }
          })
          .catch((error: any) => {
            setSubmittingButton(false);
          })
          .finally(() => { });
      } else {
        ScrapLinks(submitProps);
      }
    }
    if (type === "sitemap") {
      const payload = {
        url: { sitemapurl: values.crawlUrl },
        headers: headers,
      };
      if (!showListing) {
        dispatch(FaqSourceAction.CreateSiteMapCrawlUrl(payload))
          .then((res: any) => {
            setDocId(res?.payload?.data?.documentId);
            // api call for pagination list
            let response = res.payload.data;
            if (res?.payload?.data?.error) {
              showAlert(res?.payload?.data?.error, "error");
              setTimeout(() => {
                setShowListing(false);
                setSubmittingButton(false);
              }, 2000);
            } else {
              if (response?.sourceId && response?.sourceId?.length) {
                setSourceAndDocsId({
                  ...sourceAndDocsId,
                  count: response.count,
                  sourceId: response.sourceId,
                  documentId: response.documentId,
                });
                let payLoad_data = {
                  sourceId: response.sourceId,
                  offset: 0,
                  limit: 10,
                  headers: headers,
                  submitProps: submitProps,
                };
                getCrawlUrls(payLoad_data);
              }
            }
          })
          .catch((error: any) => {
            setSubmittingButton(false);
          })
          .finally(() => {
            submitProps.setSubmitting(false);
          });
      } else {
        ScrapLinks(submitProps);
      }
    }
  };

  // scraplinks final submit api call

  const ScrapLinks = (submitProps: {
    resetForm(): unknown;
    setSubmitting: (arg0: boolean) => void;
  }) => {
    const payload = {
      urls: { documentId: sourceAndDocsId.documentId },
      headers: headers,
    };

    dispatch(FaqSourceAction.PostSelectedSubUrl(payload))
      .then((res: any) => {
        if (res?.payload?.data?.error) {
          showAlert(res?.payload?.data?.error, "error");
          submitProps.setSubmitting(false);
        } else {
          if (res?.payload?.data && !res?.payload?.data?.error) {
            submitProps.setSubmitting(false);
            submitProps.resetForm();
            onReset();
            setLocalValues({
              crawlUrl: "",
              checkbox: [],
              checkboxAll: false,
            });
            showAlert("url added", "success");
            toggle();
            getListingData((prev: any) => ({
              ...prev,
            }));
            setSourceAndDocsId((prev: any) => ({
              ...prev,
              sourceId: "",
              documentId: "",
            }));
            dispatch(setcrawlLinkList(null));
          }
        }
      })
      .catch((error: any) => {
        showAlert("submit false", "error");
        submitProps.setSubmitting(false);
      })
      .finally(() => {
        submitProps.setSubmitting(false);
      });
  };

  // checked url apis call
  const addSubUrlOnCheck = async (selectedId: any, value: string) => {
    let docId;
    let payload;
    if (selectedId?.checkbox?.length === 0 || selectedId?.checkboxAll) {
      docId = sourceAndDocsId?.documentId;
      payload = {
        url: { documentId: docId, allselect: selectedId?.checkboxAll },
        headers: headers,
      };
    } else {
      docId = value;
      payload = {
        url: { documentId: docId },
        headers: headers,
      };
    }

    dispatch(FaqSourceAction.AddSubUrl(payload))
      .then((res: any) => {
        if (res?.payload?.data?.error) {
          showAlert(res?.payload?.data?.error, "error");
        } else {
          if (res?.payload?.data && !res?.payload?.data?.error) {
          }
        }
      })
      .catch((error: any) => {
        showAlert("submit false", "error");
      })
      .finally(() => { });
  };

  // delete crawled url
  const deletSourceByDocumentId = () => {
    const HEADERS = {
      appid: JSON.stringify({
        botid: bot_id,
      }),
    };
    const data = {
      HEADERS,
      documentIds: [sourceAndDocsId?.documentId],
    };
    dispatch(FaqSourceAction.DeleteSourceByIds(data))
      .then((res: any) => {
        if (res?.payload?.status === 200) {
          showAlert("Crawl Url Is Deleted", "success");
          setLocalValues((prev: any) => ({ ...prev }));
          toggleConfirmModal();
          toggle();
          setLocalValues({
            crawlUrl: "",
            checkbox: [],
            checkboxAll: false,
          });
          onReset();
          getListingData((prev: any) => ({
            ...prev,
          }));
        } else {
          showAlert("Error", "error");
        }
      })
      .catch((error: any) => {
        showAlert(error, "error");
      });
  };
  const onCancel = () => {
    toggle();
    if (localValues && localValues.crawlUrl?.length) {
      getListingData((prev: any) => ({
        ...prev,
      }));
    }
    const HEADERS = {
      appid: JSON.stringify({
        botid: bot_id,
      }),
    };
    const data = {
      HEADERS,
      documentIds: [docId],
    };
    docId &&
      dispatch(FaqSourceAction.DeleteSourceByIds(data))
        .then((res: any) => {
          if (res?.payload?.status === 200) {
            showAlert("Source Url Is Deleted", "success");
            onReset();
            setDocId(null);
          } else {
            showAlert("Error", "error");
          }
        })
        .catch((error: any) => {
          showAlert(error, "error");
        });
  };

  // on reset **********************
  const onReset = () => {
    dispatch(setcrawlLinkList(null));
    dispatch(setSiteMappedUrls(null));
    setShowListing(false);
    setLocalValues({
      crawlUrl: "",
      checkbox: [],
      checkboxAll: true,
    });
    setSourceAndDocsId({
      count: 0,
      offset: 0,
      limit: 10,
      sourceId: "",
      documentId: "",
      perPage: { label: "50 per page", value: 50 },
    });
    setSubmittingButton(false);
  };
  return (
    <AppModel
      isShowing={isShowing}
      divStyle={{
        padding: 0,
      }}
      PaperProps={{
        style: {
          boxShadow: "none",
          width: "931px",
        },
      }}
    >
      <CrawlPopupWrap>
        <div className="crawl-popup">
          <div className="heading">
            <div className="crawl-heading">
              <p>Crawl URL</p>
            </div>
            <div className="close-icon">
              <IconButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  onCancel();
                  onReset();
                }}
              >
                <FailedIcon />
              </IconButton>
            </div>
          </div>
        </div>

        <AppForm
          initialValues={localValues}
          validationSchema={WebSiteAndSitemapUrlValidations}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmitBtn(values, submitProps);
            setLocalValues(values);
          }}
        >
          <div className="body">
            <div>
              <h3>
                {type === "website"
                  ? "Please paste a website URL below"
                  : "Submit your website sitemap"}
              </h3>
            </div>

            <div className="input-field">
              <AppFormField
                label="URL"
                name="crawlUrl"
                placeholder="Paste your URL here"
                disabled={urlList?.length > 0}
              />
            </div>
            {showListing && (
              <React.Fragment>
                {submitingButton ? (
                  <SkeletonLoader />
                ) : (
                  <div className="linklist">
                    <LinksList
                      urlList={urlList}
                      setLocalValues={setLocalValues}
                      localValues={localValues}
                      addSubUrlOnCheck={addSubUrlOnCheck}
                      setSourceAndDocsId={setSourceAndDocsId}
                      sourceAndDocsId={sourceAndDocsId}
                      setShouldApply={setShouldApply}
                      siteMapData={siteMapData}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
            {type === "website" && sourceAndDocsId?.count > 100 && <p className="warning_text">Accept only 100 pages in Crawl website</p>}
            {siteMapData?.count !== 0 ? (
              <div className="paragraph">
                <div className="para">
                  {type === "website" ? (
                    <p>
                      Experience the magic of transforming your website into
                      captivating dialogues! Simply provide your website URL,
                      and witness the enchantment as it gets crawled to build an
                      engaging knowledge base.
                    </p>
                  ) : (
                    <p>
                      Use your website's sitemap to power our smart bot! Share
                      the sitemap, and see the magic happen. Our bot will learn
                      from it to answer user questions accurately.
                    </p>
                  )}
                </div>
              </div>
            ) : null}
          </div>

          <div className="footer">
            <div className="popup-buttons">
              <AppButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  onCancel();
                  onReset();
                }}
                variant="grey"
              >
                Cancel
              </AppButton>
              <AppSubmitButton
                loading={submitingButton}
                title={showListing && !Loader ? "Crawl" : "Next"}
                disabled={
                  showListing && !Loader ? siteMapData?.count === 0 : false
                }
              >
                <AppMaterialIcons iconName="ArrowForwardOutlined" />
              </AppSubmitButton>
            </div>
          </div>
        </AppForm>
      </CrawlPopupWrap>
      <CancelConfirmationModal
        toggleConfirmModal={toggleConfirmModal}
        deletSourceByDocumentId={deletSourceByDocumentId}
        isShowingConfirmModal={isShowingConfirmModal}
      />
    </AppModel>
  );
};

export default WebsiteAndSitemapAddModal;

interface CancelConfirmationModal {
  toggleConfirmModal: any;
  deletSourceByDocumentId: any;

  isShowingConfirmModal: any;
}
const CancelConfirmationModal: React.FC<CancelConfirmationModal> = ({
  toggleConfirmModal,
  deletSourceByDocumentId,
  isShowingConfirmModal,
}) => {
  return (
    <AppModel
      isShowing={isShowingConfirmModal}
      onClose={() => toggleConfirmModal()}
      divStyle={{
        padding: 0,
      }}
      PaperProps={{
        style: {
          boxShadow: "none",
          width: "497px",
        },
      }}
    >
      <ConfirmDelete>
        <div className="header">
          <div className="header-left">
            <div className="danger">
              <img src={Danger} alt="" />
            </div>
            <div className="text">
              <p>Are you sure, you want to Discard ?</p>
            </div>
          </div>
          <div className="header-right">
            <span onClick={() => toggleConfirmModal()}>
              <AppMaterialIcons iconName="close" />
            </span>
          </div>
        </div>
        <div className="footer">
          <AppButton variant="grey" onClick={() => toggleConfirmModal()}>
            No
          </AppButton>
          <AppButton
            variant="danger-filled"
            onClick={() => deletSourceByDocumentId()}
          >
            <AppMaterialIcons iconName="DeleteOutlineOutlined" />
            Yes
          </AppButton>
        </div>
      </ConfirmDelete>
    </AppModel>
  );
};

const ConfirmDelete = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.875rem;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
    .header-left {
      display: flex;
      gap: 0.875rem;
      .danger {
        padding-top: 4px;
        img {
          width: 1.2rem;
        }
      }
      .text h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .text p {
        color: ${theme.palette.default.darkGrey};
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
  }
`;
