import { RootState } from "../../index";

// // get All Subscription Plan
// export const getStoredWebhook = (state: RootState) => state.webhook.getWebhook;

export const getVoiceBotDetails = (state: RootState) =>
  state.voiceBot.botDetails;
export const getVoiceList = (state: RootState) => state.voiceBot.voiceList;
export const getVoiceListPagination = (state: RootState) =>
  state.voiceBot.pagination;
export const getCallLogsRowData = (state: RootState) =>
  state.voiceBot.callLogsRowData;
export const getVoice = (state: RootState) =>
  state.voiceBot.voice;
