type ChatbotParams = Record<string, string>;

const setChatbotParamsData = (params: ChatbotParams): void => {
  try {
    const parsedParamsData = JSON.stringify(params);
    const encodedText = window.btoa(parsedParamsData);
    window.localStorage.setItem("chatbot_params", encodedText);
  } catch (error) {
    console.error("Error setting chatbot params data:", error);
  }
};

const getChatbotParamsData = (): ChatbotParams | null => {
  try {
    const result = window.localStorage.getItem("chatbot_params");
    if (!result) {
      return null;
    }
    const decodedText = window.atob(result);
    const parsedResult: ChatbotParams = JSON.parse(decodedText);
    return parsedResult;
  } catch (error) {
    console.error("Error getting chatbot params data:", error);
    return null;
  }
};

const deleteChatbotParamsData = (): void => {
  window.localStorage.removeItem("chatbot_params");
};

export {
  deleteChatbotParamsData, getChatbotParamsData, setChatbotParamsData
};
