import React from "react";
import styled from "styled-components";

const ClickableDivBlock = styled.div<{
  borderColor?: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border: 1px solid
    ${({ borderColor, disabled }: any) =>
      disabled ? "#ccc" : borderColor || "#ddd"};
  border-radius: 8px;
  cursor: ${({ disabled }: any) => (disabled ? "not-allowed" : "pointer")};
  width: 100%; // Make it 100% width
  background-color: ${({ disabled }: any) => (disabled ? "#f5f5f5" : "#fff")};
  font-size: 16px;
  font-weight: 500;
  color: ${({ disabled }: any) => (disabled ? "#999" : "#1976d2")};
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${({ disabled }: any) =>
      disabled ? "#f5f5f5" : "#f0f0f0"}; // Ensure no hover effect when disabled
    border-color: ${({ disabled }: any) =>
      disabled ? "#ccc" : "#1976d2"}; // Optional hover border color change
  }
`;

interface ClickableDivProps {
  label: string;
  handleDivClick: () => void;
  disabled?: boolean;
}

const ClickableDiv: React.FC<ClickableDivProps> = ({
  handleDivClick,
  label,
  disabled = false,
}) => {
  return (
    <ClickableDivBlock
      borderColor="#ddd"
      onClick={!disabled ? handleDivClick : undefined}
      disabled={disabled}
    >
      {label}
    </ClickableDivBlock>
  );
};

export default ClickableDiv;
