import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import { CircularProgress, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
//custom imports
import { theme } from "../../Customization/Theme";
import useAuthentication from "../../Hooks/useAuthentication";
import useSupportChatWidgetScript from "./useSupportChatWidgetScript";

const SupportChatWidget = () => {
  const { isUserLoggedIn } = useAuthentication();

  const { addScript, removeScript,isSupportBotAvailable } =
    useSupportChatWidgetScript();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const openDrawer = () => {
    addScript();
    setDrawerOpen(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const closeDrawer = () => {
    removeScript();
    setDrawerOpen(false);
    setIsLoading(true);
  };


  useEffect(() => {
    const handleMessageEvent = (event: MessageEvent) => {
      const allowedOrigins = [process.env.REACT_APP_IFRAME_CHATBOT_BASE_URL, "http://localhost:3000"];

      console.log(`🚀 ~ handleMessageEvent ~ allowedOrigins:`, allowedOrigins,event.origin);

      if (!allowedOrigins.includes(event.origin)) return; // ✅ SECURITY CHECK

      if (
        event.data?.type === "onChatbotClose" &&
        event.data?.data === "close"
      ) {
        closeDrawer(); 
      }
    };

    window.addEventListener("message", handleMessageEvent);

    return () => {
      window.removeEventListener("message", handleMessageEvent);
    };
  }, []); //eslint-disable-line


  if (!isSupportBotAvailable) return null;
  if (!isUserLoggedIn) return null;

  return (
    <>
      <StyledContainer style={{ display: drawerOpen ? "none" : "flex" }}>
      <Tooltip title="Support Chat" placement="top-end" enterDelay={500} arrow>
        <div
          className="iconBtn"
          onClick={() => openDrawer()}
        >
          <HeadsetMicIcon className="sidebar-icon" />
          </div>
        </Tooltip>
      </StyledContainer>

      <StyledDrawer
        anchor={"right"}
        open={drawerOpen}
        id="support-chatbot-drawer"
      >
        <PreviewWrapBox>
          {isLoading ? <CircularProgress color="inherit" /> : null}
        </PreviewWrapBox>
      </StyledDrawer>
    </>
  );
};

export default SupportChatWidget;

const StyledContainer = styled.div`
  && {
    position: absolute;
    bottom: 20%;
    right: 0;
    z-index: 999;
    height: 56px !important; //do not remove !important
    overflow: hidden;
    width: 56px;
    padding: 8px 8px 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    .iconBtn {
      background-color: ${theme.palette.primary.main};
      height: 52px;
      width: 56px;
      border-radius: 50% 0px 0px 50%;
      padding-left: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -24px;
      transition: right 0.3s ease-in;
      &:hover {
        cursor: pointer;
        right: 0;
      }
    }

    .sidebar-icon {
      font-size: 1.6rem;
      color: ${theme.palette.default.white};
      user-select: none;
    }
  }
`;

const PreviewWrapBox = styled.div`
  && {
    width: 470px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: ${theme.palette.secondary.main};
    overflow: hidden;
    position: relative;
  }
`;

const StyledDrawer = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: ${({ open }: { open: boolean }) => (open ? "470px" : "0")};
  min-width: ${({ open }: { open: boolean }) => (open ? "470px" : "0")};
  transition: width 0.2s ease-in-out;
  background-color: ${theme.palette.secondary.main};
  overflow: hidden;
  z-index: 99;
`;
