import { FieldArray, useFormikContext } from "formik";
import { useState } from "react";
import styled from "styled-components";
import AppButton from "../../../../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../../../../AppComponents/AppMaterialIcons";
import {
  AppForm,
  AppFormField,
  AppFormMobileField,
  AppFormSwitchField,
  AppFormTextArea,
} from "../../../../../../../AppComponents/Forms";
import { theme } from "../../../../../../../Customization/Theme";
import AppModel from "../../../../../../../Hooks/useModel/AppModel";
import { ReactComponent as EyeIcon } from "../../../../../../../assets/images/eye-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../../../assets/images/icons/delete.svg";

const VoiceFunctions = (): JSX.Element => {
  const { values }: any = useFormikContext();
  const [openWebhook, setOpenWebhook] = useState(false);
  const [openTransferToHuman, setOpenTransferToHuman] = useState(false);
  return (
    <Container>
      <>
        <FormContent>
          <p>
            Enable your agent with capabilities such as calendar bookings, call
            termination...
          </p>
          {/* Transfer to Human */}
          <div className="fields-header">
            <AppFormSwitchField
              label="Transfer to Human"
              divStyle={{ width: "auto" }}
              name="transfer_to_human"
              labelStyle={{ color: "#09090B" }}
              tooltip={"Transfer to Human"}
            />

            {/* <div
              className="add-container"
              onClick={() => setOpenTransferToHuman(true)}
            >
              <span className="add-text">Add</span>
              <PlusIcon />
            </div> */}
            <AppButton
              isDisabled={!values?.transfer_to_human}
              endIcon={"ri-add-line"}
              variant={!values?.transfer_to_human ? "outline" :"outline"}
              onClick={() => setOpenTransferToHuman(true)}
            >
              Add
            </AppButton>
          </div>
          <FieldArray name="functions">
            {({ push, remove }) => (
              <>
                {values?.functions?.map((item: any, index: number) => (
                  <FunctionItemRow key={index}>
                    <AppFormField
                      label=""
                      name={`functions[${index}].name`}
                      placeholder="Please enter human name"
                      disabled={!values?.transfer_to_human}
                    />
                    <DeleteIcon
                      onClick={() => remove(index)}
                      style={{ cursor: "pointer" }}
                    />

                    <EyeIcon style={{ cursor: "pointer" }} />
                  </FunctionItemRow>
                ))}
              </>
            )}
          </FieldArray>

          {/* Webhook */}
          {/* <div className="fields-header">
            <AppFormSwitchField
              label="Webhook"
              divStyle={{ width: "auto" }}
              name="webhook"
              labelStyle={{ color: "#09090B" }}
              tooltip={"Webhook"}
            />

            <div className="add-container" onClick={() => setOpenWebhook(true)}>
              <span className="add-text">Add</span>
              <PlusIcon />
            </div>
          </div>
          <FieldArray name="functions">
            {({ push, remove }) => (
              <>
                {values?.webhooks?.map((item: any, index: number) => (
                  <FunctionItemRow key={index}>
                    <AppFormField
                      label=""
                      name={`functions[${index}].webhook`}
                      placeholder="Please enter webhook"
                      disabled={true}
                    />
                    <DeleteIcon
                      onClick={() => remove(index)}
                      style={{ cursor: "pointer" }}
                    />

                    <EyeIcon style={{ cursor: "pointer" }} />
                  </FunctionItemRow>
                ))}
              </>
            )}
          </FieldArray> */}

          {/* Book a Meeting */}
          {/* <div className="fields-header">
            <AppFormSwitchField
              label="Book a meeting"
              divStyle={{ width: "auto" }}
              name="webhook"
              labelStyle={{ color: "#09090B" }}
              tooltip={"Book a meeting"}
            />

            <div className="add-container">
              <span className="add-text">Add</span>
              <PlusIcon />
            </div>
          </div> */}
        </FormContent>
        {/* </AppForm> */}

        <AppModel
          isShowing={openWebhook}
          divStyle={{
            padding: 0,
          }}
          PaperProps={{
            style: {
              boxShadow: "none",
              width: "700px",
            },
          }}
        >
          <AppForm
            initialValues={{}}
            onSubmit={() => {}}
            validationSchema={undefined}
          >
            <ModalContainer>
              <div className="header">
                <h4>Add Webhook</h4>
                <div className="header-right">
                  <span
                    onClick={() => {
                      setOpenWebhook(false);
                    }}
                  >
                    <AppMaterialIcons iconName="close" />
                  </span>
                </div>
              </div>
              <div className="body">
                <AppFormField
                  label="Webhook"
                  name="webhook"
                  placeholder="Write webhook name"
                  requiredSign
                  tooltip={"Webhook"}
                />

                <AppFormTextArea
                  name="webhook_description"
                  label="Description"
                  placeholder="Write webhook description"
                  rows={20}
                />

                <AppFormField
                  label="Webhook URL"
                  name="webhook_url"
                  placeholder="https://www.example.com/webhook"
                  requiredSign
                  tooltip={"Webhook URL"}
                />
              </div>
              <div className="footer">
                <AppButton
                  variant="grey"
                  onClick={() => {
                    setOpenWebhook(false);
                  }}
                >
                  Cancel
                </AppButton>
                <AppButton
                  onClick={() => {
                    console.log("Add Webhook");
                  }}
                >
                  Add
                </AppButton>
              </div>
            </ModalContainer>
          </AppForm>
        </AppModel>
        {/* Transfer to Human Model */}
        <AppModel
          isShowing={openTransferToHuman}
          divStyle={{
            padding: 0,
          }}
          PaperProps={{
            style: {
              boxShadow: "none",
              width: "700px",
            },
          }}
        >
          <AppForm
            initialValues={{}}
            onSubmit={() => {}}
            validationSchema={undefined}
          >
            <ModalContainer>
              <div className="header">
                <h4>Transfer to human</h4>
                <div className="header-right">
                  <span
                    onClick={() => {
                      setOpenTransferToHuman(false);
                    }}
                  >
                    <AppMaterialIcons iconName="close" />
                  </span>
                </div>
              </div>
              <div className="body">
                <AppFormField
                  label="Name"
                  name="first_name"
                  placeholder="Write webhook name"
                  requiredSign
                  tooltip={"Webhook"}
                />
                <AppFormMobileField
                  name="phone_number"
                  label="Phone Number"
                  mobileCountryFormat="phone_number_country"
                  requiredSign
                  tooltip={"Phone Number"}
                />
              </div>
              <div className="footer">
                <AppButton
                  variant="grey"
                  onClick={() => {
                    setOpenTransferToHuman(false);
                  }}
                >
                  Cancel
                </AppButton>
                <AppButton
                  onClick={() => {
                    console.log("Transgfer to Human");
                  }}
                >
                  Add
                </AppButton>
              </div>
            </ModalContainer>
          </AppForm>
        </AppModel>
      </>
    </Container>
  );
};

export default VoiceFunctions;

const Container = styled.div`
  && {
    width: 100%;
    height: 100%;
    background: ${theme.palette.default.white};
  }
`;

const FormContent = styled.div`
  && {
    padding: 1rem;
    width: 60%;
    height: 100%;
    p {
      margin-bottom: 1rem;
    }
    .fields-header {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      align-items: center;
    }
    .add-text {
      color: ${theme.palette.primary.main};
    }
    .add-container {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
    }
  }
`;
const FunctionItemRow = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    gap: 10px;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
    padding:0 0.8rem;
    .header-left {
      display: flex;
      gap: 0.875rem;
      }
      .text h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .text p {
        color: ${theme.palette.default.darkGrey};
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }
  .content{
    margin:10px 0;
    padding: 10px 15px; 
}
  .body {
    padding: 0.8rem;
    height: calc(100% - 110px);
    p {
    margin-bottom: 20px;
    font-size: 16px;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
    border-top: 1px solid ${theme.palette.default.border};
    .change-template-name {
      display: flex;
      justify-content: space-between;
      align-items: start;

      p.change-name {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;
