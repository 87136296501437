// vendors
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

// components
import Input from "../../../Components/Input";
import InteractiveMessageHeader from "../InteractiveMessage/Header";
import InteractiveMessageBody from "../InteractiveMessage/InteractiveMessageBody";
import {
  getInitialEditiorText,
  getPlainTextData,
} from "../../../../../Components/FlowBuilder/nodes/utils/editor";
import { getProcessedLength } from "../../../Utils/editor";
import { useEffect } from "react";

type Header = {
  type: string;
  text?: string;
  id?: string;
  link?: string;
  filename?: string;
  value?: string;
};
type Props = {
  characterLimit: { header: number; body: number; footer: number };
  header: Header;
  body: string;
  footer: string;
  onBodyChange: (text: string) => void;
  onHeaderChange: (data: Header) => void;
  onFooterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isActive?: boolean;
  isList?: boolean;
};

const InteractiveContent = ({
  characterLimit,
  header,
  body,
  footer,
  onBodyChange,
  onHeaderChange,
  onFooterChange,
  isActive,
  isList,
}: Props) => {
  return (
    <>
      <InputLabel
        shrink
        htmlFor="footer"
        sx={{ position: "static", transform: "none" }}
      >
        Header {((header?.type && header?.type !== "none") || (header?.value && header?.value !== "none")) ? (
          <sup style={{ color: "red" }}>*</sup>
        ) : ""}
      </InputLabel>
      <InteractiveMessageHeader
        data={header}
        isList={isList}
        characterLimit={characterLimit.header}
        onChange={onHeaderChange}
      />
      <InputLabel
        shrink
        htmlFor="footer"
        sx={{ position: "static", transform: "none" }}
      >
        Body <sup style={{ color: "red" }}>*</sup>
      </InputLabel>
      <InteractiveMessageBody
        text={body}
        maxTextLimit={characterLimit?.body}
        onChange={onBodyChange}
        error={getPlainTextData(getInitialEditiorText(body)) === ""}
      />
      <FormControl variant="standard" fullWidth sx={{ mb: "16px" }}>
        <InputLabel
          shrink
          htmlFor="footer"
          sx={{ position: "static", transform: "none" }}
        >
          Footer <span style={{ color: "#eeeeee" }}>(optional)</span>
        </InputLabel>
        <Input
          id="footer"
          placeholder="Footer"
          onChange={onFooterChange}
          value={footer}
          {...{
            helperText: characterLimit.footer ? (
              <Typography variant="body2" align="right">
                {`${getProcessedLength(footer)}/${characterLimit.footer}`}
              </Typography>
            ) : (
              ""
            ),
          }}
        />
      </FormControl>
    </>
  );
};

export default InteractiveContent;
