interface Contact {
    id: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    mobile_phone_number?: string;
  }

export const getDisplayName = (contact: Contact): string => {
    if (contact.first_name && contact.last_name) return `${contact.first_name} ${contact.last_name}`;
    if (contact.first_name) return contact.first_name;
    if (contact.last_name) return contact.last_name;
    if (contact.email) return contact.email;
    if (contact.mobile_phone_number) return contact.mobile_phone_number;
    return "-";
  };