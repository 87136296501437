import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../Store';
import { ContactsActions } from '../../../Store/Slices/Contacts/contacts.action';
import { useNotification } from '../../../Hooks/useNotification/useNotification';
import { ConatctsLifeCycle, ContactBasicInfo, contactDetailsId } from '../../../Store/Slices/Contacts/contacts.selector';
import { Box, Divider, List, ListItem, ListItemButton, Popover } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import AppModel from '../../../Hooks/useModel/AppModel';
import styled from 'styled-components';
import { theme } from '../../../Customization/Theme';
import RenderDynamicFields from '../contactDetailedComponents/FieldsLogic/RenderDynamicFields';
import { useSearchParams } from 'react-router-dom';

interface LifecycleProps {
  columns: any;
  anchorEl: any;
  setAnchorEl: any;
  setLoading: any;
  setIsUpdating: any;
}

const LifeCycleUpdate: React.FC<LifecycleProps> = ({ columns, anchorEl, setAnchorEl, setLoading, setIsUpdating }) => {
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const basicInfo: any = useAppSelector(ContactBasicInfo);
  const contactId: any = useAppSelector(contactDetailsId);
  const [lifecycleValue, setLifecycleValue] = useState<any>({});
  const [userLifeCycleValues, setUserLifeCycleValues] = useState<any>({});
  let payload: any = {};
  const filteredColumns = columns?.map((eachCol: any)=>eachCol.id === "select"?"id":eachCol.id);
  const [searchParams] = useSearchParams();
  const currentViewId = searchParams.get("view");
  const params = searchParams.get("page") || "1";
  const page = Number(params)-1;
  useEffect(() => {
    let tempValues: any = {};
    if (basicInfo?.lifecycle_fields?.[0]?.fields.length > 0) {
      basicInfo?.lifecycle_information?.forEach((field: any) => {
        if (field.is_selected) {
          tempValues.lifecycle_stage = field;
          tempValues.lifecycle_stage_status = field.statuses.find(
            (status: any) => status.is_selected
          );
        }
      });
      setUserLifeCycleValues(tempValues);
    }
  }, [basicInfo?.lifecycle_fields?.[0]?.fields]);

  useEffect(() => {
    if (basicInfo?.lifecycle_fields?.[0]?.fields) {
      let data = [...basicInfo?.lifecycle_fields];

      let lifecycleFieldData: any = {
        ...data[0],
        "fields": basicInfo?.lifecycle_fields?.[0]?.fields
      }
      setLifecycleValue(lifecycleFieldData)
    }
  }, [basicInfo?.lifecycle_fields?.[0]?.fields]);



  const closeModal = () => {
    setAnchorEl(null)
  }

  const getContactDetails = () => {
    let payload = {
      contact_id: contactId,
    };

    setAnchorEl(null)

    dispatch(ContactsActions.getBasicInfo(payload))
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(
            res?.payload?.data?.message || "Enabled to fetch Data",
            "error"
          );
        } else {
        }
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {
      });
  };

  const updateContactData = () => {
    setLoading(true);
    setIsUpdating(true);
    setAnchorEl(null)
    dispatch(ContactsActions.getContactsListData({
      data: {
        columns: filteredColumns,
        view_id: currentViewId,
        page_number: page,
        page_size: 50
      }
    }))
    .then(()=>{
      setLoading(false);
      setIsUpdating(false);
    })
  }


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeModal}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          boxShadow: `0 2px 15px 0.5px ${theme.palette.default.heather}`,
          "& .MuiPaper-root": {
            borderRadius: 1,
            boxShadow: `0 0 15px 0 ${theme.palette.default.paleGray}`,
            width: "420px",
            paddingBottom: "10px"
          },
        }}
      >
        <Box
        >
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "500", fontSize: "20px" }}>
              Edit Lifecycle Stage/Status
            </span>
            <CloseIcon
              onClick={() => closeModal()}
              sx={{
                cursor: "pointer",
              }}
            />
          </ListItem>
          <Divider />
          <LifeCycleWrapper style={{ paddingRight: "20px", paddingLeft: "20px" }}>
            {basicInfo?.lifecycle_fields?.length > 0 &&
              basicInfo?.lifecycle_fields?.[0]?.fields?.length > 0 &&
              Object.keys(userLifeCycleValues).length > 0 ? (
              <>
                <RenderDynamicFields
                  AddContactsFields={[lifecycleValue]}
                  ConatctDetailsById={JSON.parse(
                    JSON.stringify(
                      basicInfo?.contact_lifecycle_data || {}
                    ),
                    (key, value) =>
                      value === null || value === "" ? undefined : value
                  )}
                  ContactId={contactId}
                  cancel={() => closeModal()}
                  hideLabelSectionTitle={true}
                  updateData={getContactDetails}
                  updateContact={updateContactData}
                />
              </>
            ) : null}
          </LifeCycleWrapper>
        </Box>
      </Popover>
    </>
  )
}

export default LifeCycleUpdate

export const LifeCycleWrapper = styled.div`

  .formWrapperUI {
    padding-top: 0px !important;

    .MuiGrid-root.MuiGrid-grid-xs-12:first-child {
      margin-top: 17px;
    }
  }

  .MuiGrid-item {
    padding-top: 20px;
  }
`;

