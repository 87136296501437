import styled from "styled-components";
// custom imports
import { AppFormField } from "../../../../../../../AppComponents/Forms";
import AppReactSelectField from "../../../../../../../AppComponents/Forms/AppFormReactSelect";
import { theme } from "../../../../../../../Customization/Theme";
import { getLanguageOptions } from "../../../utils";

const VoiceBotGeneralSettings = (): JSX.Element => {
  const languageOptions = getLanguageOptions();
  return (
    <Container>
      <AppFormField
        label="Company name"
        name="company_name"
        placeholder="Please enter company name"
        tooltip={"Company Name"}
        // requiredSign
      />
      <AppReactSelectField
        name="language"
        options={languageOptions}
        label={"Select Language"}
        displayKey="label"
        valueKey="id"
        isSearchable={true}
        isClearable={true}
        hideSelectedOptions={true}
        divStyle={{ background: "none", padding: "0"}}
        optionsPlaceholder="No Options Found"
        tooltip={"Select language"}
        maxWidth="100%"
        onChangeCallbackFn={(res: any) => {
          console.log(res, "res");
        }}
      />
    </Container>
  );
};

export default VoiceBotGeneralSettings;

const Container = styled.div`
  && {
    padding: 1rem;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    background: ${theme.palette.default.white};
  }
`;
