import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../../AppComponents/AppButton";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../../HOC/HocBackdropLoader";
import useDocumentTitle from "../../../../Hooks/useDocumentTitle";
import { getBotTrackingCodeFn } from "../../../../Services/Apis";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { useAuthStore } from "../../../../Store/Slices/AuthSlice";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import { formatString, isEmptyObject } from "../../../../Utils";
import { VoiceBotActions } from "../../../../Store/Slices/Voicebot/voicebot.actions";
import { getVoiceBotDetails } from "../../../../Store/Slices/Voicebot/voicebot.selectors";

export interface Props extends HocBackdropLoaderProps {}

const VoiceBotIndex: React.FC<Props> = ({ setLoading }: any) => {
  useDocumentTitle("Voice Bot");
  const { botId }: any = useParams();
  const { userConfig }: any = useAppSelector(useAuthStore);
  const currentBot: any = useAppSelector(useCurrentBot);
  const botDetails = useAppSelector(getVoiceBotDetails);
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState<any>(0);
  const [userInput, setUserInput] = useState<any>({
    bot_enabled: false,
    is_pubilsh_button: false,
  });

  let dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(VoiceBotActions.getBotDeatils(botId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (currentBot?.id) {
      getBotTrackingCodeFn(dispatch)({
        pathParams: [currentBot?.id],
      });
    }
  }, [currentBot?.id]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (currentBot) {
      setUserInput({
        ...userInput,
        bot_enabled: currentBot.bot_enabled,
      });
    }
  }, [currentBot?.bot_enabled]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCurrentTabAfterRefresh = () => {
    memoizedMenuOptions?.forEach((item: any, index: number) => {
      if (location.pathname.includes(item?.value)) {
        setValue(index);
      }
    });
  };

  const memoizedMenuOptions = useMemo(() => {
    const permissionsData: any =
      userConfig?.payload?.allowed_permissions ?? null;

    if (isEmptyObject(permissionsData)) return;
    // userConfig?.payload?.menu_items?.["bot_builder_tabs_options"] ?? [];
    const result: any = [
      {
        isAvailable: true,
        isDisabled: false,
        label: "Agent Setup",
        moduleId: "customize",
        url: `/voice-bot/${botId}/setup/agent`,
        value: "setup/agent",
      },
      {
        isAvailable: true,
        isDisabled: false,
        label: "Bot Knowledge",
        moduleId: "manage_bot_knowledgebase",
        url: `/voice-bot/${botId}/setup/voice-bot-knowledge`,
        value: "setup/bot-knowledge",
      },
      {
        isAvailable: true,
        isDisabled: false,
        label: "Call Logs",
        moduleId: "deploy_bot",
        url: `/voice-bot/${botId}/setup/call-logs`,
        value: "setup/call-logs",
      }
    ];

    return result;
  }, [userConfig?.payload]); // eslint-disable-line react-hooks/exhaustive-deps

  //*  do not remove location dependency
  useEffect(() => {
    getCurrentTabAfterRefresh();
  }, [memoizedMenuOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    handleRedirect(memoizedMenuOptions?.[newValue]?.url);
  };
  const handleRedirect = (subUrl: string) => {
    const url = formatString(subUrl, [botId || currentBot.bot_id]);
    navigate(url);
  };

  if (memoizedMenuOptions?.length === 0) return null;
  return (
    <BotBuilderWrap>
      <VoicesHeaderContainer>
        <div className="voices-header-left">
          <>
            <Box
              onClick={() => navigate("/workspace/geta_ai/setup/bots")}
              sx={{ cursor: "pointer" }}
            >
              <NavigateBeforeIcon fontSize="small" />
            </Box>
            {/* Breadcrumb Navigation */}
            <StyledBreadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <p>{botDetails?.voicebot?.name || "Voice Bot"}  </p>
            </StyledBreadcrumbs>
          </>
        </div>

        {/* Test Chatbot Button */}
        {/* need to check this functoinality for disabled */}
        <AppButton isDisabled={false}>Test Chatbot</AppButton>
      </VoicesHeaderContainer>
      <TopTabbarWrap>
        <TabsBox>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <StyledBuilderTabs
                value={value}
                onChange={handleChange}
                aria-label="bot builder"
                variant="scrollable"
                scrollButtons="auto"
              >
                {memoizedMenuOptions &&
                  memoizedMenuOptions?.length > 0 &&
                  memoizedMenuOptions.map((curElem: any, index: number) => {
                    return (
                      <Tab
                        label={curElem?.label}
                        id={`simple-tab-${index}`}
                        key={index}
                        disableRipple
                      />
                    );
                  })}
              </StyledBuilderTabs>
            </Box>
          </Box>
        </TabsBox>
        <ActionBox></ActionBox>
      </TopTabbarWrap>

      {memoizedMenuOptions &&
        memoizedMenuOptions?.length > 0 &&
        memoizedMenuOptions.map((curElem: any, index: number) => {
          return (
            <TabPanel value={value} index={index} key={index}>
              <Outlet />
            </TabPanel>
          );
        })}
    </BotBuilderWrap>
  );
};

export default HocBackdropLoader(VoiceBotIndex);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        height: "calc(100vh - 220px)",
        overflowY: "hidden",
        margin: "16px 0 0 16px",
      }}
    >
      {value === index && (
        <BotBuilderContentBox>{children}</BotBuilderContentBox>
      )}
    </div>
  );
}

const BotBuilderWrap = styled.div`
  && {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
    height: 100%;
    background-color: #f5f6f8;
    overflow: hidden;
  }
`;

const TopTabbarWrap = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem 0 1.1rem;
    gap: 0.5rem;
    width: 100%;
    min-height: 40px;
    height: 40px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
  }
`;
const TabsBox = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;
    .MuiBox-root {
      border: none !important;
    }
  }
`;
const ActionBox = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 0.5rem;
    width: 40%;
    height: 100%;
    background-color: white;
  }
`;

const BotBuilderContentBox = styled.div`
  && {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

export const StyledBuilderTabs = styled(Tabs)`
  && {
    .MuiTabs-indicator {
      background-color: #5b73e8;
    }
    .Mui-selected {
      color: #5b73e8 !important;
      background-color: white;
    }
    .MuiTab-root {
      min-height: 38px;
      max-height: 38px;
      height: 38px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      color: #7e8392;
      text-transform: capitalize;
      margin-right: 1rem;
      overflow: hidden;
    }
  }
`;

// Header
const VoicesHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px 20px;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  .voices-header-left {
    display: flex;
    gap: 8px;
    align-items: center;
    .MuiSvgIcon-root {
      cursor: pointer;
    }
  }
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  font-size: 14px;
  color: #5f6368;
  p {
    font-size: 14px;
    font-family: Rubik, Helvetica, sans-serif;
  }
`;
