import React, { useEffect, useRef, useState } from "react";
import {
  Paper,
  InputBase,
  FormHelperText,
  IconButton,
  InputBaseProps,
  Tooltip,
} from "@mui/material";
import SelectPopover from "../SelectPopover";
import { ReactComponent as SelectIcon } from "../../../../assets/images/icons/custom-select.svg";
import { useAppDispatch } from "../../../../Store";
import {
  getApiListData,
  getContactPropertyData,
} from "../../Slices/main.slice";
import { useSelector } from "react-redux";
import { transformApiAndContactOptions } from "../Editor/utils/common";
import { getApiList, getContactPropertyList } from "../../Slices/main.service";

interface InputProps extends Omit<InputBaseProps, "value" | "onChange"> {
  helperText?: React.ReactElement | string | false;
  max?: number;
  value?: string | number; // Explicitly type value as string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // Ensure proper onChange typing
}

const Input = (props: InputProps) => {
  const { placeholder, onChange, error, helperText, max } = props;
  const value: string = props?.value?.toString() || "";
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
  const [optionsStack, setOptionsStack] = useState<any[]>([]);

  const dispatch = useAppDispatch();

  // Get contact properties and API list from the Redux store
  const contactProperties = useSelector(getContactPropertyData);
  const apiList = useSelector(getApiListData);
  const [searchTerm, setSearchTerm] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
    setApiAndContactOptions();
    setSearchTerm("");
  };

  const handleOptionClick = (option: any) => {
    if (option.options) {
      setOptionsStack(option.options); // Navigate into nested options
      setSearchTerm("");
    } else {
      const inputElement = inputRef.current;
      if (inputElement) {
        const cursorPos = inputElement.selectionStart ?? value.length;
        const updatedValue =
          value.slice(0, cursorPos) + option.value + value.slice(cursorPos);
        onChange?.({
          target: { value: updatedValue } as HTMLInputElement,
        } as React.ChangeEvent<HTMLInputElement>);
      }
      handlePopoverClose();
    }
  };

  const setApiAndContactOptions = () => {
    const { apiOptions, contactOptions } = transformApiAndContactOptions(
      apiList,
      contactProperties
    );
    setOptionsStack([
      {
        label: "Contact Properties",
        value: "property",
        options: contactOptions,
      },
      {
        label: "API's",
        value: "api",
        options: apiOptions,
      },
    ]);
  };
  useEffect(() => {
    // Call the utility function and update currentOptions state
    setApiAndContactOptions();
  }, [apiList, contactProperties]);

  useEffect(() => {
    dispatch(getContactPropertyList());
    dispatch(getApiList());
  }, []);
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          ...(props.fullWidth && { flex: 1 }),
          display: "flex",
          alignItems: "center",
          backgroundColor: error ? "#f3d1d15c" : "#F5F6F8",
          border: error ? "1px solid red" : "",
          borderRadius: "5px",
        }}
        className="ignore-onClickOutside"
      >
        <InputBase
          size="medium"
          sx={{ flex: 1 }}
          inputProps={{
            style: {
              color: "#7E8392",
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: 400,
              padding: "10px 12px",
            },
            inputProps: {
              maxLength: max,
            },
          }}
          placeholder={placeholder}
          onChange={onChange}
          error={error}
          value={value}
          className="ignore-onClickOutside"
          inputRef={inputRef}
          {...props}
        />
        <Tooltip title={"Please select a value within the input limit."} arrow>
          <IconButton onClick={handleIconClick} size="small" sx={{ p: 0.5 }}>
            <SelectIcon />
          </IconButton>
        </Tooltip>
      </Paper>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
      <SelectPopover
        anchorEl={popoverAnchor}
        isOpen={Boolean(popoverAnchor)}
        optionsStack={optionsStack}
        searchTerm={searchTerm}
        onClose={handlePopoverClose}
        onBackClick={() => {}}
        onSearchChange={setSearchTerm}
        onOptionClick={handleOptionClick}
      />
    </>
  );
};

export default Input;
