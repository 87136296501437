import { createAsyncThunk } from "@reduxjs/toolkit";
import { VoiceBotNetworkService } from "./voicebotNetworkService";

export class VoiceBotActions {
  // get Webhook
  static createVoiceBot = createAsyncThunk(
    "WebhookSlice/createVoiceBot",
    (data: any, { dispatch, rejectWithValue }) => {
      return VoiceBotNetworkService.createVoiceBot(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static getBotDeatils = createAsyncThunk(
    "WebhookSlice/getBotDeatils",
    (data: any, { dispatch, rejectWithValue }) => {
      return VoiceBotNetworkService.getBotDeatils(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static updateBotDetails = createAsyncThunk(
    "WebhookSlice/updateBotDetails",
    async ({ data, botId }: { data: any; botId: any }, { rejectWithValue }) => {
      try {
        const response = await VoiceBotNetworkService.updateVoiceBot(
          data,
          botId
        );
        return response;
      } catch (error: any) {
        return rejectWithValue(error?.response || error);
      }
    }
  );

  static getVoiceBotLogs = createAsyncThunk(
    "WebhookSlice/getVoiceBotLogs",
    async (data: any, { rejectWithValue }) => {
      try {
        const response = await VoiceBotNetworkService.getVoiceBotLogs(data);
        return response;
      } catch (error: any) {
        return rejectWithValue(error?.response || error);
      }
    }
  );
  // get Voice List
  static getVoicesList = createAsyncThunk(
    "WebhookSlice/getVoicesList",
    async (params: any, { rejectWithValue }) => {
      try {
        const response = await VoiceBotNetworkService.getVoicesList(params);
        return response;
      } catch (error: any) {
        return rejectWithValue(error?.response || error);
      }
    }
  );
}
