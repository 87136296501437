// vendors
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
import isUrl from "is-url";

// components
import UIButton from "../../Components/Button";
import Input from "../../Components/Input";
import NodeType from "../../Components/NodeType";
import Icon from "../../Components/Icon";
import InputOption from "../../Components/InputOption";

// utils
import { getNodeConfig } from "../../Utils";
import { getProcessedLength, isApiVariable } from "../../Utils/editor";

// slice
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";

type Props = {
  language: string;
  onSave: (button: any) => void;
  onClose: () => void;
  activeButtonData: any;
  maxCharLength: number;
  addDescription?: boolean;
};

const LinkButtonInput = (props: Props) => {
  const {
    onClose,
    onSave,
    activeButtonData,
    language,
    maxCharLength,
    addDescription,
  } = props;

  const CHARACTER_LIMITS = {
    button: maxCharLength,
    description: 72,
  };
  
  const [value, setValue] = useState<string>("");
  const [urlValue, setURLValue] = useState<string>("");
  const [isURLValid, setURLValidity] = useState<boolean>(false);
  const [descriptionValue, setDescriptionValue] = useState<string>("");
  
  const node = getNodeConfig("button");
  const currentBot = useSelector(useCurrentBot);

  useEffect(() => {
    if (activeButtonData) {
      setValue(activeButtonData.value.text || "");
      setURLValue(activeButtonData.value.url || "");
      setDescriptionValue(activeButtonData.value?.description || "");
      
      // Validate URL on initial load
      const urlVal = activeButtonData.value.url || "";
      if (isApiVariable(urlVal)) {
        setURLValidity(true);
      } else {
        setURLValidity(isUrl(urlVal));
      }
    }
  }, [activeButtonData]);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleSaveClick = () => {
    const updatedAction = {
      ...activeButtonData,
      type: "link",
      value: {
        ...activeButtonData.value,
        text: value,
        url: urlValue,
        ...(descriptionValue ? { description: descriptionValue } : {})
      }
    };
    onSave && onSave(updatedAction);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (getProcessedLength(value) > CHARACTER_LIMITS.button) return;
    setValue(value);
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setURLValue(value);
    if (isApiVariable(value)) {
      setURLValidity(true);
    } else {
      setURLValidity(isUrl(value));
    }
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    if (value.length > CHARACTER_LIMITS.description) return;
    setDescriptionValue(value);
  };

  return (
    <Box sx={{ width: "345px", maxWidth: "345px" }}>
      <Box
        sx={{
          py: "4px",
          position: "relative",
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #F5F6F8;",
        }}
      >
        <NodeType text={node.name} color={node.color} icon={node.icon} />
        <Box
          sx={{
            position: "absolute",
            height: "24px",
            width: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "20px",
            right: "16px",
          }}
        >
          <IconButton onClick={handleClose}>
            <Icon icon="close" size={12} color="#7E8392" />
          </IconButton>
        </Box>
      </Box>
      <>
        <Box sx={{ p: "16px" }}>
          <Typography
            sx={{
              color: "#7E8392",
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: 400,
            }}
          >
            Button Title
          </Typography>
          <Input
            error={value === ""}
            placeholder="Title"
            onChange={handleInputChange}
            value={value}
            {...{
              helperText: maxCharLength ? (
                <Stack direction={"row"} spacing={2}>
                  <Typography variant="body2" align="right">
                    {`${getProcessedLength(value)}/${CHARACTER_LIMITS.button}`}
                  </Typography>
                </Stack>
              ) : (
                ""
              ),
            }}
          />
          
          <Typography
            sx={{
              color: "#7E8392",
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: 400,
              mt: 2
            }}
          >
            Enter URL
          </Typography>
          <Input
            placeholder="https://example.com"
            onChange={handleUrlChange}
            value={urlValue}
            error={urlValue !== "" && !isURLValid}
          />

          {addDescription || descriptionValue?.length > 0 ? (
            <>
              <Typography
                sx={{
                  color: "#7E8392",
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: 400,
                  mt: 2
                }}
              >
                Button Description
              </Typography>
              <Input
                placeholder="Description"
                onChange={handleDescriptionChange}
                value={descriptionValue}
                {...{
                  helperText: CHARACTER_LIMITS.description ? (
                    <Typography variant="body2" align="right">
                      {`${descriptionValue.length}/${CHARACTER_LIMITS.description}`}
                    </Typography>
                  ) : (
                    ""
                  ),
                }}
              />
            </>
          ) : null}
        </Box>

        <Box sx={{ borderTop: "1px solid #F5F6F8", p: "16px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <UIButton variant="outlined" onClick={handleClose}>
              Cancel
            </UIButton>
            <UIButton
              color="primary"
              variant="contained"
              startIcon={<Icon icon="save" color="#fff" size={13} />}
              disabled={language !== "english" || !isURLValid || value === ""}
              onClick={handleSaveClick}
            >
              Save
            </UIButton>
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default LinkButtonInput; 