const isEmptyObject = (obj: any) => 
    obj && typeof obj === 'object' && !Array.isArray(obj) && Object.keys(obj).length === 0;
  
  const isEmptyArray = (arr: any) => Array.isArray(arr) && arr.length === 0;
  
  export const filterObject = (obj: any): any => {
    const filteredObj: any = {};
  
    // Iterate over the entries of the input object
    Object.entries(obj).forEach(([key, value]) => {
      // Add the value to the filtered object if it's valid
      if (value !== null && value !== undefined && value !== "") {
        if (typeof value === 'object' && !Array.isArray(value)) {
          if (!isEmptyObject(value)) {
            filteredObj[key] = value;
          }
        } else if (Array.isArray(value)) {
          if (!isEmptyArray(value)) {
            filteredObj[key] = value;
          }
        } else {
          filteredObj[key] = value;
        }
      }
    });
  
    return filteredObj;
  };


  export function convertCurlToJson(curlCommand: string) {
    const jsonOutput: any = {
      method: '',
      url: '',
      headers: {},
      data: {}
    };
  
    const methodRegex = /--request\s+([A-Z]+)/;
    const urlRegex = /'([^']+)'/;
    const headerRegex = /--header\s+'([^']+)'/g;
    const dataRegex = /--data(?:-raw)?\s+'([^']+)'/;
  
    // Extract method (GET, POST, etc.)
    const methodMatch = curlCommand.match(methodRegex);
    jsonOutput.method = methodMatch ? methodMatch[1] : 'GET';
  
    // Extract URL
    const urlMatch = curlCommand.match(urlRegex);
    jsonOutput.url = urlMatch ? urlMatch[1] : '';
  
    // Extract headers
    let headerMatch;
    while ((headerMatch = headerRegex.exec(curlCommand)) !== null) {
      const headerParts = headerMatch[1].split(':');
      const headerKey = headerParts[0].trim();
      const headerValue = headerParts[1].trim();
      jsonOutput.headers[headerKey] = headerValue;
    }
  
    // Extract data (if any) for --data or --data-raw
    const dataMatch = curlCommand.match(dataRegex);
    if (dataMatch) {
      // Try parsing as JSON, if that fails, just use as a string
      try {
        jsonOutput.data = JSON.parse(dataMatch[1]);
      } catch (e) {
        jsonOutput.data = dataMatch[1];
      }
    }
  
    return jsonOutput;
  }


  export function replaceVariables(config: any[], data: any): any {
    if (typeof data === 'string') {
        // Replace the placeholder if it matches a config value
        const match = config?.find((conf: any) => data === `{{${conf.name}}}`);
        return match?.default_value || data;
    } else if (Array.isArray(data)) {
        // Recursively handle arrays
        return data.map((item: any) => replaceVariables(config, item));
    } else if (typeof data === 'object' && data !== null) {
        // Recursively handle objects
        return Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key, replaceVariables(config, value)])
        );
    }
    return data; // Return as is if not a string, object, or array
}



  
  
  
