import  { useState, useRef, useEffect, useCallback, useMemo } from "react";
import AppButton from "../../../../../AppComponents/AppButton";
import { Drawer, Backdrop, IconButton, Box } from "@mui/material";
import { Close } from "@material-ui/icons";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import styled from "styled-components";
import {
  AppForm,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import AppReactSelectField from "../../../../../AppComponents/Forms/AppFormReactSelect";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { WhatsappNetworkService } from "../../../../../Store/Slices/Whatsapp/WhatsappNetworkService";
import { FacebookCircularProgress } from "../../../../../socket/appComponents/UtilsComponents/AppNoActivityScreen";
import { WhatsappActions } from "../../../../../Store/Slices/Whatsapp/whatsapp.actions";
import { whatsappDataStore } from "../../../../../Store/Slices/Whatsapp/whatsapp.selectors";
import { dataToTemplateFormData } from "../../../../../Components/Broadcast/WhatsApp/Utils/utils";
import WhatsappBroadcastResolveVaribles from "../../../../../Components/Leads/contact-action/utils/WhatsappBroadcastResolveVaribles";
import { singleWhatsappBroadcastSchemaFlowBuilder } from "./ValidationsSchema";
import {  updateModifiedData } from "../../../Slices/main.slice";
import { v4 as uuid } from "uuid";
import { convertFormToPayloadSingleWhatsappBroadcast } from "./utils";
import { useSelector } from "react-redux";
import { getActiveNode } from "../../../Slices/main.slice";
import { theme } from "../../../../../Customization/Theme";
import { useSaveNode } from "../../../Hooks/useSaveNode";

const WhatsappTemplate = (props: NodeProps) => {
  const { node } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useAppDispatch();
  const formikRef = useRef<any>(null);
  const { showAlert } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [templateOptions, setTemplateOptions] = useState<any>([]);
  
  const { templateByIdData }: any = useAppSelector(whatsappDataStore);
  const activeNode = useSelector(getActiveNode);
  const isActiveNode = activeNode === node.nodeId;

  // Add an effect to open drawer when node becomes active
  useEffect(() => {
    if (isActiveNode && !drawerOpen) {
      setDrawerOpen(true);
      loadTemplates();
      // Set preserveValues to true when opening drawer to load saved values
      setPreserveValues(true);
    }
  }, [isActiveNode]);

  // Replace the simple variable with useMemo
  const nodeKey = useMemo(() => node?.id || 'new-node', [node?.id]);

  const [userInput, setUserInput] = useState({
    template_id: null,
    resolved_header_variables: [],
    header_media: null,
    header_type: null,
    header_media_type: null,
    body_preview: "",
    resolved_body_variables: [],
    resolved_buttons_variables: [],
    resolved_carousel_variables: [],
    carousel_type: null,
    footer_value: null,
    limitedTimeOfferDate: new Date(Date.now()),
    limitedTimeOfferTime: new Date(Date.now()),
  });

  const [preserveValues, setPreserveValues] = useState(true);

  const { saveNode } = useSaveNode();

  // Add a state variable to track when data is updated
  const [shouldSave, setShouldSave] = useState(false);

  // Add an effect to save when data is updated
  useEffect(() => {
    if (shouldSave) {
      saveNode();
      setShouldSave(false);
    }
  }, [shouldSave, saveNode]);

  // Reset form when component is mounted with a new node
  useEffect(() => {
    // Reset the form to initial values
    if (formikRef?.current) {
      formikRef.current.resetForm();
    }
    
    // Reset component state
    setUserInput({
      template_id: null,
      resolved_header_variables: [],
      header_media: null,
      header_type: null,
      header_media_type: null,
      body_preview: "",
      resolved_body_variables: [],
      resolved_buttons_variables: [],
      resolved_carousel_variables: [],
      carousel_type: null,
      footer_value: null,
      limitedTimeOfferDate: new Date(Date.now()),
      limitedTimeOfferTime: new Date(Date.now()),
    });
    // Only preserve values if this node has saved data
    setPreserveValues(node?.response && node?.response.length > 0);
    
  }, [nodeKey]); // This effect runs when the node key changes


  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    if (!drawerOpen) {
      // Load templates when drawer opens
      loadTemplates();
      // Set preserveValues to true when opening drawer to load saved values
      setPreserveValues(true);
    }
  };

  const loadTemplates = () => {
    setLoading(true);
    WhatsappNetworkService.getWhatsappTemplatesListings({
      limit: 9999,
      offset: 0,
      status: "APPROVED",
    })
      .then((result) => {
        const templatesData = result?.data?.results ?? [];
        setTemplateOptions(templatesData ?? []);
      })
      .catch((error) => {
        showAlert("Unable to fetch templates", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTemplateData = (value: any) => {
    if (!value?.id) return;
    // When user selects a new template, we should not preserve values
    if (value?.id !== node?.response?.[0]?.template_id) {
      setPreserveValues(false);
    }
    setLoading(true);
    dispatch(WhatsappActions.getTemplateById(value?.id)).finally(() => {
      setLoading(false);
    });
  };

  async function fetchData() {
    if (!templateByIdData) return;
    const data = {
      ...(await dataToTemplateFormData(templateByIdData?.original_response)),
      components: templateByIdData?.original_response?.components,
    };
    formikRef?.current?.setFieldValue("template", data);
    
    // Set the body preview from the template data
    if (templateByIdData?.original_response?.components) {
      const bodyComponent = templateByIdData?.original_response?.components.find(
        (component: any) => component.type === "BODY"
      );
      if (bodyComponent && bodyComponent.text) {
        formikRef?.current?.setFieldValue("body_preview", bodyComponent.text);
      }
      
      // Set the header preview from the template data
      const headerComponent = templateByIdData?.original_response?.components.find(
        (component: any) => component.type === "HEADER"
      );
      if (headerComponent) {
        if (headerComponent.format === "TEXT" && headerComponent.text) {
          formikRef?.current?.setFieldValue("header_value", headerComponent.text);
          formikRef?.current?.setFieldValue("header_type", "TEXT");
        } else if (["IMAGE", "VIDEO", "DOCUMENT"].includes(headerComponent.format)) {
          formikRef?.current?.setFieldValue("header_type", headerComponent.format);
        }
      }
      
      // Check if this is a carousel template
      if (templateByIdData?.original_response?.button_type === "carousel") {
        formikRef?.current?.setFieldValue("carousel_type", "carousel");
        if (!node?.response || !node?.response[0]?.components?.carousel) {
          const carouselVariables = templateByIdData?.original_response?.components
            .filter((component: any) => component.type === "CAROUSEL")
            .map((component: any, index: number) => ({
              header: {
                key: `{{${index + 1}}}`,
                type: component.format || "IMAGE",
                value: null
              },
              type: "",
              value: []
            }));
            
          if (carouselVariables.length > 0) {
            formikRef?.current?.setFieldValue("resolved_carousel_variables", carouselVariables);
          }
        }
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [templateByIdData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDebounceFn = useCallback((payload: any) => {
    dispatch(
      updateModifiedData([
        { 
          id: uuid(), 
          template_id: payload.template_id,
          ...payload
        },
      ])
    );
  }, [dispatch]);


  const handleSubmitBtn = (values: any, submitProps: any) => {
    const valuesObj = {
      ...values,
      components: values?.template?.components,
    }; 

    const payload = convertFormToPayloadSingleWhatsappBroadcast(valuesObj);
    
    // Use the debounced function to update the data
    handleDebounceFn(payload);  // Non-debounced version
    
    // Set flag to save after data is updated
    setShouldSave(true);
    
    submitProps.setSubmitting(false);
    setDrawerOpen(false);
  };

  // Load saved template data if it exists
  useEffect(() => {
    if (node?.response && node?.response[0]?.template_id && templateOptions.length > 0 && drawerOpen) {
      // Only proceed if we have a valid template ID and options
      const savedTemplate = node?.response[0];
      
      // Find the template in templateOptions if available
      const selectedTemplate = templateOptions.find(
        (template: any) => template.id === savedTemplate.template_id
      );

      
      if (selectedTemplate) {
        // Set template_id field with the selected template object
        formikRef?.current?.setFieldValue("template_id", selectedTemplate);
        
        // Fetch template data
        getTemplateData({ id: savedTemplate.template_id });
        
        // Set components data if available
        if (savedTemplate.components) {
          // Handle header values
          if (savedTemplate.components.header) {
            // Text header handling
            if (savedTemplate.components.header.type === "text") {
              formikRef?.current?.setFieldValue(
                "resolved_header_variables", 
                [{ index: savedTemplate.components.header.index, value: savedTemplate.components.header.value }]
              );
            } 
            // Media header handling (image, video, document)
            else if (["image", "video", "document"].includes(savedTemplate.components.header.type)) {
              // Create a media object for WhatsappMediaPicker
              const mediaUrl = savedTemplate.components.header.value;
              const fileName = mediaUrl.split('/').pop();
              
              const mediaObject = {
                file_name: fileName,
                url: mediaUrl,
                name: fileName,
                source: mediaUrl
              };
              
              formikRef?.current?.setFieldValue("header_media", mediaObject);
              formikRef?.current?.setFieldValue("header_media_type", savedTemplate.components.header.type.toUpperCase());
              formikRef?.current?.setFieldValue(
                "resolved_header_variables", 
                [{ 
                  index: 0, 
                  value: mediaObject,
                  type: savedTemplate.components.header.type.toUpperCase()
                }]
              );
            }
          }
          
          // Handle body values
          if (savedTemplate.components.body && savedTemplate.components.body.value) {
            formikRef?.current?.setFieldValue(
              "resolved_body_variables", 
              savedTemplate.components.body.value
            );
            
            if (savedTemplate.components.body.text) {
              formikRef?.current?.setFieldValue("body_preview", savedTemplate.components.body.text);
            }
          }
          
          // Handle button values
          if (savedTemplate.components.button && savedTemplate.components.button.value) {
            formikRef?.current?.setFieldValue(
              "resolved_buttons_variables", 
              savedTemplate.components.button.value
            );
          }
          
          // Handle carousel template
          if (savedTemplate.components.carousel) {
            formikRef?.current?.setFieldValue(
              "carousel_type", 
              "carousel"
            );
            
            // Process carousel data to map it correctly for the form
            const carouselData = savedTemplate.components.carousel.value || [];
            formikRef?.current?.setFieldValue(
              "resolved_carousel_variables", 
              carouselData.map((item: any) => {
                // Process header value if it's a string wrapped in << >>
                if (item.header && typeof item.header.value === 'string' && 
                    item.header.value.startsWith('<<') && item.header.value.endsWith('>>')) {
                  // Extract the URL from between << >>
                  const mediaUrl = item.header.value.substring(2, item.header.value.length - 2);
                  const fileName = mediaUrl.split('/').pop();
                  
                  return {
                    ...item,
                    header: {
                      ...item.header,
                      value: {
                        file_name: fileName,
                        url: mediaUrl,
                        name: fileName,
                        source: mediaUrl
                      }
                    }
                  };
                }
                
                return item;
              })
            );
          }

          // Handle footer value
          if (savedTemplate.components.footer) {
            formikRef?.current?.setFieldValue("footer_value", savedTemplate.components.footer.value);
          }
        }
      }
    }
  }, [templateOptions, drawerOpen, nodeKey, node]);

  // Add a new useEffect to handle the applyValue prop for WhatsappBroadcastResolveVaribles
  useEffect(() => {
    // This will ensure the WhatsappBroadcastResolveVaribles component doesn't override our values
    if (formikRef?.current && node?.response && node?.response[0]?.components?.body?.value) {
      // Set a flag to indicate we want to use our prefilled values
      formikRef?.current?.setFieldValue("applyValue", true);
    }
  }, [node, templateByIdData]);
  // Add effect to reset store when there's no template ID
  useEffect(() => {
    if (!node?.response || !node?.response[0]?.template_id) {
      dispatch(updateModifiedData([]))
    }
  }, [node?.response, dispatch]);

  return <>
    <TemplateCard key={nodeKey} templateSelected={node?.response && node?.response[0]?.template_id}>
      <TemplateCardContent>
        <WhatsAppIconStyled />
        {node?.response && node?.response[0]?.template_id ? (
          <Box display={"flex"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
            <Box>
              <Box display={"flex"} gap={0.5} flexDirection={"column"} justifyContent={"center"} alignItems={"flex-start"}>
                <TemplateName>
                  {templateOptions.find((t: any) => t.id === node?.response[0]?.template_id)?.name || 
                   "WhatsApp Template"}
                </TemplateName>
                <TemplateDescription>
                  WhatsApp Template Message
                </TemplateDescription>
              </Box>
            </Box>
            {isActiveNode && (
              <EditButton onClick={toggleDrawer}>
                Edit
              </EditButton>
            )}
          </Box>
        ) : (
          <>
            <EmptyTemplateInfo>
              <EmptyTemplateTitle>WhatsApp Template</EmptyTemplateTitle>
              <EmptyTemplateDescription>
                Select a template to send WhatsApp messages
              </EmptyTemplateDescription>
            </EmptyTemplateInfo>
            {isActiveNode && (
              <AppButton onClick={toggleDrawer}>
                Select Template
              </AppButton>
            )}
          </>
        )}
      </TemplateCardContent>
    </TemplateCard>
    
    <Drawer
      anchor="right"
      className="ignore-onClickOutside"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      BackdropProps={{ onClick: (event) => event.stopPropagation() }}
    >
      {loading ? (
        <Backdrop open={loading} style={{ zIndex: 9999999 }}>
          <FacebookCircularProgress />
        </Backdrop>
      ) : null}
      <Box sx={{ width: '40vw', maxWidth: '40vw', minWidth: '40vw', height: '100%' }}>      
      <StyledDrawerHeader>
        <p className="title">
          <WhatsAppIcon /> Select WhatsApp Template
        </p>
        <IconButton
          onClick={() => setDrawerOpen(false)}
          title="close"
        >
          <Close />
        </IconButton>
      </StyledDrawerHeader>
      <AppForm
        innerRef={formikRef}
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={singleWhatsappBroadcastSchemaFlowBuilder}
        divStyle={{
          flex: 1,
          height: 'calc(100%  - 64px)',
        }}
      >
        <StyledDrawerWrapper>
          <AppReactSelectField
         
            menuPosition="fixed"
            label={"Whatsapp Template"}
            options={templateOptions ?? []}
            valueKey={"id"}
            displayKey="name"
            name={"template_id"}
            placeholder={`Select template`}
            isClearable={false}
            requiredSign={true}
            isLoading={loading}
            onChangeCallbackFn={(value: any) => {
              getTemplateData(value);
            }}
            resetDependentFieldName={[
              "resolved_header_variables",
              "header_media",
              "header_type",
              "header_media_type",
              "body_preview",
              "resolved_body_variables",
              "resolved_buttons_variables",
              "footer_value",
            ]}
          />
          <WhatsappBroadcastResolveVaribles applyValue={preserveValues} />
        </StyledDrawerWrapper>
        <StyledDrawerFooter>
          <AppButton
            variant="grey"
            onClick={() => setDrawerOpen(false)}
          >
            Cancel
          </AppButton>
          <AppSubmitButton
            title={"Use Template"}
            checkIsValidProperty={false}
          ></AppSubmitButton>
        </StyledDrawerFooter>
      </AppForm>
      </Box>
    </Drawer>
  </>
};

export default WhatsappTemplate;

const StyledDrawerWrapper = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    flex: 1;
    padding: 1rem 2rem;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const StyledDrawerFooter = styled.div`
  && {
    width: 100%;
    height: 48px;
    max-height: 48px;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
    padding: 1rem 2rem;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    border-top: 1px solid ${theme.palette.default.border};
    .title {
      font-size: 18px;
      font-weight: 500;
    }
  }
`;

const StyledDrawerHeader = styled.div`
  && {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    .title {
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;

      svg {
        font-size: 21px;
        margin-right: 8px;
      }
    }
  }
`;

const TemplateCard = styled.div`
  && {
  border: 1px solid ${(props: any) => props.templateSelected ? "transparent" : theme.palette.default.error};
    background-color: ${(props: any) => props.templateSelected ? theme.palette.default.white : theme.palette.default.errorBgColor};
    border-radius: 4px;
    margin: 8px;
  }
`;

const TemplateCardContent = styled.div`
  && {
    padding: 16px;
    display: flex;
    align-items: center;
    position: relative;
  }
`;

const WhatsAppIconStyled = styled(WhatsAppIcon)`
  && {
    color: ${theme.palette.default.success}; /* WhatsApp brand color */
    font-size: 32px;
    margin-right: 16px;
  }
`;

const TemplateName = styled.h3`
  && {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: ${theme.palette.primary.main};
  }
`;

const TemplateDescription = styled.p`
  && {
    margin: 4px 0 0;
    font-size: 13px;
    color: ${theme.palette.default.darkGrey};
  }
`;

const EmptyTemplateInfo = styled.div`
  && {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const EmptyTemplateTitle = styled.h3`
  && {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: ${theme.palette.default.newTextBlack};
  }
`;

const EmptyTemplateDescription = styled.p`
  && {
    margin: 4px 0 0;
    font-size: 13px;
    color: ${theme.palette.default.darkGrey};
  }
`;



const EditButton = styled(AppButton)`
  && {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.common.white};
    min-width: 80px;
    &:hover {
      background-color: ${theme.palette.primary.dark};
    }
  }
`;