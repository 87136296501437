import { useEffect, useState } from "react";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../AppComponents/Loader";
import { ApiBoxWrapper, ApiLibraryWrap} from "../style";
import TopMenuComponent from "../common/TopBar";
import ApiCard from "../common/ApiAccordian";
import AppPaginationComponent from "../../../AppComponents/Pagination/Pagination";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  selectApiList,
  selectApiPagination,
} from "../../../Store/Slices/APILibrary/apilibrary.selectors";
import { ApiLibraryNetworkActions } from "../../../Store/Slices/APILibrary/apilibrary.actions";
import { Box, debounce, Divider, Typography } from "@mui/material";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { useNavigate } from "react-router-dom";
import { TableWrapper } from "../../../TableStyle";
import AppModel from "../../../Hooks/useModel/AppModel";
import { APILibraryNetworkService } from "../../../Store/Slices/APILibrary/apilibraryNetworkService";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import AppButton from "../../../AppComponents/AppButton";
interface Props extends LoaderComponentProps {}

const ApiLibraryList: React.FC<Props> = ({ setLoading }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: { label: "50  per page", value: 50 },
    currentPage: 0,
    method: "",
    search: "",
  });
  const [deleteModel, setDeleteModel] = useState<any>({
    isOpen: false,
    apiId: "",
  });
  // Fetch data from Redux store using selectors
  const apiList = useAppSelector(selectApiList);
  const { showAlert } = useNotification();

  const apiPagination = useAppSelector(selectApiPagination);

  const fetchApiList = () => {
    setLoading(true);
    let paylaod = {
      limit: localValues?.itemsPerPage?.value,
      offset: localValues?.currentPage,
      search: localValues?.search,
      method: localValues?.method,
    };
    // Dispatch action to fetch API data
    dispatch(ApiLibraryNetworkActions.getApiList(paylaod)).finally(() => {
      // Set loading false after fetching data
      setLoading(false);
    });
  }
  useEffect(() => {
    // Set loader true before fetching data
    fetchApiList()
  }, [localValues]);

  const handleDeleteModal = (id: any) => {
    setDeleteModel({ isOpen: true, apiId: id });
  };
  const handleDeleteApi = async (apiId: string) => {
    APILibraryNetworkService.DeleteApi(apiId)
      .then(() => {
        fetchApiList()
        setDeleteModel({ isOpen: false, apiId: "" });
        showAlert("API Deleted Succesfully", "success");
      })
      .catch((error) => {
        setDeleteModel({ isOpen: false, apiId: "" });
        showAlert(error?.response?.data?.response, "error");
      });
  };
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };
  const handleChangeMethod = (value: any) => {
    setLocalValues({
      ...localValues,
      method: value.target.value,
      currentPage: 0,
    });
  };
  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      search: e,
      currentPage: 0,
    });
  }, 700);

  return (
    <ApiLibraryWrap>
      <TopMenuComponent
        hideFilters={false}
        method={localValues?.method}
        handleChangeMethod={handleChangeMethod}
        handleSelectSearch={handleSelectSearch}
      />
      <ApiBoxWrapper>
        {apiList?.length !== 0 ? (
          <TableWrapper>
            <div style={{ overflow: "auto", height: "calc(100vh - 210px)" }}>
              <Box className="table-header">
                <Box className="table-row">
                  {["Name", "Created Date", "Modified Date", "Action"].map(
                    (header, index) => (
                      <Box
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        style={{
                          minWidth: `${header === "Action" ? 100 : 150}px`,
                          border: "none",
                        }}
                        key={index}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {header}
                        </Typography>
                      </Box>
                    )
                  )}
                </Box>
                <Divider />
              </Box>

              <Box className="table-body">
                {apiList.map((api: any, index: any) => (
                  <ApiCard
                    handleDeleteModal={handleDeleteModal}
                    key={api.api_id}
                    api={api}
                  />
                ))}
              </Box>
            </div>
          </TableWrapper>
        ) : (
          <NoRecordsFound
            height="calc(100vh - 212px)"
            subText="Create New API"
          >
            <AppButton
              variant="primarydark"
              onClick={() => navigate("/api/library/create")}
            >
              Create API
              <AppMaterialIcons iconName="Add" />
            </AppButton>
          </NoRecordsFound>
        )}
      </ApiBoxWrapper>
      {/* Pagination */}
      {apiList.length > 0 && (
        <AppPaginationComponent
          totalCount={apiPagination?.totalRecords}
          handlePageChange={handlePageChange}
          currentPage={localValues?.currentPage}
          totalPages={apiPagination?.totalPages}
          rowsPerPage={localValues?.itemsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      \
      <AppModel
        width="600px"
        style={{ width: "100%" }}
        onClose={() => setDeleteModel({ isOpen: false, apiId: "" })}
        isShowing={deleteModel.isOpen}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this api ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => setDeleteModel({ isOpen: false, apiId: "" })}
          >
            Cancel
          </Button>
          <Button
            color="error"
            onClick={() => handleDeleteApi(deleteModel.apiId)}
          >
            Delete
          </Button>
        </DialogActions>
      </AppModel>
    </ApiLibraryWrap>
  );
};

export default withLoader(ApiLibraryList);
