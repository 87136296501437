import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

const Endpoints = {
  GET_API_LIBRARY_LIST: "/workspace/api-library-list",
  CREATE_API: "/workspace/create-api-library",
  RUN_TEST_API: "/workspace/test-api",
  CONTACT_PROPERTIES: "/workspace/contact-properties/fields?status=visible",
  API_OPTIONS: "/workspace/get-used-in-bot-api",
  GET_API_DETAILS: "/workspace/get-api-library",
  EDIT_API: "/workspace/update-api-library",
  DELETE_API: "/workspace/delete-api-library",
};

export class APILibraryNetworkService {
  //get application property options
  static getApiList(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_API_LIBRARY_LIST}`,
      data
    );
  }

  static CreateApi(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.CREATE_API}`, data);
  }

  static RunTestAPI(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.RUN_TEST_API}`, data);
  }

  static getContactProperties() {
    return http.get(`${consoleBaseUrl}${Endpoints.CONTACT_PROPERTIES}`);
  }

  static getApiListOption() {
    return http.get(`${consoleBaseUrl}${Endpoints.API_OPTIONS}`);
  }

  static GetApiDetails(id: string) {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_API_DETAILS}/${id}`);
  }
  static EditApi(id: string,data : any) {
    return http.put(`${consoleBaseUrl}${Endpoints.EDIT_API}/${id}`,data);
  }
  static DeleteApi(id: string) {
    return http.delete(`${consoleBaseUrl}${Endpoints.DELETE_API}/${id}`);
  }
  
}
