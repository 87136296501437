import inline from "./inline";
import link from "./link";
import emoji from "./emoji";
import upload from "./upload";
import insert from "./insert"
import api from "./api"

export default {
  inline,
  link,
  emoji,
  upload,
  insert,
  api
};
