import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AppSmallWindowPane from "../../../../../AppComponents/AppSmallWindowPane";
import {
  AppForm,
  AppFormSelectField,
  AppFormSwitchField,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { EnabledDevicesSchema } from "../../../../../Models/ValidationSchema";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { ChangeSelectedBot, useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { ChatbotConfiguration } from "../../../../../Models/ChatbotConfiguration";
import { AxiosResponse } from "axios";
import { LoaderComponentProps, withLoader } from "../../../../../AppComponents/Loader";
import AppFormSliderField from "../../../../../AppComponents/Forms/AppFormSliderField";
import { Slider } from "@mui/material";
import { FontSizeOptions, ModelOptions } from "../../../../../Models/defaultSelectOptions";
import AppFormCountSliderField from "../../../../../AppComponents/Forms/AppFormSilder";
import { useAppDispatch } from "../../../../../Store";

interface Props extends LoaderComponentProps { }
const EnabledDevices = (props: Props) => {
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch()
  const currentBot :any = useSelector(useCurrentBot);
  const [userInput, setUserInput] = useState<any>({
    prompt: "",
    temperature : 0
  });

  useEffect(() => {

    // if (currentBot?.chatbot_configuration && currentBot?.chatbot_configuration) {
    //   ChatbotConsoleService.getBotConfiguration(
    //     currentBot?.chatbot_configuration as string
    //   ).then((res: AxiosResponse<any>) => {
    //     if (res.data?.enabled_devices) {

    //       setUserInput({
    //         prompt: res.data?.prompt ? res.data?.prompt : ""
    //       });
    //       props.setLoading(false)

    //     }
    //   }).catch((error: any) => {
    //     props.setLoading(false)

    //   });
    // }

    // else {
    //   props.setLoading(false)

    // }

    if(currentBot?.prompt || currentBot?.temperature || currentBot?.modelName){
      setUserInput({
        prompt:currentBot?.prompt,
        temperature : currentBot?.temperature,
        modelName:currentBot?.modelName
      })
    }
    props.setLoading(false)
  }, [currentBot]);
  console.log("currentBot",currentBot);
  
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    setUserInput(values);
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot, prompt: values.prompt,modelName:values.modelName,temperature:values.temperature },
      }
    )
      .then((response) => {        
        dispatch(ChangeSelectedBot(response?.data?.chatbot));
        SubmitProps.setSubmitting(false);
        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        SubmitProps.setSubmitting(false);
        showAlert(error?.response?.statusText || 'Something went wrong', "error");
      });
  };

  return (
    <AppSmallWindowPane>
      <AppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={EnabledDevicesSchema}
      >
        <AppFormSelectField
          label="Model"
          Options={ModelOptions}
          name="modelName"
          tooltip="Font Options"
          divStyle={{maxWidth:"100%"}}
        />
        <AppFormTextArea style={{maxHeight:"90px",minHeight:"335px"}} label="Enter Prompt" name={"prompt"} />
        <AppFormCountSliderField label="Temperature" name="temperature" />
         
        <AppSubmitButton
          variant="cta"
          style={{ width: "100%", margin: "0.5rem 0" }}
          title={"Save Changes"}
        />
      </AppForm>
    </AppSmallWindowPane>
  );
};

export default withLoader(EnabledDevices);
