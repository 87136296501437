import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../Store';
import { useNotification } from '../../../../../Hooks/useNotification/useNotification';
import { ContactCommunicationStats, contactDetailsId } from '../../../../../Store/Slices/Contacts/contacts.selector';
import { ContactsActions } from '../../../../../Store/Slices/Contacts/contacts.action';
import { AppToolTip } from '../../../../UiComponents/AppToolTip/AppToolTip';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styled from 'styled-components';
import { theme } from '../../../../../Customization/Theme';

const CommunicationStats = (props: any) => {
  const contactId: any = useAppSelector(contactDetailsId);
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const CommunicationStats: any = useAppSelector(ContactCommunicationStats);
  const getContactDetails = () => {
    props.setLoading(true);
    let payload = {
      contact_id:contactId,
    };

    dispatch(ContactsActions.getCommunication(payload))
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(
            res?.payload?.data?.message || "Enabled to fetch Data",
            "error",
          );
        } else {
        }
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error",
        );
      })
      .finally(() => {
        props.setLoading(false);
      });
  };
  useEffect(() => {
    if (contactId) {
      getContactDetails();
    }
  }, [contactId]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <CommunicationWrapper>
      <h3 className="box-heading">Email Summary</h3>
      <div className="summary-boxes">
        <div className="summary-inner-box">
          <div className="box-text">
            <div className="heading-text">
              <h3>Email Sent</h3>
            </div>
            <AppToolTip
              tooltipProps={{
                title: "Total number of emails successfully delivered to recipients.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <InfoOutlinedIcon fontSize='small' />
              }
            />
          </div>
          <h3 className="summary-number">
            {CommunicationStats?.email_information?.marketing_emails_sent.toString()}
          </h3>
        </div>

        <div className="summary-inner-box">
          <div className="box-text">
            <div className="heading-text">
              <h3>Email Delivered</h3>
            </div>
            <AppToolTip
              tooltipProps={{
                title: "The total number of emails successfully received by recipients' email servers.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <InfoOutlinedIcon fontSize='small' />
              }
            />
          </div>
          <h3 className="summary-number">
            {CommunicationStats?.email_information?.marketing_emails_delivered.toString()}
          </h3>
        </div>

        <div className="summary-inner-box">
          <div className="box-text">
            <div className="heading-text">
              <h3>Email Opened</h3>
            </div>
            <AppToolTip
              tooltipProps={{
                title: "The number of emails that have been opened by recipients.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <InfoOutlinedIcon fontSize='small' />
              }
            />
          </div>
          <h3 className="summary-number">
            {CommunicationStats?.email_information?.marketing_emails_opened.toString()}
          </h3>
        </div>
        <div className="summary-inner-box">
          <div className="box-text">
            <div className="heading-text">
              <h3>Email Clicked</h3>
            </div>
            <AppToolTip
              tooltipProps={{
                title: "The number of times links within your email have been clicked by recipients.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <InfoOutlinedIcon fontSize='small' />
              }
            />
          </div>
          <h3 className="summary-number">
            {CommunicationStats?.email_information?.marketing_emails_clicked.toString()}
          </h3>
        </div>

        <div className="summary-inner-box">
          <div className="box-text">
            <div className="heading-text">
              <h3>Email Bounced</h3>
            </div>
            <AppToolTip
              tooltipProps={{
                title: "The number of emails that were returned to the sender because they could not be delivered to the recipient's inbox.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <InfoOutlinedIcon fontSize='small' />
              }
            />
          </div>
          <h3 className="summary-number">
            {CommunicationStats?.email_information?.marketing_emails_bounced.toString()}
          </h3>
        </div>
        <div className="summary-inner-box">
          <div className="box-text">
            <div className="heading-text">
              <h3>Email Failed</h3>
            </div>
            <AppToolTip
              tooltipProps={{
                title: "The number of emails that failed to be delivered due to various reasons.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <InfoOutlinedIcon fontSize='small' />
              }
            />
          </div>
          <h3 className="summary-number">
            {CommunicationStats?.email_information?.marketing_emails_failed.toString()}
          </h3>
        </div>
      </div>
      <h3 className="box-heading">SMS Summary</h3>
      <div className="summary-boxes">
        <div className="summary-inner-box">
          <div className="box-text">
            <div className="heading-text">
              <h3>SMS Sent</h3>
            </div>
            <AppToolTip
              tooltipProps={{
                title: "The total number of SMS (Short Message Service) messages successfully dispatched to recipients.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <InfoOutlinedIcon fontSize='small' />
              }
            />
          </div>
          <h3 className="summary-number">
            {CommunicationStats?.sms_information?.marketing_sms_sent.toString()}
          </h3>
        </div>

        <div className="summary-inner-box">
          <div className="box-text">
            <div className="heading-text">
              <h3>SMS Delivered</h3>
            </div>
            <AppToolTip
              tooltipProps={{
                title: "The number of SMS (Short Message Service) messages successfully received by recipients' devices.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <InfoOutlinedIcon fontSize='small' />
              }
            />
          </div>
          <h3 className="summary-number">
            {CommunicationStats?.sms_information?.marketing_sms_delivered.toString()}
          </h3>
        </div>

        <div className="summary-inner-box">
          <div className="box-text">
            <div className="heading-text">
              <h3>SMS Failed</h3>
            </div>
            <AppToolTip
              tooltipProps={{
                title: "The number of SMS (Short Message Service) messages that were not successfully delivered to recipients.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <InfoOutlinedIcon fontSize='small' />
              }
            />
          </div>
          <h3 className="summary-number">
            {CommunicationStats?.sms_information?.marketing_sms_failed.toString()}
          </h3>
        </div>
      </div>

      <h3 className="box-heading">Whatsapp Summary</h3>

      <div className="summary-boxes">
        <div className="summary-inner-box">
          <div className="box-text">
            <div className="heading-text">
              <h3>Whatsapp Sent</h3>
            </div>
            <AppToolTip
              tooltipProps={{
                title: "The total number of messages successfully sent via WhatsApp to recipients.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <InfoOutlinedIcon fontSize='small' />
              }
            />
          </div>
          <h3 className="summary-number">
            {CommunicationStats?.whatsapp_information?.marketing_whatsapp_sent.toString()}
          </h3>
        </div>

        <div className="summary-inner-box">
          <div className="box-text">
            <div className="heading-text">
              <h3>Whatsapp Delivered</h3>
            </div>
            <AppToolTip
              tooltipProps={{
                title: "The number of messages successfully delivered to recipients' devices via WhatsApp.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <InfoOutlinedIcon fontSize='small' />
              }
            />
          </div>
          <h3 className="summary-number">
            {CommunicationStats?.whatsapp_information?.marketing_whatsapp_delivered.toString()}
          </h3>
        </div>

        <div className="summary-inner-box">
          <div className="box-text">
            <div className="heading-text">
              <h3>Whatsapp Seen</h3>
            </div>
            <AppToolTip
              tooltipProps={{
                title: "The number of messages that have been viewed by recipients on WhatsApp.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <InfoOutlinedIcon fontSize='small' />
              }
            />
          </div>
          <h3 className="summary-number">
            {CommunicationStats?.whatsapp_information?.marketing_whatsapp_seen.toString()}
          </h3>
        </div>

        <div className="summary-inner-box">
          <div className="box-text">
            <div className="heading-text">
              <h3>Whatsapp Replied</h3>
            </div>
            <AppToolTip
              tooltipProps={{
                title: "The number of messages that have elicited a response from recipients on WhatsApp.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <InfoOutlinedIcon fontSize='small' />
              }
            />
          </div>
          <h3 className="summary-number">
            {CommunicationStats?.whatsapp_information?.marketing_whatsapp_replied.toString()}
          </h3>
        </div>

        <div className="summary-inner-box">
          <div className="box-text">
            <div className="heading-text">
              <h3>Whatsapp Failed</h3>
            </div>
            <AppToolTip
              tooltipProps={{
                title: "The number of messages that were not successfully delivered to recipients on WhatsApp.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <InfoOutlinedIcon fontSize='small' />
              }
            />
          </div>
          <h3 className="summary-number">
            {CommunicationStats?.whatsapp_information?.marketing_whatsapp_failed.toString()}
          </h3>
        </div>
      </div>
    </CommunicationWrapper>
  );
}

export default CommunicationStats;

const CommunicationWrapper = styled.div`
  padding: 16px;
  background: ${theme.palette.default.white};
  min-height: calc(100vh - 220px);
  margin: 0 15px;
  .box-heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.palette.default.black};
  }
  .summary-boxes {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .summary-inner-box {
    border: 1px solid ${theme.palette.default.periwinkle};
    background: ${theme.palette.default.zircon};
    border-radius: 8px;
    padding: 24px;
    min-width: 243px;
    height: 109px;
  }
  .box-text {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .heading-text h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${theme.palette.default.codGray};
  }
  .summary-number {
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    color: ${theme.palette.default.codGray};
    padding-top: 10px;
  }
`;