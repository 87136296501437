import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";

interface CallDetailItemProps {
  label: string;
  value: string;
}

const CallDetailItemWrapper = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  gap: 0.5rem;
  font-size: 14px;
  line-height: 32px;
`;

const Label = styled.p`
  font-weight: 500;
  color: #000;
  width: auto;
  white-space: nowrap; 
  flex-shrink: 0; 
  margin: 0; 
`;

const Value = styled.span`
  color: ${theme.palette.default.darkGrey};
`;

const CallDetailItem: React.FC<CallDetailItemProps> = ({ label, value }) => (
  <CallDetailItemWrapper>
    <Label>
      {label} <span> : </span>
    </Label>
    <Value>{value}</Value>
  </CallDetailItemWrapper>
);

export default CallDetailItem;
