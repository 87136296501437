import moment from "moment";
import uniqid from "uniqid";

export const getLanguageOptions = () => {
  return [
    { label: "English", id: "en" },
    { label: "Spanish", id: "es" },
    { label: "French", id: "fr" },
    { label: "German", id: "de" },
    { label: "Hindi", id: "hi" },
  ];
};

export const getResponseBehavior = () => {
  return [
    { label: "Normal", id: "normal" },
    { label: "Rapid", id: "rapid" },
    { label: "Patient", id: "patient" },
  ];
};

export const getGenders = () => {
  return [
    { label: "Male", id: "male" },
    { label: "Female", id: "female" },
  ];
};

export const whoSpeaksFirstOptions = () => {
  return [
    // {
    //   label: "User Initiate: Assistant remains silent until user speaks first",
    //   value: "user_initiate",
    // },
    {
      label:
        "Assistant Initiated: Assistant begins with your Call Opening Message",
      value: "assistant_initiated",
    },
  ];
};

export const getCallHangUpLogicOptions = () => {
  return [
    { label: "Call hangs up on silence", id: "call_hangs_up_on_silence" }, // By defaualt 
    { label: "Provide a custom prompt", id: "provide_a_custom_prompt" },
  ];
};

export const getCallTerminationOptions = () => {
  return [{ label: "The call end", id: "the_call_end" }];
};
// Agents Routes List
export const voiceSettingsOptions = [
  {
    name: "Setup",
    url: "setup",
    isAvailable: true,
    id: uniqid(),
    value: "setup",
  },
  {
    name: "General settings",
    url: "generalSettings",
    isAvailable: true,
    id: uniqid(),
    value: "generalSettings",
  },
  {
    name: "Speech settings",
    url: "speechSettings",
    isAvailable: true,
    id: uniqid(),
    value: "speechSettings",
  },
  {
    name: "Call settings",
    url: "callSettings",
    isAvailable: true,
    id: uniqid(),
    value: "callSettings",
  },
  {
    name: "Functions",
    url: "functions",
    isAvailable: true,
    id: uniqid(),
    value: "functions",
  },
  {
    name: "Voices",
    url: "voices",
    isAvailable: true,
    id: uniqid(),
    value: "voices",
  },
];

export function formatDuration(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // If duration is less than 60 seconds, return only "X secs"
  return minutes > 0 ? `${minutes} min ${remainingSeconds} secs` : `${remainingSeconds} secs`;
}
