import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  Box,
  Divider,
  IconButton,
  Paper,
  Typography,
  debounce,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AppDrawer from "../../../../../../AppComponents/AppDrawer";
import { AppMaterialIcons } from "../../../../../../AppComponents/AppMaterialIcons";
import { AppForm } from "../../../../../../AppComponents/Forms";
import AppAudioComponent from "../../../../../../AppComponents/Forms/AppAudioComponent";
import AppFormSearchField from "../../../../../../AppComponents/Forms/AppFormSearchField";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../../../AppComponents/Loader";
import AppPaginationComponent from "../../../../../../AppComponents/Pagination/Pagination";
import { ReactComponent as CallEndedDirection } from "../../../../../../assets/images/call-ended.svg";
import { ReactComponent as CallFailedIcon } from "../../../../../../assets/images/call-failed.svg";
import { ReactComponent as SquareNavigation } from "../../../../../../assets/images/square-navigation.svg";
import { theme } from "../../../../../../Customization/Theme";
import AppModel from "../../../../../../Hooks/useModel/AppModel";
import { useAppDispatch, useAppSelector } from "../../../../../../Store";
import { useCurrentBot } from "../../../../../../Store/Slices/DashboardSlices";
import { UserActions } from "../../../../../../Store/Slices/Settings/LiveChat/User/user.action";
import { VoiceBotActions } from "../../../../../../Store/Slices/Voicebot/voicebot.actions";
import { TableWrapper } from "../../../../../../TableStyle";
import NoRecordsFound from "../../../../../UiComponents/NoRecordsFound/NoRecordsFound";
import CallLogsBadge from "../../components/Badge";
import BasicInfo from "./components/BasicInfo";
import CallAnalytics from "./components/CallAnalytics";
import Recording from "./components/Recording";
import Transcript from "./components/Transcript";
import { getCallLogsRowData } from "../../../../../../Store/Slices/Voicebot/voicebot.selectors";
import { setCallLogsRowData } from "../../../../../../Store/Slices/Voicebot/voicebot.slice";
import moment from "moment";
import { formatDuration } from "../../utils";
import { useParams } from "react-router-dom";

interface Props extends LoaderComponentProps {}

const CallLogs: React.FC<Props> = ({ setLoading }) => {
  const currentBot = useSelector(useCurrentBot);
  const dispatch = useAppDispatch();
  const { botId, analytics_type } = useParams();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAudio, setOpenAudio] = useState(false);
  const [logs, setLogs] = useState([]);
  const [pagination, setPagination] = useState({});
  const callLogsRowData = useAppSelector(getCallLogsRowData);
  console.log("🚀 ~ callLogsRowData:", callLogsRowData);
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: 10,
    currentPage: 0,
    search: "",
  });

  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot?.bot_id,
    }),
  };
  
  // Get listing of all Labels messages
  useEffect(() => {
    getAllUsersListing();
    let data = {
      bot_id: botId
    };
    dispatch(VoiceBotActions.getVoiceBotLogs(data))
      .then((res: any) => {
        setLogs(res?.payload?.data?.data?.logs);
        setPagination(res?.payload?.data?.data?.pagination);
      })
      .catch(() => {});
  }, [localValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllUsersListing = () => {
    const data = {
      HEADERS,
      limit: localValues.itemsPerPage,
      offset: localValues.currentPage,
    };
    dispatch(UserActions.getUserListing(data));
    setLoading(false);
  };

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
  }

  const handleChangeRowsPerPage = (event: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.value),
      currentPage: 0,
    });
  };

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  }, 700);

  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
  };

  const header = [
    "Contact Name",
    "Contact Mobile",
    "Recording",
    "Status",
    "Call Answered",
    "Direction",
    "From",
    "Voicemail Detected",
    // "Meeting Action Invoked",
    "Date",
    "Call Duration",
    "Cost",
    "Action",
  ];

  const keyToHeaderMap: any = {
    "Contact Name": "contactName",
    "Contact Mobile": "toNumber",
    Recording: "recordingURL",
    Status: "callState",
    "Call Answered": "last_login",
    Direction: "callDirection",
    From: "fromNumber",
    "Voicemail Detected": "",
    // "Meeting Action Invoked": "meeting_action_invoked",
    Date: "createdAt",
    "Call Duration": "callDuration",
    Cost: "cost",
    Action: "actions",
  };

  const colWidth: any = {
    "Bot Name": 150,
    "Contact Name": 150,
    "Contact Mobile": 150,
    Recording: 120,
    Status: 150,
    "Call Answered": 100,
    Direction: 100,
    From: 150,
    "Voicemail Detected": 170,
    // "Meeting Action Invoked": 150,
    Date: 130,
    "Call Duration": 140,
    Cost: 100,
    Action: 100,
  };

  const renderColumns = (row: any, header: any) => {
    switch (header) {
      case "Action":
        return (
          <div className="" onClick={() => setOpenDrawer(true)}>
            <IconButton style={{ cursor: "pointer" }}>
              <SquareNavigation />
            </IconButton>
          </div>
        );
      case "Direction":
        return row[keyToHeaderMap["Status"]] === "Ended" ? (
          <CallEndedDirection />
        ) : (
          <CallFailedIcon />
        );

      case "Contact Name":
        return row[keyToHeaderMap[header]] || "-";
      case "Recording":
        return (
          <IconButton
            onClick={() => {
              setOpenAudio(true);
            }}
          >
            <PlayCircleOutlineIcon
              sx={{ color: theme.palette.default.black }}
            />
          </IconButton>
        );
      case "Voicemail Detected":
        // case "Meeting Action Invoked":
        const value = row[keyToHeaderMap[header]] === "Yes" ? "True" : "False";
        return <span style={{ color: "#FF7700" }}>{value}</span>;
      case "Status":
        return <CallLogsBadge label={row[keyToHeaderMap[header]]} />;
      case "Date":
        return (
          <div>{moment(row[keyToHeaderMap[header]]).format("DD-MM-YYYY")}</div>
        );
      case "Call Duration":
        return formatDuration(row[keyToHeaderMap[header]]);
      case "Call Answered":
        return (
          <span style={{ color: "#FF7700" }}>
            {row[keyToHeaderMap[header]] === "ANSWER" ? "True" : "False"}
          </span>
        );
      default:
        return row[keyToHeaderMap[header]] || "-";
    }
  };

  return (
    <Wrapper>
      <FiltersWrapper>
        <AppForm
          initialValues={{}}
          onSubmit={() => {}}
          divStyle={{ display: "flex", alignItems: "center" }}
        >
          <AppFormSearchField
            divStyle={{
              marginBottom: "0",
              border: "1px solid rgb(203, 205, 211)",
              borderRadius: "4px",
            }}
            name="message-search"
            placeholder="Search"
            onChange={(e: any) => handleSelectSearch(e, "search")}
            clearCallback={(e: any) => clearCallback(e, "search")}
          />
        </AppForm>
      </FiltersWrapper>
      <TableContainer>
        <TableWrapper height={"calc(100vh - 325px)"}>
          <div>
            <Box className="table-header">
              {/* Table Header */}
              <Box className="table-row">
                {header?.map((header: string, index: number) => {
                  return (
                    <Box
                      className={`col-head ${header
                        .toLowerCase()
                        .replaceAll(" ", "_")}`}
                      style={{
                        minWidth: colWidth[header] + "px",
                      }}
                      key={index}
                    >
                      <Typography sx={{ fontWeight: 500 }}>{header}</Typography>
                    </Box>
                  );
                })}
              </Box>
              {/* Table Body */}
            </Box>

            <Box className="table-body">
              {logs && logs?.length === 0 ? (
                <NoRecordsFound height="calc(100vh - 200px)" />
              ) : (
                <>
                  {logs &&
                    logs?.map((row: any, rowIndex: any) => {
                      return (
                        <Box
                          key={rowIndex}
                          className={`table-row`}
                          onClick={() => {
                            dispatch(setCallLogsRowData(row));
                          }}
                        >
                          {header?.map((header: any, ColumnIndex: any) => {
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={row[keyToHeaderMap[header]]}
                                style={{ minWidth: colWidth[header] + "px" }}
                                justifyContent={
                                  header === "Actions" ? "flex-end" : ""
                                }
                                key={ColumnIndex}
                              >
                                {renderColumns(row, header)}
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                </>
              )}
            </Box>
          </div>
        </TableWrapper>
      </TableContainer>

      <div className="footer-pagination">
        <AppPaginationComponent
          totalCount={localValues?.totalRecords}
          handlePageChange={handlePageChange}
          currentPage={localValues?.currentPage}
          totalPages={localValues?.totalPages}
          rowsPerPage={localValues?.itemsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          title="Agents"
        />
      </div>
      {/* Recording Model */}
      <AppModel
        isShowing={openAudio}
        onClose={() => {
          setOpenAudio(false);
        }}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "750px",
            height: "180px",
          },
        }}
      >
        <ModalContainer>
          <div className="header">
            <h4>{callLogsRowData?.contactName || "Unknown"}</h4>
            <div className="header-right">
              <span
                onClick={() => {
                  setOpenAudio(false);
                }}
              >
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="body">
            <AppAudioComponent src={callLogsRowData?.recordingURL} />
          </div>
        </ModalContainer>
      </AppModel>

      {/* Drawer */}
      <AppDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{
          "& .MuiPaper-root": { minWidth: "80%", width: "80%" },
        }}
      >
        <CallLogsAppDrawerWrapper>
          <Box className="form-heading">
            <Box>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                {callLogsRowData?.contactName || "Unknown"}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setOpenDrawer(false)}>
                <AppMaterialIcons iconName={"Close"} />
              </IconButton>
            </Box>
          </Box>

          <Divider />
          <div className="contentWrapper">
            <div className="left-panel">
              <BasicInfo />
              <Recording />
              {/* <CalleeInformation /> */}
              <CallAnalytics />
            </div>
            <div className="right-panel">
              <Transcript />
            </div>
          </div>
        </CallLogsAppDrawerWrapper>
      </AppDrawer>
    </Wrapper>
  );
};

export default withLoader(CallLogs);

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const ResponseHeader = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #fff;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  .response-header__left {
    h4 {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .response-header__right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
    padding:0 0.8rem;
    .header-left {
      display: flex;
      gap: 0.875rem;
      }
      .text h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .text p {
        color: ${theme.palette.default.darkGrey};
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }
  .content{
    margin:10px 0;
    padding: 10px 15px; 
}
  .body {
    padding: 0.8rem;
    height: calc(100% - 50px);
    p {
    margin-bottom: 20px;
    font-size: 16px;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
    border-top: 1px solid ${theme.palette.default.border};
    .change-template-name {
      display: flex;
      justify-content: space-between;
      align-items: start;

      p.change-name {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;

const FiltersWrapper = styled.div`
  && {
    display: flex;
    padding: 0 1rem;
    margin-bottom: 1rem;
    gap: 1rem;
    align-items: center;
  }
`;

export const CallLogsAppDrawerWrapper = styled.div`
  & {
    .form-heading {
      display: flex;
      justify-content: space-between;
      height: 56px;
      align-items: center;
      padding: 0rem 1rem 0rem 1rem;
      position: sticky;
      background-color: #eef1ff;
      top: 0;
      z-index: 9;
    }
    .form-first__area {
      padding: 2rem 1.5rem 1rem 1.5rem;
    }
    .form-first_area_c {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .footer {
      padding: 2rem 0rem 0rem 0rem;
      .footer-btns {
        justify-content: end;
        gap: 1.3rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        padding-right: 1rem;
        .saveBtn {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .contentWrapper {
      padding: 1rem;
      display: flex;
      gap: 1rem;
      .left-panel {
        width: 65%;
        overflow-y: auto;
      }
      .right-panel {
        width: 35%;
      }
    }
  }
`;

const TableContainer = styled(Paper)`
  width: 100%;
  height: calc(100vh - 20rem);
  overflow-x: hidden;
  background-color: ${theme.palette.default.greyishWhite} !important;
`;
