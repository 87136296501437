import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../../../AppComponents/AppButton";
import AppModel from "../../../../../../Hooks/useModel/AppModel";
import useModal from "../../../../../../Hooks/useModel/useModel";
import { AppMaterialIcons } from "../../../../Icons";
import ModelBody from "./ModelBody";
import ModelSidebar from "./ModelSidebar";
import {
  StyledWhatsappChooseButtonWrapper,
  StyledchooseBtnModelContainer,
} from "./Styles";
import { setSelectedTemplateData } from "../../../../../../Store/Slices/socket/liveChatWhatsappTemplateSelect/LiveChatWhatsappTemplateSelect.slice";
import { useAppDispatch } from "../../../../../../Store";
import { ProtectedAppButton } from "../../../../../../AppComponents/ProtectedComponents";

const WhatsappChooseTemplateBtn = () => {
  const navigate = useNavigate();
  const { isShowing, toggle } = useModal();
  const dipatch = useAppDispatch();
  const handleChooseTemplateClick = () => {
    toggle(true);
  };

  return (
    <StyledWhatsappChooseButtonWrapper>
      <AppButton variant="text" onClick={() => handleChooseTemplateClick()}>
        Choose Template
      </AppButton>

      <p>
        The customer has not spoken to you in the last 24 hours. You can only
        send pre-approved templates.
      </p>
      <AppModel
        isShowing={isShowing}
        onClose={() => toggle()}
        divStyle={{
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "0rem",
          minWidth: "900px",
          height: "600px",
        }}
      >
        <StyledchooseBtnModelContainer>
          <div className="modelHeader">
            <p className="modelheaderTitle">
              <AppMaterialIcons iconName="TextSnippetOutlined" />
              Choose Template
            </p>

            <div className="modelHeaderAction">
              <ProtectedAppButton
                variant="primary"
                moduleId="whatsapp_template"
                specificPermissionKey="create"
                className="create-template"
                onClick={() => navigate("/broadcast/whatsapp/create-template")}
              >
                Create Template
              </ProtectedAppButton>
              <IconButton
                title={"File"}
                onClick={() => {
                  toggle(false);
                  dipatch(setSelectedTemplateData([]));
                }}
              >
                <AppMaterialIcons
                  style={{
                    color: "#fff",
                  }}
                  iconName={"Close"}
                />
              </IconButton>
            </div>
          </div>
          <div className="modelBody">
            <ModelSidebar isShowing={isShowing} toggle={toggle} />
            <ModelBody isShowing={isShowing} toggle={toggle} />
          </div>
        </StyledchooseBtnModelContainer>
      </AppModel>
    </StyledWhatsappChooseButtonWrapper>
  );
};

export default WhatsappChooseTemplateBtn;
