import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import { AppForm, AppFormObserver, AppSubmitButton } from "../../../AppComponents/Forms";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import {
  automationBuilderPayload,
  automationBuilderState
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import {
  closeConfigModal,
  setNodeConfig,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import { SegmentActions } from "../../../Store/Slices/Segment/Segment.actions";
import AppReactSelectField from "../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { IsInSegmentConfigSchema } from "../validationSchema";
import { withLoader } from "../../../AppComponents/Loader";

const IsInSegment = ({ setLoading }: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const formRef: any = useRef();
  const automationReduxData: any = useAppSelector(automationBuilderState);
  const automationPayload: any = useAppSelector(automationBuilderPayload);
  const [formikProps, setFormikProps] = useState<any>(null);
  const [initialValues, setInitialValues]: any = useState({
    segmentListId: "",
  });
  const [listOptions, setListOptions] = useState<any>([]);
  const closeModel = () => {
    dispatch(closeConfigModal({}));
  };
  useEffect(() => {
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.segmentListId &&
      listOptions?.length > 0
    ) {
      //function to filter object based on Value
      setInitialValues({
        segmentListId: {
          id: listOptions?.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.segmentListId?.id
          )?.id,
          name: listOptions?.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.segmentListId
          )?.name,
        },
      });
    }
  }, [automationPayload, listOptions]);   // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    let temp = {
      limit: 999,
      offset: 0,
    };
    dispatch(SegmentActions.getAllSegmentListData(temp))
      .then((res: any) => {
        let tempArray: any = [];
        res?.payload.data?.response?.segment_list?.map((ele: any) => {
          tempArray.push({ id: ele.id, name: ele.name });
          setListOptions(tempArray);
        });
      }).then(() => {
        setLoading(false)
      }).catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps
  const handleSubmit = (values: any, submitProps: any) => {
    let payload: any = {
      nodeObject: {
        segmentId: values?.segmentListId.id,
      },
      nodeId: automationReduxData?.selectedNodeId,
      configText: values?.segmentListId.name,
    };
    submitProps.setSubmitting(false);
    dispatch(setNodeConfig(payload));
    showAlert("Node Config Saved Successfully!", "success");
    closeModel();
  };
  useEffect(() => {
    // Manually set the isValid state to false
    formRef?.current?.setFormikState((prevState: any) => ({
      ...prevState,
      isValid: false,
    }));
    formRef?.current?.setFieldValue("segmentListId", "", { shouldDirty: true })
  }, []);

  return (
    <ModelFormWrapper isDataAvaliable={listOptions?.length}>
      <Typography variant="h6">Is In List</Typography>
      {
        listOptions?.length ? (
          <AppForm
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={IsInSegmentConfigSchema}
            innerRef={formRef}
          >
            {listOptions?.length > 0 && (
              <AppReactSelectField
                name={"segmentListId"}
                options={listOptions}
                label="Email Template(s)"
                displayKey="name"
                valueKey="id"
                placeholder="Select a Segment"
                isSearchable={true}
                isClearable={true}
                hideSelectedOptions={true}
                isRequired={true}
                divStyle={{ background: "none", padding: "0" }}
              />
            )}
            <AppFormObserver setFormikProps={setFormikProps} />
            <ModelFooter>
              <AppButton variant="outline" onClick={closeModel}>Cancel</AppButton>
              <AppSubmitButton title={"Save Config"} disabled={!formRef?.current?.isValid} />
            </ModelFooter>
          </AppForm>
        ) : (
          <React.Fragment>
            <div className="image_container">
              <NoDataFound />
            </div>
            <ModelFooter>
              <AppButton onClick={closeModel}>Close</AppButton>
            </ModelFooter>
          </React.Fragment>
        )}
    </ModelFormWrapper>
  );
};

export default withLoader(IsInSegment);

const ModelFormWrapper = styled.div`
width: ${(props: any) => props.isDataAvaliable ? "400px" : "500px"};
h6 {
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 15px;
    padding: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }
  .image_container{
    text-align:center;
  }
`;
const ModelFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${theme.palette.default.border};
  padding: 15px 0 0;
  button:last-child {
    margin-left: 15px;
  }
`;
