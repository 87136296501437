import { useEffect, useRef } from "react";
import { AppConfig } from "../../Config/app-config";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../Store";
import { setUserCreditsInfo } from "../../Store/Slices/CurrentUser/CurrentUserSlice";
import {
  setIsPreviousChatLoading,
  setLastNodeResponse,
  setNewAllMessages,
  setOriginalResponses,
  setUserInfoData,
  setVisitorChatData,
} from "../../Store/Slices/socket/AllMessagesSlice";
import { updateSessionData } from "../../Store/Slices/socket/liveChat/chat.slice";
import { SOCKET_CONNECTION_TYPES } from "../utils/Enum";
import { useSocket } from "./socket-context";

const useMessagesData = () => {
  //redux
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();

  const { socket } = useSocket();

  const data = AppConfig.getLoggedInData();
  const domain = data?.data?.geta_host;
  const workspace_id = data?.data?.user?.workspace_id ?? "";
  const username = data?.data?.user?.id;

  const sessionId = useAppSelector(
    (state) => state.currrentAllMessagesStore.sessionId
  );
  // Create a ref for sessionId
  const sessionIdRef = useRef(sessionId);
  // Update the ref whenever sessionId changes to avoid closures scopes below.
  useEffect(() => {
    sessionIdRef.current = sessionId;
  }, [sessionId]);

  const handleUserInfoData = (history: any) => {
    if (history?.visitor_data) {
      dispatch(setVisitorChatData(history?.visitor_data));
    }
    if (history?.user) {
      dispatch(setUserInfoData(history?.user));
      dispatch(updateSessionData(history?.user));

      if (history?.user?.remaining_credits) {
        dispatch(setUserCreditsInfo(history?.user?.remaining_credits));
      }
    }
  };

  const handleHistoryMessages = async (history: any) => {
    let payload: any = [];
    if (
      history?.chat_data?.length === 0 ||
      !Array.isArray(history?.chat_data)
    ) {
      showAlert("No Chat Data found.", "error");
      return;
    }
    history?.chat_data?.forEach((item: any, index: any) => {
      if (item?.["user_query_object"]) {
        let temp = {
          ...item?.["user_query_object"],
          time: item?.["createdAt"],
          message_id: item?.["message_id"],
          delivered: item?.["delivered"],
          seen: item?.["seen"],
          sent: item?.["sent"],
        };
        payload.push(temp);
      }

      if (item?.["bot_response"]?.["response"]) {
        item?.["bot_response"]?.["response"]?.forEach((curRes: any) => {
          let temp = {
            ...curRes,
            time: item?.["createdAt"],
            message_id: item?.["message_id"],
            delivered: item?.["delivered"],
            seen: item?.["seen"],
            sent: item?.["sent"],
          };
          payload.push(temp);
        });
      }

      if (index + 1 === history?.chat_data?.length) {
        dispatch(setLastNodeResponse(item?.["bot_response"]));
      }
      dispatch(setOriginalResponses(item?.["bot_response"]));
    });

    dispatch(setNewAllMessages(payload));

    //* set visitor data
  };

  const sendBotHistoryRequestEvent = (id: string, botid:string=''): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (id && workspace_id && username) {
        let data = {
          session_id: id,
          workspace_id: workspace_id ?? null,
          geta_host: domain,
          user_id: username ?? null,
          botid: botid
        };
        dispatch(setIsPreviousChatLoading(true));
        socket?.emit(
          SOCKET_CONNECTION_TYPES.BOT_CONVERSATION_HISTROY,
          data,
          (error: any, response: any) => {
            // console.warn(
            //   `🚀 ~ CHAT_HISTORY:`,
            //   response
            // );
              dispatch(setIsPreviousChatLoading(false));
            if (error) {
              console.warn("error while getting chat history", error);
              showAlert("something went wrong while fetching chats ", "error");
              reject(error);
            } else if (!error && response) {
              handleHistoryMessages(response?.data);
              handleUserInfoData(response?.data);
              resolve(response);
            } else {
              //do nothing
              reject(null);
            }
          }
        );
      } else {
        reject(new Error("Invalid parameters"));
      }
    });
  };

  // const sendBotHistoryRequestEvent = (
  //   id: string,
  //   botid: string = ""
  // ): Promise<any> => {
  //   return new Promise((resolve, reject) => {
  //     if (!id) reject(new Error("Invalid parameters"));

  //     dispatch(setIsPreviousChatLoading(true));

  //     ChatbotConversationService.getHistoryMessages(
  //       {
  //         session_id: id,
  //       },
  //       BOT_Headers
  //     )
  //       .then(async (response: any) => {
  //         // console.log(
  //         //   `🚀 ~ file: useMessagesData.ts:212 ~ .then ~ res:`,
  //         //   response.data
  //         // );
  //         handleHistoryMessages(response?.data?.data);
  //         sendBotHistoryRequestEventt(id, botid);
  //         resolve(response);
  //       })
  //       .catch((err: any) => {
  //         reject(err);
  //         return err;
  //       })
  //       .finally(() => {
  //         dispatch(setIsPreviousChatLoading(false));
  //       });
  //   });
  // };

  return {
    sendBotHistoryRequestEvent,
    handleHistoryMessages,
  };
};

export default useMessagesData;
