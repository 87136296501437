import { useEffect } from "react";

/**
 * Custom hook to show JavaScript notifications.
 *
 * @returns {Object} - An object containing the showJsNotification function.
 */
const useJsNotifications = (): {
  showJsNotification: (
    title: string,
    options?: NotificationOptions,
    handleOnClick?: () => void
  ) => void;
} => {
  /**
   * Function to show a JavaScript notification.
   *
   * @param {string} title - The title of the notification.
   * @param {NotificationOptions} [options] - Optional options for the notification.
   * @param {Function} [handleOnClick] - Optional callback function for the notification click event.
   */
  const showJsNotification = (
    title: string,
    options?: NotificationOptions,
    handleOnClick?: () => void
  ): void => {
    if (!("Notification" in window)) {
      console.warn("This browser does not support desktop notification");
      return;
    }

    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const notification = new Notification(title, options);

        notification.onclick = () => {

          window.open(notification?.data?.url ?? window?.location?.href,"_blank");
          window?.focus();
          handleOnClick?.();
        };
      } else {
        console.warn("Notification permission denied");
      }
    });
  };

  useEffect(() => {
    // Check if the browser supports notifications
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
      return;
    }

    // Request notification permission if not already granted
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        if (permission !== "granted") {
          console.warn("Notification permission denied");
        }
      });
    }
  }, []);

  return { showJsNotification } as const;
};

export default useJsNotifications;
