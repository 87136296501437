import { Box, Divider, Chip, Typography, Popover } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { AppForm, AppSubmitButton } from '../../../AppComponents/Forms';
import CloseIcon from "@mui/icons-material/Close";
import AppButton from '../../../AppComponents/AppButton';
import { ReactComponent as SaveIcon } from "../../../../src/assets/images/save-icon.svg";
import AppFormCreateAbleSelect from '../../../AppComponents/Forms/AppFormCreateAbleSelect';
import AppReactSelectField from '../../../AppComponents/Forms/AppFormReactSelect';
import { useAppDispatch, useAppSelector } from '../../../Store';
import { contactDetailsId, ContactsTagsList } from '../../../Store/Slices/Contacts/contacts.selector';
import { ContactsActions } from '../../../Store/Slices/Contacts/contacts.action';
import { useNotification } from '../../../Hooks/useNotification/useNotification';
import { BulkAddTags } from '../../../Models/ValidationSchema';
import { useSearchParams } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import { theme } from '../../../Customization/Theme';

// Array of colors for the chips
const randomBgColors = [
  theme.palette.default.biloba,
  theme.palette.default.lightApricot,
  theme.palette.default.sapling,
  theme.palette.default.bermuda,
  theme.palette.default.pastelPink,
  theme.palette.default.mystic,
  theme.palette.default.pattensBlu,
  theme.palette.default.frenchLilac,
  theme.palette.default.texas,
];

const randomColors = [
  theme.palette.default.luckyPoint,
  theme.palette.default.totemPole,
  theme.palette.default.darkTan,
  theme.palette.default.kaitokeGreen,
  theme.palette.default.cardinal,
  theme.palette.default.ebonyClay,
  theme.palette.default.elephant,
  theme.palette.default.luckyPoint,
  theme.palette.default.nutmegWoodFinish
]

interface TagsProps{
    tagsAnchorEl: any;
    setTagsAnchorEl: any;
    columns: any;
    existedTags: any;
    modalPos: any;
    type: any;
    setLoading: any;
    setIsUpdating: any;
}
const InlineTagsUpdate: React.FC<TagsProps> = ({tagsAnchorEl,setTagsAnchorEl, columns, existedTags, modalPos, type, setLoading, setIsUpdating}) => {
    const contactId: any = useAppSelector(contactDetailsId);
    const {showAlert} = useNotification();
    const dispatch = useAppDispatch();
    const TagsList: any = useAppSelector(ContactsTagsList);
    const [tagsListData, setTagListData] = useState<any[]>([]);
    const [userInput, setUserInput] = useState({ tags: [], remove_tags: [] });
    const [localExistedTags, setLocalExistedTags] = useState<any[]>(existedTags);
    const [operationType, setOperationType] = useState<any>(type);
    const [combinedExistedTags, setCombinedExistedTags] = useState<any[]>([]);
    const [newTags,setNewTags] = useState<any>([])
    const open = Boolean(tagsAnchorEl);
    let payload: any = {};
    const id = open ? "simple-popover" : undefined;
    const closeModal = () => {
      setTagsAnchorEl(null);
    };
    const [searchParams] = useSearchParams();
    const currentViewId = searchParams.get("view");
    const formkey9 = "tags";
    const params = searchParams.get("page") || "1";
    const page = Number(params)-1;
    //funtion to return random colors
    const getRandomBgColor = (index: any, offset = 0) => {
      return randomBgColors[(index + offset) % randomBgColors.length];
    };

    const getRandomColor = (index: any, offset = 0): string => {
      return randomColors[(index + offset) % randomColors.length];;
    };

    const randomOffset = useMemo(
      () => Math.floor(Math.random() * randomBgColors.length),
      []
    );
    useEffect(() => {
      getTagsList();
    }, []);

    useEffect(() => {
      setLocalExistedTags(existedTags);
    }, [existedTags]);

    useEffect(()=>{
      setTagListData([]);

      if(TagsList && TagsList?.length) {
        TagsList.forEach((eachItem: any) => {
          let tagItems = {
            value: eachItem.id,
            label: eachItem.tag_title
          };
          setTagListData((prev: any) => [...prev, tagItems]);
        });
      }
    }, [TagsList]);

    useEffect(() => {
      if (existedTags && tagsListData.length) {
        const combined = existedTags.map((tagName: string) => {
          const found = tagsListData.find((tagObj: any) => tagObj.label === tagName);
          return found ? { id: found.value, label: tagName } : { id: null, label: tagName };
        });
        setCombinedExistedTags(combined);
      }
    }, [existedTags, tagsListData]);

    const handleDeleteTag = (tagName: any) => {
      setIsUpdating(true);
      setOperationType("remove");
      const updatedTags = localExistedTags.filter((tag: any) => {
        return tag !== tagName;
      });
      const updatedCombined = combinedExistedTags.filter((eachItem: any) => eachItem.label === tagName);
      setNewTags((prev: any)=>[...prev,...updatedCombined]);
      setLocalExistedTags(updatedTags);
    }
    const onClickSubmit = (values: any, submitProps: any) => {
      submitProps.setSubmitting(true);
      setTagsAnchorEl(null);
      setLoading(true);
      setIsUpdating(true);
      const filteredColumns = columns?.map((eachCol: any) => eachCol.id === "select" ? "id" : eachCol.id);
      if (operationType === "add") {
        let tags = values?.tags;
        const new_tags = tags?.filter((item: any) => item?.__isNew__)
          .map((item: any) => item?.label);
        const existing_tags = tags?.filter((item: any) => !item?.__isNew__).map((item: any) => item?.value);
        
        payload["existing_tags"] = existing_tags;
        payload["new_tags"] = new_tags;
        payload["operation"] = "add";
        payload["contactId_list"] = [contactId];
        payload["selectAll"] = false
        payload["columns"] = filteredColumns;
      }else{
        if("new_tags" in payload) {
          delete payload["new_tags"];
        }
        payload["contactId_list"] = [contactId];
        payload["selectAll"] = false
        payload["columns"] = filteredColumns;
        payload["existing_tags"] = newTags&& newTags.map((eachItem: any) => eachItem?.id);
        payload["operation"] = "remove";
      }
      dispatch(ContactsActions.addTagsList(payload))
          .then((res: any) => {
            if (res?.error?.message === "Rejected") {
              showAlert(res?.payload?.data?.message || "tags not added", "error");
            } else {
              submitProps.setSubmitting(false);
              setUserInput({ tags: [], remove_tags: [] });
              setOperationType("add");
              dispatch(ContactsActions.getContactsListData({
                data: {
                  columns: filteredColumns,
                  view_id: currentViewId,
                  page_number: page,
                  page_size: 50
                }
              }))
                .then((res: any) => {
                  setLoading(false);
                  setIsUpdating(false)
                  showAlert(res?.payload?.data?.message || `Tags ${operationType === "add"? "added":"removed"} successfully`, "success");
                })
            }
          })
          .catch((error: any) => {
            showAlert(error?.response?.statusText || "tags not added", "error");
          })
          .finally(() => {
            submitProps.setSubmitting(false);
          });
    }

    const validateTagLength = (inputValue: string) => {
      if (inputValue && inputValue.length >= 10) {
        showAlert("Tag must be less than 10 characters", "error");
        return false;
      }
      return true;
    };

    const getTagsList = ()=>{
      dispatch(ContactsActions.getContactsTagsList({}))
        .then(() => {})
        .catch((error: any) => {
          showAlert(
            error?.response?.statusText || "Enabled to fetch Data",
            "error"
          );
        })
        .finally(()=>{});
    }
    
  return (
     <Popover
           id={id}
           open={open}
           anchorEl={tagsAnchorEl}
           onClose={closeModal}
           anchorOrigin={{
             vertical: "center",
             horizontal: "left",
           }}
           transformOrigin={{
             vertical: "top",
             horizontal: "right",
           }}
           sx={{
             boxShadow: `0 2px 15px 0.5px ${theme.palette.default.heather}`,
             "& .MuiPaper-root": {
               borderRadius: 1,
               boxShadow: `0 0 15px 0 ${theme.palette.default.paleGray}`,
               padding: "10px 13px",
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
               width: "300px"
             },
           }}
         >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: "0.5rem",
          pb: "0.5rem",
          alignItems: "center",
          width: "100%"
        }}
      >
        <span style={{ fontWeight: "500", fontSize: "20px" }}>
          Add Tags
        </span>
        <CloseIcon
          onClick={() => closeModal()}
          sx={{
            cursor: "pointer",
          }}
        />
      </Box>
      <Divider />
      <AppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any)=>{
          onClickSubmit(values,submitProps);
          setUserInput(values);
        }}
        validationSchema={BulkAddTags}
        divStyle={{
          gap: 0,
          width: "100%"
        }}
      >
        <Box>
            <AppFormCreateAbleSelect
              menuPosition="absolute"
              label="Tags"
              required = {true}
              isMulti={true}
              isDisabled={operationType==="remove"?true:false}
              name={formkey9}
              options={tagsListData}
              styles={{
                menu: (provided: any) => ({
                  ...provided,
                  maxHeight: 150, 
                  overflowY: "auto", 
                }),
              }}
              onInputChange={(inputValue: string) => {
                return validateTagLength(inputValue) ? inputValue : '';
              }}

              isValidNewOption={(inputValue: any, selectValue: any, selectOptions: any) => {
                return inputValue && inputValue.length < 10 && !selectOptions.find((option: any) => option.label === inputValue);
              }}
            />
            {/* Display existing tags with random colors and filled style */}
            {localExistedTags && localExistedTags.length > 0 ? (
              <Box sx={{ mt: 2}}>
                <Typography variant="subtitle2" sx={{ mb: 1, fontSize:"13px"}}>
                  Tags Added
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, overflowY: "scroll", height: "80px" }}>
                  {localExistedTags.map((tag: any, index: number) => (
                    <Chip 
                      key={index}
                      label={tag.label || tag.tag_title || tag}
                      size="small"
                      deleteIcon={
                        <ClearIcon />
                      }
                      onDelete={() => handleDeleteTag(tag)}
                      sx={{
                        backgroundColor: getRandomBgColor(index, randomOffset),
                        color: getRandomColor(index, randomOffset),
                        fontWeight: 500,
                        '&:hover': {
                          opacity: 0.9,
                        },
                        borderRadius:"5px"
                      }}
                    />
                  ))}
                </Box>
              </Box>
            ): <Box sx = {{ mt: 2}}>
              <Typography variant="subtitle2" sx={{ mb: 1, fontSize:"13px"}}>No Tags Added</Typography>
              </Box>}
            
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px",
            gap: "6px",
          }}
        >
          <AppButton
            style={{ width: "115px" }}
            variant="outline"
            startIcon="add"
            onClick={() => closeModal()}
          >
            Cancel
          </AppButton>
          <AppSubmitButton
            title="Save"
            style={{ width: "115px" }}
          >
            <SaveIcon />
          </AppSubmitButton>
        </Box>
      </AppForm>
    </Popover>
  )
}

export default InlineTagsUpdate