import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  MouseEvent,
} from "react";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NestedMenu from "./CustomSelect"; 
import { TextAreaInput } from "../style";

interface Chip {
  name: any;
  label: string;
  value: string;
}

interface CustomInputWithChipsProps {
  icon?: ReactNode;
  onInputChange: (data: { chips: Chip[]; content: string }) => void;
  placeholder?: string;
  defaultValue?: string;
  inputStyle?: any;
  rows?: number;
  scroll?: boolean;
  error?: string;
  allSelectedChips?: Chip[]; 
}

const CustomInputWithChips: React.FC<CustomInputWithChipsProps> = ({
  icon,
  onInputChange,
  placeholder,
  defaultValue,
  inputStyle,
  rows = 3,
  scroll = false,
  error,
  allSelectedChips = [], 
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [content, setContent] = useState<string | undefined>(defaultValue);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleMenuClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option: any[]) => {
    const selectedOption = option[option.length - 1];
    if (selectedOption?.label) {
      const textarea = textareaRef.current;
      if (textarea) {
        const cursorPosition = textarea.selectionStart;
        const beforeCursor = content?.slice(0, cursorPosition) || "";
        const afterCursor = content?.slice(cursorPosition) || "";

        const chipText = `{{${selectedOption.label}}}`;
        const newContent = `${beforeCursor}${chipText}${afterCursor}`;

        const updatedChips = [...allSelectedChips, option]; 

        setContent(newContent);
        handleInputChange(newContent, updatedChips); 

        const newCaretPosition = cursorPosition + chipText.length;
        setTimeout(() => {
          textarea.setSelectionRange(newCaretPosition, newCaretPosition);
        }, 0);
      }
    }
    handleMenuClose();
  };
  const handleInputChange = (value: string, updatedChips: any[]) => {
    setContent(value);
    if (value === "") {
      onInputChange({ chips: [], content: "" });
      adjustTextAreaHeight();
      return;
    }

    onInputChange({ chips: updatedChips, content: value });
    adjustTextAreaHeight();
  };

  const handleBackspace = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Backspace") {
      const textarea = textareaRef.current;
      if (textarea) {
        const cursorPosition = textarea.selectionStart;
        const beforeCursor = content?.slice(0, cursorPosition) || "";
        const afterCursor = content?.slice(cursorPosition) || ""; // Define afterCursor

        // Check if the cursor is right after a closing chip "}}"
        const regex = /{{(.*?)}}/g;
        let match: RegExpExecArray | null;
        let lastChipMatch: RegExpExecArray | null = null;

        // Iterate through all matches and find the last one
        while ((match = regex.exec(beforeCursor)) !== null) {
          lastChipMatch = match;
        }

        if (lastChipMatch) {
          // Check if the cursor is right after the closing }} of the last match
          const chipEndIndex = lastChipMatch.index + lastChipMatch[0].length;
          if (cursorPosition === chipEndIndex) {
            // If the cursor is right after "}}", remove only the chip that is right before the cursor
            const chipToRemove = {
              label: lastChipMatch[1],
              value: lastChipMatch[1],
            };

            // Create new content without the chip
            const updatedContent =
              beforeCursor.slice(0, lastChipMatch.index) + afterCursor;

            // Update content and call onInputChange with updated chips
            setContent(updatedContent); // Update state with new content
            handleInputChange(
              updatedContent,
              allSelectedChips.filter(
                (chip) => chip.name !== chipToRemove.value
              )
            ); // Update chips

            // Prevent default backspace behavior
            event.preventDefault();

            // Set the cursor position to the index of the last chip removed
            const newCursorPosition =
              lastChipMatch.index >= 0 ? lastChipMatch.index : 0; // Default to 0 if index is negative
            setTimeout(() => {
              textarea.setSelectionRange(newCursorPosition, newCursorPosition);
            }, 0);
          }
        }
      }
    }
  };

  const adjustTextAreaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; 
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextAreaHeight();
  }, []);

  const minHeight = `${(rows || 3) * 40}px`;
  const maxHeight = !scroll ? "100%" : `${(rows || 3) * 40}px`;

  useEffect(() => {
    setContent(defaultValue || "");
  }, [defaultValue]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        ...inputStyle,
        minHeight: minHeight,
        maxHeight: maxHeight,
      }}
    >
      <TextAreaInput
        ref={textareaRef}
        placeholder={placeholder}
        value={content}
        onChange={(e: { target: { value: string; }; }) => handleInputChange(e.target.value, allSelectedChips)} 
        onKeyDown={handleBackspace} 
        style={{
          width: "100%",
          border: `${
            error ? "1px solid red" : "1px solid rgba(0, 0, 0, 0.23)"
          }`,
          borderRadius: "4px",
          padding: "10px 20px 0px 10px",
          outline: "none",
          resize: "none",
          overflow: scroll ? "auto" : "hidden",
          minHeight: minHeight,
          maxHeight: maxHeight,
        }}
      />
      {error && (
        <p
          style={{
            color: "red",
            marginTop: "5px",
            marginLeft: "5px",
            fontSize: "12px",
          }}
        >
          {error}
        </p>
      )}
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
        style={{
          position: "absolute",
          bottom: rows > 1 ? `${error ? 30 : 6}px` : 0,
          right: 0,
          padding:"10px 0px"
        }}
      >
        {icon || <MoreVertIcon />}
      </IconButton>

      <NestedMenu anchorEl={anchorEl} handleChange={handleOptionSelect} />
    </div>
  );
};

export default CustomInputWithChips;
