// vendors
import { useEffect, useState, Fragment } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import { useSelector } from "react-redux";

// components
import UIButton from "../../Components/Button";
import SearchInput from "../../Components/SearchInput";
import Icon from "../../Components/Icon";

// slice
import {
  getLanguage,
  setActiveAction,
  getApiListData,
} from "../../Slices/main.slice";
import { getApiList } from "../../Slices/main.service";
import { useAppDispatch, useAppSelector } from "../../../../Store";

// utils
import { getActionConfig } from "../../Utils";
import { transformApiAndContactOptions } from "../../Components/Editor/utils/common";
import { ApiLibraryNetworkActions } from "../../../../Store/Slices/APILibrary/apilibrary.actions";
import { selectApiList } from "../../../../Store/Slices/APILibrary/apilibrary.selectors";

const ApiLibrary = (props: any) => {
  const { showPreview, onSave, onCancel } = props;
  const action = getActionConfig(props.type);
  // const apiList = useSelector(getApiListData);
  const apiList = useAppSelector(selectApiList);
  const apiOptions = apiList.map((i: any) => {
    return { label: i?.name, value: i?.id };
  });
  const language = useSelector(getLanguage);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(apiOptions || []);
  const [apiLibraryId, setApiLibraryId] = useState<string | null>(props?.value || null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (apiOptions && apiOptions?.length > 0 && Array.isArray(apiOptions)) {
      const results: any = apiOptions?.filter((api: any) =>
        api?.label?.toLowerCase()?.includes(searchTerm.toLowerCase())
      );
      setSearchResults(results);
    }
  }, [searchTerm, apiList]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  const handleClick = (api: any) => {
    setApiLibraryId(api.value);
  };

  const handleSaveClick = () => {
    let payload = {
      apiLibraryId: apiLibraryId,
    };
    const myPromise = () =>
      Promise.resolve(dispatch(setActiveAction({ ...props, ...payload }))); /// creating promise
    myPromise().then(() => {
      const activeAction = (({ onSave, onCancel, showPreview, ...o }) => o)({
        ...props,
        ...payload,
      });
      onSave && onSave(activeAction);
    });
  };

  const handleCancelClick = () => {
    if (!props.nodeId || !props.qid) {
      onCancel && onCancel(true);
      return;
    }
    onCancel && onCancel(false);
  };
  useEffect(() => {
    let paylaod = {
      limit: 100,
      offset: 0,
    };
    // Dispatch action to fetch API data
    dispatch(ApiLibraryNetworkActions.getApiList(paylaod));
    // dispatch(getApiList());
  }, []);
  return (
    <>
      {showPreview ? (
        <Box sx={{ p: "12px 16px" }}>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Icon icon={action?.icon} size={16} color={"primary.light"} />
            <Typography
              variant="body1"
              sx={{ fontWeight: 500 }}
              color="primary.light"
            >
              {action?.name} {props.stage && props.stage}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={{ p: "16px" }}>
            <SearchInput
              placeholder="Search"
              size="small"
              onChange={handleSearchInputChange}
            />
            <Box
              sx={{
                mt: "16px",
                maxHeight: "200px",
                minHeight: "200px",
                overflowY: "scroll",
              }}
            >
              {searchResults.length > 0 ? (
                <>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                    component="nav"
                  >
                    {searchResults.map((api: any, index: number) => {
                      const openChild = false;
                      return (
                        <Fragment key={`api-${index + 1}`}>
                          <ListItem
                            disablePadding
                            sx={{
                              "&:hover": {
                                backgroundColor:
                                  language !== "english"
                                    ? openChild
                                      ? "#F5F6F8"
                                      : "transparent"
                                    : "#F5F6F8",
                              },
                              border: "1px solid #F5F6F8",
                            }}
                          >
                            <ListItemButton
                              sx={{
                                "&:hover": { backgroundColor: "transparent" },
                              }}
                              onClick={() => handleClick(api)}
                              disabled={language !== "english"}
                            >
                              <Typography
                                variant="body1"
                                color="default.darkGrey"
                              >{`${index + 1} ${api?.label}`}</Typography>
                              {apiLibraryId === api?.value && (
                                <Box sx={{ marginLeft: "auto" }}>
                                  <Icon
                                    icon="done"
                                    size={14}
                                    color="default.success"
                                  />
                                </Box>
                              )}
                            </ListItemButton>
                          </ListItem>
                        </Fragment>
                      );
                    })}
                  </List>
                </>
              ) : (
                <Typography
                  fontWeight={400}
                  fontSize={12}
                  lineHeight="16px"
                  color="#7E8392"
                >
                  No API Available
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={{ borderTop: "1px solid #F5F6F8", p: "16px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <UIButton variant="outlined" onClick={handleCancelClick}>
                Cancel
              </UIButton>
              <UIButton
                color="primary"
                variant="contained"
                startIcon={<Icon icon="save" color="#fff" size={13} />}
                disabled={language !== "english" || !apiLibraryId}
                onClick={handleSaveClick}
              >
                Save
              </UIButton>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default ApiLibrary;
