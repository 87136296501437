import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppButton from "../../../../../AppComponents/AppButton";
import AppSmallWindowPane from "../../../../../AppComponents/AppSmallWindowPane";
import {
  AppForm,
  AppFormField,
  AppFormImagePicker,
  AppFormRadioInput,
  AppFormSelectField,
  AppFormSwitchField,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import {
  SettingPageMainBoxTitle,
  StyledCustomizerActionBtnsWrapper,
} from "../../../../../Customization/CommonElements";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { ChatbotConfiguration } from "../../../../../Models/ChatbotConfiguration";
import { QuickMenuSchema } from "../../../../../Models/ValidationSchema";
import {
  QuickMenuLinkOptions,
  QuickMenuViewOptions,
} from "../../../../../Models/defaultSelectOptions";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { ConversationService } from "../../../../../Services/Apis/ConversationService";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { getDisplayValidNodes } from "../../../../../Utils";
import StaticPreview from "../../StaticPreview/StaticPreview";
import BlockCard from "./SubComponents/BlockCard";
import QuickListCard from "./SubComponents/ListCard";
import WebBotStaticPreview from "../../StaticPreview/webBot/WebBotStaticPreview";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import {
  setBotConfig,
  useBotConfigStore,
} from "../../../../../Store/Slices/botConfig/BotConfigSlice";
function QuickMenu() {
  const currentBot = useSelector(useCurrentBot);
  const [flowOptions, setFlowOptions] = useState([]);
  let { showAlert } = useNotification();
  const [isEditing, setIsEditing] = useState<any>(null);
  const [subFlowOptions, setSubFlowOptions] = useState<any>([]);
  const [localValues, setLocalValues] = useState<any>([]);
  const [reRenderState, setReRenderState] = useState<boolean>(false);
  const [originalSubNodes, setOriginalSubNodes] = useState<any>([]);
  const { botConfig } = useAppSelector(useBotConfigStore);
  const dispatch = useAppDispatch();
  const [enableState, setEnableState] = useState<any>({
    enabled: false,
    view_style: "Lists",
  });
  const [userInput, setUserInput] = useState<any>({
    image: "",
    button_title: "",
    button_url: "https://",
    position: "Add Link",
    view_style: "Lists",
    journey: null,
    main_flow: 0,
    sub_flow: 0,
  });
  const [quickmenudata, setQuickMenuData] = useState([]);

  const sendValuesToParent = (values: any): void => {
    setLocalValues(values);
  };

  const getPreviousData = () => {
    if (
      currentBot?.chatbot_configuration &&
      currentBot?.chatbot_configuration
    ) {
      ChatbotConsoleService.getBotConfiguration(
        currentBot?.chatbot_configuration as string
      )
        .then((res: AxiosResponse<ChatbotConfiguration>) => {
          dispatch(
            setBotConfig({ ...botConfig, chatbot_configuration: res.data })
          );
          if (res.data?.quick_menu) {
            setQuickMenuData(res?.data?.quick_menu?.data);
            setEnableState({
              enabled: res.data?.quick_menu?.enabled ? true : false,
              view_style: res.data?.quick_menu?.view_style || "Lists",
            });

            setReRenderState(!reRenderState);
          }
        })
        .catch((error: any) => {});
    }
  };
  useEffect(() => {
    getPreviousData();
  }, [currentBot]);

  const handleEnableStateForm = (values: any, SubmitProps: any) => {
    let SorId: any = [];
    quickmenudata?.forEach((currentItem: any) => {
      let current = { ...currentItem, image: currentItem?.image?.id };
      SorId.push(current);
    });

    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          quick_menu: {
            enabled: values?.enabled,
            view_style: values?.view_style,
            data: SorId,
          },
        },
      }
    )
      .then((response) => {
        getPreviousData();
        setUserInput({
          image: "",
          button_title: "",
          button_url: "https://",
          position: "Add Link",
          journey: null,
          main_flow: 0,
          sub_flow: 0,
        });
        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
      })
      .finally(() => {
        SubmitProps?.setSubmitting(false);
      });
  };

  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot.bot_id,
    }),
  };
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    let xyz: any = [];
    quickmenudata.forEach((currentItem: any) => {
      let current = {
        ...currentItem,
        image: currentItem?.image?.id,
      };
      xyz.push(current);
    });

    let temp = {
      image: values?.image?.id,
      button_title: values.button_title,
      button_url: values.button_url,
      position: values.position,

      journey: {
        main_flow: values?.main_flow,
        sub_flow: originalSubNodes[values?.sub_flow?.nodeIndex],
      },
    };
    let abc: any = [...xyz];
    abc.push(temp);
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          quick_menu: {
            enabled: enableState?.enabled,
            view_style: enableState?.view_style,

            data: abc,
          },
        },
      }
    )
      .then((response) => {
        getPreviousData();
        SubmitProps?.resetForm();
        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
      })
      .finally(() => {
        SubmitProps?.setSubmitting(false);
      });
  };

  useEffect(() => {
    ConversationService.getFlowList(HEADERS)
      .then((res: AxiosResponse<any>) => {
        if (Array.isArray(res?.data?.data)) {
          setFlowOptions(res?.data?.data);
        } else {
          setFlowOptions([]);
        }
      })
      .catch((error: any) => {});
  }, []);

  const getSubFlow = async (qid: string) => {
    const headers = {
      appid: JSON.stringify({
        botid: currentBot.bot_id,
      }),
    };
    ConversationService.getLinkSubFlow(qid, headers)
      .then(async (response: any) => {
        setOriginalSubNodes(response?.data?.data);
        let abc: any = getDisplayValidNodes(response?.data?.data);
        setSubFlowOptions(abc);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (localValues?.main_flow) {
      getSubFlow(localValues?.main_flow);
    }
  }, [localValues?.main_flow]);

  const handleUpdateBtn = (values: any, SubmitProps: any) => {
    let xyz = quickmenudata?.filter((index: any) => index !== isEditing);

    let SorId: any = [];
    xyz?.forEach((currentItem: any) => {
      let current = { ...currentItem, image: currentItem?.image?.id };
      SorId.push(current);
    });

    let temp = {
      image: values?.image?.id,
      button_title: values?.button_title,
      button_url: values?.button_url,
      position: values?.position,

      journey: {
        main_flow: values?.main_flow,
        sub_flow: originalSubNodes.filter(
          (ele: any) => ele.id === values.sub_flow.id
        )[0],
      },
    };

    SorId.push(temp);

    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          quick_menu: {
            enabled: enableState?.enabled,
            view_style: enableState?.view_style,
            data: SorId,
          },
        },
      }
    )
      .then((response) => {
        getPreviousData();
        SubmitProps.resetForm();

        setUserInput({
          image: "",
          button_title: "",
          button_url: "https://",
          position: "Add Link",
          journey: null,
          main_flow: 0,
          sub_flow: 0,
        });
        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
      })
      .finally(() => {
        SubmitProps.setSubmitting(false);
        setIsEditing(null);
      });
  };

  const handleEditButton = (item: any) => {
    setIsEditing(item);

    setUserInput({
      image: item?.image,
      button_title: item.button_title,
      button_url: item.button_url,
      position: item?.position,
      journey: item?.journey,
      main_flow: item?.journey ? item?.journey?.main_flow : 0,
      sub_flow: item?.journey ? item?.journey?.sub_flow : 0,
    });
  };
  const handleDeleteButton = (item: any) => {
    let abc = quickmenudata?.filter((el: any) => el?.image !== item?.image);
    let SorId: any = [];
    abc?.forEach((currentItem: any) => {
      let current = { ...currentItem, image: currentItem?.image?.id };
      SorId.push(current);
    });
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          quick_menu: {
            enabled: enableState?.enabled,
            view_style: enableState?.view_style,
            data: SorId,
          },
        },
      }
    )
      .then((response) => {
        getPreviousData();

        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
      });
  };
  const handleOpenUrlFn = (url: string) => {
    window.open(url, "margin-top: 15px;_blank", "noreferrer");
  };
  const handleCancel = () => {
    setIsEditing(false);
    setUserInput({
      image: "",
      button_title: "",
      button_url: "https://",
      position: "Add Link",
      journey: null,
      main_flow: 0,
      sub_flow: 0,
    });
  };
  return (
    <>
      {/* ===== QUICK MENU FORM SIDE ===== */}
      <AppSmallWindowPane>
        <AppForm
          initialValues={enableState}
          onSubmit={(values: any, submitProps: any) => {
            handleEnableStateForm(values, submitProps);
          }}
        >
          <AppFormSwitchField
            label="QuickMenu"
            name="enabled"
            submitOnChange={true}
          />
          <AppFormRadioInput
            label="Display View"
            name="view_style"
            Options={QuickMenuViewOptions}
            submitOnChange={true}
            sendValuesToParent={(values: any) => sendValuesToParent(values)}
          />
        </AppForm>

        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            isEditing
              ? handleUpdateBtn(values, submitProps)
              : handleSubmitBtn(values, submitProps);
          }}
          validationSchema={QuickMenuSchema}
        >
          <AppFormImagePicker
            label="Upload Image"
            name="image"
            tooltip={
              <ul>
                <li>Recommended size 64*64 px</li>
                <li>Your logo must be smaller than 1 MB</li>
                <li>A PNG, JPEG or JPG works best</li>
              </ul>
            }
            sizeLimit={1000000}
            sizeErrorMsg={"Image size must be smaller than 1MB."}
            formatErrorMsg={"image should be PNG, JPEG or JPG."}
            squareImageBox={true}
          />
          <AppFormRadioInput
            label="Select Button Action"
            name="position"
            Options={QuickMenuLinkOptions}
            sendValuesToParent={(values: any) => sendValuesToParent(values)}
          />

          {localValues?.position === "Link To Flow" ? (
            <>
              <AppFormSelectField
                label="Select a flow"
                defaulttext={"Select a Flow"}
                Options={flowOptions}
                name="main_flow"
                style={{ minWidth: "300px", maxWidth: "450px" }}
                variant="object"
                valueKey="qid"
                triggerEmptyField={"sub_flow"}
              />
              <AppFormSelectField
                valueKey="id"
                label="Select a node"
                defaulttext={"Select a node"}
                variant="object"
                Options={subFlowOptions}
                name="sub_flow"
                style={{ minWidth: "300px", maxWidth: "450px" }}
              />
            </>
          ) : (
            <>
              <AppFormField
                label="Button Title"
                name="button_title"
                placeholder="Click now"
              />
              <AppFormField
                label="Enter Button Url"
                name="button_url"
                placeholder="https://www.geta.ai"
              />
            </>
          )}

          <StyledCustomizerActionBtnsWrapper>
            {isEditing && (
              <div className="Cancel_button">
                <AppButton
                  onClick={handleCancel}
                  variant="outline"
                  style={{ width: "150px" }}
                >
                  Cancel
                </AppButton>
              </div>
            )}
            <AppSubmitButton
              variant=""
              title={isEditing ? "Update" : "Create"}
            />
          </StyledCustomizerActionBtnsWrapper>
        </AppForm>
      </AppSmallWindowPane>
      {/* ===== QUICK MENU FORM SIDE END ===== */}

      <AppSmallWindowPane style={{
        paddingLeft: "8px",
        paddingRight: "8px"
      }}>
        <SettingPageMainBoxTitle
          style={{ fontSize: "16px", marginBottom: "0.5rem" }}
        >
          Available Quick Menu
        </SettingPageMainBoxTitle>
        <div
          style={{
            display: enableState.view_style === "Blocks" ? "flex" : "",
            flexWrap: enableState.view_style === "Blocks" ? "wrap" : "nowrap",
            gap: enableState.view_style === "Blocks" ? "4px" : ""
          }}
        >
          {quickmenudata?.map((curItem: any, index: number) => {
            return (
              <React.Fragment key={index}>
                {enableState?.view_style === "Blocks" ? (
                  <BlockCard
                    curItem={curItem}
                    handleOpenUrlFn={handleOpenUrlFn}
                    handleEditButton={handleEditButton}
                    handleDeleteButton={handleDeleteButton}
                    isEditing={isEditing ? true : false}
                    isPannelPreview={true}
                    />
                ) : (
                  <QuickListCard
                    curItem={curItem}
                    handleOpenUrlFn={handleOpenUrlFn}
                    handleEditButton={handleEditButton}
                    handleDeleteButton={handleDeleteButton}
                    isEditing={isEditing ? true : false}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </AppSmallWindowPane>

      {/* ===== CHAT BOT WINDOW SIDE ===== */}

      {/* <StaticPreview
        variant={"QuickMenu"}
        quickMenuVariant={localValues && enableState?.view_style}
        botName="Quick Menu"
        quickdata={quickmenudata}
        reRenderState={reRenderState}
      /> */}

      <WebBotStaticPreview />
    </>
  );
}

export default QuickMenu;
