import { IconButton, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { AppForm, AppFormSwitchField } from "../../AppComponents/Forms";
import { theme } from "../../Customization/Theme";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../HOC/HocBackdropLoader";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import AppModel from "../../Hooks/useModel/AppModel";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import useFlowBuilder from "../../Modules/FlowBuilder/Hooks/useFlowBuilder";
import { getBotTrackingCodeFn } from "../../Services/Apis";
import { ChatbotConsoleService } from "../../Services/Apis/ChatbotConsoleService";
import { ConversationService } from "../../Services/Apis/ConversationService";
import { ParaphraserService } from "../../Services/Apis/ParaphraserService";
import { useAppDispatch, useAppSelector } from "../../Store";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { useCurrentBot } from "../../Store/Slices/DashboardSlices";
import { FaqSourceAction } from "../../Store/Slices/Faq/Source/source.actions";
import { formatString, getLastUrlSegment, isEmptyObject } from "../../Utils";
import useSupportChatWidgetScript from "../support-chat-widget/useSupportChatWidgetScript";
import ChannelsBotLivePreview from "./LivePreviewWidget/ChannelsBotLivePreview";
import WebBotLivePreview from "./LivePreviewWidget/WebBotLivePreview";

export interface Props extends HocBackdropLoaderProps {}

const BotBuilder: React.FC<Props> = ({ setLoading }) => {
  const { showAlert } = useNotification();
  useDocumentTitle("Bot Builder");

  const [isShowingWarningModal, toggleWarningModal] = useState<boolean>(false);

  const { bot_id }: any = useParams();
  const { userConfig }: any = useAppSelector(useAuthStore);
  const currentBot: any = useAppSelector(useCurrentBot);
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState<any>(0);
  const [isBotPreviewOpen, setIsBotPreviewOpen] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<any>({
    bot_enabled: false,
    is_pubilsh_button: false,
  });

  const { checkValidity } = useFlowBuilder();
  let dispatch = useAppDispatch();
  useEffect(() => {
    if (currentBot?.id) {
      getBotTrackingCodeFn(dispatch)({
        pathParams: [currentBot?.id],
      });
    }
  }, [currentBot?.id]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (currentBot) {
      setUserInput({
        ...userInput,
        bot_enabled: currentBot.bot_enabled,
      });
    }
  }, [currentBot?.bot_enabled]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCurrentTabAfterRefresh = () => {
    memoizedMenuOptions?.forEach((item: any, index: number) => {
      if (location.pathname.includes(item?.value)) {
        setValue(index);
      }
    });
  };

  const memoizedMenuOptions = useMemo(() => {
    const permissionsData: any =
      userConfig?.payload?.allowed_permissions ?? null;

    if (isEmptyObject(permissionsData)) return;

    const result =
      userConfig?.payload?.menu_items?.["bot_builder_tabs_options"] ?? [];
    return result;
  }, [userConfig?.payload]); // eslint-disable-line react-hooks/exhaustive-deps

  //*  do not remove location dependency
  useEffect(() => {
    getCurrentTabAfterRefresh();
  }, [memoizedMenuOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const sourceType = getLastUrlSegment(location?.pathname);
  // headers
  const HEADERS = {
    appid: JSON.stringify({
      botid: bot_id,
    }),
  };
  //fetching all Tabs Data Function
  const getAllTabsData = () => {
    const dataForTabs: any = {
      HEADERS,
      sourceType: sourceType === "document" ? "file" : sourceType,
      offset: 0,
      limit: 10,
      query: "",
    };

    dispatch(FaqSourceAction.GeteSourceListing(dataForTabs))
      .then((res: any) => {
        setLoading(false);
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    handleRedirect(memoizedMenuOptions?.[newValue]?.url);
  };
  const handleRedirect = (subUrl: string) => {
    const url = formatString(subUrl, [bot_id || currentBot.bot_id]);
    navigate(url);
  };

  const publishBotFn = () => {
    if (!userInput.is_pubilsh_button && !userInput.bot_enabled) {
      setUserInput({
        bot_enabled: true,
        is_pubilsh_button: true,
      });
      toggleWarningModal(true);
      return;
    }
    const HEADERS = {
      appid: JSON.stringify({
        botid: bot_id || currentBot.bot_id,
      }),
    };
    const { isValid, error } = checkValidity();
    if (!isValid) {
      showAlert(error, "error");
      return;
    }
    setLoading(true);
    ConversationService.publishBot({}, HEADERS)
      .then((res: any) => {
        showAlert("Bot Published", "success");
        //only call this on faq bot knowledge base
        if (value === 2) {
          getAllTabsData();
        }
      })
      .catch((error: any) => {
        showAlert("Something went wrong", "error");
      })
      .finally(() => {
        setUserInput({
          bot_enabled: true,
          is_pubilsh_button: false,
        });
      });

    ParaphraserService.trainBot({}, HEADERS)
      .then((res: any) => {
        showAlert("Bot trained", "success");
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const { addScript, removeScript } = useSupportChatWidgetScript();

  const previewBot = () => {
    const { isValid, error } = checkValidity();
    if (!isValid) {
      showAlert(error, "error");
      return;
    }
    removeScript();
    setTimeout(() => {
      setIsBotPreviewOpen(!isBotPreviewOpen);
    }, 800);
  };

  const handleClosePreviewBot = () => {
    setIsBotPreviewOpen(false);
  };
  const handleSeeAllBots = () => {
    const subUrl = `/workspace/{0}/setup/bots`;
    const workspace_id: string = window.localStorage.getItem("ws_id") || "";
    const url = formatString(subUrl, [workspace_id]);
    navigate(url);
    return;
  };

  if (memoizedMenuOptions?.length === 0) return null;
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    setUserInput({
      ...userInput,
      bot_enabled: values.bot_enabled,
    });
    toggleWarningModal(true);
  };
  const saveEnableConfig = () => {
    if (userInput.is_pubilsh_button) {
      publishBotFn();
    }
    setLoading(true);
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: {
          ...currentBot,
          bot_enabled: userInput.is_pubilsh_button
            ? true
            : userInput.bot_enabled,
        },
      }
    )
      .then((response) => {
        // SubmitProps.resetForm();
        toggleWarningModal(false);

        setLoading(false);
        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
        toggleWarningModal(false);
        setLoading(false);
      });
  };

  return (
    <BotBuilderWrap>
      <BuilderTitleBox>
        <div className="botTitle">
          <IconButton onClick={() => handleSeeAllBots()}>
            <AppMaterialIcons
              iconName="ArrowBackIos"
              style={{ fontSize: "1.1rem" }}
            />
          </IconButton>
          <p>{currentBot?.name} </p>
        </div>
        <div className="builderTopActionBox">
          <AppForm
            initialValues={userInput}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitBtn(values, submitProps);
            }}
          >
            <AppFormSwitchField
              label="Enable/Disable Bot"
              divStyle={{ margin: 0 }}
              name="bot_enabled"
              submitOnChange={true}
              tooltip={
                userInput.bot_enabled
                  ? "Disabling the bot will skip the bot flow and enable the chat with a live agent right away!"
                  : "Enabling the bot will activate the bot flow, allowing users to interact with the chatbot before being transferred to a live agent if needed!"
              }
            />
          </AppForm>
          <AppButton variant="grey" onClick={previewBot}>
            Preview
          </AppButton>
          {currentBot?.bot_id?.length > 0 ? (
            <>
              {["whatsapp", "telegram", "messenger"].includes(
                currentBot?.channel
              ) ? (
                <ChannelsBotLivePreview
                  setIsBotPreviewOpen={setIsBotPreviewOpen}
                  isBotPreviewOpen={isBotPreviewOpen}
                  handleClosePreviewBot={handleClosePreviewBot}
                />
              ) : (
                <WebBotLivePreview
                  setIsBotPreviewOpen={setIsBotPreviewOpen}
                  isBotPreviewOpen={isBotPreviewOpen}
                  handleClosePreviewBot={handleClosePreviewBot}
                />
              )}
            </>
          ) : null}

          <AppButton startIcon="ri-rocket-line" onClick={publishBotFn}>
            Publish
          </AppButton>
        </div>
      </BuilderTitleBox>
      <TopTabbarWrap>
        <TabsBox>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <StyledBuilderTabs
                value={value}
                onChange={handleChange}
                aria-label="bot builder"
                variant="scrollable"
                scrollButtons="auto"
              >
                {memoizedMenuOptions &&
                  memoizedMenuOptions?.length > 0 &&
                  memoizedMenuOptions.map((curElem: any, index: number) => {
                    return (
                      <Tab
                        label={curElem?.label}
                        id={`simple-tab-${index}`}
                        key={index}
                        disableRipple
                      />
                    );
                  })}
              </StyledBuilderTabs>
            </Box>
          </Box>
        </TabsBox>
        <ActionBox></ActionBox>
      </TopTabbarWrap>

      {memoizedMenuOptions &&
        memoizedMenuOptions?.length > 0 &&
        memoizedMenuOptions.map((curElem: any, index: number) => {
          return (
            <TabPanel value={value} index={index} key={index}>
              <Outlet />
            </TabPanel>
          );
        })}

      <AppModel
        isShowing={isShowingWarningModal}
        onClose={() => {
          toggleWarningModal(false);
          setUserInput({
            is_pubilsh_button: false,
            bot_enabled: currentBot?.bot_enabled,
          });
        }}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "500px",
            height: "250px",
          },
        }}
      >
        <ModalContainer>
          <div className="header">
            <h4>
              {userInput.is_pubilsh_button
                ? "Publish and Enable Bot"
                : !userInput.bot_enabled
                ? "Disable Bot"
                : "Enable Bot"}
            </h4>
            <div className="header-right">
              <span
                onClick={() => {
                  toggleWarningModal(false);
                  setUserInput({
                    is_pubilsh_button: false,
                    bot_enabled: currentBot?.bot_enabled,
                  });
                }}
              >
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="body">
            {userInput.is_pubilsh_button === false ? (
              <p>
                {!userInput.bot_enabled
                  ? "Disabling the bot will skip the bot flow and enable the chat with a live agent right away!"
                  : "Enabling the bot will activate the bot flow, allowing users to interact with the chatbot before being transferred to a live agent if needed!"}
              </p>
            ) : (
              <p>
                By publishing these changes, the bot will be enabled. This will
                activate the bot flow, allowing users to interact with the
                chatbot first, before being transferred to a live agent if
                needed.
              </p>
            )}
            <p>
              Are you sure, you want to{" "}
              {userInput.is_pubilsh_button ? (
                <strong>Enable Bot</strong>
              ) : !userInput.bot_enabled ? (
                <strong>Disable Bot</strong>
              ) : (
                <strong>Enable Bot</strong>
              )}
              ?
            </p>
          </div>
          <div className="footer">
            <AppButton
              variant="grey"
              onClick={() => {
                setUserInput({
                  is_pubilsh_button: false,
                  bot_enabled: currentBot?.bot_enabled,
                });
                toggleWarningModal(false);
              }}
            >
              Cancel
            </AppButton>
            <AppButton
              variant={
                userInput.is_pubilsh_button
                  ? "success"
                  : !userInput.bot_enabled
                  ? "danger"
                  : "success"
              }
              onClick={() => {
                saveEnableConfig();
              }}
            >
              {userInput.is_pubilsh_button
                ? "Enable & Publish"
                : !userInput.bot_enabled
                ? "Disable Bot"
                : "Enable Bot"}
            </AppButton>
          </div>
        </ModalContainer>
      </AppModel>
    </BotBuilderWrap>
  );
};

export default HocBackdropLoader(BotBuilder);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "100%", overflowY: "auto" }}
    >
      {value === index && (
        <BotBuilderContentBox>{children}</BotBuilderContentBox>
      )}
    </div>
  );
}

const BotBuilderWrap = styled.div`
  && {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
    height: 100%;
    background-color: #f5f6f8;

    overflow: hidden;
  }
`;
const BuilderTitleBox = styled.div`
  && {
    display: flex;

    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    gap: 1rem;
    width: 100%;
    min-height: 64px;
    height: 64px;
    overflow: hidden;

    background-color: white;

    .botTitle {
      display: flex;
      align-items: center;
      width: 50%;
      gap: 0.5rem;
      height: 100%;

      p {
        font-size: 1rem;
        font-weight: 500;
        line-height: 21px;
        color: #7e8392;
        text-transform: capitalize;
      }
    }

    .builderTopActionBox {
      display: flex;
      justify-content: right;
      align-items: center;
      gap: 1.5rem;
      width: 50%;
      height: 100%;
      button:hover .ri-rocket-line {
        color: ${theme.palette.primary.dark} !important;
      }
    }
  }
`;
const TopTabbarWrap = styled.div`
  && {
    display: flex;

    justify-content: space-between;
    padding: 0 1.5rem 0 1.1rem;
    gap: 0.5rem;
    width: 100%;
    min-height: 40px;
    height: 40px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
  }
`;
const TabsBox = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    background-color: white;

    .MuiBox-root {
      border: none !important;
    }
  }
`;
const ActionBox = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 0.5rem;
    width: 40%;
    height: 100%;
    background-color: white;
  }
`;

const BotBuilderContentBox = styled.div`
  && {
    display: flex;

    gap: 0.5rem;
    width: 100%;
    height: 100%;

    overflow: hidden;
  }
`;

export const StyledBuilderTabs = styled(Tabs)`
  && {
    .MuiTabs-indicator {
      background-color: #5b73e8;
    }
    .Mui-selected {
      color: #5b73e8 !important;
      background-color: white;
    }
    .MuiTab-root {
      min-height: 38px;
      max-height: 38px;
      height: 38px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      color: #7e8392;
      text-transform: capitalize;
      margin-right: 1rem;

      overflow: hidden;
    }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
    padding: 0 0.8rem;
    .header-left {
      display: flex;
      gap: 0.875rem;
    }
    .text h4 {
      font-size: 1.1rem;
      font-weight: 500;
    }
    .text p {
      color: ${theme.palette.default.darkGrey};
    }
  }
  .header-right {
    .MuiSvgIcon-root {
      color: ${theme.palette.default.darkGrey};
    }
    cursor: pointer;
  }

  .content {
    margin: 10px 0;
    padding: 10px 15px;
  }
  .body {
    padding: 0.8rem;
    height: calc(100% - 110px);
    p {
      margin-bottom: 20px;
      font-size: 16px;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
    border-top: 1px solid ${theme.palette.default.border};
    .change-template-name {
      display: flex;
      justify-content: space-between;
      align-items: start;

      p.change-name {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;
