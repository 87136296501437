import { Box, Divider } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../../../../AppComponents/AppMaterialIcons";
// custom imports
import {
  AppFormField,
  AppFormObserver,
  AppFormTextArea
} from "../../../../../../../AppComponents/Forms";
import AppReactSelectField from "../../../../../../../AppComponents/Forms/AppFormReactSelect";
import { ReactComponent as VoiceAgent } from "../../../../../../../assets/images/voice-agent.svg";
import { theme } from "../../../../../../../Customization/Theme";
import AppModel from "../../../../../../../Hooks/useModel/AppModel";
import { ContactListNetworkService } from "../../../../../../../Store/Slices/ContactList/ContactListNetworkService";
import { whoSpeaksFirstOptions } from "../../../utils";
import VoiceTable from "./VoiceTable";
import { AppToolTip } from "../../../../../../UiComponents/AppToolTip/AppToolTip";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
const AgentSetup = (): JSX.Element => {
  const [openScript, setOpenScript] = useState(false);
  const { values, setFieldValue }: any = useFormikContext();

  // const [showVariableList, setShowVariableList] = useState(false);
  // const [showScriptVariableList, setShowScriptVariableList] = useState(false);
  const [showVoiceAgent, setShowVoiceAgent] = useState(false);
  const [formikProps, setFormikProps] = useState<any>(null); // Formik props
  const [contactFields, setContactFields] = useState<any>([]);

  // const popoverRef = useRef<HTMLDivElement | null>(null);
  // const popoverScriptRef = useRef<HTMLDivElement | null>(null);

  // Click outside handler
  // useEffect(() => {
  //   function handleClickOutside(event: MouseEvent) {
  //     if (
  //       popoverRef.current &&
  //       !popoverRef.current.contains(event.target as Node)
  //     ) {
  //       setShowVariableList(false);
  //     }
  //     if (
  //       popoverScriptRef.current &&
  //       !popoverScriptRef.current.contains(event.target as Node)
  //     ) {
  //       setShowScriptVariableList(false);
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    ContactListNetworkService.getContactPropertiesList({}).then((response) => {
      setContactFields(response?.data?.data || []);
    });
  }, []);

  const handleAddField = () => {
    const newField = { name: "", label: "", value: "" }; // Create the object
    setFieldValue?.("mapping", [...(values.mapping || []), newField]);
  };

  return (
    <Wrapper>
      {/* <AgentHeader>
        <p>Monika</p>
        <div className="right-section">
          <span onClick={() => setShowVoiceAgent(true)}>
            <p>Jessica</p>
            <AppMaterialIcons
              style={{
                fontSize: "20px",
                color: theme.palette.default.darkGrey,
              }}
              iconName="ExpandMore"
            />
            <VoiceAgent />
          </span>
        </div>
      </AgentHeader> */}
      <Divider />
      <AgentFormFieldsWrapper>
      <AppFormField
          label="Bot Name"
          name="name"
          placeholder="Please enter description"
          tooltip={"This is description"}
          labelStyle={{ color: theme.palette.default.black }}
        />
        <AppFormField
          label="Description"
          name="description"
          placeholder="Please enter description"
          tooltip={"This is description"}
          labelStyle={{ color: theme.palette.default.black }}
        />
        <Box display="flex" alignItems="center" >
          <label>Who Speaks First </label>
          <AppToolTip
            iconWrapperStyle={{
              cursor: "pointer"
            }}
            tooltipProps={{
              title: `Who Speaks First`,
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={<HelpOutlineIcon style={{ width: "15px" }} />}
          >
          </AppToolTip>
        </Box>
        <div style={{ flex: 1 }}>
          <AppReactSelectField
            name={`who_speak_first`}
            options={whoSpeaksFirstOptions()}
            displaykey="label"
            // label="Who Speaks First"
            valueKey="value"
            placeholder="Assistant Initiated: Assistant begins with your Call Opening Message"
            isSearchable={true}
            isDisabled={true}
            isClearable={true}
            hideSelectedOptions={true}
            // isRequired={false}
            divStyle={{ background: "none", padding: "0" }}
            // tooltip={"Who Speaks First"}
            labelStyle={{ color: theme.palette.default.black }}
            maxWidth="100%"
          />
        </div>
        {/* <div style={{ position: "relative" }}> */}
        {/* <div
              style={{
                position: "absolute",
                top: 10,
                right: 0,
                cursor: "pointer",
                color: theme.palette.primary.main,
                zIndex: 1,
              }}
              onClick={() => setShowVariableList(true)}
            >
              <p>+ Add Variable</p>
            </div> */}
        {/* Popover for Variable List */}
        {/* {showVariableList && (
              <PopoverContainer ref={popoverRef}>
                {variableOptions.map((option, index) => (
                  <PopoverItem
                    key={index}
                    onClick={() => {
                      const newValue = values.welcome_message
                        ? `${values.welcome_message} ${option.value}`
                        : `${option.value}`;
                      setFieldValue("welcome_message", newValue);
                      handleAddField(option);
                      setShowVariableList(false); // Close popover after selection
                    }}
                  >
                    {option.label}
                  </PopoverItem>
                ))}
              </PopoverContainer>
            )} */}
        <AppFormField
          label="Welcome Message"
          name="welcome_message"
          placeholder="Welcome Message"
          tooltip={"This is description"}
          labelStyle={{ color: theme.palette.default.black }}
          isRequired={true}
          requiredSign={true}
        />
        {/* </div> */}
        {/* <div style={{ position: "relative" }}> */}
        {/* <div
              style={{
                position: "absolute",
                top: 10,
                right: 5,
                cursor: "pointer",
                color: theme.palette.primary.main,
                zIndex: 1,
              }}
              onClick={() => setShowScriptVariableList(true)}
            >
              <p>+ Add Variable</p>
            </div> */}
        {/* Popover for Variable List */}
        {/* {showScriptVariableList && (
              <PopoverContainer ref={popoverScriptRef}>
                {variableOptions.map((option, index) => (
                  <PopoverItem
                    key={index}
                    onClick={() => {
                      const newValue = values.agent_prompt
                        ? `${values.agent_prompt} ${option.value}`
                        : `${option.value}`;
                      setFieldValue("agent_prompt", newValue);
                      handleAddField(option);
                      setShowScriptVariableList(false); // Close popover after selection
                    }}
                  >
                    {option.label}
                  </PopoverItem>
                ))}
              </PopoverContainer>
            )} */}
        <AppFormTextArea
          name="agent_prompt"
          placeholder="Please enter script"
          rows={40}
          label="Script"
          tooltip={"Please enter agent prompt"}
          labelStyle={{ color: theme.palette.default.black }}
          handleIconClick={() => {
            setOpenScript((prev) => !prev);
          }}
        />
        <div className="extract-header">
          <p className="info">Extract & Store Information</p>
          <div
            style={{
              cursor: "pointer",
              color: theme.palette.primary.main,
            }}
            onClick={() => handleAddField()}
          >
            <p>+ Add Variable</p>
          </div>
        </div>

        <FieldArray name="mapping">
          {({ form, remove }) => (
            <FieldArrayContainer>
              <Table>
                <thead>
                  <tr>
                    <Th>Fields Name</Th>
                    <Th>Contact Property</Th>
                    <Th>Default Value</Th>
                  </tr>
                </thead>
                <tbody>
                  {values?.mapping?.map((field: any, index: number) => {
                    return (
                      <tr key={index}>
                        <Td style={{ backgroundColor: "#C5C5C580" }}>
                          <AppFormField
                            name={`mapping[${index}].label`}
                            placeholder="Enter field name"
                          />
                        </Td>
                        <Td>
                          <AppReactSelectField
                            name={`mapping[${index}].name`}
                            options={contactFields}
                            displaykey="label"
                            valueKey="name"
                            placeholder="Select Contact Property"
                            isSearchable={true}
                            isClearable={true}
                            hideSelectedOptions={true}
                            isRequired={false}
                            divStyle={{ background: "none", padding: "0" }}
                            labelStyle={{ color: theme.palette.default.black }}
                          />
                        </Td>
                        <Td style={{ position: "relative" }}>
                          <AppFormField
                            name={`mapping[${index}].value`}
                            placeholder="Enter default value"
                          />

                        </Td>
                        <Td>
                          <div
                            style={{
                              cursor: "pointer",
                              color: theme.palette.default.red,
                              // position: "absolute",
                              right: "10px",
                              // top: "50%",
                              // transform: "translateY(-50%)"
                            }}
                            onClick={() => remove(index)}
                          >
                            <AppMaterialIcons iconName="Close" />
                          </div>
                        </Td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </FieldArrayContainer>
          )}
        </FieldArray>
      </AgentFormFieldsWrapper>
      <AppFormObserver setFormikProps={setFormikProps} />

      <AppModel
        isShowing={openScript}
        onClose={() => {
          setOpenScript(false);
        }}
        divStyle={{
          padding: 0,
          zIndex: "99999999999 !important",
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "75%",
            height: "75%",
            zIndex: "99999999999 !important",
          },
        }}
      >
        <ModalContainer>
          <div className="header">
            <h4>Script</h4>
            <div className="header-right">
              <span
                onClick={() => {
                  setOpenScript(false);
                }}
              >
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="body">
            <AppFormTextArea style={{minHeight:"540px"}} name="agent_prompt" rows={60} label="" />
          </div>
          <div className="footer">
            <AppButton
              variant="grey"
              onClick={() => {
                setOpenScript(false);
              }}
            >
              Cancel
            </AppButton>
            <AppButton
              onClick={() => {
                setOpenScript(false);
              }}
            >
              Save Changes
            </AppButton>
          </div>
        </ModalContainer>
      </AppModel>

      {/* Choose Voice Agent Model */}
      <AppModel
        isShowing={showVoiceAgent}
        onClose={() => {
          setShowVoiceAgent(false);
        }}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "75%",
            height: "75%",
          },
        }}
      >
        <ModalContainer>
          <div className="header">
            <h4>Select Voice</h4>
            <div className="header-right">
              <span
                onClick={() => {
                  setShowVoiceAgent(false);
                }}
              >
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="body">
            <VoiceTable />
          </div>
        </ModalContainer>
      </AppModel>
    </Wrapper>
  );
};

export default AgentSetup;

const Wrapper = styled.div`
  && {
    background-color: ${theme.palette.default.background};
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    .info {
      font-size: 14px;
      font-weight: 600;
    }
    .extract-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.5rem 0;
    }
  }
`;

const AgentHeader = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    p {
      font-weight: 500;
      font-size: 14px;
    }
    .right-section {
      display: flex;
      gap: 0.8rem;
      align-items: center;
      cursor: pointer;
      font-weight: 500;
    }
    div span {
      display: flex;
      align-items: center;
      gap: 0.2rem;
    }
  }
`;

const AgentFormFieldsWrapper = styled.div`
  && {
    padding: 1rem;
  }
`;
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
    padding:0 0.8rem;
    .header-left {
      display: flex;
      gap: 0.875rem;
      }
      .text h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .text p {
        color: ${theme.palette.default.darkGrey};
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }
  .content{
    margin:10px 0;
    padding: 10px 15px;
}
  .body {
    padding: 0.8rem;
    height: calc(100% - 10px);
    p {
    margin-bottom: 20px;
    font-size: 16px;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
    border-top: 1px solid ${theme.palette.default.border};
    .change-template-name {
      display: flex;
      justify-content: space-between;
      align-items: start;

      p.change-name {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
`;

const Th = styled.td`
  border-top: 1px solid #c5c5c5;
  border-bottom: 1px solid #c5c5c5;
  border-left: none;
  border-right: none;
  padding: 8px;
  font-weight: 500;
  text-align: left;
  margin-left: 10px;
`;

const Td = styled.td`
  border-top: 1px solid #c5c5c5;
  border-bottom: 1px solid #c5c5c5;
  padding: 8px;
`;

const FieldArrayContainer = styled.div`
  width: 100%;
`;

// const PopoverContainer = styled.div`
//   position: absolute;
//   top: 30px;
//   right: 0;
//   width: 250px;
//   max-height: 250px;
//   overflow-y: auto;
//   background: white;
//   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
//   border-radius: 5px;
//   padding: 8px 0;
//   z-index: 9999999999;
// `;

// const PopoverItem = styled.div`
//   padding: 10px 15px;
//   font-size: 14px;
//   cursor: pointer;
//   &:hover {
//     background: ${theme.palette.primary.light};
//     color: white;
//   }
// `;
