import { Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styled from 'styled-components'
import AppButton from '../../../AppComponents/AppButton'
import { AppForm, AppFormFastField, AppFormField, AppFormObserver, AppFormSelectFastField, AppSubmitButton } from '../../../AppComponents/Forms'
import { theme } from '../../../Customization/Theme'
import { useNotification } from '../../../Hooks/useNotification/useNotification'
import { useAppDispatch, useAppSelector } from '../../../Store'
import { AutomationBuilderActions } from '../../../Store/Slices/AutomationBuilder/automationBuilder.actions'
import { automationBuilderPayload, automationBuilderState, modelConfigData } from '../../../Store/Slices/AutomationBuilder/automationBuilder.selectors'
import { closeConfigModal, setNodeConfig } from '../../../Store/Slices/AutomationBuilder/automationBuilder.slice'
import { useCurrentBot } from '../../../Store/Slices/DashboardSlices'
import { SMSActions } from '../../../Store/Slices/SMS/sms.actions'
import { templateByIdData } from '../../../Store/Slices/SMS/sms.selectors'
import { UserActions } from '../../../Store/Slices/Settings/LiveChat/User/user.action'
import { userListigData } from '../../../Store/Slices/Settings/LiveChat/User/user.selectors'
import AppReactSelectField from '../../../socket/appComponents/UtilsComponents/AppFormReactSelect'
import { getDefaultValue } from '../utils'
import { SmsConfigSchema } from '../validationSchema'
import { saveGraph } from '../config'
import { QueryBuilderActions } from '../../../Store/Slices/QueryBuilder/QueryBuilder.actions'
import { useQueryBuilderStore } from '../../../Store/Slices/QueryBuilder/QueryBuilder.selectors'
import { FieldArray, getIn, useFormikContext } from "formik";
import { makeStringFirstLetterUppercase } from '../../../Utils'
import React from 'react'
import { AppToolTip } from '../../UiComponents/AppToolTip/AppToolTip'
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import { withLoader } from '../../../AppComponents/Loader';

const tempOption: any = {
    label: "Value + text",
    name: "value_text",
    placeholder: "Enter Your value",
    type: "text",
    valueEditorType: "input",
};
const SmsSend = ({ setLoading }: any): JSX.Element => {
    const dispatch = useAppDispatch();
    const formRef: any = useRef();
    const { showAlert } = useNotification();
    const [formikProps, setFormikProps] = useState<any>(null);
    const automationPayload: any = useAppSelector(automationBuilderPayload);
    const automationReduxData: any = useAppSelector(automationBuilderState);
    const templateDetails: any = useAppSelector(templateByIdData);
    const smsModelConfig: any = useAppSelector(modelConfigData);
    const userlListing = useAppSelector(userListigData);
    const { queryBuilderFields } = useAppSelector(useQueryBuilderStore);
    const currentBot = useAppSelector(useCurrentBot);
    const [initialValues, setInitialValues]: any = useState({
        smsTemplateId: null,
        sender_id: null,
        resolved_body_variables: []
    });
    const [limit] = useState(9999);
    useEffect(() => {
        if (templateDetails?.body_text?.length > 0) {
            formRef?.current?.setFieldValue("resolved_body_variables", automationPayload?.draftedUIObject?.graphData?.nodes?.[automationReduxData?.selectedNodeId]?.nodeObjectData?.variables || templateDetails?.body_text)
        }
    }, [templateDetails, automationPayload, automationReduxData])
    const handleSubmit = async (values: any, submitProps: any) => {
        let payload: any = {
            nodeObject: {
                smsTemplateId: values?.smsTemplateId?.id,
                sender_id: values?.sender_id?.value,
                variables: values?.resolved_body_variables
            },
            nodeId: automationReduxData?.selectedNodeId,
            configText: `${templateDetails?.template_name}`

        }
        if (automationReduxData?.modelType === "sms-send_user") {
            payload.nodeObject.to = values?.to?.id
        }
        submitProps.setSubmitting(false);

        dispatch(setNodeConfig(payload));
        await saveGraph();
        showAlert("Node Config Saved Successfully!", "success");
        closeModel();
    }
    const closeModel = () => {
        dispatch(closeConfigModal({}));
    }
    useEffect(() => {
        dispatch(AutomationBuilderActions.getModelConfigByType("sms-config"))
            .finally(() => {
                setLoading(false);
            });
    }, [])   // eslint-disable-line react-hooks/exhaustive-deps

    const HEADERS = {
        appid: JSON.stringify({
            botid: currentBot?.bot_id,
        }),
    };

    const getFieldsDataFromBackend = () => {
        let payload = {
            id: "other",
        }
        dispatch(QueryBuilderActions.getQueryBuilderMappingFields(payload));
    };
    useEffect(() => {
        const data = {
            HEADERS,
            limit: limit,
            offset: 0,
        };
        dispatch(UserActions.getUserListing(data));

        getFieldsDataFromBackend();
    }, [])   // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (automationPayload?.draftedUIObject?.graphData?.nodes?.[automationReduxData?.selectedNodeId]?.nodeObjectData?.smsTemplateId) {
            const userInfo = getDefaultValue(userlListing?.data, "id", automationPayload?.draftedUIObject?.graphData?.nodes?.[automationReduxData?.selectedNodeId]?.nodeObjectData?.to)
            setInitialValues({
                smsTemplateId: {
                    id: smsModelConfig?.template_list.find((ele: any) => ele.id === automationPayload?.draftedUIObject?.graphData?.nodes?.[automationReduxData?.selectedNodeId]?.nodeObjectData?.smsTemplateId)?.id,
                    value: smsModelConfig?.template_list.find((ele: any) => ele.id === automationPayload?.draftedUIObject?.graphData?.nodes?.[automationReduxData?.selectedNodeId]?.nodeObjectData?.smsTemplateId)?.name
                },
                sender_id: templateDetails?.sender_ids.find((ele: any) => ele.value === automationPayload?.draftedUIObject?.graphData?.nodes?.[automationReduxData?.selectedNodeId]?.nodeObjectData?.sender_id),
                to: {
                    id: userInfo?.id,
                    value: userInfo?.first_name
                },
            })
        }
    }, [automationPayload, smsModelConfig, automationReduxData, templateDetails]); // eslint-disable-line react-hooks/exhaustive-deps
    const onChange = (value: any) => {
        dispatch(SMSActions.getTemplateById(value?.id))
    }
    useEffect(() => {
        if (formRef?.current?.values?.smsTemplateId?.id) {
            dispatch(SMSActions.getTemplateById(formRef?.current?.values?.smsTemplateId?.id))
        }
    }, [formRef?.current?.values?.smsTemplateId]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect Manually set the isValid state to false
    useEffect(() => {
        formRef?.current?.setFormikState((prevState: any) => ({
            ...prevState,
            isValid: false,
        }));
        formRef?.current?.setFieldValue("smsTemplateId", "", { shouldDirty: true })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ModelFormWrapper>
            <Typography variant="h6">
                {automationReduxData?.modelType === "sms-send_user" ? "Send SMS To User" : "Send SMS"}
            </Typography>
            {
                smsModelConfig?.template_list ? (
                    <AppForm
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={SmsConfigSchema}
                        innerRef={formRef}
                    >
                        <AppReactSelectField
                            name={"smsTemplateId"}
                            options={smsModelConfig?.template_list || []}
                            label="SMS Template"
                            displayKey="name"
                            valueKey="id"
                            placeholder="Select SMS Template"
                            isSearchable={true}
                            isClearable={true}
                            hideSelectedOptions={true}
                            isRequired={true}
                            onChangeCallbackFn={onChange}
                            divStyle={{ background: "none", padding: "0" }}
                        />
                        {formRef?.current?.values?.smsTemplateId?.id &&
                            <React.Fragment>
                                <AppReactSelectField
                                    name={"sender_id"}
                                    options={templateDetails?.sender_ids || []}
                                    label="Select Sender"
                                    displayKey="name"
                                    valueKey="value"
                                    placeholder="Select Sender"
                                    isSearchable={true}
                                    isClearable={true}
                                    hideSelectedOptions={true}
                                    isRequired={true}
                                    isDisabled={!formRef?.current?.values?.smsTemplateId}
                                    divStyle={{ background: "none", padding: "0" }}
                                />
                                {
                                    automationReduxData?.modelType === "sms-send_user" && <>
                                        <AppReactSelectField
                                            name={"to"}
                                            options={userlListing?.data || []}
                                            label="Select User"
                                            displayKey="first_name"
                                            valueKey="id"
                                            placeholder="Select User"
                                            isSearchable={true}
                                            isClearable={true}
                                            hideSelectedOptions={true}
                                            divStyle={{ background: "none", padding: "0" }}
                                        />

                                    </>
                                }

                                <RenderSingleTypeVariables
                                    name={"resolved_body_variables"}
                                    variableType="Body Variables"
                                    fieldsData={queryBuilderFields}
                                />
                            </React.Fragment>
                        }
                        <AppFormObserver setFormikProps={setFormikProps} />
                        <ModelFooter>
                            <AppButton variant="outline" onClick={closeModel}>Cancel</AppButton>
                            <AppSubmitButton title={"Save Config"} disabled={!formRef?.current?.isValid} />
                        </ModelFooter>
                    </AppForm>
                ) : (
                    <React.Fragment>
                        <div className="image_container">
                            <NoDataFound />
                        </div>
                        <ModelFooter>
                            <AppButton onClick={closeModel}>Close</AppButton>
                        </ModelFooter>
                    </React.Fragment>
                )
            }

        </ModelFormWrapper>
    )
}

export default withLoader(SmsSend)
interface RenderSingalProps {
    variableType: string;
    name: string;
    isMedia?: boolean;
    fieldsData: any;
}
const RenderSingleTypeVariables: React.FC<RenderSingalProps> = ({
    variableType,
    name,
    isMedia = false,
    fieldsData = [],
}) => {
    const { values } = useFormikContext<any>();
    if (getIn(values, name)?.length === 0) {
        return null;
    }
    return (
        <React.Fragment>
            <p className="variablesType">
                {makeStringFirstLetterUppercase(variableType)}
            </p>

            <FieldArray name={name}>
                {(arrayHelpers) => (
                    <React.Fragment>
                        {getIn(values, name)?.length > 0 &&
                            getIn(values, name)?.map((item: any, index: number) => {
                                const textfieldName: string = `${name}.${index}.value`;
                                const selectfieldName: string = `${name}.${index}.index`;
                                return (
                                    <div className="variablesContent" key={index.toString()}>
                                        <p className="variableIndex">{item?.key as string}</p>
                                        {isMedia ? (
                                            <AppFormFastField
                                                name={textfieldName}
                                                placeholder={"url"}
                                            />
                                        ) : (
                                            <React.Fragment>
                                                {fieldsData?.length > 0 && (
                                                    <AppFormSelectFastField
                                                        Options={[...fieldsData, tempOption]}
                                                        name={selectfieldName}
                                                        divStyle={{ maxWidth: "300px", margin: "0 20px" }}
                                                        valueKey="name"
                                                    />
                                                )}


                                                <CustomVariableWrapper>
                                                    <AppFormField
                                                        name={textfieldName}
                                                        placeholder={
                                                            getIn(values, selectfieldName) === "value_text"
                                                                ? "Static value"
                                                                : "Fallback Value"
                                                        }
                                                    />
                                                    <AppToolTip
                                                        tooltipProps={{
                                                            title:
                                                                getIn(values, selectfieldName) === "value_text"
                                                                    ? "Static Value - A fixed and unchanging piece of data or text that remains consistent for all users. It is not personalized and remains unchanged throughout interactions. Examples : URLs (e.g., www.example.com), coupon codes (e.g., SAVE10), default settings (e.g., language: English), company names (e.g., XYZ Corporation), etc."
                                                                    : "Fallback values are crucial. When a property is unavailable for a user, we automatically replace it with the specified fallback value. These alternatives ensure a seamless user experience and prevent errors. For example, if a user's First Name is not found, the fallback value should be defined as 'User'.",
                                                        }}
                                                        iconButtonProps={{ disableRipple: true }}
                                                        IconComponent={
                                                            <InfoOutlinedIcon fontSize="small" />
                                                        }
                                                    />
                                                </CustomVariableWrapper>
                                            </React.Fragment>
                                        )}
                                    </div>

                                );
                            })}
                    </React.Fragment>
                )}
            </FieldArray>
        </React.Fragment>
    );
};


export const CustomVariableWrapper = styled.div`
  & {
    width: 100%;
    display: flex;
    align-items: top;
    input {
      width: calc(100% - 40px);
    }
    .textLabelOptional ~ input {

    }
  }
  `;
const ModelFormWrapper = styled.div`
    width: 720px;
    h6 {
        border-bottom: 1px solid ${theme.palette.default.border};
        margin-bottom: 15px;
        padding: 0 0 0 0;
        font-size: 16px;
        font-weight: 500;
    }
    .variablesContent {
        display: flex;
        align-items: center;
        justify-content: space-around;
        div:nth-child(2){
            label {
                display: none;
            }
        }
    }
    .image_container{
        text-align:center;
    }
`
const ModelFooter = styled.div`
    display: flex;
    align-items:center;
    justify-content: flex-end;
    border-top: 1px solid ${theme.palette.default.border};
    padding: 15px 0 0;
    button:last-child {
        margin-left: 15px;
    }
`