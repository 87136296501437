import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import uniqid from "uniqid";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormObserver,
  AppSubmitButton
} from "../../../AppComponents/Forms";
import { AppQueryBuilder } from "../../../AppComponents/QueryBuilder";
import {
  convertQueryToMongo,
  initialQuery,
  isQueryValid,
} from "../../../AppComponents/QueryBuilder/utils/utils";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  automationBuilderPayload,
  automationBuilderState,
  modelConfigError
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import {
  closeConfigModal,
  setNodeConfig,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import { QueryBuilderActions } from "../../../Store/Slices/QueryBuilder/QueryBuilder.actions";
import { useQueryBuilderStore } from "../../../Store/Slices/QueryBuilder/QueryBuilder.selectors";
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import { saveGraph } from "../config";
import { withLoader } from "../../../AppComponents/Loader";

export const EmailViewOptions = [
  {
    id: uniqid(),
    value: "Any Email",
    name: "any",
  },
  {
    id: uniqid(),
    value: "Particular Email",
    name: "perticular",
  },
];
const ContactFieldMatch = ({ setLoading }: any): JSX.Element => {
  const formRef: any = useRef();
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const { queryBuilderFields, queryBuilderOperators } =    
    useAppSelector(useQueryBuilderStore);

  const [formikProps, setFormikProps] = useState<any>(null);
  const [userInput, setUserInput] = useState<any>({
    filter_query: initialQuery,
  });

  const automationReduxData: any = useAppSelector(automationBuilderState);
  const automationPayload: any = useAppSelector(automationBuilderPayload);
  const getFieldsDataFromBackend = () => {
    let module_id = "other";
    let payload = {
      id: module_id,
    };
    dispatch(QueryBuilderActions.getQueryBuilderMappingFields(payload)).then(() => 
      {
       setLoading(false)
    });
  };
  useEffect(() => {
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.filter_query
    ) {
      //function to filter object based on Value
      setUserInput({
        filter_query:
          automationPayload?.draftedUIObject?.graphData?.nodes?.[
            automationReduxData?.selectedNodeId
          ]?.nodeObjectData?.filter_query,
      });
    }
  }, [automationPayload]);   // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getFieldsDataFromBackend();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const closeModel = () => {
    dispatch(closeConfigModal({}));
  };
  const handleSubmit = async (values: any, submitProps: any) => {
    const validQuery = isQueryValid(values?.filter_query);
    if (!validQuery) {
      showAlert("Please add only valid rules.", "error");
      submitProps.setSubmitting(false);
      return;
    }

    const mongoQuery = convertQueryToMongo(values?.filter_query);
    let data = {
      mongodb_query: mongoQuery,
      filter_query: values?.filter_query,
    };
    let payload: any = {
      nodeObject: data,
      nodeId: automationReduxData?.selectedNodeId,
      configText: `Rules Configured`,
    };
    submitProps.setSubmitting(false);
    dispatch(setNodeConfig(payload));
    await saveGraph();

    showAlert("Node Config Saved Successfully!", "success");
    closeModel();
  };
  const onQueryChangeCallbackFn = (filtersValue: any) => {
    formikProps?.setFieldValue("filter_query", filtersValue);
  };
  return (
    <ModelFormWrapper>
      <Typography variant="h6">
        {automationReduxData?.modelType === "contact-field_match"
          ? "Field Match"
          : "Check Contact Field"}
      </Typography>
      {queryBuilderFields?.length ? (
        <AppForm
          onSubmit={handleSubmit}
          initialValues={userInput}
          innerRef={formRef}
        >
          <Box className="modal-body">
            {queryBuilderFields?.length > 0 ? (
              <AppQueryBuilder
                fields={queryBuilderFields}
                operators={queryBuilderOperators}
                onChangeCallbackFn={onQueryChangeCallbackFn}
                defaultInputValueFromApi={
                  userInput?.filter_query?.rules?.length
                    ? userInput?.filter_query
                    : null
                }
              />
            ) : null}
          </Box>
          <AppFormObserver setFormikProps={setFormikProps} />
          <ModelFooter>
            <AppButton variant="outline" onClick={closeModel}>Cancel</AppButton>
            <AppSubmitButton title={"Save Config"} />
          </ModelFooter>
        </AppForm>
      ) : (
        <React.Fragment>
          <div className="image_container">
            <NoDataFound />
          </div>
          <ModelFooter>
            <AppButton onClick={closeModel}>Close</AppButton>
          </ModelFooter>
        </React.Fragment>
      )}
    </ModelFormWrapper>
  );
};
export default withLoader(ContactFieldMatch);

const ModelFormWrapper = styled.div`
  width: 900px;
  padding: 0;
  .modal-body {
    height: calc(100vh - 200px);
    overflow-y: hidden;
    border-top: 1px solid ${theme.palette.default.border};
    border-bottom: 1px solid ${theme.palette.default.border};
    padding: 15px 0;
  }
  h6 {
    margin-bottom: 15px;
    padding: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }
  .image_container{
    text-align:center;
  }
`;
const ModelFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0 0;
  button:last-child {
    margin-left: 15px;
  }
`;
