import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNotification } from '../../../../../../Hooks/useNotification/useNotification';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../Store';
import { ContactBasicInfo, contactDetailsId, getContactTaskOutcome, getContactTaskPriority, getContactTaskType } from '../../../../../../Store/Slices/Contacts/contacts.selector';
import { AppConfig } from '../../../../../../Config/app-config';
import moment from 'moment';
import { ContactsActions } from '../../../../../../Store/Slices/Contacts/contacts.action';
import { DashboardNetworkService } from '../../../../../../Store/Slices/DashboardRedux/DashboardNetworkService';
import { uniqueId } from 'lodash';
import { ReactComponent as TagCrossIcon } from "../../../../../../../src/assets/images/tag-cross-icon.svg";
import { CreateTaskSchema, getCreateTaskBody } from '../../../ContactConsts';
import { ContactWrapper, LeadPageWrapper } from '../../../../LeadsStyles';
import { Autocomplete, Checkbox, debounce, IconButton, ListSubheader, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { TaskStatus } from '../../../AddContactStyles';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import Nodata from '../../../../../../../src/assets/images/noDatafound.svg';
import Danger from "../../../../../../../src/assets/images/danger-live.svg";
import AppButton from '../../../../../../AppComponents/AppButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AppRangeFutureDatePicker from '../../../../../../AppComponents/AppRangeFutureDatePicker';
import { ProtectedAppButton } from '../../../../../../AppComponents/ProtectedComponents';
import { AppMaterialIcons } from '../../../../../../AppComponents/AppMaterialIcons';
import AppPaginationComponent from '../../../../../../AppComponents/Pagination/Pagination';
import AppModel from '../../../../../../Hooks/useModel/AppModel';
import Task from "../../../../../ContactTask";
import { AppForm, AppFormDatePicker, AppFormField, AppFormSelectField, AppFormTextArea, AppFormTimePickerMui, AppSubmitButton } from '../../../../../../AppComponents/Forms';
import AppFormCheckboxField from '../../../../../../AppComponents/Forms/AppFormCheckboxField';
import styled from 'styled-components';
import { theme } from '../../../../../../Customization/Theme';

var timer: NodeJS.Timeout; 
const Tasks = (props: any) => {
    const dispatch = useAppDispatch();
    const { showAlert } = useNotification();
    const contactId: any = useAppSelector(contactDetailsId);
    const navigate = useNavigate();
    const TaskOutcome = useAppSelector(getContactTaskOutcome);
    const TaskPriority = useAppSelector(getContactTaskPriority);
    const TaskType = useAppSelector(getContactTaskType);
    const [agentList, setAgentList] = useState([])
    const [agentListForAllTask, setAgentListForAllTask] = useState([])
    const [dateFilter, setDateFilter] = useState("today")
    const [searchTerm, setSearchTerm] = useState("")
    const [relatedTo, setRelatedTo] = useState([])
    const [statusRelatedTo, setStatusRelatedTo] = useState(false)
    const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false)
    const [isCompleteTaskOpen, setIsCompleteTaskOpen] = useState(false)
    const [open, setOpen] = useState(false); // Explicitly control open state
    const [inputValue, setInputValue] = useState("");
    const [selectRelatedToValue, setSelectRelatedToValue] = useState<any>({})
    const [markAsCompleted, setMarkAsCompleted] = useState<boolean>(false)
    const [allTaskList, setAllTaskList] = useState<any>([])
    const [isUpdate, setIsUpdate] = useState<any>(false)
    const [taskId, setTaskId] = useState<any>("")
    const userData: any = AppConfig.getUserData("loggedInUserData")?.data?.user;
    const basicInformation: any = useAppSelector(ContactBasicInfo);
    const [taskData, setTaskData] = useState<any>({})
    const [selectTaskIdList, setSelectTaskIdList] = useState<any>([])
    const [selectAll, setSelectAll] = useState<boolean>(false)
    const [pagination, setPagination] = useState<any>({})
    const [permission, setPermission] = useState<any>("")
    const [isDeleteModel, setIsDeleteModel] = useState<boolean>(false)

    // Ref for the search input
    const searchInputRef = useRef<HTMLInputElement>(null);

    const [localValues, setLocalValues] = useState<any>({
        itemsPerPage: { label: "10  per page", value: 10 },
        currentPage: 0,
        startDate: null,
        endDate: null,
        task_type: "",
        task_priority: "",
        task_status: "",
        task_ower: "",
        //For Create Task
        create_task_title: "",
        create_task_description: "",
        create_task_type: "",
        create_task_date: new Date(new Date().setDate(new Date().getDate() + 1)),
        create_task_time: "",
        create_task_priority: "",
        create_task_outcome: "",
        create_task_owner: "",
        create_task_related_to: "",
        create_task_related_to_Details: {},
        mark_as_completed: false,
        task_completed_date: "",
        task_completed_time: "",

    });
    function handlePageChange(event: any, value: any) {
        setLocalValues({
            ...localValues,
            currentPage: value - 1,
        });
    }
    const handleChangeRowsPerPage = (value: any) => {
        setLocalValues({
            ...localValues,
            itemsPerPage: value,
            currentPage: 0,
        });
    };
    const getAllTaskList = () => {
        const dataFilter = dateFilter === "custom" ? {
            "start": moment(localValues?.startDate).format('YYYY-MM-DD'),
            "end": moment(localValues?.endDate).format('YYYY-MM-DD')
        } : getDate(dateFilter)
        let payload: any = {
            "offset": localValues?.currentPage,
            "limit": localValues?.itemsPerPage?.value,
            "task_type": localValues?.task_type ? localValues?.task_type : null,
            "task_priority": localValues?.task_priority ? localValues?.task_priority : null,
            "task_status_filter": localValues?.task_status ? localValues?.task_status : null,
            "task_owner": localValues?.task_ower ? localValues?.task_ower : null,
            "start_date": dataFilter?.start,
            "end_date": dataFilter?.end
        }
        // Remove null or undefined keys
        Object.keys(payload).forEach(key => {
            if (payload[key] === null || payload[key] === undefined) {
                delete payload[key];
            }
        });
        if (contactId) {
            payload['contact_id'] = contactId
        }
        if (payload?.contact_id) {
            props.setLoading(true)
            dispatch(ContactsActions.getAllTaskByContactId(payload))
                .then((res: any) => {
                    props.setLoading(false)
                    setAllTaskList(res?.payload?.data?.response?.data)
                    setPagination(res?.payload?.data?.response?.pagiantion)
                    setPermission(res?.payload?.data?.response?.read_permissions)
                })
                .catch((error: any) => {
                    props.setLoading(false)
                    console.error(error);
                })
        } else {
            props.setLoading(true)
            dispatch(ContactsActions.getAllTask(payload))
                .then((res: any) => {
                    props.setLoading(false)
                    setAllTaskList(res?.payload?.data?.response?.data)
                    setPagination(res?.payload?.data?.response?.pagiantion)
                    setPermission(res?.payload?.data?.response?.read_permissions)

                })
                .catch((error: any) => {
                    props.setLoading(false)
                    console.error(error);
                })
        }

    }
    const getDate = (filter: any) => {
        const today = dayjs();
        switch (filter) {
            case "overdue":
                return {
                    start: dayjs('1995-01-01').format('YYYY-MM-DD'),
                    end: today.startOf('day').format('YYYY-MM-DD') // End of yesterday
                };
            case "today":
                return {
                    start: today.startOf('day').format('YYYY-MM-DD'), // Start of today
                    end: today.endOf('day').format('YYYY-MM-DD') // End of today
                };
            case "tomorrow":
                return {
                    start: today.add(1, 'day').startOf('day').format('YYYY-MM-DD'), // Start of tomorrow
                    end: today.add(1, 'day').endOf('day').format('YYYY-MM-DD') // End of tomorrow
                };
            case "this_week":
                return {
                    start: today.startOf('week').format('YYYY-MM-DD'), // Start of the current week
                    end: today.endOf('week').format('YYYY-MM-DD') // End of the current week
                };
            default:
                return { start: null, end: null }; // For "all" or undefined filter
        }
    }
    useEffect(() => {
        setSelectAll(false)
        setMarkAsCompleted(false)
        setSelectTaskIdList([])
        dispatch(ContactsActions.getContactTaskDetailsOptions({}))
        DashboardNetworkService.getAgentList().then((res: any) => {
            setAgentList(res?.data?.agent_list || []);
            setAgentListForAllTask(res?.data?.agent_list || [])
        });
    }, [])
    useEffect(() => {
        getAllTaskList()
    }, [localValues, dateFilter])
    const handleChangeDate = (data: { startDate: Date; endDate: Date }) => {
        setDateFilter("custom")
        setLocalValues({
            ...localValues,
            startDate: data.startDate,
            endDate: data.endDate,
        });
    };
    const handleSelectChange = (e?: any, field?: any) => {
        setLocalValues({
            ...localValues,
            [field]: e.target.value,
            currentPage: 0,
        });
    };
    const handleChange = (value?: any, field?: any) => {
        setLocalValues({
            ...localValues,
            [field]: value,
            currentPage: 0,
        });
        setOpen(false)
    };
    const clearCallback = (e?: any, field?: any) => {
        setLocalValues({
            ...localValues,
            [field]: "",
        });
    };

    const handleSelectSearchForAutoComplete = useCallback(debounce((value?: any) => {
        if (value.length < 3) return; // Only trigger API call if input has 3 or more characters
        setStatusRelatedTo(true)
        if (value !== selectRelatedToValue?.full_name) {
            dispatch(ContactsActions.getContectList(value))
                .then((response: any) => {
                    if (response?.payload?.data?.data?.length !== 0 && value !== response?.payload?.data?.data?.[0]?.full_name) {
                        setRelatedTo(response?.payload?.data?.data);
                        setStatusRelatedTo(false)
                        setOpen(true); // Keep the dropdown open after receiving API response
                    }
                }).catch((error: any) => {
                    console.error(error);
                });
        }
    }, 900), []); // Memoize the debounce function with an empty dependency array

    // Function to refocus the input
    const refocusInput = () => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };
    const handleClear = () => {
        setLocalValues({
            ...localValues,
            create_task_related_to: "",
            create_task_related_to_Details: {},
        });
        setSelectRelatedToValue({})
        setStatusRelatedTo(false)
    }
    const handleSubmit = async (values: any, SubmitProps: any) => {
        if (selectRelatedToValue?.id) {
            values['selectRelatedToValue'] = selectRelatedToValue
            let payload: any = getCreateTaskBody(values)
            if (isUpdate) {
                payload['task_id'] = taskId
                dispatch(ContactsActions.updateTask(payload))
                    .then((response: any) => {
                        if (response?.payload?.data?.status) {
                            showAlert("Update Task Successfully", "success");
                            setIsCreateTaskOpen(false)
                            getAllTaskList()
                        } else {
                            showAlert(response?.payload?.data?.data, "error");
                        }

                    }).catch((error: any) => {
                        console.error(error);
                    });
            } else {
                dispatch(ContactsActions.createTask(payload))
                    .then((response: any) => {
                        if (response?.payload?.data?.status) {
                            showAlert("Create Task Successfully", "success");
                            setIsCreateTaskOpen(false)
                            getAllTaskList()
                        } else {
                            showAlert(response?.payload?.data?.data, "error");
                        }

                    }).catch((error: any) => {
                        console.error(error);
                    });
            }
        } else {
            showAlert("Please Select Related To", "error");
        }
    }
    const getSigleTask = (task_id: any) => {
        if (task_id) {
            setIsCreateTaskOpen(true)
            setIsUpdate(true)
            setTaskId(task_id)
            const subUrl = contactId ? `/contacts/view/${contactId}/activities/tasks/` + task_id : `/task/lists/` + task_id;
            navigate(subUrl);
        }
    }
    const getSingleTaskForComplete = (task_id: any) => {
        setIsCompleteTaskOpen(true)
        if (task_id) {
            dispatch(ContactsActions.getSingleTask(task_id))
                .then((response: any) => {
                    if (response?.payload?.data) {
                        let data: any = {
                            create_task_title: response?.payload?.data?.data?.task_title,
                            create_task_description: response?.payload?.data?.data?.task_description,
                            create_task_type: response?.payload?.data?.data?.task_type,
                            create_task_date: response?.payload?.data?.data?.task_date,
                            create_task_time: new Date(response?.payload?.data?.data?.task_date),
                            create_task_priority: response?.payload?.data?.data?.task_priority,
                            create_task_owner: response?.payload?.data?.data?.task_owner?.id,
                            create_task_related_to_Details: {
                                "full_name": response?.payload?.data?.data?.related_to?.first_name && response?.payload?.data?.data?.related_to?.last_name ? response?.payload?.data?.data?.related_to?.first_name + " " + response?.payload?.data?.data?.related_to?.last_name : response?.payload?.data?.data?.related_to?.first_name,
                                "id": response?.payload?.data?.data?.related_to?.id
                            },
                            mark_as_completed: true,
                            task_completed_date: response?.payload?.data?.data?.completed_date,
                            task_completed_time: new Date(response?.payload?.data?.data?.completed_date),
                            create_task_outcome: response?.payload?.data?.data?.task_outcome
                        }
                        setMarkAsCompleted(response?.payload?.data?.data?.completed_date)
                        data['selectRelatedToValue'] = {
                            "full_name": response?.payload?.data?.data?.related_to?.first_name && response?.payload?.data?.data?.related_to?.last_name ? response?.payload?.data?.data?.related_to?.first_name + " " + response?.payload?.data?.data?.related_to?.last_name : response?.payload?.data?.data?.related_to?.first_name,
                            "id": response?.payload?.data?.data?.related_to?.id
                        }
                        data['task_completed_date'] = new Date()
                        data['task_completed_time'] = new Date()
                        let payload: any = getCreateTaskBody(data)
                        payload['task_id'] = task_id
                        setTaskData(payload)
                    }
                }).catch((error: any) => {
                    console.error(error);
                });
        }
    }
    const setOutcome = (outcome: any) => {
        taskData['task_outcome'] = outcome
        setTaskData(taskData)
    }
    const completeTask = () => {
        props.setLoading(true)
        dispatch(ContactsActions.updateTask(taskData))
            .then((response: any) => {
                if (response?.payload?.data?.status) {
                    showAlert("Task Completed Successfully", "success");
                    setIsCompleteTaskOpen(false)
                    getAllTaskList()
                    props.setLoading(false)
                } else {
                    props.setLoading(false)
                    showAlert(response?.payload?.data?.data, "error");
                }

            }).catch((error: any) => {
                props.setLoading(false)
                console.error(error);
            });
    }
    const setTaskIds = (id: string) => {
        if (selectTaskIdList.includes(id)) {
            setSelectTaskIdList(selectTaskIdList.filter((res: any) => res !== id))
        } else {
            setSelectTaskIdList([...selectTaskIdList, id])
        }
    };
    const deleteTasks = () => {
        let result = selectTaskIdList.join(","); // Converts array into a comma-separated string
        setIsDeleteModel(false)
        props.setLoading(true)
        dispatch(ContactsActions.deleteTaskByIds(result))
            .then((response: any) => {
                if (response?.payload?.data?.status) {
                    showAlert("Task Deleted Successfully", "success");
                    getAllTaskList()
                    setSelectTaskIdList([])
                    props.setLoading(false)
                } else {
                    setIsDeleteModel(false)
                    props.setLoading(false)
                    showAlert(response?.payload?.data?.data, "error");
                }

            }).catch((error: any) => {
                props.setLoading(false)
                console.error(error);
            });
    }
    const closeCompleteTask = () => {
        setIsCompleteTaskOpen(false)
        setTaskData({})
    }
    const getTomorrowDate = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1); // Increment by 1 to get tomorrow's date
        return tomorrow;
    };
    const createTask = () => {
        setRelatedTo([])
        setStatusRelatedTo(false)
        setMarkAsCompleted(false)
        if (basicInformation?.general_information && contactId) {
            setSelectRelatedToValue({
                "full_name": basicInformation?.general_information?.first_name && basicInformation?.general_information?.last_name ? basicInformation?.general_information?.first_name + " " + basicInformation?.general_information?.last_name : basicInformation?.general_information?.first_name,
                "id": contactId
            })
        } else {
            setSelectRelatedToValue({})
        }
        if (userData) {
            setLocalValues({
                ...localValues,
                create_task_title: "",
                create_task_description: "",
                create_task_type: "",
                create_task_date: getTomorrowDate(),
                create_task_time: "",
                create_task_priority: "",
                create_task_related_to_Details: {
                },
                mark_as_completed: false,
                task_completed_date: "",
                task_completed_time: "",
                create_task_outcome: "",
                create_task_owner: userData?.id
            })
        }
        setIsUpdate(false)
        setTaskId("")
        setIsCreateTaskOpen(true)
    }
    const closeTaskPopover = () => {
        setIsCreateTaskOpen(false)
        setTaskId("")
        // const subUrl = contactId ? `/contacts/view/${contactId}/activities/tasks` : `/task/lists`;
        // navigate(subUrl);
    }
    return (
        <ContactWrapper>
            <div>
                {/* <LeadPageSideBar />  */}
                <div className="userprofile-main-div">
                    <LeadPageWrapper>
                        <div className="contact-notes">
                            <TaskWrapper>
                                <div className="notes" >
                                    <div style={{ display: "flex" }}>
                                        {selectTaskIdList?.length === 0 ? <> <div className="setting-page__menu">
                                            <p>Type:</p>
                                            <NewNativeSelect>
                                                <Select
                                                    defaultValue={""}
                                                    name="task_type"
                                                    value={localValues?.task_type}
                                                    onChange={(e: any) => handleSelectChange(e, "task_type")}
                                                    displayEmpty
                                                    className="new-select-main"
                                                    style={{ minWidth: "115px", maxWidth: "0px", width: "0px" }}
                                                >
                                                    <MenuItem value="">All</MenuItem>
                                                    {TaskType?.map((item: any, index: any) => (
                                                        <MenuItem value={item?.id} key={1}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <div
                                                    className="clear-icon"
                                                    onClick={(e: any) => clearCallback(e, "task_type")}
                                                >
                                                    {localValues?.task_type ? <GridCloseIcon fontSize="small" /> : ""}
                                                </div>
                                            </NewNativeSelect>
                                        </div>
                                            <div className="setting-page__menu">
                                                <p>Priority:</p>
                                                <NewNativeSelect>
                                                    <Select
                                                        defaultValue={""}
                                                        name="task_priority"
                                                        value={localValues?.task_priority}
                                                        onChange={(e: any) => handleSelectChange(e, "task_priority")}
                                                        displayEmpty
                                                        className="new-select-main"
                                                        style={{ minWidth: "115px", maxWidth: "0px", width: "0px" }}
                                                    >
                                                        <MenuItem value=""> <FiberManualRecordIcon style={{ color: "lightgray" }} className="priority" /> All</MenuItem>
                                                        {TaskPriority?.map((item: any, index: any) => (
                                                            <MenuItem value={item?.id} key={1}>
                                                                <FiberManualRecordIcon style={{ color: item?.color }} className="priority" />  {item?.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <div
                                                        className="clear-icon"
                                                        onClick={(e: any) => clearCallback(e, "task_priority")}
                                                    >
                                                        {localValues?.task_priority ? <GridCloseIcon fontSize="small" /> : ""}
                                                    </div>
                                                </NewNativeSelect>
                                            </div>
                                            <div className="setting-page__menu">
                                                <p>Status:</p>
                                                <NewNativeSelect>
                                                    <Select
                                                        defaultValue={""}
                                                        name="task_status"
                                                        value={localValues?.task_status}
                                                        onChange={(e: any) => handleSelectChange(e, "task_status")}
                                                        displayEmpty
                                                        className="new-select-main"
                                                        style={{ minWidth: "115px", maxWidth: "0px", width: "0px" }}
                                                    >
                                                        <MenuItem value=""> All</MenuItem>
                                                        {TaskStatus?.map((item: any, index: any) => (
                                                            <MenuItem value={item?.name} key={1}>
                                                                {item?.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <div
                                                        className="clear-icon"
                                                        onClick={(e: any) => clearCallback(e, "task_status")}
                                                    >
                                                        {localValues?.task_status ? <GridCloseIcon fontSize="small" /> : ""}
                                                    </div>
                                                </NewNativeSelect>
                                            </div>
                                            {permission === "global_access" ? <div className="setting-page__menu">
                                                <p>Task Owner:</p>
                                                <NewNativeSelect>
                                                    <Select
                                                        defaultValue={""}
                                                        name="task_ower"
                                                        value={localValues?.task_ower}
                                                        onChange={(e: any) => {
                                                            handleSelectChange(e, "task_ower");
                                                            refocusInput(); // Refocus the input when a value is selected
                                                        }}
                                                        displayEmpty
                                                        className="new-select-main"
                                                        style={{ minWidth: "135px", maxWidth: "0px", width: "0px" }}
                                                        onBlur={() => refocusInput()} // Ensure input stays focused on blur
                                                    >
                                                        <ListSubheader>
                                                            <TextField
                                                                size="small"
                                                                id="searchText"
                                                                // Autofocus on textfield
                                                                autoFocus
                                                                placeholder="Type to search..."
                                                                fullWidth
                                                                className="searchbox"
                                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                                onKeyDown={(e) => {
                                                                    if (e.key !== "Escape") {
                                                                        // Prevents autoselecting item while typing (default Select behaviour)
                                                                        e.stopPropagation();
                                                                    }
                                                                }}
                                                            />
                                                        </ListSubheader>

                                                        <MenuItem value="" style={{ fontSize: "13px" }}> All</MenuItem>

                                                        {/* Filter the agentList based on the search term */}
                                                        {agentList
                                                            ?.filter((item: any) =>
                                                                item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) // Search by name
                                                            )
                                                            .map((item: any, index: any) => (
                                                                <MenuItem value={item?.id} key={item?.id} style={{ fontSize: "13px" }}>
                                                                    {item?.name}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>

                                                    <div
                                                        className="clear-icon"
                                                        onClick={(e: any) => clearCallback(e, "task_ower")}
                                                    >
                                                        {localValues?.task_ower ? <GridCloseIcon fontSize="small" /> : ""}
                                                    </div>
                                                </NewNativeSelect>
                                            </div> : null} </> : <div style={{ padding: "14px 12px 11px" }}> <AppButton onClick={() => setIsDeleteModel(true)} variant="danger">
                                                Delete
                                            </AppButton> </div>}
                                    </div>
                                    <div style={{ display: "flex", marginTop:"25px" }}>
                                        <div className="date-filter" onClick={() => setDateFilter("overdue")} style={{ width: "80px", border: "1px solid red", color: dateFilter === "overdue" ? theme.palette.default.white: "", fontWeight: 400, marginRight: "0px", backgroundColor: dateFilter === "overdue" ? theme.palette.default.valencia : "", borderRadius: "3px" }}>
                                            Overdue
                                        </div>
                                        <div className="date-filter" onClick={() => setDateFilter("all")} style={{ width: "60px", backgroundColor: dateFilter === "all" ? theme.palette.default.paleBlue : "", color: dateFilter === "all" ? theme.palette.default.white : "" }}>
                                            All
                                        </div>
                                        <div className="date-filter" onClick={() => setDateFilter("today")} style={{ backgroundColor: dateFilter === "today" ? theme.palette.default.paleBlue : "", color: dateFilter === "today" ? theme.palette.default.white : "" }}>
                                            Today
                                        </div>
                                        <div className="date-filter" onClick={() => setDateFilter("tomorrow")} style={{ width: "87px", backgroundColor: dateFilter === "tomorrow" ? theme.palette.default.paleBlue : "", color: dateFilter === "tomorrow" ? theme.palette.default.white : "" }}>
                                            Tomorrow
                                        </div>
                                        <div className="date-filter" onClick={() => setDateFilter("this_week")} style={{ backgroundColor: dateFilter === "this_week" ? theme.palette.default.paleBlue : "", color: dateFilter === "this_week" ? theme.palette.default.white : "" }}>
                                            This Week
                                        </div>
                                        <AppRangeFutureDatePicker
                                            dateFieldStyle={{ borderRadius: "inherit", backgroundColor: dateFilter === "custom" ? theme.palette.default.paleBlue : "" }}
                                            dateRange={{
                                                startDate: localValues?.startDate,
                                                endDate: localValues?.endDate,
                                            }}
                                            reset={true}
                                            setDateRange={handleChangeDate}
                                            divStyle={{ borderRadius: "initial", width: "62px" }}
                                            customIcon={<> <CalendarMonthIcon style={{ color: dateFilter === "custom" ? theme.palette.default.white : "" }} /> </>}
                                            isAllowCustomDate={true}
                                        />
                                        <ProtectedAppButton
                                            moduleId="task_crud"
                                            specificPermissionKey="create"
                                            style={{ height: "35px", marginTop: "6px", width: "110px" }}
                                            onClick={() => createTask()}
                                        >
                                            <AppMaterialIcons iconName="add" />
                                            Create Task
                                        </ProtectedAppButton>
                                        {/* <AppButton onClick={() => createTask()} variant=" " buttonStyle={{ height: "35px", marginTop: "6px" }}>
                                            <AppMaterialIcons iconName="add" />
                                            Create Task
                                        </AppButton> */}
                                    </div>
                                </div>
                            </TaskWrapper>
                        </div>
                        {allTaskList?.length !== 0 ? <TableContainer style={{ height: "calc(100vh - 224px)" }} component={Paper}>
                            <Table
                                sx={{ minWidth: 500 }}
                                size="small"
                                aria-label="a dense table"
                            >
                                <TableHead>
                                    <TableRow key={uniqueId()}>
                                        {/* <TableCell align="left"> <Checkbox checked={selectAll ? true : false} onClick={() => setSelectAll(!selectAll)}/> </TableCell> */}
                                        <TableCell align="left">  </TableCell>

                                        <TableCell align="left">Task Title</TableCell>
                                        <TableCell align="left" style={{ width: "20%" }}>Description</TableCell>
                                        <TableCell align="left">Priority</TableCell>
                                        <TableCell align="left">Task Type</TableCell>
                                        <TableCell align="left">Start Date & Time</TableCell>
                                        <TableCell align="left">Contact</TableCell>
                                        <TableCell align="left">Contact Ower</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        allTaskList?.map((task: any) => {
                                            const date = moment(task.task_date).format(
                                                " DD MMM , YYYY hh:mm A "
                                            );
                                            return (
                                                <TableRow key={task?.id}>
                                                    <TableCell align="left" style={{ display: "flex" }}> {task?.can_delete ? <Checkbox checked={selectAll ? true : selectTaskIdList.includes(task?.id) ? true : false} onClick={() => setTaskIds(task.id)} /> : <Checkbox disabled checked={false} />} {task?.can_update ? <p style={{ cursor: task?.mark_as_completed ? "default" : "pointer" }} onClick={() => !task?.mark_as_completed && getSingleTaskForComplete(task?.id)}> <CheckCircleIcon style={{ color: task?.mark_as_completed ? "green" : "lightgray", margin: "7px -40px 0px 18px" }} /> </p> : <CheckCircleIcon style={{ color: "lightgray", margin: "7px -40px 0px 18px" }} />}  </TableCell>
                                                    <TableCell align="left">{task?.can_update ? <p onClick={() => getSigleTask(task?.id)} style={{ color: theme.palette.default.darkBlue, cursor: "pointer" }}>{task.task_title}</p> : task.task_title}</TableCell>
                                                    <TableCell align="left" title={task.task_description}
                                                        style={{
                                                            textDecoration: "none",
                                                            maxWidth: "160px",
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}>{task.task_description}</TableCell>
                                                    <TableCell align="left" style={{ color: task.task_priority?.color }}>{task.task_priority?.label}</TableCell>
                                                    <TableCell align="left">{task.task_type?.label}</TableCell>
                                                    <TableCell align="left">{date}</TableCell>
                                                    <TableCell align="left">
                                                        <a
                                                            style={{ color: theme.palette.default.darkBlue, cursor: "pointer" }}
                                                            onClick={() => window.open(`/contacts/view/${task.related_to?.id}/basic-information`, '_blank')}
                                                        >
                                                            {task?.related_to?.first_name || task?.related_to?.last_name
                                                                ? `${task?.related_to?.first_name || ""} ${task?.related_to?.last_name || ""}`.trim()
                                                                : ""}
                                                        </a>
                                                    </TableCell>                                                    
                                                    <TableCell align="left">{task.task_owner?.first_name + " " + task.task_owner?.last_name}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table>
                            <div style={{ position: contactId ? "fixed" : "absolute", bottom: "9px", width: "95%", backgroundColor: theme.palette.default.white }}>
                                {allTaskList?.length ? (
                                    <AppPaginationComponent
                                        totalCount={pagination?.total}
                                        handlePageChange={handlePageChange}
                                        currentPage={localValues?.currentPage}
                                        totalPages={pagination?.totalPages}
                                        rowsPerPage={localValues?.itemsPerPage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                ) : null}
                            </div>
                        </TableContainer> :
                            <div style={{ padding: "50px 0", textAlign: "center" }}>
                                <img src={Nodata} />
                                <div style={{ marginTop: "15px", fontSize: "14px", fontWeight: 500 }}>
                                    <a>No Task Found</a>
                                </div>
                                <div style={{ marginTop: "15px", fontSize: "14px", display: "flex", justifyContent: "space-around", fontWeight: 500 }}>
                                    <ProtectedAppButton
                                        moduleId="task_crud"
                                        specificPermissionKey="create"
                                        style={{ height: "35px", marginTop: "6px", width: "110px" }}
                                        onClick={() => createTask()}
                                    >
                                        <AppMaterialIcons iconName="add" />
                                        Create Task
                                    </ProtectedAppButton>
                                </div>
                            </div>}

                    </LeadPageWrapper>
                </div>
            </div>
            <AppModel
                isShowing={false}
                onClose={() => closeTaskPopover()}
                divStyle={{
                    width: "1079px",
                    height: "600px",
                    gap: "0.5rem",
                    overflowY: "hidden",
                    padding: "1rem 1rem",
                }}
            >
                <CreateTaskWapper>
                    <div className="mark-complete">
                        <div className="popup-header">
                            <div className="mark-complete-text">Create new task</div>
                        </div>
                        <div className="popup-heading-icon">
                            <IconButton onClick={() => closeTaskPopover()}>
                                <TagCrossIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className="create-task-body">
                        <AppForm initialValues={localValues} validationSchema={CreateTaskSchema} onSubmit={handleSubmit}>
                            <div className="body">
                                <div className="left">
                                    <div className="title input">
                                        <AppFormField
                                            label="Task Title"
                                            name="create_task_title"
                                            className="title input"
                                            requiredSign={true}
                                            placeholder="Enter the task title "
                                        />
                                    </div>
                                    <div className="title input">
                                        <AppFormTextArea
                                            label="Description"
                                            name="create_task_description"
                                            rows="4"
                                            placeholder="Enter details about the task..."
                                            divStyle={{ height: "150px" }}
                                        />
                                    </div>
                                    <div style={{ display: "flex", gap: "15px" }}>
                                        <div className="title input">
                                            <AppFormSelectField
                                                label="Task type"
                                                name="create_task_type"
                                                defaultText="Select an Task Type"
                                                placeholder="Select an Task Type"
                                                Options={TaskType}
                                                requiredSign={true}
                                                displayLabel={"label"}
                                                valueKey="name"
                                            />
                                        </div>
                                        <AppFormDatePicker requiredSign={true} name={"create_task_date"} label="Date" />
                                        <AppFormTimePickerMui
                                            name={"create_task_time"}
                                            label="Time"
                                            utcTime={true}
                                            requiredSign={true}
                                            divStyle={{ marginRight: "21px" }}
                                        />
                                    </div>
                                    <div className="title input" style={{ display: "flex" }}>
                                        <AppFormSelectField
                                            label="Priority"
                                            name="create_task_priority"
                                            defaultText="Select an priority"
                                            placeholder="Select an priority"
                                            Options={TaskPriority}
                                            requiredSign={true}
                                            displayLabel={"label"}
                                            divStyle={{ width: "34%" }}
                                        />
                                        {markAsCompleted ? <div className="title input">
                                            <AppFormSelectField
                                                label="Outcome"
                                                name="create_task_outcome"
                                                defaultText="Select an outcome"
                                                placeholder="Select an outcome"
                                                Options={TaskOutcome}
                                                displayLabel={"label"}
                                                enableSearch={true}
                                                requiredSign={true}
                                                divStyle={{
                                                    width: "170%",
                                                    marginLeft: "13px"
                                                }}
                                            />
                                        </div> : null}
                                    </div>

                                </div>
                                <div className="right">
                                    <div className="title input" style={{ marginLeft: "15px" }}>
                                        <AppFormSelectField
                                            label="Owner"
                                            name="create_task_owner"
                                            defaultText="Select an owner"
                                            placeholder="Select an owner"
                                            Options={agentListForAllTask}
                                            requiredSign={true}
                                            displayLabel={"name"}
                                            enableSearch={true}
                                        />
                                    </div>
                                    <div className="title input" style={{ marginLeft: "15px" }}>
                                        <div className="bodyTitle" style={{ color: theme.palette.default.dustyGray }}>
                                            Related To <sup className="starSuperScript"> *</sup>
                                        </div>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            open={open} // Control the open state of the dropdown
                                            onOpen={() => setOpen(true)} // Open the dropdown while interacting
                                            onClose={() => setOpen(false)} // Close dropdown on user action
                                            options={relatedTo} // Options array
                                            value={""}
                                            defaultValue={""}
                                            getOptionLabel={(option: any) => option.full_name || ""} // Display full_name
                                            inputValue={inputValue} // Keep input controlled
                                            noOptionsText={statusRelatedTo ? "Loading...." : "Type to search"} // Custom message when no options
                                            onInputChange={(event: any, newInputValue: string) => {
                                                clearTimeout(timer); // Clear the previous debounce
                                                setInputValue(newInputValue); // Set input value from the input change
                                                if (newInputValue && newInputValue !== selectRelatedToValue?.full_name) {
                                                    handleSelectSearchForAutoComplete(newInputValue); // Trigger search API call
                                                } else if (!newInputValue) {
                                                    handleClear(); // Clear options when input is cleared
                                                }
                                            }}
                                            style={{
                                                border: "1px solid lightgray",
                                                borderRadius: "5px",
                                                backgroundColor: theme.palette.default.paleWhite,
                                                height: "42px",
                                            }}
                                            onChange={(event: any, newValue: any) => {
                                                if (newValue === null) {
                                                    handleClear(); // Handle the clearing of selected value
                                                } else {
                                                    setInputValue(""); // Clear the input value after selecting
                                                    setSelectRelatedToValue(newValue); // Set the selected value
                                                }
                                                setRelatedTo([]); // Reset options after selection or clearing
                                                setOpen(false); // Close the dropdown
                                            }}
                                            renderInput={(params: any) => (
                                                <TextField
                                                    onClick={() => setStatusRelatedTo(false)}
                                                    {...params}
                                                    placeholder="Select contact from list"
                                                    sx={{
                                                        width: "340px", // Custom input width
                                                        position: "relative",
                                                        height: "40px",
                                                        zIndex: 1,
                                                        "& .MuiInputBase-input": { height: "7px" },
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "lightgray",
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "gray", // Hover effect
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "blue", // Focus effect
                                                            },
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div>
                                        {selectRelatedToValue?.full_name ? (
                                            <div className="contact_info">
                                                {selectRelatedToValue?.full_name}
                                                <div>
                                                    <IconButton style={{ paddingTop: "5px" }} onClick={() => handleClear()}>
                                                        <TagCrossIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="title input" style={{ marginLeft: "15px", marginTop: "12px" }}>
                                        {/* <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={localValues?.mark_as_completed}
                                                />
                                            }
                                            label="Mark as completed"
                                            name="mark_as_completed"
                                        /> */}
                                        <AppFormCheckboxField
                                            name={`mark_as_completed`}
                                            label={"Mark as completed"}
                                            checked={localValues.mark_as_completed}
                                            onChange={(event: any) => setMarkAsCompleted(event?.target?.checked)}
                                        />
                                    </div>
                                    {markAsCompleted ? <div className="mark_as_completed">
                                        Once marked as completed, you will not be able to open this again.
                                    </div> : null}
                                    {markAsCompleted ? <div className="title input" style={{ marginLeft: "15px", marginTop: "12px" }}>
                                        <AppFormDatePicker requiredSign={true} name={"task_completed_date"} label="Date" />
                                        <AppFormTimePickerMui
                                            requiredSign={true}
                                            name={"task_completed_time"}
                                            label="Time"
                                            utcTime={true}
                                            divStyle={{ marginRight: "21px" }}
                                        />
                                    </div> : null}
                                </div>
                            </div>
                            <div className="mark-complete-buttons">
                                <AppButton onClick={() => closeTaskPopover()} variant="grey">
                                    cancel
                                </AppButton>
                                <AppSubmitButton title={"Save"} >save</AppSubmitButton>
                            </div>
                        </AppForm>
                    </div>

                </CreateTaskWapper>
            </AppModel>
            <AppModel
                isShowing={isCompleteTaskOpen}
                onClose={() => closeCompleteTask()}
                divStyle={{
                    width: "668px",
                    height: "200px",
                    gap: "0.5rem",
                    overflowY: "hidden",
                    padding: "0.7rem",
                }}
            >
                <CreateTaskWapper>
                    <div className="mark-complete">
                        <div className="popup-header">
                            <div className="mark-complete-text">You marked the task complete.</div>
                        </div>
                        <div className="popup-heading-icon">
                            <IconButton onClick={() => closeCompleteTask()}>
                                <TagCrossIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className="create-task-body" style={{ padding: "0px 16px" }}>
                        <AppForm initialValues={{}} onSubmit={() => { }}>

                            <AppFormSelectField
                                label="Outcome"
                                name="create_task_outcome"
                                defaultText="Select an outcome"
                                placeholder="Select an outcome"
                                Options={TaskOutcome}
                                displayLabel={"label"}
                                enableSearch={true}
                                requiredSign={true}
                                onChange={setOutcome}
                                divStyle={{
                                    width: "100%",
                                }}
                            />
                            {/* <AppFormTextArea
                                label="Description"
                                name="create_task_description"
                                rows="4"
                                onChange={setDescription}
                                placeholder="Enter details about the task..."
                                divStyle={{ height: "150px" }}
                                sendValuesToParent={taskData?.task_description}
                            /> */}
                        </AppForm>
                    </div>
                    <div className="mark-complete-footer">
                        <AppButton onClick={() => closeCompleteTask()} variant="grey">
                            cancel
                        </AppButton>
                        <AppButton onClick={() => completeTask()} variant="grey">
                            save
                        </AppButton>
                    </div>
                </CreateTaskWapper>

            </AppModel>
            <AppModel
                isShowing={isDeleteModel}
                onClose={setIsDeleteModel}
                divStyle={{
                    padding: 0,
                }}
                PaperProps={{
                    style: {
                        boxShadow: "none",
                        width: "500px",
                    },
                }}
            >
                <DeleteCannedResponse>
                    <div className="task-header">
                        <div className="header-left">
                            <div className="danger">
                                <img loading="lazy" src={Danger} alt="" />
                            </div>
                            <div className="text">
                                <h4>Delete Task</h4>
                                <p>Are you sure, you want to delete?</p>
                            </div>
                        </div>
                        <div className="header-right">
                            <span onClick={() => setIsDeleteModel(false)}>
                                <AppMaterialIcons iconName="close" />
                            </span>
                        </div>
                    </div>
                    <div className="footer">
                        <AppButton variant="grey" onClick={() => setIsDeleteModel(false)}>
                            Cancel
                        </AppButton>
                        <AppButton
                            variant="danger-filled"
                            onClick={() => deleteTasks()}
                        >
                            <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                            Delete
                        </AppButton>
                    </div>
                </DeleteCannedResponse>
            </AppModel>
            {isCreateTaskOpen ? <Task taskId={taskId} onClose={() => closeTaskPopover()} open={isCreateTaskOpen} getAllTaskList={getAllTaskList} setDateFilter={setDateFilter} /> : null}
        </ContactWrapper>
    )
}
export const DeleteCannedResponse = styled.div`
  .task-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.875rem;
    border-bottom: 1px solid ${theme.palette.default.semiGray};
    .header-left {
      display: flex;
      gap: 0.875rem;
      .danger {
        padding-top: 4px;
        img {
          width: 1.2rem;
        }
      }
      .text h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .text p {
        color: ${theme.palette.default.darkGrey};
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;

    .change-template-name {
      display: flex;
      justify-content: space-between;
      align-items: start;

      p.change-name {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;
const CreateTaskWapper = styled.div`
    width: 103%;
    /* padding: 0.5rem 1rem; */
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: ${theme.palette.default.silverGray};
    box-shadow: ${theme.palette.default.sinkGray} 0px 2px 4px;
    border-radius: 0px 0px 4px 2px;
    min-height: calc(-4rem + 100vh);
    margin: 0px -13px;
    .mark-complete {
        /* padding: 1rem; */
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid lightgray;
    }
    .mark-complete-footer {
        -webkit-box-pack: justify;
        justify-content: flex-end;
        gap: 9px;
        width: 100%;
        padding: 12px 10px 0px;
        /* margin: 2px; */
        display: flex;
        border-top: 1px solid lightgray;
    }
  .mark-complete-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: ${theme.palette.default.mineShaft};
    };
    padding-left: 16px;
  }
    .popup-header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 10px;
    }
      .popup-heading-icon svg {
        width: 12px;
        height: 12px;
        color: red;
        margin-right : 15px;
    }
    .create-task-body{
        .left{
            width : 65%;
            border-right : 1px solid lightgray;
            height: 465px;
            .title{
                margin: 0px 19px;
                .input{
                    border : 1px soild lightgray !important;
                }
            }
        }
        .right{
            width: 33%;
        }
    }
    .mark-complete-buttons {
        display: flex;
        gap: 16px;
        -webkit-box-pack: end;
        justify-content: flex-end;
        padding: 8px 9px 0px 0px;
        border-top: 1px solid lightgray;
        width: 100%;
        margin-top: -6px;
        position: sticky;
        bottom: 0;
        z-index: 1;
    }
    .date-time-picker {
        display: flex;
        gap: 8px;
        align-items: end;
        .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
            padding: 0;
        }
    }
        .body{
            display: flex;
            margin: 3px 11px;
        }
        .mark_as_completed{
            border: 1px solid lightgray;
            border-radius: 30px;
            padding: 4px 14px;
            margin: 3px 10px;
            background-color: ${theme.palette.default.sandyBeach};
            /* text-align: center; */
            font-size: 13px;
        }
        .starSuperScript{
            top: 0;
            font-size: 16px;
            color: red;
        }
        .contact_info{
            border: 1px solid ${theme.palette.default.semiViolet};
            margin: 11px 14px;
            padding: 4px 10px;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            background-color: ${theme.palette.default.paleWhite};
            border-radius: 10px;
            width: fit-content;
        }
}

`
const TaskWrapper = styled.div`
    .date-filter{
        border: 1px solid lightgray;
        height: 36px;
        margin-top: 6px;
        /* border-radius: 6px; */
        margin-right: -7px;
        padding: 7px;
        font-size: 13px;
        /* opacity: 49%; */
        color: ${theme.palette.default.cement};
        font-weight: 450;
        background-color: ${theme.palette.default.paleWhite};
        width: 82px;
        text-align:center;
    }
        .notes {
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow-x: auto;
        }
            .setting-page__menu{
                margin-right : 20px;
                p{
                    margin-bottom: 5px;
                }
                .new-select-main .priority{
                    font-size: 12px;
                }
            }
`;

const NewNativeSelect = styled.div`
  position: relative;
  width: 100%;
  &.datePicker {
    > div > div:first-child {
      padding: 0;
      height: 28px;
    }
  }
  .mb0 {
    margin-bottom: 0px !important;
  }
  .new-select-main {
    max-width: 210px;
    min-width: 180px;
    width: 210px;
    height: 35px;
    background-color: ${theme.palette.default.lighterFilterGrey};
    border: 1px solid ${theme.palette.default.semiViolet};
    border-radius: 4px;
    .MuiSelect-select {
      padding: 5px;
      font-size: 14px;
    }
    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }
  }
  .clear-icon {
    background-color: ${theme.palette.default.lightGrey};
    position: absolute;
    top: 1px;
    right: 10px;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default Tasks;