import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import { AppForm, AppFormObserver, AppSubmitButton } from "../../../AppComponents/Forms";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  automationBuilderPayload,
  automationBuilderState
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import {
  closeConfigModal,
  setNodeConfig,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import { SMSActions } from "../../../Store/Slices/SMS/sms.actions";
import AppReactSelectField from "../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { CheckSMSStatusConfigSchema } from "../validationSchema";
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import { withLoader } from "../../../AppComponents/Loader";

const sms_status_options = [
  { id: "sent", name: "SMS Sent" },
  { id: "notsent", name: "SMS Not Sent" },
  { id: "delivered", name: "SMS Delivered" },
  { id: "failed", name: "SMS FAILED" },
];
const CheckSMSStatus = ({ setLoading }: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const formRef: any = useRef();
  const automationReduxData: any = useAppSelector(automationBuilderState);
  const automationPayload: any = useAppSelector(automationBuilderPayload);
  const [templateOptions, setTemplateOptions]: any = useState([]);
  const [formikProps, setFormikProps] = useState<any>(null);
  const [initialValues, setInitialValues]: any = useState({
    sms_status: "",
    smsTemplateId: "",
  });

  const closeModel = () => {
    dispatch(closeConfigModal({}));
  };
  useEffect(() => {
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.smsTemplateId &&
      templateOptions
    ) {
      //function to filter object based on Value
      setInitialValues({
        smsTemplateId: {
          id: templateOptions.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.smsTemplateId
          )?.id,
          name: templateOptions.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.smsTemplateId
          )?.name,
        },
        sms_status: {
          id: sms_status_options.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.function
          )?.id,
          name: sms_status_options.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.function
          )?.name,
        },
      });
    }
  }, [automationPayload, templateOptions]);   // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const payload: any = {
      limit: 999,
      offset: 0,
    };
    dispatch(SMSActions.getSMSTemplateListData(payload))
      .then((res: any) => {
        let tempArray: any = [];
        res?.payload.data?.results?.map((ele: any) => {
          if (ele.status === "APPROVED") {
            tempArray.push({
              id: ele.id,
              name: ele.name,
              sender_ids: ele.sender_ids,
            });
          }
          setTemplateOptions(tempArray);
        });
      }).then(() => {
        setLoading(false)
      }).catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps
  const handleSubmit = (values: any, submitProps: any) => {
    let payload: any = {
      function: values?.sms_status.id,
      key: "function",
      nodeObject: {
        smsTemplateId: values?.smsTemplateId.id,
      },
      nodeId: automationReduxData?.selectedNodeId,
      configText: values?.sms_status.name,
    };
    submitProps.setSubmitting(false);
    dispatch(setNodeConfig(payload));
    showAlert("Node Config Saved Successfully!", "success");
    closeModel();
  };
  useEffect(() => {
    // Manually set the isValid state to false
    formRef?.current?.setFormikState((prevState: any) => ({
      ...prevState,
      isValid: false,
    }));
    formRef?.current?.setFieldValue("smsTemplateId", "", { shouldDirty: true })
  }, []);
  return (
    <ModelFormWrapper isDataAvaliable={templateOptions?.length}>
      <Typography variant="h6">Check SMS Status</Typography>
      {templateOptions?.length ? (
        <AppForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={CheckSMSStatusConfigSchema}
          innerRef={formRef}
        >
          <AppReactSelectField
            name={"smsTemplateId"}
            label="SMS DLT Template"
            options={templateOptions}
            defaultText="Select a DLT template"
            displayKey="name"
            valueKey="id"
            enableSearch={true}
            isSearchable={true}
            hideSelectedOptions={true}
            divStyle={{ background: "none", padding: "0" }}
            optionsPlaceholder="No List Found"
          />
          <AppReactSelectField
            name={"sms_status"}
            options={sms_status_options}
            label="Select List"
            displayKey="name"
            valueKey="id"
            placeholder="Select List"
            isSearchable={true}
            isClearable={true}
            hideSelectedOptions={true}
            isRequired={true}
            divStyle={{ background: "none", padding: "0" }}
            optionsPlaceholder="No List Found"
          />
          <AppFormObserver setFormikProps={setFormikProps} />
          <ModelFooter>
            <AppButton variant="outline" onClick={closeModel}>Cancel</AppButton>
            <AppSubmitButton title={"Save Config"} disabled={!formRef?.current?.isValid} />
          </ModelFooter>
        </AppForm>
      ) : (
        <React.Fragment>
          <div className="image_container">
            <NoDataFound />
          </div>
          <ModelFooter>
            <AppButton onClick={closeModel}>Close</AppButton>
          </ModelFooter>
        </React.Fragment>
      )}
    </ModelFormWrapper>
  );
};

export default withLoader(CheckSMSStatus);

const ModelFormWrapper = styled.div`
width: ${(props: any) => props.isDataAvaliable ? "400px" : "500px"};
  h6 {
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 15px;
    padding: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }
  .image_container{
    text-align:center;
  }
`;
const ModelFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${theme.palette.default.border};
  padding: 15px 0 0;
  button:last-child {
    margin-left: 15px;
  }
`;
