import { createAsyncThunk } from "@reduxjs/toolkit";
import { FileMangerNetworkService } from "./filemanagerNetworkService";

export class filemanagerAction {
    static Uploadfiles(formData: FormData): any {
        throw new Error('Method not implemented.');
    }
    static CreateFolderAction = createAsyncThunk(
        "FormsSlice/CreateFolderAction",
        (data: any, { dispatch, rejectWithValue }) => {
            return FileMangerNetworkService.createfolder(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
    static UploadfilesAction = createAsyncThunk(
        "FormsSlice/UploadfilesAction",
        (data: any, { dispatch, rejectWithValue }) => {
            return FileMangerNetworkService.uploadFiles(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
 
    
    static RenameFolderAction = createAsyncThunk(
        "FormsSlice/RenameFolderAction",
        (data: any, { dispatch, rejectWithValue }) => {
            return FileMangerNetworkService.renameboard(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
   
    static GetFolderListing = createAsyncThunk(
        "FormsSlice/GetFolderListing",
        (data: any, { dispatch, rejectWithValue }) => {
            return FileMangerNetworkService.getFolderListing(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
   
    static GetFiles = createAsyncThunk(
        "FormsSlice/ GetFiles",
        (data: any, { dispatch, rejectWithValue }) => {
            return FileMangerNetworkService. GetFiles(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
   
    static  deleteFolderaction = createAsyncThunk(
        "FormsSlice/deleteFolderaction",
        (data: any, { dispatch, rejectWithValue }) => {
            return FileMangerNetworkService. deleteFolderaction(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
    static deletefileaction = createAsyncThunk(
        "FormsSlice/deletefileaction",
        async ({ folder_id, file_id }: { folder_id: any; file_id: any }, { rejectWithValue }) => {
            try {
                const response = await FileMangerNetworkService.deletefileaction(folder_id, file_id);
                return response;
            } catch (error: any) {
                return rejectWithValue(error?.response);
            } finally {
                console.log("Cleanup or final execution logic here.");
            }
        }
    );
    
    
    
    static DownloadFile = createAsyncThunk(
        "FormsSlice/ DownloadFile",
        (data: any, { dispatch, rejectWithValue }) => {
            return FileMangerNetworkService. DownloadFile(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
    static MoveFile = createAsyncThunk(
        "FormsSlice/MoveFile",
        (data: any, { dispatch, rejectWithValue }) => {
            return FileMangerNetworkService.MoveFile(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
 
}

