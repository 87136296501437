// Card.tsx
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { memo, MouseEvent, useCallback, useMemo, useRef, useState, lazy, Suspense, useEffect } from "react";
import { useAppDispatch } from "../../../Store";
import { editId, setNotesData } from "../../../Store/Slices/Contacts/contacts.slice";
import { Box, Divider, Popover } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { createPortal } from 'react-dom';
import { theme } from "../../../Customization/Theme";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import {
  StyledCardContainer,
  StyledCardHeader,
  StyledAvatar,
  StyledTitle,
  StyledCardContent,
  StyledIconButton,
  StyledIconButtonAddNotes,
  StyledMenuItem
} from "./SwimLaneView.styles";
import { CardProps } from "./utils/swimlinetype";


// PopoverMenu Component
const PopoverMenu = lazy(() => Promise.resolve({
  default: memo(function PopoverMenu({ 
    onEdit, 
    onDelete,
    anchorEl,
    onClose,
    open
  }: { 
    onEdit: () => void;
    onDelete: () => void;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    open: boolean;
  }) {
    const handleEditClick = useCallback((e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onEdit();
      onClose();
    }, [onEdit, onClose]);

    const handleDeleteClick = useCallback((e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onDelete();
      onClose();
    }, [onDelete, onClose]);

    return createPortal(
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        keepMounted={false}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          style: {
            width: '124px'
          }
        }}
      >
        <div className="py-1" onClick={(e) => e.stopPropagation()}>
          <StyledMenuItem onMouseDown={handleEditClick}>
            <AppMaterialIcons style={{ fontSize: '13px' }} iconName="edit" />
            Edit
          </StyledMenuItem>
          <Divider />
          <StyledMenuItem onMouseDown={handleDeleteClick}>
            <AppMaterialIcons style={{ fontSize: '13px' }} iconName="delete" />
            Delete
            </StyledMenuItem>
        </div>
      </Popover>,
      document.body
    );
  })
}));

// Main Card Component
const Card = memo(function Card({ 
  contact, 
  setDeleteData, 
  toggleContactModal, 
  status, 
  statusId, 
  index, 
  stageId, 
}: CardProps) {
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const isDragging = useRef(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging: dndIsDragging
  } = useSortable({
    id: contact.id,
    transition: {
      duration: 120,
      easing: "cubic-bezier(0.25, 1, 0.5, 1)",
    },
  });

  isDragging.current = dndIsDragging;

  // Add useEffect to handle clicks outside with correct event typing
  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent) => {
      if (menuAnchorEl && !(menuAnchorEl as HTMLElement).contains(event.target as Node)) {
        setMenuAnchorEl(null);
      }
    };

    if (menuAnchorEl) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuAnchorEl]);

  const handleMenuClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (!isDragging.current) {
      event.stopPropagation();
      event.preventDefault();
      setMenuAnchorEl(event.currentTarget);
    }
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const displayName = useMemo(() => {
    if (contact.first_name && contact.last_name) return `${contact.first_name} ${contact.last_name}`;
    if (contact.first_name) return contact.first_name;
    if (contact.last_name) return contact.last_name;
    if (contact.email) return contact.email;
    if (contact.mobile_phone_number) return contact.mobile_phone_number;
    return "-";
  }, [contact.first_name, contact.last_name, contact.email, contact.mobile_phone_number]);

  const handleAddNotes = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    if (!isDragging.current) {
      dispatch(setNotesData({ status: true, contactId: contact.id }));
    }
  }, [contact.id, dispatch]);

  const handleEdit = useCallback(() => {
    
    dispatch(ContactsActions.getEditContactsFilds(contact.id))
      .then((res: any) => {
        if (res?.payload?.data?.status !== 200) {
          showAlert(res?.payload?.data?.error || "Unable to fetch data", "error");
        } else {
          dispatch(editId(contact.id));
          toggleContactModal?.();
        }
      })
      .catch((error: any) => {
        showAlert(error?.response?.statusText || "Unable to fetch data", "error");
      });
  }, [contact.id, dispatch, showAlert, toggleContactModal]);

  const handleDelete = useCallback(() => {
    setDeleteData({
      id: contact.id,
      index,
      status,
      statusId,
      stageId,
    });
  }, [contact.id, index, setDeleteData, status, statusId, stageId]);

  const style = useMemo(() => ({
    transform: dndIsDragging ? 'none' : CSS.Transform.toString(transform), // Freeze transforms while dragging
    transition,
    touchAction: 'none', // Optimize touch handling
  }), [transform, transition, dndIsDragging]);

  const avatarLetter = useMemo(() => displayName.charAt(0).toUpperCase(), [displayName]);

   // Only render popover when not dragging
   const renderPopover = !dndIsDragging && menuAnchorEl && (
    <Suspense fallback={null}>
      <PopoverMenu
        onEdit={handleEdit}
        onDelete={handleDelete}
        anchorEl={menuAnchorEl}
        onClose={handleMenuClose}
        open={Boolean(menuAnchorEl)}
      />
    </Suspense>
  );

  return (
    <Box sx={{ position: 'relative',  marginBottom: "12px", overflow: 'hidden' }}>

    <StyledCardContainer
      ref={setNodeRef}
      style={style}
      $isDragging={dndIsDragging}
      {...attributes}
      {...listeners}
      >
      <StyledCardHeader>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'grab', width: '100%' }}>
          <StyledAvatar>{avatarLetter}</StyledAvatar>
          <StyledTitle>{displayName}  </StyledTitle>
        </div>
       
      </StyledCardHeader>

      <StyledCardContent>
      <DescriptionOutlinedIcon />
        <p>
          {contact?.notes_count || 0} Note
        </p>   

      </StyledCardContent>

      {renderPopover}
    </StyledCardContainer>

    <StyledIconButton onClick={handleMenuClick}>
          <MoreVertIcon style={{ fontSize: '16px' }} />
        </StyledIconButton>
    <StyledIconButtonAddNotes 
          onClick={handleAddNotes}
          style={{ color: theme.palette.default.doveGrey }}
        >
          < AddCircleIcon  /> <span>Add Note</span>
        </StyledIconButtonAddNotes>
      </Box>
  );
});

export default Card;