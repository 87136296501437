import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import styled from 'styled-components';
import AppButton from '../../../AppComponents/AppButton';
import { AppMaterialIcons } from '../../../AppComponents/AppMaterialIcons';
import { AppForm, AppFormField, AppFormFieldArrayFastField, AppFormObserver, AppSubmitButton } from '../../../AppComponents/Forms';
import { theme } from '../../../Customization/Theme';
import { useNotification } from '../../../Hooks/useNotification/useNotification';
import { useAppDispatch, useAppSelector } from '../../../Store';
import { automationBuilderPayload, automationBuilderState } from '../../../Store/Slices/AutomationBuilder/automationBuilder.selectors';
import { closeConfigModal, setNodeConfig } from '../../../Store/Slices/AutomationBuilder/automationBuilder.slice';
import { contactListActions } from '../../../Store/Slices/ContactList/ContactList.actions';
import { allContactListData } from '../../../Store/Slices/ContactList/ContactList.selectors';
import { MarketingListNetworkService } from '../../../Store/Slices/MarketingList/MarketingListNetworkService';
import deleteIcon from '../../../assets/images/delete-icon.svg';
import AppReactSelectField from '../../../socket/appComponents/UtilsComponents/AppFormReactSelect';
import { saveGraph } from '../config';
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import { getDefaultValue, isString } from '../utils';
import { UpdateContactConfigSchema } from '../validationSchema';
import { withLoader } from '../../../AppComponents/Loader';

const ContactUpdate = ({ setLoading }: any): JSX.Element => {
    const formRef: any = useRef();
    const dispatch = useAppDispatch();
    const { showAlert } = useNotification();
    const automationReduxData: any = useAppSelector(automationBuilderState);
    const automationPayload: any = useAppSelector(automationBuilderPayload);
    const [fields, setFields] = useState<any>([])
    const allContactListOptionsData = useAppSelector(allContactListData);
    const [formikProps, setFormikProps] = useState<any>(null);
    let localValues: any = formikProps?.values || [];
    if (automationPayload?.draftedUIObject?.graphData?.nodes?.[automationReduxData?.selectedNodeId]?.nodeObjectData?.fields) {
        const field = automationPayload?.draftedUIObject?.graphData?.nodes?.[automationReduxData?.selectedNodeId]?.nodeObjectData;
        const result = field?.localValues?.fields
        localValues = { "fields": result }
    }

    const handleSubmit = async (values: any, submitProps: any) => {
        const fieldsArray = values?.fields?.map((item: any) => {
            return {
                field: item?.field?.name,
                value: isString(item?.value) ? item?.value : item?.value?.value,
                type: item?.field?.type,
                label: item?.value?.label
            }
        })
        const newObj: any = {};
        fieldsArray.forEach((item: any) => {
            newObj[item.field] = { template: item.value, label: item?.label, uiObject: {} }
        });
        const fields: any = [];
        for (const key in newObj) {
            fields.push({ [key]: newObj[key] });
        }
        let payload: any = {
            nodeObject: {
                fields: fields,
                localValues: formikProps?.values
            },
            nodeId: automationReduxData?.selectedNodeId,
            configText: values?.fields?.map((item: any, index: any) => `Update Contacts ${item?.field?.label || item?.field?.value} set ${isString(item?.value) ? item?.value : item?.value?.label} ${index !== values?.fields?.length - 1 ? "and" : ""}`),
        }
        submitProps.setSubmitting(false);
        dispatch(setNodeConfig(payload));
        await saveGraph();
        showAlert("Node Config Saved Successfully!", "success");
        closeModel();
    }

    useEffect(() => {
        setLoading(true)
        MarketingListNetworkService.getFieldsTypeMappingList("").then((res) => {
            setFields(res?.data?.mappingFields);
        }).then(() => {
            setLoading(false)
        });
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(contactListActions.getAllVisibleContactPropertiesFieldOptions(""))
    }, [])   // eslint-disable-line react-hooks/exhaustive-deps

    const closeModel = () => {
        dispatch(closeConfigModal({}));
    }

    const onChange = (value: any) => {
        if (!value) return;
        dispatch(contactListActions.getContactPropertiesFieldOptions(value?.id));
        const newFilteredFields = fields?.filter((item: any) => item?.id !== value?.id);
        const updatedFields = [...newFilteredFields, value];
        const uniqueFields = Array.from(new Set(updatedFields.map((item: any) => item?.id)))
            ?.map(id => updatedFields.find((item: any) => item.id === id));
        setFields(uniqueFields);
    };

    const refetchOptions = (item: any) => {
        const value = getDefaultValue(fields, "name", item.field.id);
        const id = value ? value?.id : item.field.id
        dispatch(contactListActions.getContactPropertiesFieldOptions(id))
    }
    return (
        <ModelFormWrapper>
            <Typography variant="h6">
                Update Contact
            </Typography>
            {fields?.length ? (
                <AppForm
                    onSubmit={handleSubmit}
                    initialValues={localValues}
                    validationSchema={UpdateContactConfigSchema}
                    innerRef={formRef}
                >
                    <AppFormFieldArrayFastField
                        name={'fields'}
                        render={(arrayHelpers: any, values: any[]) => {
                            return (
                                <React.Fragment>
                                    <ModalHeader>
                                        <Typography className='add-stage' variant="body2">
                                            Sub Stages
                                        </Typography>
                                        <Typography className='add-stage' variant="body2">
                                            Operation
                                        </Typography>
                                        <Typography className='add-stage' variant="body2">
                                            Value
                                        </Typography>
                                        <Typography className='add-stage' variant="body2">
                                            Action
                                        </Typography>
                                    </ModalHeader>
                                    {values?.map((item: any, index: number) => {
                                        return (
                                            <FieldsContainer>
                                                <AppReactSelectField
                                                    name={`fields.${index}.field`}
                                                    options={fields}
                                                    label=""
                                                    displayKey="label"
                                                    valueKey="id"
                                                    placeholder="Select Field"
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    hideSelectedOptions={true}
                                                    onChangeCallbackFn={onChange}
                                                    divStyle={{ background: "none", padding: "0", width: "400px" }}
                                                />
                                                <p>Set</p>
                                                {["dropdown", "radio"].includes(item?.field?.type) ?
                                                    (
                                                        <AppReactSelectField
                                                            name={`fields.${index}.value`}
                                                            options={allContactListOptionsData?.options}
                                                            displayKey="label"
                                                            valueKey="id"
                                                            placeholder="Select Field"
                                                            label=""
                                                            isClearable={true}
                                                            hideSelectedOptions={true}
                                                            divStyle={{ background: "none", padding: "0", width: "450px" }}
                                                            onMenuOpen={() => refetchOptions(item)}
                                                        />
                                                    ) : (
                                                        <AppFormField
                                                            name={`fields.${index}.value`}
                                                            placeholder="Enter Value"
                                                            label=""
                                                            divStyle={{ width: "450px" }}
                                                        />
                                                    )}
                                                <IconButton component="span" onClick={() => arrayHelpers?.remove(index)}>
                                                    <img src={deleteIcon} alt='' />
                                                </IconButton>
                                            </FieldsContainer>

                                        )
                                    })}
                                    <AppFormObserver setFormikProps={setFormikProps} />
                                    <AppButton variant='outline'
                                        onClick={() => arrayHelpers.push()}
                                        style={{
                                            width: "120px",
                                            padding: "10px 0"
                                        }}
                                    >
                                        <AppMaterialIcons iconName='AddOutlined' />
                                        Add Field
                                    </AppButton>
                                </React.Fragment>
                            )
                        }}
                    />
                    <ModelFooter>
                        <AppButton variant="outline" onClick={closeModel}>Cancel</AppButton>
                        <AppSubmitButton title={"Save Config"} disabled={!formRef?.current?.isValid} />
                    </ModelFooter>
                </AppForm>
            ) : (
                <React.Fragment>
                    <div className="image_container">
                        <NoDataFound />
                    </div>
                    <ModelFooter>
                        <AppButton onClick={closeModel}>Close</AppButton>
                    </ModelFooter>
                </React.Fragment>
            )}
        </ModelFormWrapper>
    )
}

export default withLoader(ContactUpdate);

const ModelFormWrapper = styled.div`
&&{
    width: 650px;
    h6 {
        border-bottom: 1px solid ${theme.palette.default.border};
        margin-bottom: 15px;
        padding: 0 0 0 0;
        font-size: 16px;
        font-weight: 500;
    }
    .image_container{
        text-align:center;
    }
}`
const ModelFooter = styled.div`
&&{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid ${theme.palette.default.border};
    padding: 15px 0 0;
    button: last-child {
        margin-left: 15px;
    }
}`

const ModalHeader = styled.div`
&&{
    display: flex;
    gap: 10px;
    flex: 1;
    height: 50px;
.add-stage{
    :nth-child(1){
        width: 380px;
    }
    :nth-child(2){
        width: 150px;
    }
    : nth-child(3){
        width: 400px;
    }
    :nth-child(4){
        width: 90px;
    }
    }
}`

const FieldsContainer = styled.div`
&&{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 25px;
    p{
        width: 150px;
    }
}`
