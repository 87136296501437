import React, { useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import "./progress-bar.scss";
import { ReactComponent as ChatbotImg } from "../../assets/images/CloseIcon.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/images/successIcon.svg";
import { useNavigate } from "react-router-dom";

const { useSnackbar, VariantType } = require("notistack");

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const action = useCallback(
    (key: string) => (
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => closeSnackbar(key)}
      >
        <CloseIcon fontSize="small" style={{ fontSize: 14 }} />
      </IconButton>
    ),
    [closeSnackbar]
  );

  const handleRedirection = useCallback(
    (payload: any, key: string) => {
      clearAlert(key);
      if (payload?.url) {
        navigate(payload?.url);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const renderIcon = useCallback((data: any) => {
    return data?.icon ? (
      data?.icon
    ) : (
      <CheckCircleIcon
        style={{
          fontSize: 25,
          color: "green",
          margin: "-1px 6px 5px -3px",
        }}
      />
    );
  }, []);

  const interactiveMessage = useCallback(
    (data: any, variant: typeof VariantType, key: string) => (
      <div style={{ zIndex: 9999999 }}>
        <div
          className={variant === "success" ? "success-bar" : "error-bar"}
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          {renderIcon(data)}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "4px",
              cursor: "pointer",
            }}
            onClick={() => handleRedirection(data, key)}
          >
            <p style={{ fontSize: "14px", fontWeight: 500, width: "170px" }}>
              {data?.title?.length > 20
                ? `${data?.title?.substring(0, 20)}...`
                : data?.title}
            </p>
            <p style={{ fontSize: "14px", fontWeight: 400, width: "190px" }}>
              {data?.body?.length > 40
                ? `${data?.body?.substring(0, 40)}...`
                : data?.body}
            </p>
          </div>
        </div>
        <div className="container">
          <div
            className={
              variant === "success"
                ? "progress-striped-success"
                : "progress-striped-error"
            }
          >
            <div className="progress-bar"></div>
          </div>
        </div>
        <IconButton
          aria-label="close"
          onClick={() => {
            clearAlert(key);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon style={{ fontSize: "16px", margin: "-5px 1px" }} />
        </IconButton>
      </div>
    ),
    []
  );

  const customMessage = useCallback(
    (message: any, variant: typeof VariantType, key: string) => (
      <div style={{ zIndex: 9999999 }} className="ignore-onClickOutside">
        <div className={variant === "success" ? "success-bar" : "error-bar"}>
          {variant === "success" ? (
            <CheckCircleIcon
              style={{
                fontSize: 25,
                color: "green",
                margin: "-1px 6px 5px -3px",
              }}
            />
          ) : (
            <ChatbotImg
              style={{ fontSize: 25, color: "red", margin: "2px 9px 5px -3px" }}
            />
          )}
          <p style={{ fontSize: "14px", fontWeight: 400, width: "190px" }}>
            {message}
          </p>
          <IconButton
            aria-label="close"
            onClick={() => {
              clearAlert(key);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon style={{ fontSize: "16px", margin: "-5px 1px" }} />
          </IconButton>
        </div>
        <div className="container">
          <div
            className={
              variant === "success"
                ? "progress-striped-success"
                : "progress-striped-error"
            }
          >
            <div className="progress-bar"></div>
          </div>
        </div>
      </div>
    ),
    []
  );

  const showAlert = useCallback(
    (message: string, variant: typeof VariantType) => {
      const key = new Date().getTime().toString();
      enqueueSnackbar(message, {
        key,
        variant,
        action: action(key),
        autoHideDuration: 5000,
        content: customMessage(message, variant, key),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    },
    [enqueueSnackbar] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const showInteractiveAlert = useCallback(
    (data: any, variant: typeof VariantType) => {
      const key = new Date().getTime().toString();
      enqueueSnackbar(data?.title, {
        key,
        variant,
        action: action(key),
        autoHideDuration: 5000,
        content: interactiveMessage(data, variant, key),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    },
    [enqueueSnackbar] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const clearAlert = useCallback(
    (key: string) => {
      closeSnackbar(key);
    },
    [closeSnackbar] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return { showAlert, clearAlert, showInteractiveAlert } as const;
};
