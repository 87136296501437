import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip } from "@mui/material";
import styled from "styled-components";
import { useAppSelector } from "../../../../../../../Store";
import { getCallLogsRowData } from "../../../../../../../Store/Slices/Voicebot/voicebot.selectors";
import moment from "moment";

const Transcript = (): JSX.Element => {
  const callLogsRowData: any = useAppSelector(getCallLogsRowData);
  return (
    <Container>
      <Header>
        <div>
          <p>Transcript</p>
          <Tooltip title={"Transcript"} placement="top">
            <InfoOutlined sx={{ fontSize: "16px" }} />
          </Tooltip>
        </div>
        <Box onClick={() => alert("Copied!")}>
          <ContentCopyIcon />
        </Box>
      </Header>

      {/* <DateSeparator>August 21</DateSeparator> */}

      {callLogsRowData?.conversation?.length > 1 ? (
        callLogsRowData?.conversation
          ?.slice(1)
          ?.map((msg: any, index: number) => (
            <MessageContainer
              key={index}
              isAssistant={msg?.role === "assistant"}
            >
              <Avatar isAssistant={msg?.role === "assistant"}>
                {msg?.role === "assistant" ? "A" : "U"}
              </Avatar>
              <div>
                <MessageBubble isAssistant={msg?.role === "assistant"}>
                  {msg.content}            
                </MessageBubble>
                <Timestamp isAssistant={msg?.role === "assistant"}>
                  {moment(msg.timestamp).format("h:mma")}
                </Timestamp>
              </div>
            </MessageContainer>
          ))
      ) : (
        <p>No Transcript to show</p>
      )}
      {/* <Footer>Transferring your call to the agent...</Footer> */}
    </Container>
  );
};

export default Transcript;

// Styled components
const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  font-family: Arial, sans-serif;
  height: calc(100vh - 190px);
  max-height: calc(100vh - 190px);
  overflow-y: auto;
  p {
    margin: 0;
    color: #666;
    line-height: 1.5;
    text-align: center;
  }
`;

const Header = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 16px;
  display: flex;
  background-color: #e5e5e5;
  width: 100%;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    gap: 4px;
    p {
      margin: 0;
      font-weight: 500;
    }
  }
`;

// const DateSeparator = styled.div`
//   text-align: center;
//   font-size: 0.8rem;
//   color: #888;
//   margin: 16px 0;
//   position: relative;

//   &::before,
//   &::after {
//     content: "";
//     display: block;
//     width: 40%;
//     height: 1px;
//     background-color: #ddd;
//     position: absolute;
//     top: 50%;
//   }
//   &::before {
//     left: 0;
//   }
//   &::after {
//     right: 0;
//   }
// `;

const MessageContainer = styled.div<{ isAssistant: boolean }>`
  display: flex;
  flex-direction: ${(props: any) =>
    props.isAssistant ? "row" : "row-reverse"};
  margin-bottom: 16px;
  padding: 16px;
`;

const MessageBubble = styled.div<{ isAssistant: boolean }>`
  min-width: min-content;
  position: relative;
  max-width: 70%;
  padding: 12px;
  background-color: ${(props: any) =>
    props.isAssistant ? "#f9f9f9" : "#6150E1"};
  color: ${(props: any) => (props.isAssistant ? "#333" : "#fff")};
  border-radius: 8px;
  border: ${(props: any) => (props.isAssistant ? "1px solid #6150E1" : "none")};
  margin-left: ${(props: any) => (props.isAssistant ? "0" : "auto")};
`;

const Avatar = styled.div<{ isAssistant: boolean }>`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${(props: any) => (props.isAssistant ? "#ddd" : "#ffc107")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: bold;
  color: ${(props: any) => (props.isAssistant ? "#666" : "#fff")};
  margin: ${(props: any) => (props.isAssistant ? "0 8px 0 0" : "0 0 0 8px")};
  box-sizing: border-box;
`;

const Timestamp = styled.div`
  font-size: 0.75rem;
  color: #aaa;
  margin-top: 4px;
  text-align: ${(props: any) => (props.isAssistant ? "center" : "right")};
`;

// const Footer = styled.div`
//   font-size: 0.8rem;
//   color: #aaa;
//   text-align: center;
//   margin-top: 16px;
// `;
