import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
// @ts-ignore
import Editor from "@monaco-editor/react";
import { replaceVariables } from "./utils";
import AppButton from "../../../AppComponents/AppButton";

interface JsEditorProps {
  value: string;
  onChange: (newValue: string) => void;
  editable?: boolean;
  config?:any;
  payload: any; // Payload comes from props
  output:any;
  setOutput:any;
}

const JsEditor: React.FC<JsEditorProps> = ({ value, onChange, editable = true, payload,config,output,setOutput}) => {
  
  const handleEditorChange = (newValue?: string) => {
    if (newValue !== undefined && newValue !== value) {
      onChange(newValue);
    }
  };

  const runCode = () => {
    let data = {}
    if (payload && typeof payload !== "object") {
      data =JSON.parse(payload)
    }
    
    let result = replaceVariables(config,data)
    var defaultCode = `processPayload(${JSON.stringify(result)}); // Call function with input
    `;
    try {
      let code = `${value}
      ${defaultCode}; // Call function with input
      `
      const result = eval(code); 

      setOutput(JSON.stringify(result, null, 2)); // Pretty print JSON output
    } catch (error) {
      setOutput(`Error: ${error}`);
    }
  };

  return (
    <Box
      sx={{
        border: "1px solid #7e839280",
        borderRadius: "5px",
        overflow: "hidden",
      }}
    >
      {/* Code Editor */}
      <Editor
        height="20vh"
        language="javascript"
        value={value}
        onChange={handleEditorChange}
        options={{
          readOnly: !editable,
          minimap: { enabled: false },
          formatOnPaste: true,
          padding: { top: 10, bottom: 10 },
          lineNumbers: "on",
        }}
      />

      {/* Run Button */}
      {/* <Button onClick={runCode} variant="contained" color="primary" sx={{ m: 1 }}>
        Run Code
      </Button> */}
      {/* <AppButton
                buttonStyle={{
                  margin: "5px",
                  padding:"10px"
                }}
                variant="primary"
                onClick={runCode}
              >
                Run Code
              </AppButton> */}

      {/* Output Box */}
      <Box sx={{ p: 2, backgroundColor: "#f4f4f4", minHeight: "50px" }}>
        {output ? (
          <>
            <Typography variant="body2">
              <strong>Output:</strong>
            </Typography>
            <pre>{output}</pre>
          </>
        ) : (
          <AppButton
            buttonStyle={{
              margin: "5px",
              padding: "10px",
            }}
            variant="primary"
            onClick={runCode}
          >
            Run Code
          </AppButton>
        )}
      </Box>
    </Box>
  );
};

export default JsEditor;
