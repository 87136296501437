import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  DndContext,
  DragOverlay,
  closestCorners,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import {
  CardDetails,
  GridData,
  SeeMore,
  afterDeletedContacts,
  getFilterQuery,
  getNotesDetails,
} from "../../../Store/Slices/Contacts/contacts.selector";
import {
  DeleteData,
  SeeMorePaginationData,
  kanBanCardDetails,
  setNotesData,
} from "../../../Store/Slices/Contacts/contacts.slice";
import { withLoader } from "../../../AppComponents/Loader";
import AppDeleteItem from "../../../AppComponents/AppDeleteItem";
import AppModel from "../../../Hooks/useModel/AppModel";
import AppButton from "../../../AppComponents/AppButton";
import { useSelector } from "react-redux";
import {
  convertQueryToMongo,
  initialQuery,
} from "../../../AppComponents/QueryBuilder/utils/utils";
import {  Box, Divider } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Section from "./Section";
import { getDisplayName } from "./utils/utils";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import {
  styles,
  wrapperStyle,
  TableWrapper,
  AddNoteWrapper,
  Textarea
} from "./SwimLaneView.styles";

function SwimLaneView(props: any) {
  // local values
  const retrievedValue = localStorage.getItem("grid_view") || null;
  const [searchParams] = useSearchParams();
  const currentViewId = searchParams.get("view");
  // redux data
  const { localValues, toggleContactModal, shouldApply, setShouldApply } =
    props;
  // redux
  const gridItems = useAppSelector(GridData);
  const moreData: any = useAppSelector(SeeMore);
  const filterQuery = useSelector(getFilterQuery);
  const refreshData: any = useAppSelector(afterDeletedContacts);
  const noteDetails = useSelector(getNotesDetails);
  const [noteContext, setNoteContext] = useState();
  const allContacts = useAppSelector(CardDetails);
  // hooks
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  // states
  const [deleteData, setDeleteData] = useState<any>(null);
  const [deletedContactStore, setDeletedContactStore] = useState<any>({});
  const [items, setItems] = useState<any>([]);
  const [isApiErr, setIsApiErr] = useState<boolean>(false);
  const [Loader, setLoader] = useState(false);
  const [applyFilter, setApplyFilter] = useState(true);
  const [activeId, setActiveId] = useState<string | number | null>(null);
  const [pagination, setPagination] = useState<any>({
    index: 0,
    status: "",
    statusId: "",
    stageId: "",
    limit: 10,
  });
  const [updateIds, setUpdateIds] = useState<any>({
    id: "",
    stage: "",
    status: "",
  });
  const [data, setData] = useState<any>(null);

  // find container
  function findContainer(id: string) {
    const containerKey = items?.find((key: any) =>
      key?.statuses?.some((item: { contacts: { id: string }[] }) =>
        item?.contacts?.some((contact) => contact?.id === id)
      )
    );

    // for blank array
    const result = items?.find((key: any) =>
      key?.statuses?.some((item: { name: string }) => item?.name === id)
    );
    if (containerKey) {
      // const container = items[containerKey];
      const container = containerKey?.statuses;

      const matchingItem = container?.find(
        (item: { contacts: { id: string }[] }) =>
          item?.contacts?.some((contact) => contact.id === id)
      );

      if (matchingItem) {
        return {
          stage: containerKey?.name,
          name: matchingItem.name,
        };
      }
    } else if (result) {
      return {
        stage: result.name,
        name: id,
      };
    } else if (id in items) {
      return {
        stage: id,
        name: null,
      };
    }

    return {
      stage: null,
      name: null,
    };
  }


  

  // paginaton
  useEffect(() => {
    if (pagination.statusId && pagination?.statusId?.length) {
      setLoader(true);
      dispatch(ContactsActions.GetMoreContacts(pagination))
        .then((res) => {
          if (res.payload.data.status) {
          } else {
            showAlert(
              res?.payload?.message || "Unable to fetch Data",
              "error"
            );
            // sortArray();
            showAlert(
              res?.payload?.message || "Unable to fetch Data",
              "error"
            );
          }
        })
        .catch((error) => {
          // sortArray();
          showAlert(
            error?.response?.statusText || "Unable to fetch Data",
            "error"
          );
        })
        .finally(() => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  useEffect(() => {
    if (moreData && moreData?.contacts?.length && items && items?.length) {
      setItems((prev: { [x: string]: any }) => {
        // for update
        const foundStage = items.find((i: any) => i.id === pagination?.stageId);
        const testObject = foundStage?.statuses?.find(
          (item: { id: any }) => item?.id === pagination?.statusId
        );

        // Update the contact data for the destination container
        const statusIndex = foundStage?.statuses.findIndex(
          (status: { id: any }) => status?.id === pagination?.statusId
        );

        const updatedStatuses = foundStage?.statuses;
        updatedStatuses[statusIndex] = {
          ...updatedStatuses[statusIndex],
          contacts: [...testObject?.contacts, ...moreData?.contacts],
        };
        return prev.map((item: any) => {
          if (item?.id === pagination?.stageId) {
            return { ...item, statuses: updatedStatuses };
          }
          return item;
        });
      });
      dispatch(SeeMorePaginationData(moreData));
    } else {
      if (
        refreshData &&
        refreshData?.length >= 0 &&
        deletedContactStore?.statusId
      ) {
        setItems((prev: { [x: string]: any }) => {
          // for update
          const foundStage = items.find(
            (i: any) => i.id === deletedContactStore?.stageId
          );
          // Update the contact data for the destination container
          const statusIndex = foundStage?.statuses.findIndex(
            (status: { id: any }) =>
              status?.id === deletedContactStore?.statusId
          );

          const updatedStatuses = foundStage?.statuses;
          updatedStatuses[statusIndex] = {
            ...updatedStatuses[statusIndex],
            total_records: updatedStatuses[statusIndex]?.total_records - 1,
            contacts: refreshData,
          };
          return prev.map((item: any) => {
            if (item?.id === deletedContactStore?.stageId) {
              return { ...item, statuses: updatedStatuses };
            }
            return item;
          });
        });
        dispatch(DeleteData(moreData));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moreData, deletedContactStore]);

  useEffect(() => {
    if (items && items?.length) {
      const updatedStore =
        items?.flatMap(({ statuses = [] }) =>
          statuses?.flatMap(({ contacts = [] }) => contacts)
        ) || [];
      // dispatch function on items value update to show in card when drag ***
      dispatch(kanBanCardDetails(updatedStore));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  // store all contact data in array ob object
  const sortArray = () => {
    let ArrayCopy =
      gridItems && gridItems.length
        ? JSON.parse(JSON.stringify(gridItems))
        : [];
    let aa = ArrayCopy.map((item: any) => {
      return {
        ...item,
        statuses: [...item.statuses].sort(),
      };
    });
    setItems(aa);
  };

  useEffect(() => {
    if (gridItems && gridItems.length) {
      sortArray();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridItems]);

  //  api's calling
  // get data api
  const getGridData = () => {
    props.setLoading(true);
    let payload: any;

    if (applyFilter) {
      payload = {
        contact_filter: localValues?.contact_filter,
        search: localValues?.search,
        mongodb_query: convertQueryToMongo(
          filterQuery ? filterQuery : initialQuery
        ),
        filter_query: filterQuery ? filterQuery : initialQuery,
        ...pagination
      };
    } else {
      payload = {
        contact_filter: localValues?.contact_filter,
        search: localValues?.search,
        mongodb_query: convertQueryToMongo(
          filterQuery ? filterQuery : initialQuery
        ),
        filter_query: filterQuery ? filterQuery : initialQuery,
        ...pagination
      };
    }

    dispatch(ContactsActions.getContactGridView(payload))
      .then((res: any) => {
        props.setLoading(false);
        // Check if toggleDrawer exists before calling it
        if (typeof props.toggleDrawer === 'function') {
          props.toggleDrawer(false);
        }
        setApplyFilter(false);
        if (res?.error?.message === "Rejected") {
          setIsApiErr(true);
          showAlert(
            res?.payload?.data?.message || "Unable to fetch the data",
            "error"
          );
        } else {
          // Check if toggleDrawer exists before calling it
          if (typeof props.toggleDrawer === 'function') {
            props.toggleDrawer(false);
          }
        }
      })
      .catch((error: any) => {
        setIsApiErr(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch the data main",
          "error"
        );
      })
      .finally(() => {
        props.setLoading(false);
      });
  };
  // geta data
  useEffect(() => {
    if (retrievedValue !== "true") return;
    
    if (shouldApply) {
      getGridData();
      setShouldApply(false);
    } else if (localValues?.mongoQuery || localValues?.selected_view) {
      props.setLoading(false);
      getGridData();
    }
  }, [shouldApply, localValues?.mongoQuery, localValues?.selected_view]);
  
  // localValues - This dependency is removed

  // submit for update contacts
  const submitFunctionCall = (updateIds: any) => {
    if (updateIds?.status) {
      dispatch(ContactsActions.ContactGridViewUpdate(updateIds))
        .then((res) => {
          if (res.payload.data.status) {
            // getGridData();
            // return null;
          } else {
            showAlert(
              res?.payload?.message || "Enabled to fetch Data",
              "error"
            );
            sortArray();
            showAlert(
              res?.payload?.message || "Enabled to fetch Data",
              "error"
            );
          }
        })
        .catch((error) => {
          sortArray();
          showAlert(
            error?.response?.statusText || "Enabled to fetch Data",
            "error"
          );
        })
        .finally(() => {});
    }
  };

  // contacts delete api
  const handleDeleteBtn = async (item: any) => {
    setLoader(true);
    try {
      let payload = {
        contact_id: deleteData?.id,
        lifecycle_status: deleteData?.statusId,
        record_size: deleteData?.index,
        contact_filter: localValues?.filter_query,
        search: localValues?.search,
        mongodb_query: localValues.mongoQuery,
      };
      const res: any = await dispatch(ContactsActions.DeletContacts(payload));

      if (res?.error?.message === "Rejected") {
        showAlert(res?.error?.message || "Contact not deleted", "error");
      } else {
        showAlert(res?.payload?.data?.msg || "Contact deleted", "success");
        setDeletedContactStore(deleteData);
        setDeleteData(null);
        setTimeout(() => {
          getGridData();
        }, 500);
      }
    } catch (error) {
      showAlert("Something went wrong", "error");
    } finally {
      setLoader(false);
    }
  };

  // add notes api
  const addNote = () => {
    let data = {
      contact_id: noteDetails?.contactId,
      context: noteContext,
    };
    setLoader(true);
    dispatch(ContactsActions.addNoteAsPerId(data))
      .then((response: any) => {
        showAlert(response?.payload?.data?.message, "success");
        const payload = {
          status: false,
          contactId: "",
        };
        dispatch(setNotesData(payload));
        getGridData();
      })
      .catch((error: any) => {
        // setPaginationData({ ...paginationData, pageReady: false });
      })
      .finally(() => {
        // props.setLoading(false);
        setLoader(false);
      });
  };
  const closeNote = () => {
    const payload = {
      status: false,
      contactId: "",
    };
    dispatch(setNotesData(payload));
  };
  let subTitle = `Are you sure, you want to delete item`;










  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 0,
        tolerance: 8,
        distance: 5
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 0,
        tolerance: 8,
        distance: 5
      },
    })
  );

  // Memoize container styles
  const containerStyles = useMemo(() => ({
    ...styles.container,
  }), [styles.container]);

  const findContactLocation = useCallback((contactId: any) => {
    for (const section of data as any) {
      for (const status of section.statuses) {
        const contactIndex = status.contacts.findIndex((c: any) => c.id === contactId);
        if (contactIndex !== -1) {
          return { section, status, contactIndex };
        }
      }
    }
    return null;
  }, [data, submitFunctionCall, getGridData, activeId]);

  // Memoize event handlers
  const handleDragStart = useCallback((event: any) => {
    const { active } = event;
    setActiveId(active.id);
  }, []);

  const handleDragEnd = useCallback((event: any) => {
    const { active, over, delta } = event;
    
    // If there's no over element or if the movement was too small, don't do anything
    if (!over || (Math.abs(delta.x) < 5 && Math.abs(delta.y) < 5)) return;

    const activeContactId = active.id;
    const activeLocation = findContactLocation(activeContactId);
    
    if (!activeLocation) return;

    // Parse the over.id to get section and status IDs when dropping in empty column
    let overSectionId: string | number | null = null;
    let overStatusId: string | number | null = null;
    let overContactId: string | number | null = null;

    if (over.id.includes('-')) {
      [overSectionId, overStatusId] = over.id.split('-');
      overContactId = null;
    } else {
      overContactId = over.id;
      const overLocation = findContactLocation(overContactId);
      if (!overLocation) return;
      overSectionId = overLocation.section.id;
      overStatusId = overLocation.status.id;
    }

    // Check if the card is being moved to a different column
    const isNewColumn = activeLocation.status.id !== overStatusId || 
                       activeLocation.section.id !== overSectionId;

    // If it's the same column, don't do anything
    if (!isNewColumn) {
      setActiveId(null);
      return;
    }

    // Only call API if moving to a different column
    if (isNewColumn) {
      const updateIds = {
        id: activeContactId,
        stage: overSectionId,
        status: overStatusId,
      };
      submitFunctionCall(updateIds);
      
      // Add getGridData call after submitting the update
      setTimeout(() => {
        getGridData();
      }, 500);
    }

    setData((prevData: any) => {
      const newData = JSON.parse(JSON.stringify(prevData));
      
      const activeSection = newData.find((s: any) => s.id === activeLocation.section.id);
      const activeStatus = activeSection.statuses.find((s: any) => s.id === activeLocation.status.id);
      
      const overSection = newData.find((s: any) => s.id === overSectionId);
      const overStatus = overSection.statuses.find((s: any) => s.id === overStatusId);
      
      // Remove from source
      const [movedContact] = activeStatus.contacts.splice(activeLocation.contactIndex, 1);
      activeStatus.total_records--;
      
      // Add to destination
      const overIndex = overContactId 
        ? overStatus.contacts.findIndex((c: any) => c.id === overContactId)
        : overStatus.contacts.length;
      
      overStatus.contacts.splice(overIndex, 0, {
        ...movedContact,
        lifecycle_stage: overSection.id,
        lifecycle_stage_status: overStatus.id
      });
      overStatus.total_records++;
      
      return newData;
    });
    
    setActiveId(null);
  }, [findContactLocation, submitFunctionCall, getGridData]);

  useEffect(() => {
setData(items)
  }, [items])

  if (!data) return <div>Loading...</div>;




// A memoized component for the drag overlay content.
const DragOverlayContent = memo(function DragOverlayContent({ 
  activeId, 
  findContactLocation, 
  styles 
}: any) {
  const content = useMemo(() => {
    if (!activeId) return null;
    
    const location = findContactLocation(activeId);
    if (!location) return null;
    
    const contact = location.status.contacts.find((c: any) => c.id === activeId);
    if (!contact) return null;

    return (
      <div style={styles.cardName}>
        {getDisplayName(contact)}
      </div>
    );
  }, [activeId, findContactLocation, styles]);

  return content;
});


if (isApiErr) {
  return <NoRecordsFound
      headerText="Something Went Wrong!"
      subText="Error Loading Data.."
      height="calc(100vh - 168px)"
      />
  }

  

  return (
    <TableWrapper style={wrapperStyle} className="check">




<DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <div style={containerStyles}>
          {data.map((section: any) => (
            <Section
              key={section.id}
              section={section}
              setDeleteData={setDeleteData}
              toggleContactModal={toggleContactModal}
              Loader={Loader}
              setPagination={setPagination}
              items={items}
              pagination={pagination}
            />
          ))}
        </div>
        <DragOverlay>
          {activeId ? (
            <div style={styles.dragOverlay}>
              <DragOverlayContent
                activeId={activeId}
                findContactLocation={findContactLocation}
                styles={styles}
              />
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>



     



     
      {/* delete modal*/}
      {items && items?.length ? (
        <AppDeleteItem
          title="Delete Contact"
          subTitle={subTitle}
          onDelete={() => handleDeleteBtn(deleteData)}
          setDeleteData={setDeleteData}
          deleteData={deleteData}
        />
      ) : null}
      {/* add note modal */}
      <AppModel
        isShowing={noteDetails?.status}
        divStyle={{
          width: "550px",     
          gap: "0.5rem",
          overflowY: "hidden",
          padding: 0
        }}
      >
        <AddNoteWrapper>
          <div className="text">
            <h4>Add Note</h4>
          </div>
          <Divider sx={{ mb: 2 }} />
          <div className="header">
            <Textarea
            placeholder="Your note..."
            rows="4"
              onChange={(e: any) => {
                setNoteContext(e.target.value);
              }}
            />
          </div>
          <Divider sx={{ mt: 2 }} />
          <div className="footer">
            <AppButton variant="grey" onClick={() => closeNote()} style={{}}>
              Cancel
            </AppButton>
            <AppButton onClick={() => addNote()} style={{}}>
              Add
            </AppButton>
          </div>
        </AddNoteWrapper>
      </AppModel>
    </TableWrapper>
  );
}
export default memo(withLoader(SwimLaneView));