import { Box, Breadcrumbs, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import {
  AppFormFastField,
  AppFormObserver,
  AppSubmitButton,
} from "../../../../AppComponents/Forms";
import { LoaderComponentProps } from "../../../../AppComponents/Loader";
import { ProtectedAppForm } from "../../../../AppComponents/ProtectedComponents";
import { AppConfig } from "../../../../Config/app-config";
import { theme } from "../../../../Customization/Theme";
import { HocBackdropLoader } from "../../../../HOC/HocBackdropLoader";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { creditDetailsStore } from "../../../../Store/Slices/CreditDetails/CreditDetails.selectors";
import { marketingListActions } from "../../../../Store/Slices/MarketingList/marketingList.actions";
import { marketingListStore } from "../../../../Store/Slices/MarketingList/marketingList.selectors";
import { removeMarketingListData } from "../../../../Store/Slices/MarketingList/marketingList.slice";
import { WhatsappNetworkService } from "../../../../Store/Slices/Whatsapp/WhatsappNetworkService";
import { WhatsappActions } from "../../../../Store/Slices/Whatsapp/whatsapp.actions";
import { whatsappDataStore } from "../../../../Store/Slices/Whatsapp/whatsapp.selectors";
import {
  resetTemplateData,
  testWhatsappData,
} from "../../../../Store/Slices/Whatsapp/whatsapp.slice";
import { findFormSpecificPermissionKeyUsingLocation } from "../../../../Utils/rolesUtils";
import { ReactComponent as ResolveVariable } from "../../../../assets/images/ResolveVariable.svg";
import { ReactComponent as Recipients } from "../../../../assets/images/recipients.svg";
import { ReactComponent as Schedule } from "../../../../assets/images/schedule.svg";
import { ReactComponent as Setup } from "../../../../assets/images/setup.svg";
import { WHATSAPP_PRIMARY_COLOR } from "../../BroadcastPreview/Constants";
import BroadcastCreditDetails from "../../Utils/BroadcastCreditDetails";
import BroadcastNoData from "../../Utils/BroadcastNoData";
import { StyledContentBox, StyledCreditBox } from "../Templates/Styles";
import BroadcastTemplatePreview from "../Templates/components/Preview/BroadcastTemplatePreview";
import {
  BROADCAST_WHATSAPP_FORM_DATA,
  WhatsappBroadcastScheduleStep1Schema,
  WhatsappBroadcastScheduleStep2Schema,
  WhatsappBroadcastScheduleStep3Schema,
  WhatsappBroadcastScheduleStep4Schema,
} from "../Utils/Constants";
import {
  convertDataToWhatsappBroadcastForm,
  convertDateTimeToUTC,
  dataToTemplateFormData,
} from "../Utils/utils";
import {
  BreadcrumbsItem,
  StyledCreateBroadcastWrap,
  WhatsappBoxTopbar,
  WhatsappStepperBar,
} from "./Styles";
import RenderFormStep1 from "./components/RenderFormStep1";
import RenderFormStep2 from "./components/RenderFormStep2";
import RenderFormStep3 from "./components/RenderFormStep3";
import RenderFormStep4 from "./components/RenderFormStep4";
import {
  ContactListingPagination,
  ContactsListingData,
  getFilterQuery,
} from "../../../../Store/Slices/Contacts/contacts.selector";
import { convertQueryToMongo } from "../../../../AppComponents/QueryBuilder/utils/utils";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { getIn } from "formik";
import { ContactsActions } from "../../../../Store/Slices/Contacts/contacts.action";

interface BreadCrumbItem {
  id: number;
  name: string;
  icon: JSX.Element;
}

const BreadCrumbsItems: BreadCrumbItem[] = [
  {
    id: 1,
    name: "Template",
    icon: <Setup />,
  },
  {
    id: 2,
    name: "Recipients",
    icon: <Recipients />,
  },
  {
    id: 3,
    name: "Review Cost & Confirm",
    icon: <ResolveVariable />,
  },
  {
    id: 4,
    name: "Schedule",
    icon: <Schedule />,
  },
];
interface Props extends LoaderComponentProps {}

const BroadcastCreate: React.FC<Props> = ({ setLoading }) => {
  const localStorageFormData: any = AppConfig.getUserData(
    BROADCAST_WHATSAPP_FORM_DATA
  );
  const Listdata = useAppSelector(ContactsListingData);
  const pagination = useAppSelector(ContactListingPagination);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { templateByIdData, postWhatsappBroadcastData, allTemplatesData } =
    useAppSelector(whatsappDataStore);
  const [broadcastNowPayload, setBroadcastNowPayload] = useState<any>(null);
  const { broadcastByIdData }: any = useAppSelector(marketingListStore);
  const { broadcastCreditDetails }: any = useAppSelector(creditDetailsStore);
  const { showAlert } = useNotification();
  const [previewData, setPreviewData] = useState<any>({});
  const [isShowing, setShowing] = useState(false);

  const { step_id, broadcast_id }: any = useParams();
  const parameters = new URLSearchParams(window.location.search);
  const filterQuery = useAppSelector(getFilterQuery);
  const [duplicateName, setDuplicateName] = useState("");
  const [pendingPayload, setPendingPayload] = useState<any>(null);

  //*WILL BE USED TO SET THE FORM ACCESS TYPE
  const [formAccessType, setFormAccessType] = useState<
    "create" | "read" | "update" | "delete" | undefined
  >();
  //*getting the form access type using the location on first render
  useEffect(() => {
    const result = findFormSpecificPermissionKeyUsingLocation();
    if (result === "update" && parameters?.get("view")) {
      setFormAccessType("read");
    } else {
      setFormAccessType(result);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [formStep, setFormStep] = useState<any>(
    step_id && broadcast_id && !parameters?.get("view")
      ? Number(step_id)
      : step_id && templateByIdData
      ? Number(step_id)
      : 1
  );
  useEffect(() => {
    const clearData = () => {
      AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, null);
      dispatch(resetTemplateData({}));
      dispatch(removeMarketingListData({}));
    };

    window.addEventListener("beforeunload", clearData);
    return () => window.removeEventListener("beforeunload", clearData);
  }, []);
  const [formikProps, setFormikProps] = useState<any>(null);
  const { values }: any = formikProps || {};
  async function fetchData() {
    const previewData = await dataToTemplateFormData(
      templateByIdData?.original_response
    );
    setPreviewData(previewData);
  }
  useEffect(() => {
    fetchData();
  }, [templateByIdData]);
  const [isEditingBroadcast] = useState<boolean>(
    step_id && broadcast_id && step_id === String(4) ? true : false
  );
  const [isView] = useState<boolean>(
    step_id && broadcast_id && step_id === String(1) ? true : false
  );
  const [userInput, setUserInput] = useState<any>(
    localStorageFormData
      ? {
          broadcast_name: localStorageFormData?.broadcast_name,
          broadcast_template_id:
            localStorageFormData?.broadcast_template_id || 0,
          broadcast_action:
            localStorageFormData?.broadcast_action || "broadcast_to_contacts",
          marketing_list: localStorageFormData?.marketing_list || 0,
          filters: localStorageFormData?.filters || null,
          selected_rows: localStorageFormData?.selected_rows || [],
          search_term: localStorageFormData?.search_term || "",

          when_to_send: localStorageFormData?.when_to_send || "broadcast_now",
          date: new Date(Date.now()),
          time: new Date(Date.now()),
          limitedTimeOfferDate: new Date(Date.now()),
          limitedTimeOfferTime: new Date(Date.now()),

          resolved_header_variables:
            localStorageFormData?.resolved_header_variables || [],
          resolved_body_variables:
            localStorageFormData?.resolved_body_variables || [],
          resolved_buttons_variables:
            localStorageFormData?.resolved_buttons_variables || [],
          id: localStorageFormData?.id || null,
          template: localStorageFormData?.components,
          components: localStorageFormData?.components,
        }
      : {
          broadcast_name: "",
          broadcast_template_id: 0,
          broadcast_action: "broadcast_to_contacts",
          marketing_list: 0,
          filters: null,
          selected_rows: [],
          search_term: "",
          when_to_send: "broadcast_now",
          date: new Date(Date.now()),
          time: null,
          limitedTimeOfferDate: new Date(Date.now()),
          limitedTimeOfferTime: new Date(Date.now()),
          resolved_header_variables: [],
          resolved_body_variables: [],
          resolved_buttons_variables: [],
          id: postWhatsappBroadcastData?.id,
          body_preview: previewData?.original_response?.body_value,
        }
  );
  const [openNameChangeDialog, setOpenNameChangeDialog] = useState(false);
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
  const [limitCount, setLimitCount] = useState(0);

  const handleCloseModal = () => {
    setIsLimitModalOpen(false);
  };
  const handleCloseNameDialog = () => {
    setOpenNameChangeDialog(false);
  };
  const [newBroadcastName, setNewBroadcastName] = useState(duplicateName);

  const submitBroadcastWithNewName = () => {
    const payloadData = payloadObject(values);
    const payload = {
      ...payloadData,
      broadcast_name: values?.broadcast_name.replace(/\s+/g, " ").trim() || "",
      broadcast_template_id: values?.broadcast_template_id,
      template_title: values?.template?.original_response?.template_title || "",
      marketing_list_id: values?.marketing_list || "",
      marketing_list_name: values?.marketing_list_name || "",
      broadcast_action: values?.broadcast_action || "marketing_list",
      filter_query: filterQuery,
      mongodb_query: convertQueryToMongo(filterQuery),
      scheduled_now: values?.when_to_send === "broadcast_now",
      search: "",
      // scheduled_date: values?.date,
    };
    setDuplicateName(payload.broadcast_name);
    setPendingPayload(payload);
    WhatsappNetworkService.postWhatsappBroadcast(payload)
      .then((response) => {
        if (response.data && response.data.status) {
          showAlert("Broadcast created successfully", "success");
          navigate("/broadcast/whatsapp/history");
        }
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleError = (error: {
    response: { data: { error: any } };
    request: any;
    message: any;
  }) => {
    if (error.response && error.response.data.error) {
      if (error.response.data.error === "Broadcast Name Already Exist.") {
        setOpenNameChangeDialog(true);
        // setFormStep(1);
        showAlert(
          error.response.data.error || "Broadcast name already exists",
          "error"
        );
        setShowing(false);
      } else {
        showAlert(error.response.data.error || "Error in broadcast", "error");
        setOpenNameChangeDialog(false);
        setShowing(false);
      }
      // toggle(false);    } else if (error.request) {
      // showAlert("Request failed", "error");
    } else {
      showAlert("Something went wrong", "error");
    }
  };
  const handleBroadcastNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewBroadcastName(event.target.value);
  };
  //* HANDLE SUBMIT BTN API CALL
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    if (formStep === 1) {
      AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, values);
      setFormStep(formStep + 1);
      SubmitProps.setSubmitting(false);
      return;
    }

    if (formStep === 2) {
      dispatch(ContactsActions.getBroadcastLimit("whatsapp"))
        .then((response: any) => {
          let limit = response?.payload?.data?.limit;

          if (pagination?.totalRecords <= limit || limit == "TIER_UNLIMITED") {
            SubmitProps.setSubmitting(false);
            AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, values);
            setFormStep(formStep + 1);
            return;
          } else {
            setLimitCount(limit);
            setIsLimitModalOpen(true);
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
      SubmitProps.setSubmitting(false);
    }
    if (formStep === 3) {
      // const payload = payloadObject(values);
      AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, values);
      setFormStep(formStep + 1);
      SubmitProps.setSubmitting(false);
      return;
    }

    if (formStep === 4) {
      if (!isEditingBroadcast) {
        if (values.when_to_send === "broadcast_now" && !openNameChangeDialog) {
          setBroadcastNowPayload(values);
          setShowing(true);
          SubmitProps.setSubmitting(false);
        } else {
          const payloadData = payloadObject(values);
          const payload = {
            ...payloadData,
            broadcast_name:
              values?.broadcast_name.replace(/\s+/g, " ").trim() || "",
            broadcast_template_id: values?.broadcast_template_id,
            template_title:
              values?.template?.original_response?.template_title || "",
            marketing_list_id: values?.marketing_list || "",
            marketing_list_name: values?.marketing_list_name || "",
            broadcast_action: values?.broadcast_action || "marketing_list",
            filter_query: filterQuery,
            mongodb_query: convertQueryToMongo(filterQuery),
            scheduled_now: values?.when_to_send === "broadcast_now",
            search: "",
            // scheduled_date: values?.date,
          };
          setDuplicateName(payload.broadcast_name);
          setPendingPayload(payload);
          WhatsappNetworkService.postWhatsappBroadcast(payload)
            .then((response) => {
              if (response.data && response.data.status) {
                showAlert("Broadcast created successfully", "success");
                navigate("/broadcast/whatsapp/history");
              }
            })
            .catch(handleError)
            .finally(() => {
              setLoading(false);
            });
        }
      } else {
        let payload: any = {};
        payload["scheduled_date"] = convertDateTimeToUTC(
          values?.date,
          values?.time
        );
        payload["scheduled_now"] =
          values?.when_to_send === "broadcast_now" ? true : false;
        WhatsappNetworkService.updateWhatsappBroadcast(payload, broadcast_id)
          .then((res: any) => {
            if (res?.data?.error) {
              showAlert(res.data.error, "error");
              return;
            }
            AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, null);
            showAlert("Broadcast updated", "success");
            navigate("/broadcast/whatsapp/scheduled");
          })
          .catch((err: any) => {
            showAlert(
              err?.response?.data?.error || "Something went wrong",
              "error"
            );
          })
          .finally(() => {
            SubmitProps.setSubmitting(false);
            setLoading(false);
          });
      }
      SubmitProps.setSubmitting(false);
    }
  };

  const payloadObject = (values: any) => {
    let payload: any = {
      broadcast_name: values?.broadcast_name.replace(/\s+/g, " ").trim() || "",
      broadcast_template_id: values?.broadcast_template_id,
      status: "draft",
      components: {},
    };
    if (
      values?.template?.original_response?.button_type === "limited_time_offer"
    ) {
      payload["limited_time_offer"] = {};
      payload["limited_time_offer"]["value"] = convertDateTimeToUTC(
        values?.limitedTimeOfferDate,
        values?.limitedTimeOfferTime
      );
    }

    if (values?.resolved_header_variables?.length > 0) {
      payload["components"]["header"] = {
        type: values?.header_type,
        value:
          typeof values?.resolved_header_variables?.[0]?.value === "object"
            ? values?.resolved_header_variables?.[0]?.value?.source
            : ["image", "video", "document"].includes(
                values?.header_type?.toLowerCase()
              )
            ? `<<${values?.resolved_header_variables?.[0]?.value}>>`
            : values?.resolved_header_variables?.[0]?.value,
        index:
          values?.header_type === "TEXT"
            ? values?.resolved_header_variables?.[0]?.index
            : null,
      };
    }
    if (values?.resolved_body_variables?.length > 0) {
      payload["components"]["body"] = {
        value: values?.resolved_body_variables,
      };
    }
    if (values?.resolved_buttons_variables?.length > 0) {
      payload["components"]["button"] = {
        value: values?.resolved_buttons_variables,
        type: "dynamic",
      };
    }

    const processedData = values?.resolved_carousel_variables?.map(
      (item: any) => {
        const headerValue = item.header?.value;

        if (typeof headerValue === "object") {
          // Keep value as it is if it's an object
          return { ...item, header: { ...item.header, value: headerValue } };
        } else if (typeof headerValue === "string") {
          // Wrap the string in << >>
          return {
            ...item,
            header: { ...item.header, value: `<<${headerValue}>>` },
          };
        }
        // Return the item as is if the value is neither an object nor a string
        return item;
      }
    );
    if (values?.resolved_carousel_variables?.length > 0) {
      payload["components"]["carousel"] = {
        value: processedData,
        type: "carousel",
      };
    }
    payload["broadcast_id"] = values?.id;
    payload["scheduled_now"] =
      values?.when_to_send === "broadcast_now" ? true : false;
    payload["scheduled_date"] = null;
    if (values?.when_to_send === "schedule_broadcast") {
      payload["scheduled_date"] = convertDateTimeToUTC(
        values?.date,
        values?.time
      );
    }

    return payload;
  };

  //* only first time render
  useEffect(() => {
    if ((isEditingBroadcast || isView) && broadcast_id) {
      // dispatch(WhatsappActions.getBroadcastById(broadcast_id)).finally(() => {
      //   setLoading(false);
      // });
      dispatch(
        marketingListActions.getScheduledBroadcastByID(broadcast_id)
      ).finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    }
    dispatch(resetTemplateData({}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if ((isEditingBroadcast || isView) && broadcastByIdData) {
      setLoading(true);
      let temp = convertDataToWhatsappBroadcastForm(broadcastByIdData);
      setUserInput(temp);
      setLoading(true);
      dispatch(
        WhatsappActions.getTemplateById(temp?.broadcast_template_id)
      ).finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    }
  }, [broadcastByIdData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleFormNavigation(formStep);
  }, [formStep]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, null);

      dispatch(resetTemplateData({}));
      formikProps?.handleReset();
      setUserInput({
        broadcast_name: "",
        broadcast_template_id: 0,
        broadcast_action: "broadcast_to_contacts",
        marketing_list: 0,
        filters: null,
        selected_rows: [],
        search_term: "",
        when_to_send: "broadcast_now",
        date: new Date(Date.now()),
        time: null,
        resolved_header_variables: [],
        resolved_body_variables: [],
        resolved_buttons_variables: [],
        body_preview: "",
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormNavigation = (formIndex: number) => {
    if (formIndex === 1) {
      setLoading(true);
      dispatch(
        WhatsappActions.getTemplatesListings({
          offset: 0,
          limit: 9999,
        })
      ).finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 100);
      });
    }
    if (!templateByIdData && formStep !== 1) {
      return;
    }
    if (!isView && !isEditingBroadcast) {
      let url = `/broadcast/whatsapp/create-broadcast/${formIndex}`;
      navigate(url);
    }
  };

  const handleResetClick = () => {
    if (formStep === 1) {
      AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, null);
      dispatch(resetTemplateData({}));
      dispatch(removeMarketingListData({}));
      formikProps?.handleReset();
      setUserInput({
        broadcast_name: "",
        broadcast_template_id: 0,
        broadcast_action: "broadcast_to_contacts",
        marketing_list: 0,
        filters: null,
        selected_rows: [],
        search_term: "",
        when_to_send: "broadcast_now",
        date: new Date(Date.now()),
        time: null,
        resolved_header_variables: [],
        resolved_body_variables: [],
        resolved_buttons_variables: [],
        body_preview: "",
      });
      return;
    }

    if (formStep !== 1) {
      AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, null);
      // formikProps?.handleReset();
      setUserInput({
        broadcast_name: "",
        // broadcast_template_id: 0,
        broadcast_action: "broadcast_to_contacts",
        marketing_list: 0,
        filters: null,
        selected_rows: [],
        search_term: "",
        when_to_send: "broadcast_now",
        date: new Date(Date.now()),
        time: new Date(Date.now()),
        resolved_header_variables: [],
        resolved_body_variables: [],
        resolved_buttons_variables: [],
        body_preview: "",
      });
      setFormStep(formStep - 1);
      if (formStep === 3) {
        setFormStep(formStep - 2);
        return;
      }
      if (formStep === 4) {
        setFormStep(formStep - 3);
        return;
      }
      return;
    }
  };
  const handleBackClick = () => {
    if (isEditingBroadcast || isView || formStep === 1) {
      let url = `/broadcast/whatsapp/history`;
      navigate(url);
    } else {
      if (formStep !== 1) {
        setFormStep(formStep - 1);
      } else {
        navigate(-3);
      }
    }
  };

  const approvedTeplateCount = allTemplatesData?.filter((ele: any) => {
    return ele?.status === "APPROVED";
  });

  const handleButtonClick = () => {
    return navigate("/broadcast/whatsapp/create-template");
  };
  const copyCodeUrlQuickRepliesEtcState =
    previewData?.original_response?.copyUrlOptions?.reduce(
      (acc: any, state: any) => {
        if (state.checked) {
          acc[state.name] = true;
        }
        return acc;
      },
      {}
    );
  return (
    <StyledCreateBroadcastWrap>
      <StyledContentBox style={{ marginTop: "0rem" }}>
        <div className="formBox">
          <ProtectedAppForm
            initialValues={userInput}
            divStyle={{ gap: 0 }}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitBtn(values, submitProps);
            }}
            validationSchema={
              (!isView &&
                formStep === 1 &&
                WhatsappBroadcastScheduleStep1Schema) ||
              (!isView &&
                formStep === 2 &&
                WhatsappBroadcastScheduleStep2Schema) ||
              (!isView &&
                formStep === 3 &&
                WhatsappBroadcastScheduleStep3Schema) ||
              (!isView &&
                formStep === 4 &&
                WhatsappBroadcastScheduleStep4Schema) ||
              null
            }
            initialTouched={userInput}
            validateOnChange={true}
            moduleId="whatsapp_broadcast_crud"
            specificPermissionKey={formAccessType}
          >
            <WhatsappBoxTopbar>
              <div className="left">
                <p className="heading">
                  {" "}
                  {!isEditingBroadcast && (
                    <IconButton onClick={() => handleBackClick()}>
                      <AppMaterialIcons
                        iconName={"ArrowBackIosRounded"}
                        style={{ fontSize: "0.8rem", color: "black" }}
                      />
                    </IconButton>
                  )}{" "}
                  {isEditingBroadcast ? "Edit" : "Create"} WhatsApp Broadcast
                </p>
              </div>
              <div
                className="test-tamplate__btn"
                style={{ justifyContent: "flex-end" }}
              >
                <Box>
                  <div
                    className="BtnsBox"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "1rem",
                      }}
                    >
                      {localStorageFormData?.broadcast_template_id &&
                        formStep === 1 && (
                          <AppButton
                            onClick={() => handleResetClick()}
                            variant="grey"
                            disabled={isEditingBroadcast || isView}
                          >
                            Reset
                          </AppButton>
                        )}
                      {formStep !== 1 && !isEditingBroadcast && (
                        <AppButton
                          onClick={() => handleBackClick()}
                          variant="grey"
                          disabled={isEditingBroadcast || isView}
                        >
                          Back
                        </AppButton>
                      )}
                      {isView ? (
                        <AppButton
                          onClick={() =>
                            navigate("/broadcast/whatsapp/scheduled")
                          }
                          variant="grey"
                        >
                          Back
                        </AppButton>
                      ) : null}
                      {formStep === 4 ? (
                        <React.Fragment>
                          {isEditingBroadcast && (
                            <AppButton
                              onClick={() =>
                                navigate("/broadcast/whatsapp/scheduled")
                              }
                              variant="grey"
                            >
                              Back
                            </AppButton>
                          )}
                          <AppSubmitButton
                            title={
                              isEditingBroadcast
                                ? "Update"
                                : values.when_to_send === "broadcast_now"
                                ? "Broadcast Now"
                                : "Schedule Broadcast"
                            }
                          />
                        </React.Fragment>
                      ) : isView ? null : (
                        <React.Fragment>
                          {approvedTeplateCount?.length === 0 ? (
                            ""
                          ) : (
                            <AppSubmitButton
                              title="continue"
                              disabled={
                                !allTemplatesData?.length ||
                                (formStep === 3 &&
                                  !broadcastCreditDetails?.valid) ||
                                broadcastCreditDetails?.credits_balance <= 0
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </Box>
              </div>
            </WhatsappBoxTopbar>

            <WhatsappStepperBar>
              <Breadcrumbs aria-label="breadcrumb" separator=">">
                {BreadCrumbsItems?.map((item: BreadCrumbItem) => {
                  if (isView && item?.id === 1) {
                    return (
                      <BreadcrumbsItem
                        iconColorCondition={item?.id === formStep}
                        key={item?.id}
                      >
                        <div className="icon">{item?.icon}</div>
                        <div className="text">
                          <p>{item?.name}</p>
                        </div>
                      </BreadcrumbsItem>
                    );
                  } else if (!isView) {
                    return (
                      <BreadcrumbsItem
                        iconColorCondition={item?.id === formStep}
                        key={item?.id}
                      >
                        <div className="icon">{item?.icon}</div>
                        <div className="text">
                          <p>{item?.name}</p>
                        </div>
                      </BreadcrumbsItem>
                    );
                  }
                })}
              </Breadcrumbs>
              <Box>
                {/* {formStep === 4 ? (
                  // <AppButton
                  //   variant="outline"
                  //   endIcon="ri-arrow-right-s-line"
                  //   onClick={() => dispatch(testWhatsappData(true))}
                  // >
                  //   Test Whatsapp
                  // </AppButton>
                ) : null} */}
              </Box>
            </WhatsappStepperBar>
            <Box className="section_wrapper">
              <Box className="section_left" p={2}>
                {formStep === 3 ? (
                  <StyledCreditBox>
                    <h3 className="titleText">Review Cost</h3>
                    <BroadcastCreditDetails
                      creaditsData={broadcastCreditDetails}
                    ></BroadcastCreditDetails>
                  </StyledCreditBox>
                ) : null}
                {formStep === 1 &&
                  (approvedTeplateCount?.length === 0 ? (
                    <div style={{ height: "calc(100vh - 240px)" }}>
                      <BroadcastNoData
                        title="No Templates Found"
                        subtitle="Please create at least one template to create the broadcast."
                        onClick={handleButtonClick}
                        buttonText="Create Template"
                      />
                    </div>
                  ) : (
                    <RenderFormStep1
                      setFormStep={setFormStep}
                      formStep={formStep}
                      isView={isView}
                    />
                  ))}

                {formStep === 2 && <RenderFormStep2 />}
                {formStep === 3 && (
                  <RenderFormStep3
                    setFormStep={setFormStep}
                    formStep={formStep}
                    mediaType={templateByIdData?.header_type}
                  />
                )}
                {formStep === 4 && (
                  <RenderFormStep4
                    setFormStep={setFormStep}
                    formStep={formStep}
                  />
                )}
                <AppFormObserver setFormikProps={setFormikProps} />
              </Box>
              {/* PREVIEW WIDGET */}
              <BroadcastTemplatePreview
                primaryColor={WHATSAPP_PRIMARY_COLOR}
                bodyValue={previewData?.original_response?.body_value?.replace(
                  /\*(.*?)\*/g,
                  "<strong>$1</strong>"
                )}
                headerValue={previewData?.original_response?.header_value}
                headerType={previewData?.original_response?.header_type}
                footerValue={previewData?.original_response?.footer_value}
                copyCodeUrlQuickRepliesEtcStateRenderState={
                  previewData?.original_response?.copyUrlOptions
                }
                phoneNumberButtonData={
                  copyCodeUrlQuickRepliesEtcState?.add_phone_number
                    ? {
                        button_text:
                          previewData?.original_response
                            ?.phone_number_button_text,
                        value: previewData?.original_response?.phone_number,
                      }
                    : null
                }
                button_type={previewData?.original_response?.button_type}
                buttonsData={
                  copyCodeUrlQuickRepliesEtcState?.add_quick_replies
                    ? previewData?.original_response?.quick_replies
                    : []
                }
                flowButton={
                  previewData?.origicopyCodeUrlQuickRepliesEtcStatenal_response
                    ?.button_type === "flow"
                    ? previewData?.original_response?.flow
                    : []
                }
                websiteButtonData={
                  copyCodeUrlQuickRepliesEtcState?.add_website_url
                    ? previewData?.original_response?.website_url
                    : []
                }
                carouselButtonData={
                  previewData?.original_response?.carousel?.[0]?.text ||
                  previewData?.original_response?.carousel?.[0]?.card_header
                    ? previewData?.original_response?.carousel
                    : []
                }
                limitedTimeOfferData={
                  previewData?.original_response?.button_type ===
                  "limited_time_offer"
                    ? {
                        offer_button_text:
                          previewData?.original_response?.offer_button_text,
                      }
                    : null
                }
              />
            </Box>
            <AppModel
              isShowing={isShowing}
              onClose={() => setShowing(false)}
              divStyle={{
                padding: 0,
              }}
              PaperProps={{
                style: {
                  boxShadow: "none",
                  width: "450px",
                  height: "150px",
                },
              }}
            >
              <ModalContainer>
                <div className="header">
                  <h4>Broadcast now!</h4>
                  <div className="header-right">
                    <span onClick={() => setShowing(false)}>
                      <AppMaterialIcons iconName="close" />
                    </span>
                  </div>
                </div>
                <div className="body">
                  <p>Are you sure, you want to broadcast this message now?</p>
                </div>
                <div className="footer">
                  <AppButton
                    variant="grey"
                    onClick={() => {
                      setShowing(false);
                    }}
                  >
                    Cancel
                  </AppButton>
                  <AppButton
                    variant="default"
                    onClick={() => {
                      const payloadData = payloadObject(broadcastNowPayload);
                      const payload = {
                        ...payloadData,
                        broadcast_name:
                          broadcastNowPayload?.broadcast_name
                            .replace(/\s+/g, " ")
                            .trim() || "",
                        broadcast_template_id:
                          broadcastNowPayload?.broadcast_template_id,
                        template_title:
                          broadcastNowPayload?.template?.original_response
                            ?.template_title || "",
                        marketing_list_id:
                          broadcastNowPayload?.marketing_list || "",
                        marketing_list_name:
                          broadcastNowPayload?.marketing_list_name || "",
                        broadcast_action:
                          broadcastNowPayload?.broadcast_action ||
                          "marketing_list",
                        filter_query: filterQuery,
                        mongodb_query: convertQueryToMongo(filterQuery),
                        scheduled_now:
                          broadcastNowPayload?.when_to_send === "broadcast_now",
                        search: "",
                        // scheduled_date: broadcastNowPayload?.date,
                      };
                      setDuplicateName(payload.broadcast_name);
                      setPendingPayload(payload);
                      WhatsappNetworkService.postWhatsappBroadcast(payload)
                        .then((response) => {
                          if (response.data && response.data.status) {
                            showAlert(
                              "Broadcast created successfully",
                              "success"
                            );
                            navigate("/broadcast/whatsapp/history");
                          }
                        })
                        .catch(handleError)
                        .finally(() => {
                          setLoading(false);
                        });
                    }}
                  >
                    Broadcast Now
                  </AppButton>
                </div>
              </ModalContainer>
            </AppModel>
            <Dialog
              open={isLimitModalOpen}
              onClose={handleCloseModal}
              PaperProps={{
                sx: style,
              }}
            >
              <DialogTitle>List Limit Reached</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You've reached the contact limit set by WhatsApp. The current
                  limit is {limitCount} contacts. Please change the contact list
                  to proceed. This limit may be updated dynamically by WhatsApp.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Box display="flex">
                  <AppButton
                    variant="cta"
                    onClick={handleCloseModal}
                    endIcon="ok"
                  >
                    OK
                  </AppButton>
                </Box>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openNameChangeDialog}
              // onClose={handleCloseNameDialog}
              PaperProps={{
                sx: style,
              }}
            >
              <DialogTitle>Change Broadcast Name</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please enter a different name for your broadcast as this name
                  is already in use.
                </DialogContentText>
                <AppFormFastField
                  name={"broadcast_name"}
                  label={"Broadcast Name"}
                  placeholder="Broadcast Name"
                  disabled={isView ? true : false}
                  require={true}
                />
              </DialogContent>
              <DialogActions>
                <Box display="flex" gap={2}>
                  <AppButton
                    variant="grey"
                    onClick={handleCloseNameDialog}
                    style={{ padding: "11px 20px 11px" }}
                  >
                    Cancel
                  </AppButton>
                  <AppButton
                    variant="cta"
                    onClick={() => {
                      submitBroadcastWithNewName();
                    }}
                    disabled={!getIn(values, "broadcast_name")?.trim()}
                  >
                    Submit
                  </AppButton>
                </Box>
              </DialogActions>
            </Dialog>
          </ProtectedAppForm>
        </div>
      </StyledContentBox>
    </StyledCreateBroadcastWrap>
  );
};

export default HocBackdropLoader(BroadcastCreate);

const ModalContainer = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.875rem;
    height:40px;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
      h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }
  .body{
    p {
        padding: 0.875rem;
        color: ${theme.palette.default.darkGrey};
      }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
  }
`;
const style = {
  position: "fixed" as "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "662px",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "3px",
};
