import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { IconButton, MenuItem, Select } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../../../../AppComponents/AppMaterialIcons";
import {
  AppForm,
  AppFormField,
} from "../../../../../../../AppComponents/Forms";
import { theme } from "../../../../../../../Customization/Theme";
import AppModel from "../../../../../../../Hooks/useModel/AppModel";
import { useAppDispatch, useAppSelector } from "../../../../../../../Store";
import { VoiceBotActions } from "../../../../../../../Store/Slices/Voicebot/voicebot.actions";
import {
  getVoiceBotDetails,
  getVoiceList,
} from "../../../../../../../Store/Slices/Voicebot/voicebot.selectors";
import { getGenders, getLanguageOptions } from "../../../utils";
import { useParams } from "react-router-dom";
import { useNotification } from "../../../../../../../Hooks/useNotification/useNotification";

const selectStyles = {
  marginBottom: "0px",
  height: "28px",
  fontSize: "14px",
  fontWeight: 400,
  width: "100%",
  maxWidth: "auto",
  minWidth: "100%",
};

const VoiceTable: React.FC = () => {
  const { botId }: any = useParams();
  const { showAlert } = useNotification();

  const [localValues, setLocalValues] = useState<any>({
    search: "",
    limit: 10,
    offset: 0,
    specification: "",
    gender: "",
  });
  const voiceList = useAppSelector(getVoiceList);
  const [languageOpen, setLanguageOpen] = useState(false);
  const botDeatils = useAppSelector(getVoiceBotDetails);
  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
  };

  const handleSelectChange = (e: any, field: any) => {
    setLocalValues({
      ...localValues,
      [field]: e.target.value,
    });
  };

  const renderSelectIcon = localValues?.agent ? (
    <GridCloseIcon fontSize="small" />
  ) : null;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      VoiceBotActions.getVoicesList({
        limit: localValues?.limit,
        offset: localValues?.offset,
        specification: localValues?.specification,
        gender: localValues?.gender,
      })
    );
  }, [localValues]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleUseVoice = (row: any) => {
    const payload = {
      ...botDeatils,
      voicebot: {
        ...botDeatils?.voicebot,
        voice: row,
      },
    };
    dispatch(VoiceBotActions.updateBotDetails({ data: payload, botId }))
      .then((response: any) => {
        if (response.payload.data.voicebot) {
          dispatch(VoiceBotActions.getBotDeatils(botId));
          showAlert("Voice used Successfully!", "success");
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  return (
    <Wrapper>
      <AppForm
        initialValues={{}}
        onSubmit={() => {}}
        divStyle={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <FiltersWrapper>
          <div>
            <div className="filterField">
              <span className="filterLabel">Language </span>
              <NewNativeSelect style={{ width: "200px" }}>
                <Select
                  defaultValue={undefined}
                  name="specification"
                  value={localValues?.specification}
                  onChange={(e) => handleSelectChange(e, "specification")}
                  displayEmpty
                  className="new-select-main mb0"
                  style={selectStyles}
                >
                  <MenuItem value="" disabled>
                    Select Language
                  </MenuItem>
                  {getLanguageOptions()?.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                <div
                  className="clear-icon"
                  onClick={(e: any) => clearCallback(e, "specification")}
                >
                  {renderSelectIcon}
                </div>
              </NewNativeSelect>
            </div>
            <div className="filterField">
              <span className="filterLabel">Gender </span>
              <NewNativeSelect style={{ width: "200px" }}>
                <Select
                  defaultValue={undefined}
                  name="gender"
                  label="Gender"
                  value={localValues?.status}
                  onChange={(e) => handleSelectChange(e, "gender")}
                  displayEmpty
                  className="new-select-main mb0"
                  style={selectStyles}
                >
                  <MenuItem value="" disabled>
                    Select Gender
                  </MenuItem>
                  {getGenders()?.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                <div
                  className="clear-icon"
                  onClick={(e: any) => clearCallback(e, "gender")}
                >
                  {renderSelectIcon}
                </div>
              </NewNativeSelect>
            </div>
          </div>
        </FiltersWrapper>
      </AppForm>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <TableHeader>Audio Sample</TableHeader>
              <TableHeader>Avatar</TableHeader>
              <TableHeader>Name</TableHeader>
              <TableHeader>Specification</TableHeader>
              <TableHeader>Provider</TableHeader>
              <TableHeader>Use Voice</TableHeader>
            </tr>
          </thead>
          <tbody>
            {voiceList?.map((voice: any, index: number) => (
              <TableRow key={index}>
                <TableCell style={{ paddingLeft: "50px" }}>
                  <IconButton onClick={() => setLanguageOpen(true)}>
                    <PlayCircleOutlineIcon sx={{ color: "#000" }} />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <Avatar src={voice.avatar} />
                </TableCell>
                <TableCell>{voice.name}</TableCell>
                <TableCell>
                  {voice?.Locale && <Tag key={"1"}>{voice?.Locale}</Tag>}
                  {voice?.language && <Tag key={"2"}>{voice?.language}</Tag>}
                </TableCell>
                <TableCell>
                  <ProviderButton type="button">
                    {voice.provider}
                  </ProviderButton>
                </TableCell>
                <TableCell>
                  {voice?.isActive ? (
                    <CheckCircleIcon
                      style={{ color: "#25926A", fontSize: "2rem" }}
                    />
                  ) : (
                    <AppButton
                      onClick={() => {
                        handleUseVoice(voice);
                      }}
                      variant="outline"
                    >
                      Use Voice
                    </AppButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      {/* Choose Voice Agent Model */}
      <AppModel
        isShowing={languageOpen}
        onClose={() => {
          setLanguageOpen(false);
        }}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "30%",
            height: "25%",
          },
        }}
      >
        <AppForm
          initialValues={{ audio: "" }}
          onSubmit={() => {}}
          validationSchema={undefined}
        >
          <ModalContainer>
            <div className="header">
              <h4>Voice</h4>
              <div className="header-right">
                <span
                  onClick={() => {
                    setLanguageOpen(false);
                  }}
                >
                  <AppMaterialIcons iconName="close" />
                </span>
              </div>
            </div>
            <div className="body">
              <p>AdaMultilingual</p>
              <p className="provider-info">English (United Kingdom) accent</p>
              <p className="provider-info">by azuretts</p>
              <div className="fieldsContainer">
                <AppFormField
                  name="audio"
                  label=""
                  placeholder="Please enter your text here"
                />
                <IconButton onClick={() => console.log("clicked")}>
                  <PlayCircleOutlineIcon
                    sx={{
                      color: theme.palette.default.darkGrey,
                      fontSize: "25px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </div>
            </div>
          </ModalContainer>
        </AppForm>
      </AppModel>
    </Wrapper>
  );
};

export default VoiceTable;

const Wrapper = styled.div`
  && {
    padding: 1rem;
  }
`;
// Styled Components
const TableContainer = styled.div`
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  background-color: #fff;
`;

const TableHeader = styled.td`
  background-color: #fff;
  padding: 1rem;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
`;

const TableRow = styled.tr`
  background-color: #fff;
`;

const TableCell = styled.td`
  padding: 20px 12px;
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const Tag = styled.span`
  display: inline-block;
  margin-right: 8px;
  padding: 4px 8px;
  background-color: #f0f0f0;
  border-radius: 8px;
  font-size: 12px;
  color: #555;
`;

const ProviderButton = styled.button`
  background-color: #2c2c54;
  color: white;
  padding: 8px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #40407a;
  }
`;

const FiltersWrapper = styled.div`
  && {
    display: flex;
    width: 100%;
    // justify-content: center;
    align-items: center;
    overflow-y: hidden;
    margin-bottom: 1rem;
    max-width: 100%;
    overflow-x: auto;
    gap: 1rem;
    div {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;
    }
    .filterField {
      display: flex;
      flex-direction: column;
    }
    .filterLabel {
      font-size: 16px;
      font-weight: 500;
      padding-left: 0.5rem;
    }
  }
`;

export const NewNativeSelect = styled.div`
  position: relative;
  width: 100%;
  .mb0 {
    margin-bottom: 0px !important;
  }
  .new-select-main {
    max-width: 210px;
    min-width: 180px;
    width: 210px;
    height: 35px;
    background-color: #f5f6f8;
    border: 1px solid rgb(203, 205, 211);
    border-radius: 4px;

    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }
  }
  .clear-icon {
    background-color: ${theme.palette.default.lightGrey};
    position: absolute;
    top: 1px;
    right: 10px;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
    padding:0 0.8rem;
    .header-left {
      display: flex;
      gap: 0.875rem;
      }
      .text h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .text p {
        color: ${theme.palette.default.darkGrey};
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }
  .content{
    margin:10px 0;
    padding: 10px 15px;
}
  .body {
    padding: 0.8rem;
    height: calc(100% - 10px);
    p {
    margin-bottom: 20px;
    font-size: 16px;
    }
    .provider-info {
      font-size: 14px;
      color: ${theme.palette.default.darkGrey};
      margin: 2px;
    }
    .fieldsContainer{
    display:flex;
    align-items:center;
    gap:0.5rem
  }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
    border-top: 1px solid ${theme.palette.default.border};
    .change-template-name {
      display: flex;
      justify-content: space-between;
      align-items: start;

      p.change-name {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;
