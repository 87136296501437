// vendors
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";

// config
import { registerActions } from "./buttonRegisterActions";

// components
import UIButton from "../../Components/Button";
import Input from "../../Components/Input";
import NodeType from "../../Components/NodeType";
import Icon from "../../Components/Icon";
import InputOption from "../../Components/InputOption";

// slice
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";

// utils
import { getNodeConfig } from "../../Utils";
import { getProcessedLength } from "../../Utils/editor";

type Props = {
  language: string;
  onDelete: (button: any) => void;
  onClose: () => void;
  onOptionClick: (action: any) => void;
  selectedAction: any;
  onButtonLabelInputChange: (value: string, description?: boolean) => void;
  activeButtonData: any;
  maxCharLength: number;
  addDescription?: boolean;
  nodeType?: string;
  excludedActionTypes?: string[];
};

const ButtonInputOptions = (props: Props) => {
  const {
    onClose,
    onOptionClick,
    selectedAction,
    onDelete,
    onButtonLabelInputChange,
    activeButtonData,
    language,
    maxCharLength,
    addDescription,
    nodeType,
    excludedActionTypes = [],
  } = props;
  const nodeConfig = getNodeConfig(nodeType || "button");
  const CHARACTER_LIMITS = {
    button: maxCharLength,
    description: 72,
  };
  const [value, setValue] = useState<string>("");
  const [descriptionValue, setDescriptionValue] = useState<string>("");
  const node = getNodeConfig("button");
  const currentBot = useSelector(useCurrentBot);

  useEffect(() => {
    if (activeButtonData) {
      setValue(activeButtonData.value.text);
      setDescriptionValue(activeButtonData.value?.description || "");
    }
  }, [activeButtonData]);

  const handleOptionClick = (action: any) => {
    onOptionClick && onOptionClick(action);
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const handleDeleteButtonClick = () => {
    onDelete && onDelete(activeButtonData);
  };

  const handleInputChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    // Calculate the processed length and enforce the character limit
    if (getProcessedLength?.(value) > CHARACTER_LIMITS.button) {
      return; // Stop further execution if limit is exceeded
    }

    setValue(value); // Update the state with the new value
    onButtonLabelInputChange?.(value); // Trigger the callback if provided
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (getProcessedLength(value) > CHARACTER_LIMITS.description) return;
    setDescriptionValue(value);
    onButtonLabelInputChange && onButtonLabelInputChange(value, true);
  };
  return (
    <Box sx={{ width: "345px", maxWidth: "345px" }}>
      <Box
        sx={{
          py: "4px",
          position: "relative",
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #F5F6F8;",
        }}
      >
        <NodeType text={node.name} color={node.color} icon={node.icon} />
        <Box
          sx={{
            position: "absolute",
            height: "24px",
            width: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "20px",
            right: "16px",
          }}
        >
          <IconButton onClick={handleClose}>
            <Icon icon="close" size={12} color="#7E8392" />
          </IconButton>
        </Box>
      </Box>
      <>
        <Box sx={{ p: "16px" }}>
          <Typography
            sx={{
              color: "#7E8392",
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: 400,
            }}
          >
            Button Title
          </Typography>
          <Input
            error={value === ""}
            placeholder="Title"
            onChange={handleInputChange}
            value={value}
            {...{
              helperText: maxCharLength ? (
                <Stack direction={"row"} spacing={2}>
                  <Typography variant="body2" align="right">
                    {`${getProcessedLength(value)}/${CHARACTER_LIMITS.button}`}
                  </Typography>
                </Stack>
              ) : (
                ""
              ),
            }}
          />
          {addDescription || descriptionValue?.length > 0 ? (
            <>
              <Typography
                sx={{
                  color: "#7E8392",
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: 400,
                }}
              >
                Button Description
              </Typography>
              <Input
                placeholder="Description"
                onChange={handleDescriptionChange}
                value={descriptionValue}
                {...{
                  helperText: CHARACTER_LIMITS.description ? (
                    <Typography variant="body2" align="right">
                      {`${getProcessedLength(descriptionValue)}/${
                        CHARACTER_LIMITS.description
                      }`}
                    </Typography>
                  ) : (
                    ""
                  ),
                }}
              />
            </>
          ) : null}
        </Box>
        <Typography
          sx={{
            px: "16px",
            color: "#101010",
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 500,
            mb: "16px",
          }}
        >
          Button Pressed action
        </Typography>
        <List sx={{ maxWidth: "380px" }}>
          <Grid container spacing={0} columns={2}>
            {registerActions
              .filter((action: any) => {
                // First check if action type is in excluded types
                if (excludedActionTypes.includes(action.type)) {
                  return false;
                }
                
                // Then apply the interactive-message-list specific filtering
                if (nodeType !== "interactive-message-list") return true;
                return (
                  nodeType === "interactive-message-list" &&
                  action.type !== "link" &&
                  action.type !== "mobile"
                );
              })
              .map((action, index) => 
                currentBot.channel !== null && currentBot.channel ? (
                  (action?.supports?.includes(
                    currentBot.channel ? currentBot.channel : ""
                  ) ||
                    nodeType !== "interactive-message") && (
                    <Grid item xs={1} key={`button-action-${index}`}>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{ px: "16px !important" }}
                          disabled={language !== "english"}
                          onClick={() => handleOptionClick(action)}
                        >
                          <InputOption
                            label={action.title}
                            color={action.color}
                            icon={action.icon}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Grid>
                  )
                ) : (
                  <Grid item xs={1} key={`button-action-${index}`}>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ px: "16px !important" }}
                        disabled={language !== "english"}
                        onClick={() => handleOptionClick(action)}
                      >
                        <InputOption
                          label={action.title}
                          color={action.color}
                          icon={action.icon}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Grid>
                )
              )}
          </Grid>
        </List>
      </>
      {selectedAction === null && (
        <Box sx={{ borderTop: "1px solid #F5F6F8", p: "16px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <UIButton variant="outlined" onClick={handleClose}>
              Cancel
            </UIButton>
            <UIButton
              color="error"
              variant="contained"
              startIcon={<Icon icon="delete" color="#fff" size={13} />}
              disabled={language !== "english"}
              onClick={handleDeleteButtonClick}
            >
              Delete
            </UIButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ButtonInputOptions;
