import React, { useState } from "react";
import { ReactComponent as SearchIcon } from "../../../assets/images/Searchicon.svg";
import { ReactComponent as ImportIcon } from "../../../assets/images/Import.svg";

import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { StyledAppInput } from "../../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import AppButton from "../../../AppComponents/AppButton";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  MenuItem,
} from "@mui/material";
import { StyledInput, StyledLabel, StyledSelect } from "../style";
import AppModel from "../../../Hooks/useModel/AppModel";
import { convertCurlToJson } from "./utils";

const TopMenuComponent = (props: any) => {
  const navigate = useNavigate();
  const [curlUrl, setCurlUrl] = useState<string>("");
  const [importModel, setImportModel] = useState<boolean>(false);

  const handleNavigations = () => {
    let curlObj;
    if (curlUrl) {
      curlObj = convertCurlToJson(curlUrl);
    }
    navigate("/api/library/create", { state: curlObj });
  };

  return (
    <div className="topmenu">
      <div
        className="menu-left"
        onClick={() => (props?.hideFilters ? navigate("/api/library") : null)}
      >
        {props?.hideFilters ? (
          <AppMaterialIcons
            iconName={"ArrowBackIosOutlined"}
            style={{ fontSize: "18px", marginRight: "-25px" }}
          />
        ) : null}
        API Library
      </div>
      {!props?.hideFilters ? (
        <div className="menu-right">
          <div className="faq-search" style={{ marginRight: "1rem" }}>
            <div className="faq-search" style={{ marginRight: "1rem" }}>
              <StyledAppInput>
                <input
                  type="text"
                  onChange={(e: any) =>
                    props?.handleSelectSearch(e.target.value)
                  }
                  name="query"
                  id="query"
                  placeholder="Search"
                />
                <SearchIcon />
              </StyledAppInput>
            </div>
          </div>
          <StyledSelect
            value={props?.method}
            onChange={props?.handleChangeMethod}
            displayEmpty
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FF5722", // Custom color for the border
              },
              ".Mui-selected": {
                color: "#FF5722", // Custom color for the selected text
              },
            }}
          >
            {/* Placeholder */}
            <MenuItem value="" disabled>
              Filter by method
            </MenuItem>

            {/* Options */}
            <MenuItem value="">ALL</MenuItem>
            <MenuItem value="GET">GET</MenuItem>
            <MenuItem value="POST">POST</MenuItem>
            <MenuItem value="PUT">PUT</MenuItem>
            <MenuItem value="DELETE">DELETE</MenuItem>
            <MenuItem value="PATCH">PATCH</MenuItem>
          </StyledSelect>

          <AppButton
            buttonStyle={{
              marginRight: "5px",
              padding: "14px 10px 14px 5px",
            }}
            onClick={() => setImportModel(true)}
          >
            <ImportIcon />
            Import API's
          </AppButton>
          <AppButton
            buttonStyle={{
              marginRight: "5px",
              padding: "14px 10px 14px 3px",
            }}
            onClick={handleNavigations}
          >
            <AppMaterialIcons iconName="add" />
            Create
          </AppButton>
        </div>
      ) : null}
      
      <AppModel
        style={{ width: "100%", padding: "0", borderRadius: "10px" }}
        onClose={() => setImportModel(false)}
        isShowing={importModel}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            padding: "10px 15px",
          }}
        >
          <StyledLabel>
            Insert CURL <sup style={{ color: "red" }}>*</sup>
          </StyledLabel>
        </Box>
        <Divider />
        <Box sx={{ padding: "25px 15px" }}>
          <StyledInput
            fullWidth
            multiline
            rows={6}
            placeholder="Paste your URL here..."
            value={curlUrl}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCurlUrl(e.target.value)
            }
            sx={{
              width: "500px",
              border: "1px solid #F5F6F8",
              borderRadius: "15px",
              backgroundColor: "#fff",
            }}
            required
          />
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px 15px",
          }}
        >
         <AppButton
            buttonStyle={{
              padding: "10px 30px",
            }}
            variant="outline"
            onClick={() => handleNavigations()}
          >
            Insert
          </AppButton>
        </Box>
      </AppModel>
    </div>
  );
};

export default TopMenuComponent;
