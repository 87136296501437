// const replaceVariables = (variableArray: any[], text: string) => {
//   return text.replace(/\{\{(\d+)\}\}/g, (_, index) => variableArray[parseInt(index) - 1] || '');
// };

const extractPlaceholdersWithBrackets = (str: string) => {
  const regex = /\{\{.*?\}\}/g;
  return str.match(regex) || [];
}
const convertFormat = (components: any[], category: any) => {
  const formattedComponents: any[] = [];
  // console.log("COMPONENTS", components)
  components.forEach((component: any) => {
    let formattedComponent: any = {};

    if (component.type === "BODY" || (component.type === "body" && Array.isArray(component.example?.body_text?.[0]))) {
      if (Array.isArray(component.example?.body_text?.[0])) {
        // formattedComponent = {
        //   type: "body",
        //   parameters: component.example.body_text[0].map((text: any) => ({
        //     type: "text",
        //     text: text
        //   }))
        // };
        // console.log("example", component.example)
        formattedComponent = {
          type: "body",
          parameters: [
            ...extractPlaceholdersWithBrackets(component.text).map((placeholder) => ({
              type: "text",
              text: "<BODY_VARIABLE_" + placeholder + ">"
            }))
          ]
        };

        console.log("FORMATED COMPONENT", formattedComponent)
      } else {
        return;
      }
    } else if (component.type === "HEADER" || component.type === "header") {
      formattedComponent = {
        type: "header",
        parameters: []
      };

      if (component.format === "IMAGE" || component.format === "image") {
        formattedComponent.parameters.push({
          type: "image",
          // image: { link: component.example?.header_handle?.[0] || "NO URL" }
          image: { link: "<PUBLIC_IMAGE_URL>" }
        });
      } else if (component.format === "DOCUMENT" || component.format === "document") {
        formattedComponent.parameters.push({
          type: "document",
          // document: { link: component.example?.header_handle?.[0] || "NO URL" }
          document: { link: "<PUBLIC_DOCUMENT_URL>" }
        });
      } else if (component.format === "VIDEO" || component.format === "video") {
        formattedComponent.parameters.push({
          type: "video",
          // video: { link: component.example?.header_handle?.[0] || "NO URL" }
          video: { link: "<PUBLIC_VIDEO_URL>" }
        });
      } else if (component.format === "TEXT" || component.format === "text") {
        extractPlaceholdersWithBrackets(component.text).forEach((placeholder) => {
          formattedComponent.parameters.push({
            type: "text",
            text: placeholder
          });
        });
        // formattedComponent.parameters.push({
        //   type: "text",
        //   text: component.example?.header_text?.[0]
        //     ? replaceVariables(component.example.header_text[0], component.text)
        //     : component.text
        // });
      }
    } else if (component.type === "BUTTONS" || component.type === "buttons") {
      // Handle multiple buttons
      const buttons = component.buttons.map((button: any, index: number) => {
        const buttonComponent: any = {
          type: "button",
          sub_type: button.type.toLowerCase(),
          index: 0,

        };

        if (button.type === "URL" || button.type === "url") {
          buttonComponent.parameters = [];
          if (category == "AUTHENTICATION") {
            buttonComponent.parameters = [
              {
                type: "text",
                text: "XYXY"
              }
            ]
          }
          extractPlaceholdersWithBrackets(button.url).forEach((placeholder) => {
            buttonComponent.parameters.push({
              type: "text",
              text: placeholder
            });
          });
          console.log("BTN URL", buttonComponent)
          buttonComponent.index = index;
        } else if (button.type === "PHONE_NUMBER" || button.type === "phone_number") {
          buttonComponent.index = index;
        } else if (button.type === "COPY_CODE" || button.type === "copy_code") {
          buttonComponent.parameters = [{
            type: "coupon_code",
            coupon_code: button.example[0]
          }];

          buttonComponent.index = 0;
        } else if (button.type === "OTP" || button.type === "otp") {
          buttonComponent.sub_type = "otp";
          buttonComponent.otp_type = button.otp_type;
          // buttonComponent.text = button.text;
          buttonComponent.index = 4;
        } else if (button.type === "CATALOG" || button.type === "catalog") {
          // buttonComponent.text = button.text;
          buttonComponent.index = 5;
        } else if (button.type === "QUICK_REPLY" || button.type === "quick_reply") {
          // buttonComponent.text = button.text;
          buttonComponent.index = index;
        }

        return buttonComponent;
      });

      // Add each button as a separate component in the formattedComponents array
      buttons.forEach((button: any) => formattedComponents.push(button));

      // Skip the default push at the end of the loop
      return;
    } else if (component.type === "limited_time_offer" || component.type === "LIMITED_TIME_OFFER") {
      formattedComponent = {
        type: "limited_time_offer",
        parameters: [{
          type: "limited_time_offer",
          limited_time_offer: {
            expiration_time_ms: component.limited_time_offer?.has_expiration
              ? Date.now() + 60 * 60 * 1000
              : undefined,
            text: component.limited_time_offer?.text || "Limited time offer"
          }
        }]
      };
    } else if (component.type === "CAROUSEL" || component.type === "carousel") {
      formattedComponent = {
        type: "carousel",
        cards: component.cards.map((card: any, index: number) => ({
          card_index: index,
          components: convertFormat(card.components, category).formattedComponents
        }))
      };

      component.cards.forEach((card: any) => {

      })
    } else if (component.type === "FOOTER" || component.type === "footer") {
      return;
    }
    formattedComponents.push(formattedComponent);
  });
  return {
    formattedComponents
  };
};


export default convertFormat;