import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Drawer, IconButton } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import {
  SettingPageMainBoxBtnBox,
  SettingPageMainBoxTitle,
  SettingPageMainBoxTopbar,
} from "../../../Customization/CommonElements";
import { theme } from "../../../Customization/Theme";
import { PreviewType } from "../../../Models";
import { useBotTrackingCodeStore } from "../../../Services/Apis";
import { useAppSelector } from "../../../Store";

interface Props {
  isBotPreviewOpen: boolean;
  setIsBotPreviewOpen: (value: React.SetStateAction<boolean>) => void;
  handleClosePreviewBot: () => void;
}

const WebBotLivePreview: React.FC<Props> = ({
  isBotPreviewOpen,
  handleClosePreviewBot,
}) => {
  const IFRAMEURL: string =
    process.env.REACT_APP_IFRAME_SCRIPT_BASE_URL || "http://localhost:8081";
  // const currentBot = useSelector(useCurrentBot);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { data: trackingCode } = useAppSelector(useBotTrackingCodeStore);

  // Unique script & iframe IDs to prevent conflicts
  const scriptId = `getaScript_${trackingCode?.bot_id}_preview_console`;
  const iframeId = `geta_${trackingCode?.bot_id}`;
  const modifedIframeId = `geta_${trackingCode?.bot_id}_preview_console`;
  const iframeClass = `geta-iframe-wrapper-preview-console`;

  const addPreviewScript = () => {
    if (!trackingCode?.bot_id || !trackingCode?.geta_host) return;
  
    if (document.getElementById(scriptId) || document.getElementById(modifedIframeId)) {
      return; // Prevent duplicates
    }
  
    const script = document.createElement("script");
    script.id = scriptId;
    script.async = true;
    script.innerHTML = `
      (function(w, d, s) {
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s);
        j.async = true;
  
        j.onload = function() {
          var geta = window.GetA('${trackingCode?.geta_host}', ${trackingCode?.matomo_site_id}, "${trackingCode?.bot_id}", "${PreviewType.getaWidgetPreview}");
          
          setTimeout(() => {
            var iframe = document.getElementById("${iframeId}");
            if (iframe) {
              iframe.id = "${modifedIframeId}"; // Set the correct ID at creation
              iframe.classList.add("${iframeClass}");
            }
          }, 1000); // Ensures iframe is available before adding class
        };
  
        j.src = '${IFRAMEURL}/output.js';
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script');
    `;
  
    document.body.appendChild(script);
  };

  const removePreviewScript = () => {
    const script = document.getElementById(scriptId);
    script?.parentNode?.removeChild(script);

    const iframe = document.getElementById(modifedIframeId);
    iframe?.parentNode?.removeChild(iframe);
  };

  useEffect(() => {
    if (isBotPreviewOpen) {
      setIsLoading(true);
      addPreviewScript();
      setTimeout(() => setIsLoading(false), 2000);
    } else {
      removePreviewScript();
    }

    return () => {
      removePreviewScript();
    };
  }, [isBotPreviewOpen, trackingCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Drawer
      anchor={"right"}
      open={isBotPreviewOpen}
      onClose={() => handleClosePreviewBot()}
      hideBackdrop={false}
    >
      <SettingPageMainBoxTopbar
        style={{
          backgroundColor: theme.palette.default.white,
          height: "68px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
        }}
      >
        <SettingPageMainBoxTitle style={{ width: "100%" }}>
          Web Bot Preview
        </SettingPageMainBoxTitle>
        <SettingPageMainBoxBtnBox>
          <IconButton onClick={() => handleClosePreviewBot()}>
            <CloseIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </SettingPageMainBoxBtnBox>
      </SettingPageMainBoxTopbar>
      <PreviewWrapBox>
        {isLoading ? <CircularProgress color="inherit" /> : null}
        {(isLoading || !trackingCode?.bot_id || !trackingCode?.geta_host) && (
          <h4 style={{ fontWeight: "500 !important", textAlign: "center" }}>
            Please wait while <br /> we fetch the bot script
          </h4>
        )}
        {(!trackingCode?.bot_id || !trackingCode?.geta_host) && !isLoading && (
          <h4 style={{ fontWeight: "500 !important" }}>
            Bot script not found
          </h4>
        )}
      </PreviewWrapBox>
    </Drawer>
  );
};

export default memo(WebBotLivePreview);

const PreviewWrapBox = styled.div`
  && {
    width: 470px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    background-color: ${theme.palette.secondary.main};
  }
`;
