import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Typography,
  Box,
  Modal,
  CircularProgress,
  Grid,
  Paper,
  IconButton,
  Menu,
  ListItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FolderIcon from "@mui/icons-material/Folder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { StyledAppInput } from "../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import { ApiLibraryWrap, StyledSelect } from "../ApiLibrary/style";
import { TableFilter } from "../../TableStyle";
import * as Yup from "yup";
import { FormAction } from "../../Store/Slices/FormBuilder/formbuilder.actions";
import { useAppDispatch } from "../../Store";
import CreateBoardModal from "./CreateBoardModal";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import { useTheme } from "@mui/material/styles";
import AppModel from "../../Hooks/useModel/AppModel";
import { AppForm, AppFormField } from "../../AppComponents/Forms";
import { DeleteCannedResponse } from "../FormBuilder";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { filemanagerAction } from "../../Store/Slices/filemanagers/fileManagerAction";
import { ActionPopover } from "../Broadcast/Email/Template/EmailTemplateListingStyle";
import { Search } from "@material-ui/icons";
import { ProtectedAppButton } from "../../AppComponents/ProtectedComponents";
import AppSkeletonTableLoader from "../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
interface FolderData {
  can_delete: boolean;
  created_at: string;
  id: string;
  label: string;
  parent_id: string;
  type: string;
  updated_at: string;
  workspace_id: string;
}
interface FolderResponse {
  data: FolderData[];
}
const FileManagerHeader = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [boardName, setBoardName] = useState("");
  const [folders, setFolders] = useState<FolderData[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("date");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFolder, setSelectedFolder] = useState<FolderData | null>(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [pagination, setPagination] = useState<any>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState<FolderData | null>(null);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [folderToRename, setFolderToRename] = useState<FolderData | null>(null);
  const [newFolderName, setNewFolderName] = useState("");
  const [error, setError] = useState("");
  const formNameValidationSchema = Yup.object().shape({
    boardName: Yup.string()
      .required("Board name is required")
      .min(2, "Board name must be at least 2 characters")
      .max(50, "Board name must be less than 50 characters"),
  });
  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    folder: FolderData
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedFolder(folder);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedFolder(null);
  };
  const handleFolderClick = (folder: FolderData) => {
    navigate(`/board-upload/${folder.id}`, {
      state: {
        folderName: folder.label,
        id: folder.id,
      },
    });
  };
  const openRenameModal = () => {
    if (selectedFolder) {
      setFolderToRename(selectedFolder);
      // setNewFolderName(selectedFolder.label); // Prefill with current name
      setRenameModalOpen(true);
      handleMenuClose();
    }
  };
  const closeRenameModal = () => {
    setRenameModalOpen(false);
    setFolderToRename(null);
    setNewFolderName("");
    setError("");
  };
  const handleRenameFolder = (newName: string) => {
    if (folderToRename && folderToRename.id) {
      const payload = {
        // "folder_name": folderToRename.label,
        new_name: newName,
        folder_id: folderToRename.id,
      };
      const data = {
        data: payload,
        folder_name: folderToRename.label,
        new_name: newName,
        folder_id: folderToRename.id,
      };
      dispatch(filemanagerAction.RenameFolderAction(data))
        .then((res: any) => {
          showAlert("folder  Rename successfully", "success");
          fetchFolders();
          if (res.payload?.status === 200 || res.payload?.status === 204) {
            closeRenameModal();
          } else {
            showAlert("Failed to rename folder. Please try again.", "error");
          }
        })
        .catch((err: any) => {
          setError("Failed to rename folder. Please try again.");
        });
    }
  };
  const openDeleteModal = () => {
    if (selectedFolder) {
      setFolderToDelete(selectedFolder);
      setDeleteModalOpen(true);
      handleMenuClose();
    }
  };
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setFolderToDelete(null);
  };
  const handleDeleteFolder = () => {
    if (folderToDelete && folderToDelete.id) {
      dispatch(filemanagerAction.deleteFolderaction(folderToDelete.id))
        .then((res: any) => {
          setPagination(res?.payload?.data?.pagination);

          if (res.payload?.status === 200 || res.payload?.status === 204) {
            fetchFolders();
            showAlert("Folder deleted successfully", "success");
          } else if (res?.payload?.data?.message === "Delete files first") {
            showAlert(
              "Please delete all files inside the folder before deleting it.",
              "warning"
            );
          } else {
            showAlert("Failed to delete folder. Please try again.", "error");
          }
        })
        .catch((err: any) => {
          showAlert("Failed to delete folder. Please try again.", "error");
        })
        .finally(() => {
          closeDeleteModal();
        });
    }
  };
  const [localValues, setLocalValues] = useState<any>({
    search: "",
    itemsPerPage: { label: "10  per page", value: 10 },
    currentPage: 0,
    totalPages: 0,
  });
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (event: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: event,
      currentPage: 0,
    });
  };
  const fetchFolders = () => {
    setLoading(true);
    let payload: any = {
      limit: localValues.itemsPerPage?.value,
      offset: localValues.search.length > 0 ? 0 : localValues.currentPage,
      search: localValues?.search || searchQuery,
      sort_by: sortBy,
    };
    dispatch(filemanagerAction.GetFolderListing(payload))
      .then((res: any) => {
        setPagination(res?.payload?.data?.pagination);
        if (res.payload?.data?.data && Array.isArray(res.payload.data.data)) {
          const mappedFolders = res.payload.data.data.map((folder: any) => ({
            ...folder,
            label:
              folder.label || folder.name || folder.title || "Unnamed Folder",
          }));
          setFolders(mappedFolders);
        } else {
          setFolders([]);
        }
      })
      .catch((err: any) => {
        setFolders([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const delaySearch = setTimeout(() => {
      fetchFolders();
    }, 500);
    return () => clearTimeout(delaySearch);
  }, [
    localValues?.currentPage,
    localValues?.itemsPerPage,
    searchQuery,
    sortBy,
  ]);
  const filteredFolders = folders
    .filter((folder) =>
      folder.label.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (sortBy === "name") {
        return a.label.localeCompare(b.label);
      } else if (sortBy === "date") {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      }
      return 0;
    });

  return (
    <>
      <FileManagerWrap>
        <div className="topmenu">
          <Box className="flex flex-col space-y-1">
            <div className="menu-left">Manage Assets</div>
            <Typography variant="body2" style={{ fontSize: "13px" }}>
              Manage your files and reuse them anytime, anywhere!
            </Typography>
          </Box>
          <div className="menu-right">
            <div className="faq-search" style={{ marginRight: "1rem" }}>
              <StyledAppInput>
                <input
                  type="text"
                  name="query"
                  id="query"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <SearchIcon />
              </StyledAppInput>
            </div>

            <TableFilter style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <div
                className="filter-left"
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Typography fontSize="13px" variant="h6">
                  Sort By
                </Typography>
                <StyledSelect
                  sx={{ width: "150px", height: "30px" }}
                  value={sortBy}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => setSortBy(e.target.value)}
                >
                  <MenuItem value="name">Name</MenuItem>
                  <MenuItem value="date">Date</MenuItem>
                </StyledSelect>
              </div>
            </TableFilter>
            <ProtectedAppButton
              variant="primarydark"
              moduleId="file_manager"
              specificPermissionKey="create"
              style={{ width: "200px" }}
              title={"Create Board"}
              startIcon="AppMaterialIcons"
              onClick={handleOpen}
              buttonStyle={{ marginRight: "5px", padding: "14px 10px" }}
            >
              Create Board
            </ProtectedAppButton>
          </div>
        </div>
        {/* Folder Content Area */}
        <Box
          sx={{
            flexGrow: 1,
            mt: 3,
            px: 2,
            display: "flex",
            p: 4,
            bgcolor: "background.paper",
            borderRadius: 1,
            marginX: "20px",
            marginBottom: "10px",
            minHeight: "calc(100vh - 200px)",
            overflow: "hidden",
          }}
        >
          {loading ? (
            // <Box sx={{ display: "flex", justifyContent: "center",alignItems:"center", my: 4 }}>
            //   <CircularProgress />
            // </Box>
            <AppSkeletonTableLoader
              numberOfCol={4}
              numberofRow={10}
              skeletonHeight={30}
              skeletonWidth={"100%"}
              skeletonHeaderHeight={40}
              skeletonHeaderWidth={"100%"}
              tableHeight={`calc(100vh - 200px)`}
            />
          ) : (
            <Grid container spacing={3}>
              {filteredFolders.map((folder) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={folder.id}>
                  <Paper
                    sx={{
                      borderRadius: "12px",
                      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                      overflow: "hidden",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      minHeight:"200px",
                      maxHeight:"200px",
                      maxWidth:"350px",
                      minWidth:"350px",
                      "&:hover": {
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
                        transform: "translateY(-4px)",
                      },
                    }}
                    onClick={() => handleFolderClick(folder)}
                  >
                    {/* Folder Header with Title and Menu */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "12px 16px",
                        borderBottom: "1px solid #f0f0f0",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          component="span"
                          sx={{
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "24px",
                            height: "24px",
                            borderRadius: "4px",
                            backgroundColor: "#FFE5E5",
                            marginRight: "8px",
                          }}
                        >
                          <Typography fontSize="14px" color="grey">
                            {folder.label.charAt(0)}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          title={folder.label}
                        >
                          {folder.label}
                        </Typography>
                      </Box>

                      {folder.can_delete && (
                        <IconButton
                          size="small"
                          onClick={(e) => handleMenuOpen(e, folder)}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "30px 0",
                      }}
                    >
                      <FolderIcon
                        sx={{
                          fontSize: "2rem",
                          width: "100px",
                          height: "100px",
                          color: "text.secondary",
                        }}
                      />
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        {/* Folder Options Menu */}
        <Box
          display="flex"
          sx={{
            maxWidth: "100px",
            minWidth: "100px",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
          className="actionColumn"
        >
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem value="" disabled>
              Action
            </MenuItem>

            <ListItem
              sx={{
                color: theme.palette.default.darkGrey,
                cursor: "pointer",
              }}
              onClick={openRenameModal}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <AppMaterialIcons iconName="EditOutlined" />
                <p>Rename</p>
              </div>
            </ListItem>

            {selectedFolder?.can_delete && (
              <ListItem
                sx={{
                  color: theme.palette.default.darkGrey,
                  cursor: "pointer",
                }}
                onClick={openDeleteModal}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                  <p>Delete</p>
                </div>
              </ListItem>
            )}
          </Menu>
        </Box>

        {/* Create Board Modal */}
        <CreateBoardModal
          open={open}
          handleClose={handleClose}
          boardName={boardName}
          setBoardName={setBoardName}
          error={error}
          fetchFolders={fetchFolders}
        />

        {/* Rename Folder Modal */}
        <AppModel
          isShowing={renameModalOpen}
          onClose={closeRenameModal}
          divStyle={{
            padding: 0,
          }}
          PaperProps={{
            style: {
              width: "450px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          }}
        >
          <AppForm
            initialValues={{ boardName: folderToRename?.label || "" }}
            onSubmit={(values: any) => {
              handleRenameFolder(values.boardName);
            }}
            validationSchema={formNameValidationSchema}
          >
            <StyledModelHeader>
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                  Rename Board
                </Typography>
              </Box>
            </StyledModelHeader>
            <AppFormModalContainer direction="column">
              <GridContainer>
                <AppFormField
                  name="boardName"
                  errorMessage={error}
                  label="Board Name"
                  placeholder="Please Enter Board Name"
                  defaultBorder={true}
                  labelStyle={{ color: theme.palette.default?.newFontColor }}
                />
              </GridContainer>
              <div className="saveButton">
                <AppButton variant="grey" onClick={closeRenameModal}>
                  Cancel
                </AppButton>
                <ProtectedAppButton
                  variant="default"
                  moduleId="file_manager"
                  specificPermissionKey="update"
                  title={"Rename folder"}
                  style={{ width: "100px" }}
                  startIcon="AppMaterialIcons"
                  type="submit"
                >
                  Save
                </ProtectedAppButton>
              </div>
            </AppFormModalContainer>
          </AppForm>
        </AppModel>

        {/* Delete Confirmation Modal */}
        <AppModel
          isShowing={deleteModalOpen}
          onClose={closeDeleteModal}
          divStyle={{
            padding: 0,
          }}
          PaperProps={{
            style: {
              width: "450px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          }}
        >
          <DeleteCannedResponse>
            <div className="header">
              <div className="header-left">
                <div className="danger">{/* Icon could be placed here */}</div>
                <div className="text">
                  <h4>Delete Board</h4>
                  <p>Are you sure, you want to delete?</p>
                </div>
              </div>

              <div className="header-right" onClick={closeDeleteModal}>
                <AppMaterialIcons iconName="close" />
              </div>
            </div>
            <div className="footer">
              <AppButton variant="grey" onClick={closeDeleteModal}>
                Cancel
              </AppButton>
              <ProtectedAppButton
                variant="danger-filled"
                moduleId="file_manager"
                specificPermissionKey="delete"
                title={"Delete folder"}
                startIcon="AppMaterialIcons"
                onClick={handleDeleteFolder}
              >
                {" "}
                <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                Delete
              </ProtectedAppButton>
            </div>
          </DeleteCannedResponse>
        </AppModel>
        {!loading && folders.length > 0 ? (
          <AppPaginationComponent
            totalCount={pagination?.totalRecords}
            handlePageChange={handlePageChange}
            currentPage={localValues?.currentPage}
            totalPages={pagination?.totalPages}
            rowsPerPage={localValues?.itemsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            title="record"
          />
        ) : null}
      </FileManagerWrap>
    </>
  );
};

export default FileManagerHeader;

const StyledModelHeader = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    height: 56px;
    min-height: 56px;
    align-items: center;
    padding: 0rem 1rem 0rem 1rem;
    position: sticky;
    background-color: ${theme.palette.default.background};
    top: 0;
    z-index: 9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

    h6 {
      font-size: 1.05rem;
      color: ${theme.palette.default.text};
    }

    svg {
      color: ${theme.palette.default.darkGrey};
    }
  }
`;

const AppFormModalContainer = styled.div`
  && {
    display: flex;
    flex-direction: ${(props: { direction: string }) => props.direction};
    justify-content: start;
    padding: 16px;
    gap: 10px;
    .saveButton {
      width: 100%;
      display: flex;
      justify-content: end;
      gap: 20px;
    }
  }
`;

const GridContainer = styled.div`
  && {
    display: flex;
    gap: 10px;
    padding: 10px 0 10px 0;
  }
`;
const FileManagerWrap = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  height: 100%;

  .topmenu {
    padding: 18px 22px;
    background: ${theme.palette.default.white};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 61px;
  }

  .menu-left {
    display: flex;
    align-items: center;
    gap: 2rem;
    font-weight: 500;
    color: ${theme.palette.default.black};
    font-size: 20px;
  }

  .custom-btn {
    padding: 0;
  }

  .left-menu {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .left-part {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .menu-right {
    display: flex;
    align-items: center;
  }

  .edit-conditions {
    border: 1px solid ${theme.palette.default.BorderColorGrey};
  }

  .edit_text {
    font-size: 14px;
  }

  .AllFilterLogo {
    padding-right: 5px;
  }

  .data-container {
    background: ${theme.palette.default.white};
    margin: 1rem;
  }

  .image {
    padding-top: 24px;
    padding-left: 3rem;
  }

  .norecord {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
  }

  .data-details {
    display: flex;
    justify-content: center;
    padding-top: 120px;
    gap: 72px;
  }

  .data-details p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.darkGrey};
  }

  .Import-details {
    width: 640px;
  }

  .Import-details button {
    width: 148px;
  }

  .Import-details li {
    margin-bottom: 1rem;
  }

  .Import-details p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: ${theme.palette.default.black};
  }

  .button {
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: rgb(54, 84, 227);
    color: white !important;
  }

  .norecord-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .norecord-data p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: ${theme.palette.default.darkGrey};
    text-transform: capitalize;
  }

  .add-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    gap: 2rem;
  }

  .add-list button {
    width: 148px;
  }

  .add-list p {
    width: 1115px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: ${theme.palette.default.black};
  }

  .parent_Drawer {
    width: 700px;
  }
`;
