import { createAsyncThunk } from "@reduxjs/toolkit";
import { APILibraryNetworkService } from "./apilibraryNetworkService";

export class ApiLibraryNetworkActions {
  //* CREATE MARKETING LIST  //
  static getApiList = createAsyncThunk(
    "broadcastSlice/getApiList",
    (data: any, { dispatch, rejectWithValue }) => {
      return APILibraryNetworkService.getApiList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static getContactProperties = createAsyncThunk(
    "broadcastSlice/getContactProperties",
    (data: any, { dispatch, rejectWithValue }) => {
      return APILibraryNetworkService.getContactProperties()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static getApiListOption = createAsyncThunk(
    "broadcastSlice/getApiListOption",
    (data: any, { dispatch, rejectWithValue }) => {
      return APILibraryNetworkService.getApiListOption()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
}
