// vendors
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import ReactPlayer from "react-player";

// components
import Icon from "../../../Components/Icon";
import Input from "../../../Components/Input";
import MediaUploadPlaceholder from "../../../Components/MediaPlaceholder";
import UISelect from "../../../Components/Select";

// helpers
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import {
  getCorrectMediaFormats,
  getCorrectMediaSize,
  getInfoMessage,
  getMediaInfo,
  headerOptions,
} from "./helper";
import { useEffect } from "react";
import { getProcessedLength } from "../../../Utils/editor";

type Header = {
  id?: string;
  link?: string;
  type?: string;
  filename?: string;
  text?: string;
};
type Errors = {
  header: {
    textError: boolean;
    uploadError: boolean;
  };
};
type InteractiveMessageHeaderProps = {
  characterLimit: number;
  data: {
    id?: string;
    link?: string;
    type: string;
    filename?: string;
    text?: string;
    value?: string;
  };
  isList?: boolean;
  notAllow ? :any;
  onChange: (data: any) => void;
};

const InteractiveMessageHeader = ({
  characterLimit,
  data,
  onChange,
  isList,
  notAllow
}: InteractiveMessageHeaderProps) => {
  const [headerType, updateHeaderType] = useState(
    (data?.type || data?.value) || headerOptions[0].value
  );

  const [headerSelectOptions, setHeaderSelectOptions] =
    useState<any>(headerOptions);
    const [componentErrors, updateComponentErrors] = useState<Errors>({
      header: {
        textError: false,
        uploadError: false,
      },
    });
        
  const [header, updateHeader] = useState<Header>(data || {});
  const [uploadError, updateUploadError] = useState("");
  const [uploadedData, updateUploadedData] = useState<any>(data || {});
  const [showLoader, updateLoaderVisibility] = useState(false);
  const { showAlert } = useNotification();
  const [isRequired, setIsRequired] = useState(true);

  useEffect(() => {
    setIsRequired(headerType !== "none");
  }, [headerType]);

  useEffect(() => {
    if (data?.type) {
      updateHeaderType(data.type);
      updateHeader({});
      updateUploadedData(data || {});
    }
  }, [data]);
  

  const handleHeaderOptionChange = (event: any) => {    
    updateUploadedData({ type: event.target.value });
    updateHeaderType(event.target.value);
    onChange(event.target);
  };

  const handleHeaderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (getProcessedLength(value) > characterLimit) return;
    const data = getMediaInfo(value, headerType);
    updateHeader(data);
    onChange(data);
  };

  const handleUploadClick = (event: any) => {
    let file = event.target.files[0];

    const reader = new FileReader();
    if (!reader || !file) {
      return;
    }
    reader?.readAsDataURL(file);
    const allowedMediaSize = getCorrectMediaSize(headerType);
    if (allowedMediaSize && file.size > allowedMediaSize) {
      setTimeout(() => {
        updateUploadError(
          `${headerType} size must be smaller than ${
            allowedMediaSize / 1000000
          } MB`,
        );
      }, 1);
      return;
    }

    reader.onloadend = (e: any) => {
      var mimeType = e?.target?.result
        ?.split(",")[0]
        .split(":")[1]
        .split(";")[0];

      if (!getCorrectMediaFormats(headerType).includes(mimeType)) {
        setTimeout(() => {
          updateUploadError("Invalid File format");
        }, 1);
        return;
      }
      onMediaChange(file);
    };
  };

  const onMediaChange = (file: any) => {
    if (file) {
      updateLoaderVisibility(true);
      const formData = new FormData();
      formData.append("source", file);
      ChatbotConsoleService.uploadWhatsappMedia(formData, true)
        .then((response: any) => {
          updateUploadedData(getMediaInfo(response?.data, headerType));
          showAlert("Media uploaded", "success");
          updateUploadError("");
          updateComponentErrors({
            header: {
              ...componentErrors.header,
              uploadError: false
            }
          });
          onChange(getMediaInfo(response?.data, headerType));
        })
        .catch((error: any) => {
          showAlert("Media upload failed", "error");
        })
        .finally(() => {
          setTimeout(() => {
            updateLoaderVisibility(false);
          }, 1000);
        });
    }
  };

  const handleCancelClick = () => {
    updateUploadedData({});
    // Set upload error when media is removed
    updateComponentErrors({
      header: {
        ...componentErrors.header,
        uploadError: true
      }
    });
  };

  const CloseButton = ({ onClick }: any) => {
    return (
      <IconButton
        sx={{
          height: "24px",
          width: "24px",
          backgroundColor: "#fff",
          borderRadius: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ml: "auto",
        }}
        onClick={onClick}
      >
        <Icon icon={"close"} size={14} />
      </IconButton>
    );
  };
  const validateInput = () => {
    // Removed validation for header type none...
    if (headerType === "none") {
      updateComponentErrors({
        header: {
          textError: false,
          uploadError: false,
        },
      });
      return;
    }
  
    // For text type
    if (headerType === "text") {
      updateComponentErrors({
        header: {
          ...componentErrors.header,
          textError: !header?.text || header?.text?.trim() === ""
        },
      });
    } 
    // For media types (image, video, document)
    else if (["image", "video", "document"].includes(headerType)) {
      updateComponentErrors({
        header: {
          ...componentErrors.header,
          uploadError: !uploadedData?.link
        },
      });
    }
  };
  useEffect(() => {
    validateInput();
    if (isList) {
      setHeaderSelectOptions([
        {
          label: "None",
          value: "none",
        },
        {
          label: "Text",
          value: "text",
        },
      ]);
    }
  }, [headerType]);

  useEffect(() => {
    validateInput();
  }, [headerType]);

  return (
    <>
      <UISelect
        value={headerType}
        options={headerSelectOptions}
        onChange={handleHeaderOptionChange}
        isDisabled={false}
      />
      {headerType === "text" && !notAllow?.includes(headerType) && (
        <FormControl variant="standard" fullWidth sx={{ mb: "16px" }}>
          <Input
            id="header"
            placeholder="Header"
            onChange={handleHeaderChange}
            value={header?.text}
            onBlur={validateInput}
            required = {isRequired}
            error={componentErrors.header.textError}
            {...{
              helperText: characterLimit ? (
                <Typography variant="body2" align="right">
                  {`${
                    header?.text ? getProcessedLength(header?.text) : 0
                  }/${characterLimit}`}
                </Typography>
              ) : (
                ""
              ),
            }}
          />
        </FormControl>
      )}
      {(headerType === "image" ||
        headerType === "video" ||
        headerType === "document") && (
        <Box sx={{ mb: "16px" }}>
          {!uploadedData?.link ? (
            <MediaUploadPlaceholder error={componentErrors.header.uploadError}>
              <label
                htmlFor={`${headerType}-upload`}
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Icon icon={headerType} size={24} color="#5B73E8" />
                <Typography px={"20px"} py={"10px"} align="center">
                  {getInfoMessage(headerType)}
                </Typography>
                {showLoader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <div className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-disableElevation MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-disableElevation css-1wgego7-MuiButtonBase-root-MuiButton-root">
                    Browse
                    <input
                      hidden
                      id={`${headerType}-upload`}
                      name={`${headerType}-upload`}
                      required = {isRequired}
                      accept={getCorrectMediaFormats(headerType).toString()}
                      type="file"
                      onChange={handleUploadClick}
                    />
                  </div>
                )}
              </label>
            </MediaUploadPlaceholder>
          ) : (
            <>
              {headerType === "image" && (
                <Box sx={{ position: "relative" }}>
                  <Box
                    component="img"
                    src={uploadedData.link}
                    sx={{ width: "100%", height: "150px", objectFit: "cover" }}
                  />
                  <Box
                    sx={{ position: "absolute", right: "-8px", top: "-8px" }}
                  >
                    <CloseButton onClick={handleCancelClick} />
                  </Box>
                </Box>
              )}
              {headerType === "video" && (
                <Box sx={{ position: "relative" }}>
                  <ReactPlayer
                    url={uploadedData.link}
                    width={"100%"}
                    height="150px"
                    controls={true}
                  />
                  <Box
                    sx={{ position: "absolute", right: "-8px", top: "-8px" }}
                  >
                    <CloseButton onClick={handleCancelClick} />
                  </Box>
                </Box>
              )}
              {headerType === "document" && (
                <Box
                  sx={{
                    backgroundColor: "#F5F6F8",
                    p: "16px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Icon icon={headerType} size={24} color="#5B73E8" />
                  <Typography
                    color={"#5B73E8"}
                    variant="body1"
                    component={"a"}
                    href={uploadedData.link}
                    target="_blank"
                    sx={{ textDecoration: "none" }}
                  >
                    {uploadedData?.filename?.substring(
                      uploadedData?.filename?.indexOf("_") + 1,
                    )}
                  </Typography>
                  <CloseButton onClick={handleCancelClick} />
                </Box>
              )}
            </>
          )}
          {uploadError && !uploadedData?.link && (
            <p style={{ color: "red", textTransform: "capitalize" }}>
              {uploadError}
            </p>
          )}
        </Box>
      )}
    </>
  );
};
export default InteractiveMessageHeader;
