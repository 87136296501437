import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";

// Styled components for the divider and text
const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 16px 0;
`;

const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: #ddd;
`;

const OrText = styled.span`
  margin: 0 8px;
  font-size: 14px;
  color: ${theme.palette.default.darkGrey};
  font-weight: 500;
`;

interface DividerWithTextProps {
  text?: string; // Text to display, defaults to "OR"
}

const DividerWithText: React.FC<DividerWithTextProps> = ({ text = "OR" }) => {
  return (
    <DividerWrapper>
      <Line />
      <OrText>{text}</OrText>
      <Line />
    </DividerWrapper>
  );
};

export default DividerWithText;
