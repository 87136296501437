import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import uniqid from "uniqid";
import { theme } from "../../../../../../Customization/Theme";
import { getLastUrlSegment } from "../../../../../../Utils";
import { useEffect } from "react";

const VoiceBotKnowledge = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const voiceBotKnowledgeOptions = [
    {
      name: "Website",
      url: "website",
      isAvailable: true,
      id: uniqid(),
      value: "website",
    },
    {
      name: "Sitemap",
      url: "sitemap",
      isAvailable: true,
      id: uniqid(),
      value: "sitemap",
    },
    {
      name: "Document",
      url: "document",
      isAvailable: true,
      id: uniqid(),
      value: "document",
    },
    {
      name: "Text",
      url: "text",
      isAvailable: true,
      id: uniqid(),
      value: "text",
    },
  ];
  useEffect(() => {
    if (getLastUrlSegment(location?.pathname) === "voice-bot-knowledge") {
      navigate("website");
    }
  }, []);
  return (
    <Container>
      <LeftSection>
        {voiceBotKnowledgeOptions.map((option, index: number) => (
          <SettingContainer
            key={index}
            active={getLastUrlSegment(location?.pathname) === option.url}
            onClick={() => navigate(option.url)}
          >
            <span className="label">{option.name}</span>
          </SettingContainer>
        ))}
      </LeftSection>
      <RightSection>
        <Outlet />
      </RightSection>
    </Container>
  );
};
const Container = styled.div`
  && {
    margin: 0.5rem;
    display: flex;
    height: calc(100vh - 250px);
    width: 100%;
  }
`;
const LeftSection = styled.div`
  && {
    width: 15%;
    background: ${theme.palette.default.white};
    overflow-y: auto;
  }
`;

const SettingContainer = styled.div`
&& {
    display:flex;
    align-items: center;
    padding: 0.8rem 0 0.8rem 1rem;
    font-size:16px;
    gap: 16px;
    cursor: pointer;
    color: ${(props: { active: boolean }) =>
      props.active
        ? theme.palette.primary.light
        : theme.palette.default.darkGrey};
    background ${(props: { active: boolean }) =>
      props.active ? "rgba(91, 115, 232, 0.1)" : theme.palette.default.white};
  .label{
    font-size: 14px;
    color:#000;
  }
}`;

const RightSection = styled.div`
  && {
    width: 85%;
    padding: 1rem;
    overflow-y: auto;
  }
`;

export default VoiceBotKnowledge;
