import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import BarChartIcon from "@mui/icons-material/BarChart";
import EventIcon from "@mui/icons-material/Event";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { theme } from "../../../../Customization/Theme";

interface SidebarProps {
    activeSection: string;
    onSelect: (section: string) => void;
}

const ContactDetailsDrawerSidebar: React.FC<SidebarProps> = ({ activeSection, onSelect }) => {
    const sidebarItems = [
        {
            id: "basic-information",
            label: "Basic Info",
            icon: <InfoIcon />,
            tooltip: "Basic Information"
        },
        {
            id: "communication-stats",
            label: "Communication",
            icon: <BarChartIcon />,
            tooltip: "Communication Stats"
        },
        {
            id: "activities",
            label: "Activities",
            icon: <EventIcon />,
            tooltip: "Activities"
        },
        {
            id: "files",
            label: "Files",
            icon: <AttachFileIcon />,
            tooltip: "Files"
        }
    ];

    return (
        <div className="sidebar" style={styles.sidebar}>
            {sidebarItems.map((item) => (
                <Tooltip 
                    key={item?.id}
                    title={item.tooltip}
                    placement="right"
                    arrow
                >
                    <div
                        style={{
                            ...styles.sidebarItem,
                            ...(activeSection === item.id && styles.activeItem),
                        }}
                        onClick={() => onSelect(item.id)}
                    >
                        <IconButton color="inherit">
                            {item.icon}
                        </IconButton>
                    </div>
                </Tooltip>
            ))}
        </div>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    drawer: {
        display: "flex",
        height: "100%",
        width: "100%",
    },
    sidebar: {
        width: "70px",
        borderRight: `1px solid ${theme.palette.default.lightBorder}`,
        padding: "0.6rem",
        display: "flex",
        flexDirection: "column",
        alignItems:"center",
        gap: "1rem",
        height:"100%",
        backgroundColor: theme.palette.default.paleBlue,
        color:theme.palette.default.silverGray
    },
    sidebarItem: {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        cursor: "pointer",
        padding: "0.2rem",
        borderRadius: "4px",
    },
    activeItem: {
        backgroundColor: theme.palette.default.darkBlue,
    },
    label: {
        fontSize: "14px",
        color: theme.palette.default.wave,
    },
    content: {
        flex: 1,
        padding: "1rem",
        overflowY: "auto",
    },
};

export default ContactDetailsDrawerSidebar;