// actions
import CallNumber from "./actions/call-number";
import ConnectFlow from "./actions/connect-flow";
import ExternalApis from "./actions/external-apis";
import Functions from "./actions/functions";
import OpenUrl from "./actions/open-url";
import Properties from "./actions/properties";
import SendEmail from "./actions/send-email";
import LiveAgent from "./actions/live-agent";
import TransferToFAQs from "./actions/transfer-to-faqs";
import OptOut from "./actions/opt-out";
import OptIn from "./actions/opt-in";
import ConnectWhatsappFlow from "./actions/connect-whastapp-flow";

export const linkAction = [
  {
    icon: "url",
    type: "link",
    color: "#89C6FF",
    title: "Open URL",
    displayComponent: OpenUrl,
    supports: ["Web Bot", "whatsapp", "messenger", "telegram"],
  },
]

export const flowAction = [
  {
    icon: "nodes",
    type: "flow",
    color: "#B8C4FF",
    title: "Whastapp flow",
    displayComponent: ConnectWhatsappFlow,
    supports: ["Web Bot", "whatsapp", "messenger", "telegram"],
  },
]

export const registerActions = [
  {
    icon: "nodes",
    type: "flow",
    color: "#B8C4FF",
    title: "Connect flow",
    displayComponent: ConnectFlow,
    supports: ["Web Bot", "whatsapp", "messenger", "telegram"],
  },
  {
    icon: "phone",
    type: "mobile",
    color: "#93E9C8",
    title: "Call Number",
    displayComponent: CallNumber,
    supports: ["Web Bot", "whatsapp", "messenger", "telegram"],
  },
  {
    icon: "email",
    type: "email",
    color: "#FFB9C5",
    title: "Send Email",
    displayComponent: SendEmail,
    supports: ["Web Bot", "messenger", "telegram"],
  },
  {
    icon: "url",
    type: "link",
    color: "#89C6FF",
    title: "Open URL",
    displayComponent: OpenUrl,
    supports: ["Web Bot", "whatsapp", "messenger", "telegram"],
  },
  // {
  //   icon: "api",
  //   type: "api",
  //   color: "#B4EDB3",
  //   title: "External API",
  //   displayComponent: ExternalApis,
  // },
  // {
  //   icon: "function",
  //   type: "function",
  //   color: "#9DF8D7",
  //   title: "Function",
  //   displayComponent: Functions,
  // },
  // {
  //   icon: "properties",
  //   type: "properties",
  //   color: "#EDEF8A",
  //   title: "Properties",
  //   displayComponent: Properties,
  // },
  {
    icon: "live-agent",
    type: "live_agent",
    color: "#FFD1AB",
    title: "Live Agent",
    displayComponent: LiveAgent,
    supports: ["Web Bot", "whatsapp", "messenger", "telegram"],
  },
  {
    icon: "live-agent",
    type: "opt_in",
    color: "#FFD1AB",
    title: "Opt In",
    displayComponent: OptIn,
    supports: ["whatsapp"],
  },
  {
    icon: "live-agent",
    type: "opt_out",
    color: "#FFD1AB",
    title: "Opt Out",
    displayComponent: OptOut,
    supports: ["whatsapp"],
  },
  // {
  //   icon: "cancel-circle",
  //   type: "opt_out",
  //   color: "#FFD1AB",
  //   title: "Opt Out",
  //   displayComponent: OptOut,
  //   supports: ["whatsapp"],
  // },
  // {
  //   icon: "faq-icon",
  //   type: "transfer_to_faqs",
  //   color: "#C8EF87",
  //   title: "Transfer to FAQs",
  //   displayComponent: TransferToFAQs,
  // },
];
