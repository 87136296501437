import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../../../AppComponents/AppButton";
import { AppErrorMessage } from "../../../../../AppComponents/Forms";
import {
  Caption,
  DecsText,
  Label,
} from "../../../../../Customization/CommonElements";
import { getTextWithoutHtml } from "../../../../../Utils";
import {
  getHTMLContent,
  getInitialEditiorText,
} from "../../../../FlowBuilder/nodes/utils/editor";
import { Editor } from "../../../../FlowBuilder/ui-components";
import { insertEditorText } from "../../Utils/utils";
import useVariableHook from "./useVariableHook";

interface Props {
  label?: string;
  name: string;
  tooltip?: any;
  divStyle?: any;
  variablesKey: string;
  charactersLimit?: number;
  variablesButtonLimit?: number;
  showAddVariableBtn?: boolean;
  disabled?: boolean;
  labelStyle?: React.CSSProperties;
  description?: string;
  sendValuesToParent?: (values: any) => void | undefined;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

interface StyledProps {
  isError?: any;
}

const WhatsappTextRichEditor: React.FC<Props> = ({
  label,
  name,
  tooltip,
  divStyle,
  variablesKey,
  showAddVariableBtn = true,
  sendValuesToParent,
  disabled = false,
  charactersLimit = 0,
  variablesButtonLimit = 3,
  labelStyle,
  children,
  description,
  ...otherProps
}) => {
  const { setFieldTouched, errors, touched, setFieldValue, values } =
    useFormikContext<any>();
  useVariableHook(name, variablesKey, variablesButtonLimit);
  const [editorState, setEditorState] = useState<any>(
    getInitialEditiorText("")
  );
  useEffect(() => {
    if (sendValuesToParent) {
      sendValuesToParent(values);
    }
  }, [values]); // eslint-disable-line
  useEffect(() => {
    if (
      !getTextWithoutHtml(getIn(values, name)) ||
      getTextWithoutHtml(getIn(values, name))?.length === 0
    ) {
      setFieldValue(name, null);
    }

    if (getIn(values, name)) {
      setFieldTouched(name, true);
      setEditorState(
        getInitialEditiorText(
          getIn(values, name).replace(/\*(.*?)\*/g, "<strong>$1</strong>")
        )
      );
    } else {
      setFieldValue(name, "");
    }
  }, []); // eslint-disable-line

  const onEditorStateChange = (editorState: any, mediaState: any) => {
    setFieldTouched(name, true);
    setEditorState(editorState);
    let hasText: boolean = editorState?.getCurrentContent()?.hasText();
    if (!hasText) {
      setFieldValue(name, "");
    }
    let htmlText = getHTMLContent(editorState);
    htmlText = htmlText.replace(/&amp;/g, "&");
    setFieldValue(name, htmlText.replace(/\*(.*?)\*/g, "<strong>$1</strong>"));
  };

  const handleAddVariableClick = () => {
    setFieldTouched(name, true);
    const lastMatchedVar = getIn(values, variablesKey)[
      getIn(values, variablesKey)?.length - 1
    ];
    const lastVarNumber = parseInt(lastMatchedVar?.match(/\d+/)![0]) || 0;
    const nextVarNumber = lastVarNumber + 1;
    setFieldValue(
      name,
      getHTMLContent(
        insertEditorText(` {{${nextVarNumber}}} `, editorState)
      ).replace(/\*(.*?)\*/g, "<strong>$1</strong>")
    );
    setEditorState(insertEditorText(` {{${nextVarNumber}}} `, editorState));
  };

  if (typeof getIn(values, name) === "undefined") {
    setFieldValue(name, "");
  }

  const getTextLength = (htmlString: string) => {
    if (!htmlString) return 0; // If string is empty, return 0

    const strippedString = htmlString
      .replace(/<[^>]*>/g, "") // Remove HTML tags
      .replace(/&nbsp;/g, " "); // Replace `&nbsp;` with a normal space
    return strippedString.length;
  };

  return (
    <Div style={divStyle}>
      <Label style={labelStyle}>
        {label}
        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <HelpOutlineIcon />
          </Tooltip>
        )}
      </Label>
      {description && <DecsText>{description}</DecsText>}
      <div className="EditorDiv customInput">
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          hideFocusOnLoad={true}
          onBlur={() => setFieldTouched(name, true)}
          whatsappEditorOptions={true}
          error={getIn(errors, name) && getIn(touched, name)}
          readOnly={disabled}
        />
      </div>
      {charactersLimit !== 0 && (
        <Caption>
          {" "}
          Characters: {getTextLength(getIn(values, name)) || 0}/
          {charactersLimit}
        </Caption>
      )}
      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />

      {showAddVariableBtn && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <AppButton
            variant="text"
            onClick={() => {
              handleAddVariableClick();
            }}
            style={{
              marginTop: "0.5rem",
              minWidth: "170px",
              width: "170px",
              border: "1px solid rgba(91, 115, 232, 1)",
              borderRadius: "6px",
            }}
            startIcon={"ri-add-line"}
            isDisabled={
              disabled ||
              (getIn(values, variablesKey)?.length >= variablesButtonLimit
                ? true
                : false)
            }
            className="buttonInsideForm"
          >
            Add Variable
          </AppButton>
        </div>
      )}
    </Div>
  );
};

export default WhatsappTextRichEditor;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-bottom: 0.5rem;
  }

  .EditorDiv {
    margin: 1rem 0;
  }
`;
