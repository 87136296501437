// vendors
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import isUrl from "is-url";
import cloneDeep from "lodash.clonedeep";
import Stack from "@mui/material/Stack";

// components
import UIButton from "../../../Components/Button";
import Input from "../../../Components/Input";
import Icon from "../../../Components/Icon";
import Select from "../../../Components/Select";
import { getProcessedLength, isApiVariable } from "../../../Utils/editor";

const OpenUrl = ({
  onSaveClick,
  activeButtonData,
  onCancelClick,
  language,
  maxCharLength
}: any) => {
  const [value, setValue] = useState<string>("");
  const [urlValue, setURLValue] = useState<string>("");
  const [isURLValid, setURLValidity] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(getProcessedLength(event.target.value) > maxCharLength) return;
    setValue(event.target.value);
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setURLValue(value);
    if (isApiVariable(value)) {
      setURLValidity(true);
    } else {
      setURLValidity(isUrl(value));
    }
  };

  const handleSaveClick = () => {
    let updatedAction = cloneDeep(activeButtonData);
    updatedAction.type = "link";
    updatedAction.value.url = urlValue;
    updatedAction.value.text = value;
    onSaveClick && onSaveClick(updatedAction);
  };

  const handleCancelClick = () => {
    onCancelClick && onCancelClick();
  };

  useEffect(() => {
    if (activeButtonData && activeButtonData.type == "link") {
      setURLValue(activeButtonData.value.url);
      setValue(activeButtonData.value.text);
      const value = activeButtonData.value.url;
      if (isApiVariable(value)) {
        setURLValidity(true);
      } else {
        setURLValidity(isUrl(value));
      }
    }
  }, [activeButtonData]);

  return (
    <>
      <Box sx={{ px: "16px" }}>
        <UIButton
          fullWidth
          color="info"
          variant="outlined"
          startIcon={<Icon icon="link" size={16} />}
        >
          Open URL
        </UIButton>
      </Box>
      <Box sx={{ p: "16px" }}>
        <Box>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: "#7E8392",
            }}
          >
            Button title
          </Typography>
          <Input
            placeholder="Title"
            onChange={handleChange}
            value={value}
            error={value === ""}
            {...{
              helperText: maxCharLength ? (
                <Stack direction={"row"} spacing={2}>
                  <Typography variant="body2">
                    {`Limit ${getProcessedLength(value)} characters. remaining ${maxCharLength}`}
                  </Typography>
                </Stack>
              ) : (
                ""
              ),
            }}
          />
        </Box>
        <Box sx={{ py: "16px" }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: "#7E8392",
            }}
          >
            Enter URL
          </Typography>
          <Input
            placeholder="url"
            onChange={handleUrlChange}
            value={urlValue}
            error={urlValue !== "" && !isURLValid}
          />
        </Box>
        {/* <Box sx={{ py: "16px" }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: "#7E8392",
            }}
          >
            Open view
          </Typography>
          <Select
            id="open-view-url"
            value={"in-widget"}
            // onChange={handleDropdownChange}
            options={[
              {
                label: "In Widget",
                value: "in-widget",
              },
              {
                label: "In Browser",
                value: "in-browser",
              },
            ]}
          />
        </Box> */}
        {/* <UIButton
          variant="outlined"
          fullWidth
          startIcon={<Icon icon="actions" size={16} />}
        >
          Perform action
        </UIButton> */}
      </Box>
      <Box sx={{ borderTop: "1px solid #F5F6F8", p: "16px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UIButton variant="outlined" onClick={handleCancelClick}>
            Cancel
          </UIButton>
          <UIButton
            color="primary"
            variant="contained"
            startIcon={<Icon icon="save" color="#fff" size={13} />}
            disabled={language !== "english" || !isURLValid || value === ""}
            onClick={handleSaveClick}
          >
            Save
          </UIButton>
        </Box>
      </Box>
    </>
  );
};
export default OpenUrl;
