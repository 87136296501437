
import React, { useEffect, useRef, useState } from 'react'
import { useNotification } from '../../../../Hooks/useNotification/useNotification';
import { useAppDispatch, useAppSelector } from '../../../../Store';
import { ContactBasicInfo, contactDetailsId } from '../../../../Store/Slices/Contacts/contacts.selector';
import { contactId } from '../../../../Store/Slices/Contacts/contacts.slice';
import { ContactsActions } from '../../../../Store/Slices/Contacts/contacts.action';
import useModal from '../../../../Hooks/useModel/useModel';
import { useNavigate } from 'react-router-dom';
import AppDrawer from '../../../../AppComponents/AppDrawer';
import ContactDetailsDrawerSidebar from '../Sidebar/ContactDetailsDrawerSidebar';
import { Box, Divider } from '@mui/material';
import { Title } from '../AddContactStyles';
import AppButton from '../../../../AppComponents/AppButton';
import CloseIcon from "@mui/icons-material/Close";
import { theme } from '../../../../Customization/Theme';
import styled from 'styled-components';
import CommonTabHeader from '../Sidebar/CommonTabHeader';
import BasicInformation from './Sections/BasicInformation';
import CommunicationStats from './Sections/CommunicationStats';
import Activities from './Sections/Activities';
import FilesStats from './Sections/Files';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface AddContactDetailsProps {
    isShow: boolean;
    toggle: () => void;
    data: any;
}
const RenderSections: React.FC<AddContactDetailsProps> = (props) => {
    const { isShow, toggle, data } = props;
    const { showAlert } = useNotification();
    const ContactDetailsId: any = useAppSelector(contactDetailsId);
    const basicInfo: any = useAppSelector(ContactBasicInfo)
    const [activeSection, setActiveSection] = useState("basic-information");
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const closeModal = () => {
        dispatch(contactId(""))
        toggle();
    };

    const allContactIds = data?.map((eachContact: any)=>eachContact.id);

    useEffect(() => {
        if (isShow) {
            dispatch(ContactsActions.getBasicInfo({ contact_id: ContactDetailsId }))
                .then((res: any) => {
                    setLoading(false)
                })
                .catch((error: any) => {
                    showAlert(
                        error?.response?.statusText || "Enabled to fetch Data",
                        "error",
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [isShow, ContactDetailsId]);
    const useModal3 = () => {
        const { isShowing, toggle } = useModal();
        return { isShowingLifeCycle: isShowing, toggleLifeCycleModal: toggle };
    };

    const useModal4 = () => {
        const { isShowing, toggle } = useModal();
        return { isShowingModal4: isShowing, toggleModal4: toggle };
    };

    const useModal5 = () => {
        const { isShowing, toggle } = useModal();
        return { isShowingModal5: isShowing, toggleModal5: toggle };
    };

    const currentIndex = allContactIds.indexOf(ContactDetailsId);

    // Function to go to the previous contact
    const handlePrevious = () => {
        if (currentIndex > 0) {
            const prevContactId = allContactIds[currentIndex - 1];
            dispatch(contactId(prevContactId));  // Update Redux store
        }
    };

    // Function to go to the next contact
    const handleNext = () => {
        if (currentIndex < allContactIds.length - 1) {
            const nextContactId = allContactIds[currentIndex + 1];
            dispatch(contactId(nextContactId));  // Update Redux store
        }
    };

    const getContactDetails = () => {
        setLoading(true);
        let payload = {
            contact_id: ContactDetailsId,
        };

        dispatch(ContactsActions.getBasicInfo(payload))
            .then((res: any) => {
                if (res?.error?.message === "Rejected") {
                    showAlert(
                        res?.payload?.data?.message || "Enabled to fetch Data",
                        "error"
                    );
                } else {
                }
            })
            .catch((error: any) => {
                showAlert(
                    error?.response?.statusText || "Enabled to fetch Data",
                    "error"
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        if (ContactDetailsId) {
            getContactDetails();
        }
    }, [ContactDetailsId]);
    const refRibbon1 = useRef<HTMLElement>(null);
    const refRibbon2 = useRef<HTMLElement>(null);
    const [hideButton, setHideButton] = useState(false);
    const [hideButtonRef2, setHideButtonRef2] = useState(false);
    const { isShowingLifeCycle, toggleLifeCycleModal } = useModal3();
    const { isShowingModal4, toggleModal4 } = useModal4();
    const { isShowingModal5, toggleModal5 } = useModal5();
    const [lifeCycleValue, setLifeCycleValue] = useState<any>({});
    const navigate = useNavigate();
    const assignedname = [{ name: "Shubham Puranik" }];
    const [userLifeCycleValues, setUserLifeCycleValues] = useState<any>({});
    useEffect(() => {
        let tempValues: any = {};
        if (basicInfo?.lifecycle_fields?.[0]?.fields.length > 0) {
            basicInfo?.lifecycle_information?.forEach((field: any) => {
                if (field.is_selected) {
                    tempValues.lifecycle_stage = field;
                    tempValues.lifecycle_stage_status = field.statuses.find(
                        (status: any) => status.is_selected
                    );
                }
            });
            setUserLifeCycleValues(tempValues);
        }
    }, [basicInfo?.lifecycle_fields?.[0]?.fields]);

    const ribbon1Scroll = (scrollOffset: number) => {
        if (refRibbon1.current) {
            refRibbon1.current.scrollLeft += scrollOffset;
        }
    };

    const ribbon2Scroll = (scrollOffset: number) => {
        if (refRibbon2.current) {
            refRibbon2.current.scrollLeft += scrollOffset;
        }
    };

    useEffect(() => {
        const ribbonElement = refRibbon1?.current;

        const handleResize = () => {
            if (ribbonElement) {
                const hasHorizontalScroll =
                    ribbonElement.scrollWidth > ribbonElement.clientWidth;

                if (hasHorizontalScroll) {
                    setHideButton(true);
                } else {
                    setHideButton(false);
                }
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [loading, refRibbon1]);

    useEffect(() => {
        const ribbonElement = refRibbon2?.current;

        const handleResize = () => {
            if (ribbonElement) {
                const hasHorizontalScroll =
                    ribbonElement.scrollWidth > ribbonElement.clientWidth;

                if (hasHorizontalScroll) {
                    setHideButtonRef2(true);
                } else {
                    setHideButtonRef2(false);
                }
            }
        };

        window.addEventListener('resize', handleResize);

        // Call handleResize initially to set initial state
        handleResize();

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [loading, refRibbon2]);

    return (
      <AppDrawer 
        open={isShow} 
        onClose={() => undefined}
        sx={{
          "& .MuiPaper-root": { width: "calc(100vw - 270px)" },
        }}
      >
        <DrawerContentWrapper>
          <SidebarWrapper>
            <ContactDetailsDrawerSidebar 
              activeSection={activeSection} 
              onSelect={setActiveSection} 
            />
          </SidebarWrapper>
          <ContentSection>
            <StickyHeader
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0.5rem 1rem",
                alignItems: "center",
              }}
            >
              <Title>
                {basicInfo?.general_information?.first_name} {basicInfo?.general_information?.last_name}
              </Title>
              <Container>
                <Container>
                  <button
                    style={{
                      backgroundColor: "transparent",
                      color: currentIndex === 0 ? "gray" : theme.palette.default.paleBlue,
                      border: "0px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "9px",
                      cursor: currentIndex === 0 ? "auto": "pointer"
                    }}
                    onClick={handlePrevious}
                    disabled={currentIndex === 0}
                  >
                    <div
                      style={{
                        border: `2px solid ${currentIndex === 0 ? "gray" : theme.palette.default.paleBlue}`,
                        padding: "0px",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "30px",
                        alignItems: "center",
                        cursor: "pointer"
                      }}
                    >
                      <KeyboardArrowLeftIcon
                        style={{ fontSize: "11px", color: currentIndex === 0 ? "gray" : theme.palette.default.paleBlue }}
                      />
                    </div>
                    Previous
                  </button>

                  <button
                    style={{
                      backgroundColor: "transparent",
                      color: currentIndex === allContactIds.length - 1 ? "gray" : theme.palette.default.paleBlue,
                      border: "0px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "9px",
                      cursor: "pointer"
                    }}
                    onClick={handleNext}
                    disabled={currentIndex === allContactIds.length - 1}
                  >
                    Next
                    <div
                      style={{
                        border: `2px solid ${
                          currentIndex === allContactIds.length - 1 ? "gray" : theme.palette.default.paleBlue
                        }`,
                        padding: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "30px",
                        cursor: "pointer"
                      }}
                    >
                      <ChevronRightIcon
                        style={{
                          fontSize: "11px",
                          color: currentIndex === allContactIds.length - 1 ? "gray" : theme.palette.default.paleBlue,
                        }}
                      />
                    </div>
                  </button>
                </Container>
                <AppButton
                  variant="outline"
                  buttonStyle={{
                    padding: 3,
                    height: "auto",
                    minHeight: "15px",
                  }}
                  onClick={() => navigate(`/contacts/view/${ContactDetailsId}/basic-information`)}
                >
                  View Details
                </AppButton>
                <CloseIcon
                  onClick={() => closeModal()}
                  sx={{
                    color: theme?.palette?.default?.darkGrey,
                    cursor: "pointer",
                  }}
                />
              </Container>
            </StickyHeader>
            <Divider />
            <div style={{marginTop: "10px"}}>
              <CommonTabHeader />
            </div>
            <DetailsWrapper>
              <div>
                {activeSection === "basic-information" && <BasicInformation contactId = {ContactDetailsId} />}
                {activeSection === "communication-stats" && <CommunicationStats setLoading={setLoading} />}
                {activeSection === "activities" && <Activities setLoading={setLoading} />}
                {activeSection === "files" && <FilesStats setLoading={setLoading} />}
              </div>
            </DetailsWrapper>
          </ContentSection>
        </DrawerContentWrapper>
      </AppDrawer>
    );
}

export default RenderSections;

const DrawerContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const SidebarWrapper = styled.div`
  width: 90px;
  min-width: 90px;
  height:100%;
`;

const ContentSection = styled.div`
    flex: 1;
  min-width: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
const StickyHeader = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${theme.palette.default.white};
  padding: 10px 0px;
`;

const DetailsWrapper = styled.div`
  width: 100%;
  height: calc(100% - 45px);
  form {
    padding: 10px 15px 0;
  }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 23px;
`
export const ModalUI = styled.div`

  .wrapper {
    padding: 0px 16px;
    .input-field_wrapper {
      height: auto !important;
      padding-top: 16px;
    }
  }
  .mark-complete-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid ${theme.palette.default.border};
    padding-top: 10px;
    gap: 15px;
  }
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 10px;
  }
  .btn {
    border: none;
    background: none;
  }
  .popup-heading-text h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: ${theme.palette.default.mineShaft};
  }
  .popup-heading-icon svg {
    width: 12px;
    height: 12px;
    color: red;
  }
`;
const SectionWrapper = styled.div`
  background-color: ${theme.palette.default.white};
  margin: 15px 15px 0;
`;

const Stage = styled.div`
  background: ${theme.palette.default.white};
  border-radius: 4px 4px 0px 0px;
  justify-content: space-between;
  min-height: 104px;
  display: flex;
  align-items: stretch;
  margin-bottom: 15px;
  margin: 0 15px;
  .stage-wrapper {
    width: calc(100% - 45px);
    padding: 16px;
  }
`;
const StageContainer = styled.div`
  margin: 1rem;
  display: flex;
  align-items: center;
  .scroll-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:6px;
    .scroll-back {
      border: 1px solid ${theme.palette.default.border};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 6px;
      border-radius: 4px;
    }
    .scroll-forward {
      border: 1px solid ${theme.palette.default.border};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 6px;
      border-radius: 4px;
    }
  }
  &.loading {
    gap: 10px;
  }
`;

const StartArrow = styled.div`
  width: 100%;
  min-width: 130px;
  // max-width: 130px;
  height: 40px;
  display: inline-block;
  position: relative;
  height: 24px;
  line-height: 24px;
  text-align: center;
  // padding: 0 40px 0 18px;
  padding: 0 18px;
  font-size: 13px;
  background: ${theme.palette.default.athensGray};
  color: ${theme.palette.default.black};
  box-sizing: border-box;

  .icon-wrapper {
    text-overflow: ellipsis !important;
    display: block !important;
    text-decoration: none !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    &::before {
      content: "" !important;
    }
  }

  &::before {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: 1;
    top: 0px;
    left: -12px;
    border-width: 12px;
    border-style: solid;
    border-radius: 50%;
    color: ${theme.palette.default.athensGray};
  }

  &::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: 1;
    top: 0;
    right: -12px;
    border-width: 12px 0px 12px 13px;
    border-color: transparent transparent transparent ${theme.palette.default.athensGray};
    border-style: solid;
  }
`;
const Medium = styled.div`
  width: 100%;
  height: 24px;
  min-width: 100px;
  // max-width: 100px;
  display: inline-block;
  position: relative;
  line-height: 24px;
  text-align: center;
  // padding: 0 18px 0 40px; /* Adjusted padding for the left side */
  padding: 0 18px;
  font-size: 13px;
  background: ${theme.palette.default.athensGray};
  color: ${theme.palette.default.black};
  box-sizing: border-box;
  .icon-wrapper {
    text-overflow: ellipsis !important;
    display: block !important;
    text-decoration: none !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    &::before {
      content: "" !important;
    }
  }

  &::before {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: 1;
    top: 0;
    left: -16px;
    border-width: 12px 0px 12px 17px;
    border-color: ${theme.palette.default.athensGray} transparent ${theme.palette.default.athensGray} transparent;
    border-style: solid;
  }

  &::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: 1;
    top: 0;
    right: -16px;
    border-width: 12px 0px 12px 17px;
    border-color: transparent transparent transparent ${theme.palette.default.athensGray};
    border-style: solid;
  }
`;
const EndArrow = styled.div`
  width: 100%;
  height: 40px;
  display: inline-block;
  min-width: 130px;
  // max-width: 130px;
  position: relative;
  height: 24px;
  line-height: 24px;
  text-align: center;
  // padding: 0 40px 0 18px;
  padding: 0 18px;
  font-size: 13px;
  background: ${theme.palette.default.athensGray};
  color: ${theme.palette.default.black};
  box-sizing: border-box;
  .icon-wrapper {
    text-overflow: ellipsis !important;
    display: block !important;
    text-decoration: none !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    &::before {
      content: "" !important;
    }
  }

  &::before {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: 1;
    top: 0;
    left: -17px;
    border-width: 13px 0px 12px 18px;
    border-color: ${theme.palette.default.athensGray} transparent ${theme.palette.default.athensGray} transparent;
    border-style: solid;
  }

  &::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: 1;
    top: 0px;
    right: -12px;
    border-width: 12px;
    border-style: solid;
    border-radius: 50%;
    color: ${theme.palette.default.athensGray};
  }
`;

const Ribbon = styled.div`
  display: flex;
  gap: 30px;

   padding: 0 1rem;
    overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;  
  white-space: nowrap;
  margin-left: 2rem;
  margin-right: 2rem;
  width: calc(100% - 100px);
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: transparent !important;
  }
  
  > div.active {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.default.white};
    :after {
      color: ${theme.palette.primary.main};
    }
  }
  > div.active {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.default.white};
    &:after {
      border-color: transparent transparent transparent
        ${theme.palette.primary.main};
    }
    &.end:after {
      border-color: ${theme.palette.primary.main};
    }
    &:before {
      border-color: ${theme.palette.primary.main} transparent;
    }
    &.start:before {
      border-color: ${theme.palette.primary.main};
    }
  }
`;

