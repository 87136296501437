import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ArcElement, Chart as ChartJS, Legend } from "chart.js";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { DashboardActions } from "../../../Store/Slices/DashboardRedux/dashboard.actions";
import {
  BotList,
  botDateRange,
} from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { isEmptyObject } from "../../../Utils";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import { TextTitle } from "../AnalyticsSummaryStyles";
import {
  SkeletonCircleIconDoughnut
} from "../SkeletonLoader.tsx/SkeletonCard";
import { MessageWrapper } from "./MessageExchanged";

ChartJS.register(ArcElement, Legend);

interface Props {}

const MessageExchanged: React.FC<Props> = () => {
  const [botdata, setBotdata] = useState<any[]>([]) || [0];
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const dashdateRange: any = useAppSelector(botDateRange);
  const botList = useAppSelector(BotList);

  // Message Exchanged API call
  const getDashSummaryMsg = () => {
    setLoading(true);

    let payload: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
      filter_type: "bot",
    };
    if (botList.length > 0) {
      payload = {
        ...payload,
        bot_id: botList,
      };
    }
    dispatch(DashboardActions.getSummaryMsg(payload))
      .then((res) => {
        let data: any = res?.payload;
        let temp = data?.data?.message_data;
        if (!isEmptyObject(temp)) {
          setBotdata(temp || [0]);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dashdateRange?.startDate) {
      getDashSummaryMsg();
    }
  }, [dashdateRange, botList]); // eslint-disable-line react-hooks/exhaustive-deps

  const data = {
    labels: ["Total Messages Sent", "Total Messages Received"],
    datasets: [
      {
        label: "# of Votes",
        data: Object.values(botdata) ?? [0],
        backgroundColor: ["#ff4545", "#feb019"],
        borderWidth: 4,
      },
    ],
  };

  const chartData: any = {
    series: data?.datasets?.[0]?.data,
    options: {
      chart: {
        type: "donut",
        events: {
          dataPointSelection: function (event: any, chartContext: any, config: any) {
            if (!chartContext || !config || !config.w) return; // Ensure chartContext is available
            const selectedLabel = config.w.globals.labels[config.dataPointIndex];
            const shortLabel = selectedLabel.includes("Sent")
              ? "Sent"
              : selectedLabel.includes("Received")
              ? "Received"
              : selectedLabel;
            const value = config.w.globals.series[config.dataPointIndex];    
            const updatedLabel = `${shortLabel}`;  
            setTimeout(() => {
              chartContext.updateOptions({
                plotOptions: {
                  pie: {
                    expandOnClick: false,
                    donut: {
                      labels: {
                        show: true,
                        total: {
                          show: true,
                          showAlways: true,
                          label: updatedLabel,
                          formatter: function (w: any) {
                            return `${value}`;
                          },
                        },
                      },
                    },
                  },
                },
              });
            }, 0); 
          },
        },
      },
      legend: {
        position: "left",
        show: true,
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              width: 400,
            },
            legend: {
              position: "bottom",
            },
          },
        },
        {
          breakpoint: 3000,
          options: {
            chart: {
              width: 400,
            },
          },
        },
      ],
      labels: ["Total Messages Sent", "Total Messages Received"],
      dataLabels: { enabled: false },
      tooltip: { enabled: true },
      fill: { colors: ["#ff4545", "#feb019", "#008ffb"] },
      colors: ["#ff4545", "#feb019", "#008ffb"],
      states: {
        hover: { filter: { type: "lighten", value: 0.5 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 0 },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: "Total", // Initial label inside the donut
              },
            },
          },
        },
      },
    },
  };  

  return (
    <>
      <Header>
        <p className="time_stats">
          <TextTitle>Messages Exchanged</TextTitle>
          <AppToolTip
            divStyle={{ padding: "0 8px" }}
            tooltipProps={{
              title: "The total number of messages for bot",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={<InfoOutlinedIcon style={{ fontSize: "10px" }} />}
          />
        </p>
      </Header>
      <MessageWrapper>
        {loading ? (
          <DoughnutLoading>
            <SkeletonCircleIconDoughnut />
          </DoughnutLoading>
        ) : data.datasets[0].data[0] === 0 && data.datasets[0].data[1] === 0 ? (
          <NoPreview>No Preview</NoPreview>
        ) : (
          <div className="donout_div">
            {Object.values(botdata)?.length > 0 ? (
              <ReactApexChart
                type="donut"
                options={chartData?.options}
                series={chartData?.series}
                width={400}
              />
            ) : (
              <NoPreview>No Preview</NoPreview>
            )}
          </div>
        )}
      </MessageWrapper>
    </>
  );
};
export default MessageExchanged;

const Header = styled.div`
  && {
    .time_stats {
      display: flex;
      font-weight: 500;
      align-items: center;
      padding: 10px 24px 10px 10px;
      border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
      .div_tooltip {
        font-size: 15px;
        margin-top: 0.094rem;
      }
    }
  }
`;

const NoPreview = styled.div`
  min-width: 120px;
  min-height: 200px;
  display: flex;
  align-items: center;
`;
const DoughnutLoading = styled.div`
  min-width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  > span {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
