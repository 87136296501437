import React from "react";
import styled from "styled-components";

interface StatusBadgeProps {
  label: string;
}

const Badge = styled.span<{ status: string }>`
  display: inline-block;
  padding: 0.2rem 0.5rem;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 9999px; 
  color: ${({ status }: any) =>
    status === "ENDED" ? "#25926A" : 
    status === "COMPLETED" ? "#25926A" : "#DE4848"}; 
  background-color: ${({ status }: any) =>
    status === "ENDED" ? "#E5FFF6" : 
    status === "COMPLETED" ? "#E5FFF6" : "#FFF1F1"}; 
  border-color: ${({ status }: any) =>
    status === "ENDED" ? "#25926A" : 
    status === "COMPLETED" ? "#25926A" : "#DE4848"}; 
`;

const CallLogsBadge: React.FC<StatusBadgeProps> = ({ label }) => {
  console.log(label, "label");
  const upperLabel = label.toUpperCase();
  return <Badge status={upperLabel}>{upperLabel}</Badge>;
};

export default CallLogsBadge;
