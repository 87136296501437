import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Menu,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { ColumnDef, createColumnHelper, SortingFn, SortingState } from "@tanstack/react-table";
import React, { HTMLProps, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import AppCustomTable from "../../AppComponents/AppCustomTable";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { AppForm, AppFormField } from "../../AppComponents/Forms";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import { NoDataBox } from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import AppModel from "../../Hooks/useModel/AppModel";
import useModal from "../../Hooks/useModel/useModel";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../Store";
import { ContactsActions } from "../../Store/Slices/Contacts/contacts.action";
import { ReactComponent as VertOutlinedIcon } from "../../assets/images/tableImages/three-dots.svg";
import { ReactComponent as CrownImage } from "../../assets/images/tableImages/crown.svg";
import { ReactComponent as StatusArrow } from "../../assets/images/tableImages/statusArrow.svg";
import { ReactComponent as StatusWonArrow } from "../../assets/images/tableImages/statusWonArrow.svg";
import { ReactComponent as StatusLostArrow } from "../../assets/images/tableImages/statusLostArrow.svg";
import {
  ContactListingPagination,
  ContactsListingData,
  getContactHistoryData,
  getContactHistoryError,
  getContactHistoryLoading,
} from "../../Store/Slices/Contacts/contacts.selector";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { contactId, editId } from "../../Store/Slices/Contacts/contacts.slice";
import HistoryArrowIcon from "../../assets/images/historyarrow.png";
import NoDataImage from "../../assets/images/noDatafound.svg";
import { ReactComponent as NotesIcon } from "../../assets/images/tableImages/remix-file.svg";
import { ReactComponent as TaskIcon } from "../../assets/images/icons/task.svg";
import { ReactComponent as SaveIcon } from "../../assets/images/save-icon.svg";
import { ReactComponent as TagCrossIcon } from "../../assets/images/tag-cross-icon.svg";
import { ActionPopover } from "../Broadcast/Email/Template/EmailTemplateListingStyle";
import AppSkeletonTableLoader from "../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import { AppToolTip } from "../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../UiComponents/NoRecordsFound/NoRecordsFound";
import { LeadPageWrapper } from "./LeadsStyles";
// import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { ReactComponent as EmailIcon } from "../../assets/images/tableImages/remix-email.svg";
import { ReactComponent as ChangeCircleOutlinedIcon } from "../../assets/images/tableImages/remix-repeat.svg";
import { ReactComponent as SmsOutlinedIcon } from "../../assets/images/tableImages/remix-message.svg";
// import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { RenderArrayValues } from "../../Utils/tableUtils/TableUtils";
import ChangeLifeCycleStage from "./contact-action/change-lifecycle-stage";
import ChangeNotes from "./contact-action/change-notes";
import EmailSingleBroadcast from "./contact-action/email-single-broadcast";
import SmsSingleBroadcast from "./contact-action/sms-single-broadcast";
import WhatsappSingleBroadcast from "./contact-action/whatsapp-single-broadcast";
import Task from "../ContactTask";
import AppMasterTable from "../../AppMasterTable";
import { ContactsSelectData } from "../../Store/Slices/MarketingList/marketingList.selectors";
import InlineTagsUpdate from "./bulkActions/InlineTagsUpdate";
import { he } from "date-fns/locale";
import LifeCycleUpdate from "./bulkActions/LifeCycleUpdate";
import UpdateInlineContactFields from "./bulkActions/UpdateInlineContactFields";
import { ContactPropertiesActions } from "../../Store/Slices/Settings/ContactProperties/contactProperties.action";
import ShadesSkeletonLoader from "../../AppComponents/Loader/ShadesSkeletonLoader";
// import { RenderFirstColumnWithActions } from "./ActionMenu";
interface BasicInfo {
  first_name: string;
  last_name: string;
  designername: string;
  email: string;
  emptyemail: string;
  phone: string;
  workphone: string;
  birthdate: string;
  facebook: string;
  twitter: string;
  linkdin: string;
  hasUpdateAceess?: string;
  sorting?: any;
  hidePagination?: boolean;
  removeContactDetailsLink?: boolean;
  totalCount?: any;
  sortingApply?: boolean;
  type?: string;
  hideSelection?: boolean;
}

interface TagProps {
  bgColor: string;
  color?: string;
}

interface EdittableCellProps {
  info: any;
  col: any;
  firstColumnName?: any;
}

// Define column width configurations
const columnWidthConfig: Record<string, { size: number; minSize: number }> = {
  // Basic information columns
  name: { size: 350, minSize: 350 },
  email: { size: 150, minSize: 200 },
  phone: { size: 150, minSize: 120 },

  spacer: { size: 0, minSize: 0 },
  // Status and date columns
  status: { size: 150, minSize: 100 },
  date: { size: 150, minSize: 120 },
  created_at: { size: 150, minSize: 120 },
  updated_at: { size: 150, minSize: 120 },

  // Description and content columns
  description: { size: 300, minSize: 200 },
  content: { size: 400, minSize: 250 },

  // Default values for unspecified columns
  default: { size: 250, minSize: 250 }
};

//custom sorting logic for one of our enum columns
const sortStatusFn: SortingFn<any> = (rowA: any, rowB: any, _columnId: any) => {
  const statusA = rowA.original.status
  const statusB = rowB.original.status
  const statusOrder = ['single', 'complicated', 'relationship']
  return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB)
}






export const LeadTable: React.FC<any> = ({
  data,
  toggleModal2,
  dynamicsColumn,
  setLocalValues,
  localValues,
  setShouldApply,
  toggleContactModal,
  selectedDocumentIds,
  setSelectedDocumentIds,
  toggleContactDetailsModal,
  NodataButton,
  createImportDoc,
  setSelectAll,
  selectAll,
  sorting,
  hasUpdateAceess,
  loader,
  setSkeltonLoader,
  hidePagination,
  removeContactDetailsLink,
  totalCount,
  sortingApply,
  leadRefresh,
  type,
  hideSelection,
}) => {

  const dispatch = useAppDispatch();
  const useModalTags = () => {
    const {isShowing,toggle} = useModal();
    return {isShowingTagsModal: isShowing, toggleTagsModal: toggle};
  }
const useModalLifecycle = () => {
  const {isShowing, toggle} = useModal();
  return {isShowingLifecycle: isShowing, toggleLifecycle: toggle};
}
  const [lifeCycleAnchorEl, setLifecycleAnchorEl] = useState<any>();
  const [tagsAnchorEl, setTagsAnchorEl ] = useState<null | HTMLElement>(null);
  const [modalAnchorEl, setModalAnchorEl] = useState<null | HTMLElement>(null);
  const [modalPos, setModalPos] = useState({ top: 0, left: 0, visible: false });
  const [ loading, setLoading] = useState<any>(false);
  const {isShowingLifecycle, toggleLifecycle} = useModalLifecycle();
  const [showTagsPopup, setShowTagsPopup] = useState(false);
  const [colData, setColData] = useState<any>();
  const [rowData, setRowData] = useState<any>("");
  const [allTags,setAllTags] = useState([]);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [searchParams, setSearchParams] = useSearchParams();
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    zoom: window.devicePixelRatio,
  });
  const [isUpdating, setIsUpdating] = useState(true);


  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        zoom: window.devicePixelRatio,
      });
    };

    window.addEventListener("resize", handleResize);
    // Cleanup on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getDynamicColumnWidthConfig = (columns: any) => {
    const numberOfColumns = columns.length;

    // Default minimum widths for different column types
    const MIN_WIDTHS = {
      name: 350,
      email: 195,
      phone: 120,
      status: 100,
      date: 120,
      tags: 160,
      lifecycle_stage_status: 160,
      description: 200,
      content: 250,
      default: 160,
    };

    // Default preferred widths (can be larger than minimum)
    const PREFERRED_WIDTHS = {
      name: 350,
      email: 250,
      phone: 150,
      status: 150,
      date: 150,
      lifecycle_stage_status: 300,
      description: 300,
      content: 400,
      default: 250,
    };

    // Calculate natural table width based on preferred column widths
    const getNaturalWidth = () => {
      const CHECKBOX_WIDTH = 40; // Width for checkbox column
      let totalWidth = CHECKBOX_WIDTH;
      columns.forEach((column: any) => {
        const columnType = column.name as keyof typeof PREFERRED_WIDTHS;
        totalWidth += PREFERRED_WIDTHS[columnType] || PREFERRED_WIDTHS.default;
      });
      return totalWidth;
    };

    const naturalWidth = getNaturalWidth();
    const viewportWidth = windowDimensions.width; 
    const tableWidth = Math.min(naturalWidth, viewportWidth);

    // Special handling for the 'name' column based on number of columns
    const getNameColumnWidth = () => {
      switch (numberOfColumns) {
        case 1:
          return Math.min(PREFERRED_WIDTHS.name, tableWidth - 40); // 40px for checkbox
        case 2:
          return Math.min(PREFERRED_WIDTHS.name, (tableWidth - 40) * 0.6);
        case 3:
          return Math.min(PREFERRED_WIDTHS.name, (tableWidth - 40) * 0.4);
        default:
          return Math.min(PREFERRED_WIDTHS.name, (tableWidth - 40) * 0.35);
      }
    };

    // Calculate widths for other columns
    const getColumnWidth = (columnType: string, columns: any) => {
      const remainingWidth = tableWidth - getNameColumnWidth() - 40; // Subtract checkbox and name column
      const remainingColumns = numberOfColumns - 1;
      const isNameColumn = columnType === 'name';
      const minWidth =
        MIN_WIDTHS[columnType as keyof typeof MIN_WIDTHS] || MIN_WIDTHS.default;

      if (naturalWidth <= viewportWidth) {
        const evenWidth = (viewportWidth / columns.length) - 20;
        return { size: evenWidth, minSize: evenWidth };
      } else {
           if (isNameColumn) {
            return {
              size: getNameColumnWidth(),
              minSize: MIN_WIDTHS.name
            };
          }
        const width = Math.max(Math.floor(remainingWidth / remainingColumns), minWidth);
        return { size: width, minSize: minWidth };
      }
    };

    return { getColumnWidth };
  };


  const getColumnConfig = (columns: any) => {
    const widthConfig = getDynamicColumnWidthConfig(columns);
    return columns.map((column: any) => {
      const columnWidths = widthConfig.getColumnWidth(column.name, columns);
      return {
        ...column,
        size: columnWidths.size,
        minSize: columnWidths.minSize,
      };
    });
  };


  const randomBgColors = [
    theme.palette.default.biloba,
    theme.palette.default.lightApricot,
    theme.palette.default.sapling,
    theme.palette.default.bermuda,
    theme.palette.default.pastelPink,
    theme.palette.default.mystic,
    theme.palette.default.pattensBlu,
    theme.palette.default.frenchLilac,
    theme.palette.default.texas,
  ];
  
  const randomColors = [
    theme.palette.default.luckyPoint,
    theme.palette.default.totemPole,
    theme.palette.default.darkTan,
    theme.palette.default.kaitokeGreen,
    theme.palette.default.cardinal,
    theme.palette.default.ebonyClay,
    theme.palette.default.elephant,
    theme.palette.default.luckyPoint,
    theme.palette.default.nutmegWoodFinish
  ]

  //funtion to return random colors
  const getRandomBgColor = (index: any, offset = 0) => {
    return randomBgColors[(index + offset) % randomBgColors.length];
  };

  const getRandomColor = (index: any, offset = 0): string => {
    return randomColors[(index + offset) % randomColors.length];;
  };

  useEffect(() => {
    // If the "page" parameter doesn't exist, set it to "1"
    if (!searchParams.get("page")) {
      const params = Object.fromEntries(searchParams.entries());
      setSearchParams({ ...params, page: "1" });
    }
  }, [searchParams, setSearchParams,]);

  const pageParam = searchParams.get("page") || "1";
  const initialPage = parseInt(pageParam, 10) - 1;
  // Maintain the current page in state
  const [currentPage, setCurrentPage] = useState(initialPage);

  const handlePageChange = (event: any, newPage: number) => {
    setCurrentPage(newPage - 1);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: newPage.toString(),
    });
    setLocalValues &&
      setLocalValues({
        ...localValues,
        currentPage: newPage - 1,
      });
    setShouldApply(true);
  };

  useEffect(() => {
    setCurrentPage(parseInt(pageParam, 10) - 1);
  }, [pageParam]);

  useEffect(() => {
    dispatch(ContactPropertiesActions.getListProperties({}));
  }, []);
  const params = searchParams.get("page") || "1";
  const tagsRef = useRef([]);

  const columns = React.useMemo<ColumnDef<Object>[]>(() => {
      // Filter out the 'id' column
      const filteredColumns = dynamicsColumn.filter((col: any) => col.name !== "id");
      const columnsWithWidth = getColumnConfig(filteredColumns);

      // Ensure that 'name' column is detected as the first column
      const firstColumn = filteredColumns.find((col: any) => col.name === "name");

      const EdittableCell: React.FC<EdittableCellProps> = ( {info, col, firstColumnName} ) => {
        const initialValue = info.getValue();
        const [value, setValue] = useState(initialValue);
        const [cellData,setCellData] = useState({})
        const [isEditing, setIsEditing] = useState(false);
        const [combinedData,setCombinedData] = useState({
          contactId_list:selectedDocumentIds,
          data:cellData
        });

        const randomOffset = useMemo(
          () => Math.floor(Math.random() * randomBgColors.length),
          []
        );
        const [searchParams] = useSearchParams();
        const currentViewId = searchParams.get("view");
        useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        useEffect(() => {
          setCombinedData({
            contactId_list: selectedDocumentIds,
            data: cellData,
          });
        }, [selectedDocumentIds, cellData]);

        const onClickTags = (event: any,colData: any)=>{
          tagsRef.current = colData?.cell?.row?.original?.tags;
          setModalPos({
            top: event.clientY,
            left: event.clientX,
            visible: true
          });
          getContactTagsById(colData?.cell?.row?.original?.id);
          setTagsAnchorEl(event.currentTarget);
          setIsEditing(false)
        };

        const onClickLifeCycle = (event: any,colData: any) =>{
          setLifecycleAnchorEl(event.currentTarget);
          getContactDetails(colData?.cell?.row?.original?.id)
        }
      
        const onBlur = () => {
          setIsEditing(false);
          if (value !== initialValue) {
            updateData();
          }
        };

        const updateData = () => {
          setLoading(true);
          setIsUpdating(true)
          const page = Number(params) - 1;

            const rowCellId = info.table.options.meta?.updateData(info.row.index, info.column.id, value);
            const updatedCellData = {
              ...cellData,
              [info.column.id]: value,
            };
            setCellData(updatedCellData);

            const newCombinedData = {
              contactId_list: rowCellId,
              data: updatedCellData,
            };
            const filteredColumns = info?.table?.state?.columnOrder?.map((eachCol: any) => eachCol === "select" ? "id" : eachCol);
            const payload = {
              data: {
                columns: filteredColumns,
                page_number: page,
                view_id: currentViewId,
                page_size: 50
              }
            };
            setCombinedData(newCombinedData);
            if (Object.keys(updatedCellData).length > 0) {
              dispatch(
                ContactsActions.bulkUpdateContact({
                  selectAll: false,
                  ...newCombinedData,
                })
              )
                .then((res: any) => {
                  if (res?.error?.message === "Rejected") {
                    showAlert(res?.payload?.data?.message || "Contact not updated", "error");
                  } else {
                    dispatch(ContactsActions.getContactsListData(payload))
                      .then((res: any) => {
                        setIsUpdating(false)
                        setLoading(false);
                        showAlert("Contact updated successfully", "success");
                      })
                  }
                })
                .catch((error: any) => {
                  showAlert(error?.response?.statusText || "Contact not updated", "error");
                });
            }
        }
        const onMouseEnterTags = (event: React.MouseEvent, tags: any) => {
          const rect = event.currentTarget.getBoundingClientRect();
          setPopupPosition({
            top: rect.bottom + window.scrollY + 5, // 5px below the element
            left: rect.left + window.scrollX
          });
          setShowTagsPopup(true);
          setAllTags(tags);
        };

        const onMouseLeaveTags = () => {
          setShowTagsPopup(false);
        };
        
        const getArrow = (status: any) =>{
          switch(status){
            case "Won":
            return <StatusWonArrow />
          case "Lost":
            return <StatusLostArrow />
          default:
            return <StatusArrow />
          }
        }

        const handleKeyDown = (e: React.KeyboardEvent) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            if (value !== initialValue) {
              updateData();
            }
            setIsEditing(false);
          }
        };

        const onClickMulti = (event: any, data: any, info: any) => {
          setModalAnchorEl(event.currentTarget);
          dispatch(contactId(info?.cell?.row?.original?.id));
          setColData(data);
          setRowData(info?.row?.original)
        }
        const mulitiSelectionFields = ["dropdown", "radio","multiselect","checkbox"];
        // Use the column id (or accessor) to decide what to render
        const colId = info.column.id;
        if (!isEditing) {
          if (colId === "contact_owner") {
            return (
              <Container>
                {info.getValue() ? (
                  <ContactOwnerContainer>
                    <CrownImage style={{marginBottom:"4px"}} /> {info.getValue()}
                  </ContactOwnerContainer>
                ) : (
                  <div>-</div>
                )}
                {(col.type==="dropdown" && (col.read_only === null)) && <EditIcon onClick={(event) => onClickMulti(event,col,info)}  className="edit-icon" sx={{
                    borderRadius: '4px',
                    display: "flex",
                    position: "absolute",
                    right: "0px",
                    padding: '4px',
                    color: theme.palette.default.black,
                    fontSize: '24px',
                    cursor: 'pointer',
                    transition: 'border-color 0.2s ease-in-out',
                    border: "1px solid lightgrey",
                    backgroundColor: theme.palette.default.lightBeach,
                  }}/>}
              </Container>
            );
          } else if (colId === "tags") {
            return (
              <Container onClick={(event) => onClickTags(event, info)} style={{ cursor: "pointer" }}>
                {info.getValue() && info.getValue().length > 0 ? (
                  <>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      {info.getValue() && info?.getValue().slice(0, 5)?.map((eachTag: any, index: any) => (
                        <Tag key={index} bgColor={getRandomBgColor(index,randomOffset)} color={getRandomColor(index, randomOffset)} style={{ cursor: "pointer",fontSize: "10px" }}>
                          {typeof eachTag === 'string' ? eachTag.charAt(0).toUpperCase() + eachTag.slice(1) : ''}
                        </Tag>
                      ))}
        
                      {info.getValue().length > 5 && (
                        <Tag 
                          bgColor={getRandomBgColor(4)} 
                          onMouseEnter={(e) => onMouseEnterTags(e, info.getValue())}
                          onMouseLeave={onMouseLeaveTags}
                        >
                          +{info.getValue().length - 5}
                        </Tag>
                      )}
                    </div>
                  </>
                ) : (
                  <div style={{ color: "gray", cursor: "pointer" }}>+Click to add</div>
                )}
                {info.getValue().length > 0 && <EditIcon onClick={(event) => onClickTags(event, info)} className="edit-icon" sx={{
                    borderRadius: '4px',
                    display: "flex",
                    position: "absolute",
                    right: "0px",
                    padding: '4px',
                    color: theme.palette.default.black,
                    fontSize: '24px',
                    cursor: 'pointer',
                    transition: 'border-color 0.2s ease-in-out',
                    border: "1px solid lightgrey",
                    backgroundColor: theme.palette.default.lightBeach,
                    
                  }}/>}
              </Container>
            );
          }else if (colId === "lifecycle_stage_status") {
            const statusValue = String(info.getValue()).toLowerCase();
            return (
              <Container>
                {info.getValue() ? (
                  <LifecycleStatus status={String(info.getValue()).toLowerCase()}>
                    {getArrow(info.getValue())} {info.getValue()}
                  </LifecycleStatus>
                ) : (
                  <div>-</div>
                )}
                {<EditIcon onClick={(event)=>onClickLifeCycle(event,info)} className="edit-icon" sx={{
                    borderRadius: '4px',
                    display: "flex",
                    position: "absolute",
                    right: "0px",
                    padding: '4px',
                    color: theme.palette.default.black,
                    fontSize: '24px',
                    cursor: 'pointer',
                    transition: 'border-color 0.2s ease-in-out',
                    border: "1px solid lightgrey",
                    backgroundColor: theme.palette.default.lightBeach,
                    
                  }}/>}
              </Container>
            );
          }else if (colId === "lifecycle_stage") {
            return (
              <Container>
                {info.getValue() ? (
                  info.getValue()
                ) : (
                  <div>-</div>
                )}
                {<EditIcon onClick={(event)=>onClickLifeCycle(event,info)} className="edit-icon" sx={{
                    borderRadius: '4px',
                    display: "flex",
                    position: "absolute",
                    right: "0px",
                    padding: '4px',
                    color: theme.palette.default.black,
                    fontSize: '24px',
                    cursor: 'pointer',
                    transition: 'border-color 0.2s ease-in-out',
                    border: "1px solid lightgrey",
                    backgroundColor: theme.palette.default.lightBeach,
                  
                  }}/>}
              </Container>
            )
           } else {
             if(mulitiSelectionFields.includes(col?.type) && col.read_only === null){
              return (<Container  
              >
                <Text title={value}>{value !== undefined && value !== null && value !== "" ? value : "-"}</Text>
                {(mulitiSelectionFields.includes(col?.type) && col.read_only === null) && <EditIcon onClick={(event) => onClickMulti(event,col,info)} className="edit-icon"
                  sx={{
                    borderRadius: '4px',
                    display: "flex",
                    position: "absolute",
                    right: "0px",
                    padding: '4px',
                    color: theme.palette.default.black,
                    fontSize: '24px',
                    cursor: 'pointer',
                    transition: 'border-color 0.2s ease-in-out',
                    border: "1px solid lightgrey",
                    backgroundColor: theme.palette.default.lightBeach,
                  }}
                  />
                }
              </Container>
              )
             } 
            return (
              <Container  
              >
                <Text title={value}>{value !== undefined && value !== null && value !== "" ? value : "-"}</Text>
                {((col.read_only === null || col?.read_only === false) && col.type !== "dropdown" && col.type !== "multiselect" && col.type !== "radio" && colId !== "email" && colId !== "mobile_phone_number") && <EditIcon onClick={() => setIsEditing((col.read_only === null || col?.read_only === false))} className="edit-icon"
                  sx={{
                    borderRadius: '4px',
                    display: "flex",
                    position: "absolute",
                    right: "0px",
                    padding: '4px',
                    color: theme.palette.default.black,
                    fontSize: '24px',
                    cursor: 'pointer',
                    transition: 'border-color 0.2s ease-in-out',
                    border: "1px solid lightgrey",
                    backgroundColor: theme.palette.default.lightBeach,
                  }}
                  />
                }
              </Container>
            );
          }
        }
        // Edit mode: render a plain input field for editing
        return (
          <>
          {
          <input
            value={value as string}
            onChange={(e) => {setValue(e.target.value)}}
            onBlur={onBlur}
            autoFocus
            onKeyDown={handleKeyDown}
            style={{
              width: "100%",
              height: "37px",
              paddingLeft:"5px",
              paddingRight:"5px",
              borderRadius:"6px",
              border:`2px solid ${theme.palette.default.ceruleanBlue}`,
            }}
          />
          }
          </>
        );
      };

      
      
      return [
        {
          id: 'select',
          header: ({ table }) => (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
              />
            </div>
          ),
          cell: ({ row }) => (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          ),
          size: 40,
          minSize: 40,
          maxSize: 40,
        },
        ...columnsWithWidth.map((col: any) => {
          return {
            accessorKey: col.name,
            id: col.name,
            header: () => <span>{col.label}</span>,
            cell: (info: any) =>
              col.name === firstColumn?.name ? (
                <EditDelete id={info} info={info} />
              ) : (  
                <EdittableCell info={info} col={col} firstColumnName={firstColumn}/>
            ),
            size: col.size,
            minSize: col.minSize,
            enableColumnFilter: false,
            sortUndefined: 'last',
            sortDescFirst: false,
            enableResizing: true

          };
        }),    
      ];
    },
    [dynamicsColumn, windowDimensions]
  );

  const { showAlert } = useNotification();
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const { userConfig } = useAppSelector(useAuthStore);
  const ContactHistoryData = useSelector(getContactHistoryData);
  const isContactHistoryLoading = useSelector(getContactHistoryLoading);
  const isErrorInContactHistoryAPI = useSelector(getContactHistoryError);
  const paginationCount = useAppSelector(ContactListingPagination);
  const [dynamicsColumns, setDynamicsColumns] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [sortingT, setSortingT] = React.useState<SortingState>([])
  const [basicInfo] = useState<BasicInfo>({
    first_name: "Shrikant",
    last_name: "Dange",
    designername: "Graphics Designer",
    email: "shrikant.dange@srvmedia.com",
    emptyemail: "-",
    phone: "",
    workphone: "",
    birthdate: "",
    facebook: "",
    twitter: "",
    linkdin: "",
  });

  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [actionDrawerChildComponent, setActionDrawerChildComponent] =
    useState<any>(null);

  const getOrdinal = (number: number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  function formatDateToHTML(dateString: string) {
    // Create a new Date object from the given string
    const date = new Date(dateString);

    // Array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract day, month, and year using local time methods
    const day = date.getDate(); // Get local day
    const monthIndex = date.getMonth(); // Get local month index
    const year = date.getFullYear(); // Get local year
    let hours = date.getHours(); // Get local hours
    const minutes = date.getMinutes(); // Get local minutes

    // Determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Format the date as HTML string
    const formattedHTML = (
      <Box>
        <Box className="date-box">
          <div className="day">{day}</div>
          <div className="month-year">
            <span style={{ display: "block", fontWeight: "500" }}>
              {monthNames[monthIndex]}
            </span>
            <span style={{ display: "block" }}>{year}</span>
          </div>
        </Box>
        <div className="time">
          {hours < 10 ? "0" + hours : hours}:
          {minutes < 10 ? "0" + minutes : minutes} {ampm}
        </div>
      </Box>
    );

    return formattedHTML;
  }

  const toggleDrawerNew = (contactId: any) => {
    setIsOpen(true);
    let payload;
    if (contactId) {
      payload = {
        selectedColumns: [
          "referrerName",
          "module",
          "referrerUrl",
          "campaignName",
          "deviceType",
          "channel",
          "updatedAt",
          "campaignSource",
        ],
        contact_id: contactId.toString(),
      };
    }
    dispatch(ContactsActions.leadsForContact(payload));
  };
  // message
  const checkAllSelect: boolean =
    paginationCount?.totalRecords < localValues?.perPage
      ? false
      : (selectedDocumentIds?.length === data?.length && selectedDocumentIds?.length !== 0)
        ? true
        : false;

  const useUpDateContactModal = () => {
    const { isShowing, toggle } = useModal();
    return { isUpdateShowingModal: isShowing, toggleUpdateContact: toggle };
  };
  const { isUpdateShowingModal, toggleUpdateContact } = useUpDateContactModal();

  // getcontact by id*********************

  const getContactById = (contact_id: any) => {
    dispatch(ContactsActions.getEditContactsFilds(contact_id))
      .then((res: any) => {
        if (res?.payload?.data?.status !== 200) {
          showAlert(
            res?.payload?.data?.error || "Enabled to fetch Data",
            "error",
          );
        } else {
          dispatch(editId(contact_id));
          toggleContactModal();
        }
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error",
        );
      });
  };

  const getContactTagsById = (contact_id: any)=>{
    dispatch(ContactsActions.getEditContactsFilds(contact_id))
      .then((res: any) => {
        if (res?.payload?.data?.status !== 200) {
          showAlert(
            res?.payload?.data?.error || "Enabled to fetch Data",
            "error",
          );
        } else {
          dispatch(contactId(contact_id));
        }
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error",
        );
      });
  };
  const getContactDetails = (id: any) => {
    let payload = {
      contact_id: id
    }
    dispatch(ContactsActions.getBasicInfo(payload))
      .then((res: any)=>{
        if (res?.payload?.status !== 200) {
          showAlert(
            res?.payload?.data?.error || "Enabled to fetch Data",
            "error",
          );
        } else {
          dispatch(contactId(id));
          toggleLifecycle();
        }
      })
  }

  const getContactDetailsById=(contact_id: any)=>{
    dispatch(ContactsActions.getBasicInfo({contact_id}))
      .then((res: any) => {
        if (res?.payload?.status !== 200) {
          showAlert(
            res?.payload?.data?.error || "Enabled to fetch Data",
            "error",
          );
        } else {
          dispatch(contactId(contact_id));
          toggleContactDetailsModal();
        }
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error",
        );
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  const colorPairs = [
    {
      backgroundColor: theme.palette.default.karryl,
      color: theme.palette.default.sandal,
    },
    {
      backgroundColor: theme.palette.default.onahau,
      color: theme.palette.default.horizon,
    },
    {
      backgroundColor: theme.palette.default.pippin,
      color: theme.palette.default.coralTree,
    },
    {
      backgroundColor: theme.palette.default.snowyMint,
      color: theme.palette.default.aquaForest,
    },
    {
      backgroundColor: theme.palette.default.cumulus,
      color: theme.palette.default.sycamore,
    },
  ];
  let prev = 0;
  const getColors = (word: string) => {
    if (!word || typeof word !== "string") {
      return {
        bg: theme.palette.default.karryl,
        textColor: theme.palette.default.sandal,
      };
    }
    const firstLetter = word.charAt(0);

    // Convert the letter to lowercase to handle both uppercase and lowercase letters
    const lowerCaseLetter = firstLetter.toLowerCase();

    // Check if the character is a valid lowercase letter
    if (/^[a-z]$/.test(lowerCaseLetter)) {
      // Get the ASCII code of the letter and subtract the ASCII code of 'a' to get the position
      const position = lowerCaseLetter.charCodeAt(0) - "a".charCodeAt(0) + 1;
      let colorIndex = position % colorPairs.length;
      if (prev === colorIndex) {
        prev = colorIndex + 1;
      } else {
        prev = colorIndex;
      }
      return colorPairs[prev];
    } else {
      return {
        bg: theme.palette.default.karryl,
        textColor: theme.palette.default.sandal,
      };
    }
  };
  const FirstColumn = (original: any, firstObjectKey: any) => {
    return original?.first_name && original?.last_name
      ? `${original?.first_name} ${original?.last_name}`
      : original?.first_name
        ? original?.first_name
        : original?.last_name
          ? original?.last_name
          : original?.email &&
            (original?.[firstObjectKey]?.country_code ||
              original?.[firstObjectKey]?.extension)
      ? `${original?.email} ${
          original?.[firstObjectKey]?.country_code ||
            original?.[firstObjectKey]?.extension +
            original?.[firstObjectKey]?.number
            }`
            : original?.email
              ? original?.email
              : original?.[firstObjectKey]?.country_code ||
                original?.[firstObjectKey]?.extension
                ? (original?.[firstObjectKey]?.country_code ||
                  original?.[firstObjectKey]?.extension) +
                original?.[firstObjectKey]?.number
                : "-";
  };

  //  columns ============**********
  const nameTooltipComponent = (elm: any, index: number) => {
    return columnHelper.accessor(elm?.name, {
      header: headerName(elm?.label, elm),
      id: elm?.name,
      size: 320,
      minSize: 320,
      cell: (info: any) => {
        const original = info?.cell?.row?.original;
        let firstObjectKey: any = null;
        if (!(original?.first_name || original?.last_name || original?.email)) {
          for (const key in original) {
            if (
              typeof original[key] === "object" &&
              original[key] !== null &&
              key !== "contact_tool_tip"
            ) {
              firstObjectKey = key;
              break;
            }
          }
        }
        return (
          <NameWrapper>
            <AppToolTip
              tooltipProps={{
                placement: "right",
              }}
              iconButtonProps={{
                disableRipple: true,
                style: { padding: 0 },
              }}
            >
              <div
                className="avatar long-word"
                title={FirstColumn(original, firstObjectKey) || "-"}
                style={{
                  display: "flex",
                  fontSize: "14px",
                  gap: "1rem",
                  alignItems: "center",
                  fontWeight: "400",
                  cursor: "pointer",
                  color: removeContactDetailsLink
                    ? theme.palette.primary.main
                    : theme.palette.default.black,
                }}
                onClick={() =>
                  removeContactDetailsLink
                    ? {}
                    : navigate(
                      `/contacts/view/${info.cell.row.original?.id}/basic-information`,
                    )
                }
              >
                <Avatar
                  alt={
                    original?.first_name ||
                    original?.last_name ||
                    original?.email ||
                    ""
                  }
                  src=""
                  sx={getColors(
                    original?.first_name ||
                    original?.last_name ||
                    original?.email,
                  )}
                  style={{ width: "25px", height: "25px", fontSize: "13px" }}
                >
                  {typeof original?.first_name === "string" ? original?.first_name?.charAt(0) || "G" : "G"}
                </Avatar>
                <span
                  style={
                    !removeContactDetailsLink
                      ? {
                        textDecoration: "underline",
                        fontSize: "13px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }
                      : {
                        fontSize: "13px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: theme.palette.default.black,
                      }
                  }
                >
                  {FirstColumn(original, firstObjectKey) || "-"}
                </span>
              </div>
            </AppToolTip>
            {<EditDelete id={info} />}
          </NameWrapper>
        );
      },
    });
  };





  // Memoized Icon Action component
  const IconAction = React.memo(({
    enabled = true,
    icon: Icon,
    title,
    onClick,
    iconStyle
  }: {
    enabled?: boolean;
    icon: React.ComponentType<any>;
    title: string;
    onClick: (e: React.MouseEvent) => void;
    iconStyle: React.CSSProperties;
  }) => {
    if (!enabled) return null;

    return (
      <IconButton onClick={onClick} title={title}>
        <Icon style={iconStyle} />
      </IconButton>
    );
  });

  // Memoized Action Buttons component
  const ActionButtons = React.memo(({
    userConfig,
    handleIconAction,
    iconStyles,
    taskIconStyles
  }: any) => {
    return (
    <div style={{display: 'flex', gap: '0px'}}>
        <IconAction
          enabled={userConfig?.payload?.email_enabled}
          icon={EmailIcon}
          title="Email"
          onClick={(e) => handleIconAction(e, "email")}
          iconStyle={iconStyles}
        />
        <IconAction
          enabled={userConfig?.payload?.sms_enabled}
          icon={SmsOutlinedIcon}
          title="SMS"
          onClick={(e) => handleIconAction(e, "sms")}
          iconStyle={iconStyles}
        />
        <IconAction
          enabled={userConfig?.payload?.whatsapp_enabled}
          icon={WhatsAppIcon}
          title="WhatsApp"
          onClick={(e) => handleIconAction(e, "whatsapp")}
          iconStyle={iconStyles}
        />
        <IconAction
          icon={ChangeCircleOutlinedIcon}
          title="Change Lifecycle Stage"
          onClick={(e) => handleIconAction(e, "lifecycle-stage")}
          iconStyle={iconStyles}
        />
        <IconAction
          icon={NotesIcon}
          title="Manage Notes"
          onClick={(e) => handleIconAction(e, "notes")}
          iconStyle={iconStyles}
        />
        <IconAction
          icon={TaskIcon}
          title="Manage Task"
          onClick={(e) => handleIconAction(e, "task")}
          iconStyle={taskIconStyles}
        />
      </div>
    );
  });

  const EditDeleteComponent: React.FC<any> = ({
    id,
  }) => {
    const [showActions, setShowActions] = useState<boolean>(false);
    const rowItem = id.cell.row.original;
    const rowId = rowItem.id;
    const [anchorEl, setAnchorEl ] = useState<any>();
  
    const open = Boolean(anchorEl);

    const handleClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = React.useCallback(() => {
      setAnchorEl(null);
    }, []);

    const handleDetails = React.useCallback((id: string) => {
      requestAnimationFrame(() => {
        getContactById(id);
      });
    }, [getContactById]);

    const handleContactDetails = React.useCallback((id: string) => {
      requestAnimationFrame(() => {
        getContactDetailsById(id)
      })
    },[getContactDetailsById])

    const deleteRow = React.useCallback(() => {
      toggleModal2();
      dispatch(editId(rowId));
    }, [toggleModal2, dispatch, editId, rowId]);

    const handleIconAction = React.useCallback((
      e: React.MouseEvent,
      iconType: "whatsapp" | "email" | "sms" | "lifecycle-stage" | "notes" | "task"
    ) => {
      if (!rowItem?.can_update) return;
      setActionDrawerChildComponent({ type: iconType, id: rowId });
    }, [rowItem?.can_update, rowId, setActionDrawerChildComponent]);

    const fullName = React.useMemo(() => {
      const { first_name, last_name } = rowItem;
      return [first_name, last_name].filter(Boolean).join(" ") || "-";
    }, [rowItem]);

    const avatarInitial = React.useMemo(() =>
      rowItem?.first_name?.charAt(0) || "G"
      , [rowItem?.first_name]);


    ;
    let firstObjectKey: any = null;
    if (!(rowItem?.first_name || rowItem?.last_name || rowItem?.email)) {
      for (const key in rowItem) {
        if (
          typeof rowItem[key] === "object" &&
          rowItem[key] !== null &&
          key !== "contact_tool_tip"
        ) {
          firstObjectKey = key;
          break;
        }
      }
    }

    const styles = {
      avatar: {
        width: "25px",
        height: "25px",
        fontSize: "13px"
      },
      nameSpan: {
        maxWidth: "100%",
        color: theme.palette.default.ceruleanBlue,
        fontSize: "13px",
        fontWeight: 400,
        overflow: "hidden",
        textDecoration: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap" as const,
        cursor: "pointer"
      },
      icon: {
        width: '14px',
        height: '14px',
        fill: theme.palette.default.fiord,
      },
      taskIcon: {
        width: '12px',
        height: '12px'
      },
      wrapper: {
        display: "flex",
        alignItems: "center",
        gap: "6px",
        flex: "1 1 auto",
        overflow: "hidden",
      }
    };

    const menuPaperStyles = {
      width: "197px",
      maxWidth: "197px",
      minWidth: "197px",
      backgroundColor: theme.palette.default.white,
      borderRadius: "4px",
      boxShadow: `0 4px 16px ${theme.palette.default.borderBlack}`,
      padding: "6px 0",
    } as const;

    return (
      <div style={{ width: '100%', display: 'flex'}}
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}>
        <div style={styles.wrapper}>
          <Avatar
            src=""
            style={styles.avatar}
            sx={getColors(
              rowItem?.first_name ||
              rowItem?.last_name ||
              rowItem?.email,
            )}
          >
            {avatarInitial}
          </Avatar>
          <span style={styles.nameSpan} onClick={() =>
            removeContactDetailsLink
              ? {}
              :handleContactDetails(rowId)
          } >
            {FirstColumn(rowItem, firstObjectKey) || "-"}
          </span>
        </div>

        <div className="buttonWrapper" style={{ display: 'flex', gap: '8px' }}>
          <div style={{
            opacity: showActions ? 1 : 0,
            transition: 'opacity 0.2s ease-in-out',
          }}>
            {
              showActions && <ActionButtons
                userConfig={userConfig}
                handleIconAction={handleIconAction}
                iconStyles={styles.icon}
                taskIconStyles={styles.taskIcon}
              />
            }

          </div>

          <IconButton onClick={handleClick}>
            <VertOutlinedIcon style={{ width: '14px', height: '14px' }} />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            slotProps={{
              paper: {
                sx: menuPaperStyles,
              },
            }}
          >
            <List>
              {rowItem?.can_update && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      handleDetails(rowId);
                      handleClose();
                    }}
                  >
                    Edit
                  </ListItemButton>
                </ListItem>
              )}

              {rowItem?.can_delete && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      deleteRow();
                      handleClose();
                    }}
                  >
                    Delete
                  </ListItemButton>
                </ListItem>
              )}

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    toggleDrawerNew(rowId);
                    handleClose();
                  }}
                >
                  Recurring Visits
                </ListItemButton>
              </ListItem>
            </List>
          </Menu>
        </div>
      </div>
    );
  };

  const EditDelete = React.memo(EditDeleteComponent);





  const columnHelper = createColumnHelper<any>();
  let idColumn: any = null; // Variable to store the column with "id" header
  let temp: any = [];

  const otherColumns = (data: any, name: any) => {
    // const regex =
    //   /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun), (0[1-9]|[12][0-9]|3[01]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4} \d{2}:\d{2}:\d{2} GMT$/;
    // if (typeof data[name] === "string" && regex.test(data[name])) {
    //   return moment(data[name]).format("ddd, DD MMM, YYYY hh:mm A");
    // }
    return data[name] && data[name]?.number
      ? "+ " +
      (data[name]?.country_code
        ? data[name]?.country_code
        : data[name]?.extension) +
      " " +
      data[name]?.number
      : Array.isArray(data[name]) && data[name]?.[0]?.label
        ? data[name]?.map((item: any) => item.label).join(", ")
        : Array.isArray(data[name])
          ? RenderArrayValues(data[name])
          : data[name]
            ? data[name]
            : "-";
  };

  const renderColumnData = (info: any, elm: any) => {
    let data = info.cell.row.original;
    return (
      <span
        className={`long-word ${
          Array.isArray(data[elm?.name]) ? "overflow-visible" : ""
          }`}
        title={
          Array.isArray(data[elm?.name]) ? "" : otherColumns(data, elm?.name)
        }
      >
        <span>{otherColumns(data, elm?.name) || "-"}</span>
      </span>
    );
  };
  let headerName = (label: any, elm: any) => {
    if (localValues?.sort_by === 1 && localValues?.sort_field === elm?.name) {
      return label;
    } else if (
      localValues?.sort_by === -1 &&
      localValues?.sort_field === elm?.name
    ) {
      return label;
    } else {
      return label;
    }
  };
  const CreateCoulumns = () => {
    if (dynamicsColumn?.length !== 0) {
      dynamicsColumn?.forEach((elm: any, index: number) => {
        let colData;
        if (elm?.name !== "id" && elm?.name !== "name") {
          colData = columnHelper.accessor(elm?.name, {
            header: headerName(elm?.label, elm),
            id: elm?.name,
            cell: (info: any) => renderColumnData(info, elm),
            size: 190,
            minSize: 190,
          });
          temp.push(colData);
        } else {
          if (elm?.name === "name") {
            let tollTip: any = nameTooltipComponent(elm, index);
            temp?.push(tollTip);
          }
        }
        if (elm?.name === "id") {
          idColumn = columnHelper.accessor(elm?.name, {
            id: "select",
            size: 10,
            maxSize: 10,
            header: ({ table }) => (
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
              />
            ),
            cell: ({ row }) => (
              <IndeterminateCheckbox
                {...{
                  checked: row?.getIsSelected(),
                  disabled: !row?.getCanSelect(),
                  indeterminate: row?.getIsSomeSelected(),
                  onChange: row?.getToggleSelectedHandler(),
                }}
              />
            ),
            footer: (info: any) => info.column.id,
          });
        }
      });
      temp.unshift(idColumn);
      setDynamicsColumns(temp);
    }
  };

  useEffect(() => {
    if (dynamicsColumn && dynamicsColumn?.length > 0) {
      CreateCoulumns();
    }
  }, [data, dynamicsColumn]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues &&
      setLocalValues({
        ...localValues,
        perPage: value,
      });
    // setShouldApply(true);
  };
  const getSortData = (data: any) => {
    setLocalValues &&
      setLocalValues({
        ...localValues,
        sort_field: data[0]?.id,
        sort_by: data[0]?.desc ? -1 : 1,
        defaultApply: true,
      });
  };


  const handleSortChange = (sortData: { id: string; desc: boolean }[]) => {
    getSortData(sortData);
  };


  const menuActions = React.useMemo(() => [
    // {
    //   icon: <ChangeCircleOutlinedIcon />,
    //   label: 'Delete',
    //   onClick: deleteRow
    // },

    {
      icon: <ChangeCircleOutlinedIcon />,
      label: 'Recurring Visits',
      onClick: toggleDrawerNew
    }
  ], [toggleDrawerNew]);
  return (
    <React.Fragment>
      <div style={{position: "relative"}}>
      {(isContactHistoryLoading && data.length === 0) ? (
              <ShadesSkeletonLoader />
            ) : data.length === 0 ? (
              <Box
                sx={{
                  height: "calc(100vh - 170px)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ContactNodata
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "4rem 2rem 2rem 2rem",
                    boxShadow: "none !important",
                    backgroundColor: "white",
                  }}
                >
                  <Paper
                    sx={{
                      width: "40%",
                      mb: 2,
                      boxShadow: "none !important",
                      backgroundColor: "transparent",
                    }}
                  >
                    <NoDataBox>
                      <img src={NoDataImage} alt="no data available" />
                      <p>Contact Not Found</p>
                      {type === "segment" ? null : (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AppButton
                            style={{ margin: "1rem" }}
                            variant="contained"
                            startIcon="add"
                            onClick={() => toggleContactModal()}
                          >
                            Add Contact
                          </AppButton>
                          <span>Or</span>
                          <AppButton
                            style={{ margin: "1rem" }}
                            variant="contained"
                            startIcon="add"
                            onClick={() => createImportDoc()}
                          >
                            Import Contact
                          </AppButton>
                        </Box>
                      )}
                    </NoDataBox>
                  </Paper>
                </ContactNodata>
              </Box>
            ) : (
        <React.Fragment>
          <div className="data-container" style={{backgroundColor:theme.palette.default.white}}>
            {checkAllSelect ? (
              <div
                style={{
                  textAlign: "center",
                  backgroundColor: theme.palette.default.linkWater,
                  display:
                    hasUpdateAceess === "global_access" ? "block" : "none",
                }}
              >
                <>
                  {selectAll
                    ? "Clear selection"
                    : `${selectedDocumentIds?.length} contacts selected`}
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      setSelectedDocumentIds((prevState: any) => {
                        if (selectAll) {
                          setSelectAll(false);
                          return false;
                        } else {
                          setSelectAll(true);
                          return prevState;
                        }
                      });
                    }}
                  >
                    {selectAll
                      ? `${
                          totalCount
                        ? totalCount
                        : paginationCount?.totalRecords
                      } contacts selected.`
                      : `Select all ${
                          totalCount
                        ? totalCount
                        : paginationCount?.totalRecords
                      } contacts ?`}
                  </Button>
                  <IconButton
                    size="small"
                    style={{ color: theme.palette.default.black }}
                    disableRipple={true}
                    onClick={() => {
                      setSelectedDocumentIds(false);
                      setSelectAll(false);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </>
              </div>
            ) : null}
            {/* table */}
                <AppMasterTable
                  setSelectedRow={setSelectedDocumentIds}
                  selectedRow={selectedDocumentIds}
                  data={data}
                  loading = {loading}
                  setLoading = {setLoading}
                  columns={columns}
                  loader={loader}
                  hideSelection={hideSelection}
                  onSortChange={handleSortChange}
                  enableColumnResize={true}
                  setIsUpdating = {setIsUpdating}
            // enableMemoizedBody={true}
                />



            {/* <AppCustomTable
              data={data}
              dynamicsColumns={dynamicsColumns}
              setSelectedRow={setSelectedDocumentIds}
              selectedRow={selectedDocumentIds}
              getSortData={getSortData}
              loader={loader}
              removeContactDetailsLink={removeContactDetailsLink}
              sortingApply={sortingApply}
              hideSelection={hideSelection}
            /> */}
          </div>

          {!hidePagination && data && data?.length && !loader ? (
            <AppPaginationComponent
              totalCount={paginationCount?.totalRecords}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={paginationCount?.totalPages}
              rowsPerPage={localValues?.perPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              title="Contacts"
            />
          ) : null}
        </React.Fragment>
      )}

      {/* basic inforamtion edit */}
      <AppModel
        isShowing={isUpdateShowingModal}
        onClose={() => toggleUpdateContact()}
        divStyle={{
          width: "671px",
          //   height:'371px',
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <LeadPageWrapper>
          <div className="social-media-popup">
            <div className="popup-header">
              <div className="popup-heading-text">
                <h4>Basic Information</h4>
              </div>
              <div className="popup-heading-icon">
                <IconButton onClick={() => toggleUpdateContact()}>
                  <TagCrossIcon />
                </IconButton>
              </div>
            </div>
          </div>

          <AppForm
            initialValues={basicInfo}
            onSubmit={(values: any, submitProps: any) => {
              // handleSubmitBtn(values, submitProps);
            }}
          >
            <div className="basic-info-popup">
              <div className="fname">
                <div className="input-field">
                  <AppFormField
                    name={"firstname"}
                    label="First Name"
                  // sendValuesToParent={(values: any) => sendValuesToParent(values)}
                  />
                </div>
                <div className="input-field">
                  <AppFormField name={"last_name"} label="Last Name" />
                </div>
              </div>
              <div className="input-field">
                <AppFormField name={"designername"} label="Job title" />
              </div>

              <div className="email-verification">
                <div className="input-field-email">
                  <AppFormField name={"email"} label="Email" />
                </div>
                <div className="verify-email-button">
                  <AppButton variant="outline">Verify email</AppButton>
                </div>
              </div>

              <div className="input-field">
                <AppFormField name={"emptyemail"} label="Email" />
              </div>
              <div className="input-field">
                <AppFormField name={"phone"} label="Phone" />
              </div>

              <div className="input-field">
                <AppFormField name={"workphone"} label="work Phone" />
              </div>
              <div className="input-field">
                <AppFormField name={"birthdate"} label="D. O. B." />
              </div>
            </div>

            <div className="mark-complete-buttons">
              <AppButton onClick={() => toggleUpdateContact()} variant="grey">
                cancel
              </AppButton>
              <AppButton type="submit">
                <IconButton>
                  <SaveIcon />
                </IconButton>
                save
              </AppButton>
            </div>
          </AppForm>
        </LeadPageWrapper>
      </AppModel>

      {/* Drawer */}
      {isOpen && (
        <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
          <Box
            p={2}
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              backgroundColor: theme.palette.default.GhostWhite,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              fontSize={"16px"}
              fontWeight={500}
            >
              Recurring Visits
            </Typography>
            <Box onClick={() => setIsOpen(false)} sx={{ cursor: "pointer" }}>
              <AppMaterialIcons iconName="Close" />
            </Box>
          </Box>
          <Box
            sx={{
              width: "80vw",
              p: 3,
              height: "calc(100% - 78px)",
              overflow: "auto",
            }}
            role="presentation"
          >
            {isContactHistoryLoading ? (
              <AppSkeletonTableLoader
                numberOfCol={1}
                numberofRow={10}
                skeletonHeight={30}
                skeletonWidth={"100%"}
                skeletonHeaderHeight={40}
                skeletonHeaderWidth={"100%"}
                tableHeight={`calc(100vh - 128px)`}
              />
            ) : isErrorInContactHistoryAPI ? (
              <NoRecordsFound
                headerText="Something Went Wrong!"
                subText="Error Loading Data.."
                height="calc(100vh - 126px)"
              />
            ) : ContactHistoryData.length === 0 ? (
              <NoRecordsFound height="calc(100vh - 126px)" />
            ) : (
              ContactHistoryData.map((item: any, index: number) => {
                const reverseIndex = ContactHistoryData.length - index;
                const ordinalIndex = getOrdinal(reverseIndex);
                let backgroundColor;
                switch (reverseIndex % 4) {
                  case 1:
                    backgroundColor = "#FFF854";
                    break;
                  case 2:
                    backgroundColor = "#70DDFF";
                    break;
                  case 3:
                    backgroundColor = "#FFBD6F";
                    break;
                  case 0:
                    backgroundColor = "#47FFC8";
                    break;
                  default:
                    backgroundColor = "transparent";
                }
                return (
                  <ListItem disablePadding sx={{ mb: 4 }}>
                    <ContactHitstoyWrapper>
                      <Box className="details-left">
                        {formatDateToHTML(item?.updatedAt)}
                        <Box sx={{ backgroundColor }} className="visit">
                          {ordinalIndex} Visit
                        </Box>
                        <Box textAlign={"center"}>
                          {ordinalIndex === "1st" ? null : (
                            <img
                              width={"12px"}
                              src={HistoryArrowIcon}
                              alt="History Arrow"
                            />
                          )}
                        </Box>
                      </Box>
                      <Box className="history-content_box">
                        <Box className="user-details border-r">
                          <Box
                            mb={"4px"}
                            className="username"
                            title={item?.visitor_name || "N/A"}
                          >
                            {item?.visitor_name || "N/A"}
                          </Box>
                          <Box
                            title={item?.visitor_email || "N/A"}
                            mb={"4px"}
                            className="userText"
                          >
                            {item?.visitor_email || "N/A"}
                          </Box>
                          <Box
                            title={item?.visitor_phone || "N/A"}
                            mb={"4px"}
                            className="userText"
                          >
                            {item?.visitor_phone || "N/A"}
                          </Box>
                        </Box>

                        <Box
                          width={"calc(100% - 210px)"}
                          display={"flex"}
                          alignItems={"center"}
                          pl={3}
                        >
                          <Grid container spacing={3}>
                            <Grid item xl={2} lg={4} xs={6}>
                              <Box className="user-details_2">
                                <Box className="username">Referrer Name</Box>
                                <Box
                                  className="userText"
                                  title={item?.referrerName || "N/A"}
                                >
                                  {item?.referrerName || "N/A"}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xl={2} lg={4} xs={6}>
                              <Box className="user-details_2">
                                <Box className="username">Module</Box>
                                <Box
                                  className="userText"
                                  title={item?.module || "N/A"}
                                >
                                  {item?.module || "N/A"}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xl={2} lg={4} xs={6}>
                              <Box className="user-details_2">
                                <Box className="username">Referrer Url</Box>
                                <Box
                                  title={item?.referrerUrl || "N/A"}
                                  className="userText"
                                >
                                  {item?.referrerUrl || "N/A"}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xl={2} lg={4} xs={6}>
                              <Box className="user-details_2">
                                <Box className="username">Campaign</Box>
                                <Box
                                  title={item?.campaignName || "N/A"}
                                  className="userText"
                                >
                                  {item?.campaignName || "N/A"}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xl={2} lg={4} xs={6}>
                              <Box className="user-details_2">
                                <Box className="username">Device Type</Box>
                                <Box
                                  title={item?.deviceType || "N/A"}
                                  className="userText"
                                >
                                  {item?.deviceType || "N/A"}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xl={2} lg={4} xs={6}>
                              <Box className="user-details_2">
                                <Box className="username">Channel</Box>
                                <Box
                                  title={item?.channel || "N/A"}
                                  className="userText"
                                >
                                  {item?.channel || "N/A"}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xl={2} lg={4} xs={6}>
                              <Box className="user-details_2">
                                <Box className="username">Source</Box>
                                <Box
                                  title={item?.campaignSource || "N/A"}
                                  className="userText"
                                >
                                  {item?.campaignSource || "N/A"}
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </ContactHitstoyWrapper>
                  </ListItem>
                );
              })
            )}
          </Box>
        </Drawer>
      )}

      <Drawer
        anchor="right"
        open={actionDrawerChildComponent?.type !== "task" && actionDrawerChildComponent?.type?.length > 0 ? true : false}
        onClose={undefined}
        sx={{
          "& .MuiDrawer-paper": {
            width: "600px",
            padding: "0px",
            overflow: "hidden",
          },
        }}
      >
        {actionDrawerChildComponent?.type === "whatsapp" ? (
          <WhatsappSingleBroadcast
            actionDrawerChildComponent={actionDrawerChildComponent}
            setActionDrawerChildComponent={setActionDrawerChildComponent}
          />
        ) : null}
        {actionDrawerChildComponent?.type === "email" ? (
          <EmailSingleBroadcast
            actionDrawerChildComponent={actionDrawerChildComponent}
            setActionDrawerChildComponent={setActionDrawerChildComponent}
          />
        ) : null}
        {actionDrawerChildComponent?.type === "sms" ? (
          <SmsSingleBroadcast
            actionDrawerChildComponent={actionDrawerChildComponent}
            setActionDrawerChildComponent={setActionDrawerChildComponent}
          />
        ) : null}
        {actionDrawerChildComponent?.type === "lifecycle-stage" ? (
          <ChangeLifeCycleStage
            actionDrawerChildComponent={actionDrawerChildComponent}
            setActionDrawerChildComponent={setActionDrawerChildComponent}
          />
        ) : null}
        {actionDrawerChildComponent?.type === "notes" ? (
          <ChangeNotes
            actionDrawerChildComponent={actionDrawerChildComponent}
            setActionDrawerChildComponent={setActionDrawerChildComponent}
          />
        ) : null}
      </Drawer>
      {actionDrawerChildComponent?.type === "task" ? (
          <Task taskId={""} onClose = {() => setActionDrawerChildComponent(null)} open={actionDrawerChildComponent} getAllTaskList={() => {}}  />
      ) : null}
      {tagsRef.current &&
        <InlineTagsUpdate 
          // toggleTagsModal={toggleTagsModal}
          tagsAnchorEl = {tagsAnchorEl}
          setTagsAnchorEl = {setTagsAnchorEl}
          columns = {columns}
          existedTags = {tagsRef.current}
          modalPos = {modalPos}
          type= {"add"}
          setLoading = {setLoading}
          setIsUpdating = {setIsUpdating}
        />
    
    }

    {
      (
        <div
        >
          <LifeCycleUpdate
            columns= {columns}
            anchorEl = {lifeCycleAnchorEl}
            setAnchorEl={setLifecycleAnchorEl}
            setLoading = {setLoading}
            setIsUpdating = {setIsUpdating}
          />
        </div>
      )
    }
    {
      (
        <div>
          {(colData) && <UpdateInlineContactFields modalAnchorEl= {modalAnchorEl} setModalAnchorEl = {setModalAnchorEl} setLoading = {setLoading} columns = {columns} colData={colData} rowData = {rowData} setIsUpdating={setIsUpdating} />}
        </div>
      )
    }
        <Popup 
        visible={showTagsPopup} 
        position={popupPosition}
      >
        <h4 style={{marginBottom:"5px"}}>Tags</h4>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
          {allTags?.map((eachTag: any, index: any) => (
            <Tag key={index} bgColor={getRandomBgColor(index)}>
            {eachTag[0].toUpperCase() + eachTag.slice(1)}
          </Tag>
          ))}
        </div>
      </Popup>
    </div>
    </React.Fragment>
  );
};

export default LeadTable;

// First, let's define the IndeterminateCheckbox component
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, checked, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef();
    const resolvedRef = (ref || defaultRef) as React.MutableRefObject<HTMLInputElement>;

    React.useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate ?? false;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <input
        type="checkbox"
        ref={resolvedRef}
        checked={checked ?? false}
        {...rest}
      />
    );
  }
);

const ContactNodata = styled(Box)`
  && {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4rem 2rem 2rem 2rem;
    box-shadow: none !important;
  }
`;
const ContactOwnerContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Tag = styled("div")<TagProps>`
  display: inline-block;
  background-color: ${({ bgColor }) => bgColor};
  padding: 4px 8px;
  border-radius: 4px;
  color: ${({color}) => color};
`;

const FooterRow = styled("p")`
  font-size: 13px;
  color: ${theme.palette.default.black};
  display: flex;
  align-items: center;
  padding: 5px 15px;
`;
const NameWrapper = styled("div")`
  display: flex;
  align-items: center;
  position: relative;
  // max-width: 250px;
  .buttonWrapper {
    position: absolute;
    right: 0px;
    background: ${theme.palette.default.white};
    box-shadow: 0px 0px 5px -1px ${theme.palette.default.lightShadow};
    top: -3px;
    display: none;
    padding: 1px 4px;
    .MuiIconButton-root {
      padding: 6px;
    }
    svg {
      font-size: 15px;
    }
  }
  &:hover {
    .buttonWrapper {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

const LifecycleStatus = styled("div")<{ status: string }>`
  background-color: ${({ status }) =>
    status === "lost"
      ? theme.palette.default.lavenderBlush
      : status === "won"
      ? theme.palette.default.iceBerg
      : theme.palette.default.pattensBlu};
  color: ${({ status }) =>
    status === "lost"
      ? theme.palette.default.extraValencia
      : status === "won"
      ? theme.palette.default.tropicalRainForest
      : theme.palette.default.cello};
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
  text-transform: capitalize;
`;

const ContactHitstoyWrapper = styled("div")`
  && {
    width: 100%;
    gap: 24px;
    display: flex;
    .details-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      .visit {
        font-size: 12px;
        border-radius: 2px;
        padding: 6px 8px;
      }
      .time {
        text-align: center;
        margin-top: 4px;
        font-size: 13px;
        color: ${theme.palette.default.darkGrey};
      }
    }
    .date-box {
      max-height: 64px;
      border-radius: 4px;
      min-width: 106px;
      width: 106px;
      display: flex;
      justify-content: center;
      gap: 8px;
      align-items: center;
      background-color: ${theme.palette.primary.dark};
      .day {
        font-size: 32px;
        font-weight: 700;
        color: ${theme.palette.default.white};
      }
      .month-year {
        color: ${theme.palette.default.white};
      }
    }
    .history-content_box {
      width: calc(100% - 106px);
      background-color: #fafafb;

      border: 1px solid ${theme.palette.default.border};
      border-radius: 4px;
      padding: 20px;
      display: flex;
      flex-wrap: wrap;

      .border-r {
        border-right: 1px dashed ${theme.palette.default.border};
      }

      .user-details {
        // padding: 16px;
        min-width: 210px;
        max-width: 210px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .username {
          max-width: 190px;
          font-size: 16px;
          font-weight: 500;
          color: ${theme.palette.default.black};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .userText {
          max-width: 190px;
          font-size: 14px;
          color: ${theme.palette.default.darkGrey};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .user-details_2 {
        padding-bottom: 8px;
        // min-width: 210px;
        .username {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 4px;
          color: ${theme.palette.default.black};
        }
        .userText {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 13px;
          color: ${theme.palette.default.darkGrey};
        }
      }
    }
  }
`;



const CheckBox = styled("div")`
display: flex;
align-items: center;
justify-content: center; 
cursor: pointer
input {
border: 1px solid red;
}
`;

const Container = styled("div")`
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 0.25rem;
  display: flex;
  padding-right: 0px;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:hover .edit-icon {
    opacity: 1;
  }
`;

// Styled edit icon
const EditIcon = styled(CreateOutlinedIcon)`
  font-size: 23px !important;
  color: gray;
  border-radius: 4px;
  opacity: 0; 
  transition: opacity 0.2s ease-in-out;
`;
const Text = styled("span")`
  max-width: 130px; /* Limit width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

const Popup = styled("div")<{visible: boolean; position: {top: number; left: number}}>`
  position: fixed;
  top: ${props => props.position.top}px;
  left: ${props => props.position.left}px;
  background-color: ${theme.palette.default.white};
  box-shadow: 0px 4px 6px ${theme.palette.default.lightShadow};
  padding: 15px;
  border-radius: 4px;
  z-index: 999;
  white-space: nowrap;
  display: ${props => props.visible ? "block" : "none"};
  gap: 5px;
  width: 400px;
`;
