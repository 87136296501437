import React, { useEffect, useState } from 'react';
import { MenuItem, IconButton } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/minus.svg';
import styled from 'styled-components';
import { StyledInput } from '../style';
import { useAppSelector } from '../../../Store';
import { getContactPropertiesOptions } from '../../../Store/Slices/APILibrary/apilibrary.selectors';

// Define the types for props
interface ContactProperties {
  api_field_name: string;
  mapping_name: string | null;
  default_value: string | null;
}

interface DynamicTableProps {
  contactProperties: ContactProperties[];
  edit: boolean;
  editMapField: (type: string, index: number, value: string) => void;
  removeRow: (index: number) => void;
}

// Styled components
const TableContainer = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensure equal column widths */
`;

const TableCell = styled.td`
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  word-wrap: break-word;
`;

const TableHeader = styled.th`
  padding: 8px;
  border-bottom: 2px solid #ddd;
  border-top: 2px solid #ddd;
  text-align: left;
`;

const DynamicTableContainer = styled(TableContainer)`
  @media (max-width: 768px) {
    ${TableHeader}, ${TableCell} {
      font-size: 12px;
      padding: 6px;
    }
  }
`;

const DynamicTable: React.FC<DynamicTableProps> = ({
  contactProperties,
  edit,
  editMapField,
  removeRow,
}) => {
  const [contactOptions, setContactOptions] = useState<any[]>([]);

  const contactList = useAppSelector(getContactPropertiesOptions);
  useEffect(() => {
    const data = contactList?.map((property: any) => ({
      value: property?.name,
      label: property?.name,
    }));
    setContactOptions(data);
  }, [contactList]);

  return (
    <DynamicTableContainer>
      <Table>
        <thead>
          <tr>
            <TableHeader style={{ width: '33%' }}>Fields Name</TableHeader>
            <TableHeader style={{ width: '33%' }}>Fields</TableHeader>
            <TableHeader style={{ width: '33%' }}>Default Value</TableHeader>
            <TableHeader style={{ width: '10%' }}></TableHeader>
          </tr>
        </thead>
        <tbody>
          {contactProperties.map((row, index) => (
            <tr key={index}>
              <TableCell>{row.api_field_name}</TableCell>
              <TableCell>
                {edit ? (
                  <StyledInput
                    select
                    value={row.mapping_name}
                    onChange={(e: { target: { value: string; }; }) =>
                      editMapField('mapping_name', index, e.target.value)
                    }
                    variant="outlined"
                    size="small"
                    style={{ width: '100%' }}
                  >
                    {contactOptions.map((field) => (
                      <MenuItem key={field.value} value={field.value}>
                        {field.label}
                      </MenuItem>
                    ))}
                  </StyledInput>
                ) : (
                  <div>{row.mapping_name}</div>
                )}
              </TableCell>
              <TableCell>
                {edit && row.api_field_name !== 'dropdown' ? (
                  <StyledInput
                    placeholder="Enter Value"
                    value={row.default_value}
                    onChange={(e: { target: { value: string; }; }) =>
                      editMapField('default_value', index, e.target.value)
                    }
                    variant="outlined"
                    size="small"
                    style={{ width: '100%' }}
                  />
                ) : (
                  <div>{row.default_value}</div>
                )}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => removeRow(index)} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </tr>
          ))}
        </tbody>
      </Table>
    </DynamicTableContainer>
  );
};

export default DynamicTable;
