// vendors
import { useEffect, useState, Fragment } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";

// components
import UIButton from "../../../Components/Button";
import SearchInput from "../../../Components/SearchInput";
import Icon from "../../../Components/Icon";
import DisplayNodes from "./components/display-nodes";

// slice
import { useFlows } from "../../../Slices/main.slice";
import { createFlow } from "../../../Slices/main.service";
import { useAppDispatch } from "../../../../../Store";

// services
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { FlowBuilderActions } from "../../../../../Whatsapp/Store/FlowBuilder.actions";

const ConnectWhatsappFlow = ({
  onSaveClick,
  activeButtonData,
  onCancelClick,
  language,
}: any) => {
  const updatedFlows = useSelector(useFlows);
  const [hierarchyLevel, setHierarchyLevel] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(updatedFlows || []);
  const [flowId, setflowId] = useState<string | null>(null);
  const [flowOrder, setFlowOrder] = useState(0);
  const [displayNodes, setShowNodes] = useState<string>("");
  const [flowList , setFlowList] = useState<any>([])
  const [isLoading , setIsLoading] = useState<any>(false)
  const [selectedFlow, setFlow] = useState<{
    qid: string;
    stage: string | null;
    nodeId: string | null;
  } | null>(null);
  const currentBot = useSelector(useCurrentBot);

  useEffect(() => {
    if (
      updatedFlows &&
      updatedFlows?.length > 0 &&
      Array.isArray(updatedFlows)
    ) {
      const results: any = updatedFlows?.filter((flow: any) =>
        flow?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())
      );
      setSearchResults(results);
    }
  }, [searchTerm, updatedFlows]);

  useEffect(() =>{
    setIsLoading(true)
    let data = {
        status: "PUBLISHED",
    }
    dispatch(FlowBuilderActions.getFlows(data))
        .then((res: any) => {
            console.log("res----------------------->", res);
            if (res?.payload?.error) {
              setIsLoading(false)
                // setErrorMessage(res?.payload?.error)
            } else {
              setIsLoading(false)
                setFlowList(res?.payload?.data?.data)
            }
        })
        .catch((error: any) => {
          setIsLoading(false)
            console.log("res----------------------->", error?.payload?.error);
        })
  },[])

  const botId = { botid: currentBot.bot_id };
  const dispatch = useAppDispatch();

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  const handleNewFlowCreate = () => {
    dispatch(createFlow({ botId }));
  };

  const handleClick = (id: string, order: number) => {
    setHierarchyLevel(2);
    setFlowOrder(order);
    setflowId(id);
    setShowNodes((prevState: string) => {
      if (prevState !== id) {
        return id;
      } else {
        return prevState;
      }
    });
  };

  const handleBackClick = () => {
    setHierarchyLevel(1);
  };

  const handleNodeSelect = (qId: string, nodeId: string | null, order: number | null) => {
    setFlow({
      qid: qId,
      stage: `${flowOrder}.${order}`,
      nodeId: nodeId,
    });
  };

  const handleSaveClick = () => {
    let flow_Data = flowList?.filter((res:any) => res.meta_id === flowId)
    const updatedAction = {
      type: "flow",
      id: activeButtonData.id,
      value: {
        text: activeButtonData.value.text,
        "flow_message_version": "3",
        "flow_token": "9de24c24-7434-4b82-b7cb-eb4782ecb3df",
        "flow_id": flowId,
        "flow_cta": activeButtonData.value.text,
        "flow_action": "navigate",
        "mode": "published",
        "flow_action_payload": {
          "screen": flow_Data[0]?.template_flow?.screens[0]?.id}
      },
    };

    onSaveClick && onSaveClick(updatedAction);
  };

  const handleCancelClick = () => {
    onCancelClick && onCancelClick();
  };

  useEffect(() => {
    if (activeButtonData && activeButtonData.type == "flow") {
      setflowId(activeButtonData.value?.flow_id);
      setFlow(activeButtonData?.value);
    }
  }, [activeButtonData]);

  return (
    <>

        <Box sx={{ p: "16px" }}>
          <Box
            sx={{
              mt: "16px",
              maxHeight: "200px",
              minHeight: "200px",
              overflowY: "scroll",
            }}
          >
            {flowList?.length > 0 ? (
              <>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                  component="nav"
                >
                  {flowList.map((flow: any, index: number) => {
                    const openChild = activeButtonData.value?.qid === flow?.qid;
                    return (
                      <Fragment key={`flow-${index + 1}`}>
                        <ListItem
                          disablePadding
                          sx={{
                            "&:hover": {
                              backgroundColor:
                                language !== "english"
                                  ? openChild
                                    ? "#F5F6F8"
                                    : "transparent"
                                  : "#F5F6F8",
                            },
                            border: "1px solid #F5F6F8",
                            ...(openChild && { backgroundColor: "#F5F6F8" }),
                          }}
                        >
                          <ListItemButton
                            sx={{
                              "&:hover": { backgroundColor: "transparent" },
                            }}
                            onClick={() =>
                              handleClick(flow.meta_id, flow.displayOrder)
                            }
                            disabled={language !== "english"}
                          >
                            <Typography
                              fontWeight={400}
                              fontSize={14}
                              lineHeight="21px"
                              color="#7E8392"
                            >{`${index + 1} ${flow?.name}`}</Typography>
                            {flowId === flow.meta_id && (
                              <Box sx={{ marginLeft: "auto" }}>
                                <Icon icon="done" size={14} color="#2ECC71" />
                              </Box>
                            )}
                          </ListItemButton>
                        </ListItem>
                      </Fragment>
                    );
                  })}
                </List>
              </>
            ) : (
              isLoading ?<Typography
                fontWeight={400}
                fontSize={12}
                lineHeight="16px"
                color="#7E8392"
              >
                Loading.....
              </Typography> : <Typography
                fontWeight={400}
                fontSize={12}
                lineHeight="16px"
                color="#7E8392"
              >
                No Flows Available
              </Typography> 
            )}
          </Box>
        </Box>
      
      <Box sx={{ borderTop: "1px solid #F5F6F8", p: "16px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UIButton variant="outlined" onClick={handleCancelClick}>
            Cancel
          </UIButton>
          <UIButton
            color="primary"
            variant="contained"
            startIcon={<Icon icon="save" color="#fff" size={13} />}
            disabled={
              flowId ? false : true
            }
            onClick={handleSaveClick}
          >
            Save
          </UIButton>
        </Box>
      </Box>
    </>
  );
};
export default ConnectWhatsappFlow;
