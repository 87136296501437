import http from "../../../NetworkServices/http";


let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

// Services Functions
export class FileMangerNetworkService {
  static createfolder(data: any) {
    console.log(data);
    return http.post(`${consoleBaseUrl}${Endpoints.CREATE_BOARD}`, {
      ...data,
    });
  }
  static uploadFiles(data: any) {
    console.log("data", data);
    return http.post(`${consoleBaseUrl}${Endpoints.UPLOAD_Files}`,
      data,
    );
  }

  static renameboard(payload: any) {
    let { data, id } = payload
    return http.put(`${consoleBaseUrl}${Endpoints.RENAME_BOARD}?id=${id}`, {
      ...data,
    });
  }

  static getFolderListing(params: any) {
    const url = `${consoleBaseUrl}${Endpoints.GET_FOLDER}`;
    return http.get(url, { params });
  }
  static GetFiles(params: any) {
    const url = `${consoleBaseUrl}${Endpoints.GET_Files}`;
    return http.get(url, { params });
  }
  static deleteFolderaction(folderid: any) {
    return http.delete(`${consoleBaseUrl}${Endpoints.DELETE_BOARD}?id=${folderid}`);
  }

  static DownloadFile(params: any) {
    const url = `${consoleBaseUrl}${Endpoints.DOWNLOAD_FILE}`;
    return http.get(url, { params });
  }

  static deletefileaction(folder_id: any,file_id:any) {
    return http.delete(`${consoleBaseUrl}${Endpoints.DELETE_FILES}?folder_id=${folder_id}&file_id=${file_id}`);
  }
  static MoveFile(data: any) {
    console.log("data", data);
    return http.post(`${consoleBaseUrl}${Endpoints.MOVE_FILE}`,
      data,
    );
  }
}


class Endpoints {

  static GET_FOLDER = "/workspace/board";
  static CREATE_BOARD = "/workspace/board/add";
  static DELETE_BOARD = "/workspace/board/delete";
  static RENAME_BOARD = "/workspace/board/rename";
  static GET_Files = "/workspace/board/files";
  static UPLOAD_Files = "/workspace/board/upload";
  static  DOWNLOAD_FILE = "/workspace/board/download";
  static  DELETE_FILES= "/workspace/board/delete-file";
  static  MOVE_FILE= "/workspace/board/move-file";

  
}
