import { Box, Divider, ListItem, Popover } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../Store';
import { getAllFieldOptions, getListProperties } from '../../../Store/Slices/Settings/ContactProperties/contactProperties.selector';
import { ContactPropertiesActions } from '../../../Store/Slices/Settings/ContactProperties/contactProperties.action';
import DynamicsInputFields from '../contactDetailedComponents/FieldsLogic/DynamicsFieldsLogic';
import { useFormikContext } from 'formik';
import { AppForm, AppFormRadioField, AppFormSelectField, AppSubmitButton } from '../../../AppComponents/Forms';
import AppButton from '../../../AppComponents/AppButton';
import { ReactComponent as SaveIcon } from "../../../assets/images/save-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import { contactDetailsId, UndePendantFieldDropDown } from '../../../Store/Slices/Contacts/contacts.selector';
import { ContactsActions } from '../../../Store/Slices/Contacts/contacts.action';
import { useNotification } from '../../../Hooks/useNotification/useNotification';
import { useSearchParams } from 'react-router-dom';
import { theme } from '../../../Customization/Theme';

interface Props {
  modalAnchorEl: any;
  setModalAnchorEl: any;
  columns: any;
  colData: any;
  setLoading: any;
  rowData: any;
  setIsUpdating: any;
}

const UpdateInlineContactFields: React.FC<Props> = ({ modalAnchorEl, setModalAnchorEl, columns, colData, setLoading, rowData, setIsUpdating }) => {
  const contactId = useAppSelector(contactDetailsId);
  let payLoad: any = {
    columns,
    contactId_list: [contactId],
    selectAll: false,
    sort_by: -1,
    sort_field: "created_time"
  };
  const [searchParams] = useSearchParams();
  const currentViewId = searchParams.get("view");
  const filteredColumns = columns?.map((eachCol: any) => eachCol.id === "select" ? "id" : eachCol.id);
  const params = searchParams.get("page") || "1";
  const page = Number(params) - 1;
  // Payload for rendering the contact list after updating the field value......... 
  const contactPayload = {
    data: {
      columns: filteredColumns,
      page_number: page,
      view_id: currentViewId,
      page_size: 50
    }
  };
  const open = Boolean(modalAnchorEl);
  const dispatch = useAppDispatch();
  const unDepandantDropDownList: any = useAppSelector(UndePendantFieldDropDown);
  const contactListProperties = useAppSelector(getListProperties)
  const id = open ? "simple-popover" : undefined;
  const closeModal = () => {
    setModalAnchorEl(null);
  };

  const formData: any = {};
  const { showAlert } = useNotification();
  const [valid, setValid] = useState<any>({
    lost_reason: true,
  });
  const [lifeCycleStatus, setLifeCycleStatus] = useState<any>([]);
  const options = useAppSelector(getAllFieldOptions);
  let filterDataByName = colData && contactListProperties?.length > 0
    ? contactListProperties[0].fields?.filter((eachItem: any) => eachItem?.name === colData?.name)
    : [];

  if (filterDataByName && filterDataByName.length > 0) {
    filterDataByName[0] = { ...filterDataByName[0], options };
  }
  const [localStore, setLocalStore] = useState<any>({
    schemaName: [filterDataByName[0]],
    lifeCycleStage: [],
    lifeCycleStatus: [],
    isEnabled: [],
    countryStates: [],
    stateCity: [],
  });

  useEffect(() => {
    dispatch(ContactPropertiesActions.GetAllOptions(filterDataByName[0]?.id));
    setLocalStore((prevLocalStore: any) => ({
      ...prevLocalStore,
      schemaName: filterDataByName,
    }));

  }, [colData, rowData, dispatch]);
  let someData = [{
    label: "label",
    fields: filterDataByName || []
  }];

  const handleSubmitBtn = (values: any, submitProps: any) => {
    setModalAnchorEl(null);
    localStore?.schemaName.forEach((t: any) => {
      const { name, type } = t;
      let fieldName: any = values[name];
      switch (type) {
        case "dropdown":
          formData[name] = fieldName;
          break;
        case "multiselect":
          formData[name] = fieldName.map((i: any) => i.id);
          break;
        case "radio":
          formData[name] =
            fieldName === ""
              ? unDepandantDropDownList[0]?.value
              : fieldName?.id;
          break;
        case "string":
        case "text":
        case "checkbox":
          formData[name] = fieldName;
          break;
        case "phone":
          let countryCode =
            fieldName?.[`mobileCountryFormat${name}`]?.countryCode;
          let number = fieldName.replace(countryCode, "");
          formData[name] = { country_code: countryCode, number };
          break;
        case "landline":
          formData[name] = {
            country_code: fieldName?.[`landlineExtension${name}`],
            number: fieldName?.phone_number,
          };
          break;
        default:
          formData[name] = fieldName;
          break;
      }
    });
    payLoad.data = formData;
    setLoading(true);
    setIsUpdating(true);
    dispatch(ContactsActions.bulkUpdateContact(payLoad))
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(
            res?.payload?.data?.message || "Contact not update",
            "error"
          );
        } else {
          dispatch(ContactsActions.getContactsListData(contactPayload))
            .then((res: any) => {
              setLoading(false);
              setIsUpdating(false);
              showAlert("Contact updated successfully", "success");
            })
        }
      })
      .catch((error: any) => {
        showAlert(error?.response?.statusText || "Contact not update", "error");
      })
      .finally(() => {
        delete payLoad.data;
        submitProps.setSubmitting(false);
      });
  }
  const [name, setName] = useState<any>("");
  const [value, setValue] = useState<any>("");
  useEffect(() => {
    setName(filterDataByName[0]["name"]);
    setValue(rowData[`${filterDataByName[0]["name"]}_id`]?.trim())
  }, [filterDataByName, rowData, options])

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={modalAnchorEl}
        onClose={closeModal}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          boxShadow: `0 2px 15px 0.5px ${theme.palette.default.heather}`,
          "& .MuiPaper-root": {
            borderRadius: 1,
            boxShadow: `0 0 15px 0 ${theme.palette.default.paleGray}`,
            padding: "15px 10px",
            display: "flex",
            alignItems: "center"

          },
        }}
      >
        <Box>
          <ListItem>
            <div style={{ padding: "0px" }}>
              {" "}
              {options?.length &&
                <AppForm
                  initialValues={{ [filterDataByName[0]["name"]]: rowData[`${name}_id`] }}
                  onSubmit={(values: any, submitProps: any) => {
                    handleSubmitBtn(values, submitProps);
                  }}
                  enableReinitialize={true}
                >
                  {(filterDataByName[0]?.type === "dropdown" || filterDataByName[0]?.type === "multiselect") &&
                    <AppFormSelectField
                    menuPosition="fixed"
                    label={filterDataByName[0]["label"]}
                    Options={
                      options
                    }
                    valueKey={"id"}
                    name={filterDataByName[0]["name"]}
                    placeholder={`Select ${filterDataByName[0]["label"]}`}
                    isClearable={true}
                  />
                  }

                  {filterDataByName[0]?.type === "radio" &&
                    <AppFormRadioField
                      menuPosition="fixed"
                      label={filterDataByName[0]["label"]}
                      Options={options}
                      valueKey={"id"}
                      name={filterDataByName[0]["name"]}
                      placeholder={`Select ${filterDataByName[0]["label"]}`}
                      isClearable={true}
                    />
                  }

                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "16px",
                      gap: "6px",
                    }}
                  >
                    <AppButton
                      style={{ width: "90px" }}
                      variant="outline"
                      startIcon="add"
                      onClick={() => closeModal()}
                    >
                      Cancel
                    </AppButton>
                    <AppSubmitButton title="Update" style={{ width: "90px" }}>
                      <SaveIcon />
                    </AppSubmitButton>
                  </Box>
                </AppForm>

              }{" "}
            </div>
          </ListItem>
        </Box>
      </Popover>
    </>
  )
}

export default UpdateInlineContactFields