import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import AppButton from "../../AppComponents/AppButton";
import * as Yup from "yup";
import AppModel from "../../Hooks/useModel/AppModel";
import { AppForm, AppFormField } from "../../AppComponents/Forms";
import { theme } from "../../Customization/Theme";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit"; // Import this to handle the async thunk result
import { FormAction } from "../../Store/Slices/FormBuilder/formbuilder.actions";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { filemanagerAction } from "../../Store/Slices/filemanagers/fileManagerAction";

interface CreateBoardModalProps {
  open: boolean;
  handleClose: () => void;
  boardName: string;
  setBoardName: (value: string) => void;
  error?: string;
  fetchFolders?: any;
}

const CreateBoardModal: React.FC<CreateBoardModalProps> = ({
  open,
  handleClose,
  boardName,
  setBoardName,
  error,
  fetchFolders,
}) => {
  // Use AppDispatch type instead of the default dispatch type
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showAlert } = useNotification();

  const formNameValidationSchema = Yup.object().shape({
    boardName: Yup.string().trim().required("Board Name is required"),
  });

  const handleSaveBoard = async (name: string) => {
    try {
      setIsSubmitting(true);
      const payload = { folder_name: name };
      const res = await dispatch(
        filemanagerAction.CreateFolderAction(payload) as any
      );
      if (res.payload?.status === 201) {
        showAlert("Folder created successfully", "success");
        fetchFolders();
        handleClose();
      } else {
        showAlert(
          res.payload?.data?.message || "Failed to create folder",
          "error"
        );
      }
    } catch (error) {
      showAlert("Something went wrong, please try again", "error");
    } finally {
      setIsSubmitting(false);
      handleClose();
    }
  };
  return (
    <AppModel
      isShowing={open}
      onClose={handleClose}
      divStyle={{
        padding: 0,
      }}
      PaperProps={{
        style: {
          width: "450px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <AppForm
        initialValues={{ boardName }}
        onSubmit={(values: any) => {
          // This gets the user input from the form and passes it to handleSaveBoard
          handleSaveBoard(values.boardName);
        }}
        validationSchema={formNameValidationSchema}
      >
        <StyledModelHeader>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Enter Board Name
            </Typography>
          </Box>
        </StyledModelHeader>
        <AppFormModalContainer direction="column">
          <GridContainer>
            <AppFormField
              name="boardName"
              errorMessage={error}
              label="Board Name"
              placeholder="Please Enter Board Name"
              defaultBorder={true}
              labelStyle={{ color: theme.palette.default.newFontColor }}
              requiredSign
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setBoardName(e.target.value);
              }}
            />
          </GridContainer>
          <div className="saveButton">
            <AppButton
              variant="grey"
              onClick={handleClose}
              disabled={isSubmitting}
            >
              Cancel
            </AppButton>
            <AppButton type="submit" variant="default" disabled={isSubmitting}>
              {isSubmitting ? "Saving..." : "Save"}
            </AppButton>
          </div>
        </AppFormModalContainer>
      </AppForm>
    </AppModel>
  );
};
const StyledModelHeader = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    height: 56px;
    min-height: 56px;
    align-items: center;
    padding: 0rem 1rem 0rem 1rem;
    position: sticky;
    background-color: ${theme.palette.default.background};
    top: 0;
    z-index: 9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

    h6 {
      font-size: 1.05rem;
      color: ${theme.palette.default.text};
    }

    svg {
      color: ${theme.palette.default.darkGrey};
    }
  }
`;

const AppFormModalContainer = styled.div`
  && {
    display: flex;
    flex-direction: ${(props: { direction: string }) => props.direction};
    justify-content: start;
    padding: 16px;
    gap: 10px;
    .saveButton {
      width: 100%;
      display: flex;
      justify-content: end;
      gap: 20px;
    }
  }
`;

const GridContainer = styled.div`
  && {
    display: flex;
    gap: 10px;
    padding: 10px 0 10px 0;
  }
`;

export default CreateBoardModal;
