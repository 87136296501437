// import { AxiosResponse } from "axios";
// import { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import styled from "styled-components";
// import AppButton from "../../../AppComponents/AppButton";
// import {
//   AppErrorMessage,
//   AppForm,
//   AppFormError,
//   AppFormField,
//   AppFormObserver,
//   AppFormTextArea,
//   AppSubmitButton,
// } from "../../../AppComponents/Forms";
// import {
//   LoaderComponentProps,
//   withLoader,
// } from "../../../AppComponents/Loader";
// import {
//   Caption,
//   ErrorTextMsg,
//   TitleText,
// } from "../../../Customization/CommonElements";
// import useDocumentTitle from "../../../Hooks/useDocumentTitle";
// import { useNotification } from "../../../Hooks/useNotification/useNotification";
// import { CHANNELS, ChatbotCategory } from "../../../Models";
// import { Chatbot, ChatbotType } from "../../../Models/Chatbot";
// import { BotValidationSchema } from "../../../Models/ValidationSchema";
// import { LanguageOptions } from "../../../Models/defaultSelectOptions";
// import { ChatbotConsoleService } from "../../../Services/Apis/ChatbotConsoleService";
// import { getProjectConfigFn } from "../../../Services/Apis/CoreApis";
// import { CORE_ENDPOINTS } from "../../../Services/Apis/Endpoints";
// import { ChangeLayout, GETA_LAYOUT } from "../../../Store";
// import {
//   ChangeSelectedBot,
//   useCurrentBot,
// } from "../../../Store/Slices/DashboardSlices";
// import { formatString } from "../../../Utils";
// import whatsappImg from "../../../assets/images/BrandColor.svg";
// import chatbotImg from "../../../assets/images/Chat-bot-amico.svg";
// import messengerImg from "../../../assets/images/facebook-icon.svg";
// import telegramImg from "../../../assets/images/telegram.svg";
// import webImg from "../../../assets/images/icons/website-icon.svg";
// import { FormikContextType } from "formik";
// import { theme } from "../../../Customization/Theme";
// import { useAuthStore } from "../../../Store/Slices/AuthSlice";
// import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
// import { ProtectedAppForm } from "../../../AppComponents/ProtectedComponents";
// import { findFormSpecificPermissionKeyUsingLocation } from "../../../Utils/rolesUtils";
// import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect";

// export const CHANNEL_OPTIONS = [
//   {
//     name: "Whatsapp Chatbot",
//     value: "whatsapp",
//     image: whatsappImg,
//   },
//   {
//     name: "Facebook Chatbot",
//     value: "messenger",
//     image: messengerImg,
//   },
//   {
//     name: "Telegram Chatbot",
//     value: "telegram",
//     image: telegramImg,
//   },
// ];

// interface CreateBotForm extends LoaderComponentProps {}

// const CreateBotForm = (props: CreateBotForm) => {
//   const form: any = useRef();
//   const { showAlert } = useNotification();
//   const navigate = useNavigate();
//   let { bot_id } = useParams();
//   const { userConfig } = useSelector(useAuthStore);

//   let { workspace_id } = useParams();
//   const dispatch = useDispatch();
//   useDocumentTitle(bot_id ? "Edit Bot" : "Create Bot");
//   const currentBot = useSelector(useCurrentBot);
//   const [userInput, setUserInput] = useState<any>({
//     name: "",
//     primary_language: "english",
//     bot_type: 1,
//     bot_category: 0,
//     timezone: null,
//     description: "",
//     channel: "",
//     deploy_url: "",
//     fb_page_id: "",
//   });

//   const [botData, setBotData] = useState<any>(null);
//   const [formikBag, setFormikBag] = useState<FormikContextType<any>>();

//   const [botCategories, setBotCategories] = useState<ChatbotCategory[]>([]);

//   //*WILL BE USED TO SET THE FORM ACCESS TYPE
//   const [formAccessType, setFormAccessType] = useState<
//     "create" | "read" | "update" | "delete" | undefined
//   >();
//   //*getting the form access type using the location on first render
//   useEffect(() => {
//     const result = findFormSpecificPermissionKeyUsingLocation();
//     setFormAccessType(result ?? "create");
//   }, []);

//   // useEffect(() => {
//   //   ChatbotConsoleService.getAllBotCategories().then(
//   //     (res: AxiosResponse<ChatbotCategory[]>) => {
//   //       if (res.data) {
//   //         setBotCategories(res.data);
//   //       }
//   //     }
//   //   );
//   // }, []);
//   useEffect(() => {
//     if (bot_id) {
//       props.setLoading(true);
//       ChatbotConsoleService.getBotById({ bot_id: bot_id })
//         .then((response: any) => {
//           setBotData(response.data);
//           setUserInput({
//             name: response.data?.name || "",
//             deploy_url: response.data?.deploy_url || "",
//             bot_type: response.data?.bot_type ? response.data?.bot_type : 1,
//             bot_category: response.data?.bot_category
//               ? response.data?.bot_category
//               : 0,
//             description: response.data?.description || "",
//             primary_language: response.data?.primary_language
//               ? response.data?.primary_language
//               : LanguageOptions[0].value,
//             timezone: response.data?.timezone ? response.data?.timezone : null,
//             channel: response.data?.channel ? response.data?.channel : null,
//           });
//         })
//         .finally(() => {
//           props.setLoading(false);
//         });
//     } else {
//       props.setLoading(false);
//     }
//   }, []); // eslint-disable-line react-hooks/exhaustive-deps

//   const [apiErrorMsg, setApiErrorMsg] = useState<any>(null);

//   const handleSubmitBtn = (values: any, SubmitProps: any) => {
//     setUserInput(values);

//     props.setLoading(true);
//     let payload: any = {
//       chatbot: {
//         name: values.name,
//         description: values.description,
//         deploy_url: values.deploy_url,
//         bot_type: values.bot_type,
//         bot_category: values.bot_category,
//         primary_language: values.primary_language,
//         timezone: values.timezone,
//         channel: values?.channel,
//       },
//       chatbot_configuration: {},
//     };
//     if (values?.channel === "telegram") {
//       payload.chatbot.access_token = values.bot_token;
//     }
//     if (values?.channel === "messenger") {
//       payload.chatbot.access_token = values.fb_page_id;
//     }
//     ChatbotConsoleService.createNewBot(payload)
//       .then((response: any) => {
//         SubmitProps.setSubmitting(false);
//         setApiErrorMsg(null);
//         showAlert("Bot created successfully", "success");
//         handleManageBot(response?.data?.chatbot, "setup/customize/look-feel");
//         setBotData(response?.data?.chatbot);

//         SubmitProps.resetForm();
//       })
//       .catch((error) => {
//         console.warn("api error", error);
//         setApiErrorMsg(error.response.statusText);
//         SubmitProps.setSubmitting(false);
//         showAlert(error.response.data.error || "Something went wrong", "error");
//       })
//       .finally(() => {
//         SubmitProps.setSubmitting(false);
//         props.setLoading(false);
//       });
//   };

//   const handleCancelBtn = () => {
//     if (form.current?.values?.channel !== "") {
//       form.current.setFieldValue("channel", "");
//     } else {
//       navigate(`/workspace/${currentBot?.workspace_id}/setup/bots`);
//     }
//   };

//   const handleUpdateBtn = (values: any, SubmitProps: any) => {
//     setUserInput(values);
//     props.setLoading(true);
//     ChatbotConsoleService.updateBot(botData.id, {
//       ...botData,
//       name: values.name,
//       description: values.description,
//       deploy_url: values.deploy_url,
//       bot_type: values.bot_type,
//       bot_category: values.bot_category,
//       primary_language: values.primary_language,
//       timezone: values.timezone,
//       channel: values?.channel,
//     })
//       .then((response) => {
//         let res = JSON.parse(response?.config?.data);
//         SubmitProps.setSubmitting(false);
//         SubmitProps.resetForm();
//         setApiErrorMsg(null);
//         showAlert("Bot updated successfully", "success");
//         navigate(`/workspace/${res.workspace_id}/setup/bots`);
//       })
//       .catch((error) => {
//         setApiErrorMsg(error.response.statusText);
//         SubmitProps.setSubmitting(false);
//         showAlert(error.response.statusText, "error");
//       })
//       .finally(() => {
//         props.setLoading(false);
//       });
//   };

//   const handleManageBot = (bot: Chatbot, subUrl: string) => {
//     dispatch(ChangeSelectedBot(bot));
//     const proj_v_url = formatString(CORE_ENDPOINTS.PROJECT_VER_CONFIG, [
//       bot.project_version_id,
//     ]);
//     getProjectConfigFn(dispatch)({ url: proj_v_url }).then((res: any) => {});
//     setTimeout(() => {
//       navigate(`/bot/${bot?.bot_id}/${subUrl}`);
//     }, 1);
//   };
//   return (
//     <StyledWrap>
//       <StyledHeader>
//         <TitleText
//           style={{ fontWeight: 500, fontSize: "14px", marginBottom: 0 }}
//         >
//           {bot_id ? "Update chatbot" : "Create a chatbot"}{" "}
//         </TitleText>
//       </StyledHeader>
//       <StyledFormWrap>
//         <div className="wrapperDiv">
//           <p>Step 1</p>
//           <h4>Select the platform</h4>
//           <ErrorTextMsg>{apiErrorMsg}</ErrorTextMsg>
//           <ProtectedAppForm
//             initialValues={userInput}
//             onSubmit={(values: any, submitProps: any) => {
//               bot_id
//                 ? handleUpdateBtn(values, submitProps)
//                 : handleSubmitBtn(values, submitProps);
//               setUserInput(values);
//             }}
//             validationSchema={BotValidationSchema}
//             moduleId="create_bot"
//             specificPermissionKey={formAccessType}
//             innerRef={form}
//           >
//             <div className="web-platform">
//               <CustomLabel>Web Platform</CustomLabel>
//               <div
//                 className={`channelDiv customInput ${
//                   formikBag?.errors?.channel ? "" : ""
//                 }`}
//                 style={{
//                   // borderColor:
//                   //   formikBag?.values?.channel === "Web Bot"
//                   //     ? theme.palette.primary.main
//                   //     : theme.palette.default.border,
//                   color:
//                     formikBag?.values?.channel === "Web Bot"
//                       ? theme.palette.primary.main
//                       : theme.palette.default.darkGrey,
//                   cursor: !userConfig?.payload?.whatsapp_enabled
//                     ? "not-allowed"
//                     : "pointer",
//                 }}
//                 onClick={() =>
//                   !userConfig?.payload?.whatsapp_enabled
//                     ? undefined
//                     : formikBag?.setFieldValue("channel", "Web Bot")
//                 }
//               >
//                 <img src={webImg} alt="channel type"></img>
//                 <p className="channelTitle">
//                   Website Chatbot, Landing Page Bot
//                 </p>
//               </div>
//             </div>
//             <CustomLabel>Social Platforms</CustomLabel>
//             <div className="channel">
//               {form.current?.values?.channel === "" &&
//                 CHANNEL_OPTIONS &&
//                 CHANNEL_OPTIONS?.map((item: any, index: any) => {
//                   let channel = item?.value;
//                   let temp =
//                     userConfig?.payload?.allowed_permissions
//                       ?.whatsapp_chatbot ?? false;
//                   if (channel === "whatsapp" && !temp) return null;
//                   return (
//                     <div
//                       className={`channelDiv customInput ${
//                         formikBag?.errors?.channel ? "" : ""
//                       }`}
//                       key={index}
//                       style={{
//                         // borderColor:
//                         //   formikBag?.values?.channel === item?.value
//                         //     ? theme.palette.primary.main
//                         //     : theme.palette.default.border,
//                         color:
//                           formikBag?.values?.channel === item?.value
//                             ? theme.palette.primary.main
//                             : theme.palette.default.darkGrey,
//                         cursor: !userConfig?.payload?.whatsapp_enabled
//                           ? "not-allowed"
//                           : "pointer",
//                       }}
//                       onClick={() =>
//                         !userConfig?.payload?.whatsapp_enabled
//                           ? undefined
//                           : formikBag?.setFieldValue("channel", item?.value)
//                       }
//                     >
//                       <img src={item?.image} alt="channel type"></img>

//                       <p className="channelTitle">{item?.name}</p>
//                     </div>
//                   );
//                 })}
//               <AppErrorMessage
//                 visible={true}
//                 error={formikBag?.errors?.channel}
//               />
//             </div>

//             {form.current?.values?.channel === "telegram" ? (
//               <>
//                 <AppFormTextArea
//                   label="Bot Token"
//                   name="bot_token"
//                   rows="4"
//                   placeholder="Enter Bot Token"
//                 />
//                 <p>
//                   Open <strong>@BotFather</strong>, type{" "}
//                   <strong>/newbot</strong> and follow the instructions. You will
//                   get the bot token in message. Paste the token here.
//                 </p>
//               </>
//             ) : (
//               form.current?.values?.channel !== "" && (
//                 <>
//                   <AppFormField
//                     label="Bot Name"
//                     name="name"
//                     placeholder="Travel booking chatbot"
//                   />

//                   <AppFormTextArea
//                     label="Bot Description"
//                     name="description"
//                     rows="4"
//                     placeholder="bot description"
//                   />
//                 </>
//               )
//             )}
//             {form.current?.values?.channel === "messenger" ? (
//               <></>
//             ) : // <AppReactSelectField options={[]} name={"fb_page_id"} />
//             null}
//             {!userConfig?.payload?.whatsapp_enabled ? (
//               <Caption>
//                 <AppMaterialIcons iconName="Info" />
//                 Enable Whatsapp channel under settings&gt;channels menu to
//                 create a Whatsapp bot.
//               </Caption>
//             ) : null}

//             <AppFormObserver setFormikProps={setFormikBag} />

//             <div className="footerBtn">
//               <div className="btnSubmit">
//                 {form.current?.values?.channel !== "" && (
//                   <AppButton
//                     variant={
//                       form.current?.values?.channel !== "" ? "grey" : "primary"
//                     }
//                     style={{
//                       width:
//                         form.current?.values?.channel !== "" ? "15%" : "auto",
//                       margin:
//                         form.current?.values?.channel !== ""
//                           ? "0.5rem 0"
//                           : "1rem 0",
//                     }}
//                     onClick={() => handleCancelBtn()}
//                     type="button"
//                   >
//                     {form.current?.values?.channel !== ""
//                       ? "Cancel"
//                       : "Back to bot listing"}
//                   </AppButton>
//                 )}
//                 {form.current?.values?.channel !== "" && (
//                   <AppSubmitButton
//                     style={{
//                       width: "25%",
//                       minWidth: "210px",
//                       margin: "0.5rem 0",
//                     }}
//                     title={bot_id ? "Update Chatbot" : "Create Chatbot"}
//                   />
//                 )}
//               </div>
//             </div>
//           </ProtectedAppForm>
//         </div>
//       </StyledFormWrap>
//       <StyledFormSideImg>
//         <img src={chatbotImg} alt="" />
//       </StyledFormSideImg>
//     </StyledWrap>
//   );
// };

// export default withLoader(CreateBotForm);

// const CustomLabel = styled.div`
//   background-color: ${theme.palette.primary.main};
//   color: ${theme.palette.default.white};
//   font-size: 13px;
//   padding: 8px 24px;
//   display: inline-block;
//   border-radius: 20px;
// `;
// const StyledWrap = styled.div`
//   && {
//     width: 100%;
//   }
// `;
// const StyledHeader = styled.div`
//   && {
//     width: 100%;
//     display: flex;
//     height: 65px;
//     justify-content: space-between;
//     align-items: center;
//     padding: 14px 24px;
//     background-color: #ffffff;
//     box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.08);
//     position: relative;
//     margin-bottom: 10px;
//   }
// `;

// const StyledFormWrap = styled.div`
//   && {
//     background-color: #fff;
//     overflow-y: auto;
//     .wrapperDiv {
//       padding: 1rem;
//       position: relative;
//       z-index: 1;
//       min-height: calc(100vh - 140px);
//       min-width: 100%;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       justify-content: center;
//       gap: 0.5rem;
//       h4 {
//         font-size: 22px;
//         font-weight: normal;
//         margin-bottom: 20px;
//       }
//       .web-platform {
//         display: flex;
//         justify-content: center;
//         flex-direction: column;
//         align-items: center;
//         gap: 14px;

//         margin-bottom: 40px;
//         .channelDiv {
//           width: 270px;
//           height: 180px;
//         }
//       }
//       .formikFormWrapper {
//         align-items: center;
//       }
//     }
//     .btnSubmit {
//       display: flex;
//       justify-content: right;
//       align-items: center;
//       gap: 2rem;
//     }
//     .channel {
//       display: flex;
//       gap: 2rem;
//       flex-wrap: wrap;
//       justify-content: space-around;
//     }
//     .channelDiv {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       background: ${theme.palette.default.white};
//       border: 1px solid #c7e4ff;
//       border-radius: 8px;
//       padding: 0.7rem 1rem;
//       gap: 1rem;
//       cursor: pointer;
//       flex-direction: column;
//       cursor: pointer;
//       margin-top: 20px;
//       width: 180px;
//       height: 180px;
//       &:hover {
//         background: ${theme.palette.primary.main};
//         box-shadow: 0px 0px 20px 0px #6150e14d;
//         .channelTitle {
//           color: ${theme.palette.default.white};
//         }
//       }
//       &.error {
//         box-shadow: red 0px 0px 3px 0px;
//         border: 1px solid red;
//       }
//       .channelTitle,
//       img {
//         cursor: pointer;
//         color: ${theme.palette.default.black};
//       }
//       img {
//         width: 70px;
//         height: 70px;
//       }
//     }
//     .active {
//       border: 1px solid #5b73e8;
//     }
//     .footerBtn {
//       height: 100%;
//       margin-bottom: 0.5rem;
//       display: flex;
//       flex-direction: column;
//       justify-content: flex-end;
//     }
//   }
// `;

// const StyledFormSideImg = styled.div`
//   && {
//     width: 100%;
//     height: 100%;
//     min-height: calc(100vh - 140px);
//     position: fixed;
//     display: flex;
//     justify-content: flex-end;
//     align-items: flex-end;
//     opacity: 0.2;
//     z-index: 0;
//     top: 0;
//     left: 0;
//     img {
//       width: 400px;
//     }
//   }
// `;

import { AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppErrorMessage,
  AppForm,
  AppFormError,
  AppFormField,
  AppFormObserver,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../AppComponents/Loader";
import {
  Caption,
  ErrorTextMsg,
  TitleText,
} from "../../../Customization/CommonElements";
import useDocumentTitle from "../../../Hooks/useDocumentTitle";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { CHANNELS, ChatbotCategory } from "../../../Models";
import { Chatbot, ChatbotType } from "../../../Models/Chatbot";
import { BotValidationSchema } from "../../../Models/ValidationSchema";
import { LanguageOptions } from "../../../Models/defaultSelectOptions";
import { ChatbotConsoleService } from "../../../Services/Apis/ChatbotConsoleService";
import { getProjectConfigFn } from "../../../Services/Apis/CoreApis";
import { CORE_ENDPOINTS } from "../../../Services/Apis/Endpoints";
import {
  ChangeSelectedBot,
  useCurrentBot,
} from "../../../Store/Slices/DashboardSlices";
import { formatString } from "../../../Utils";
import whatsappImg from "../../../assets/images/BrandColor.svg";
import VoiceBot from "../../../assets/images/VoiceBot.svg";
import chatbotImg from "../../../assets/images/Chat-bot-amico.svg";
import messengerImg from "../../../assets/images/Frame39702.svg";
import webImg from "../../../assets/images/Frame39703.svg";
import { FormikContextType } from "formik";
import { theme } from "../../../Customization/Theme";
import { useAuthStore } from "../../../Store/Slices/AuthSlice";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { ProtectedAppForm } from "../../../AppComponents/ProtectedComponents";
import { findFormSpecificPermissionKeyUsingLocation } from "../../../Utils/rolesUtils";
import voiceImg from "../../../assets/images/voice.svg";
import CreateVoiceBot from "./voice/components/CreateVoiceBot";
import { useMyBotsStore } from "../../../Services/Apis";
import { useAppSelector } from "../../../Store";

export const CHANNEL_OPTIONS = [
  {
    name: "Web Bot",
    value: "Web Bot",
    image: webImg,
  },
  {
    name: "Whatsapp",
    value: "whatsapp",
    image: whatsappImg,
  },
  {
    name: "Voice",
    value: "voice",
    image: voiceImg,
  },
  // {
  //   name: "Messenger",
  //   value: "messenger",
  //   image: messengerImg,
  // },
  // {
  //   name: "Telegram Chatbot",
  //   value: "telegram",
  //   image: telegramImg,
  // },
];

interface CreateBotForm extends LoaderComponentProps {
  bot_id?: any
  setCreateBotModelTitle?: any
  close?:any
}

const CreateBotForm = (props: CreateBotForm) => {
  const form: any = useRef();
  const { showAlert } = useNotification();
  const navigate = useNavigate();
  let { bot_id, setCreateBotModelTitle } = props
  const { userConfig } = useSelector(useAuthStore);
  const { data: paginatedMyBots } = useAppSelector(useMyBotsStore);

  let { workspace_id } = useParams();
  const dispatch = useDispatch();
  const [openVoiceBot, setOpenVoiceBot] = useState<boolean>(false);
  useDocumentTitle(bot_id ? "Edit Bot" : "Create Bot");
  const currentBot = useSelector(useCurrentBot);
  const [userInput, setUserInput] = useState<any>({
    name: "",
    primary_language: "english",
    bot_type: 1,
    bot_category: 0,
    timezone: null,
    description: "",
    channel: "",
    deploy_url: "",
  });

  const [botData, setBotData] = useState<any>(null);
  const [disableVoiceBot, setDisableVoiceBot] = useState<boolean>(false);
  const [formikBag, setFormikBag] = useState<FormikContextType<any>>();

  //*WILL BE USED TO SET THE FORM ACCESS TYPE
  const [formAccessType, setFormAccessType] = useState<
    "create" | "read" | "update" | "delete" | undefined
  >();
  //*getting the form access type using the location on first render
  useEffect(() => {
    const result = findFormSpecificPermissionKeyUsingLocation();
    setFormAccessType(result ?? "create");
  }, []);

  useEffect(() => {
    if (paginatedMyBots?.voice_bot_limit?.phone) {
      const allEnabled = paginatedMyBots.voice_bot_limit.phone.every(
        (config: { incoming: boolean; outgoing: boolean }) => config.incoming && config.outgoing
      );
  
      setDisableVoiceBot(allEnabled);
    } else {
      setDisableVoiceBot(false);
    }
  }, [paginatedMyBots]);

  // useEffect(() => {
  //   ChatbotConsoleService.getAllBotCategories().then(
  //     (res: AxiosResponse<ChatbotCategory[]>) => {
  //       if (res.data) {
  //         setBotCategories(res.data);
  //       }
  //     }
  //   );
  // }, []);

  useEffect(() => {
    if (bot_id) {
      props.setLoading(true);
      ChatbotConsoleService.getBotById({ bot_id: bot_id })
        .then((response: any) => {
          setBotData(response.data);
          setUserInput({
            name: response.data?.name || "",
            deploy_url: response.data?.deploy_url || "",
            bot_type: response.data?.bot_type ? response.data?.bot_type : 1,
            bot_category: response.data?.bot_category
              ? response.data?.bot_category
              : 0,
            description: response.data?.description || "",
            primary_language: response.data?.primary_language
              ? response.data?.primary_language
              : LanguageOptions[0].value,
            timezone: response.data?.timezone ? response.data?.timezone : null,
            channel: response.data?.channel ? response.data?.channel : null,
          });
        })
        .finally(() => {
          props.setLoading(false);
        });
    } else {
      props.setLoading(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [apiErrorMsg, setApiErrorMsg] = useState<any>(null);

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    setUserInput(values);
    props.setLoading(true);
    let payload: any = {
      chatbot: {
        name: values.name,
        description: values.description,
        deploy_url: values.deploy_url,
        bot_type: values.bot_type,
        bot_category: values.bot_category,
        primary_language: values.primary_language,
        timezone: values.timezone,
        channel: values?.channel,
      },
      chatbot_configuration: {},
    };
    if (values?.channel === "telegram") {
      payload.chatbot.access_token = values.bot_token;
    }
    // if (values?.channel === "messenger") {
    //   payload.chatbot.access_token = values.fb_page_id;
    // }
    ChatbotConsoleService.createNewBot(payload)
      .then((response: any) => {
        SubmitProps.setSubmitting(false);
        setApiErrorMsg(null);
        showAlert("Bot created successfully", "success");
        handleManageBot(response?.data?.chatbot, "setup/customize/look-feel");
        setBotData(response?.data?.chatbot);
        SubmitProps.resetForm();
        props.close()
      })
      .catch((error) => {
        console.warn("api error", error);
        setApiErrorMsg(error.response.statusText);
        SubmitProps.setSubmitting(false);
        showAlert(error.response.data.error || "Something went wrong", "error");
      })
      .finally(() => {
        SubmitProps.setSubmitting(false);
        props.setLoading(false);
      });
  };
  const handleCancelBtn = () => {
    if (form.current?.values?.channel !== "") {
      form.current.setFieldValue("channel", "");
    } else {
      navigate(`/workspace/${currentBot?.workspace_id}/setup/bots`);
    }
  };

  const handleUpdateBtn = (values: any, SubmitProps: any) => {
    setUserInput(values);
    props.setLoading(true);
    ChatbotConsoleService.updateBot(botData.id, {
      ...botData,
      name: values.name,
      description: values.description,
      deploy_url: values.deploy_url,
      bot_type: values.bot_type,
      bot_category: values.bot_category,
      primary_language: values.primary_language,
      timezone: values.timezone,
      channel: values?.channel,
    })
      .then((response) => {
        let res = JSON.parse(response?.config?.data);
        SubmitProps.setSubmitting(false);
        SubmitProps.resetForm();
        setApiErrorMsg(null);
        showAlert("Bot updated successfully", "success");
        navigate(`/workspace/${res.workspace_id}/setup/bots`);
        props.close()
      })
      .catch((error) => {
        setApiErrorMsg(error.response.statusText);
        SubmitProps.setSubmitting(false);
        showAlert(error.response.statusText, "error");
      })
      .finally(() => {
        props.setLoading(false);
      });
  };

  const handleManageBot = (bot: Chatbot, subUrl: string) => {
    dispatch(ChangeSelectedBot(bot));
    const proj_v_url = formatString(CORE_ENDPOINTS.PROJECT_VER_CONFIG, [
      bot.project_version_id,
    ]);
    getProjectConfigFn(dispatch)({ url: proj_v_url }).then((res: any) => { });
    setTimeout(() => {
      navigate(`/bot/${bot?.bot_id}/${subUrl}`);
    }, 1);
  };
  return (
    // <StyledWrap>
    <StyledFormWrap>
      {/* <TitleText style={{ fontWeight: 500, fontSize: "1.1rem" }}>
          {bot_id ? "Update chatbot" : "Create a chatbot"}{" "}
        </TitleText> */}
      <ErrorTextMsg>{apiErrorMsg}</ErrorTextMsg>
      <ProtectedAppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          bot_id
            ? handleUpdateBtn(values, submitProps)
            : handleSubmitBtn(values, submitProps);
          setUserInput(values);
        }}
        validationSchema={BotValidationSchema}
        moduleId="create_bot"
        specificPermissionKey={formAccessType}
        innerRef={form}
      >
        {form.current?.values?.channel === "" && (
          <>
            <div className="channel">
              {CHANNEL_OPTIONS &&
                CHANNEL_OPTIONS?.map((item: any, index: any) => {
                  let channel = item?.value;
                  let temp =
                    userConfig?.payload?.allowed_permissions
                      ?.whatsapp_chatbot ?? false;
                  // if (channel === "whatsapp" && !temp) return null;
                  let is_voice_enabled = userConfig?.payload?.voice_enabled ?? false;

                  if (channel === "voice" && !is_voice_enabled) return null;

                  return (
                    <div
                      className="channelDiv customInput"
                      key={index}
                      
                      style={{
                        borderColor:
                          formikBag?.values?.channel === item?.value
                            ? theme.palette.primary.main
                            : theme.palette.default.border,
                        color:
                          formikBag?.values?.channel === item?.value
                            ? theme.palette.primary.main
                            : theme.palette.default.darkGrey,
                        cursor:
                          (!userConfig?.payload?.whatsapp_enabled && channel === "whatsapp") ||
                          (channel === "voice" && disableVoiceBot)
                            ? "not-allowed"
                            : "auto",

                          
                        opacity:
                          (!userConfig?.payload?.whatsapp_enabled && channel === "whatsapp") ||
                          (channel === "voice" && disableVoiceBot)
                            ? 0.5
                            : 1, // Visually indicate it's disabled
                      }}
                      // onClick={() =>
                      //   channel === "whatsapp" &&
                      //   !userConfig?.payload?.whatsapp_enabled
                      //     ? undefined
                      //     : formikBag?.setFieldValue("channel", item?.value)
                      // }
                      onClick={() => {
                        if(channel === "voice" && disableVoiceBot){
                          return true;
                        }
                        formikBag?.setFieldValue("channel", item?.value); // Set the value for other cases
                        if (item?.value === "voice") {
                          setOpenVoiceBot(true); // Open the modal
                          formikBag?.setFieldValue("channel", item?.value); // Set the value in Formik
                        } else if (
                          channel === "whatsapp" &&
                          !userConfig?.payload?.whatsapp_enabled
                        ) {
                          return; // Prevent action if WhatsApp is disabled
                        } else {
                          formikBag?.setFieldValue("channel", item?.value); // Set the value for other cases
                        }
                      }}
                    >
                      <img src={item?.image} alt="channel type"></img>
                      <p className="channelTitle">{item?.name}</p>
                    </div>
                  )
                })}
              <AppErrorMessage
                visible={true}
                error={formikBag?.errors?.channel}
              />
            </div>
          </>
        )}
        {form.current?.values?.channel !== "" &&
          !["telegram", "voice"].includes(form.current?.values?.channel) && (
            <>
              <AppFormField
                label="Bot Name"
                name="name"
                placeholder="Travel booking chatbot"
              />

              <AppFormTextArea
                label="Bot Description"
                name="description"
                rows="4"
                placeholder="bot description"
              />
            </>
          )}
        {form.current?.values?.channel === "telegram" ? (
          <>
            <AppFormTextArea
              label="Bot Token"
              name="bot_token"
              rows="4"
              placeholder="Enter Bot Token"
            />
            <p>
              Open <strong>@BotFather</strong>, type <strong>/newbot</strong>{" "}
              and follow the instructions. You will get the bot token in
              message. Paste the token here.
            </p>
          </>
        ) : null}
        <CreateVoiceBot
          isShowing={openVoiceBot}
          toggleModalCreate={() => {
            setOpenVoiceBot((prev) => !prev);
            formikBag?.setFieldValue("channel", "");
          }}
        />

        {!userConfig?.payload?.whatsapp_enabled ? (
          <Caption>
            <AppMaterialIcons iconName="Info" />
            Enable Whatsapp channel under settings&gt;channels menu to create
            a Whatsapp bot.
          </Caption>
        ) : null}
        {form.current?.values?.channel === "voicebot" ? (
          <>
            <AppFormField
              label="Agent Name"
              name="name"
              placeholder="Write your agent name here"
            />
            <AppFormTextArea
              label="Description"
              name="description"
              rows="4"
              placeholder="Agent description"
            />
            <h3><b>Select your use case and we'll create an agent</b></h3>
          </>
        ) : null}
        <AppFormObserver setFormikProps={setFormikBag} />
        {form.current?.values?.channel !== "" &&
          !["voice"].includes(form.current?.values?.channel) && (
            <div className="footerBtn">
              <div className="btnSubmit">
                <AppButton
                  variant="grey"
                  style={{ width: "15%", margin: "0.5rem 0" }}
                  onClick={() => handleCancelBtn()}
                >
                  Cancel
                </AppButton>
                <AppSubmitButton
                  style={{
                    width: "25%",
                    minWidth: "210px",
                    margin: "0.5rem 0",
                  }}
                  title={bot_id ? "Update Chatbot" : "Create Chatbot"}
                />
              </div>
            </div>
          )}
      </ProtectedAppForm>
    </StyledFormWrap>

  );
};

export default withLoader(CreateBotForm);

const StyledWrap = styled.div`
  && {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: cen;
    padding: 1rem;
    padding-left: 2rem;
    border-radius: 8px;
    background-color: #ffffff;
    overflow: hidden;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.08);
  }
`;

const StyledFormWrap = styled.div`
  && {
    width: 100%;
    height: 100%;
    min-width: 500px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    gap: 0.5rem;
    .btnSubmit {
      display: flex;
      justify-content: right;
      align-items: center;
      gap: 2rem;
    }
    .channel {
      display: flex;
      gap: 1rem;
    }
    .channelDiv {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: #ffffff;
      border: 1.2px solid #eaeaea;
      border-radius: 8px;
      padding: 0.7rem 1rem;
      gap: 1rem;
      cursor: pointer;
      width: 40%;
      flex-direction: column;
      max-width: 200px;
      cursor: pointer;
      * {
        pointer-events: none;
      }
      &:hover {
        background: ${theme.palette.primary.main};
        .channelTitle {
          color: #fff;
        }
      }
      .channelTitle,
      img {
        cursor: pointer;
      }
    }
    .active {
      border: 1px solid #5b73e8;
    }
    .footerBtn {
      height: 100%;
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
`;

const StyledFormSideImg = styled.div`
  && {
    margin-left: 5%;
    width: 50%;
    height: 100%;

    display: flex;

    justify-content: center;
    align-items: center;

    img {
      width: 60%;
      height: 100%;
    }
  }
`;
