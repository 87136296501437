import React, { useState } from "react";
import { JSONTree } from "react-json-tree";
import styled from "styled-components";
import { ReactComponent as Add } from "../../../assets/images/icons/tree-add.svg";
import { ReactComponent as Remove } from "../../../assets/images/icons/tree-remove.svg";
import { IconButton } from "@mui/material";
import { StyledMenu, StyledMenuItem } from "../style";

// Define a styled container
const TreeContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 10px;
  background-color: transparent; // Make background transparent
`;

// Props interface
interface JsonViewerProps {
  jsonData: any; // Accept any dynamic JSON data
  theme?: any; // Optional theme prop for customization
  shouldExpandNode?: (keyName: any, data: any, level: number) => boolean; // Function for expanding nodes
  handleSelectionChange?: any;
  selectedOptions?: any[];
  contactProperties?: any[];
}

// Main component
const JsonViewer: React.FC<JsonViewerProps> = ({
  contactProperties = [],
  selectedOptions = [],
  jsonData,
  theme,
  shouldExpandNode,
  handleSelectionChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // State for menu anchor
  const [currentPath, setCurrentPath] = useState<string>(""); // State to hold the current path
  const [nestedMenuOpen, setNestedMenuOpen] = useState<boolean>(false); // State for nested menu visibility
  const [parentOption, setParentOption] = useState<string>(""); // State for the currently selected parent option

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    path: string
  ) => {
    setCurrentPath(path); // Set the current path
    setAnchorEl(event.currentTarget); // Set the anchor element for the menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
    setNestedMenuOpen(false); // Reset nested menu state
    setParentOption(""); // Reset selected parent option
  };

  // Simplified handleMenuItemClick
  const handleMenuItemClick = (action: string) => {
    setParentOption(action); // Set the selected parent option
    setNestedMenuOpen(true); // Open the nested menu
  };

  // Handle nested menu item click
  const handleNestedMenuItemClick = (
    nestedAction: string,
    isRemove: boolean
  ) => {
    handleSelectionChange(nestedAction, currentPath, isRemove, parentOption); // Call with parent and nested options
    handleMenuClose();
  };

  // Type-safe valueRenderer function
  const valueRenderer = (
    valueAsString: unknown,
    value: any,
    ...keyPath: any[]
  ) => {
    const path = keyPath
      .reverse()
      .map((key, index) =>
        Array.isArray(value) && !isNaN(Number(key)) // Check if the key is a numeric index
          ? `[${key}]`
          : key
      )
      .join(".")
      .replace(/\.\[/g, "[") // Replace ".[" with "[" for better formatting
      .replace(/\.\]/g, "]"); // Replace ".]" with "]" for better formatting

    const isRemove = (path: string): boolean => {
      // Extract the exact path by converting `.n` to `[n]`
      const exactPath = path.replace(/\.(\d+)/g, "[$1]");

      // Extract the generalized path by replacing `.n` with `[]`
      const generalizedPath = path.replace(/\.\d+/g, "[]");
      // Check for the original path and modified paths in selectedOptions and api_field_names
      const apiFieldNames = contactProperties?.map(
        (property) => property.api_field_name
      );

      // Check if the path or its modified versions exist in selectedOptions or api_field_names
      const pathExists =
        selectedOptions.includes(exactPath) || // Match exact path
        selectedOptions.includes(generalizedPath) || // Match generalized path
        apiFieldNames?.includes(exactPath) || // Match exact path in api_field_names
        apiFieldNames?.includes(generalizedPath); // Match generalized path in api_field_names

      return pathExists;
    };

    const handleRemove = () => {
      handleSelectionChange(null, path, true, "both");
    };

    return (
      <>
        {String(valueAsString)} {/* Safely handle the value as a string */}
        <IconButton
          onClick={(event) =>
            isRemove(path) ? handleRemove() : handleMenuOpen(event, path)
          }
          style={{ bottom: "1px" }}
        >
          {isRemove(path) ? <Remove /> : <Add />}
        </IconButton>
        {/* Menu for actions */}
        {!isRemove(path) && (
          <StyledMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && !nestedMenuOpen}
            onClose={handleMenuClose}
          >
            <StyledMenuItem onClick={() => handleMenuItemClick("bot")}>
              Use in Bot
            </StyledMenuItem>
            <StyledMenuItem onClick={() => handleMenuItemClick("contact")}>
              Save As Contact Properties
            </StyledMenuItem>
            <StyledMenuItem onClick={() => handleMenuItemClick("both")}>
              Both
            </StyledMenuItem>
          </StyledMenu>
        )}
        {/* Nested Menu for additional options */}
        {nestedMenuOpen && (
          <StyledMenu
            anchorEl={anchorEl}
            open={nestedMenuOpen}
            onClose={handleMenuClose}
          >
            <StyledMenuItem
              onClick={() =>
                handleNestedMenuItemClick("all-index", isRemove(path))
              }
            >
              All Index
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() =>
                handleNestedMenuItemClick("selected-index", isRemove(path))
              }
            >
              Selected Index
            </StyledMenuItem>
          </StyledMenu>
        )}
      </>
    );
  };

  // Default theme if none is provided
  const defaultTheme = {
    scheme: "dark-custom",
    base00: "#1f1f1f", // Dark grey base background (primary)
    base01: "#313131", // Slightly darker grey for nested nodes or subtle contrasts
    base02: "#666666", // Medium grey for subtle background contrast
    base03: "#7E8392", // Grey for comments, metadata, and secondary text
    base04: "#CBCDD3", // Light grey for borders and dividers
    base05: "#FFFFFF", // Primary text color (white)
    base06: "#F1F1F1", // Lighter grey for highlights and interactive elements
    base07: "#F9F9F9", // Very light grey for strong highlights
    base08: "#FF4E6A", // Neon pink for error messages or critical labels
    base09: "#FF9F47", // Accent orange for warnings or highlights
    base0A: "#FFD700", // Neon yellow for notices or attention-grabbing elements
    base0B: "#00FFAA", // Neon green for success messages or confirmations
    base0C: "#00FFFF", // Neon cyan for links, keys, or identifiers
    base0D: "#008BFF", // Neon blue for focus or highlighted elements
    base0E: "#C084FC", // Neon lavender for accent details
    base0F: "#FF8C00", // Neon orange for deeper highlights and accents

    // Define custom colors for specific JSON data types
    string: {
      color: "#FF6347", // Tomato red for string values
    },
    number: {
      color: "#32CD32", // Lime green for numbers
    },
    boolean: {
      color: "#FFD700", // Gold for boolean values
    },
    null: {
      color: "#FF4500", // Orange red for null values
    },
    object: {
      color: "#1E90FF", // Dodger blue for objects
    },
    array: {
      color: "#8A2BE2", // Blue violet for arrays
    },
    key: {
      color: "#ADFF2F", // Green yellow for keys/labels
    },

    tree: {
      style: {
        fontSize: "18px",
        fontFamily: "Arial, sans-serif",
        fontWeight: "bold", // Make font bold
      },
    },
  };

  return (
    <>
      {jsonData ? (
        <TreeContainer>
          <JSONTree
            data={jsonData}
            valueRenderer={valueRenderer}
            theme={theme || defaultTheme} // Use provided theme or default
            shouldExpandNodeInitially={shouldExpandNode} // Allow control over node expansion
          />
        </TreeContainer>
      ) : (
        ""
      )}
    </>
  );
};

export default JsonViewer;
