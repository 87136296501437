import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../../../../../../assets/images/deletegrey.svg";
import { ReactComponent as LinkIcon } from "../../../../../../../assets/images/link.svg";
import nodata from "../../../../../../../assets/images/noDatafound.svg";
import { ReactComponent as ReatainIcon } from "../../../../../../../assets/images/retain.svg";

import DeleteConfirmationModal from "./DeleteConfirmationModal";
import ShowAllLinksCommonTable from "./ShowAllLinksCommonTable";
import WebsiteAndSitemapUpdateModal from "./WebsiteAndSitemapUpdateModal";
import { useAppDispatch } from "../../../../../../../Store";
import { useNotification } from "../../../../../../../Hooks/useNotification/useNotification";
import useModal from "../../../../../../../Hooks/useModel/useModel";
import { FaqSourceAction } from "../../../../../../../Store/Slices/Faq/Source/source.actions";
import { NewTable } from "../../../../../../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import AppButton from "../../../../../../../AppComponents/AppButton";
import { setcrawlLinkList } from "../../../../../../../Store/Slices/Faq/Source/source.slice";
import AppBadge from "../../../../../../../AppComponents/AppBadge";
import { AppMaterialIcons } from "../../../../../../../AppComponents/AppMaterialIcons";
import { theme } from "../../../../../../../Customization/Theme";
interface Props {
  listData: any;
  type: string;
  selectedDocumentIds: any;
  setSelectedDocumentIds: any;
  setLocalValues: any;
}
const WebsiteAndSitemapTable: React.FC<Props> = (props) => {
  const {
    type,
    listData,
    setSelectedDocumentIds,
    selectedDocumentIds,
    setLocalValues,
  } = props;

  // hooks **********
  const params = useParams();
  // const *****
  const { bot_id } = params;
  const HEADERS = {
    appid: JSON.stringify({
      botid: bot_id,
    }),
  };

  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  // states ********
  // source id for update modal
  const [selectedRow, setSelectedRow] = useState<any>({});
  // Action PopOver for Table
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  // modals hooks********
  // for update modal
  const useModalUpdate = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModalUpdate: isShowing, toggleModalUpdate: toggle };
  };
  const { isShowingModalUpdate, toggleModalUpdate } = useModalUpdate();

  // for show All links modal
  const useModalShowAllLinks = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingAllLinks: isShowing, toggleModalShowAllLinks: toggle };
  };
  const { isShowingAllLinks, toggleModalShowAllLinks } = useModalShowAllLinks();

  // delete Modals
  const ConfirmModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingConfirmModal: isShowing, toggleConfirmModal: toggle };
  };
  const { isShowingConfirmModal, toggleConfirmModal } = ConfirmModal();

  // Action Click for table
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(item);
  };

  // Close Actions for table
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // single select
  // Handle Single Check
  const handleSelectChanges = (
    event: React.ChangeEvent<HTMLInputElement>,
    documentId: string
  ) => {
    const isChecked = event.target.checked;

    setSelectedDocumentIds((prevSelectedIds: any) => {
      let updatedSelectedIds = isChecked
        ? [...prevSelectedIds, documentId]
        : prevSelectedIds.filter((id: any) => id !== documentId);

      return updatedSelectedIds;
    });
  };

  // Function To Delete
  const deletSourceByDocumentId = () => {
    const data = {
      HEADERS,
      documentIds: [selectedRow?.documentId],
    };
    dispatch(FaqSourceAction.DeleteSourceByIds(data))
      .then((res: any) => {
        if (res?.payload?.status === 200) {
          showAlert("Url Is Deleted", "success");
          setLocalValues((prev: any) => ({ ...prev }));
          setSelectedDocumentIds([]);
          toggleConfirmModal();
          handleClose();
        } else {
          showAlert("Error", "error");
        }
      })
      .catch((error: any) => {
        showAlert(error, "error");
      });
  };

  // reTry url api call
  const reTrain = (status: any) => {
    const payload = {
      urls: { documentId: selectedRow.documentId },
      headers: HEADERS,
    };

    dispatch(FaqSourceAction.PostSelectedSubUrl(payload))
      .then((res: any) => {
        if (res?.payload?.data?.error) {
          showAlert(res?.payload?.data?.error, "error");
        } else {
          if (res?.payload?.data && !res?.payload?.data?.error) {
            showAlert(
              `URL ${status === "failed" ? "Retry" : "Retrain"} Successfully!`,
              "success"
            );
            setLocalValues((prev: any) => ({ ...prev }));
            handleClose();
          }
        }
      })
      .catch((error: any) => {
        showAlert("submit false", "error");
      })
      .finally(() => {});
  };

  return (
    <React.Fragment>
      {/* Table Start */}
      {!listData || listData.length === 0 ? (
        <div className="front-image">
          <div className="no-data-img">
            <img src={nodata} alt="Description about" />
          </div>
          <div className="front-text">No Data found</div>
        </div>
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <NewTable>
            <Box className="table-header">
              <Box className="table-row">
                <Box className="col-head hide-column-line">URL</Box>
                <Box className="col-head">
                  <span className="hidden-text">Data URL</span>
                </Box>
                <Box className="col-head">Data Characters</Box>
                <Box className="col-head">Status</Box>
                <Box className="col-head">Created</Box>
                <Box className="col-head"></Box>
              </Box>
            </Box>
            <Box
              className="table-body"
              sx={{ height: "calc(100vh - 487px)", overflow: "auto" }}
            >
              {listData?.map((item: any) => {
                return (
                  <Box className="table-row">
                    <Box className="col-body">
                      <Checkbox
                        checked={selectedDocumentIds.includes(item.documentId)}
                        onChange={(event: any) =>
                          handleSelectChanges(event, item.documentId)
                        }
                        id={item?.documentId}
                        disabled={item?.status === "deleting"}
                      />
                    </Box>
                    <Box className="col-body">{item?.url}</Box>
                    <Box
                      className="col-body"
                      style={{ minWidth: "180px", maxWidth: "180px" }}
                    >
                      <Box className="url-count">
                        {item?.count}
                        <AppButton
                          variant="outline"
                          onClick={() => {
                            dispatch(setcrawlLinkList(null));
                            setTimeout(() => {
                              toggleModalShowAllLinks();
                              setSelectedRow(item);
                            }, 100);
                          }}
                          disabled={item?.status === "deleting"}
                        >
                          Show All Links
                        </AppButton>
                      </Box>
                    </Box>
                    <Box className="col-body">{item?.characters}</Box>
                    <Box className="col-body">
                      {item?.status ? (
                        <AppBadge
                          status={item?.status}
                          isTooltipAvaliabe={true}
                        />
                      ) : (
                        "--"
                      )}
                    </Box>
                    <Box className="col-body">
                      {moment(item?.createdOn).format("DD-MM-YYYY h:mm A")}
                    </Box>
                    <Box className="col-body">
                      <IconButton
                        onClick={(e) => handleClick(e, item)}
                        aria-describedby={id}
                        disabled={item?.status === "deleting"}
                      >
                        <AppMaterialIcons iconName="MoreVert" />
                      </IconButton>

                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{
                          "& .MuiPaper-root": {
                            borderRadius: 1,
                            boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                          },
                        }}
                      >
                        <Box
                          sx={{ width: "100%", maxWidth: 130, minWidth: 130 }}
                        >
                          <nav aria-label="secondary mailbox folders">
                            <List
                              disablePadding
                              sx={{
                                color: `${theme.palette.default.darkGrey}`,
                              }}
                            >
                              <ListItem disablePadding>
                                <ListItemButton
                                  sx={{ p: "0 2" }}
                                  onClick={() => {
                                    toggleModalUpdate();
                                  }}
                                >
                                  <LinkIcon />
                                  <Typography sx={{ ml: 1 }} variant="body2">
                                    Add Link
                                  </Typography>
                                </ListItemButton>
                              </ListItem>
                              <Divider />

                              <ListItem
                                disablePadding
                                onClick={(e) => {
                                  reTrain(item?.status);
                                }}
                              >
                                <ListItemButton>
                                  <ReatainIcon />
                                  <Typography sx={{ ml: 1 }} variant="body2">
                                    {item?.status === "failed"
                                      ? "Retry"
                                      : "Retrain"}
                                  </Typography>
                                </ListItemButton>
                              </ListItem>

                              <Divider />
                              <ListItem disablePadding>
                                <ListItemButton
                                  onClick={(e: any) => {
                                    toggleConfirmModal();
                                  }}
                                >
                                  <DeleteIcon />
                                  <Typography sx={{ ml: 1 }} variant="body2">
                                    Delete
                                  </Typography>
                                </ListItemButton>
                              </ListItem>
                            </List>
                          </nav>
                        </Box>
                      </Popover>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </NewTable>
        </Box>
      )}
      {/* Table End */}

      {/* Edit / Update Modal */}
      <WebsiteAndSitemapUpdateModal
        toggleModalUpdate={toggleModalUpdate}
        isShowingModalUpdate={isShowingModalUpdate}
        type={type}
        selectedRow={selectedRow}
        handleClose={handleClose}
      />

      {/* Show All Links Modal*/}
      <ShowAllLinksCommonTable
        toggleModalShowAllLinks={toggleModalShowAllLinks}
        isShowingAllLinks={isShowingAllLinks}
        type={type}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />

      {/* delete confirmation modal */}
      <DeleteConfirmationModal
        toggleConfirmModal={toggleConfirmModal}
        deletSourceByDocumentId={deletSourceByDocumentId}
        selectedDocumentIds={selectedRow}
        isShowingConfirmModal={isShowingConfirmModal}
        type={"Url"}
      />
    </React.Fragment>
  );
};

export default WebsiteAndSitemapTable;
