import { IconButton } from "@mui/material";
import NavigationBar from "./NavigationBar";
import ResponsiveTable from "./ResponsiveTable";
import {
  Button,
  CreditBalanceDetails,
  CreditDetails,
  LivePlanContainer,
  Title,
} from "./style";
import { ReactComponent as Credit } from "../../assets/images/icons/credit.svg";
import { useAppDispatch, useAppSelector } from "../../Store";
import { useCurrentUser } from "../../Store/Slices/CurrentUser/CurrentUserSlice";
import { useState } from "react";
import { format } from "date-fns";
import { resetInvoicePlanList } from "../../Store/Slices/Subscriptions/subscriptions.slice";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { useSelector } from "react-redux";
import { SubscriptionActions } from "../../Store/Slices/Subscriptions/subscriptions.actions";
import {
  getAllInvoiceList,
  getInvoiceCount,
} from "../../Store/Slices/Subscriptions/subscriptions.selectors";
import AppButton from "../../AppComponents/AppButton";
const InvoicesList = () => {
  const { userCreditsInfo } = useAppSelector(useCurrentUser);
  const { data: authData } = useSelector(useAuthStore);
  const invoiceList = useSelector(getAllInvoiceList);
  const getTotalInvoiceCount = useSelector(getInvoiceCount);
  const [check, setCheck] = useState(false);
  const dispatch = useAppDispatch();
  const getInvoiceList = (localValues: any, dateRange: any) => {
    dispatch(resetInvoicePlanList({}));
    const payload: any = {
      start_date: format(dateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dateRange.endDate, "yyyy-MM-dd"),
      limit: localValues?.itemsPerPage,
      offset: localValues?.currentPage + 1,
      filter_by: "",
      search: localValues?.search,
      customer_Id: authData?.data?.business?.subscription_data?.customer_id,
    };
    
    const totalCountPayload: any = {
      start_date: format(dateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dateRange.endDate, "yyyy-MM-dd"),
      filter_by: "",
      search: localValues?.search,
      customer_Id: authData?.data?.business?.subscription_data?.customer_id,
    };

    // Return Promise.all to wait for both actions to complete
    return Promise.all([
      dispatch(SubscriptionActions.getAllInvoiceList(payload)),
      dispatch(SubscriptionActions.getInvoiceCount(totalCountPayload))
    ]);
};
  return (
    <CreditDetails>
      <NavigationBar list={["Invoices"]} />
      <CreditBalanceDetails>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ padding: 0, display: "flex", alignItems: "center" }}>
            <Credit />
            <Title
              style={{ marginLeft: "15px" }}
              fontSize={16}
              color={"#7E8392"}
            >
              Available Credit balance
              <br />
              <Title fontSize={30} color={"#000"}>
                INR {userCreditsInfo?.remaining_amounts}
              </Title>
            </Title>
          </div>
          <LivePlanContainer align={"flex-end"} gap={"8px"}>
            <AppButton
              style={{ width: "272px", height: "60px", fontSize: "20px" }}
              onClick={() => setCheck(true)}
            >
              Buy credits
            </AppButton>
          </LivePlanContainer>
        </div>
      </CreditBalanceDetails>
      <ResponsiveTable
        callBackApi={getInvoiceList}
        invoiceList={invoiceList}
        totalInvoiceCount={getTotalInvoiceCount}
        check={check}
        setCheck={setCheck}
      />
    </CreditDetails>
  );
};
export default InvoicesList;
