import TransferToFAQs from "./../Actions/transfer-to-faqs";
import NodeToNode from "./../Actions/node-to-node";
import Function from "./../Actions/function";
import ApiLibrary from "./../Actions/api-library";

export const registerActions: any = [
  // {
  //   icon: "faq-icon",
  //   type: "transfer_to_faqs",
  //   color: "#C8EF87",
  //   name: "Transfer to FAQs",
  //   displayComponent: TransferToFAQs,
  //   defaultText: "Please input your query below.",
  //   enableOnLastNode: true,
  //   hideAddButton: true,
  // },
  {
    icon: "nodes",
    type: "connect_node",
    color: "#B8C4FF",
    name: "Node to node",
    displayComponent: NodeToNode,
    enableOnLastNode: true,
    hideAddButton: true,
    shouldBeAddedOnce: true,
  },
  {
    icon: "function",
    type: "function",
    color: "#9DF8D7",
    name: "JS Function",
    displayComponent: Function,
    enableOnLastNode: false,
    hideAddButton: false,
    shouldBeAddedOnce: false,
  },
  {
    icon: "api",
    type: "api-library",
    color: "#00FFFF",
    name: "API Library",
    displayComponent: ApiLibrary,
    enableOnLastNode: false,
    hideAddButton: false,
    shouldBeAddedOnce: false,
  }
];
