// import { convertDateTimeToUTC } from "../../../Broadcast/WhatsApp/Utils/utils";

import { convertDateTimeToUTC } from "../../../../../Components/Broadcast/WhatsApp/Utils/utils";

export const convertFormToPayloadSingleWhatsappBroadcast = (values: any) => {
  let payload: any = {
    components: {},
    template_id: values?.template?.template_id,
  };
  if (
    values?.template?.original_response?.button_type === "limited_time_offer"
  ) {
    payload["limited_time_offer"] = {};
    payload["limited_time_offer"]["value"] = convertDateTimeToUTC(
      values?.limitedTimeOfferDate,
      values?.limitedTimeOfferTime
    );
  }

  if (values?.resolved_header_variables?.length > 0) {
    payload["components"]["header"] = {
      type: values?.header_type?.toLowerCase(),
      value:
        typeof values?.resolved_header_variables?.[0]?.value === "object"
          ? values?.resolved_header_variables?.[0]?.value?.source
          : ["image", "video", "document"].includes(
              values?.header_type?.toLowerCase()
            )
          ? `<<${values?.resolved_header_variables?.[0]?.value}>>`
          : values?.resolved_header_variables?.[0]?.value,
      index:
        values?.header_type === "TEXT"
          ? values?.resolved_header_variables?.[0]?.index
          : null,
    };
  }
  if (values?.resolved_body_variables?.length > 0) {
    payload["components"]["body"] = {
      value: values?.resolved_body_variables,
    };
  }
  if (values?.resolved_buttons_variables?.length > 0) {
    payload["components"]["button"] = {
      value: values?.resolved_buttons_variables,
      type: "dynamic",
    };
  }

  const processedData = values?.resolved_carousel_variables?.map(
    (item: any) => {
      const headerValue = item.header?.value;

      if (typeof headerValue === "object") {
        // Keep value as it is if it's an object
        return { ...item, header: { ...item.header, value: headerValue } };
      } else if (typeof headerValue === "string") {
        // Wrap the string in << >>
        return {
          ...item,
          header: { ...item.header, value: `<<${headerValue}>>` },
        };
      }
      // Return the item as is if the value is neither an object nor a string
      return item;
    }
  );
  if (values?.resolved_carousel_variables?.length > 0) {
    payload["components"]["carousel"] = {
      value: processedData,
      type: "carousel",
    };
  }
  return payload;
};