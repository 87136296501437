import styled from "styled-components";
import Danger from "../../../../../../../assets/images/danger-live.svg"
import React from "react";
import AppModel from "../../../../../../../Hooks/useModel/AppModel";
import { AppMaterialIcons } from "../../../../../../../AppComponents/AppMaterialIcons";
import AppButton from "../../../../../../../AppComponents/AppButton";
import { theme } from "../../../../../../../Customization/Theme";

interface Props {
  toggleConfirmModal: () => void;
  deletSourceByDocumentId: () => void;
  selectedDocumentIds: string[];
  isShowingConfirmModal: any;
  type: string;
}
const DeleteConfirmationModal: React.FC<Props> = ({
  toggleConfirmModal,
  deletSourceByDocumentId,
  selectedDocumentIds,
  isShowingConfirmModal,
  type,
}) => {
  return (
    <AppModel
      isShowing={isShowingConfirmModal}
      onClose={() => toggleConfirmModal()}
      divStyle={{
        padding: 0,
      }}
      PaperProps={{
        style: {
          boxShadow: "none",
          width: "497px",
        },
      }}
    >
      <ConfirmDelete>
        <div className="header">
          <div className="header-left">
            <div className="danger">
              <img src={Danger} alt="" />
            </div>
            <div className="text">
              <h4>
                Delete {selectedDocumentIds?.length || 1} {type} From List
              </h4>
              <p>Are you sure, you want to delete?</p>
            </div>
          </div>
          <div className="header-right">
            <span onClick={() => toggleConfirmModal()}>
              <AppMaterialIcons iconName="close" />
            </span>
          </div>
        </div>
        <div className="footer">
          <AppButton variant="grey" onClick={() => toggleConfirmModal()}>
            Cancel
          </AppButton>
          <AppButton
            variant="danger-filled"
            onClick={() => deletSourceByDocumentId()}
          >
            <AppMaterialIcons iconName="DeleteOutlineOutlined" />
            Delete
          </AppButton>
        </div>
      </ConfirmDelete>
    </AppModel>
  );
};
export default DeleteConfirmationModal;

const ConfirmDelete = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.875rem;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
    .header-left {
      display: flex;
      gap: 0.875rem;
      .danger {
        padding-top: 4px;
        img {
          width: 1.2rem;
        }
      }
      .text h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .text p {
        color: ${theme.palette.default.darkGrey};
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
  }
`;
