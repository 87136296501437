import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  CompositeDecorator,
  ContentBlock,
  Editor,
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
  getDefaultKeyBinding,
} from "draft-js";
import {
  blockRenderMap,
  changeDepth,
  extractInlineStyle,
  getCustomStyleMap,
  getSelectedBlocksType,
  handleNewLine,
} from "draftjs-utils";
import { Component } from "react";

// draft js utils
import defaultToolbar from "./config/defaultToolbar";
import Controls from "./controls";
import getLinkDecorator from "./decorators/Link";
import getPropertyDecorator from "./decorators/Property";
import FocusHandler from "./event-handler/focus";
import getBlockRenderFunc from "./renderer";
import blockStyleFn from "./utils/BlockStyle";
import { hasProperty } from "./utils/common";

// components
import Icon from "../icon";
import UploadSection from "./upload";

// styles
import {
  EditorInnerWrapperStyled,
  EditorMainWrapperStyled,
  UploadSectionCloseButtonStyled,
} from "./editor.styled";

interface Props {
  editorState: any;
  onBlur?: any;
  onFocus?: any;
  onTab?: any;
  readOnly?: any;
  onEditorStateChange: any;
  onChange?: any;
  editorRef?: any;
  mediaInfo?: MediaInfoType;
  whatsappEditorOptions?: boolean;
  hideFocusOnLoad?: boolean;
  error?: boolean;
  media?: boolean;
}

type URL = {
  url: string;
};

type MediaInfoType = {
  file: URL;
  image: URL;
  video: URL;
};

interface States {
  editorState: any;
  editorFocused: boolean;
  toolbar: any;
  upload: string;
  mediaInfo: MediaInfoType;
  error: boolean;
}

const defaultMediaInfo = {
  file: { url: "" },
  image: { url: "" },
  video: { url: "" },
};

class UIEditor extends Component<Props, States> {
  blockRendererFn: ((block: ContentBlock) => any) | undefined;
  focusHandler: any;
  compositeDecorator: CompositeDecorator;
  wrapper: any;
  editor: any;

  constructor(props: any) {
    super(props);
    const toolbar = defaultToolbar;
    this.focusHandler = new FocusHandler();
    this.blockRendererFn = getBlockRenderFunc(
      {
        getEditorState: this.getEditorState,
        onChange: this.onChange,
      },
      props.customBlockRenderFunc
    );
    this.compositeDecorator = this.getCompositeDecorator(toolbar);
    const editorState = this.createEditorState(this.compositeDecorator);
    extractInlineStyle(editorState);
    this.state = {
      editorState,
      editorFocused: false,
      toolbar,
      upload: props?.mediaInfo ? this.getuploadType() : "",
      mediaInfo: props?.mediaInfo ? props.mediaInfo : defaultMediaInfo,
      error: props?.error || false,
    };
  }

  getuploadType = () => {
    const { mediaInfo } = this.props;
    let uploadType = "";
    if (mediaInfo) {
      Object?.entries(mediaInfo)?.map((item) => {
        if (item[1]?.url !== "") {
          uploadType = item[0];
        }
      });
    }
    return uploadType;
  };

  focusEditor = () => {
    setTimeout(() => {
      if (this.editor !== null)
        this.editor.focus();
    });
  };

  componentDidMount() {
    // * kindly do not remove to stop focus mode onLoad
    if (this.props.hideFocusOnLoad) {
      //do nothing 
    }
    else {
      this.focusEditor();
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps === this.props) return;
    const newState: any = {};
    const { editorState, mediaInfo } = this.props;
    if (
      hasProperty(this.props, "editorState") &&
      editorState !== prevProps.editorState
    ) {
      if (editorState) {
        newState.editorState = EditorState.set(editorState, {
          decorator: this.compositeDecorator,
        });
      } else {
        newState.editorState = EditorState.createEmpty(this.compositeDecorator);
      }
    }
    if (prevProps.editorState !== editorState) {
      extractInlineStyle(newState.editorState);
    }
    if (Object.keys(newState).length) this.setState(newState);

    //* Fixed: sometimes mediaInfo wasn't updating while retriving values from api 
    if (mediaInfo && hasProperty(this.props, "mediaInfo") && prevProps?.mediaInfo !== mediaInfo) {
      this.setState({ mediaInfo, upload: this.getuploadType() })
    }
  }

  getCompositeDecorator = (toolbar: any) => {
    const decorators = [
      getLinkDecorator({
        showOpenOptionOnHover: toolbar.link.showOpenOptionOnHover,
      }),
      getPropertyDecorator({}),
    ];
    return new CompositeDecorator(decorators);
  };

  createEditorState = (compositeDecorator: any) => {
    let editorState;
    if (hasProperty(this.props, "editorState")) {
      if (this.props.editorState) {
        editorState = EditorState.set(this.props.editorState, {
          decorator: compositeDecorator,
        });
      }
    }

    if (!editorState) {
      editorState = EditorState.createEmpty(compositeDecorator);
    }
    return editorState;
  };

  getEditorState = () => (this.state ? this.state.editorState : null);

  changeEditorState = (contentState: any) => {
    const newContentState = convertFromRaw(contentState);
    let { editorState } = this.state;
    editorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );
    editorState = EditorState.moveSelectionToEnd(editorState);
    return editorState;
  };

  setEditorReference = (ref: any) => {
    if (this.props.editorRef) {
      this.props.editorRef(ref);
    }
    this.editor = ref;
  };

  keyBindingFn = (event: any) => {
    if (event.key === "Tab") {
      const { onTab } = this.props;
      if (!onTab || !onTab(event)) {
        const editorState = changeDepth(
          this.state.editorState,
          event.shiftKey ? -1 : 1,
          4
        );
        if (editorState && editorState !== this.state.editorState) {
          this.onChange(editorState);
          event.preventDefault();
        }
      }
      return null;
    }

    return getDefaultKeyBinding(event);
  };

  onChange = (editorState: any) => {
    const { readOnly, onEditorStateChange, mediaInfo } = this.props;
    if (
      !readOnly &&
      !(
        getSelectedBlocksType(editorState) === "atomic" &&
        editorState.getSelection().isCollapsed
      )
    ) {
      if (onEditorStateChange) {
        onEditorStateChange(
          editorState,
          mediaInfo
        );
      }
      if (!hasProperty(this.props, "editorState")) {
        // @ts-ignore
        this.setState({ editorState }, this.afterChange(editorState));
      } else {
        this.afterChange(editorState);
      }
    }
  };

  afterChange = (editorState: any) => {
    setTimeout(() => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(
          convertToRaw(editorState.getCurrentContent()),
          this.state.mediaInfo
        );
      }
    });
  };

  handleReturn = (event: any) => {
    const { editorState } = this.state;
    const newEditorState = handleNewLine(editorState, event);
    if (newEditorState) {
      this.onChange(newEditorState);
      return true;
    }
    return false;
  };

  handleKeyCommand = (command: any) => {
    const {
      editorState,
      toolbar: { inline },
    } = this.state;
    if (inline && inline.options.indexOf(command) >= 0) {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        this.onChange(newState);
      }
    }
  };

  preventDefault = (event: any) => {
    if (event.target.tagName === "INPUT") {
      this.focusHandler.onInputMouseDown();
    } else {
      event.preventDefault();
    }
  };

  onToolbarFocus = (event: any) => {
    const { onFocus } = this.props;
    if (onFocus && this.focusHandler.isToolbarFocused()) {
      onFocus(event);
    }
  };

  onUploadClick = (uploadOption: string) => {
    this.setState({ upload: uploadOption });
  };

  onMediaSave = (url: string, type: string) => {
    const { onEditorStateChange } = this.props;
    this.setState({
      mediaInfo: { ...defaultMediaInfo, [type]: { url } },
    }, () => {
      if (onEditorStateChange) {
        onEditorStateChange(
          this.state.editorState,
          { ...defaultMediaInfo, ...{ [type]: { url } } }
        );
      }
    });
  };

  onMediaDelete = () => {
    const { onEditorStateChange } = this.props;
    this.setState({
      mediaInfo: {
        ...defaultMediaInfo,
        ...{ [this.state.upload]: { url: "" } },
      },
    });
    if (onEditorStateChange) {
      onEditorStateChange(
        this.state.editorState,
        { ...defaultMediaInfo, ...{ [this.state.upload]: { url: "" } } }
      );
    }
  };

  getStyleMap = () => ({ ...getCustomStyleMap() });

  getTextData = () => {
    const { editorState } = this.state;
    let value = "";
    if (editorState) {
      const blocks = convertToRaw(
        this.state.editorState.getCurrentContent()
      ).blocks;
      value = blocks
        .map((block) => (!block.text.trim() && "\n") || block.text)
        .join("\n");
    }
    return value;
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      const blocks = convertToRaw(
        this.state.editorState.getCurrentContent()
      ).blocks;
      const value = blocks
        .map((block) => (!block.text.trim() && "\n") || block.text)
        .join("\n");
      onBlur(value);
    }
  };

  render() {
    const { editorState, toolbar, upload, mediaInfo } = this.state;
    const controlProps = {
      editorState,
      onChange: this.onChange,
      onUploadClick: this.onUploadClick,
    };

    let checkForURL: any = upload !== "" ? mediaInfo : "";
    // @ts-ignore
    checkForURL = checkForURL[upload];

    let isURL = checkForURL?.url;
    let uploadURLType = upload;
    Object.entries(mediaInfo).map((item) => {
      if (item[1]?.url !== "" && item[0] === upload) {
        isURL = item[1]?.url;
        uploadURLType = item[0];
      }
    });

    return (
      <EditorMainWrapperStyled
        className="wrapper"
        error={this.props.error || false}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {(isURL || uploadURLType) && (
          <Box sx={{ position: "relative" }}>
            {!isURL && (
              <UploadSectionCloseButtonStyled
                onClick={() => this.setState({ upload: "" })}
                size="medium"
              >
                <Icon icon="close" size={12} color="#fff" />
              </UploadSectionCloseButtonStyled>
            )}
            <UploadSection
              url={isURL}
              type={uploadURLType}
              onSave={this.onMediaSave}
              onDelete={this.onMediaDelete}
            />
          </Box>
        )}
        <EditorInnerWrapperStyled className="inner-wrapper">
          <Editor
            ref={this.setEditorReference}
            keyBindingFn={this.keyBindingFn}
            editorState={editorState}
            onChange={this.onChange}
            blockStyleFn={blockStyleFn}
            customStyleMap={this.getStyleMap()}
            blockRendererFn={this.blockRendererFn}
            // @ts-ignore
            handleKeyCommand={this.handleKeyCommand}
            blockRenderMap={blockRenderMap}
            onBlur={this.handleBlur}
            placeholder="Type your message here"
            readOnly={this.props.readOnly}
          />
        </EditorInnerWrapperStyled>
        <Box onMouseDown={this.preventDefault} onFocus={this.onToolbarFocus} style={{paddingTop: "10px"}}>
          <Stack direction={"row"} spacing={2}>
            {toolbar.options.map((opt: any, index: any) => {
              if (typeof this.props?.media === 'boolean') {
                if (!this.props?.media && opt === 'upload') {
                  return;
                }
              }
              // @ts-ignore
              const Control = Controls[opt];
              const config = toolbar[opt];

              //* hiding some options for whatsapp editor 
              if (this.props.readOnly || this.props.whatsappEditorOptions && (config?.icon === 'upload' || config?.link?.icon === 'link')) {
                return null;
              }
              if (this.props.whatsappEditorOptions && !this.props.readOnly) {
                config['blockOptions'] = [];
                config['options'] = config['options']?.filter((e: any) => e !== 'underline')
              }

              return <Control key={index} {...controlProps} config={config} />;
            })}
          </Stack>
        </Box>
      </EditorMainWrapperStyled>
    );
  }
}

export default UIEditor;
