import { RootState } from "../../index";

export const ContactListingPagination = (state: RootState) =>
  state.Contacts.data.pagination;
export const ContactsListingData = (state: RootState) =>
  state.Contacts.data.contactsListData;
export const ContactsSelectData = (state: RootState) =>
  state.Contacts.data.contactsSelectData;
export const ContactsAddFields = (state: RootState) =>
  state.Contacts.data.contactsFields;
export const ContactHasUpdateAccess = (state: RootState) =>
  state.Contacts.data.hasGlobalAccess;
export const ContactSortingObject = (state: RootState) =>
  state.Contacts.data.contactsTableSorting;
export const ContactField = (state: RootState) =>
  state.Contacts.data.fieldCheck;

export const editContactsAddFields = (state: RootState) =>
  state.Contacts.data.editByIdContactsField;

export const ContactsAddDropDown = (state: RootState) =>
  state.Contacts.data.addContactsDropDownList;
export const ConatctsLifeCycle = (state: RootState) =>
  state.Contacts.data.licfeCycleConatcts;

export const getLostReasonData = (state: RootState) =>
  state.Contacts.data.lostReasonData;

export const ContactsTagsList = (state: RootState) =>
  state.Contacts.data.contactTagsList;

export const AssigneeList = (state: RootState) =>
  state.Contacts.data.assigneeList;

export const bulkUpdateFields = (state: RootState) =>
  state.Contacts.data.bulkUpdateFields;
export const UndePendantFieldDropDown = (state: RootState) =>
  state.Contacts.data.unDependantContactsDropDownList;

export const getAddToList = (state: RootState) =>
  state.Contacts.data.addTolistData;

export const GridData = (state: RootState) => state.Contacts.data.gridData;
export const GridDataCount = (state: RootState) => state.Contacts.data.gridDataCount;
export const isGridDataLoading = (state: RootState) => state.Contacts.data.isGridDataLoading;
export const CardDetails = (state: RootState) =>
  state.Contacts.data.cardDetails;

export const SeeMore = (state: RootState) => state.Contacts.data.seeMore;
export const ContactById = (state: RootState) =>
  state.Contacts.data.ContactById;

export const afterDeletedContacts = (state: RootState) =>
  state.Contacts.data.delete;

export const exportedContactsList = (state: RootState) =>
  state.Contacts.data.exportedContacts;

export const getSequenceList = (state: RootState) =>
  state.Contacts.data.sequence;

export const getFilterQuery = (state: RootState) =>
  state.Contacts.data.filterQuery;
export const getSavedFilterQuery = (state: RootState) =>
  state.Contacts.data.savedFilterQuery;

export const resetFilterQuery = (state: RootState) =>
  state.Contacts.data.resetFilterQuery;

export const ContactBasicInfo = (state: RootState) =>
  state.Contacts.data.BasicInformation;

export const ContactCommunicationStats = (state: RootState) =>
  state.Contacts.data.Communication;

export const SourceDetails = (state: RootState) =>
  state.Contacts.data.SourceData;

export const MarketingInformation = (state: RootState) =>
  state.Contacts.data.marketingInfo;

export const Activities = (state: RootState) => state.Contacts.data.activity;

export const Files = (state: RootState) => state.Contacts.data.files;

export const ChatTranscript = (state: RootState) =>
  state.Contacts.data.chatTransScript;

export const getNotesDetails = (state: RootState) =>
  state.Contacts.data.addNotes;

export const contactEditId = (state: RootState) => state.Contacts.data.editId;

export const contactDetailsId = (state: RootState) => state.Contacts.data.contactId;

export const contactSectionData = (state: RootState) =>
  state.Contacts.data.sectionData;

export const contactSectionFieldsData = (state: RootState) =>
  state.Contacts.data.fieldsData;

export const contactSectionValuesData = (state: RootState) =>
  state.Contacts.data.sectionValues;

export const contactSectionFieldsValue = (state: RootState) =>
  state.Contacts.data.contactData;

export const fileUploaded = (state: RootState) =>
  state.Contacts.data.fileUploadedSuccess;

export const SingleContactTagsList = (state: RootState) =>
  state.Contacts.data.singleContactTagsList;

export const getAllNotes = (state: RootState) => state.Contacts.data.notes;

export const getTotalNoteCount = (state: RootState) =>
  state.Contacts.data.notesPagination;

export const getMarketingStatusOption = (state: RootState) =>
  state.Contacts.data.marketingStatusOption;

export const canAddNotes = (state: RootState) =>
  state.Contacts.data?.BasicInformation;

export const getFieldSectionData = (state: RootState) =>
  state.Contacts.data?.fieldSectionData;

// Contact History Data
export const getContactHistoryData = (state: RootState) =>
  state.Contacts.data?.contactHistoryData;

// Loading State
export const getContactHistoryLoading = (state: RootState) =>
  state.Contacts.loading;

// Error in API
export const getContactHistoryError = (state: RootState) =>
  state.Contacts.error;

//views listing
export const viewsListing = (state: RootState) =>
  state.Contacts.data.viewsListing;

export const enabledViewsListing = (state: RootState) =>
  state.Contacts.data.enabledViewsListing;

export const contactsFilterDrawerStatus = (state: RootState) =>
  state.Contacts.data.contactsFilterDrawerStatus;
export const selectedContactViewState = (state: RootState) =>
  state.Contacts.data.selectedContactView;

export const contactFieldHistoryData = (state: RootState) =>
  state.Contacts.data.contactFieldHistoryData;

export const contactFieldHistoryDataPagination = (state: RootState) =>
  state.Contacts.data.contactFieldHistoryDataPagination;

export const contactFieldHistoryDataLoading = (state: RootState) =>
  state.Contacts.data.contactFieldHistoryDataLoading;

export const contactFieldHistoryDataError = (state: RootState) =>
  state.Contacts.data.contactFieldHistoryDataError;

export const contactFieldsDataList = (state: RootState) =>
  state.Contacts.data.contactFieldsData;

export const getContactTaskOutcome = (state: RootState) =>
  state.Contacts.data.taskOutcome;

export const getContactTaskPriority = (state: RootState) =>
  state.Contacts.data.taskPriority;

export const getContactTaskType = (state: RootState) =>
  state.Contacts.data.taskType;
