import React from "react";
import { Stack, Chip, Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

interface Option {
  label: string;
  value: string;
}

interface MultiSelectProps {
  options: Option[];
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  selectedValues,
  setSelectedValues,
}) => {
  const handleDelete = (valueToDelete: string) => {
    setSelectedValues(selectedValues.filter((item) => item !== valueToDelete));
  };

  return (
    <Box
      sx={{
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "8px",
        display: "flex",
        minHeight: "50px",
        flexWrap: "wrap",
        gap: 1,
      }}
    >
      {selectedValues.map((value) => {
        const selectedOption = options.find((option) => option.value === value);
        return (
          <Chip
            key={value}
            label={selectedOption?.label || value}
            onDelete={() => handleDelete(value)}
            deleteIcon={
              <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
            }
            sx={{
              border: '1px solid #6150e1', // Customize the border color
              backgroundColor: 'transparent', // Transparent background for outlined look
              color: '#6150e1', // Text color
              '& .MuiChip-deleteIcon': {
                color: '#6150e1', // Icon color
                display: 'none',
              },
              '&:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0.1)', // Light hover background
              },
              '&:hover .MuiChip-deleteIcon': {
                display: 'inline-flex',
              },
            }}
          />
        );
      })}
    </Box>
  );
};
