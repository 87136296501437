import { createSlice } from "@reduxjs/toolkit";
import { FlowBuilderActions } from "./FlowBuilder.actions";

interface initialStateTypes {
    data: {
        screenId: any;
        contentData: any;
        flowBuilderList: any;
        subScreenList: any;
        screenType: any;
        contactMapping: any;
        defaultFlows: any;
        categoriesList: any;
        formName:any;
    };
    error: string;
    loading: boolean;
    channelName: any
}

const initialState: initialStateTypes = {
    data: {
        screenId: "",
        contentData: {},
        flowBuilderList: {
            "version": "4.0",
            "screens": [
            ]
        },
        subScreenList: [],
        screenType: "parentScreen",
        contactMapping: {},
        defaultFlows: [],
        categoriesList: [],
        formName :""
    },
    error: "",
    loading: false,
    channelName: ""
};

const WhatsappFlowBuilderSlice = createSlice({
    name: "WhatsappFlowBuilderSlice",
    initialState,
    reducers: {
        setScreenId(state, { payload }: any) {
            state.data.screenId = payload;
        },
        setContentData(state, { payload }: any) {
            state.data.contentData = payload;
        },
        setFlowBuilderList(state, { payload }: any) {
            state.data.flowBuilderList = payload;
        },
        setSubScreenList(state, { payload }: any) {
            state.data.subScreenList = payload;
        },
        setScreenType(state, { payload }: any) {
            state.data.screenType = payload;
        },
        setContactMapping(state, { payload }: any) {
            state.data.contactMapping = payload;
        },
        setDefaultList(state, { payload }: any) {
            state.data.defaultFlows = payload;
        },
        setCategorieList(state, { payload }: any) {
            state.data.categoriesList = payload;
        },
        setFormName(state, { payload }: any) {
            state.data.formName = payload;
        },
        resetContentData(state) {
            state.data.contentData = {};
            return state
        },
        setChannelName: (state, { payload }: any) => {
              state.channelName = payload;
          }
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                FlowBuilderActions.getDefaultFlowAndCategories.pending,
                (state, action) => {
                    state.error = "";
                    state.loading = true;
                }
            )
            .addCase(
                FlowBuilderActions.getDefaultFlowAndCategories.fulfilled,
                (state, action) => {
                    state.loading = false;
                    // Handle success case if needed
                }
            )
            .addCase(
                FlowBuilderActions.getDefaultFlowAndCategories.rejected,
                (state, action) => {
                    state.loading = false;
                    state.error = action.error.message ?? "Unknown error";
                }
            );
    },
});

export const { setScreenId, setContentData, resetContentData, setFlowBuilderList, setSubScreenList, setScreenType, setContactMapping, setDefaultList, setCategorieList,setFormName, setChannelName } = WhatsappFlowBuilderSlice.actions;

export default WhatsappFlowBuilderSlice.reducer;
