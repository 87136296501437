import { FieldArray, getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  AppFormDatePicker,
  AppFormFastField,
  AppFormSelectFastField,
  AppFormTextArea,
  AppFormTimePickerMui,
} from "../../../../AppComponents/Forms";
import { WhatsappNetworkService } from "../../../../Store/Slices/Whatsapp/WhatsappNetworkService";
import {
  getBodyComponentDetails,
  getButtonComponentDetails,
  getHeaderComponentDetails,
  makeStringFirstLetterUppercase,
} from "../../../../Utils";
import WhatsappMediaPicker from "../../../Broadcast/WhatsApp/Templates/components/WhatsappMediaPicker";
import {
  generateCarouselResolveVariables,
  generateButtonsResolveVariables,
  generateResolveVariables,
} from "../../../Broadcast/WhatsApp/Utils/utils";
import { theme } from "../../../../Customization/Theme";
import { ConversationService } from "../../../../Services/Apis/ConversationService";
import { useAuthStore } from "../../../../Store/Slices/AuthSlice";
import { useAppSelector } from "../../../../Store";
import { AxiosResponse } from "axios";
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";

const IMAGE_TYPES = ["image/jpeg", "image/png"];
const VIDEO_TYPES = ["video/mp4", "video/3gp"];
const AUDIO_TYPES = ["audio/aac", "audio/mp4", "audio/mpeg", "audio/amr"];
const STICKER_TYPES = ["image/webp"];

const DOCUMENT_TYPES = [
  "txt/plain",
  "application/pdf",
  "application/document",
  "application/vnd.ms-powerpoint",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
const tempOption: any = {
  label: "Value + Text",
  name: "value_text",
  placeholder: "Enter Your value",
  type: "text",
  valueEditorType: "input",
};
const staticOptoins: any = [
  {
    label: "COPY CODE",
    name: "COPY_CODE",
  },
  {
    label: "QUICK REPLY",
    name: "QUICK_REPLY",
  },
  {
    label: "PHONE NUMBER",
    name: "PHONE_NUMBER",
  },
];
const getCorrectMediaFormats = (mediaType: string) => {
  if (mediaType === "VIDEO") {
    return VIDEO_TYPES;
  } else if (mediaType === "IMAGE") {
    return IMAGE_TYPES;
  } else if (mediaType === "DOCUMENT") {
    return DOCUMENT_TYPES;
  } else if (mediaType === "STICKER") {
    return STICKER_TYPES;
  } else if (mediaType === "AUDIO") {
    return AUDIO_TYPES;
  } else {
    return [];
  }
};
interface Props {
  applyValue?:any
  removeAttachmentUpload?: boolean;
}

const WhatsappBroadcastResolveVaribles: React.FC<Props> = ({applyValue = false, removeAttachmentUpload=false}) => {
  const { values, setValues, setFieldValue } = useFormikContext<any>();
  let [filterFieldData, setFilterFieldData] = React.useState<any>([]);
  const { userConfig } = useAppSelector(useAuthStore);
  const [flowList, setFlowList] = useState<any>([]);
  const [activeBotId, setActiveBotId] = useState<string | null>(null)
  const selectedTemplateData = getIn(values, "template");

  useEffect(() => {
    WhatsappNetworkService.getFieldsTypeMappingList("").then((res: any) => {
      let mappingFields: any = res?.data?.mappingFields || [];
      let list = [tempOption, ...staticOptoins, ...mappingFields];
      setFilterFieldData(list);
    });
    ChatbotConsoleService.getAllBots().then((res: any) => {
      if (res.data?.results?.length > 0) {
        let activeBot = res.data.results.filter((el: any) => el.channel === "whatsapp" && el.bot_enabled === true);
        if(activeBot.length){
          setActiveBotId(activeBot[0].bot_id)
        }
      }
    })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if(activeBotId){
    const HEADERS = {
      appid: JSON.stringify({
        botid: activeBotId,
      }),
    };
    ConversationService.getFlowList(HEADERS)
      .then((res: AxiosResponse<any>) => {
        
        if (Array.isArray(res?.data?.data)) {
          setFlowList(res?.data?.data);
        } else {
          setFlowList([]);
        }
      })
      .catch((error: any) => {});
    }
  }, [activeBotId]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (getIn(values, "resolved_buttons_variables")) {
      getIn(values, "resolved_buttons_variables")?.map(
        (res: any, index: any) => {
          if (
            res.sub_type === "QUICK_REPLY" &&
            res.quick_reply_action === "Opt_out"
          ) {
            setFieldValue(
              `${"resolved_buttons_variables"}.${index}.payload`,
              "OPT-OUT-678-688"
            );
          }
          if (
            res.sub_type === "QUICK_REPLY" &&
            res.quick_reply_action === "connect_flow" &&
            res.payload === "OPT-OUT-678-688"
          ) {
            setFieldValue(
              `${"resolved_buttons_variables"}.${index}.payload`,
              ""
            );
          }
        }
      );
    }
  }, [getIn(values, "resolved_buttons_variables")]); // eslint-disable-line react-hooks/exhaustive-deps

  const headers = getHeaderComponentDetails(selectedTemplateData?.components);
  const body = getBodyComponentDetails(selectedTemplateData?.components);
  const buttons = getButtonComponentDetails(selectedTemplateData?.components);
  const carousel =
    selectedTemplateData?.original_response?.button_type === "carousel"
      ? generateCarouselResolveVariables(selectedTemplateData?.components)
      : [];
  useEffect(() => {
    if(!applyValue){
      const header_type: string = selectedTemplateData?.original_response?.header_type;
      const button_type: string = selectedTemplateData?.original_response?.button_type;
      const a =
        header_type === "TEXT"
          ? generateResolveVariables(
            headers?.text,
            headers?.header_text
          )
          : [];
      const c =
        header_type !== "TEXT"
          ? selectedTemplateData?.header_value
          : null;
      const b =
        generateResolveVariables(
          body?.text,
          body?.body_text
        ) || [];
      const d =
        ["MEDIA", "IMAGE", "VIDEO", "DOCUMENT"].includes(header_type)
          ? [
            {
              key: "{{1}}",
              value: "",
              index: 0,
              type: header_type,
            },
          ]
          : a;

      const buttonsObj = ["copy_code_url_quick_replies_etc", "limited_time_offer", "otp"].includes(button_type) ? generateButtonsResolveVariables(buttons) : null
      let newValues = {
        ...values,
        resolved_header_variables: d,
        header_media: c,
        header_type: header_type,
        header_media_type: selectedTemplateData?.header_type,
        header_value: selectedTemplateData?.original_response?.header_value,
        body_preview: body?.text,
        resolved_body_variables: b,
        resolved_buttons_variables: buttonsObj,
        resolved_carousel_variables: carousel,
        footer_value: selectedTemplateData?.footer_value
      };
      if (button_type !== "carousel") delete newValues["carousel"]
      setValues(newValues);
    }
  }, [selectedTemplateData]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!selectedTemplateData && !applyValue) return null;
  if (
    getIn(values, "resolved_header_variables")?.length <= 0 &&
    getIn(values, "resolved_body_variables")?.length <= 0 &&
    getIn(values, "resolved_buttons_variables")?.length <= 0 &&
    getIn(values, "resolved_carousel_variables")?.length <= 0
  ) {
    return <p>No dynamic varibles found. kindly proceed further</p>;
  }
  return (
    <StyledVariblesBox className="styledVariblesBox">
      {values?.header_type === "TEXT" && (
        <AppFormTextArea
          name={"header_value"}
          label="Header Preview"
          placeholder="header preview"
          rows={2}
          disabled={true}
        />
      )}
      <AppFormTextArea
        name={"body_preview"}
        label="Body Preview"
        placeholder="body preview"
        rows={3}
        disabled={true}
      />
      {values?.template?.original_response?.button_type ===
        "limited_time_offer" && (
        <React.Fragment>
          <AppFormDatePicker
            name={"limitedTimeOfferDate"}
            label="Select Date "
            minDate={new Date()}
          />
          <AppFormTimePickerMui
            name={"limitedTimeOfferTime"}
            label="Select Time "
            utcTime={true}
            disablePast={true}
          />
        </React.Fragment>
      )}
      {getIn(values, "resolved_header_variables")?.length > 0 ||
        getIn(values, "resolved_body_variables")?.length > 0 ||
        getIn(values, "resolved_carousel_variables")?.length > 0 ||
        getIn(values, "resolved_buttons_variables")?.length > 0
        ? (
          <p className="variablesTitle">{"Resolve custom variables"}</p>
        ) : null}
      {
        !removeAttachmentUpload && (
          <>
            {getIn(values, "resolved_header_variables")?.length > 0 && (
              <RenderSingleTypeVariables
              name={"resolved_header_variables"}
              variableType={
                "Header"
              }
              mediaType={selectedTemplateData?.original_response?.header_type}
              isMedia={["MEDIA", "IMAGE", "VIDEO", "DOCUMENT"].includes(values?.header_type) ? true : false}
              fieldsData={filterFieldData}
              flowList={flowList}
              />
            )}
          </>
        )
      }
      {getIn(values, "resolved_body_variables")?.length > 0 && (
        <RenderSingleTypeVariables
          name={"resolved_body_variables"}
          variableType="Body"
          fieldsData={filterFieldData}
          flowList={flowList}
        />
      )}
      {getIn(values, "resolved_buttons_variables")?.length > 0 && (
        <RenderSingleTypeVariables
          name={"resolved_buttons_variables"}
          variableType="Buttons"
          fieldsData={filterFieldData}
          flowList={flowList}
        />
      )}
      {getIn(values, "resolved_carousel_variables")?.length > 0 && (
        <RenderCarouselTypeVariables
          name={"resolved_carousel_variables"}
          variableType="Carousel"
          fieldsData={filterFieldData}
        />
      )}
    </StyledVariblesBox>
  );
};

export default WhatsappBroadcastResolveVaribles;

interface RenderSingalProps {
  variableType: string;
  name: string;
  isMedia?: boolean;
  fieldsData: any;
  mediaType?: string;
  flowList: any;
}
const RenderSingleTypeVariables: React.FC<RenderSingalProps> = ({
  variableType,
  name,
  isMedia = false,
  fieldsData = [],
  mediaType = "",
  flowList = [],
}) => {
  const { values } = useFormikContext<any>();
  
  const [activeBotId, setActiveBotId] = useState<string | null>(null)
  
  const { userConfig } = useAppSelector(useAuthStore);
  const [childNode, setChildNode] = useState([]);
  if (getIn(values, name)?.length === 0) {
    return null;
  }
  let data = [
    {
      label: "Connect Flow",
      name: "connect_flow",
    },
    {
      label: "Opt out",
      name: "Opt_out",
    },
  ];
  const getChildNodeList = (data: any) => {
    ChatbotConsoleService.getAllBots().then((res: any) => {
      if (res.data?.results?.length > 0) {
        let activeBot = res.data.results.filter((el: any) => el.channel === "whatsapp" && el.bot_enabled === true);
        if(activeBot.length){
          setActiveBotId(activeBot[0].bot_id)
        }
      }
    })
    const HEADERS = {
      appid: JSON.stringify({
        botid: activeBotId,
      }),
    };
    ConversationService.getChildFlowList(data, HEADERS)
      .then((res: AxiosResponse<any>) => {
        if (Array.isArray(res?.data?.data)) {
          setChildNode(res?.data?.data);
        } else {
          // setChildNode([]);
        }
      })
      .catch((error: any) => {});
  };
  return (
    <React.Fragment>
      {getIn(values, name)?.length > 0 && (
        <p className="variablesType">
          {makeStringFirstLetterUppercase(variableType)}:
        </p>
      )}

      <FieldArray name={name}>
        {() => (
          <React.Fragment>
            {getIn(values, name)?.length > 0 &&
              getIn(values, name)?.map((item: any, index: number) => {
                const textfieldName: string = `${name}.${index}.value`;
                const selectfieldName: string = `${name}.${index}.index`;
                const quickReplyAction: string = `${name}.${index}.quick_reply_action`;
                const payload: string = `${name}.${index}.payload`;
                const parentPayload: string = `${name}.${index}.parentPayload`;
                if (item?.sub_type === "URL" && !item.hasOwnProperty("key"))
                  return null;
                return (
                  <React.Fragment>
                    {variableType === "Buttons" && (
                      <p>
                        {"Type:    " + item?.sub_type?.replaceAll("_", " ")}
                      </p>
                    )}
                    {item?.sub_type?.replaceAll("_", " ") === "QUICK REPLY" && (
                      <div style={{ marginLeft: "40px" }}>
                        <p
                          className="variableIndex"
                          style={{ fontWeight: "500", fontSize: "15px" }}
                        >
                          Select Action :-{" "}
                        </p>
                        <AppFormSelectFastField
                          Options={data}
                          name={quickReplyAction}
                          valueKey="name"
                        />
                        {item?.quick_reply_action === "connect_flow" ? (
                          <>
                            {" "}
                            <AppFormSelectFastField
                              Options={flowList}
                              name={parentPayload}
                              valueKey="qid"
                              onChangeCallbackFn={getChildNodeList}
                            />{" "}
                            {childNode?.length !== 0 ? (
                              <AppFormSelectFastField
                                Options={childNode}
                                name={payload}
                                valueKey="nodeId"
                              />
                            ) : null}{" "}
                          </>
                        ) : null}
                      </div>
                    )}
                    <div className="variablesContentt" key={index.toString()}>
                      <p className="variableIndex">{`{{${index + 1}}}`}</p>
                      {isMedia ? (
                        <>
                          <WhatsappMediaPicker
                            name={textfieldName}
                            isResolveVariable={true}
                            supportedFormat={getCorrectMediaFormats(mediaType)}
                            mediaType={mediaType}
                            divStyle={{ marginTop: "0.3rem" }}
                            tooltipTitle={
                              "The supported format is mp4. The video size must be smaller than 16MB."
                            }
                          />
                          {fieldsData?.length > 0 && (
                            <AppFormSelectFastField
                              Options={fieldsData}
                              name={textfieldName}
                              valueKey="name"
                              disabled={item?.disable}
                              disabledOptionsList={[
                                "COPY_CODE",
                                "QUICK_REPLY",
                                "PHONE_NUMBER",
                                "value_text"
                              ]}
                            />
                          )}
                        </>
                      ) : (
                        <React.Fragment>
                          {fieldsData?.length > 0 && (
                            <AppFormSelectFastField
                              Options={fieldsData}
                              name={selectfieldName}
                              valueKey="name"
                              disabled={item?.disable}
                              disabledOptionsList={[
                                "COPY_CODE",
                                "QUICK_REPLY",
                                "PHONE_NUMBER"
                              ]}
                            />
                          )}
                          <AppFormFastField
                            name={textfieldName}
                            placeholder={
                              getIn(values, selectfieldName) === "value_text"
                                ? "Static value"
                                : "Fallback Value"
                            }
                            disabled={
                              item?.sub_type === "QUICK_REPLY" ? true : false
                            }
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        )}
      </FieldArray>
    </React.Fragment>
  );
};

// Carouasel Component
interface RenderCarouselProps {
  variableType: string;
  name: string;
  fieldsData: any;
  mediaType?: string;
}
const RenderCarouselTypeVariables: React.FC<RenderCarouselProps> = ({
  variableType,
  name,
  fieldsData = [],
  mediaType = "",
}) => {
  const { values } = useFormikContext<any>();
  if (getIn(values, name)?.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <p className="variablesType">
        {makeStringFirstLetterUppercase(variableType)}:
      </p>

      <FieldArray name={name}>
        {() => (
          <React.Fragment>
            {getIn(values, name)?.length > 0 &&
              getIn(values, name)?.map((item: any, index: number) => {
                const headerName: string = `${name}.${index}.header.value`;
                return (
                  <div
                    className="carouselVariablesContent"
                    key={index.toString()}
                  >
                    {/* Header */}
                    <p>{`Card:  ${index + 1}`}</p>
                    <div className="headerContainer">
                      <p className="carousel_title">
                        Header ({item?.header?.type})
                      </p>
                      <div className="header_field_container">
                        <p className="variableIndex">
                          {item?.header?.key as string}
                        </p>
                        <WhatsappMediaPicker
                          name={headerName}
                          isResolveVariable={true}
                          supportedFormat={getCorrectMediaFormats(
                            item?.header?.type?.toUpperCase()
                          )}
                          mediaType={mediaType}
                          divStyle={{ marginTop: "0.3rem" }}
                          tooltipTitle={
                            "The supported format is mp4. The video size must be smaller than 16MB."
                          }
                        />
                        {fieldsData?.length > 0 && (
                          <AppFormSelectFastField
                            Options={fieldsData}
                            name={headerName}
                            valueKey="name"
                            disabled={item?.disable}
                            disabledOptionsList={[
                              "COPY_CODE",
                              "QUICK_REPLY",
                              "PHONE_NUMBER",
                              "value_text"
                            ]}
                          />
                        )}
                      </div>
                    </div>

                    {/* Body */}
                    {item?.body?.length > 0 && (
                      <p className="carousel_title">Body</p>
                    )}
                    <React.Fragment>
                      {item?.body?.length > 0 &&
                        item?.body?.map((bodyEle: any, bodyIndex: number) => {
                          const selectfieldName: string = `${name}.${index}.body.${bodyIndex}.field`;
                          const selectfieldValue: string = `${name}.${index}.body.${bodyIndex}.field_value`;
                          return (
                            <div className="carouselFields">
                              <p className="variableIndex">
                                {bodyEle?.key as string}
                              </p>
                              {fieldsData?.length > 0 && (
                                <AppFormSelectFastField
                                  Options={fieldsData}
                                  name={selectfieldName}
                                  valueKey="name"
                                />
                              )}
                              <AppFormFastField
                                name={selectfieldValue}
                                placeholder={
                                  getIn(values, selectfieldName) ===
                                  "value_text"
                                    ? "Static value"
                                    : "Fallback Value"
                                }
                              />
                            </div>
                          );
                        })}
                    </React.Fragment>

                    {/* Buttons  */}
                    {item?.buttons?.length > 0 && (
                      <p className="carousel_title">Buttons</p>
                    )}
                    <React.Fragment>
                      {item?.buttons?.length > 0 &&
                        item?.buttons?.map(
                          (buttonEle: any, buttonIndex: number) => {
                            const selectfieldName: string = `${name}.${index}.buttons.${buttonIndex}.field`;
                            const selectfieldValue: string = `${name}.${index}.buttons.${buttonIndex}.field_value`;
                            return (
                              <div className="carouselFields">
                                <p className="variableIndex">
                                  {buttonEle?.key as string}
                                </p>
                                {fieldsData?.length > 0 && (
                                  <AppFormSelectFastField
                                    Options={fieldsData}
                                    name={selectfieldName}
                                    valueKey="name"
                                  />
                                )}
                                <AppFormFastField
                                  name={selectfieldValue}
                                  placeholder={
                                    getIn(values, selectfieldName) ===
                                    "value_text"
                                      ? "Static value"
                                      : "Fallback Value"
                                  }
                                />
                              </div>
                            );
                          }
                        )}
                    </React.Fragment>
                  </div>
                );
              })}
          </React.Fragment>
        )}
      </FieldArray>
    </React.Fragment>
  );
};

const StyledVariblesBox = styled.div`
  && {
    .variablesTitle {
      font-size: 16px;
      font-weight: 500;
      margin: 0.5rem 0;
    }

    .variablesType {
      font-size: 15px;
      font-weight: 500;
    }
    .variablesContentt {
      display: flex;
      align-items: flex-start;
      gap: 1rem;

      .variableIndex {
        padding-top: 0.8rem;
        display: flex;
        height: 100%;
      }
    }
    .carouselVariablesContent {
      gap: 1rem;
      border-bottom: 1px solid ${theme.palette.default.border};
      &:last-child {
        border-bottom: none; /* Hide border-bottom for the last element */
        margin-bottom: 0; /* Adjust margin if necessary */
      }
      margin-bottom: 1rem;
      .variableIndex {
        padding-top: 0.8rem;
        display: flex;
        height: 100%;
      }
      .carouselFields {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
      }
      .header_field_container {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
      }
    }
    .StyledUploadedImageWrap {
      margin: 0px;
    }
    .MediaUploadBox {
      margin: 0px;
    }
    .headerContainer {
      display: block;
      width: 100%;
    }
    .carousel_title {
      font-size: 15px;
      font-weight: 500;
      margin: 0.5rem 0;
    }
  }
`;
