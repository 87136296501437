import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import getPublicAssets from "../Services/s3bucket";
import { useAppDispatch, useAppSelector } from "../Store";
import { useAuthStore } from "../Store/Slices/AuthSlice";
import {
  removeAllMessages,
  updateUserInfoData,
} from "../Store/Slices/socket/AllMessagesSlice";
import {
  appendNewSessionData,
  removeSessionData,
} from "../Store/Slices/socket/liveChat/chat.slice";
import { useSocket } from "../socket/hooks/socket-context";
import { SOCKET_CONNECTION_TYPES } from "../socket/utils/Enum";
import useJsNotifications from "./useJsNotifications";
import { useNotification } from "./useNotification/useNotification";
import { playSoundLastBotMsg } from "../socket/utils/utils";
import LOGO from "../assets/images/logo.svg";

const Whatsapp = getPublicAssets("images/whatsapp.svg");
const Website = getPublicAssets("images/web-live.svg");
const Telegram = getPublicAssets("images/telegram.svg");
const Facebook = getPublicAssets("images/facebook.svg");

export const getChannelIcon = (type: string) => {
  if (type === "whatsapp") {
    return <img loading="lazy" src={Whatsapp} alt="whatsapp" />;
  }
  if (type === "website" || type === "Website") {
    return <img loading="lazy" src={Website} alt="Website" />;
  }

  if (type === "messenger") {
    return <img loading="lazy" src={Facebook} alt="Facebook" />;
  }

  if (type === "telegram") {
    return <img loading="lazy" src={Telegram} alt="telegram" />;
  }

  return <img loading="lazy" src={Website} alt="website" />;
};

const useLiveChatNewSessions = () => {
  const { showInteractiveAlert } = useNotification();
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showJsNotification } = useJsNotifications();
  const { socket } = useSocket();
  const { data: authData } = useAppSelector(useAuthStore);

  const location = useLocation();
  const locationRef = useRef<any>();

  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  useEffect(() => {
    if (!socket) return;

    const handleUserSessions = (response: any) => {
      if (response?.error) return;
      if (!response?.data) return;
      if (!response?.data?.session_id) return;

      const isChatScreen: boolean =
        locationRef.current?.pathname?.includes("/conversations/chats") ??
        false;

      const isMySession: boolean =
        response?.data?.agent === authData?.data?.user?.id;

      if (isChatScreen && !isMySession) {
        dispatch(removeSessionData(response?.data));
        dispatch(removeAllMessages({}));
        return;
      }

      if (isChatScreen) {
        dispatch(updateUserInfoData(response?.data));
        dispatch(appendNewSessionData(response?.data));
      }

      const title = response?.data?.user ?? response?.data?.name;
      const body = response?.data?.last_message ?? "";
      const icon = getChannelIcon(response?.data?.channel ?? "whatsapp");
      const url = `/conversations/chats`;

      if(!body || !title) return;

      playSoundLastBotMsg();

      showJsNotification(
        title,
        {
          body,
          icon: LOGO,
          data: {
            url,
          },
        },
        () => undefined
      );

      showInteractiveAlert(
        {
          title,
          body,
          url,
          icon,
        },
        "success"
      );

      return;
    };
    //NEW SESSIONS
    socket.on(SOCKET_CONNECTION_TYPES.GET_NEW_SESSIONS, handleUserSessions);
    //CLEAN UP
    return () => {
      socket.off(SOCKET_CONNECTION_TYPES.GET_NEW_SESSIONS);
    };
  }, [socket, authData]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useLiveChatNewSessions;
