// vendors
import { Fragment, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip"

// components
import Icon from "../../Components/Icon";
import MediaUploadPlaceholder from "../../Components/MediaPlaceholder";
import Input from "../../Components/Input";
import MessagePreview from "../../Components/MessagePreview";
import Editor from "../../Components/Editor";
import UIButton from "../../Components/Button";
import ButtonList from "../../Nodes/Buttons/ButtonsList";
import UIModal from "../../Components/Modal";

// styles
import { DeleteButtonStyled, LabelStyled, ImgStyled, RemoveImageButtonStyled } from "./Carousel.styled";

// utils
import { getPlainTextData } from "../../Utils";

const Slide = (props: CarouselSlideProps) => {
  const {
    index,
    activeNode,
    slide,
    error,
    onDelete,
    onFileChange,
    onAddImgUrlButtonClick,
    onTitleChange,
    onSubTitleChange,
    editorState,
    onEditorChange,
    onEditorBlur,
    onButtonLabelChange,
    onButtonSave,
    onButtonPreview,
    onButtonDelete,
    onDeleteSlide,
    onButtonOrderChange,
  } = props;
  const uploadInputRef = useRef(null);
  const [openPopover, updateOpenPopover] = useState<boolean>(false);
  const isButtonDisabled =
    slide.image === "" &&
    slide.title === "" &&
    slide.subTitle === "" &&
    slide.text === "" &&
    slide.buttons.length === 0;

  const handleDeleteSlide = () => {
    toggleModal();
  };

  const handleSave = () => {
    onDeleteSlide(index);
    toggleModal();
  };

  const toggleModal = () => {
    updateOpenPopover(!openPopover);
  };

  return (
    <Box key={`carousel-slide-${index}`} sx={{ pb: "40px" }}>
      {/* carousel image */}
      <Box sx={{ position: "relative" }}>
        {!activeNode || slide.image ? (
          <>
            <ImgStyled src={slide.image} alt="carousel-image" />
            {activeNode && (
              <Fragment>
                <Tooltip
                  title={"you can remove image by clicking on this button"}
                >
                  <RemoveImageButtonStyled onClick={() => onDelete(index)}>
                    <Icon icon="replace" color="default.darkGrey" size={22} />
                  </RemoveImageButtonStyled>
                </Tooltip>
                <Tooltip
                  title={"you can delete slide by clicking on this button"}
                >
                  <DeleteButtonStyled
                    onClick={handleDeleteSlide}
                    disabled={isButtonDisabled}
                  >
                    <Icon icon="delete" color="default.darkGrey" size={18} />
                  </DeleteButtonStyled>
                </Tooltip>
              </Fragment>
            )}
          </>
        ) : (
          <>
            <MediaUploadPlaceholder error={error}>
              <Icon icon="add-image" color="default.blue" size={22} style={{ marginBottom: "16px" }} />
              {/* May be in the future we will use this */}
              {/* <Typography
                sx={{
                  color: "#7E8392",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "21px",
                  my: "8px",
                }}
              >
                Drop JPG, PNG File to upload or{" "}
                <input
                  ref={uploadInputRef}
                  type="file"
                  accept="image/*"
                  id="raised-button-file"
                  style={{ display: "none" }}
                  onChange={(event) => onFileChange(event, index)}
                />
                <label htmlFor="raised-button-file">
                  <UIButton
                    disableRipple
                    sx={{
                      p: "0 !important",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={() =>
                      uploadInputRef.current &&
                      // @ts-ignore
                      uploadInputRef?.current?.click()
                    }
                    variant="text"
                  >
                    Browse
                  </UIButton>
                </label>
              </Typography> */}
               {/* May be in the future we will use this */}
              <UIButton
                variant="outlined"
                startIcon={<Icon icon="link" size={14} />}
                onClick={onAddImgUrlButtonClick}
              >
                Image URL
              </UIButton>
            </MediaUploadPlaceholder>
            <DeleteButtonStyled
              onClick={handleDeleteSlide}
              disabled={isButtonDisabled}
            >
              <Icon icon="delete" color="default.darkGrey" size={18} />
            </DeleteButtonStyled>
            `
          </>
        )}
      </Box>
      {/* text block */}
      <Box>
        {!activeNode ? (
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "21px",
              py: "4px",
              color: "#101010",
            }}
          >
            {slide.title}
          </Typography>
        ) : (
          <>
            <LabelStyled>Title</LabelStyled>
            <Input
              placeholder="Title"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onTitleChange(event, index)
              }
              value={slide.title}
            />
          </>
        )}
        {!activeNode ? (
          <Typography
            variant="subtitle1"
            sx={{
              py: "4px",
              color: "#101010",
            }}
          >
            {slide.subTitle}
          </Typography>
        ) : (
          <>
            <LabelStyled>Subtitle</LabelStyled>
            <Input
              placeholder="Subtitle"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSubTitleChange(event, index)
              }
              value={slide.subTitle}
            />
          </>
        )}
        {!activeNode ? (
          <MessagePreview text={slide.text} />
        ) : (
          <>
            <LabelStyled>Description</LabelStyled>
            <Editor
              error={error && getPlainTextData(editorState[index]) === ""}
              hideFocusOnLoad
              editorState={editorState[index]}
              onEditorStateChange={onEditorChange}
              media={false}
              onBlur={onEditorBlur}
            />
          </>
        )}
      </Box>
      {/* button block */}
      <Box sx={{ mx: "-16px" }}>
        <ButtonList
          isActiveNode={activeNode}
          buttonsList={!slide.buttons ? [] : slide.buttons}
          onLabelChange={(label, activeBtnIdx) =>
            onButtonLabelChange(label, activeBtnIdx, index)
          }
          onButtonSave={(buttonAction) => onButtonSave(buttonAction, index)}
          onPreviewButtonClick={onButtonPreview}
          onButtonDelete={(activeButton) => onButtonDelete(activeButton, index)}
          onOrderChange={(buttonList) => onButtonOrderChange(buttonList, index)}
        />
      </Box>
      <UIModal
        open={openPopover}
        type={"default"}
        size={"xs"}
        onClose={toggleModal}
        primaryButtonConfig={{
          label: "Yes!",
          onClick: handleSave,
        }}
        secondaryButtonConfig={{
          label: "No",
          onClick: toggleModal,
        }}
        header={{ title: "Confirm" }}
      >
        <Box sx={{ py: "8px !important" }}>
          <Typography
            component="label"
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: "#7E8392",
              pb: "4px",
            }}
          >
            Are you sure you want to delete slide?
          </Typography>
        </Box>
      </UIModal>
    </Box>
  );
};
export default Slide;
