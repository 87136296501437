const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    className: "ignore-onClickOutside",
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.1) !important",
      borderRadius: "8px",
      marginTop: "10px",
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu" as "menu",
  sx: {
    "& .MuiList-root": {
      display: "grid",
      gridTemplateColumns: "auto auto auto",
    },
  },
};

const SingleMenuProps = {
  PaperProps: {
    className: "ignore-onClickOutside",
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.1) !important",
      borderRadius: "8px",
      marginTop: "10px",
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top" as "top",
    horizontal: "center",
  },
  variant: "menu" as "menu",
};

const options = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const dateConfig = ["Last", "Next", "Current Date", "Pick a Date"];

const dateTypeOptions = [
  {
    value: "LAST",
    label: "Last",
  },
  {
    value: "NEXT",
    label: "Next",
  },
  {
    value: "CURRENT",
    label: "Current Date",
  },
  {
    value: "CUSTOM",
    label: "Pick a Date",
  },
];

const dateUnitOptions = [
  {
    value: "days",
    label: "Day(s)",
  },
  {
    value: "months",
    label: "Month(s)",
  },
  {
    value: "years",
    label: "Year(s)",
  },
];

{/*const supportedDateFormats = [
  {
    label: "dd/mm/yyyy",
    value: "dd/mm/yyyy",
  },
  {
    label: "mm/dd/yy",
    value: "mm/dd/yy",
  },
  {
    label: "dd/mm/yyyy",
    value: "dd/mm/yyyy",
  },
  {
    label: "dd/mm/yy",
    value: "dd/mm/yy",
  },
  {
    label: "dd-mm-yyyy",
    value: "dd-mm-yyyy",
  },
  {
    label: "dd-mm-yy",
    value: "dd-mm-yy",
  },
  {
    label: "mm-dd-yyyy",
    value: "mm-dd-yyyy",
  },
  {
    label: "mm-dd-yy",
    value: "mm-dd-yy",
  },
  {
    label: "yyyy-mm-dd",
    value: "yyyy-mm-dd",
  },
  {
    label: "m/d/yyyy",
    value: "m/d/yyyy",
  },
  {
    label: "m/d/yy",
    value: "m/d/yy",
  },
  {
    label: "d/m/yyyy",
    value: "d/m/yyyy",
  },
  {
    label: "d/m/yy",
    value: "d/m/yy",
  },
  {
    label: "m-d-yyyy",
    value: "m-d-yyyy",
  },
  {
    label: "m-d-yy",
    value: "m-d-yy",
  },
  {
    label: "d-m-yyyy",
    value: "d-m-yyyy",
  },
  {
    label: "d-m-yy",
    value: "d-m-yy",
  },
  {
    label: "Mth d, yyyy",
    value: "Mth d, yyyy",
  },
  {
    label: "Month d, yyyy",
    value: "Month d, yyyy",
  },
  {
    label: "Mth d, yyyy",
    value: "Mth d, yyyy",
  },
  {
    label: "Month d, yyyy",
    value: "Month d, yyyy",
  },
];*/}
const intervalOptions = ["Day(s)", "Month(s)", "Year(s)"];

const findMatch = (original: string[], updated: string[]) => {
  let res = [];
  for (let i = 0; i < original.length; i++) {
    for (let j = 0; j < updated.length; j++) {
      if (original[i] === updated[j]) {
        res.push(i);
      }
    }
  }
  return res;
};

const fromInit = {
  type: "LAST",
  date: new Date(),
  number: 1,
  unit: "days",
};

const toInit = {
  type: "NEXT",
  date: new Date(),
  number: 1,
  unit: "days",
};

export {
  MenuProps,
  options,
  findMatch,
  SingleMenuProps,
  dateConfig,
  intervalOptions,
  //supportedDateFormats,
  dateTypeOptions,
  dateUnitOptions,
  fromInit,
  toInit,
};
