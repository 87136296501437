import TopMenuComponent from "../common/TopBar";
import { ApiLibraryWrap } from "../style";
import ApiCreateForm from "../common/ApiCreate";

interface Props {}

const CreateApi: React.FC<Props> = () => {
  return (
    <ApiLibraryWrap>
      {/* TOPBAR */}
      <TopMenuComponent hideFilters={true} />

      <ApiCreateForm />
    </ApiLibraryWrap>
  );
};

export default CreateApi;
