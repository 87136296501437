import { getIn, useFormikContext } from "formik";
import React, { Fragment } from "react";
import Select, { createFilter } from "react-select";
import styled from "styled-components";

import { Tooltip } from "@mui/material";
import AppErrorMessage from "../Forms/AppErrorMessage";
import { theme } from "../../Customization/Theme";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
interface Props {
  options: any[];
  label?: string;
  displayKey?: string;
  valueKey?: string;
  placeholder?: string;
  defaultIndexValue?: number;
  name: string;
  divStyle?: any;
  tooltip?: any;
  onChangeCallbackFn?: (value: any) => void;
  isSearchable?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  defaultInputValue?: string;
  defaultValue?: any;
  isOptionDisabled?: ((option: any, selectValue: any) => boolean) | undefined;
  hideSelectedOptions?: boolean;
  //   components: any;
  [otherProps: string]: any;
  children?: React.ReactNode;
  field?: any;
  requiredSign?: boolean;
  maxWidth?: string;
  onMenuOpen?: any;
  className?: string;
  resetDependentFieldName?: string[];
  labelStyle?: any;
}

const AppReactSelectField: React.FC<Props> = ({
  options,
  label,
  name,
  placeholder = "Select an option",
  divStyle,
  displayKey = "label",
  valueKey = "value",
  tooltip,
  onChangeCallbackFn,
  isSearchable = true,
  isClearable = false,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
  requiredSign,
  defaultInputValue,
  defaultValue,
  field,
  //   components,
  isOptionDisabled = (option: any) => option?.disabled,
  hideSelectedOptions = true,
  maxWidth,
  onMenuOpen,
  className,
  resetDependentFieldName = [],
  labelStyle,
  ...otherProps
}) => {
  const { errors, touched, setFieldValue, values, setFieldError } =
    useFormikContext<any>();
  const onInputChange = (e: any) => {
    // let event = {
    //   target: { name: name, value: e },
    // };
    // handleChange(event);
    if (e) {
      setFieldValue(name, e);
      onChangeCallbackFn && onChangeCallbackFn(e);
      if (resetDependentFieldName?.length > 0) {
        resetDependentFieldName?.forEach((field: string) => {
          setFieldValue(field, "");
        });
      }
    } else {
      setFieldValue(name, null);
      onChangeCallbackFn && onChangeCallbackFn(null);
      if (resetDependentFieldName?.length > 0) {
        resetDependentFieldName?.forEach((field: string) => {
          setFieldValue(field, "");
        });
      }
    }
  };

  // for search
  const filterConfig: any = (option: any, searchText: string) => {
    if (
      option[displayKey].toLowerCase().includes(searchText.toLowerCase()) ||
      option[displayKey].toLowerCase().includes(searchText.toLowerCase())
    ) {
      return option[displayKey];
    } else {
      return "";
    }
  };

  return (
    <Div style={{ maxWidth: maxWidth }} className={className}>
      <Label style={labelStyle}>
        {requiredSign ? (
          <Fragment>
            {label}
            <sup
              className="starSuperScript"
              style={{ color: `${theme.palette.default.error}` }}
            >
              {" "}
              *
            </sup>
          </Fragment>
        ) : (
          label
        )}
        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <HelpOutlineIcon />
          </Tooltip>
        )}
      </Label>
      <Select
        id={name}
        name={name}
        options={options}
        value={getIn(values, name) ?? null}
        onChange={(e) => onInputChange(e)}
        defaultInputValue={defaultInputValue}
        defaultValue={defaultValue || null}
        onBlur={(e: any) => {
          // setFieldTouched(name, true);
          if (!getIn(values, name)) setFieldError(name, undefined);
        }}
        placeholder={placeholder}
        getOptionLabel={(option: any) =>
          option?.[displayKey] || option?.label || option?.name || option?.value
        }
        isOptionSelected={(option: any) =>
          option?.[valueKey] === getIn(values, name)?.[valueKey]
        }
        onMenuOpen={() => (onMenuOpen ? onMenuOpen() : {})}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isDisabled={isDisabled}
        blurInputOnSelect={true}
        isLoading={isLoading}
        isOptionDisabled={isOptionDisabled}
        hideSelectedOptions={hideSelectedOptions}
        filterOption={createFilter(filterConfig)}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
        {...otherProps}
      />
      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />
    </Div>
  );
};

export default AppReactSelectField;

const Div = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 750px;
    gap: 6px;
    position: relative;
    margin-bottom: 0.5rem;
    [class$="-control"] {
      // margin-bottom: 0.5rem;
    }
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
    .starSuperScript {
      top: 0;
      font-size: 16px;
    }
  }
`;

export const StyledReactSelectInput = styled(Select)`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 0 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-right: 2.4rem;
    position: relative;
    .clearBtn {
      background-color: #f5f6f8;
    }
    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }

    &:disabled {
      color: ${theme.palette.default.lightGrey};
      border: 2px solid ${theme.palette.default.lightGrey};
    }
  }
`;
