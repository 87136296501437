import React, { useRef, useState } from "react";
import styled from "styled-components";

const AudioPlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: "100%";
  .audio-player {
    width: 100%;
  }
`;

type AudioPlayerProps = {
  src: string;
};

const AppAudioComponent: React.FC<AudioPlayerProps> = ({ src }) => {
  return (
    <AudioPlayerContainer>
      <audio controls className="audio-player">
        <source src={src} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </AudioPlayerContainer>
  );
};

export default AppAudioComponent;
