import styled from "styled-components";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import { Slider, Stack } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";

interface AppInputFieldProps {
  label?: string;
  name: string;
  children?: React.ReactNode;
  showValue?: boolean;
  additonalValueText?: string;
  showVolumnIcon?: boolean;
  minValue?:number;
  maxValue?: number;
  onChangeCallbackFn?:any;
  [otherProps: string]: any;
}

interface StyledProps {
  isError?: any;
}

const AppFormSliderField: React.FC<AppInputFieldProps> = ({
  label,
  name,
  children,
  showValue = false,
  additonalValueText = "",
  showVolumnIcon = false,
  minValue =0,
  maxValue =100,
  onChangeCallbackFn,
  ...otherProps
}) => {
  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    getFieldMeta,
    initialValues,
    setFieldValue,
    values,
  } = useFormikContext<any>();

  // const [value, setValue] = React.useState<number>(30);

  const handleValueChange = (event: Event, newValue: number | number[]) => {
    setFieldValue(name, newValue);
    if(onChangeCallbackFn){
      onChangeCallbackFn(newValue)
    }
    // setValue(newValue as number);
  };

  return (
    <Div>
      <Label>{label}</Label>
      <Stack
        spacing={2}
        direction="row"
        sx={{ mb: 1, flexWrap: "nowrap" }}
        alignItems="center"
      >
        <VolumeDown
          style={{
            color: theme.palette.default.darkGrey,
          }}
        />
        <Slider
          aria-label="Volume"
          value={getIn(values,name)}
          onChange={handleValueChange}
          valueLabelDisplay="auto"
          sx={{ flexGrow: 1 }}
          min={minValue}
          max={maxValue}
        />
        {showVolumnIcon && (
          <VolumeUp
            style={{
              color: theme.palette.default.darkGrey,
            }}
          />
        )}
        {showValue && (
          <span
            style={{
              whiteSpace: "nowrap", // Prevents wrapping
              display: "flex",
              alignItems: "center",
            }}
          >{`(${getIn(values,name) || 0}${
            additonalValueText ? ` ${additonalValueText}` : ""
          })`}</span>
        )}
      </Stack>
      <AppErrorMessage error={errors[name]} visible={touched[name]} />
    </Div>
  );
};

export default AppFormSliderField;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 1rem;
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
  }
`;
const Input = styled.input<StyledProps>`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 2rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
    /* outline: 1px solid ${theme.palette.default.border} !important; */
  }
`;
