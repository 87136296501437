import styled from "styled-components";
import CallDetailItem from "../../../components/CallDetailItem";
import { useAppSelector } from "../../../../../../../Store";
import { getCallLogsRowData } from "../../../../../../../Store/Slices/Voicebot/voicebot.selectors";
import { formatDuration } from "../../../utils";
import moment from "moment";
import { capitalizeFirstLetter } from "../../../../../../../Utils";

const BasicInfo = () => {
  const callLogsRowData: any = useAppSelector(getCallLogsRowData);

  return (
    <Container>
      <div>
        <CallDetailItem
          label="Call Duration"
          value={formatDuration(callLogsRowData?.callDuration)}
        />
        <CallDetailItem
          label="Start Time"
          value={moment(callLogsRowData?.callStartTime).format("hh:mm:ss a")}
        />
        <CallDetailItem
          label="Customer Number"
          value={callLogsRowData?.toNumber}
        />
      </div>
      <div>
        <CallDetailItem
          label="Date"
          value={moment(callLogsRowData?.createdAt).format("D MMMM YYYY")}
        />
        <CallDetailItem
          label="End Time"
          value={moment(callLogsRowData?.callEndTime).format("hh:mm:ss a")}
        />
        <CallDetailItem
          label="Status"
          value={capitalizeFirstLetter(callLogsRowData?.callState)}
        />
      </div>
    </Container>
  );
};

export default BasicInfo;

const Container = styled.div`
  display: flex;
  gap: 50px;
  padding: 0 20px;
`;
