import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  MenuItem,
  Switch,
  Tabs,
  Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { device } from "./BreakPoints";
import { theme } from "./Theme";

import { keyframes } from "@emotion/react";
import { css } from "styled-components";

interface StyledProps {
  userMessage?: boolean;
}

export const LoginPageWrap = styled(Box)<{ props: any }>`
  && {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: ${(props: any) => (props.widthDisable ? "" : "100vw")};
    overflow: hidden;

    @media ${device.mobileL} {
    }
  }
`;
export const PlanPageWrap = styled(Box)`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    margin-top: 5rem;
    justify-content: center;
    @media ${device.mobileL} {
    }
  }
`;
export const LeftSide = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-direction: row;
    background-color: rgba(217, 217, 217, 0.2);
    background-color: white;
    padding: 1.2rem;
    flex: 0.5;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    div {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      background-color: rgba(203, 205, 211, 0.1);
      backdrop-filter: blur(220px);
    }
    img {
      width: 100%;
      height: 100%;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      -user-drag: none;
    }

    @media ${device.mobileL} {
      display: none;
    }
    @media ${device.tablet} {
      display: none;
    }
  }
`;
export const RightSide = styled.div`
  && {
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin-top: 9rem;
    background-color: ${theme.palette.default.background};
    flex: 0.5;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    @media ${device.tablet} {
      flex: 1;
    }
    @media ${device.laptopL} {
      margin-top: 4rem;
    }
  }
`;

export const DetailsBox = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 55%;
    gap: 4px;
    padding: 1rem;
    @media ${device.laptopL} {
      width: 65%;
    }
  }
`;

export const ErrorText = styled.small`
  && {
    display: flex;
    align-items: center;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    position: relative;
    color: ${theme.palette.default.error};
    margin-bottom: 0.6rem;
  }
`;

export const BrandBox = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0px;
    gap: 1rem;
  }

  img {
    width: 40px;
    height: 40px;
  }

  .dflexCenter {
    display: flex;
    align-items: center;
  }
  .tableHeader {
  }
`;
export const ExtraBox = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.7rem;
  }
`;

export const LogoText = styled(Typography)`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1rem;
    color: ${theme.palette.default.black};
  }
`;
export const TitleText = styled(Typography)`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 20px;
    color: ${theme.palette.default.text};
    margin-bottom: 0.5rem;
    align-items: center;
  }
`;
export const SubTitleText = styled(Typography)`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    color: ${theme.palette.default.darkGrey};
    margin-bottom: 4px;
  }
`;
export const LinkText = styled(Typography)`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${theme.palette.default.darkGrey};

    span {
      cursor: pointer;
      color: ${theme.palette.primary.dark};
    }
    a {
      color: ${theme.palette.primary.main};
      text-decoration: none;
    }

    button {
      cursor: pointer;
      color: ${theme.palette.primary.dark};
      font-family: ${theme.typography.fontFamily};
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      border: none;
      outline: none;
      background-color: transparent;

      &:disabled {
        color: ${theme.palette.default.grey};
      }
    }
  }
`;

export const ErrorTextMsg = styled.small`
  && {
    display: flex;
    align-items: center;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    color: ${theme.palette.default.error};
  }
`;

export const slide = keyframes`

  0%{
    bottom: 0%;
    opacity: 0.01;
  }
  100%{
    bottom: 10%;
    opacity: 1;
  }
`;

export const AppTitle = styled.p`
  && {
    cursor: default;
    font-family: ${theme.typography.fontFamily};
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 30px;

    color: ${theme.palette.default.black};
    display: flex;
    align-items: center;
    gap: 0.6rem;
  }
`;
export const AppPageTitle = styled.p`
  && {
    cursor: default;
    font-family: ${theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1rem;
    line-height: 30px;

    color: ${theme.palette.default.text};
  }
`;

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: theme.palette.default.black,
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "capitalize",
  },
  [`&.${tableCellClasses.body}`]: {
    color: `${theme.palette.default.darkGrey}`,
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "24px",
    borderBottom: `1px solid ${theme.palette.default.lightGrey}`,
    padding: "0rem !important",
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {},
  // hide last border
  "&:last-child td, &:last-child th": {},
  "&:hover": {
    backgroundColor: `${theme.palette.default.lightGrey}`,
  },
}));

export const StyledCard = styled(Card)`
  && {
    width: 18.75rem;
    height: 18.75rem;
    background-color: ${theme.palette.primary.main};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;

    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;

    cursor: pointer;

    p {
      font-family: ${theme.typography.fontFamily};
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      text-transform: capitalize;
      color: ${theme.palette.default.white};
    }

    @media ${device.laptop} {
      min-width: 17.75rem;
      min-height: 18.75rem;
    }
  }
`;

export const StyledTemplatesCard = styled(Card)`
  && {
    width: 18.75rem;
    height: 18.75rem;
    background-color: ${theme.palette.secondary.main};
    display: flex;
    flex-direction: column;

    padding: 0rem 0 0.5rem 0;
    gap: 0.8rem;

    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;

    cursor: pointer;
    .botInfoBox {
      width: 100%;
      display: flex;

      flex-direction: column;
      padding: 0 0.5rem;
      height: 4rem;
    }
    .botTitle {
      font-family: ${theme.typography.fontFamily};

      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      text-transform: capitalize;
      color: ${theme.palette.default.black};
    }

    .botLastUpdated {
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1rem;
      text-transform: capitalize;
      color: ${theme.palette.default.darkGrey};
    }

    .botSummary {
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 1rem;
      color: ${theme.palette.default.text};

      overflow: hidden;
    }

    .botActionBox {
      padding: 0 0.5rem;
      display: flex;
      justify-content: center;
      gap: 1rem;
      width: 100%;

      .ActionBtn {
        width: 100%;
      }
    }
    @media ${device.laptop} {
      min-width: 18.75rem;
      min-height: 18.75rem;
    }
  }
`;

export const MyBotCard = styled(Card)`
  && {
    // grid-column-end: span 4;
    display: flex;
    flex-direction: column;
    // background-color: #39393b;
    cursor: pointer;
    transition: all 0.3s ease 0s;

    border-radius: 8px;
    // min-width: 23.125rem;
    // width: 24.125rem;
    min-height: 22rem;
    height: 22rem;
    background-color: ${theme.palette.default.white};
    display: flex;
    flex-direction: column;
    border: 1px solid #eaeaea;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);

    align-items: center;
    gap: 0.8rem;
    justify-content: space-between;
    // padding: 0.5rem 1rem;
    // box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;

    .MuiPaper-root-MuiCard-root {
      // box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
    }

    .botExtraBtns {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      .bot-timestamp {
        font-size: 13px;
        color: ${theme.palette.default.darkGrey};
        .date {
          display: block;
        }
        .time {
          display: block;
          text-align: right;
        }
      }
    }

    .botTitleBox {
      width: 100%;
      display: flex;
      height: 5.188rem;
      min-height: 5.188rem;
      justify-content: space-between;
      align-items: center;
      padding-top: 0.2rem;
      padding-right: 1rem;
      border-bottom: 1px solid rgba(126, 131, 146, 0.2);
      position: relative;
      img {
        width: 118px;
        height: 130px;
        object-fit: contain;
        position: absolute;
        top: 16px;
      }
    }
    .botInfoBox {
      padding-top: 3rem;
      padding-left: 2rem;
      padding-right: 2rem;
      /* background-color: green; */
      width: 100%;
      display: flex;
      // gap: 0.875rem;
      flex-direction: column;
      align-items: flex-start;

      .botTitle {
        font-family: ${theme.typography.fontFamily};
        font-weight: 600;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.313rem;
        line-height: 1.5rem;
        text-transform: capitalize;
        color: ${theme.palette.default.black};
        margin-bottom: 0.25rem;
      }

      .botLastUpdated {
        font-family: ${theme.typography.fontFamily};
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 1rem;
        text-transform: capitalize;
        color: ${theme.palette.default.darkGrey};
      }

      .botSummary {
        font-family: ${theme.typography.fontFamily};
        // font-weight: 400;
        font-size: 0.938rem;
        // line-height: 1rem;
        color: ${theme.palette.default.darkGrey};
        height: 4rem;
        max-height: 4rem;
        overflow: hidden;
        text-align: left;
      }
    }
    .botChannelsBox {
      display: flex;
      
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;

      .channelsTitle {
        font-family: ${theme.typography.fontFamily};
        font-weight: 400;
        font-size: 1rem;
        line-height: 1rem;
        text-transform: capitalize;
        color: #6a6a6a;
      }

      .channelsDiv {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
    .botActionBox {
      height: 4rem;
      min-height: 4rem;
      width: calc(100% - 28px);
      border-top: 1px solid rgba(126, 131, 146, 0.3);
      display: flex;
      justify-content: space-between;
      align-items: center;
      justify-content: center;

      .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(50%);
        gap: 0.75rem;
        height: calc(100% - 12px);
        cursor: pointer;
        p {
          font-size: 1rem;
          color: ${theme.palette.default.darkGrey};
        }
      }

      .actions {
        outline: 0;

        background: #fff;

        text-align: center;
        font-weight: 400;
        color: #0f1111;
        :hover {
          background-color: #f7fafa;
          border-color: #d5d9d9;
        }
      }

      .right_border {
        padding-right: 1rem;
        border-right: 1px solid rgba(126, 131, 146, 0.3);
      }

      .ActionBtn {
        width: 100%;
      }
    }
  }
`;

export const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;
export const LabelDark = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 500;
    font-size: 0.95rem;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.text};
  }
`;

export const SettingPageMainBoxWrap = styled.div`
  && {
    width: 100%;
    height: 100%;
    padding: 0.8rem 0rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;
  }
`;
export const SettingPageMainBoxTopbar = styled.div`
  && {
    padding-top: 18px;
    overflow: visible;
    display: flex;
    align-items: center;
    .formikFormWrapper {
      width: 100%;
    }
    .btn-grp {
      padding: 0 !important;
      svg {
        font-size: 18px;
        margin: 0px 4px;
        width: 15px;
        height: 15px;
      }
    }
    .setting-page-mainboxtopbar__left {
      .sms-list {
        font-size: 1rem;
        display: flex;
        align-items: center;
        font-weight: 500;
        color: ${theme.palette.default.black};
      }
    }

    width: 100%;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 1rem;
    overflow: hidden;
    .btnDiv {
      width: 15%;
    }
    .setting-page-mainboxtopbar__right {
      background-color: ${theme.palette.default.lightGrey};
      z-index: 2;
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
`;

export const FilterPannel = styled.div`
  padding-right: 0.8rem;
  padding-left: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.palette.default.border};
  border-bottom: 0;
  width: 100%;
  background-color: #ffffff;
  /* margin-top: 20px !important; */
  .setting-page__menu {
    &.datePicker {
      > div > div {
        padding: 0;
      }
    }
  }
  .sms-broadcast-list {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .new-select-main {
      margin-bottom: 0px;
    }
  }

  .date-picker {
    div {
      @media ${device.laptop2} {
        font-size: 0.8rem;
        width: unset;
        gap: 0 !important;
      }
    }
  }
  .form-container {
    /* border: 1px solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .fields-container {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.4rem;
    .sms_left {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .create-table-select {
        display: flex;
        align-items: center;
        gap: 0.4rem;
      }

      .create-table-select {
        z-index: 2;
        .css-b62m3t-container {
          width: 220px;
          height: 40px;
        }
      }
    }
    .sms_right {
      display: flex;
      align-items: center;

      .sms_select {
        .sms-filter {
        }
      }
    }
  }

  .SmsTemplateFilter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.4rem;
    .left {
      display: flex;
      align-items: center;
      gap: 0.6rem;
    }
    .right {
      display: flex;
      align-items: center;
    }

    .history-form {
      form {
        gap: 0 !important;
      }
    }
  }
`;

export const SettingPageMainBoxTitle = styled.div`
  & {
    font-size: 16px;
    h4 {
      color: ${theme.palette.default.black};
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    p {
      color: ${theme.palette.default.darkGrey};
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 32px;
    }
    .boldText {
      font-size: 16px;
    }
    .help-text {
      color: ${theme.palette.default.darkGrey};
      font-size: 14px;
      font-weight: 400;
      margin: 8px 0px;
    }
    .checktext {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      p {
        margin: 0;
      }
    }
    .whatsapp-analytics {
      gap: 2.5rem;
      font-weight: 500;
    }

    @media ${device.laptopM} {
      .whatsapp-analytics {
        font-size: 1rem;
      }
    }
    @media ${device.laptop2} {
      .whatsapp-analytics {
        font-size: 0.9rem !important;
      }
    }
    svg {
      font-size: 1rem;
      color: ${theme.palette.default.darkGrey};
      cursor: pointer;
    }
  }
`;
export const SettingPageMainBoxBtnBox = styled.div`
  && {
    // background-color: ${theme.palette.default.red};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 55%;
    gap: 15px;
    @media ${device.laptopL} {
      width: 68%;
    }
    @media ${device.laptopM} {
      width: 78%;
    }
    @media ${device.laptop2} {
      gap: 10px;
    }
  }
`;
export const SettingPageMainContentBox = styled.div`
  && {
    height: auto;
    overflow: overlay;
    margin: 0 1rem;
    padding: 1rem 0rem 1rem;
    background-color: ${theme.palette.default.background};
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);

    .MuiTablePagination-root {
      height: 50px !important;
      min-height: 50px !important;
      width: 100%;
    }
    .MuiTablePagination-toolbar {
      height: 50px !important;
      min-height: 50px !important;
    }

    .MuiPaper-elevation1 {
      margin-bottom: 0px !important;
    }

    /* ::-webkit-scrollbar {
 display: inline;
}
&:hover{
  ::-webkit-scrollbar {
 display: inline;
}
} */
  }
`;
export const StyleFacebookButton = styled.div`
  && {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    i {
      font-size: 15px;
    }
  }
`;

export const StyledTableHeader = styled.div<StyledProps>`
  && {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.8rem;
  }
`;
export const StyledHeaderLeft = styled.div<StyledProps>`
  && {
    width: 50%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 3rem;
    padding-left: 1rem;
  }
`;
export const StyledTableHeaderTitle = styled.div<StyledProps>`
  && {
    font-weight: 500;
    font-size: 1.15rem;
    line-height: 1.3rem;
    text-transform: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    color: ${theme.palette.default.darkGrey};
    svg {
      font-size: 1.2rem;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;
export const StyledTableHeaderActionButtons = styled.div<StyledProps>`
  && {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: right;
    gap: 1.5rem;
    padding-right: 1rem;
  }
`;

export const StyledTableHeaderInfoBar = styled.div<StyledProps>`
  && {
    display: flex;
    width: 100%;
    height: 64px;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.04);
    gap: 1.5rem;
    padding: 0 1.5rem;
    font-size: 1rem;
    font-weight: 400;
  }
`;

export const HeaderActionButtons = styled.div`
  && {
    width: 100%;
    display: flex;
    gap: 1rem;
  }
`;

export const StyledDialog = styled(Dialog)`
  && {
    small {
      padding: 0 1.5rem 0;
      cursor: default;
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 0.85rem;
      line-height: 30px;
      color: ${theme.palette.default.darkGrey};
    }

    .MuiDialogContent-root {
      padding: 1rem 1.5rem 1rem;
    }
  }
`;
export const StyledActionBtn = styled(DialogActions)`
  && {
    gap: 1.5rem !important;
  }
`;
export const StyledDialogTitle = styled(DialogTitle)`
  && {
    cursor: default;
    font-family: ${theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    color: ${theme.palette.default.text};
    padding-bottom: 0.2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;
export const StyledDialogHint = styled.div`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    color: ${theme.palette.default.text};
    padding-bottom: 0.4rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    margin-bottom: 1rem;
    margin-top: 1rem;

    ul {
      font-size: 0.9rem;
      padding-left: 1rem;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;

export const GraphInfoBox = styled.div`
  && {
    width: 125%;
    height: 60px;
    margin-right: 1rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: none;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    text-transform: capitalize;
    .n_div_tooltip {
      color: ${theme.palette.default.darkGrey};
      font-size: 16px;
    }

    .browserlanguage {
      font-size: 18px;
      font-family: Inter;
      font-weight: 500;
    }

    @media (max-width: 1820px) {
      .browserlanguage {
        font-size: 14px;
        font-family: Inter;
        font-weight: 500;
      }
    }

    .acquisitionslocation {
      font-size: 18px;
      font-family: Inter;
      font-weight: 500;
    }

    @media (max-width: 1820px) {
      .acquisitionslocation {
        width: 100%;
        font-size: 14px;
        font-family: Inter;
        font-weight: 500;
      }
    }

    .acquisitiondevice {
      width: 100%;
      font-size: 18px;
      font-family: Inter;
      font-weight: 500;
    }

    @media (max-width: 1820px) {
      .acquisitiondevice {
        width: 100%;
        font-size: 14px;
        font-family: Inter;
        font-weight: 500;
      }
    }

    .acquisition_overview {
      font-size: 18px;
      font-family: Inter;
      font-weight: 500;
    }

    @media (max-width: 1820px) {
      .acquisition_overview {
        font-size: 14px;
        font-family: Inter;
        font-weight: 500;
      }
    }

    color: ${theme.palette.default.text};
    @media ${device.laptopL} {
      font-size: 0.85rem;
      line-height: 1rem;
      svg {
        font-size: 0.8rem;
      }
    }

    svg {
      cursor: pointer;
      font-size: 10px;

      &:hover {
        color: ${theme.palette.default.doveGrey};
      }
    }
  }
`;

export const GraphFilterBox = styled.div`
  && {
    width: 75%;
    max-width: 75%;
    height: 60px;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: right;
  }
`;
export const GraphTopbarBox = styled.div`
  && {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 24px 10px 10px;
    border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
  }
`;
export const StyledFilterTabs = styled(Tabs)`
  && {
    /* .MuiTabs-root, .css-okivit-MuiTabs-root{
      min-height: 40px !important;
      max-height: 40px !important;
    } */

    .Mui-selected {
      background-color: ${theme.palette.default.lightGrey};
    }
    .MuiTab-root {
      min-height: 32px;
      max-height: 32px;
      height: 32px;
      font-size: 13px;
      text-transform: capitalize;
      max-width: 80px;
      min-width: 60px;
      overflow: hidden;
      button {
        min-width: 10px;
      }

      @media ${device.laptopL} {
        font-size: 11px;
        max-width: 36px;
        min-width: 36px;
      }
      @media ${device.laptop} {
        font-size: 12px;
        max-width: 50px;
        min-width: 50px;
      }
    }
  }
`;

export const TopMenuLeftBox = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 4;
  }
`;

export const TopMenuWrap = styled.div`
  && {
    background-color: ${theme.palette.default.white};
    z-index: 999;
    height: 4rem;
    width: 100%;
    padding: 0 1rem;
    position: -webkit-fixed;
    position: fixed;
    top: 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
  }
`;

export const TopMenuRightBox = styled.div`
  && {
    display: flex;
    justify-content: right;
    gap: 2rem;
    align-items: center;
    flex: 1.5;
    margin-right: 1rem;
  }
`;
export const TopMenuMiddleBox = styled.div`
  && {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100%;
    width: 35%;
    cursor: default;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 1.05rem;
    line-height: 20px;

    color: ${theme.palette.default.text};
    gap: 0.6rem;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    text-overflow: ellipsis !important;
  }
`;
export const MultipleFieldsRow = styled.div`
  && {
    display: flex;
    width: 100%;
    align-items: start;
    flex-direction: row;
    gap: 1.5rem;
    margin-bottom: 0;
    /* @media (max-width: 110px) { */
    @media ${device.laptop} {
      gap: 6px;
      flex-direction: column;
    }
  }
`;

export const Android12Switch = styled(Switch)(() => ({
  padding: 8,
  width: 50,
  height: 34,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 14,
      height: 14,
      right: 0,
    },
    "&:before": {
      left: 12,
    },
    "&:after": {
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 12,
    height: 12,
    margin: 2,
  },
  "& .MuiSwitch-switchBase": {
    top: 1,
    left: 1,
    // transform: 'translateX(18px) !important',
  },
  "& .Mui-checked": {
    top: 1,
    transform: "translateX(16px) !important",
  },
}));

export const AppPageCaption = styled.div`
  && {
    cursor: default;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 10px;
    color: ${theme.palette.default.darkGrey};
  }
`;

export const StyledAvatar = styled(Typography)`
  && {
    width: 32px;
    height: 32px;
    background-color: #d8defa;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-transform: capitalize;
    font-size: 20px;
    color: #5b73e8;
  }
`;

export const MessageRow = styled.div<StyledProps>`
  && {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: ${(props: any) =>
      props.userMessage ? "flex-end" : "flex-start"};
    gap: 8px;
    margin: 6px 0;
    margin-bottom: 10px;
    word-wrap: break-word;
    //disable user copy and select
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0rem 1rem;
    img {
      display: ${(props: any) => (props.userMessage ? "none" : "flex")};
      width: 35px;
      height: 35px;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid
        ${(props: any) =>
          props.userMessage
            ? theme.palette.default.text
            : theme.palette.secondary.main};
      box-shadow: 0px 1px 4px #999;
    }
  }
`;

export const MessageContainer = styled.div<StyledProps>`
  && {
    max-width: 75%;
    word-wrap: break-word;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    align-items: ${(props: any) => (props.userMessage ? "right" : "left")};
    justify-content: ${(props: any) => (props.userMessage ? "right" : "left")};
    padding: 4px 8px;
    border-radius: ${(props: any) =>
      props.userMessage ? "8px 8px 0px 8px" : "8px 8px 8px 0px"};
    overflow: hidden;

    animation-delay: 2s;
  }
`;

export const MessageContainerText = styled.div<StyledProps>`
  && {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 2px 4px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    box-shadow: 0px 1px 3px #999;
    padding: 3px 8px;
    border-radius: 5px;
    a {
      color: blue;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: ${theme?.palette?.primary.dark};
      }
      &:active {
        color: blue;
      }
    }

    @media ${device.mobileL} {
      font-size: 14px;
    }
  }
`;

export const MessageMediaRow = styled(Box)<StyledProps>`
  && {
    background-color: transparent;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 6px;
    word-wrap: break-word;
    width: 65%;
    max-width: 65%;

    img {
      width: 100%;
      height: 11rem;
      object-fit: cover;
      border: 1px solid ${theme.palette.default.border};
      border-radius: 4px;
    }
    video {
      width: 100%;
      height: 9.5rem;
      object-fit: cover;
      border: 1px solid ${theme.palette.default.border};
      border-radius: 4px;
    }
  }
`;

export const StyledAlertContainerText = styled.div`
  && {
    margin: 12px 0;
    max-width: 90%;
    border-radius: 4px;
    padding: 4px 14px 4px 14px;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-size: 13px;
    font-weight: 500;

    font-family: ${theme.typography.fontFamily};
    color: ${theme.palette.default.white};
    background-color: ${theme.palette.primary.light};
  }
`;
export const Caption = styled.p`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 300;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    text-align: left;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;

export const StyledTextInput = styled.input<StyledProps>`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 1.3rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
  }
  &:disabled {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.darkGrey};
  }
`;

export const StyledInputFieldWrapper = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 0.5rem;

    button {
      &:disabled {
        color: ${theme.palette.default.grey};
      }
    }
  }
`;

export const StyledCustomizerActionBtnsWrapper = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    gap: 1rem;
    margin-top: 1.4rem;
  }
`;

export const InfiniteScrollTableDiv = styled.div`
  && {
    width: 100%;
    overflow: hidden;
    .table-wrapper {
      // border: 1px solid ${theme.palette.default.fadeWhite};
      border-radius: 5px;
      display: flex;
      flex-direction: column;
    }
    .extraInfoValue {
      margin-left: 8px;
      padding: 4px 8px;
      font-weight: 400;
      font-size: 12px;
      border-radius: 500px;
      color: ${theme.palette.default.success};
      background-color: ${theme.palette.default.lightSuccess};
    }

    .success {
      color: ${theme.palette.default.success};
      background-color: ${theme.palette.default.lightSuccess};
    }
    .error {
      color: ${theme.palette.default.error};
      background-color: ${theme.palette.default.lightError};
    }
    .table-heading {
      color: ${theme.palette.default.lighterBlack};
      font-weight: "500";
      font-size: "16px";
      text-transform: "capitalize";
      height: "48px";
      border-bottom: 1px solid rgba(126, 131, 146, 0.5);
      background-color: ${theme.palette.default.white};
      padding-top: "0.5rem";
      padding-bottom: "0.5rem";
      display: flex;
    }
    .table-row {
      padding: 0.5rem;
      min-width: 5rem;
      width: 50%;
      // text-align:center;
    }
    .table-body-row {
      color: ${theme.palette.default.lighterBlack};
      font-family: ${theme.typography.fontFamily};
      font-weight: "400";
      font-size: "16px";
      height: "48px";
      border-bottom: 1px solid ${theme.palette.default.fadeWhite};
      background-color: ${theme.palette.default.white};
      display: flex;
    }
    .table-body-row:hover {
      background-color: ${theme.palette.default.moreLighterGrey};
    }
  }
`;

export const NoDataBox = styled.div`
  && {
    height: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 60%;
      height: 60%;
      object-fit: contain;
    }
    p {
      font-size: 1.1rem;
      font-weight: 400;
    }
  }
`;

interface StyledProps {
  OPENING_SIDE?: string;
  OPENING_POSITION?: string;
}

const getLeftRightPosition = ({ OPENING_SIDE }: any) => {
  if (OPENING_SIDE === "Right") {
    return css`
      left: 0px;
    `;
  } else {
    return css`
      right: 0px;
    `;
  }
};
const getTopBottomPosition = ({ OPENING_POSITION }: any) => {
  if (OPENING_POSITION === "Top") {
    return css`
      bottom: 100%;
    `;
  } else {
    return css`
      top: 100%;
    `;
  }
};

export const StyledBox = styled.div`
  && {
    position: relative;
    width: 240px;

    pointer-events: ${({ $disabled }: any) => ($disabled ? `none` : "auto")};
  }
`;

export const StyledDatePickerWrapper = styled.div<StyledProps>`
  && {
    // border: 1px solid red;
    position: absolute;
    z-index: 999113123121222;
    display: flex;
    flex-direction: column;
    min-height: 426px;
    max-width: 800px;
    opacity: 1;
    transition: opacity 0.1s;
    ${(OPENING_SIDE: any) => getLeftRightPosition(OPENING_SIDE)};
    ${(WIDGET_POSITION: any) => getTopBottomPosition(WIDGET_POSITION)};
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);

    .styledDate {
      // border: 1px solid yellow;
      height: 372px;
      display: flex;
      align-items: flex-end;
      position: relative !important;
      background-color: #ffffff !important;
      // padding: 0px 15px;
      // padding: 0.6rem 0.6rem 0.2rem 0;

      overflow: hidden !important;
      z-index: 999991812312;

      .drpGridContainer {
        border-top: 1px solid ${theme.palette.default.border};
        border-bottom: 1px solid ${theme.palette.default.border};
        height: 100%;
      }

      & > :first-child {
        // border: 1px solid;
        // padding: 0px 15px;
        height: calc(100% - 51px);
      }

      & > :first-child::before {
        content: "Search By Calendar";
        font-size: 14px;
        position: absolute;
        top: 15px;
        left: 15px;
      }

      & .MuiPaper-root {
        box-shadow: none;
      }
    }
    .css-15lo3ef button.Mui-disabled {
      background-color: transparent;
    }

    .drpPaper {
    }

    .drpGridContainer {
    }

    .drpLeftContainer {
      /* display: none; */
      width: 135px;
      border-right: 1px solid ${theme.palette.default.border};
    }
    .drpLeftList {
      // margin-right: 4px;
      color: #282828;
      p {
        font-size: 12px;
        font-weight: 400;
      }
      .css-1tzqxis-MuiButtonBase-root-MuiListItem-root,
      .css-4hhuiu {
        background-color: #efeffe;

        color: #5b73e8;
        font-weight: 400;

        .css-1xlopdz-MuiTypography-root {
          color: white;
          font-weight: 400 !important;
        }
      }
    }

    .drpRightSelectedContainer {
      padding: 8px 10px;
      div {
        h6 {
          font-size: 12px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
      }

      h6 {
        font-size: 14.5px;
        letter-spacing: 0.3px;
        font-weight: 500;
        color: ${theme.palette.default.text};
        color: #504f4f;
      }

      svg {
        font-size: 18px;
      }
    }

    .drpRightBothMonthContainer {
      // border: 1px solid green;
      height: 88%;
      gap: 4px;
    }
    .drpMonthPaper {
      width: 310px;
    }

    .drpMonthGridContainer {
    }

    .drpMonthHeaderRow {
      padding: 4px 2px;
      .MuiSvgIcon-root {
        font-size: 16px;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .MuiSelect-select {
        font-size: 12px;
        font-weight: 500;
        color: ${theme.palette.default.darkGrey};
      }

      .MuiIconButton-root {
        padding: 0 !important;
        color: ${theme.palette.default.darkGrey};

        &:hover {
          background-color: ${theme.palette.default.lightGrey};
        }
      }

      li {
        font-size: 14px;
        letter-spacing: 0.2px;

        font-weight: 500;
        color: ${theme.palette.default.darkGrey};
      }
    }

    .drpWeekRow {
      padding: 2px 2px;
      margin: 0 0px;
      justify-content: center;
      span {
        // border: 1px solid;
        width: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.2px;
        color: ${theme.palette.default.darkGrey};
      }
    }
    .drpDaysContainer {
      padding: 0;
      margin: 0;
      padding: 2px 1px;
    }
    .drpDaysGridContainer {
      // justify-content: space-between;
      margin-bottom: 4px;
    }
    .drpSingleBox {
      width: 38px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      button {
        border-radius: 12px;
      }
      button:hover {
        // border: 1px solid;
        background-color: #5b73e8;
        border-radius: 12px;
        color: #fff;
      }
      P {
        font-size: 12px;
      }
    }

    /* start end middle click date box */
    .css-a6copi,
    .css-cuebsd,
    .css-15lo3ef {
      button {
        width: 38px;
        // background-color: red;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        p {
          color: ${theme.palette.default.white};
        }
      }
    }

    .css-1ioes2d {
      button {
        width: 38px;
        // background-color: red;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }

    .css-cuebsd,
    .css-15lo3ef {
      button {
        border-radius: 0px;
        background-color: #dde3ff;

        p {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    /* divider HR */
    .drpLeftDivider {
      display: none;
    }
    .drpRightTopDivider {
    }
    .drpRightMiddleDivider {
    }
  }
`;

export const StyledTextField = styled.div`
  && {
    word-break: keep-all;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    width: 100%;
    padding: 2px 12px;
    height: 36px;

    background-color: transparent;

    color: ${theme.palette.default.darkGrey};
    border-radius: 6px;
    border: 1px solid rgb(203, 205, 211);
    background-color: #f5f6f8;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;

    &::placeholder {
      color: ${theme.palette.default.darkGrey};
    }
    i {
      color: ${theme.palette.default.darkGrey} !important;
    }
    &:disabled {
      color: grey;
      border: 1px solid red;
    }

    &:legend {
      display: none;
    }
    &:css-16s726f-muiformlabel-root-muiinputlabel-root {
      display: none;
    }

    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
    @media screen and (max-width: 1366px) {
      font-size: 10px;
    }
  }
`;

export const StyledDateField = styled.div`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem;
    &.remove-padding {
      padding: 0;
    }
  }
`;
export const ActionBox = styled.div`
  && {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    // height: 52px;
    gap: 1.2rem;
    z-index: 9991;
    .rightBtnBox {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      justify-content: right;
      align-items: center;
      gap: 1rem;
    }
  }
`;

export const DecsText = styled.p`
  && {
    margin: 0.5rem 0;
    color: rgba(126, 131, 146, 1);
    font-size: 15px;
  }
`;
