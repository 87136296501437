import styled from "styled-components";
import { theme } from "../../../../../../../Customization/Theme";

export const AnsweredTable = styled.div`
  .search-class {
    display: flex;
    align-items: center;
  }
  .website {
    border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
    padding-bottom: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    P {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: ${theme.palette.default.newTextGrey};
    }
  }

  .left-part {
    width: 700px;
  }
  .front-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .front-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: ${theme.palette.default.newTextGrey};
  }
`;
export const PopOverText = styled.div`
  box-shadow: 0px 8px 24px 0px ${theme.palette.default.PopoverShadow};

  .filter-header p {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.blue};
    background-color: ${theme.palette.default.GhostWhite};
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .faq-filter ul {
    list-style: none;
    padding-left: 16px;
  }
  .faq-filter span {
    color: ${theme.palette.default.newTextGrey};
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
  .button {
    display: flex;
    gap: 1rem;
    padding: 12px 16px;
  }
`;
export const AnsweredFaqList = styled.div`
  .table-body {
    height: calc(100vh - 510px);
    overflow: auto;
  }
  .table-header {
    display: flex;
    align-items: stretch;
    font-weight: bold;
    border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
    padding-bottom: 4px;
    padding-top: 4px;
    flex: 1;
  }

  .table-row {
    display: flex;
    border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
  }
  .query1 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }

  .query2 {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .query3 {
    color: ${theme.palette.default.AccentOrange};
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    padding: 3px;
    border: 1px solid ${theme.palette.default.AccentOrange};
    width: 200px;
    text-align: center;
    border-radius: 4px;
  }

  .col-head,
  .col-body {
    padding: 16px 16px;
    color: ${theme.palette.default.newTextGrey};
    font-size: 14px;
    font-weight: 400;
    :nth-child(1) {
      flex-basis: 300px;
    }
    :nth-child(2) {
      flex-basis: 420px;
      @media (max-width: 1400px) {
        flex-basis: 280px;
      }
    }

    :nth-child(3) {
      flex-basis: 315px;
    }
    :nth-child(n + 3) {
      flex: 1; /* columns from the third child to grow equally */
    }
    :nth-child(7) {
      display: flex;
      align-items: baseline;
      justify-content: end;
      height: 70px;
    }
  }

  .col-head {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
    padding: 10px;
    border-right: 1px solid ${theme.palette.default.RightBorderDivder};

    :nth-child(2) {
      flex-basis: 426px;
      @media (max-width: 1400px) {
        flex-basis: 280px;
      }
    }

    :nth-child(3) {
      flex-basis: 315px;
    }
    :nth-child(8) {
      border-right: none;
    }
    :nth-child(n + 3) {
      flex: 1; /* Allow columns from the third child to grow equally */
    }
  }
`;
export const AnsweredWrap = styled.div`
height: 100%;
  .form-wrapper {

    .customInput {
      .wrapper {
        max-height: 400px;
        .inner-wrapper {
          height: 200px;
        }
      }
    }
  }

  .answered-popup {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.default.BlackOpacityBorder};
    align-items: center;
    padding: 16px;
    height: 63px;
  }
  .answered-heading p {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: ${theme.palette.default.NightRider};
  }
  .input-field label {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }
  .input-field p {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }

  .tooltip label {
    display: flex;
    align-items: center;
  }

  .body-popup {
    overflow: auto;
  }

  .btn {
    display: flex;
    gap: 16px;
    justify-content: end;
    padding: 16px;
    height: 68px;
  }

  .custom-textarea::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: ${theme.palette.default.newTextGrey};
  }
  .custom-textarea {
    margin-top: 16px;
  }

  .radio-button {
    gap: 16px;
    padding: 10px 0;
  }
  .body-popup {
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 22px;
  }
  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }

  .MuiFormGroup-root {
    display: contents;
  }

  .list h3 {
    padding-top: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: ${theme.palette.default.newTextBlack};
  }
  .list li {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: ${theme.palette.default.newTextGrey};
    margin-bottom: 8px;
  }

  .list ul {
    padding-bottom: 16px;
    padding-left: 16px;
    padding-top: 8px;
  }
  .select-field h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.newTextBlack};
  }
  .btn {
    display: flex;
    gap: 16px;
    justify-content: end;
    padding: 16px;
    border-top: 1px solid ${theme.palette.default.BlackOpacityBorder};
  }
`;

export const DocumentAndTextTableWrapper = styled.div`
  & {
    width: 100%;
    min-width: 1410px;
    background-color: ${theme.palette.default.white};
    .table-header {
      .table-row {
        display: flex;
        height: 64px;
        align-items: center;
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
      }

      .table-row > div:first-child {
        max-width: 80px;
      }

      .table-row > div:last-child {
        border-right: none;
        justify-content: flex-end;
        max-width: 120px;
      }

      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        font-weight: 500;
        border-right: 1px solid rgba(126, 131, 146, 0.5);
      }
    }

    // Body
    .table-body {
      overflow: auto;
      .table-row {
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
      }
      .table-row > div:first-child {
        max-width: 80px;
      }
    }
    .table-row > div:nth-child(8) {
      border-right: none;
    }

    .table-row > div:last-child {
      border-right: none;
      justify-content: flex-end;
      max-width: 120px;
    }
    .col-body {
      flex: 1;
      display: flex;
      word-break: break-all;
      align-items: center;
      padding: 8px 16px;
      font-weight: 500;
      color: ${theme.palette.default.darkGrey};
      .status {
        display: flex;
        gap: 8px;
        color: #25926a;
        padding: 4px 16px;
        align-items: center;
        background-color: #e5fff6;
        border: 1px solid #12c584;
        border-radius: 4px;
        justify-content: center;
      }
      .status-uploaded {
        display: flex;
        gap: 8px;
        color: ${theme.palette.primary.main};
        padding: 4px 16px;
        align-items: center;
        background-color: ${theme.palette.default.pattensBlue};
        border: 1px solid ${theme.palette.primary.main};
        border-radius: 4px;
        justify-content: center;
      }
      .in-progress {
        display: flex;
        gap: 8px;
        color: #918548;
        padding: 4px 16px;
        align-items: center;
        background-color: #fffbe5;
        border: 1px solid #ffe55e;
        border-radius: 4px;
        justify-content: center;
      }
    }
  }
`;
export const Scroll = styled.div`
  & {
    overflow: auto;
  }
`;


// Search Styles
export const StyledAppInput = styled.div`
  & {
    display: flex;
    align-items: center;
    position: relative;
    input {
      border-radius: 4px;
      outline: none;
      border: 1px solid transparent;
      padding: 0 1.3rem 0 14px;
      font-family: ${theme.typography.fontFamily};
      border: 1px solid ${theme.palette.default.grey};
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      position: relative;
      text-overflow: ellipsis;
      background-color: ${theme.palette.secondary.main};
      max-width: 320px;
      min-width: 320px;
      min-height: 30px;
    }
    svg {
      position: absolute;
      right: 10px;
      width: 15px;
    }
  }

  &::placeholder {
    color: ${theme.palette.default.grey};
  }
`;

export const ShowAllLinkTable = styled.div`
  & {
    width: 100%;
    min-width: 1343px;
    background-color: ${theme.palette.default.white};
    .table-header {
      .table-row {
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
      }

      .table-row > div:first-child {
        height: 55px;
        padding-left: 30px;
        min-width: 532px;
        max-width: 532px;
      }

      .table-row > div:nth-child(3) {
        min-width: 317px;
        max-width: 317px;
        border-right: none;
      }

      .table-row > div:last-child {
        border-right: none;
        justify-content: flex-end;
        max-width: 60px;
      }

      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        font-weight: 500;
        border-right: 1px solid rgba(126, 131, 146, 0.5);
        font-size: 13px;
      }
    }

    // Body
    .table-body {
      overflow: auto;
      .table-row {
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
      }
      .table-row > div:first-child {
        max-width: 60px;
      }
      .table-row > div:nth-child(2) {
        min-width: 532px;
        max-width: 532px;
        color: ${theme.palette.default.black};
      }
      .table-row > div:nth-child(3) {
        min-width: 317px;
        max-width: 317px;
      }
    }

    .table-row > div:last-child {
      border-right: none;
      justify-content: flex-end;
      max-width: 60px;
    }
    .col-body {
      display: flex;
      word-break: break-all;
      flex: 1;
      align-items: center;
      padding: 8px 16px;
      font-size: 13px;
      color: ${theme.palette.default.darkGrey};
      .status {
        display: flex;
        gap: 8px;
        color: #25926a;
        padding: 4px 16px;
        align-items: center;
        background-color: #e5fff6;
        border: 1px solid #12c584;
        border-radius: 4px;
        justify-content: center;
      }
      .status-uploaded {
        display: flex;
        gap: 8px;
        color: ${theme.palette.primary.main};
        padding: 4px 16px;
        align-items: center;
        background-color: ${theme.palette.default.pattensBlue};
        border: 1px solid ${theme.palette.primary.main};
        border-radius: 4px;
        justify-content: center;
      }
      .in-progress {
        display: flex;
        gap: 8px;
        color: #918548;
        padding: 4px 16px;
        align-items: center;
        background-color: #fffbe5;
        border: 1px solid #ffe55e;
        border-radius: 4px;
        justify-content: center;
      }
    }
    .col-body.url {
      color: ${theme.palette.primary.main} !important;
    }
  }
`;

// Wrapper For Website
export const FaqWebsiteWrap = styled.div`
  .container {
    padding: 16px 0;
    align-items: center;
    display: flex;
    gap: 10rem;
    justify-content: space-between;
    .header {
      display: flex;
      align-items: center;
      gap: 32px;
      h5 {
        font-weight: 500;
        font-size: 16px;
        display: block;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        display: block;
        color: ${theme.palette.default.darkGrey};
      }
    }
  }

  .front-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .front-text {
    font-family: inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: ${theme.palette.default.darkGrey};
  }
`;

export const WebsiteTableStyle = styled("div")({
  padding: "16px 16px",
  ".leftside-popup": {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid lightgray",
    paddingBottom: "16px",
    h3: {
      fontStyle: "Rubik",
      fontWeight: "500",
      fontSize: "16px",
      color: `${theme.palette.default.newTextBlack}`,
    },
    p: {
      fontStyle: "Rubik",
      fontWeight: "400",
      fontSize: "14px",
      color: `${theme.palette.default.darkGrey}`,
    },
  },
  ".input-field": {
    marginTop: "1rem",
    borderBottom: "1px solid lightgray",
    paddingBottom: "16px",
    // Add more styles as needed
    label: {
      fontStyle: "Rubik",
      fontWeight: "400",
      fontSize: "14px",
      color: `${theme.palette.default.newTextBlack}`,
    },
  },
  ".buttons": {
    display: "flex",
    justifyContent: "end",
    gap: "8px",
    paddingTop: "16px",
  },
});

// Source Type Text
export const FaqTextWrap = styled.div`
  background-color: ${theme.palette.default.white};
  padding: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  .ri-add-line {
    color: ${theme.palette.primary.main} !important;
  }
  .top-header {
    background-color: ${theme.palette.default.white};
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 2rem;

    .header-left {
      max-width: calc(100% - ${(props: any) => props.width});
      p {
        font-size: 14px;
        color: red;
        font-weight: 400;
        line-height: 22px;
        color: #7e8392;
      }
    }
    .serach-class {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      button {
      }
    }
  }

  .front-image {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 368px);
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .front-text {
    font-family: inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #7e8392;
  }
  .button svg {
    max-width: 25px;
  }
`;

export const UploadFilePopupWrap = styled.div`
  .crawl-popup {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.default.grey};
    align-items: center;
    padding: 22px 16px;

    p {
      color: #323232;
      font-weight: 400;
      font-style: inter;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .header {
    margin-bottom: 55px;
  }

  h3 {
    line-height: 30px;
    font-weight: 500;
    font-style: inter;
    font-size: 20px;
    color: #000000;
  }
  .paragraph {
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: #f5f7ff;
    border: 1px solid #bfcaff;
    padding: 10px;
    margin-top: 16px;
    p {
      font-family: inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .input-field {
    margin-top: 1rem;
  }
  .input-field label {
    font-style: inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }

  .popup-buttons {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    border-top: 1px solid ${theme.palette.default.grey};
  }

  .upload-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 16px 40px 16px;
    .header {
      width: 100%;
      display: flex;
      align-items: flex-start;
    }
  }

  .upload-file p {
    font-family: inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }
`;