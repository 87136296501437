import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Grid, FormControlLabel, Switch } from "@mui/material";
import { MyBotCard } from "../../Customization/CommonElements";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import { Chatbot } from "../../Models";
import { useMyBotsStore } from "../../Services/Apis/ChatbotConsoleApis";
import { getProjectConfigFn } from "../../Services/Apis/CoreApis";
import { CORE_ENDPOINTS } from "../../Services/Apis/Endpoints";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { ChangeSelectedBot, useCurrentBot } from "../../Store/Slices/DashboardSlices";
import { formatString } from "../../Utils";
import { ReactComponent as ManageBot } from "../../assets/images/bot.svg";
import defaultAvatar from "../../assets/images/botbuilderbot.webp";
import { ReactComponent as EditBot } from "../../assets/images/edit.svg";
import { ReactComponent as CahnnelWebbot } from "../../assets/images/webbot.svg";
import { ReactComponent as CahnnelWhatsappBot } from "../../assets/images/whatsappbot.svg";
import { ReactComponent as CahnnelFacebookBot } from "../../assets/images/facebook-icon.svg";
import { ReactComponent as CahnnelTelegramBot } from "../../assets/images/telegram.svg";
import { ReactComponent as CahnnelVoiceBot } from "../../assets/images/voice.svg";
import WelcomeBotScreen from "./WelcomeBotScreen";
import { ProtectedAppUiElement } from "../../AppComponents/ProtectedComponents";
import { Bot } from "../../Store/Slices/DashboardRedux/dashboard.slice";
import { AppForm, AppFormSwitchField } from "../../AppComponents/Forms";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import AppButton from "../../AppComponents/AppButton";
import AppModel from "../../Hooks/useModel/AppModel";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import { ChatbotConsoleService } from "../../Services/Apis/ChatbotConsoleService";
import { useAppSelector } from "../../Store";
import { bot_id as botIdSelector } from "../../Store/Slices/DashboardAnalytics/dashboardanalytics.selector";
import useFlowBuilder from "../../Modules/FlowBuilder/Hooks/useFlowBuilder";
import { getLastUrlSegment } from "../../Utils";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { Formik, Form, FieldArray, Field } from "formik";

interface Props {
  children?: React.ReactNode;
  loading?: any;
  setBotId?: any;
  setCreateBotModel?: any;
  setCreateBotModelTitle?: any;
}

const Chatbots = (props: Props) => {
  const dispatch = useDispatch();
  useDocumentTitle("Chatbots");
  const [isShowingWarningModal, toggleWarningModal] = useState<boolean>(false);
  const { userConfig } = useSelector(useAuthStore);
  const [loading, setLoading] = useState(false);
  const currentBot: any = useAppSelector(useCurrentBot);
  const tabsMenuOptionFirstUrl: any =
    userConfig?.payload?.menu_items?.bot_builder_tabs_options?.[0]?.url ?? null;

  const { data: paginatedMyBots } = useSelector(useMyBotsStore);
  const [userInput, setUserInput] = useState<any>({
    bot_enabled: false,
    is_pubilsh_button: false
  });
  const { showAlert } = useNotification();
  const { bot_id }: any = useParams();
  const { checkValidity } = useFlowBuilder();
  const location = useLocation();
  const [myBots, setMyBots] = useState<Chatbot[]>([]);
  const [botLabels,setBotLabels] = useState<any>();
  const { setBotId, setCreateBotModel, setCreateBotModelTitle } = props
  const navigate = useNavigate();

  const BOT_LABEL_MAP: Record<string, string> = {
    true: "Enabled",
    false: "Disabled",
  };

  const handleManageBot = (bot: Chatbot) => {
    dispatch(ChangeSelectedBot(bot));
    const proj_v_url = formatString(CORE_ENDPOINTS.PROJECT_VER_CONFIG, [
      bot.project_version_id,
    ]);
    getProjectConfigFn(dispatch)({ url: proj_v_url }).then((res: any) => { });
    console.log("bot",bot);

    if (bot?.channel === "voice") {
      let tempUrl ="/voice-bot/{0}/setup/agent";
      let URL = formatString(tempUrl, [bot?.bot_id]);
      console.log("URL",URL);
      
      navigate(URL);
    } else {
      let tempUrl =
        tabsMenuOptionFirstUrl ?? "/bot/{0}/setup/customize/look-feel";
      let URL = formatString(tempUrl, [bot?.bot_id]);
      navigate(URL);
    }

  };

  const renderChannelIcon = (channle: any) => {
    if (channle === "whatsapp") {
      return <CahnnelWhatsappBot />;
    } else if (channle === "messenger") {
      return <CahnnelFacebookBot width={"38px"} height={"38px"} />;
    } else if (channle === "telegram") {
      return <CahnnelTelegramBot width={"38px"} height={"38px"} />;
    } else if (channle === "voice") {
      return <CahnnelVoiceBot width={"38px"} height={"38px"} />;
    }
    return <CahnnelWebbot />;
  };

  useEffect(() => {
    if (
      paginatedMyBots &&
      paginatedMyBots?.results &&
      paginatedMyBots?.results.length > 0
    ) {
      setMyBots(paginatedMyBots.results);
      
      
      const initialLabels: Record<string, string> = {};
      paginatedMyBots.results.forEach((bot: Chatbot) => {
        initialLabels[bot.bot_id] = BOT_LABEL_MAP[bot.bot_enabled ? "true" : "false"];
      });
      setBotLabels(initialLabels);
    }
  }, [paginatedMyBots]); 

  const handleEditBtn = (bot: Chatbot) => {
    setBotId(bot?.bot_id)
    setCreateBotModel(true)
    setCreateBotModelTitle("Update chatbot")
    // navigate(`/workspace/${bot?.workspace_id}/setup/edit-bot/${bot?.bot_id}`);
  };

  const sourceType = getLastUrlSegment(location?.pathname);
 
  const HEADERS = {
    appid: JSON.stringify({
      botid: bot_id,
    }),
  };

  const handleBotToggle = (bot: Chatbot, enabled: boolean, formikSetFieldValue: any, values: any) => {
  if (userInput.current_bot_id === bot.bot_id && userInput.bot_enabled === enabled) return;
    const affectedBots = enabled ? 
      myBots.filter(b => b.channel === bot.channel && b.bot_id !== bot.bot_id && b.bot_enabled) : 
      [];
    
    setUserInput({
      bot_enabled: enabled,
      current_bot_id: bot.bot_id,
      is_pubilsh_button: false,
      affectedBots
    });

    toggleWarningModal(true);
  };
  
  const updateBotLabel = (botId: string, enabled: boolean) => {
    setBotLabels((prev:any) => ({
      ...prev,
      [botId]: BOT_LABEL_MAP[enabled ? "true" : "false"]
    }));
  };

  const publishBotFn = () => {
    if (!userInput.is_pubilsh_button && !userInput.bot_enabled) {
      setUserInput({
        ...userInput,
        bot_enabled: true,
        is_pubilsh_button: true
      });
      toggleWarningModal(true);
      return;
    }
    const HEADERS = {
      appid: JSON.stringify({
        botid: bot_id || currentBot.bot_id,
      }),
    };
    const { isValid, error } = checkValidity();
    if (!isValid) {
      showAlert(error, "error");
      return;
    }
    setLoading(true);
  };

  const saveEnableConfig = (formikSetFieldValue: any) => {
    if (userInput.is_pubilsh_button) {
      publishBotFn();
    }
  
    setLoading(true);
  
    const botToUpdate = myBots.find((bot) => bot.bot_id === userInput.current_bot_id) || currentBot;
    const currentBotEnabled = userInput.is_pubilsh_button ? true : userInput.bot_enabled;
    
  
    ChatbotConsoleService.createUpdateBotConfiguration(botToUpdate.id as string, {
      chatbot: {
        ...botToUpdate,
        bot_enabled: currentBotEnabled,
      },
    })
      .then(() => {
        if (currentBotEnabled) {
          let botsToDisable: any[] = [];
  
          if (botToUpdate.channel === "whatsapp") {
            botsToDisable = myBots.filter(
              (b) => b.channel === "whatsapp" && b.bot_id !== botToUpdate.bot_id && b.bot_enabled
            );
          }
  
          const disablePromises = botsToDisable.map((otherBot) =>
            ChatbotConsoleService.createUpdateBotConfiguration(otherBot.id as string, {
              chatbot: { ...otherBot, bot_enabled: false },
            })
          );
  
          Promise.all(disablePromises)
            .then(() => {
              setMyBots((prevBots) =>
                prevBots.map((bot) => {
                  if (bot.bot_id === botToUpdate.bot_id) {
                    return { ...bot, bot_enabled: true };
                  } else if (bot.channel === "whatsapp" && botsToDisable.some((disabled) => disabled.bot_id === bot.bot_id)) {
                    return { ...bot, bot_enabled: false };
                  }
                  return bot;
                })
              );
  
              const currentBotIndex = myBots.findIndex((bot) => bot.bot_id === botToUpdate.bot_id);
              if (currentBotIndex !== -1) {
                formikSetFieldValue(`bots[${currentBotIndex}].bot_enabled`, true);
                updateBotLabel(botToUpdate.bot_id, true);
              }
  
              botsToDisable.forEach((disabledBot) => {
                const botIndex = myBots.findIndex((bot) => bot.bot_id === disabledBot.bot_id);
                if (botIndex !== -1) {
                  formikSetFieldValue(`bots[${botIndex}].bot_enabled`, false);
                  updateBotLabel(disabledBot.bot_id, false);
                }
              });
  
              toggleWarningModal(false);
              setLoading(false);
                
              showAlert(`${botToUpdate.name} has been successfully enabled`, "success");
  
              if (botsToDisable.length > 0) {
                setTimeout(() => {
                  const botNames = botsToDisable.map((bot) => `"${bot.name}"`).join(", ");
                  showAlert(
                    `The following WhatsApp bot have been disabled: ${botNames}, as only one WhatsApp bot can be enabled at a time.`,
                    "info"
                  );
                }, 500);
              }
            })
            .catch((error) => {
              showAlert(
                error?.response?.statusText || "Failed to disable other bots",
                "error"
              );
              toggleWarningModal(false);
              setLoading(false);
            });
        } else {
          const currentBotIndex = myBots.findIndex(
            (bot) => bot.bot_id === userInput.current_bot_id
          );
          if (currentBotIndex !== -1) {
            formikSetFieldValue(
              `bots[${currentBotIndex}].bot_enabled`, 
              false
            );
          }

          updateBotLabel(userInput.current_bot_id, false);
          
          toggleWarningModal(false);
          setLoading(false);
          showAlert(
            `${botToUpdate.name} has been successfully disabled`,
            "success"
          );
        }
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
        toggleWarningModal(false);
        setLoading(false);
  
        const originalBot = myBots.find(
          (bot) => bot.bot_id === userInput.current_bot_id
        );
        if (originalBot) {
          const botIndex = myBots.findIndex(
            (bot) => bot.bot_id === userInput.current_bot_id
          );
          if (botIndex !== -1) {
            formikSetFieldValue(
              `bots[${botIndex}].bot_enabled`,
              originalBot.bot_enabled
            );
          }
          updateBotLabel(userInput.current_bot_id, originalBot.bot_enabled);
        }
      });
  };

  const confirmToggle = (formikSetFieldValue: any) => {
    saveEnableConfig(formikSetFieldValue); 
  };
  

  const cancelModalAction = (formikSetFieldValue: any) => {
    const botId = userInput.current_bot_id;
    toggleWarningModal(false);
    
    setTimeout(() => {
      if (botId) {
        const originalBot = myBots.find(bot => bot.bot_id === botId);
        if (originalBot) {
          const botIndex = myBots.findIndex(bot => bot.bot_id === botId);
          if (botIndex !== -1) {
            formikSetFieldValue(`bots[${botIndex}].bot_enabled`, originalBot.bot_enabled);
          }
          updateBotLabel(botId, originalBot.bot_enabled);
        }
      }
      
      setUserInput({
        is_pubilsh_button: false,
        bot_enabled: false,
        current_bot_id: null,
        affectedBots: []
      });
    }, 50);
  };

  return (
    <Box sx={{ height: "inherit" }}>
      {myBots && myBots.length ? (
        <Box
          sx={{
            flexGrow: 1,
            padding: "1rem 2rem",
            height: "100%",
          }}
        >
          <Formik
            initialValues={{ bots: myBots }}
            enableReinitialize
            onSubmit={(values) => {
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <FieldArray
                  name="bots"
                  render={({ remove, push }) => (
                    <Grid
                      container
                      spacing={{ xs: 2, xl: 4 }}
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                    >
                      {values.bots && values.bots.length > 0 &&
                        values.bots.map((bot: Chatbot | any, index: number) => (
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                            <MyBotCard elevation={0}>
                              <div className="botTitleBox">
                                <Box className="avatar">
                                  <img
                                    src={
                                      bot.company_logo
                                        ? bot.company_logo
                                        : defaultAvatar
                                    }
                                    alt=""
                                  />
                                </Box>
                                <div className="botExtraBtns">
                                  <Box className="bot-timestamp">
                                    <span className="date">
                                      {moment(bot.modified_time).format("MMMM D, YYYY")}
                                    </span>
                                    <span className="time">
                                      {moment(bot.modified_time).format("h:mm A")}
                                    </span>
                                  </Box>
                                  {renderChannelIcon(bot?.channel)}
                                </div>
                              </div>
                              <div className="botInfoBox">
                                <p title={bot?.name} className="botTitle">
                                  {bot?.name}
                                  </p>
                                <p className="botSummary">{bot.description}</p>
                              </div>
                              <div className="botChannelsBox"></div>

                              <ProtectedAppUiElement moduleId="manage_bot">
                                <div
                                  className={`botActionBox ${(bot?.channel === "voice" || bot?.is_active) ? "" : "disable_buttons"}`}
                                >
                                  <Box
                                    className="actions right_border"
                                    onClick={() => handleManageBot(bot)}
                                  >
                                    <ManageBot />
                                    <p>Manage</p>
                                  </Box>
                                  <Box className="actions right_border">
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={bot.bot_enabled}
                                          onChange={(e) => {
                                            e.stopPropagation(); 
                                            const newValue = e.target.checked;
                                            setFieldValue(`bots[${index}].bot_enabled`, newValue);
                                            handleBotToggle(bot, newValue, setFieldValue, values);
                                          }}
                                          name={`bots[${index}].bot_enabled`}
                                        />
                                      }
                                      label={botLabels[bot.bot_id] || (bot.bot_enabled ? "Enabled" : "Disabled")}
                                    />
                                  </Box>
                                </div>
                              </ProtectedAppUiElement>
                            </MyBotCard>
                          </Grid>
                        ))}
                    </Grid>
                  )}
                />

                <AppModel
                  isShowing={isShowingWarningModal}
                  onClose={() => cancelModalAction(setFieldValue)}
                  BackdropProps={{
                    style: { backgroundColor: 'rgba(38, 38, 38, 0.05) !important' }, 
                    opacity: '10%',
                  }}
                  divStyle={{
                    padding: 0,
                  }}
                  PaperProps={{
                    style: {
                      boxShadow: "none",
                      width: "500px",
                      height: "250px",
                    },
                  }}
                >
                  <ModalContainer>
                    <div className="header">
                      <h4>{userInput.is_pubilsh_button ? "Publish and Enable Bot" : (!userInput.bot_enabled ? "Disable Bot" : "Enable Bot")}</h4>
                      <div className="header-right">
                        <span onClick={() => cancelModalAction(setFieldValue)}>
                          <AppMaterialIcons iconName="close" />
                        </span>
                      </div>
                    </div>
                    <div className="body">
                      {userInput.is_pubilsh_button === false ?
                        <p>{!userInput.bot_enabled ? "Disabling the bot will skip the bot flow and enable the chat with a live agent right away!" : "Enabling the bot will activate the bot flow, allowing users to interact with the chatbot before being transferred to a live agent if needed!"}</p>
                        : <p>By publishing these changes, the bot will be enabled. This will activate the bot flow, allowing users to interact with the chatbot first, before being transferred to a live agent if needed.</p>}
                      <p>Are you sure, you want to {userInput.is_pubilsh_button ? <strong>Enable Bot</strong> : (!userInput.bot_enabled ? <strong>Disable Bot</strong> : <strong>Enable Bot</strong>)}?</p>
                    </div>

                    <div className="footer">
                      <AppButton
                        variant="grey"
                        onClick={() => cancelModalAction(setFieldValue)}
                      >
                        Cancel
                      </AppButton>
                      <AppButton
                        variant={userInput.is_pubilsh_button ? "success" : (!userInput.bot_enabled ? "danger" : "success")}
                        onClick={() => confirmToggle(setFieldValue)} 
                      >
                        {userInput.is_pubilsh_button ? "Enable & Publish" : (!userInput.bot_enabled ? "Disable Bot" : "Enable Bot")}
                      </AppButton>
                    </div>
                  </ModalContainer>
                </AppModel>
              </Form>
            )}
          </Formik>
        </Box>
      ) : (
        <WelcomeBotScreen />
      )}
    </Box>
  );
};

export default Chatbots;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
    padding:0 0.8rem;
    .header-left {
      display: flex;
      gap: 0.875rem;
      }
      .text h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .text p {
        color: ${theme.palette.default.darkGrey};
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }
  .content{
    margin:10px 0;
    padding: 10px 15px; 
  }
  .body {
    padding: 0.8rem;
    height: calc(100% - 110px);
    p {
    margin-bottom: 20px;
    font-size: 16px;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
    border-top: 1px solid ${theme.palette.default.border};
    .change-template-name {
      display: flex;
      justify-content: space-between;
      align-items: start;

      p.change-name {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;