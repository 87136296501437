import styled from "styled-components";
import CallDetailItem from "../../../components/CallDetailItem";
import { getCallLogsRowData } from "../../../../../../../Store/Slices/Voicebot/voicebot.selectors";
import { useAppSelector } from "../../../../../../../Store";

const CallAnalytics = (): JSX.Element => {
  const callLogsRowData: any = useAppSelector(getCallLogsRowData);

  return (
    <Container>
      <Title>Call Analytics</Title>
      <Divider />
      <div className="content-wrapper">
        <CallDetailItem
          label="Assistant's Average Response Time"
          value="1.56 seconds"
        />
        <CallDetailItem label="Is Callee Interested" value="Yes" />
        <CallDetailItem label="Chat Language" value="English" />
        <CallDetailItem
          label="Chat Summary"
          value={callLogsRowData?.summary || " "}
        />
      </div>
    </Container>
  );
};

export default CallAnalytics;

const Container = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin: 16px 0;
  .content-wrapper {
    padding: 16px;
  }
`;

const Title = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  color: #101010;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #ddd;
  margin-bottom: 16px;
`;
