import React,{CSSProperties} from "react";
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  Table as TableInstance,
  getFilteredRowModel,
  getPaginationRowModel,
  RowData,
} from "@tanstack/react-table";
import { FixedSizeList } from "react-window";
import { useWindowSize } from "react-use";
import SortingMenu from "./components/SortingMenu";
import ShadesSkeletonLoader from "../AppComponents/Loader/ShadesSkeletonLoader";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SouthOutlinedIcon from '@mui/icons-material/SouthOutlined';
import NorthOutlinedIcon from '@mui/icons-material/NorthOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
//Draggable header imports
import {useSortable,arrayMove,SortableContext,horizontalListSortingStrategy} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {closestCenter, DndContext, type DragEndEvent, DraggableAttributes, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core"
import {restrictToHorizontalAxis} from "@dnd-kit/modifiers";
import { CellTower } from "@mui/icons-material";
import { Column,Row, Table as ReactTableInstance  } from "@tanstack/react-table";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useAppDispatch } from "../Store";
import { ContactsActions } from "../Store/Slices/Contacts/contacts.action";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../Customization/Theme";
// Keep existing table styles
const tableStyles = `
  .tableWrapper {
  width: 100%;
  overflow-x: auto;
  border: 1px solid ${theme.palette.default.white};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.divTable {
  display: inline-block;
  border-collapse: collapse;
  border-spacing: 0;
}
.thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

.thead .tr {
  height: 40px;
  background-color: ${theme.palette.default.white};
}
.tbody {
  background-color: ${theme.palette.default.white};
}
.tr {
  display: flex;
}
.th {
  border-bottom: 2px solid ${theme.palette.default.mysticLight} !important;
  color: ${theme.palette.default.fiord};
  font-size: 13px;
  font-weight: 400 !important;
}
.th,
.td {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400 !important;
  color: ${theme.palette.default.elephant};
  padding: 0.75rem;
  border-right: 1px solid ${theme.palette.default.mysticLight};
  border-bottom: 1px solid ${theme.palette.default.mysticLight};
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.th {
  font-weight: bold;
  text-align: left;
}
.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background: transparent;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  transition: background-color 0.2s ease;
}
.resizer.isResizing {
  background: ${theme.palette.default.frenchPass};
  opacity: 1;
}

/* Update the hover states */
.th:hover .resizer {
  background: ${theme.palette.default.frenchPass};
  opacity: 1;
}

/* Keep the resizer visible while resizing, regardless of hover state */
.th .resizer.isResizing {
  background: ${theme.palette.default.frenchPass};
  opacity: 1;
}
  }
.th:last-child,
.td:last-child {
  border-right: none;
}
.tr:last-child .td {
  border-bottom: none;
}
  `;

  const additionalStyles = `
  .fixed-column {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: ${theme.palette.default.white};
    border-right: 1px solid ${theme.palette.default.altoLight};
  }
  
  .divTable {
    overflow-x: auto;
  }
`;

const TableWrapper = styled("div")<{selectionStatus: any}>`
  width: 100%;
  overflow-x: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: ${({ selectionStatus }: any) => {
    switch (selectionStatus) {
      case 'all':
        return 'calc(100vh - 225px)';
      case 'moreThanOne':
        return 'calc(100vh - 195px)';
      default:
        return 'calc(100vh - 240px)';
    }
  }};
`;

  
interface TableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  enableColumnResize?: boolean;
  rowHeight?: number;
  className?: string;
  maxHeight?: number;
  rowSelection?: any;
  setRowSelection?: any;
  columnOrder?:any;
  setColumnOrder?:any;
  onSortChange?: (sortData: { id: string; desc: boolean }[]) => void; // Add this line
  selectedRow?: boolean[] | any[];
  setSelectedRow?: (row: any) => void;
  hideSelection?: boolean;
  loader?:boolean;
  loading?: any;
  setLoading?: any;
  setIsUpdating?: any;
}

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void
  }
}

const TABLE_HEADER_HEIGHT = 286;

// Memoized style vars calculation
const useColumnSizeVars = (table: any, columns: ColumnDef<any>[]) => {
  return React.useMemo(() => {
    const headers = table.getFlatHeaders();
    return headers.reduce((acc: Record<string, number>, header: any) => {
      acc[`--header-${header.id}-size`] = header.getSize();
      acc[`--col-${header.column.id}-size`] = header.column.getSize();
      return acc;
    }, {});
  }, [
    table.getState().columnSizingInfo,
    table.getState().columnSizing,
    columns,
  ]);
};

// Separate the resizer into its own memoized component
const ColumnResizer = React.memo(({ header, isDragging }: { header: any; isDragging: boolean }) => (
  <div
    onDoubleClick={() => !isDragging && header.column.resetSize()}
    onMouseDown={(e) => {
      if (!isDragging) {
        const resizeHandler = header.getResizeHandler();
        if (resizeHandler) resizeHandler(e);
      }
    }}
    onTouchStart={(e) => {
      if (!isDragging) {
        const resizeHandler = header.getResizeHandler();
        if (resizeHandler) resizeHandler(e);
      }
    }}
    className={`resizer ${header.column.getIsResizing() ? "isResizing" : ""}`}
    style={{
      pointerEvents: isDragging ? "none" : "auto", // Disables interactions when dragging
    }}
  />
));

ColumnResizer.displayName = "ColumnResizer";



// Memoized Cell Component
const TableCell = React.memo(({ cell }: any) => (
  <div
    className="td"
    style={{
      width: `calc(var(--col-${cell.column.id}-size) * 1px)`,
    }}
  >
    {flexRender(cell.column.columnDef.cell, cell.getContext())}
  </div>
));

TableCell.displayName = "TableCell";

const Table = <T extends object>({
  data,
  columns,
  enableColumnResize = true,
  rowHeight = 40,
  className = "",
  maxHeight = 400,
  onSortChange,
  setSelectedRow,
  selectedRow,
  hideSelection,
  loader,
  loading,
  setLoading,
  setIsUpdating
}: TableProps<T>) => {
  const [rowSelection, setRowSelection] = React.useState({})
  const [tableHeight, setTableHeight] = React.useState(
    () => window.innerHeight - TABLE_HEADER_HEIGHT
  );
  const getSelectionStatus = React.useCallback(() => {
    if (!selectedRow || selectedRow.length === 0) return 'none';
    if (selectedRow.length >= 1 && selectedRow.length < data.length ) return 'moreThanOne';
    if (selectedRow.length === data.length) return 'all';
    return 'some';
  }, [selectedRow, data.length]);
  const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const currentViewId = searchParams.get("view");
  const [columnOrder, setColumnOrder] = React.useState<string[]>([]);
  const [isColumnOrderChanged, setIsColumnOrderChanged] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (columns.length > 0) {
      setColumnOrder(columns.map((c) => c.id!));
    }
  }, [columns]);

  // Responsible for enabling column pinning..........
  const getCommonPinningStyles = (column: Column<any>): CSSProperties => {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn =
      isPinned === "left" && column.getIsLastColumn("left");
  
    return {
      boxShadow: isLastLeftPinnedColumn
        ? "-4px 0 4px -4px gray inset"
        : undefined,
      left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
      opacity: isPinned ? 0.95 : 5,
      position: isPinned ? "sticky" : "relative",
      width: column.getSize(),
      zIndex: isPinned ? 1 : 0,
      backgroundColor:isPinned?theme.palette.default.white:"transparent"
    };
  };

  const params = searchParams.get("page") || "1";
  const page = Number(params) - 1

  const handleColumnOrderChange = React.useCallback(async (newOrder: string[]) => {
    try {
      setIsUpdating(true);
      setIsColumnOrderChanged(true);
      const filteredOrder = newOrder.map((eachCol) => eachCol === "select" ? "id" : eachCol);
      const payload = {
        data:{columns: filteredOrder,
        view_id: currentViewId,
        page_number: page,
        page_size: 50
        }
      };
      // Dispatch the action to update column order
     await dispatch(ContactsActions.getContactsListData(payload))
      .then((res: any)=>{
          setColumnOrder(newOrder);
          setIsColumnOrderChanged(false);
          setIsUpdating(false);
      })
    } catch (error) {
      setIsColumnOrderChanged(false);
    }
  }, [dispatch, currentViewId, page]);

  React.useEffect(() => {
    if (!selectedRow && selectedRow === false) {
      setRowSelection({});
      
      if (setSelectedRow) {
        setSelectedRow([]);
      }
    }
  }, [selectedRow, setSelectedRow]);

  let selectArray: any[] = [];
 React.useEffect(() => {
       const hasData = Object?.keys(rowSelection);
       if (hasData) {
         hasData.map((number) => {
           const selected = data[parseInt(number)] as any;
           selectArray.push(selected?.id);
           return selected;
         });
       }
       setSelectedRow && setSelectedRow(selectArray);
     }, [rowSelection,columnOrder]);


 

  // Debounced resize handler
  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setTableHeight(window.innerHeight - TABLE_HEADER_HEIGHT);
      }, 150);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeoutId);
    };
  }, []);

  // Insert styles into document head on component mount
  React.useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.textContent = tableStyles;
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const table = useReactTable({
    data,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
      columnOrder
    },
    enableRowSelection: true,
    onColumnOrderChange:setColumnOrder,
    debugTable:true,
    debugColumns:true,
    debugHeaders:true,
    meta:{
      updateData:(rowIndex,columnId,value)=>{
        const selected = data[rowIndex] as any;
        selectArray.push(selected?.id);
        setSelectedRow && setSelectedRow(selectArray);
        return selectArray
      }
    },
    onRowSelectionChange: setRowSelection,
    //to hide selection checkbox pass hideSelection prop as true
      initialState: {
        columnVisibility: {
          "select": hideSelection ? false : true
        }
      }
  });
  const columnSizeVars = useColumnSizeVars(table, columns);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )

  //Draggable Header
  const DraggableHeader = React.memo(({ header, enableColumnResize, onSortChange }: any) => {
    const isNameColumn = header.column.id === 'name' || header.column.id === "select";
    const {
      attributes,
      isDragging,
      listeners,
      setNodeRef,
      transform
    } = useSortable({
      id: header.column.id,
      data: { columnOrder },
      disabled: isNameColumn
    });

    const style: CSSProperties = {
      opacity: isDragging ? 0.8 : 1,
      position: "relative",
      transform: isNameColumn ? undefined : CSS.Translate.toString(transform),
      transition: "width transform 0.2s ease-in-out",
      whiteSpace: "nowrap",
      width: header.column.getSize(),
      zIndex: isDragging ? 1 : 0,
    };

    const [contentRef, setContentRef] = React.useState<HTMLDivElement | null>(null);
  const [needsEllipsis, setNeedsEllipsis] = React.useState(false);
  
  // Check if we need ellipsis whenever relevant values change
  React.useEffect(() => {
    if (contentRef) {
      const containerWidth = contentRef.parentElement?.clientWidth || 0;
      const contentWidth = contentRef.scrollWidth;
      
      setNeedsEllipsis(contentWidth > containerWidth);
    }
  }, [contentRef, header.column.getSize(), isDragging]);

    const headerContent = React.useMemo(() => {
      const baseTextStyle = {
        whiteSpace: 'nowrap',
        width: '95%',
        display: 'block',
      };
      
      const textStyle = isNameColumn 
        ? baseTextStyle 
        : {
            ...baseTextStyle,
            ...(needsEllipsis ? {
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            } : {})
          };
    
      return (
        <div style={{ width: 'calc(100% - 24px)' }}> 
          <div 
            ref={setContentRef}
            style={textStyle}
          >
            {header.isPlaceholder 
              ? null 
              : flexRender(header.column.columnDef.header, header.getContext())}
          </div>
          {!header.isPlaceholder &&
            header.column.getCanPin() &&
            isNameColumn && (
              header.column.getIsPinned() !== "left"
                ? header.column.pin("left")
                : null
            )}
        </div>
      );
    }, [header.isPlaceholder, header.column.columnDef.header, header.getContext(), isNameColumn, needsEllipsis]);
  
    const sortState = header.column.getIsSorted();
    const getSortIcon = () => {
      if (sortState === "asc") return <NorthOutlinedIcon style={{ fontSize: "14px" }} />;
      if (sortState === "desc") return <SouthOutlinedIcon style={{ fontSize: "14px" }} />;
    };
  
    return (
      <div
        className="th"
        ref={isNameColumn ? undefined : setNodeRef}
        style={style}
        // style={{ ...getCommonPinningStyles(header.column) }} apply these style for enabling column pinning also in DragAlongCell.....
      >
        <div style={{ 
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center", 
        width: "100%",
        overflow: "hidden" // Add this to prevent overflow
      }}>
          <div style={{ 
          display: "flex", 
          alignItems: "center",
          minWidth: 0,
        }}>
            {!isNameColumn && header?.id !== "select" && (
              <DragIndicatorIcon {...attributes} {...listeners} style={{ cursor: "grab", marginRight: "8px", height: "15px" }} />
            )}
            {headerContent}
            {enableColumnResize && <ColumnResizer header={header} isDragging={isDragging} />}
          </div>
          <div style={{ 
          display: "flex", 
          gap: "1px", 
          alignItems: "center",
          marginLeft: "4px",
          flexShrink: 0 // Prevent these icons from shrinking
        }}>
            {getSortIcon()}
            {header.column.getCanSort() && (
              <SortingMenu header={header} onSortChange={onSortChange} />
            )}
          </div>
        </div>
      </div>
    );
  });
DraggableHeader.displayName = "DraggableHeader";

const DragAlongCell = ({ cell }: { cell: any }) => {
  const isNameColumn = cell.column.id === "name" || cell.column.id === "select"
  
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
    disabled: isNameColumn
  });

  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: isNameColumn ? undefined : CSS.Translate.toString(transform),
    transition: "width transform 0.2s ease-in-out",
    width: `calc(var(--col-${cell.column.id}-size) * 1px)`,
    zIndex: isDragging ? 1 : 0,
    ...(isNameColumn && { position: 'sticky', left: 0, zIndex: 1, backgroundColor: theme.palette.default.white })
  };

  return (
    // style={{ ...getCommonPinningStyles(cell.column) }}    apply these style to enable column pinning also in DraggableHeader....
    <div className={`td ${isNameColumn ? 'fixed-column' : ''}`} style = {style} ref={isNameColumn ? undefined : setNodeRef}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </div>
  );
};

  // Memoized row renderer
  const Row = React.useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const row = table.getRowModel().rows[index];
      if (!row) return null;

      return (
        <div className="tr" style={style}>
          <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
            {row.getVisibleCells().map((cell) => (
              <DragAlongCell key={cell.id} cell={cell} />
            ))}
          </SortableContext>
        </div>
      );
    },
    [table, columnOrder]
  );
  React.useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.textContent = tableStyles + additionalStyles;
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      if (active.id === 'name' || over.id === 'name' || 
        active.id === 'select' || over.id === 'select') {
      return;
    }
      
      setColumnOrder((prevOrder) => {
        const oldIndex = prevOrder.indexOf(active.id as string);
        const newIndex = prevOrder.indexOf(over.id as string);
        if (oldIndex === newIndex) return prevOrder;
        const newOrder = arrayMove(prevOrder, oldIndex, newIndex);
        handleColumnOrderChange(newOrder);
        return newOrder;
      });
  }
}
  

  const TableHeader = React.useCallback(
    ({ headerGroup, enableColumnResize }: any) => {
      if (!headerGroup) return null;

      return (
        <div className="tr">
          <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
          {headerGroup.headers.map((header: any) => (
            <DraggableHeader
              key={header.id}
              header={header}
              enableColumnResize={enableColumnResize}
              onSortChange={onSortChange}
            />
          ))}
          </SortableContext>
        </div>
      );
    },
    [columnOrder, onSortChange, enableColumnResize]
  );

  if (loading || isColumnOrderChanged) {
    return <ShadesSkeletonLoader />;
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div>
        {!loader ? (
          <TableWrapper 
          className={className}
          selectionStatus={getSelectionStatus()}
        >
            <div  style={{ ...columnSizeVars, width: table.getTotalSize() }}>
              <div className="thead">
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableHeader
                    key={headerGroup.id}
                    headerGroup={headerGroup}
                    enableColumnResize={enableColumnResize}
                  />
                ))}
              </div>
              <div className="tbody">
                <FixedSizeList
                  height={tableHeight}
                  itemCount={data.length}
                  itemSize={rowHeight}
                  width="100%"
                  useIsScrolling
                  overscanCount={5}
                >
                  {Row}
                </FixedSizeList>
              </div>
            </div>
          </TableWrapper>
        ) : (
          <ShadesSkeletonLoader />
        )}
      </div>
    </DndContext>
  );
};
export default React.memo(Table);
