import { createGlobalStyle } from "styled-components";

// Reusable function to define common disabled styles
const DEFAULT_DISABLED_FORM_FIELD_STYLES = `
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
`;

interface IStylesProps {
  $fontFamily?: string;
}

const GlobalStyles = createGlobalStyle<IStylesProps>`

//RESET CSS STARTS HERE
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Rubik", sans-serif; */
  font-family:  ${({ $fontFamily }: any) =>
    $fontFamily ? `${$fontFamily}` : "Poppins"}, Helvetica, sans-serif;

  scroll-behavior: smooth;
  /* ===== Scrollbar CSS ===== */
  ::selection {
      background: rgb(54, 84, 227);
      color: white;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #7e8392;
  }
  

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}

html {
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
}

  body {
  height: 100%;
  scroll-behavior: smooth;
}

.geta-iframe-wrapper-preview-console {
  position: absolute !important;
  top: 50%;
  transform: translate(0px, -50%);
  right: 52px !important;
  left: auto !important;
}

.geta-iframe-wrapper-support-chat {
  position: absolute !important;
  /* top: 50%;
  transform: translate(0px, -50%); */
  /* right: 52px !important; */
  right: 8px !important;
  left: auto !important;
  bottom: 8px !important;
  z-index: 99999 !important;
}

.geta-iframe-wrapper{
  /* display: none !important; */
}

#root,
#root > div {
  height: 100%;
  cursor: default;
}

#basic-menu .MuiMenu-list {
  min-width: 120px;
}

strong {
  font-weight: 600 !important;
}


fieldset {
  border: none !important;
  outline: none !important;
  display: none !important;
}

@media screen and (min-width: 1400px) {
  .arco-layout-sider {
    max-width: 300px !important;
  }
}


//------------ FORM TAG STYLES --------------//
.formikFormWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

/*

//------------ FORM DISABLED CONDITIONALLY --------------//
// do not remove important tags from here otherwise it will break other nested inputs
*/
.disableEntireForm {
  /* disable user copy and select */
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // input text
  // do not load DEFAULT_DISABLED_FORM_FIELD_STYLES or important in input tags otherwise it will break other nested inputs
  input {
    pointer-events: none ;
    cursor: not-allowed;
    opacity: 0.5; /* PLEASE DO NOT MARK THIS AS IMPORTANT OTHERWISE IT WILL BREAK OTHER NESTED INPUTS */
  }

  //textarea
  textarea {
    ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
  }

  //switch
  .MuiSwitch-root,
  .MuiSwitch-thumb {
    ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
  }

  //also switch, checkox, radio button border IDk why but mui provides this class
  .PrivateSwitchBase-input {
    display: none !important;
  }

  //checkbox
  .MuiCheckbox-root {
    ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
  }

   //radio button
   .MuiFormControlLabel-root {
    ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
  }

  //select MuiSelect-root
  .MuiInputBase-root,
  .MuiOutlinedInput-root,
  .MuiSelect-root {
    ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
    border: none !important;

    // do not remove this
    input {
    display: none !important;
  }
  }

 
  //time picker muipicker
  .input-time-picker {
    ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
  }

  //date picker muipicker
  .input-date-picker {
    ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
  }


     //remix icons
  i {
    ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
  }

  //mui icons inside icon button inside input
  .MuiIconButton-root {
    .MuiSvgIcon-root {
      ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
    }

    &:hover {
      background-color: transparent;
    }
  }

  // company logo slider sound
  .MuiStack-root {
    ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
  }


  //toggle group
  .MuiToggleButtonGroup-root {
    ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
  }

  //disable ripple effect
  .MuiTouchRipple-root {
    display: none !important;
  }

  //* PLEASE NOTE IM NOT TARGETING ALL BUTTONS HERE SO THAT BACK/NEXT BUTTONS AND OTHER BUTTONS WORKS

  //only form submit button
  .appFormSubmitButton {
    ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
  }

  //diable buttons inside form like add variable or any thing
  .buttonInsideForm {
    ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
  }

  //custom classes for custom input fields
  .customInput {
    ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
  }

  //mobile field flag selector
  .flag-dropdown {
    ${DEFAULT_DISABLED_FORM_FIELD_STYLES};
  }
}


/* Permissions Classses (start) */
.disableUIBlock {
  opacity: 0.7;
  pointer-events: none;
}
.table_row_disabled {
  opacity: 0.7;
  pointer-events: none;
}

.disable_buttons {
  opacity: 0.4;
  pointer-events: none;
}
/* Permissions Classses (end) */
`;

export default GlobalStyles;
