import styled from "styled-components";

export const StyledLookFeelContainer = styled.div`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .dynamicInputsWrapper {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
    }
    .fieldWrapper {
      min-height: calc(100vh - 330px);
      .action-select {
        max-width: 200px;
        min-width: 200px;
      }
      .label-input {
        max-width: 150px;
        min-width: 150px;
      }
    }
    h3 {
      display: flex;
      align-items: center;
      gap: 10px;
      .menuSwitch {
        max-width: 40px;
        margin: 0;
      }
    }



    .sameRowFields {
      width: 100%;
      margin-top: -12px;
      padding: 0px;
      display: flex;
      flex-direction: row;
      gap: 3rem;
      justify-content: flex-start;
    }
    .newFieldWrapper {
      width: 35%;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .customClassNameInput {
      padding-right: 4px;
      min-width: 70px;
    }

    .buttonColorBox {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      margin-bottom: 16px;
    }
  }
`;
