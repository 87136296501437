import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { automationNodeOptions } from "./defaultOptions";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { useAppSelector } from "../../Store";
interface ItemProps {
  name: string;
  dataType: string;
  path: string;
  fill: string;
  transform: string;
  icon: any;
  type?: string;
  permission?: string;
}

const Accordions = (): JSX.Element => {
  const [expanded, setExpanded] = useState<any>({
    triggers: true,
    conditions: true,
    actions: true,
  });
  const { userConfig } = useAppSelector(useAuthStore);
  return (
    <div>
      <Accordion
        expanded={expanded.triggers}
        onChange={() =>
          setExpanded({ ...expanded, triggers: !expanded.triggers })
        }
        className="triggers-wrapper"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="triggers"
          id="triggers-header"
        >
          <Typography>Triggers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {automationNodeOptions.Trigger.map(
            (trigger: ItemProps, triggerIndex: number) => {
              if (
                trigger.permission &&
                !userConfig?.payload?.allowed_permissions?.[trigger.permission]
              ) {
                return false;
              }
              return (
                <div
                  className="col-xs-4 stencil-item"
                  data-type={trigger.dataType}
                  data-trigger-type={trigger.type || "web"}
                  key={triggerIndex}
                >
                  <svg height="50" width="50">
                    <path
                      d={trigger.path}
                      fill={trigger.fill}
                      transform={trigger.transform}
                    ></path>
                  </svg>
                  <div className="stencil-icon">
                    <img src={trigger.icon} alt="" />
                  </div>
                  <p className="node-title">{trigger.name}</p>
                </div>
              );
            },
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.conditions}
        onChange={() =>
          setExpanded({ ...expanded, conditions: !expanded.conditions })
        }
        className="conditions-wrapper"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="conditions-content"
          id="conditions-header"
        >
          <Typography>Conditions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {automationNodeOptions.Condition.map(
            (condition: ItemProps, conditionIndex: number) => {
              return (
                <div
                  className="col-xs-4 stencil-item"
                  data-type={condition.dataType}
                  data-trigger-type={condition.type || "web"}
                  key={conditionIndex}
                >
                  <svg height="50" width="50">
                    <path
                      d={condition.path}
                      fill={condition.fill}
                      transform={condition.transform}
                    ></path>
                  </svg>
                  <div className="stencil-icon">
                    <img src={condition.icon} alt="" />
                  </div>
                  <p className="node-title">{condition.name}</p>
                </div>
              );
            },
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.actions}
        onChange={() =>
          setExpanded({ ...expanded, actions: !expanded.actions })
        }
        className="action-wrapper"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="actions-content"
          id="actions-header"
        >
          <Typography>Actions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {automationNodeOptions.Action.map(
            (action: ItemProps, actionIndex: number) => {
              if (
                action.permission &&
                !userConfig?.payload?.allowed_permissions?.[action.permission]
              ) {
                return false;
              }
              return (
                <div
                  className="col-xs-4 stencil-item"
                  data-type={action.dataType}
                  data-trigger-type={action.type || "web"}
                  key={actionIndex}
                >
                  <svg height="50" width="50">
                    <path
                      d={action.path}
                      fill={action.fill}
                      transform={action.transform}
                    ></path>
                  </svg>
                  <div className="stencil-icon">
                    <img src={action.icon} alt="" />
                  </div>
                  <p className="node-title">{action.name}</p>
                </div>
              );
            },
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Accordions;
