import React, { useState } from "react";
import { IconButton } from "@mui/material";

import { ReactComponent as CloseIcon } from "../../../../../../../assets/images/close.svg";

import { useParams } from "react-router-dom";
import { useNotification } from "../../../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../../../../../Store";
import { FaqSourceAction } from "../../../../../../../Store/Slices/Faq/Source/source.actions";
import AppModel from "../../../../../../../Hooks/useModel/AppModel";
import { AppForm, AppFormField, AppSubmitButton } from "../../../../../../../AppComponents/Forms";
import { WebSiteAndSitemapUrlValidations } from "../../../../../../../Models/ValidationSchema";
import AppButton from "../../../../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../../../../AppComponents/AppMaterialIcons";
import { WebsiteTableStyle } from "../styles/styles";

interface Props {
  toggleModalUpdate: () => void;
  isShowingModalUpdate: boolean;
  type: string;
  selectedRow: any;
  handleClose: any;
}
const WebsiteAndSitemapUpdateModal = (props: Props) => {
  const {
    toggleModalUpdate,
    isShowingModalUpdate,
    type,
    selectedRow,
    handleClose,
  } = props;
  const params = useParams();
  const { bot_id } = params;
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const [localValues, setLocalValues] = useState<any>({
    crawlUrl: "",
  });

  //   Add urls API Call
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    const headers = {
      appid: JSON.stringify({
        botid: bot_id,
      }),
    };
    const data = {
      limit: 10,
      offset: 0,
      HEADERS: headers,
      sourceType: type,
    };

    const payload = {
      urls: {
        url: values.crawlUrl,
        sourceId: selectedRow.sourceId,
      },
      headers: headers,
    };

    dispatch(FaqSourceAction.addOneLink(payload))
      .then((res: any) => {
        if (res?.payload?.data?.error) {
          showAlert(res?.payload?.data?.error, "error");
          SubmitProps.setSubmitting(false);
        } else {
          if (res?.payload?.data && !res?.payload?.data?.error) {
            SubmitProps.setSubmitting(false);
            // SubmitProps.resetForm();
            setLocalValues({
              crawlUrl: "",
            });
            handleClose();
            showAlert("url added", "success");
            dispatch(FaqSourceAction.GeteSourceListing(data)).then((res: any) => {
            });
            toggleModalUpdate();
          }
        }
      })
      .catch((error: any) => {
        SubmitProps.setSubmitting(false);
        showAlert("submit false", "error");
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  return (
    <AppModel
      isShowing={isShowingModalUpdate}
      onClose={() => toggleModalUpdate()}
      divStyle={{
        width: "750px",
        gap: "0.5rem",
        overflowY: "hidden",
        padding: "1rem 1rem",
      }}
    >
      <WebsiteTableStyle>
        <AppForm
          initialValues={localValues}
          validationSchema={WebSiteAndSitemapUrlValidations}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmitBtn(values, submitProps);
            setLocalValues(values);
          }}
        >
          <div className="leftside-popup">
            <div className="leftside-text">
              <h3>Update data source</h3>
              <p>
                Easily extract data from any website by entering the website's
                URL or a specific page.
              </p>
            </div>
            <div className="rightside-popup">
              <IconButton onClick={() => toggleModalUpdate()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <div className="input-field">
            <AppFormField
              label="Website URL"
              name="crawlUrl"
              placeholder="Paste URL here....."
            />
          </div>
          <div className="buttons">
            <AppButton variant="grey" onClick={() => toggleModalUpdate()}>
              cancel
            </AppButton>
            <AppSubmitButton title="Add link">
              <AppMaterialIcons iconName="ArrowForwardOutlined" />
            </AppSubmitButton>
          </div>
        </AppForm>
      </WebsiteTableStyle>
    </AppModel>
  );
};

export default WebsiteAndSitemapUpdateModal;
