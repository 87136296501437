import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";

const SkeletonWrapper = styled.div`
  width: 100%;
  padding: 10px;
  overflow-x: hidden;
  height: 100vh;
`;

const ShadesSkeletonTable = styled.div`
  width: 100%;

  .table-body {
    width: 100%;
  }

  .table-row {
    display: flex;
    align-items: center;
    padding: 8px 0;
    gap: 16px;
    width: 100%;
  }

  .col-body {
    flex: 1;
    min-width: 100px; // Default minimum width for columns

    &:first-child {
      flex: 0 0 auto;
      min-width: 50px;
      max-width: 50px;
    }
  }

  @media (max-width: 1024px) {
    .col-body {
      min-width: 90px;
    }
  }

  @media (max-width: 768px) {
    .col-body {
      min-width: 80px;

      &:first-child {
        min-width: 40px;
        max-width: 40px;
      }
    }
  }

  @media (max-width: 480px) {
    .table-row {
      gap: 8px;
    }

    .col-body {
      min-width: 60px;

      &:first-child {
        min-width: 32px;
        max-width: 32px;
      }
    }
  }
`;

const ResponsiveSkeletonBox = styled(Skeleton)`
  width: 100% !important;
  max-width: 160px;
  min-width: 60px;

  @media (max-width: 1024px) {
    max-width: 140px;
  }

  @media (max-width: 768px) {
    max-width: 120px;
    height: 12px !important;
  }

  @media (max-width: 480px) {
    max-width: 100px;
    height: 10px !important;
  }
`;

const CircleSkeletonBox = styled(Skeleton)`
  width: 50px !important;
  height: 50px !important;

  @media (max-width: 1024px) {
    width: 45px !important;
    height: 45px !important;
  }

  @media (max-width: 768px) {
    width: 40px !important;
    height: 40px !important;
  }

  @media (max-width: 480px) {
    width: 32px !important;
    height: 32px !important;
  }
`;

interface ShadesSkeletonLoaderProps {
  numberOfCol?: number;
  numberofRow?: number;
}

const ShadesSkeletonLoader: React.FC<ShadesSkeletonLoaderProps> = ({
  numberOfCol = 8,
  numberofRow = 5,
}) => {
  const [numbersOfCol, setNumbersOfCol] = useState<number[]>([]);
  const [numbersofRow, setNumbersofRow] = useState<number[]>([]);

  useEffect(() => {
    setNumbersOfCol(Array.from(Array(numberOfCol).keys()));
    setNumbersofRow(Array.from(Array(numberofRow).keys()));
  }, [numberOfCol, numberofRow]);

  const getShade = (rowIndex: number) => {
    switch (rowIndex) {
        case 0: return theme.palette.default.silverChalice;
        case 1: return theme.palette.default.silver; 
        case 2: return theme.palette.default.alto;
        case 3: return theme.palette.default.altoLight;
        default: return theme.palette.default.gallery;         
    }
  }

  return (
    <SkeletonWrapper>
      <ShadesSkeletonTable>
        <div className="table-body">
          {numbersofRow.map((rowIndex) => {
            const skeletonColor = getShade(rowIndex);
            return (
              <div className="table-row" key={rowIndex}>
                <div className="col-body">
                  <CircleSkeletonBox
                    variant="circular"
                    sx={{ bgcolor: skeletonColor }}
                  />
                </div>
                {numbersOfCol.map((colIndex) => (
                  <div className="col-body" key={`${rowIndex}-${colIndex}`}>
                    <ResponsiveSkeletonBox
                      height={15}
                      sx={{ bgcolor: skeletonColor }}
                    />
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </ShadesSkeletonTable>
    </SkeletonWrapper>
  );
};

export default ShadesSkeletonLoader;