import { Close } from "@material-ui/icons";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Backdrop, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { singleWhatsappBroadcastSchema } from "../../../Models/ValidationSchema";
import { CoreService } from "../../../Services/Apis/CoreApis";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { ContactListNetworkService } from "../../../Store/Slices/ContactList/ContactListNetworkService";
import { ContactsNetworkService } from "../../../Store/Slices/Contacts/contactsNetworkService";
import { setUserCreditsInfo } from "../../../Store/Slices/CurrentUser/CurrentUserSlice";
import { WhatsappNetworkService } from "../../../Store/Slices/Whatsapp/WhatsappNetworkService";
import { FacebookCircularProgress } from "../../../socket/appComponents/UtilsComponents/AppNoActivityScreen";
import WhatsappBroadcastResolveVaribles from "./utils/WhatsappBroadcastResolveVaribles";
import { convertFormToPayloadSingleWhatsappBroadcast } from "./utils/constants";
import { WhatsappActions } from "../../../Store/Slices/Whatsapp/whatsapp.actions";
import { whatsappDataStore } from "../../../Store/Slices/Whatsapp/whatsapp.selectors";
import { dataToTemplateFormData } from "../../Broadcast/WhatsApp/Utils/utils";
import { contactDetailsId } from "../../../Store/Slices/Contacts/contacts.selector";

interface Props {
  actionDrawerChildComponent: any;
  setActionDrawerChildComponent: (value: any) => void;
}

const WhatsappSingleBroadcast = ({
  actionDrawerChildComponent,
  setActionDrawerChildComponent,
}: Props) => {
  const dispatch = useAppDispatch();
  const formikRef = useRef<any>(null);
  const { showAlert } = useNotification();
  const [loading, setLoading] = useState<boolean>(true);

  const [basicData, setBasicInfo] = useState<any>(null);
  const [templateOptions, setTemplateOptions] = useState<any>([]);
  const [senderFieldOptions, setSenderFieldOptions] = useState<any>([]);
  const { templateByIdData }: any = useAppSelector(whatsappDataStore);
  const contactId: any = useAppSelector(contactDetailsId);

  const [userInput, setUserInput] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    sender_field_value: null,
    sender_field: "",
    template_id: null,
    resolved_header_variables: [],
    header_media: null,
    header_type: null,
    header_media_type: null,
    body_preview: "",
    resolved_body_variables: [],
    resolved_buttons_variables: [],
    footer_value: null,
    contact_id: "",
    limitedTimeOfferDate: new Date(Date.now()),
    limitedTimeOfferTime: new Date(Date.now()),
    whatsapp_subscription_status: "Yes",
  });

  const getTemplateData = async (value: any) => {
    if (!value?.id) return;
    setLoading(true);
    dispatch(WhatsappActions.getTemplateById(value?.id)).finally(() => {
      setLoading(false);
    });
  };
  async function fetchData() {
    if (!templateByIdData) return;
    const data = {
      ...(await dataToTemplateFormData(templateByIdData?.original_response)),
      components: templateByIdData?.original_response?.components,
    };
    formikRef?.current?.setFieldValue("template", data);
  }
  useEffect(() => {
    fetchData();
  }, [templateByIdData]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleContactId = contactId ? contactId : actionDrawerChildComponent?.id;
  const getIntialData = () => {
    let payload = {
      contact_id: handleContactId,
    };

    Promise.allSettled([
      ContactListNetworkService.getContactPropertiesList({}),
      ContactsNetworkService.getBasicInfo(payload),
      WhatsappNetworkService.getWhatsappTemplatesListings({
        limit: 9999,
        offset: 0,
        status: "APPROVED",
      }),
    ])
      .then((results) => {
        const [allContactPropertiesResult, basicInfoResult, templatesResult] =
          results;

        if (
          basicInfoResult?.status === "fulfilled" &&
          allContactPropertiesResult?.status === "fulfilled" &&
          templatesResult?.status === "fulfilled"
        ) {
          const basicData = basicInfoResult?.value?.data ?? null;
          const templatesData = templatesResult?.value?.data?.results ?? [];
          const allContactPropertiesData =
            allContactPropertiesResult?.value?.data?.data ?? null;

          setBasicInfo(basicData);

          const senderFields =
            allContactPropertiesData?.filter(
              (item: any) => item?.type === "phone"
            ) ?? [];
          setSenderFieldOptions(senderFields ?? []);
          setTemplateOptions(templatesData ?? []);
          setUserInput({
            ...userInput,
            contact_id: handleContactId,
            first_name: basicData?.general_information?.first_name ?? "",
            last_name: basicData?.general_information?.last_name ?? "",
            email: basicData?.general_information?.email ?? "",
            mobile: basicData?.general_information?.mobile_phone_number ?? "",
            whatsapp_subscription_status:
              basicData?.general_information?.whatsapp_subscription_status ??
              "",
            sender_field_value:
              basicData?.general_information?.mobile_phone_number ?? "",
            sender_field:
              senderFields?.find(
                (item: any) => item?.name === "mobile_phone_number"
              ) ?? "",
          });
        } else {
          showAlert("Enabled to fetch Data", "error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (handleContactId) {
      getIntialData();
    }
  }, [handleContactId]); // eslint-disable-line

  const handleSubmitBtn = (values: any, submitProps: any) => {
    setLoading(true);
    const valuesObj = {
      ...values,
      components: values?.template?.components,
    }; 
    const payload = convertFormToPayloadSingleWhatsappBroadcast(valuesObj);
    WhatsappNetworkService.sendWhatsappMessage(payload)
      .then((res: any) => {
        showAlert(res?.data?.message || "Message sent!", "success");
        submitProps?.resetForm();
        setActionDrawerChildComponent(null);
        CoreService.getCredits().then((response: any) => {
          const reversedItems = response?.data;
          dispatch(setUserCreditsInfo(reversedItems));
        });
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Message sending failed",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
        submitProps.setSubmitting(false);
      });
  };

  const onSenderFieldChange = (value: any) => {
    if (!formikRef.current) return;

    //TODO GET DYNAMIC FIELD VALUE FROM CONTACT
    //API NOT READY FOR GETTING 1 FIELD VALUE OF SINGLE CONTACT
    //THEREFORE SETTING STATIC VALUE FOR MOBILE VALUE
    const dynamicValue = basicData?.general_information?.[value?.name] ?? "";
    formikRef.current?.setFieldValue("sender_field_value", dynamicValue ?? "");
  };

  return (
    <>
      {loading ? (
        <Backdrop open={loading} style={{ zIndex: 9999999 }}>
          <FacebookCircularProgress />
        </Backdrop>
      ) : null}
      <StyledDrawerHeader>
        <p className="title">
          <WhatsAppIcon /> Send Whatsapp Message
        </p>
        <IconButton
          onClick={(e: any) => setActionDrawerChildComponent(null)}
          title="close"
        >
          <Close />
        </IconButton>
      </StyledDrawerHeader>
      <AppForm
        innerRef={formikRef}
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={singleWhatsappBroadcastSchema}
        divStyle={{
          flex: 1,
        }}
      >
        <StyledDrawerWrapper>
          <div className="basicInfoBox">
            <p className="row">
              <span className="label">First Name:</span>
              <span className="value">{userInput?.first_name}</span>
            </p>
            <p className="row">
              <span className="label">Last Name:</span>
              <span className="value">{userInput?.last_name}</span>
            </p>
            <p className="row">
              <span className="label">Mobile:</span>
              <span className="value">{userInput?.mobile}</span>
            </p>
            <p className="row">
              <span className="label">Email:</span>
              <span className="value">{userInput?.email}</span>
            </p>
          </div>
          {userInput?.whatsapp_subscription_status === "Yes" ? (
            <>
              {" "}
              <div className="inlineFields">
                <AppReactSelectField
                  menuPosition="fixed"
                  label={"Sender Field"}
                  options={senderFieldOptions ?? []}
                  valueKey={"id"}
                  displayKey="label"
                  name={"sender_field"}
                  placeholder={`Select Field`}
                  isClearable={true}
                  requiredSign={true}
                  isLoading={loading}
                  onChangeCallbackFn={(value: any) => {
                    onSenderFieldChange(value);
                  }}
                  resetDependentFieldName={["template_id"]}
                  isDisabled={true}
                />
                <AppFormField
                  name="sender_field_value"
                  placeholder="Sender field value"
                  label="Sender field value"
                  disabled={true}
                  requiredSign={true}
                />
              </div>
              <AppReactSelectField
                menuPosition="fixed"
                label={"Whatsapp Template"}
                options={templateOptions ?? []}
                valueKey={"id"}
                displayKey="label"
                name={"template_id"}
                placeholder={`Select template`}
                isClearable={true}
                requiredSign={true}
                isLoading={loading}
                onChangeCallbackFn={(value: any) => {
                  getTemplateData(value);
                }}
                resetDependentFieldName={[
                  "resolved_header_variables",
                  "header_media",
                  "header_type",
                  "header_media_type",
                  "body_preview",
                  "resolved_body_variables",
                  "resolved_buttons_variables",
                  "footer_value",
                ]}
              />
              <WhatsappBroadcastResolveVaribles />
            </>
          ) : (
            <Message>
              <p>This user has opt-out for communication</p>
            </Message>
          )}
        </StyledDrawerWrapper>
        <StyledDrawerFooter>
          <AppButton
            variant="grey"
            onClick={(e: any) => setActionDrawerChildComponent(null)}
          >
            Cancel
          </AppButton>
          {userInput?.whatsapp_subscription_status === "Yes" ? (
            <AppSubmitButton
              title="Send"
              checkIsValidProperty={false}
            ></AppSubmitButton>
          ) : null}
        </StyledDrawerFooter>
      </AppForm>
    </>
  );
};

export default WhatsappSingleBroadcast;

const StyledDrawerWrapper = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    flex: 1;
    padding: 1rem 2rem;
    max-height: calc(100vh - 128px);
    overflow-y: auto;
    overflow-x: hidden;
    .inlineFields {
      display: flex;
      gap: 1rem;
    }
    .basicInfoBox {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0rem 0 1rem;
      .row {
        display: flex;
        gap: 1rem;
        align-items: center;
        .label {
          font-weight: 500;
          font-size: 15px;
        }
        .value {
          font-weight: 400;
          font-size: 15px;
        }
      }
    }
  }
`;

const StyledDrawerFooter = styled.div`
  && {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
    padding: 1rem 2rem;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    .title {
      font-size: 18px;
      font-weight: 500;
    }
  }
`;
const Message = styled.div`
  position: absolute;
  bottom: 67px;
  background: #fff2d6;
  width: 100%;
  padding: 11px;
  left: 0px;
  color: black;
  font-weight: 400;
`;
const StyledDrawerHeader = styled.div`
  && {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    .title {
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;

      svg {
        font-size: 21px;
        margin-right: 8px;
      }
    }
  }
`;
