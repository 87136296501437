import React, { useState, useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import { EditorState, Modifier } from "draft-js";
import { ReactComponent as ArrowDropDownIcon } from "../../../../../../assets/images/icons/custom-select.svg";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../../../Store";
import { getApiListData, getContactPropertyData } from "../../../../../../Modules/FlowBuilder/Slices/main.slice";
import { transformApiAndContactOptions } from "../../../../../../Modules/FlowBuilder/Components/Editor/utils/common";
import { getApiList, getContactPropertyList } from "../../../../../../Modules/FlowBuilder/Slices/main.service";
import SelectPopover from "../../../../../../Modules/FlowBuilder/Components/SelectPopover";

const IconDropdown: React.FC<any> = ({ editorState, onChange }) => {
  const dispatch = useAppDispatch();

  // Get contact properties and API list from the Redux store
  const contactProperties = useSelector(getContactPropertyData);
  const apiList = useSelector(getApiListData);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentOptions, setCurrentOptions] = useState<any[]>([]); // Single array of options

  const [searchTerm, setSearchTerm] = useState("");

  // Utility function to transform API and contact data into options


  const setApiAndContactOptions = () => {
    const { apiOptions, contactOptions } = transformApiAndContactOptions(
      apiList,
      contactProperties
    );
    setCurrentOptions([
      {
        label: "Contact Properties",
        value: "property",
        options: contactOptions,
      },
      {
        label: "API's",
        value: "api",
        options: apiOptions,
      },
    ]);
  };
  useEffect(() => {
    // Call the utility function and update currentOptions state
    setApiAndContactOptions();
  }, [apiList, contactProperties]);

  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setApiAndContactOptions();
    setAnchorEl(null);
    setSearchTerm("");
  };

  const handleOptionClick = async (option: any) => {
    if (option.options) {
      setCurrentOptions(option.options); // Navigate into nested options
      setSearchTerm("");
    } else {
      await handleValueInsertion(option.value); // Insert value if it's a leaf option
      handleClose();
    }
  };

  const handleBackClick = () => {
    // Navigate back to parent options (reset to root for simplicity)
    setApiAndContactOptions();
    setSearchTerm("");
  };

  const handleValueInsertion = async (value: string) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      value,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  const isMenuOpen = Boolean(anchorEl);
  useEffect(() => {
    dispatch(getContactPropertyList());
    dispatch(getApiList());
  }, []);
  return (
    <Box
      sx={{
        position: "relative",
        "&:hover > div": {
          display: "flex",
          opacity: 1,
        },
      }}
    >
      <IconButton
        sx={{ p: "2px !important" }}
        size="small"
        onClick={handleIconClick}
      >
        <ArrowDropDownIcon />
      </IconButton>

      <SelectPopover
        anchorEl={anchorEl}
        isOpen={isMenuOpen}
        optionsStack={currentOptions} // Pass the filtered options
        searchTerm={searchTerm}
        onClose={handleClose}
        onSearchChange={setSearchTerm}
        onOptionClick={handleOptionClick}
        onBackClick={handleBackClick} // Handle back navigation
      />
    </Box>
  );
};

export default IconDropdown;
