import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VoiceBotActions } from "./voicebot.actions";

interface initialStateTypes {
  botDetails: any;
  voiceList: any;
  pagination: any;
  error: any;
  loading: boolean;
  callLogsRowData: any;
  voice :any
}

const initialState: initialStateTypes = {
  botDetails: null,
  voiceList: null,
  pagination: null,
  error: "",
  loading: false,
  callLogsRowData: null,
  voice : {}
};
const voiceBotSlice = createSlice({
  name: "VoiceBotSlice",
  initialState,
  reducers: {
    setCallLogsRowData: (state, { payload }: PayloadAction<any>) => {
      state.callLogsRowData = payload;
    },
    setVoice: (state, { payload }: PayloadAction<any>) => {
      state.voice = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //  get Webhook
      // .addCase(WebhookActions.getWebhookAction.pending, (state, action) => {
      //   state.error = "";
      //   state.loading = true;
      // })
      // .addCase(WebhookActions.getWebhookAction.fulfilled, (state, action) => {
      //   state.getWebhook = action?.payload?.data?.data;
      // })
      // .addCase(WebhookActions.getWebhookAction.rejected, (state, error: any) => {
      //   state.getWebhook = null;
      //   state.error = error?.data?.error;
      // })

      //  Generate API Key
      .addCase(VoiceBotActions.getBotDeatils.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(VoiceBotActions.getBotDeatils.fulfilled, (state, action) => {
        state.botDetails = action?.payload?.data;
      })
      .addCase(VoiceBotActions.getBotDeatils.rejected, (state, error: any) => {
        state.error = error?.data?.error;
        state.botDetails = {};
      })

      //get voices list
      .addCase(VoiceBotActions.getVoicesList.pending, (state, action: any) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        VoiceBotActions.getVoicesList.fulfilled,
        (state, action: any) => {
          state.voiceList = action?.payload?.data?.data;
          state.pagination = action?.payload?.data?.pagination;
        }
      )
      .addCase(VoiceBotActions.getVoicesList.rejected, (state, error: any) => {
        state.error = error?.data?.error;
        state.voiceList = null;
        state.pagination = null;
      });
  },
});
export const { setCallLogsRowData,setVoice } = voiceBotSlice.actions;
export default voiceBotSlice.reducer;
