import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Menu,
  MenuItem,
  Typography,
  Box,
  IconButton,
  FormControl,
  Select,
  FormHelperText,
  ListItem,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ImageIcon from "@mui/icons-material/Image";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { TableFilter, TableWrapper } from "../../TableStyle";
import * as Yup from "yup";
import Uploadfile from "./Uploadfilemodel";
import SearchIcon from "@mui/icons-material/Search";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { RootState, useAppDispatch } from "../../Store";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import { filemanagerAction } from "../../Store/Slices/filemanagers/fileManagerAction";
import NoRecordsFound from "../UiComponents/NoRecordsFound/NoRecordsFound";
import { UsersContainer } from "../Settings/UsersAndPermissions/RolesPermissions/RolesPermissionsStyle";
import { DeleteCannedResponse } from "../FormBuilder";
import AppModel from "../../Hooks/useModel/AppModel";
import { StyledAppInput } from "../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import { theme } from "../../Customization/Theme";
import styled from "styled-components";
import { ProtectedAppButton } from "../../AppComponents/ProtectedComponents";
import DragDropUpload from "../../AppComponents/Forms/AppUploadFiles";
import useModal from "../../Hooks/useModel/useModel";
import { ReactComponent as TagCrossIcon } from "../../assets/images/tag-cross-icon.svg";
import { ReactComponent as FrameIcon } from "../../assets/images/frame-icon.svg";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
interface FileData {
  id: string;
  name: string;
  file_size: number;
  created_time: string;
  content_type?: string;
  source?: string;
}

interface PaginationData {
  currentPage: number;
  perPage: number;
  totalPages: number;
  totalRecords: number;
}
interface Props extends LoaderComponentProps { }

const BoardUploadFile = (props: Props) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [folderName, setFolderName] = useState("");
  const [open, setOpen] = useState(false);
  const [boardName, setBoardName] = useState("");
  const [error, setError] = useState("");
  const [files, setFiles] = useState<FileData[]>([]);
  const [loading, setLoading] = useState(true);
  const [loader, setLoadingLoader] = useState<boolean>(false);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [selectedFile, setSelectedFile] = useState<FileData | null>(null);
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const [pagination, setPagination] = useState<any>();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [resetFiles, setResetFiles] = useState<any>(false);

  useEffect(() => {
    if (location.state?.folderName) {
      setFolderName(location.state.folderName);
    }
  }, [id, location.state]);
  const [localValues, setLocalValues] = useState<any>({
    search: "",
    itemsPerPage: { label: "10  per page", value: 10 },
    currentPage: 0,
    totalPages: 0,
  });
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (event: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: event,
      currentPage: 0,
    });
  };
  const fetchFiles = () => {
    if (!id) return;
    setLoading(true);
    let payload: any = {
      id,
      limit: localValues?.itemsPerPage?.value,
      offset: localValues?.search.length > 0 ? 0 : localValues.currentPage,
      search: localValues?.search || searchQuery,
    };
    dispatch(filemanagerAction.GetFiles(payload))
      .then((res: any) => {
        if (res.payload?.data) {
          setFiles(res.payload.data.data || []);
          setPagination(res?.payload?.data?.pagination);
          props.setLoading(false);
        }
      })
      .catch((error: any) => {
        console.error("Error fetching files:", error);
      })
      .finally(() => {
        setLoading(false);
        props.setLoading(false);
      });
  };
  useEffect(() => {
    const delaySearch = setTimeout(() => {
      fetchFiles();
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [searchQuery, localValues.currentPage, localValues.itemsPerPage]);
  const formatFileSize = (bytes = 0) => {
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = bytes > 0 ? Math.floor(Math.log(bytes) / Math.log(k)) : 0;
    return `${(bytes / Math.pow(k, i)).toFixed(2)} ${sizes[i]}`;
  };
  const handleActionMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    file: FileData
  ) => {
    setActionMenuAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };
  const handleActionMenuClose = () => {
    setActionMenuAnchorEl(null);
    setSelectedFile(null);
  };

  const handleDownload = async () => {
    if (!selectedFile) return;
    try {
      props.setLoading(true);
      dispatch(filemanagerAction.DownloadFile({ id: selectedFile.id })).then(
        (res: any) => {
          const fileUrl = res?.payload?.data?.uri;
          if (fileUrl) {
            window.open(fileUrl, "_blank");
            showAlert("File downloaded successfully", "success");
            props.setLoading(false);
          } else {
            showAlert("File download failed", "error");
            props.setLoading(false);
          }
        }
      );
    } catch (error) {
      console.error("Error downloading file:", error);
    }
    handleActionMenuClose();
  };
  const handleCopy = async () => {
    if (!selectedFile) return;
    try {
      props.setLoading(true);
      dispatch(filemanagerAction.DownloadFile({ id: selectedFile.id })).then(
        (res: any) => {
          const fileUrl = res?.payload?.data?.uri;
          if (fileUrl) {
            navigator.clipboard.writeText(fileUrl);
            showAlert("File URL copied to clipboard", "success");
            props.setLoading(false);
          } else {
            showAlert("Failed to copy file URL", "error");
            props.setLoading(false);
          }
        }
      );
    } catch (error) {
      console.error("Error copying file URL:", error);
    }
    handleActionMenuClose();
  };
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<FileData | null>(null);
  const openDeleteModal = () => {
    if (selectedFile) {
      setFileToDelete(selectedFile);
      setDeleteModalOpen(true);
      handleActionMenuClose();
    }
  };
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setFileToDelete(null);
  };
  const handleDelete = () => {
    openDeleteModal();
  };
  const handleDeleteFile = () => {
    if (!fileToDelete) return;
    props.setLoading(true);
    const payload = {
      folder_id: id,
      file_id: fileToDelete.id,
    };
    dispatch(filemanagerAction.deletefileaction(payload))
      .then((res: any) => {
        if (res?.payload) {
          showAlert("File deleted successfully", "success");
          fetchFiles();
          props.setLoading(false);
        } else {
          showAlert("Failed to delete file", "error");
        }
      })
      .catch(() => {
        showAlert("Error deleting file", "error");
      })
      .finally(() => {
        props.setLoading(false);
        closeDeleteModal();
      });
  };
  const getFileIcon = (file: FileData) => {
    if (file?.content_type?.includes("image"))
      return <ImageIcon sx={{ color: "#5B73E8" }} />;
    if (file?.content_type?.includes("video"))
      return <VideoFileIcon sx={{ color: "#5B73E8" }} />;
    return <InsertDriveFileIcon sx={{ color: "#5B73E8" }} />;
  };
  const formNameValidationSchema = Yup.object().shape({
    boardName: Yup.string().trim().required("Board Name is required"),
  });
  const handleSave = () => {
    // console.log("Saving")
    if (!selectedFiles || selectedFiles.length === 0) {
      showAlert("No file selected!", "error");
      return;
    }
    let formData: any = new FormData();
    selectedFiles.forEach((file: any) => {
      formData.append("file[]", file);
    });
    formData.append("folder", folderName);
    setResetFiles(true);
    props.setLoading(true);
    dispatch(filemanagerAction.UploadfilesAction(formData))
      .then((res: any) => {
        if (res?.payload?.data?.error) {
          showAlert(res?.payload?.data?.error, "error");
          props.setLoading(false);
          return;
        }
        if (res.payload?.status === 200) {
          fetchFiles();
          showAlert("File uploaded successfully", "success");
          toggleModal2();
          props.setLoading(false);
        }
      })
      .catch((err: any) => {
        showAlert(err, err);
      })
      .finally(() => {
        toggleModal2();
        setSelectedFiles([]);
        props.setLoading(false);
      });
    // showAlert("File uploaded successfully", "success");
    setError("");
    setBoardName("");
    handleClose();
  };
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const [fileToTransfer, setFileToTransfer] = useState<FileData | null>(null);
  const [folderList, setFolderList] = useState<any[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<string>("");
  const [loadingFolders, setLoadingFolders] = useState(false);
  const [folderError, setFolderError] = useState("");
  const openTransferModal = () => {
    if (selectedFile) {
      setFileToTransfer(selectedFile);
      setTransferModalOpen(true);
      fetchFolders();
      handleActionMenuClose();
    }
  };
  const closeTransferModal = () => {
    setTransferModalOpen(false);
    setFileToTransfer(null);
    setSelectedFolder("");
    setFolderError("");
  };
  const handleTransfer = () => {
    openTransferModal();
  };
  const fetchFolders = () => {
    setLoadingFolders(true);
    const payload = {
      limit:1000,
      offset:0
    };
    dispatch(filemanagerAction.GetFolderListing(payload))
      .then((res: any) => {
        if (res.payload?.data?.data && Array.isArray(res.payload.data.data)) {
          const mappedFolders = res.payload.data.data.map((folder: any) => ({
            ...folder,
            label:
              folder.label || folder.name || folder.title || "Unnamed Folder",
          }));
          setFolderList(mappedFolders);
        } else {
          setFolderList([]);
        }
      })
      .catch((error: any) => {
        showAlert("Error fetching folders", "error");
      })
      .finally(() => {
        setLoadingFolders(false);
      });
  };
  const handleMoveFile = () => {
    if (!fileToTransfer || !selectedFolder) {
      setFolderError("Please select a destination folder");
      return;
    }
    const destinationFolder = folderList.find(
      (folder) => folder.label === selectedFolder
    );
    if (!destinationFolder) {
      setFolderError("Invalid destination folder");
      return;
    }
    const data = {
      source_folder_id: id,
      destination_folder_id: destinationFolder.id,
      file_id: fileToTransfer.id,
    };
    dispatch(filemanagerAction.MoveFile(data))
      .then((res: any) => {
        if (res?.payload?.status === 200) {
          showAlert("File moved successfully", "success");
          fetchFiles();
        } else {
          showAlert("Failed to move file", "error");
        }
      })
      .catch((error: any) => {
        showAlert("Error moving file", "error");
      })
      .finally(() => {
        closeTransferModal();
      });
  };
  const filess = files.filter((file) =>
    file.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const useModal2 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal2: isShowing, toggleModal2: toggle };
  };
  const { isShowingModal2, toggleModal2 } = useModal2();
  const headers = ["File Name", "File Size", "Created", "Action"];
  return (
    <FileManagerWrap>
      <div className="topmenu">
        <Box
          sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          onClick={() => navigate(-1)}
        >
          <AppMaterialIcons
            iconName={"ArrowBackIosOutlined"}
            style={{ fontSize: "18px", display: "flex" }}
          />
          <div className="menu-left">{folderName || "Board Name"}</div>
        </Box>
        <div className="menu-right">
          <div className="faq-search" style={{ marginRight: "1rem" }}>
            <StyledAppInput>
              <input
                type="text"
                name="query"
                id="query"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <SearchIcon />
            </StyledAppInput>
          </div>
          <ProtectedAppButton
            variant="primarydark"
            moduleId="file_manager"
            specificPermissionKey="create"
            style={{ width: "110px" }}
            title={"Upload File"}
            startIcon="AppMaterialIcons"
            onClick={() => toggleModal2()}
          >
            {" "}
            <AppMaterialIcons iconName="Add" />
            Upload File
          </ProtectedAppButton>
        </div>
      </div>
      <Box sx={{ mt: 4, px: 3 }}>
        <UsersContainer>
          <TableWrapper
            sx={{
              height: "calc(101vh - 279px)",
              overflowX: "auto",
              width: "100%",
              minWidth: "600px",
              "@media (max-width: 768px)": {
                minWidth: "100%",
                height: "auto",
              },
            }}
          >
            <div>
              <Box>
                <Box
                  sx={{
                    maxHeight: "47px",
                    flex: "none",
                    display: "flex",
                    wordBreak: "break-all",
                    alignItems: "center",
                    textAlign: "center",
                    fontWeight: 500,
                    borderBottom: "1px solid rgba(126, 131, 146, 0.5)",
                    borderTop: "1px solid rgba(126, 131, 146, 0.5)",
                    padding: "13px 20px",
                    gap: "509px",
                    minWidth: "145%",
                    backgroundColor: theme.palette.default.white,
                    "& p": {
                      fontSize: "15px",
                      fontWeight: 5,
                    },
                    "&:first-child p": {},
                  }}
                >
                  {headers.map((header, index) => (
                    <Box
                      key={index}
                      className={`col-head ${header
                        .toLowerCase()
                        .replaceAll(" ", "_")}`}
                    >
                      <Typography>{header}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box
                className="table-body"
                sx={{ minHeight: "calc(100vh - 200px)" }}
              >
                {loading ? (
                  <Box className="table-row">
                    <Box
                      className="col-body file_name"
                      sx={{
                        gridColumn: "span 4",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography>Loading files...</Typography>
                    </Box>
                  </Box>
                ) : filess.length === 0 ? (
                  <NoRecordsFound height="calc(101vh - 279px)" />
                ) : (
                  filess.map((file, rowIndex) => (
                    <Box key={rowIndex} className="table-row">
                      <Box
                        className="col-body file_name"
                        sx={{ justifyContent: "flex-start" }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          {getFileIcon(file)}
                          <Typography>{file.name}</Typography>
                        </Box>
                      </Box>
                      <Box className="col-body file_size">
                        <Typography>
                          {formatFileSize(file.file_size)}
                        </Typography>
                      </Box>
                      <Box
                        className="col-body created"
                        sx={{ justifyContent: "flex-start" }}
                      >
                        <Typography>{file.created_time}</Typography>
                      </Box>
                      <Box sx={{ justifyContent: "flex-end" }}>
                        <IconButton
                          onClick={(e) => handleActionMenuOpen(e, file)}
                        >
                          <AppMaterialIcons iconName="MoreVert" />
                        </IconButton>
                      </Box>
                    </Box>
                  ))
                )}
              </Box>
            </div>
          </TableWrapper>
        </UsersContainer>
        <div className="footer-pagination">
          {!loading && files.length > 0 ? (
            <AppPaginationComponent
              totalCount={pagination?.totalRecords}
              handlePageChange={handlePageChange}
              currentPage={localValues?.currentPage}
              totalPages={pagination?.totalPages}
              rowsPerPage={localValues?.itemsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              title="record"
            />
          ) : null}
        </div>
        <Box
          display="flex"
          sx={{
            maxWidth: "150px",
            minWidth: "150px",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            backgroundColor: "white",
          }}
          className="actionColumn"
        >
          <Menu
            id="file-action-menu"
            anchorEl={actionMenuAnchorEl}
            keepMounted
            open={Boolean(actionMenuAnchorEl)}
            onClose={handleActionMenuClose}
          >
            <ListItem
              sx={{
                color: theme.palette.default.darkGrey,
                cursor: "pointer",
              }}
              onClick={handleDownload}
            >
              <DownloadIcon fontSize="small" sx={{ mr: 1 }} /> Download
            </ListItem>
            <ListItem
              sx={{
                color: theme.palette.default.darkGrey,
                cursor: "pointer",
              }}
              onClick={handleCopy}
            >
              <ContentCopyIcon fontSize="small" sx={{ mr: 1 }} /> Copy Link
            </ListItem>
            <ListItem
              sx={{
                color: theme.palette.default.darkGrey,
                cursor: "pointer",
              }}
              onClick={handleTransfer}
            >
              <DriveFileMoveIcon fontSize="small" sx={{ mr: 1 }} /> Move
            </ListItem>
            <ListItem
              sx={{
                color: theme.palette.default.darkGrey,
                cursor: "pointer",
              }}
              onClick={handleDelete}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                <span>Delete</span>
              </Box>{" "}
            </ListItem>
          </Menu>
        </Box>
      </Box>
      {/* Delete Model */}
      <AppModel
        isShowing={deleteModalOpen}
        onClose={closeDeleteModal}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            width: "450px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <DeleteCannedResponse>
          <div className="header">
            <div className="header-left">
              <div className="danger"></div>
              <div className="text">
                <h4>Delete File</h4>
                <p>Are you sure, you want to delete?</p>
              </div>
            </div>

            <div className="header-right" onClick={closeDeleteModal}>
              <AppMaterialIcons iconName="close" />
            </div>
          </div>
          <div className="footer">
            <AppButton variant="grey" onClick={closeDeleteModal}>
              Cancel
            </AppButton>
            <ProtectedAppButton
              variant="danger-filled"
              moduleId="file_manager"
              specificPermissionKey="delete"
              title={"Delete file"}
              startIcon="AppMaterialIcons"
              onClick={handleDeleteFile}
            >
              {" "}
              <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              Delete
            </ProtectedAppButton>
          </div>
        </DeleteCannedResponse>
      </AppModel>
      {/* Transfer File or Move Model */}
      <AppModel
        isShowing={transferModalOpen}
        onClose={closeTransferModal}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            width: "450px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <DeleteCannedResponse>
          <div className="header">
            <div className="header-left">
              <div className="icon">
                <DriveFileMoveIcon />
              </div>
              <div className="text">
                <h4>Move File</h4>
              </div>
            </div>

            <div className="header-right" onClick={closeTransferModal}>
              <AppMaterialIcons iconName="close" />
            </div>
          </div>
          <div className="content" style={{ padding: "15px" }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                File:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 1,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                }}
              >
                <Box
                  component="span"
                  sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
                >
                  {fileToTransfer?.content_type?.includes("image") ? (
                    <ImageIcon sx={{ color: "#5B73E8" }} />
                  ) : fileToTransfer?.content_type?.includes("video") ? (
                    <VideoFileIcon sx={{ color: "#5B73E8" }} />
                  ) : (
                    <InsertDriveFileIcon sx={{ color: "#5B73E8" }} />
                  )}
                </Box>
                <Typography variant="body2">{fileToTransfer?.name}</Typography>
              </Box>
            </Box>
            <FormControl fullWidth error={!!folderError}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Destination:
              </Typography>
              <Select
                value={selectedFolder}
                onChange={(e) => {
                  setSelectedFolder(e.target.value);
                  setFolderError("");
                }}
                displayEmpty
                fullWidth
                sx={{ height: "45px" }}
                disabled={loadingFolders}
              >
                <MenuItem value="" disabled>
                  {loadingFolders
                    ? "Loading folders..."
                    : "Select destination folder"}
                </MenuItem>
                {folderList.map((folder) => (
                  <MenuItem key={folder.label} value={folder.label}>
                    {folder.label}
                  </MenuItem>
                ))}
              </Select>
              {folderError && <FormHelperText>{folderError}</FormHelperText>}
            </FormControl>
          </div>

          <div className="footer">
            <AppButton variant="grey" onClick={closeTransferModal}>
              Cancel
            </AppButton>
            <ProtectedAppButton
              variant="primary"
              moduleId="file_manager"
              specificPermissionKey="update"
              title={"Transfer File"}
              startIcon="AppMaterialIcons"
              onClick={handleMoveFile}
              disabled={!selectedFolder || loadingFolders}
              buttonStyle={{ marginRight: "5px", padding: "14px 10px" }}
            >
              Transfer File
            </ProtectedAppButton>
          </div>
        </DeleteCannedResponse>
      </AppModel>

      <AppModel
        isShowing={isShowingModal2}
        onClose={() => toggleModal2()}
        divStyle={{
          width: "460px",
          height: "416px",
          // gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <ModalWrapper>
          <ModalHeader>
            <div className="mark-complete-text-class">Add file</div>
            <div className="popup-heading-icon">
              <IconButton onClick={() => toggleModal2()}>
                <TagCrossIcon />
              </IconButton>
            </div>
          </ModalHeader>
          <DragDropUpload
            sendToParent={setSelectedFiles}
            resetFiles={resetFiles}
            setResetFiles={setResetFiles}
            multiple={false}
          />
          <div className="cancle-save-buttons">
            <AppButton onClick={() => toggleModal2()} variant="grey">
              cancel
            </AppButton>
            <AppButton
              onClick={() => {
                handleSave();
              }}
            >
              <IconButton>
                <FrameIcon />
              </IconButton>
              save
            </AppButton>
          </div>
        </ModalWrapper>
      </AppModel>
      {/* <Uploadfile
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        boardName={boardName}
        setBoardName={setBoardName}
        error={error}
        fetchFiles={fetchFiles}
      /> */}
    </FileManagerWrap>
  );
};
export default withLoader(BoardUploadFile);
const FileManagerWrap = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  height: 100%;

  .topmenu {
    padding: 18px 22px;
    background: ${theme.palette.default.white};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 61px;
  }

  .menu-left {
    display: flex;
    align-items: center;
    gap: 2rem;
    font-weight: 500;
    color: ${theme.palette.default.black};
    font-size: 20px;
  }

  .custom-btn {
    padding: 0;
  }

  .left-menu {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .left-part {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .menu-right {
    display: flex;
    align-items: center;
  }

  .edit-conditions {
    border: 1px solid ${theme.palette.default.BorderColorGrey};
  }

  .edit_text {
    font-size: 14px;
  }

  .AllFilterLogo {
    padding-right: 5px;
  }

  .data-container {
    background: ${theme.palette.default.white};
    margin: 1rem;
  }

  .image {
    padding-top: 24px;
    padding-left: 3rem;
  }

  .norecord {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
  }

  .data-details {
    display: flex;
    justify-content: center;
    padding-top: 120px;
    gap: 72px;
  }

  .data-details p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.darkGrey};
  }

  .Import-details {
    width: 640px;
  }

  .Import-details button {
    width: 148px;
  }

  .Import-details li {
    margin-bottom: 1rem;
  }

  .Import-details p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: ${theme.palette.default.black};
  }

  .button {
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: rgb(54, 84, 227);
    color: white !important;
  }

  .norecord-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .norecord-data p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: ${theme.palette.default.darkGrey};
    text-transform: capitalize;
  }

  .add-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    gap: 2rem;
  }

  .add-list button {
    width: 148px;
  }

  .add-list p {
    width: 1115px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: ${theme.palette.default.black};
  }

  .parent_Drawer {
    width: 700px;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .mark-complete-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #323232;
    padding-left: 16px;
  }
  .mark-complete-text-class {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #323232;
    /* padding-left: 16px; */
  }
  .mark-btn button {
    background: none;
    border: none;
  }
  .cancle-save-buttons {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    padding-top: 15px;
    border-top: 1px solid ${theme.palette.default.border};
    svg path {
      fill: ${theme.palette.primary.main};
    }
  }
`;
const ModalHeader = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 15px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.palette.default.border};
`;
