import RefreshIcon from "@material-ui/icons/Refresh";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Store";

import {
  Button,
  debounce,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Select,
  Typography,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { format } from "date-fns";
import React from "react";
import styled from "styled-components";
import AppButton from "../../AppComponents/AppButton";
import AppDeleteItem from "../../AppComponents/AppDeleteItem";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { AppForm } from "../../AppComponents/Forms";
import AppFormSearchField from "../../AppComponents/Forms/AppFormSearchField";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import { theme } from "../../Customization/Theme";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { DefinedCustomDates } from "../../Models/defaultSelectOptions";
import { SubAccountActions } from "../../Store/Slices/SubAccount/subaccount.actions";
import {
  creditsModifiedData,
  singleAccountData,
  subAccountsData,
} from "../../Store/Slices/SubAccount/subaccount.selectors";
import { getCurrentTimeZone } from "../../Utils";
import {
  MainContainer,
  NewNativeSelect,
  WrapperContainer,
} from "../Broadcast/WhatsApp/BroadcastHistory/HistoryStyles";
import {
  TableTitleHeader,
  TemplateTable,
} from "../Broadcast_Dashboard/Email_Broadcast_Logs/EmailDashboardLogsStyles";
import AppSkeletonTableLoader from "../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import NoRecordsFound from "../UiComponents/NoRecordsFound/NoRecordsFound";
import ManageCreditsModal from "./ActionModals/CreditsModal";
import SubAccountTopbar from "./toolbar";
import ManagePriceModal from "./ActionModals/ManagePriceModal";
import ManageEmailPriceModal from "./ActionModals/ManageEmailPriceModal";
const header = [
  "Account Name",
  "Parent ID",
  "Email",
  "Mobile",
  "Balance",
  "Create On",
  "Expire On",
  "Account Type",
  "Status",
  // "Action",
];
const keyList = [
  "name",
  "parent_workspace_id",
  "email",
  "phone",
  "credits",
  "created_time",
  "expired_on",
  "sub_account_type",
  "status",
  // ""
];
interface Props extends LoaderComponentProps {}
const SubAccountsLiting: React.FC<Props> = ({ setLoading }) => {
  const { showAlert } = useNotification();
  const formikRef = useRef(null);
  const [pagination, setPagination] = useState<any>();
  const [loading, setLocalLoading] = useState(false);
  const dispatch = useAppDispatch();
  const listing = useAppSelector(subAccountsData);
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [errorApi, setErrorApi] = useState(false);
  const accountCredits = useAppSelector(singleAccountData);
  const manageCredits = useAppSelector(creditsModifiedData);
  useDocumentTitle("Sub Accounts");
  const [onSearch, setOnSearch] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );
  const [openMenuId, setOpenMenuId] = useState(null);
  const openMenu: any = Boolean(anchorElMenu);
  const [localValues, setLocalValues] = useState<any>({
    status: "",
    startDate: DefinedCustomDates?.last30Days?.startDate,
    endDate: DefinedCustomDates?.last30Days?.endDate,
    search: "",
    itemsPerPage: { label: "50  per page", value: 50 },
    currentPage: 0,
    totalPages: 0,
  });
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [showActionModal, setShowActionModal] = useState<any>(null);
  const [showPriceModal, setShowPriceModal] = useState<any>(null);
  const [pricingChannel, setPricingChannel] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // const navigate = useNavigate();
  // const [credits, setCredits] = useState<any>([]);
  // const has_low_balance = localStorage.getItem("has_low_balance");
  //* Render only once*//
  const getListing = () => {
    dispatch(
      SubAccountActions.getSubAccountsListing({
        start: format(localValues.startDate, "yyyy-MM-dd"),
        limit: localValues.itemsPerPage?.value,
        offset: localValues.search.length > 0 ? 0 : localValues.currentPage,
        end: format(localValues.endDate, "yyyy-MM-dd"),
        status: localValues?.status,
        search: localValues?.search,
        timezone: getCurrentTimeZone(),
      })
    )
      .then((res: any) => {
        setPagination(res?.payload?.data?.pagination);
        setLoading(false);
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
        setOnSearch(false);
        setLoading(false);
      });
  };
  // useEffect(() => {
  //   getListing();
  // }, []); // eslint-disable-line
  useEffect(() => {
    getListing();
  }, [manageCredits, localValues?.status]); // eslint-disable-line
  const handleSelectChange = async (e?: any, field?: any) => {
    await setLocalValues({
      ...localValues,
      [field]: e.target.value,
    });
    // getListing();
  };

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
      currentPage: 0,
    });
    setOnSearch(true);
  }, 700);
  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
  };
  const clearAllCallback = () => {
    setLocalValues({
      ...localValues,

      status: "",
      search: "",
    });
  };
  const renderSelectIcon = localValues.status ? (
    <GridCloseIcon fontSize="small" />
  ) : null;

  const handleButtonClick = (formikRef: any) => {
    formikRef.current.handleReset();
  };
  // Action Click for table
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(item);
    setSelectedStatus(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow({});
  };
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };
  const handleRefresh = (id: any) => {
    dispatch(
      SubAccountActions.getSubAccountsCredit({
        workspace_id: id.workspace_id,
      })
    )
      .then((res: any) => {
        showAlert("Stats refresh successfully", "success");
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any,
    ele?: any
  ) => {
    setAnchorElMenu(event.currentTarget);
    setOpenMenuId(id);
  };
  const handleMenuClose = async (value?: string, id?: string, ele?: any) => {
    await setSelectedStatus(value);
    setOpenMenuId(null);
    setSelectedRow(ele);
  };

  const handleChangeStatus = (item: any) => {
    if (item) {
      setAnchorElMenu(null);
      dispatch(
        SubAccountActions.updateAccountStatus({
          id: selectedRow.workspace_id,
          status: selectedStatus,
        })
      ).then((res: any) => {
        if (res.payload.status === 200) {
          getListing();
          setSelectedStatus(null);
          setSelectedRow(null);
        }
      });
    }
  };

  const handleCreditPopup = (action: string) => {
    setShowActionModal(true);
    setAnchorEl(null);
  };

  const handlePricing = (row: string, channel: string) => {
    setSelectedRow(row);
    setPricingChannel(channel);
    setShowPriceModal(true);
    setAnchorEl(null);
  };
  return (
    <WrapperContainer style={{ height: "calc(100vh - 65px)" }}>
      {/* Top Bar JSX */}
      <SubAccountTopbar setLoading={setLoading} getListing={getListing} />

      <MainContainer style={{ padding: "0px" }}>
        <TemplateTable>
          <TableTitleHeader>
            <div className="left">
              <div className="title">
                <p style={{ fontSize: "13px" }}>Filter By</p>
              </div>
              <div className="">
                <AppForm
                  initialValues={{ search: "" }}
                  onSubmit={(values: any, submitProps: any) => {}}
                  innerRef={formikRef}
                  divStyle={{ width: "100%" }}
                >
                  <Grid
                    container
                    spacing={1}
                    display="flex"
                    sx={{ m: 0.2, alignItems: "center" }}
                  >
                    <Grid
                      item
                      alignItems="center"
                      sx={{ paddingTop: "0 !important" }}
                    >
                      <NewNativeSelect>
                        <Select
                          defaultValue={undefined}
                          name="status"
                          value={localValues?.status}
                          onChange={(e) => handleSelectChange(e, "status")}
                          displayEmpty
                          className="new-select-main"
                          style={{
                            height: "28px",
                          }}
                          placeholder="Hello"
                        >
                          <MenuItem value="" disabled>
                            Select Status
                          </MenuItem>
                          <MenuItem value="live">Live</MenuItem>
                          <MenuItem value="inactive">Inactive</MenuItem>
                          <MenuItem value="expired">Expired</MenuItem>
                        </Select>
                        <div
                          className="clear-icon"
                          onClick={(e: any) => clearCallback(e, "status")}
                        >
                          {renderSelectIcon}
                        </div>
                      </NewNativeSelect>
                    </Grid>

                    <Grid
                      item
                      alignItems="center"
                      display="flex"
                      sx={{ padding: "1px !important" }}
                      gap="0.5rem"
                    >
                      <AppFormSearchField
                        name="search"
                        placeholder="Search"
                        defaultBorder={true}
                        divStyle={{
                          marginLeft: "10px",
                          minWidth: "220px",
                          marginBottom: "0",
                        }}
                        onChange={(e: any) => {
                          handleSelectSearch(e, "search");
                        }}
                        clearCallback={(e: any) => clearCallback(e, "search")}
                      />

                      {localValues.status && localValues.search && (
                        <AppButton
                          variant="outline"
                          onClick={() => {
                            clearAllCallback();
                            handleButtonClick(formikRef);
                          }}
                          sx={{ mb: "9px" }}
                        >
                          Reset
                        </AppButton>
                      )}
                    </Grid>
                  </Grid>
                </AppForm>
              </div>
            </div>
          </TableTitleHeader>

          {loading ? (
            <AppSkeletonTableLoader
              numberOfCol={4}
              numberofRow={10}
              skeletonHeight={30}
              skeletonWidth={"100%"}
              skeletonHeaderHeight={40}
              skeletonHeaderWidth={"100%"}
              tableHeight={`calc(100vh - 200px)`}
            />
          ) : (
            <>
              <Paper
                sx={{
                  padding: "0px 0 6px 0",
                  overflowX: "auto",
                  boxShadow: "none",
                  borderRadius: 0,
                  // borderLeft: `1px solid ${theme.palette.default.border}`,
                  // borderBottom: `1px solid ${theme.palette.default.border}`,
                  // borderRight: `1px solid ${theme.palette.default.border}`,
                }}
              >
                <Box
                  display="flex"
                  sx={{
                    padding: 0,
                    maxHeight: "calc(100vh - 250px)",
                  }}
                  flexDirection="column"
                  //   style={customStyle}
                  className={`table-wrapper ${
                    errorApi || listing?.data?.length === 0
                      ? "errorWrapper"
                      : ""
                  }`}
                >
                  {/* Table Heading */}
                  <Box
                    display="flex"
                    sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
                    className="tabel-heading"
                  >
                    {header.map((key: any, index: number) => {
                      return (
                        <Box
                          className={`table-cell`}
                          minWidth={
                            index === 0
                              ? "200px"
                              : index === 1
                              ? "240px"
                              : "127px"
                          }
                          width={
                            index === 0
                              ? "16%"
                              : index === 1
                              ? "20%"
                              : `calc(100% / ${header.length - 2})`
                          }
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            {key}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                  {errorApi ||
                    (listing?.data?.length === 0 ? (
                      <>
                        <NoRecordsFound subText="Get started by creating a sub account first.">
                          {/* <ProtectedAppButton
                            moduleId={`${type}_broadcast_crud`}
                            specificPermissionKey="create"
                            variant="primarydark"
                            onClick={() =>
                              Navigate(`/broadcast/${type}/create-broadcast/1`)
                            }
                            isDisabled={has_low_balance === "true"}
                            title={
                              has_low_balance === "true"
                                ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit."
                                : ""
                            }
                          >
                            Create Broadcast
                            <AppMaterialIcons iconName="Add" />
                          </ProtectedAppButton> */}
                        </NoRecordsFound>
                      </>
                    ) : (
                      <Box
                        className={`table-content`}
                        sx={{ minHeight: "calc(100vh - 300px)" }}
                      >
                        {listing?.data?.map(
                          (currentItem: any, index: number) => {
                            return (
                              <Box
                                display="flex"
                                className="table-data__row"
                                key={index}
                              >
                                <Box
                                  minWidth="200px"
                                  width="16%"
                                  px={0.5}
                                  py={0.5}
                                  display="flex"
                                  sx={{ alignItems: "center" }}
                                  className="table-cell"
                                >
                                  <Typography className="table-text">
                                    {currentItem?.[keyList?.[0]]}
                                  </Typography>
                                </Box>
                                <Box
                                  minWidth="240px"
                                  width="20%"
                                  px={0.5}
                                  py={0.5}
                                  display="flex"
                                  sx={{ alignItems: "center" }}
                                  className="table-cell"
                                >
                                  <Typography
                                    title={currentItem?.[keyList?.[1]]}
                                    sx={{
                                      textDecoration: "none",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="table-text"
                                  >
                                    {currentItem?.[keyList?.[1]]}
                                  </Typography>
                                </Box>

                                <Box
                                  minWidth="127px"
                                  width={`calc(100% / ${header.length - 2})`}
                                  px={0.5}
                                  py={0.5}
                                  display="flex"
                                  sx={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className=" table-cell"
                                >
                                  <Typography
                                    sx={{
                                      textDecoration: "none",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                    title={currentItem?.[keyList?.[2]]}
                                    className="table-text "
                                  >
                                    {currentItem?.[keyList?.[2]]}
                                  </Typography>
                                </Box>

                                <Box
                                  minWidth="127px"
                                  width={`calc(100% / ${header.length - 2})`}
                                  px={0.5}
                                  py={0.5}
                                  display="flex"
                                  sx={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="table-cell"
                                >
                                  <StatusTypographyChip
                                    status={currentItem?.[keyList?.[3]]}
                                    title={currentItem?.[keyList?.[3]]}
                                    className="table-text text-capitalize completed"
                                  >
                                    {currentItem?.[keyList?.[3]]}
                                  </StatusTypographyChip>
                                </Box>

                                <Box
                                  minWidth="127px"
                                  width={`calc(100% / ${header.length - 2})`}
                                  px={0.5}
                                  py={0.5}
                                  display="flex"
                                  sx={{
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}
                                  className="table-cell"
                                >
                                  <Typography
                                    sx={{
                                      textDecoration: "none",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "flex",
                                      verticalAlign: "middle",
                                    }}
                                    title={currentItem?.[keyList?.[4]] || "0"}
                                    className="table-text"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {" "}
                                    {currentItem?.[keyList?.[4]] || "0"}
                                    <AppButton
                                      onClick={() => handleRefresh(currentItem)}
                                      buttonStyle={{
                                        marginLeft: "10px",
                                        minHeight: "20px",
                                        height: "20px",
                                        minWidth: "20px",
                                      }}
                                    >
                                      {" "}
                                      <RefreshIcon
                                        style={{
                                          width: "11px",
                                          margin: "-2px 3px",
                                          cursor: "pointer",
                                        }}
                                      />{" "}
                                    </AppButton>
                                  </Typography>
                                </Box>

                                <Box
                                  minWidth="127px"
                                  width={`calc(100% / ${header.length - 2})`}
                                  px={0.5}
                                  py={0.5}
                                  display="flex"
                                  sx={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="table-cell"
                                >
                                  <Typography
                                    title={currentItem?.[keyList?.[5]] || "0"}
                                    sx={{
                                      textDecoration: "none",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="table-text"
                                  >
                                    {" "}
                                    {currentItem?.[keyList?.[5]] || "0"}
                                  </Typography>
                                </Box>

                                <Box
                                  minWidth="127px"
                                  width={`calc(100% / ${header.length - 2})`}
                                  px={0.5}
                                  py={0.5}
                                  display="flex"
                                  sx={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="table-cell"
                                >
                                  <Typography
                                    title={currentItem?.[keyList?.[6]] || "0"}
                                    sx={{
                                      textDecoration: "none",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="table-text"
                                  >
                                    {" "}
                                    {currentItem?.[keyList?.[6]] || "0"}
                                  </Typography>
                                </Box>

                                <Box
                                  minWidth="127px"
                                  width={`calc(100% / ${header.length - 2})`}
                                  px={0.5}
                                  py={0.5}
                                  display="flex"
                                  sx={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="table-cell"
                                >
                                  <Typography className="table-text r">
                                    {currentItem?.[keyList?.[7]] || "0"}
                                  </Typography>
                                </Box>
                                <Box
                                  minWidth="127px"
                                  width={`calc(100% / ${header.length - 2})`}
                                  px={0.5}
                                  py={0.5}
                                  display="flex"
                                  sx={{ alignItems: "center" }}
                                  className="table-cell"
                                >
                                  <Typography className="table-text r">
                                    <Button
                                      id="status-button"
                                      className={
                                        currentItem?.[keyList?.[8]] === "live"
                                          ? "success"
                                          : currentItem?.[keyList?.[8]] ===
                                            "expired"
                                          ? "disabled"
                                          : "error"
                                      }
                                      aria-controls={
                                        openMenu ? "basic-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        openMenu ? "true" : undefined
                                      }
                                      onClick={(e: any) =>
                                        handleMenuClick(
                                          e,
                                          currentItem.workspace_id,
                                          currentItem
                                        )
                                      }
                                    >
                                      {currentItem?.[keyList?.[8]] === "live"
                                        ? "Active"
                                        : currentItem?.[keyList?.[8]]}
                                      <KeyboardArrowDownIcon />
                                    </Button>
                                    {openMenuId === currentItem.workspace_id &&
                                      currentItem?.[keyList?.[8]] !==
                                        "expired" &&
                                      openMenu && (
                                        <Menu
                                          id="basic-menu"
                                          anchorEl={anchorElMenu}
                                          open={openMenu}
                                          onClose={() => handleMenuClose()}
                                          MenuListProps={{
                                            "aria-labelledby": "basic-button",
                                          }}
                                          sx={{
                                            boxShadow: "none",
                                          }}
                                        >
                                          <MenuItem
                                            onClick={() =>
                                              handleMenuClose(
                                                "live",
                                                currentItem.workspace_id,
                                                currentItem
                                              )
                                            }
                                            selected={
                                              currentItem?.[keyList?.[8]] ===
                                              "live"
                                            }
                                            disabled={
                                              currentItem?.[keyList?.[8]] ===
                                              "live"
                                            }
                                          >
                                            Active
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() =>
                                              handleMenuClose(
                                                "inactive",
                                                currentItem.workspace_id,
                                                currentItem
                                              )
                                            }
                                            disabled={
                                              currentItem?.[keyList?.[8]] ===
                                              "inactive"
                                            }
                                            selected={
                                              currentItem?.[keyList?.[8]] ===
                                              "inactive"
                                            }
                                          >
                                            Inactive
                                          </MenuItem>
                                        </Menu>
                                      )}
                                  </Typography>
                                </Box>
                                {/* <Box
                                  minWidth="127px"
                                  width={`calc(100% / ${header.length - 2})`}
                                  px={0.5}
                                  py={0.5}
                                  display="flex"
                                  sx={{
                                    gap: "1rem",
                                    justifyContent: "center",
                                  }}
                                  className="table-cell"
                                >
                                  <IconButton
                                    onClick={(e) => handleClick(e, currentItem)}
                                    aria-describedby={id}
                                  >
                                    <AppMaterialIcons iconName="MoreVert" />
                                  </IconButton>

                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "center",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    sx={{
                                      width: "100%",
                                      "& .MuiPaper-root": {
                                        borderRadius: 1,
                                        boxShadow: "none !important",
                                        border: "1px solid #d2d2d2",
                                      },
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: "100%",
                                        maxWidth: 260,
                                        minWidth: 260,
                                      }}
                                    >
                                      <nav aria-label="secondary mailbox folders">
                                        <List
                                          disablePadding
                                          sx={{
                                            color: `${theme.palette.default.darkGrey}`,
                                          }}
                                        >
                                          <ListItem disablePadding>
                                            <ListItemButton
                                              sx={{ p: "0 2" }}
                                              onClick={() =>
                                                handleCreditPopup("add")
                                              }
                                            >
                                              
                                              <Typography
                                                sx={{ ml: 1 }}
                                                variant="body2"
                                              >
                                                Manage Credits
                                              </Typography>
                                            </ListItemButton>
                                          </ListItem>
                                          <Divider />

                                          <ListItem
                                            disablePadding
                                            onClick={() =>
                                              handlePricing(
                                                selectedRow,
                                                "whatsapp"
                                              )
                                            }
                                          >
                                            <ListItemButton>
                                              <Typography
                                                sx={{
                                                  ml: 1,
                                                  color:
                                                    theme.palette.default.black,
                                                }}
                                                variant="body2"
                                              >
                                                Manage WhatsApp Pricing
                                              </Typography>
                                            </ListItemButton>
                                          </ListItem>

                                          <ListItem
                                            disablePadding
                                            onClick={() =>
                                              handlePricing(
                                                selectedRow,
                                                "email"
                                              )
                                            }
                                          >
                                            <ListItemButton>
                                              <Typography
                                                sx={{
                                                  ml: 1,
                                                  color:
                                                    theme.palette.default.black,
                                                }}
                                                variant="body2"
                                              >
                                                Manage Email Pricing
                                              </Typography>
                                            </ListItemButton>
                                          </ListItem>
                                          <ListItem
                                            disablePadding
                                            onClick={() =>
                                              handlePricing(selectedRow, "sms")
                                            }
                                          >
                                            <ListItemButton>
                                              <Typography
                                                sx={{
                                                  ml: 1,
                                                  color:
                                                    theme.palette.default.black,
                                                }}
                                                variant="body2"
                                              >
                                                Manage SMS Pricing
                                              </Typography>
                                            </ListItemButton>
                                          </ListItem>
                                        </List>
                                      </nav>
                                    </Box>
                                  </Popover>
                                </Box> */}
                              </Box>
                            );
                          }
                        )}
                      </Box>
                    ))}
                </Box>
              </Paper>
              {listing?.data?.length ? (
                <AppPaginationComponent
                  totalCount={pagination?.totalRecords}
                  handlePageChange={handlePageChange}
                  currentPage={localValues?.currentPage}
                  totalPages={pagination?.totalPages}
                  rowsPerPage={localValues?.itemsPerPage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  title="Records"
                />
              ) : null}
            </>
          )}

          {/* <IconButton>
                                  {type === "email" ? (
                                    <Link
                                      to={`/broadcast/${type}/broadcast-details/${currentItem?.id}/overview`}
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                    >
                                      <LeaderboardOutlinedIcon fontSize="small" />
                                    </Link>
                                  ) : type === "sms" ? (
                                    <Link
                                      to={`/broadcast/${type}/broadcast-details/${currentItem?.id}/overview`}
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                    >
                                      <LeaderboardOutlinedIcon fontSize="small" />
                                    </Link>
                                  ) : (
                                    // <Link
                                    //   to={`/`}
                                    //   style={{
                                    //     textDecoration: "none",
                                    //     color: "black",
                                    //   }}
                                    // >
                                    //   <LeaderboardOutlinedIcon fontSize="small" />
                                    // </Link>
                                    <Link
                                      to={`/broadcast/${type}/broadcast-details/${currentItem?.id}`}
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                    >
                                      <LeaderboardOutlinedIcon fontSize="small" />
                                    </Link>
                                  )}
                                </IconButton> */}

          {/* </IconButton>
                </div>
              </div>
            </TemplateDisplaywrap>
          </AppModel>  */}
        </TemplateTable>
        {selectedStatus && (
          <AppDeleteItem
            title="Account Status"
            subTitle={`Are you sure, you want to ${
              selectedStatus === "live" ? "Activate" : "Deactivate"
            } this account status?`}
            onDelete={() => handleChangeStatus(selectedRow)}
            setDeleteData={setSelectedRow}
            deleteData={selectedRow}
            buttonText={"Save"}
          />
        )}
        <ManageCreditsModal
          setIsDrawerOpened={setShowActionModal}
          setloading={setLoading}
          isDrawerOpened={showActionModal}
          selectedRow={selectedRow}
        />
        {showPriceModal && pricingChannel === "whatsapp" && (
          <ManagePriceModal
            setIsDrawerOpened={setShowPriceModal}
            setloading={setLoading}
            isDrawerOpened={showPriceModal}
            selectedRow={selectedRow}
          />
        )}
        {showPriceModal &&
          (pricingChannel === "email" || pricingChannel === "sms") && (
            <ManageEmailPriceModal
              setIsDrawerOpened={setShowPriceModal}
              setloading={setLoading}
              isDrawerOpened={showPriceModal}
              selectedRow={selectedRow}
              channel={pricingChannel}
            />
          )}
      </MainContainer>
    </WrapperContainer>
  );
};

export default withLoader(SubAccountsLiting);

export const TemplateDisplaywrap = styled.div`
  #status-button {
    border-radius: 20px;
    width: 90px;
    height: 25px;
  }
  .crawl-popup {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.default.grey};
    align-items: center;
    padding: 22px 16px;

    p {
      color: #323232;
      font-weight: 400;
      font-style: inter;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .header {
    margin-bottom: 55px;
  }

  h3 {
    line-height: 30px;
    font-weight: 500;
    font-style: inter;
    font-size: 20px;
    color: #000000;
  }
  .paragraph {
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: #f5f7ff;
    border: 1px solid #bfcaff;
    padding: 10px;
    margin-top: 16px;
    p {
      font-family: inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .input-field {
    margin-top: 1rem;
  }
  .input-field label {
    font-style: inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }

  .popup-buttons {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    border-top: 1px solid ${theme.palette.default.grey};
  }

  .upload-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    .header {
      width: 100%;
      display: flex;
      align-items: flex-start;
    }
  }

  .upload-file p {
    font-family: inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }
`;
const StatusTypographyChip = styled(Typography)`
  && {
    min-width: 100%;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding: 2px 8px;
    border-radius: 4px;

    ${(props: any) => {
      switch (props.status) {
        case "completed":
          return `
            border: 1px solid ${theme.palette.default.successNew};
            background-color: ${theme.palette.default.successNewBackground};
            color: ${theme.palette.default.successNew};
          `;
        case "running":
          return `
            border: 1px solid ${theme.palette.default.error};
            background-color: #FFEDED;
            color: ${theme.palette.default.error};
          `;
        case "processing":
          return `
            border: 1px solid ${theme.palette.default.paleGold};
            background-color: ${theme.palette.default.EarlyDawn};
            color: #918548;
          `;
        default:
          return `
            border: none;
            background-color: none;
          `;
      }
    }}
  }
`;
