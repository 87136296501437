import { Box, IconButton } from "@mui/material";
import { ReactComponent as FailedIcon } from "../../../../../../../assets/images/close.svg";
import AppModel from "../../../../../../../Hooks/useModel/AppModel";
import { UploadPopupWrap } from "../../../../../../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import {
  AppForm,
  AppFormField,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../../../../../AppComponents/Forms";
import { SourceTexSchema } from "../components/text/SourceTextValidation";
import { AppToolTip } from "../../../../../../UiComponents/AppToolTip/AppToolTip";
import { AppMaterialIcons } from "../../../../../../../AppComponents/AppMaterialIcons";
import AppButton from "../../../../../../../AppComponents/AppButton";

interface Props {
  isShowingAddEditModal: boolean;
  toggleAddEditModal: () => void;
  mode: string;
  userInput: any;
  handleUpdate: (values: any, submitProps: any) => void;
  handleSubmit: (values: any, submitProps: any) => void;
}

const SourceTextModal: React.FC<Props> = ({
  isShowingAddEditModal,
  toggleAddEditModal,
  mode,
  userInput,
  handleUpdate,
  handleSubmit,
}) => {
  return (
    <AppModel
      isShowing={isShowingAddEditModal}
      onClose={() => {}}
      divStyle={{
        padding: 0,
      }}
      PaperProps={{
        style: {
          boxShadow: "none",
          width: "931px",
          height: "90vh",
        },
      }}
    >
      <UploadPopupWrap>
        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            if (mode === "edit") {
              handleUpdate(values, submitProps);
            } else {
              handleSubmit(values, submitProps);
            }
          }}
          validationSchema={SourceTexSchema}
          divStyle={{
            gap: 0,
            height: "100%",
          }}
        >
          <div className="crawl-popup">
            <div className="crawl-heading">
              <p>Paste Text</p>
            </div>
            <div className="close-icon">
              <IconButton
                onClick={() => {
                  toggleAddEditModal();
                }}
              >
                <FailedIcon />
              </IconButton>
            </div>
          </div>

          <div
            className="body"
            style={{ overflow: "auto", height: "calc(100% - 131px)" }}
          >
            <div className="input-field">
              <div className="content">
                <div className="title">
                  <h3>Paste the text below</h3>
                </div>
                <label className="label-tooltip">
                  Name*{" "}
                  <AppToolTip
                    tooltipProps={{
                      title: "The text source name is mandatory.",
                      placement: "right",
                    }}
                  >
                    <AppMaterialIcons iconName="InfoOutlined" />
                  </AppToolTip>
                </label>
                <AppFormField label="" name="title" placeholder="Source name" />

                <Box className="tex-area-wrapper">
                  <AppFormTextArea
                    name="text"
                    placeholder="Paste your text here"
                    rows={40}
                  />
                </Box>

                <div className="paragraph">
                  <div className="para">
                    <p>
                      Generate a data source from your text by inputting or
                      pasting unstructured text in any language. Utilize this
                      data to develop the bot's knowledge, enabling it to
                      respond to user queries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="popup-buttons">
            <AppButton onClick={() => toggleAddEditModal()} variant="grey">
              Cancel
            </AppButton>
            <div className="submit-btn">
              <AppSubmitButton
                title={mode === "edit" ? "Update" : "Submit"}
                endIcon="ri-arrow-right-line"
              />
            </div>
          </div>
        </AppForm>
      </UploadPopupWrap>
    </AppModel>
  );
};

export default SourceTextModal;
